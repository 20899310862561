/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, GridList, IconButton, Typography } from '@material-ui/core';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import cookie from 'react-cookies';
import swal from 'sweetalert';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { PreviousPageContext, UserContext } from '../../../../routing/IndexRouting';
import api, { queryKeys } from '../../../../http';

import Header from '../../../common/header/Header';
import Heading from './Heading';
import CurrentProjectComponent from './CurrentProject';
import CasaSkeleton from '../../../common/skeleton/CasaSkeleton';
import SearchBar from '../../../common/Searchbar/Search';
import CommonAllProjectsCard from './CommonAllProjectsCard';
// import SDBasics from '../basics/Basics';
import ApiError from '../../../common/Error/ApiError';
import { useAppProductTour } from '../../../../hooks/useAppProductTour';
import { TOUR_INDEX } from '../../../../v2/utils/tour';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '6%',
    paddingLeft: '3%',
    flexGrow: '1',
    maxWidth: '100%',
    flexBasis: '100%',
  },
  gridList: {
    flexWrap: 'nowrap',
    width: '100%',
    transform: 'translateZ(0)',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  line: {
    width: '90%',
  },
  spacing: {
    marginTop: '2%',
  },
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflowX: 'scroll',
  },
  disableRipple: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const MyProjects = () => {
  const token = cookie.load('SDPLUS_TOKEN');
  const classes = useStyles();
  const history = useHistory();
  const { setPreviousPage } = useContext(PreviousPageContext);
  const { userDetails } = useContext(UserContext);
  const [width, setWidth] = useState(window.innerWidth);
  const [searchValue, setSearchValue] = useState('');
  const [searchedData, setSearchedData] = useState([]);

  const { state: tourState, setState: setTourState } = useAppProductTour();

  useEffect(() => {
    if (tourState.tourActive) {
      setTourState({ stepIndex: TOUR_INDEX.MyProject });
    }
  }, []);

  const mutationDeleteProject = useMutation({
    mutationFn: (projectId) => api.deleteProject(projectId),
  });
  const {
    data: projectsData,
    isError,
    isLoading,
    refetch: refetchProjects,
  } = useQuery({
    queryKey: [queryKeys.projectsList, userDetails?.organisation?._id, token],
    queryFn: () => api.getProjects(userDetails?.organisation?._id),
    enabled: !!userDetails?.organisation?._id && !!token,
  });

  const projects = useMemo(() => {
    return projectsData?.projects || [];
  }, [projectsData]);

  const currentprojects = useMemo(() => {
    return projectsData?.currentProject || null;
  }, [projectsData]);

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    setPreviousPage('projects');
  }, []);

  const handleInsiteButton = (value) => history.push(`/projects/${value._id}/insights`);

  const handleDelete = (project) => {
    swal({
      title: `Are you sure you want to delete the project ${project.name}`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        const response = await mutationDeleteProject.mutateAsync(project._id);
        if (response) {
          swal(`Successfully deleted Project - ${project.name}`, {
            icon: 'success',
          });
          refetchProjects();
        }
      }
    });
  };

  const handleEditCurrentProject = () => {
    if (currentprojects) history.push(`/projects/${currentprojects._id}/edit?type=basic`);
  };

  const handleTextField = (e) => {
    if (e.target.value) {
      setSearchValue(e.target.value);
      const newData = projects?.filter((project) => project.name.toLowerCase().includes(e.target.value.toLowerCase()));
      setSearchedData(newData);
    } else {
      setSearchValue('');
      setSearchedData([]);
    }
  };

  const renderProjectList = (projectList) => {
    return projectList.map((project, index) => (
      <>
        <GridList className={classes.gridList} style={{ marginBottom: '3%' }}>
          <CommonAllProjectsCard
            index={index}
            project={project}
            currentprojects={currentprojects}
            history={history}
            userDetails={userDetails}
            handleDelete={() => handleDelete(project)}
          />
        </GridList>
        <br />
      </>
    ));
  };

  const renderSkeletons = () => (
    <>
      <Grid item xs={12} style={{ marginBottom: '3%' }}>
        <CasaSkeleton variant="rect" height="20vh" width="90%" animation="wave" />
      </Grid>
      <br />
      <Grid item xs={12} style={{ marginBottom: '3%' }}>
        <CasaSkeleton variant="rect" height="20vh" width="90%" animation="wave" />
      </Grid>
      <br />
      <Grid item xs={12} style={{ marginBottom: '3%' }}>
        <CasaSkeleton variant="rect" height="20vh" width="90%" animation="wave" />
      </Grid>
    </>
  );

  const renderProjectSection = () => {
    if (searchValue && projects) return renderProjectList(searchedData);
    if (!searchValue && projects) return renderProjectList(projects);
    return renderSkeletons();
  };

  const renderDesktopView = () => (
    <>
      <Header currentprojects={currentprojects} />
      <Grid container alignItems="center" className={classes.root} style={{ marginTop: '1%' }}>
        <Heading style={{ position: 'fixed' }} />
        <CurrentProjectComponent
          handleEditCurrentProject={handleEditCurrentProject}
          currentproject={currentprojects}
          handleInsiteButton={() => handleInsiteButton(currentprojects)}
          width={width}
          loader={isLoading}
        />
      </Grid>
      <Grid container xs={12}>
        <Grid container xs={12}>
          <Grid item xs={6} />
          <Grid item xs={6} container justifyContent="flex-end" alignItems="flex-end">
            <Grid xs={12} container alignItems="center" justifyContent="center">
              <Grid xs={10} container justifyContent="flex-end">
                <button
                  onClick={() => history.push('/projects/create')}
                  style={{
                    background: 'transparent',
                    border: 'none',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                  className="project-create-button"
                >
                  <Typography variant="h5" style={{ color: '#29E1AC', cursor: 'pointer' }}>
                    CREATE PROJECT
                  </Typography>
                </button>
              </Grid>
              <Grid xs={2}>
                <IconButton
                  onClick={() => history.push('/projects/create')}
                  className={classes.disableRipple}
                  aria-label="delete"
                  style={{ padding: 0, cursor: 'pointer' }}
                >
                  <AddCircleRoundedIcon style={{ fontSize: '32px', color: '#29E1AC' }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6} container justifyContent="flex-start" alignItems="center">
          <Typography style={{ fontWeight: 'bold' }} variant="h5">
            Other Projects
          </Typography>
          <Grid container xs={12} justifyContent="flex-start" alignItems="center" style={{ marginBottom: '5%' }}>
            <Grid item xs={6}>
              <SearchBar onChange={handleTextField} value={searchValue} placeholder="Search by project name" />
            </Grid>
          </Grid>

          {renderProjectSection()}
        </Grid>
      </Grid>
      {/* {userDetails?.onboarding?.showBasics === true && <SDBasics source="Projects" />} */}
    </>
  );

  const renderMobileView = () => (
    <>
      <Header currentprojects={currentprojects} />
      <Grid container alignItems="center" className={classes.root}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <Heading style={{ marginTop: '3%', marginBottom: '6%' }} width={width} />
            <CurrentProjectComponent
              handleEditCurrentProject={handleEditCurrentProject}
              currentproject={currentprojects}
              handleInsiteButton={() => handleInsiteButton(currentprojects)}
            />
          </div>
          <div style={{ marginTop: '8%' }}>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <Grid />
                <Typography style={{ fontWeight: 'bold' }} variant="h5">
                  Other Projects
                </Typography>
                <Grid container xs={12}>
                  <Grid item xs={6}>
                    <SearchBar onChange={handleTextField} value={searchValue} placeholder="Search by project name" />
                  </Grid>
                  <Grid xs={2} />
                </Grid>
                <Grid item xs={12} className={classes.space} />
                <br />
                <br />
                {renderProjectSection()}
              </Grid>
            </Grid>
          </div>
        </div>
        {/* {userDetails?.onboarding?.showBasics === true && <SDBasics source="Projects" />} */}
      </Grid>
    </>
  );

  if (isError)
    return (
      <>
        <Header currentprojects={currentprojects} />
        <Grid container alignItems="center" className={classes.root}>
          <ApiError />
        </Grid>
      </>
    );
  return <>{width > 1279 ? renderDesktopView() : renderMobileView()}</>;
};

export default MyProjects;
