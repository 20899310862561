/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useRef, useEffect } from 'react';
import axios from '../../../../config/Axios';
import useApiRequest from '../../../hooks/useApiRequest';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import SearchBar from '../../../common/Searchbar/Search';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import swal from 'sweetalert';
import api from '../../../../http';
import 'react-image-crop/dist/ReactCrop.css';
import { getBaseImageUrl } from '../../../../utils/getBaseImageUrl';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '2%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  doneicon: {
    color: 'green',
  },
  closeicon: {
    color: 'red',
  },
}));

function PlatformAssets() {
  const [selectedValue, setSelectedValue] = useState('');
  const [platformAsset, setPlatformAsset] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchPlatformAsset, setSearchPlatformAsset] = useState('');
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const data = [1, 2, 3, 4, 5, 6];
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [tag, setTag] = useState('');
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [asset, setAsset] = useState('');

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [file, setFile] = useState('');
  const [completedCrop, setCompletedCrop] = useState(null);
  const [{ status, response }, makeRequest] = useApiRequest('/platform-asset', {
    verb: 'get',
  });
  if (status === null) makeRequest();
  useEffect(() => {
    if (response) {
      setPlatformAsset(response.data.data);
    }
  }, [response]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleTextField = (e) => {
    setSearchValue(e.target.value);
    const newData = platformAsset && platformAsset.data.filter((category) => category.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
    setSearchPlatformAsset(newData);
  };

  const handleName = (event) => setName(event.target.value);
  const handleTag = (event) => setTag(event.target.value);

  const handlePost = () => {
    let body = { name: name, assetlocation: asset && asset._id, type: file.type, tag: tag };
    axios
      .post(`/platform-asset`, body)
      .then(() => {
        makeRequest();
        setName('');
        setFile('');
        setAsset('');
        setTag('');
        setOpen(!open);
      })
      .catch((err) => console.log(err, 'err'));
  };
  const handleOpen = () => {
    setName('');
    setOpen(!open);
    setFile('');
    setTag('');
    setCompletedCrop('');
  };

  const handleSelect = (e) => {
    if (e.target.value === 'none') {
      setSelectedValue('');
    } else {
      setSelectedValue(e.target.value);
    }
  };

  const handleSelectService = (name, id) => {
    setName(name);
    setId(id);
  };

  const handleUpload = async () => {
    if (file) {
      const signedUrl = await api.getPlatformPresignedURL(file.name);
      if (!signedUrl) throw new Error('Failed to get presigned URL');
      const uploadSuccessful = await api.putUploadToS3(signedUrl, file);
      if (uploadSuccessful?.status !== 200) throw new Error('Upload failed');
      const viewImageURL = getBaseImageUrl(signedUrl);
      if (viewImageURL) {
        const body = {
          URL: viewImageURL,
        };
        try {
          const response = await api.createStaticAsset(body);
          if (response && response.data.data) {
            setAsset(response.data.data.data);
            setShow(true);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
  const handleSelected = () => {
    setCompletedCrop('');
    setSelectedValue('none');
  };

  const handleNonupload = () => {
    swal({ icon: 'error', title: `Please Select an Image` });
    setName('');
  };
  const handleNonpost = () => {
    swal({ icon: 'error', title: `Please Select an Image` });
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);
  }, [completedCrop]);

  return (
    <>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        <Grid item xs={7} align="left">
          <Typography variant="h3">Platform Asset Management</Typography>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={3}>
          <Link style={{ background: 'transparent', color: 'rgba(0, 219, 153, 0.82)', width: '30%' }} onClick={handleOpen}>
            Add Asset <AddCircleIcon />
          </Link>
        </Grid>
      </Grid>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        <Grid item xs={5} align="left">
          <SearchBar onChange={handleTextField} value={searchValue} placeholder="Search by Platform Asset name" />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={3} align="left"></Grid>
        <Grid item xs />
      </Grid>
      {selectedValue && selectedValue === 'name' ? (
        <>
          <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
            <Grid item xs={6} align="left">
              <form className={classes.form} noValidate autoComplete="off">
                <TextField id="standard-basic" label="Platform Asset Name" onChange={handleName} value={name} />
              </form>
            </Grid>
            <Grid item xs={6} align="left">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  axios.put(`/platform-asset/${id}`, { newName: name }).then(() => {
                    makeRequest();
                    setSelectedValue('');
                    swal({ icon: 'success', title: `Platform Asset updated successfully` });
                  });
                }}
                style={{ marginRight: '2%' }}
              >
                Update
              </Button>
              <Button variant="contained" color="secondary" onClick={handleSelected} className={classes.margin}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs />
          </Grid>
        </>
      ) : selectedValue && selectedValue === 'location' ? (
        <>
          <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
            <Grid item xs={12} align="left">
              <Grid item xs={12}>
                <Typography variant="h5">Update Asset Location For {name}</Typography>
                <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
                  <div className="App">
                    <div>
                      <input type="file" accept="audio/*,video/*,image/*,application/pdf, application/vnd.ms-excel" onChange={onSelectFile} />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5} align="left">
              {file && !show ? (
                <Button variant="contained" color="primary" onClick={handleUpload} style={{ marginRight: '2%' }}>
                  Upload
                </Button>
              ) : (
                ''
              )}
              {show ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    axios.put(`/platform-asset/${id}`, { newassetlocation: asset && asset._id, newType: file.type }).then(() => {
                      swal({ icon: 'success', title: `Platform Asset updated successfully` });
                      makeRequest();
                      setSelectedValue('');
                      setFile('');
                      setAsset('');
                      setSelectedValue('');
                      setTag('');
                      setShow('');
                    });
                  }}
                  style={{ marginRight: '2%' }}
                >
                  Update
                </Button>
              ) : (
                ''
              )}
              {!file && !show ? (
                <Button variant="contained" color="primary" onClick={handleNonupload} style={{ marginRight: '2%' }}>
                  Upload
                </Button>
              ) : (
                ''
              )}
              <Button variant="contained" color="secondary" onClick={handleSelected} className={classes.margin}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs />
          </Grid>
        </>
      ) : selectedValue && selectedValue === 'tag' ? (
        <>
          <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
            <Grid item xs={6} align="left">
              <form className={classes.form} noValidate autoComplete="off">
                <TextField id="standard-basic" label="Platform Asset Tag" onChange={handleTag} value={tag} />
              </form>
            </Grid>
            <Grid item xs={6} align="left">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  axios.put(`/platform-asset/${id}`, { newTag: tag }).then(() => {
                    makeRequest();
                    setSelectedValue('');
                    swal({ icon: 'success', title: `Platform Asset updated successfully` });
                  });
                }}
                style={{ marginRight: '2%' }}
              >
                Update
              </Button>
              <Button variant="contained" color="secondary" onClick={handleSelected} className={classes.margin}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs />
          </Grid>
        </>
      ) : null}
      {open ? (
        <>
          <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
            <Grid item xs={6} align="left">
              <form className={classes.form} noValidate autoComplete="off">
                <TextField id="standard-basic" label="Platform Asset Name" onChange={handleName} value={name} />
                <TextField id="standard-basic" label="Platform Asset Tag" onChange={handleTag} value={tag} />
                <Grid item xs={12}>
                  <div className="App">
                    <div>
                      <input type="file" accept="audio/*,video/*,image/*,application/pdf, application/vnd.ms-excel" onChange={onSelectFile} />
                    </div>
                  </div>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={6} align="left">
              {file && !show ? (
                <Button variant="contained" color="primary" onClick={handleUpload} style={{ marginRight: '2%' }}>
                  Upload
                </Button>
              ) : (
                ''
              )}
              {show ? (
                <Button variant="contained" color="primary" onClick={handlePost} style={{ marginRight: '2%' }}>
                  Add
                </Button>
              ) : (
                ''
              )}
              {!file && !show ? (
                <Button variant="contained" color="primary" onClick={handleNonpost} style={{ marginRight: '2%' }}>
                  Add
                </Button>
              ) : (
                ''
              )}

              <Button variant="contained" color="secondary" onClick={handleOpen} className={classes.margin}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs />
          </Grid>
        </>
      ) : (
        ''
      )}
      {searchValue && searchPlatformAsset ? (
        searchPlatformAsset.map((asset, index) => {
          return (
            <div className={classes.root}>
              <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={4}>
                      <Typography className={classes.secondaryHeading}>
                        <b>{asset.name.toUpperCase()}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.secondaryHeading}>
                        <b>
                          <a target="_blank" href={asset.assetlocation ? asset.assetlocation.URL : null}>
                            {asset.assetlocation ? asset.assetlocation.URL : 'No Logo Available'}
                          </a>
                        </b>
                      </Typography>
                    </Grid>
                    <Grid item xs={1} align="center">
                      <Typography className={classes.heading2}>
                        <b>Update</b>
                      </Typography>
                      <br />
                      <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={handleSelect} defaultValue={selectedValue}>
                        <MenuItem value="name" onClick={() => handleSelectService(asset.name, asset._id)}>
                          Name
                        </MenuItem>
                        <MenuItem value="location" onClick={() => handleSelectService(asset.name, asset._id)}>
                          Location
                        </MenuItem>
                        <MenuItem value="tag" onClick={() => handleSelectService(asset.name, asset._id)}>
                          Tag
                        </MenuItem>
                        <MenuItem value="none">None</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={3}>
                      <Typography className={classes.secondaryHeading}>
                        <b>{asset.type.toUpperCase()}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.secondaryHeading}>
                        <b>{asset.tag.toUpperCase()}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={3} align="center">
                      <Typography variant="h6">Delete</Typography>
                      <DeleteIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          axios
                            .delete(`/platform-asset/${asset._id}`)
                            .then(() => {
                              makeRequest();
                              swal({ icon: 'success', title: `Platform Asset deleted successfully` });
                            })
                            .catch((err) => console.log(err, 'err'));
                        }}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })
      ) : platformAsset ? (
        platformAsset.data.map((asset, index) => {
          return (
            <div className={classes.root}>
              <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={4}>
                      <Typography className={classes.secondaryHeading}>
                        <b>{asset.name.toUpperCase()}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.secondaryHeading}>
                        <b>
                          <a target="_blank" href={asset.assetlocation ? asset.assetlocation.URL : null}>
                            {asset.assetlocation ? asset.assetlocation.URL : 'No Logo Available'}
                          </a>
                        </b>
                      </Typography>
                    </Grid>
                    <Grid item xs={1} align="center">
                      <Typography className={classes.heading2}>
                        <b>Update</b>
                      </Typography>
                      <br />
                      <Select labelId="demo-simple-select-label" id="demo-simple-select" onChange={handleSelect} defaultValue={selectedValue}>
                        <MenuItem value="name" onClick={() => handleSelectService(asset.name, asset._id)}>
                          Name
                        </MenuItem>
                        <MenuItem value="location" onClick={() => handleSelectService(asset.name, asset._id)}>
                          Location
                        </MenuItem>
                        <MenuItem value="tag" onClick={() => handleSelectService(asset.name, asset._id)}>
                          Tag
                        </MenuItem>
                        <MenuItem value="none">None</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={0} alignItems="center">
                    <Grid item xs={3}>
                      <Typography className={classes.secondaryHeading}>
                        <b>{asset.type.toUpperCase()}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography className={classes.secondaryHeading}>
                        <b>{asset.tag.toUpperCase()}</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={3} align="center">
                      <Typography variant="h6">Delete</Typography>
                      <DeleteIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          axios
                            .delete(`/platform-asset/${asset._id}`)
                            .then(() => {
                              makeRequest();
                              swal({ icon: 'success', title: `Platform Asset deleted successfully` });
                            })
                            .catch((err) => console.log(err, 'err'));
                        }}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })
      ) : (
        <>
          {data &&
            data.map(() => {
              return <Skeleton animation="wave" height={50} />;
            })}
        </>
      )}
    </>
  );
}
export default PlatformAssets;
