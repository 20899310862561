/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import ResetFields from '../common/textFiledwithReset';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import numberWithCommas from '../../../../../utils/numberToComma';
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@material-ui/icons';
import GlazingDguTable from './glazingDguTable';
import { isValidNumericField } from '../../intervention/utils';

const GlazingDguCustomAdvancedParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    hasValidationError,
    setValidationError,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();

  const isCustomSpecification = getQuantityValue(schema.glazingDgu__pi_customSpecification);
  const isCustomCapex = getQuantityValue(schema.glazingDgu__pi_capexMultiplierSource) === 'CUSTOM' || isCustomSpecification;

  useEffect(() => {
    validateFields();
  }, [
    isCustomCapex,
    getQuantityValue(schema.glazingDgu__pi_customCapex),
    getQuantityValue(schema.glazingDgu__pi_uValue),
    getQuantityValue(schema.glazingDgu__pi_shgc),
    getQuantityValue(schema.glazingDgu__pi_unitEc),
    isCustomSpecification,
    schema.glazingDgu__pi_customCapex,
    schema.glazingDgu__pi_uValue,
    schema.glazingDgu__pi_shgc,
    schema.glazingDgu__pi_unitEc,
  ]);

  const validateFields = () => {
    let hasError = false;
    if (isCustomCapex) {
      const uValue = getQuantityValue(schema.glazingDgu__pi_uValue);
      const shgc = getQuantityValue(schema.glazingDgu__pi_shgc);
      const unitEc = getQuantityValue(schema.glazingDgu__pi_unitEc);
      const customCapex = getQuantityValue(schema.glazingDgu__pi_customCapex);
      hasError = [uValue, shgc, unitEc, customCapex].some((value) => !isValidNumericField(value, true));
    }
    setValidationError(hasError);
  };

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Typography className={classes.textHeader1} style={{ paddingTop: '1%' }}>
        Intervention Cost Assumptions
        <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention </Typography>} placement="right" />
      </Typography>
      <Typography className={classes.textHeader4}>CAPEX (Rs/Sqm)</Typography>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {!isCustomCapex ? (
                <CheckCircleIcon className={classes.selectedCheckbox} />
              ) : (
                <RadioButtonUncheckedIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.glazingDgu__pi_capexMultiplierSource, 'VENDOR');
                    updateQuantityValue(schema.glazingDgu__pi_customSpecification, false);
                  }}
                />
              )}
              Selected Vendor
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <Typography className={classes.textconstants}>
                {numberWithCommas(getQuantityValue(schema.glazingDgu_vendorCapex))} {getQuantityUnit(schema.glazingDgu_vendorCapex)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {isCustomCapex ? (
                <CheckCircleIcon className={classes.selectedCheckbox} />
              ) : (
                <RadioButtonUncheckedIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.glazingDgu__pi_capexMultiplierSource, 'CUSTOM');
                  }}
                />
              )}
              Custom
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                isError={!isValidNumericField(getQuantityValue(schema.glazingDgu__pi_customCapex), true)}
                value={getQuantityValue(schema.glazingDgu__pi_customCapex)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.glazingDgu__pi_customCapex, e.target.value);
                }}
                fullWidth
                required
                onClick={() => updateQuantityValue(schema.glazingDgu__pi_customCapex, getQuantityDefaultValue(schema.glazingDgu__pi_customCapex))}
                disabled={!isCustomCapex}
                removeResetIcon={true}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6" className={!isCustomCapex ? classes.textDisable : undefined}>
                {' '}
                {getQuantityUnit(schema.glazingDgu__pi_customCapex)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ paddingBottom: '10px', paddingTop: '10px' }}>
        <Grid xs={12} direction="row">
          <Typography className={classes.textHeader1} style={{ paddingTop: '1%' }}>
            Product Glazing specifications
          </Typography>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ paddingBottom: '1%', opacity: 0.7 }} className={classes.textHeader3}>
              Selected Product Name:
            </Typography>
            <Typography style={{ paddingBottom: '1%', paddingLeft: '5px' }} className={classes.textHeader3}>
              {getQuantityValue(schema.glazingDgu_productName)}
            </Typography>
          </Grid>
        </Grid>
        <GlazingDguTable tableData={getQuantityValue(schema.glazingDgu_openings)} />
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={12} style={{ paddingBottom: '10px', paddingTop: '10px' }}>
          <Grid xs={12} direction="row">
            <Typography className={classes.textHeader1} style={{ paddingTop: '1%' }}>
              Custom Glazing specifications
            </Typography>
            <Typography className={classes.textHeader3}>
              {' '}
              {isCustomSpecification ? (
                <CheckBox
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.glazingDgu__pi_customSpecification, !isCustomSpecification);
                  }}
                />
              ) : (
                <CheckBoxOutlineBlankOutlined
                  className={classes.nonselectedCheckbox}
                  onClick={() => updateQuantityValue(schema.glazingDgu__pi_customSpecification, !isCustomSpecification)}
                />
              )}
              I have custom glazing specifications
            </Typography>

            <Typography className={classes.textHeader3} style={{ color: hasValidationError ? 'rgba(237, 94, 104, 1)' : 'rgba(158, 158, 158, 1)' }}>
              All fields are mandatory including Custom CAPEX
            </Typography>
          </Grid>
          <Grid container item xs={12} style={{ marginTop: '10px', opacity: isCustomSpecification ? 1 : 0.5 }}>
            <Grid item xs={4} direction="column">
              <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
                <Typography className={classes.textHeader5}>{getQuantityLabel(schema.glazingDgu__pi_uValue)}</Typography>
              </Grid>
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
                <Grid xs={7}>
                  <ResetFields
                    isError={!isValidNumericField(getQuantityValue(schema.glazingDgu__pi_uValue), true)}
                    id="outlined-basic"
                    variant="outlined"
                    value={getQuantityValue(schema.glazingDgu__pi_uValue)}
                    placeholder=""
                    onChange={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.glazingDgu__pi_uValue, e.target.value);
                    }}
                    fullWidth
                    required
                    onClick={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.glazingDgu__pi_uValue, getQuantityDefaultValue(schema.glazingDgu__pi_uValue));
                    }}
                    disabled={!isCustomSpecification}
                    removeResetIcon={true}
                  />
                </Grid>
                <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                  <Typography variant="h6">{getQuantityUnit(schema.glazingDgu__pi_uValue)}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} direction="column">
              <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
                <Typography className={classes.textHeader5}>{getQuantityLabel(schema.glazingDgu__pi_shgc)}</Typography>
              </Grid>
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
                <Grid xs={7}>
                  <ResetFields
                    isError={!isValidNumericField(getQuantityValue(schema.glazingDgu__pi_shgc), true)}
                    id="outlined-basic"
                    variant="outlined"
                    value={getQuantityValue(schema.glazingDgu__pi_shgc)}
                    placeholder=""
                    onChange={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.glazingDgu__pi_shgc, e.target.value);
                    }}
                    fullWidth
                    required
                    onClick={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.glazingDgu__pi_shgc, getQuantityDefaultValue(schema.glazingDgu__pi_shgc));
                    }}
                    disabled={!isCustomSpecification}
                    removeResetIcon={true}
                  />
                </Grid>
                <Grid xs={5} className={classes.grid} style={{ paddingLeft: '5%' }}>
                  <Typography variant="h6">{getQuantityUnit(schema.glazingDgu__pi_shgc)}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} direction="column">
              <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
                <Typography className={classes.textHeader5}>{getQuantityLabel(schema.glazingDgu__pi_unitEc)}</Typography>
              </Grid>
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
                <Grid xs={7}>
                  <ResetFields
                    isError={!isValidNumericField(getQuantityValue(schema.glazingDgu__pi_unitEc), true)}
                    id="outlined-basic"
                    variant="outlined"
                    value={getQuantityValue(schema.glazingDgu__pi_unitEc)}
                    placeholder=""
                    onChange={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.glazingDgu__pi_unitEc, e.target.value);
                    }}
                    fullWidth
                    required
                    onClick={(e) => {
                      e.preventDefault();
                      updateQuantityValue(schema.glazingDgu__pi_unitEc, getQuantityDefaultValue(schema.glazingDgu__pi_unitEc));
                    }}
                    disabled={!isCustomSpecification}
                    removeResetIcon={true}
                  />
                </Grid>
                <Grid xs={5} className={classes.grid} style={{ paddingLeft: '5%' }}>
                  <Typography variant="h6">{getQuantityUnit(schema.glazingDgu__pi_unitEc)}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GlazingDguCustomAdvancedParameters;
