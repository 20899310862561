import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '2%',
    marginTop: '1%',
  },
  tableTextPrimary: {
    fontFamily: 'Lato, sans-serif',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '19.2px',
    letterSpacing: '0.714px',
    justifyContent: 'left',
    color: 'rgba(0, 0, 0, 0.83)',
    paddingTop: '2%',
  },
  progressBarRoot: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(0),
    borderRadius: '0px 100px 100px 0px',
  },
  color: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  spacing: {
    paddingTop: '5%',
  },
  paper: {
    background: 'rgba(245, 245, 245, 1)',
    padding: '20px',
    maxWidth: '240px',
    border: 'none',
    boxShadow: 'none',
    flex: 1,
  },
  heading: {
    fontFamily: 'Lato, sans-serif',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '16.8px',
    letterSpacing: '0.714px',
    textAlign: 'center',
  },
  time: {
    fontFamily: 'Lato, sans-serif',
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.714px',
    textAlign: 'center',
  },
  buttonApply: {
    width: '80%',
    marginLeft: '10%',
    marginTop: '5%',
  },
  secondaryText: {
    fontFamily: 'Lato, sans-serif',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0.714px',
    color: 'rgba(102, 102, 102, 1)',
  },
  CardSpacing: {
    paddingTop: '7%',
  },
  productText: {
    fontFamily: 'Lato, sans-serif',
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.714px',
    justifyContent: 'center',
  },
  productImage: {
    height: '45px',
    width: '45px',
    paddingRight: '15%',
  },
  closeIcon: {
    color: 'rgba(237, 94, 104, 0.75)',
    cursor: 'pointer',
  },
  modalPaper: {
    position: 'absolute',
    width: 500,
    outline: 'none',
    maxHeight: 600,
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: theme.spacing(2, 3, 2, 4),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  linkText: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '1px',
    color: 'rgba(31, 222, 219, 1)',
    cursor: 'pointer',
  },
  tableTitle: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '21px',
    letterSpacing: '1px',
    padding: theme.spacing(1, 0, 1, 0),
    color: 'rgba(46, 46, 46, 1)',
  },
  tableContent: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '1px',
    padding: theme.spacing(1, 0, 1, 2),
  },
  additionalInfo: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '1px',
    padding: theme.spacing(1, 2, 1, 2),
    textAlign: 'justify',
    textJustify: 'inter-word',
  },
  tableStyle: {
    padding: theme.spacing(2, 0, 0, 0),
  },
  rowStyle: {
    flex: 1,
    maxWidth: '240px',
    border: 'none',
    boxShadow: 'none',
  },
  textSmall: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.71px',
    listStylePosition: 'outside',
    marginLeft: '20px',
    padding: 0,
  },
  paddingCenter: {
    padding: theme.spacing(5),
  },
  paddingCenterWithBorder: {
    margin: theme.spacing(0, 8, 0, 6),
    border: '1px solid rgba(66, 225, 152, 1)',
  },
  buttonLarge: {
    width: '60%',
    marginLeft: '20%',
    marginTop: '5%',
  },
}));

export default useStyles;
