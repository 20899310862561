/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Typography, Backdrop, CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import CustomBreadCrumbs from '../../../../common/breadcrumbs/CustomBreadCrumbs';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { InterventionSetCard } from './components/interventionSetCard';
import InterventionDataTable from './components/interventionDataTable';
import useStyles from './styles';
import NoInterventionPage from './components/noInterventionSetsPage';
import api, { queryKeys } from '../../../../../http';
import AddInterventionSetModal from '../components/AddInterventionSetModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import { useQueries } from '@tanstack/react-query';
import LoadingAnimation from '../../../../../v2/components/LoadingAnimation';

const getInterventionData = (set = {}) => {
  const opexData = (set.data || [])?.find((item) => Array.isArray(item.tags) && item.tags.includes('Opex'))?.value;
  const capexIncrease = (set.data || [])?.find((item) => Array.isArray(item.tags) && item.tags.includes('Capex'))?.value;
  const goalContributionData = (set.data || [])?.find((item) => Array.isArray(item.tags) && item.tags.includes('goal-contribution'))?.value;
  const product = (set.data || [])?.find((item) => Array.isArray(item.tags) && item.tags.includes('product'));

  return {
    opex: opexData ? opexData * 5 : 0,
    capexIncrease: capexIncrease ?? 0,
    goalContribution: goalContributionData ? goalContributionData.toFixed(2) : '0.00',
    product,
  };
};

const CompareInterventionSets = () => {
  const classes = useStyles();
  const params = useParams();
  const {
    intervention,
    projectIntervention,
    refetchProjectIntervention,
    request: { isFetchingProjectIntervention },
  } = useInterventionDetailsContext();
  const [openInterventionSetModal, setOpenInterventionSetModal] = useState(false);
  const handleOpen = () => setOpenInterventionSetModal(true);
  const handleClose = () => setOpenInterventionSetModal(false);
  const history = useHistory();

  const allInterventionSetsResponse = useQueries({
    queries: projectIntervention
      ? projectIntervention?.interventionSets?.map((interventionSet) => {
          return {
            queryKey: [queryKeys.metafireInterventionSets, params.id, interventionSet?._id, intervention?.feature],
            queryFn: () => api.getMetafireRequest({ projectId: params?.id, customSetId: interventionSet?._id, feature: intervention?.feature }),
            enabled: !!intervention && !!projectIntervention,
            select: (item) => {
              return { ...interventionSet, data: item?.data?.data?.data };
            },
          };
        })
      : [],
  });

  const isLoadingAll = useMemo(() => allInterventionSetsResponse.some((query) => query.isLoading), [allInterventionSetsResponse]);
  const sets = useMemo(() => {
    if (!isLoadingAll) {
      return allInterventionSetsResponse?.map((setsResponse) => setsResponse?.data);
    } else {
      return [];
    }
  }, [allInterventionSetsResponse, isLoadingAll]);

  const interventionSets = projectIntervention?.interventionSets;
  return (
    <>
      <Grid container alignItems="center" className={classes.root}>
        <CustomBreadCrumbs
          links={[
            { href: `/projects/${params.id}/interventions`, text: 'Interventions' },
            { text: `${intervention?.name}`, href: `/projects/${params.id}/interventions/${params.iid}` },
          ]}
          stage={<b>Saved Intervention Parameter Sets</b>}
        />

        <Grid container item xs={12} alignItems="center" justify="flex-start" spacing={2} className={classes.root}>
          <Grid xs={0} item>
            <ArrowBackIosIcon
              style={{
                color: '#66E3BE',
                cursor: 'pointer',
                width: '30.41px',
                height: '100%',
              }}
              onClick={() => {
                history.push(`/projects/${params.id}/interventions/${params.iid}`);
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <Typography variant="h3" style={{ marginLeft: '-2%' }}>
              Saved Intervention Parameter Sets
            </Typography>
          </Grid>
        </Grid>

        {!interventionSets?.length && !isFetchingProjectIntervention && <NoInterventionPage handleOpen={handleOpen} />}

        {interventionSets?.map((set, index) => {
          const setData = (sets || []).find((s) => s._id === set._id) || {};
          const { opex, capexIncrease, goalContribution, product } = getInterventionData(setData);

          return (
            <InterventionSetCard
              setId={set._id}
              name={set.name}
              createdAt={set.createdAt}
              metafireData={setData?.data || []}
              opex={opex}
              capexIncrease={capexIncrease}
              goalContribution={goalContribution}
              product={product}
              reFetchInterventionSets={refetchProjectIntervention}
              isActive={projectIntervention?.activeSetId === set._id}
              backgrounColor={index % 2 === 0 ? 'rgba(245, 245, 245, 1)' : 'rgba(251, 251, 251, 1)'}
              marginleft={index === 0 && '240px'}
            />
          );
        })}

        {sets?.length > 0 && (
          <InterventionDataTable
            setDescriptions={() => interventionSets?.map((item) => item.description || '-')}
            metafireData={interventionSets?.map((set) => {
              const setData = (sets || []).find((s) => s._id === set._id) || {};
              return setData?.data || [];
            })}
          />
        )}
      </Grid>

      {openInterventionSetModal && (
        <AddInterventionSetModal open={openInterventionSetModal} handleClose={handleClose} projectId={params.id} interventionId={intervention._id} />
      )}
      <LoadingAnimation open={isLoadingAll} />
    </>
  );
};

export default CompareInterventionSets;
