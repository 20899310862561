import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './Styles';
import { CustomModal, Status } from './Components';
import { useStatusContext } from '../../../../../features/providers/StatusProvider';
import api from '../../../../../http';

export const STATUS_DATA = {
  changeToAdmin: {
    title: 'Change Role To Admin',
    icon: <InfoOutlinedIcon style={{ color: '#26E0A3', width: '72', height: '72' }} />,
    text: "The user's role will be changed from 'Normal' to 'Admin'. This will give the user access to Admin controls.",
    buttons: [
      { label: 'Cancel', color: 'default' },
      { label: 'Confirm', color: 'primary' },
    ],
  },
  changeToNormal: {
    title: 'Change Role To Normal',
    icon: <InfoOutlinedIcon style={{ color: '#26E0A3', width: '72', height: '72' }} />,
    text: "The user's role will be changed from 'Admin' to 'Normal'. This will revoke the user's access to Admin controls.",
    buttons: [
      { label: 'Cancel', color: 'primary' },
      { label: 'Confirm', color: 'primary' },
    ],
  },
  removeUser: {
    title: 'Remove User',
    icon: <InfoOutlinedIcon style={{ color: '#f44336', width: '72', height: '72' }} />,
    text: 'The user will lose access to SD+.',
    buttons: [
      { label: 'Cancel', color: 'primary' },
      { label: 'Remove', color: 'secondary' },
    ],
  },
  inviteSent: {
    title: 'Invite Sent',
    icon: <CheckCircleIcon style={{ color: '#4caf50' }} />,
    text: 'An invite link to SD+ has been sent to the respective email address.',
    buttons: [{ label: 'OK', color: 'primary' }],
  },
  accountDeactivated: {
    title: 'Account Deactivated',
    icon: <InfoIcon style={{ color: '#4caf50' }} />,
    text: 'Your account has been deactivated. If you believe this is a mistake, please contact your administrator: aakarsh@ymail.com.',
    buttons: [{ label: 'Home', color: 'primary' }],
  },
  organisationNameUpdated: {
    title: 'Updated!!',
    icon: <CheckCircleIcon style={{ color: '#4caf50', width: '72', height: '72' }} />,
    text: 'Your Organisation Name is Updated.',
    buttons: [{ label: 'OK', color: 'primary' }],
  },
  userDeleted: {
    title: 'User Deleted!!',
    icon: <CheckCircleIcon style={{ color: '#4caf50', width: '72', height: '72' }} />,
    text: 'User Successfully Deleted.',
    buttons: [{ label: 'OK', color: 'primary' }],
  },
  roleUpdated: {
    title: 'Updated!!',
    icon: <CheckCircleIcon style={{ color: '#4caf50', width: '72', height: '72' }} />,
    text: 'User Role Successfully Updated.',
    buttons: [{ label: 'OK', color: 'primary' }],
  },
};

const StatusComponent = ({ fetchUsers, userId, organisationId, roles }) => {
  const { openStatus, statusCode, hideStatus, showStatus } = useStatusContext();
  const status = STATUS_DATA[statusCode];
  const classes = useStyles();

  if (!status) {
    return <></>;
  }

  const handleRemoveUser = async () => {
    const success = await api.deleteUser({ userId, organisationId });
    if (success) showStatus('userDeleted');
  };

  const handleChangeRole = async (status) => {
    const targetRole = roles.find(
      (role) =>
        (status?.title === STATUS_DATA?.changeToAdmin?.title && role.name === 'admin') ||
        (status?.title === STATUS_DATA?.changeToNormal?.title && role.name === 'normal')
    );

    if (targetRole && organisationId && userId) {
      const success = await api.postUserRole({ userId, organisationId, roleId: targetRole._id });
      if (success) showStatus('roleUpdated');
    }
  };

  const handleButtonClick = (label, statusCode) => {
    switch (label) {
      case 'Cancel':
        hideStatus();
        break;
      case 'OK':
        if (statusCode === 'inviteSent') {
          fetchUsers();
        }
        if (statusCode === 'userDeleted') {
          fetchUsers();
        }
        if (statusCode === 'roleUpdated') {
          fetchUsers();
        }
        hideStatus();
        break;
      case 'Home':
        hideStatus();
        break;
      case 'Remove':
        hideStatus();
        handleRemoveUser();
        break;
      case 'Confirm':
        hideStatus();
        handleChangeRole(status);
        break;
      default:
        break;
    }
  };

  return (
    <CustomModal open={openStatus} onClose={hideStatus} className={classes.modal}>
      <Status status={status} handleButtonClick={handleButtonClick} classes={classes} statusCode={statusCode} />
    </CustomModal>
  );
};

export default StatusComponent;
