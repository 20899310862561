import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ResetFields from '../common/textFiledwithReset';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import useStyles from './styles';
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@material-ui/icons';

const glazingFeatures = ['Solar Control', 'Acoustic', 'Fire Resistant', 'Bird Friendly', 'Smart Glass', 'UV Protective', 'Low-E'];
const enabledGlazingFeatures = ['Solar Control'];

const glazingColours = { Blue: 'Blue', Green: 'Green', ClearNeutral: 'Neutral/Clear', SilverReflective: 'Silver/Reflective' };

const GlazingDguCustomBasicParameters = (props) => {
  const classes = useStyles();

  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();

  const handleGlazinFeature = (option) => {
    // disabled because as of now only Solar Control can be selected
    // updateQuantityValue(schema.glazingDgu__pi_glazingFeature, option);
  };

  const handleGlazingColor = (option) => {
    updateQuantityValue(schema.glazingDgu__pi_color, option);
  };

  const glazingFeature = getQuantityValue(schema.glazingDgu__pi_glazingFeature);

  const glazingColor = getQuantityValue(schema.glazingDgu__pi_color);

  return (
    <Grid container direction="row" alignItems="left" item xs={12}>
      <Grid container item xs={12}>
        <Grid container item xs={12}>
          <Grid item xs={4} direction="column">
            <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
              <Typography className={classes.textHeader5}>{getQuantityLabel(schema.glazingDgu__pi_glazingFeature)}</Typography>
            </Grid>

            {glazingFeatures.map((feature) => (
              <Grid
                xs={12}
                container
                alignItems="flex-start"
                direction="row"
                style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', opacity: enabledGlazingFeatures.includes(feature) ? 1 : 0.5 }}
              >
                {glazingFeature === feature ? (
                  <CheckBox className={classes.selectedCheckbox} onClick={() => handleGlazinFeature(feature)} />
                ) : (
                  <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => handleGlazinFeature(feature)} />
                )}
                <Typography>{feature}</Typography>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={4} direction="column">
            <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
              <Typography className={classes.textHeader5}>{getQuantityLabel(schema.glazingDgu__pi_color)}</Typography>
            </Grid>

            {Object.keys(glazingColours).map((colour) => (
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.textHeader3} onClick={() => handleGlazingColor(colour)}>
                  {glazingColor === colour ? (
                    <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                  ) : (
                    <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} />
                  )}
                  {glazingColours[colour]}
                </Typography>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={4} direction="column">
            <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
              <Typography className={classes.textHeader5}>{getQuantityLabel(schema.glazingDgu__pi_frameFactor)}</Typography>
            </Grid>
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  value={getQuantityValue(schema.glazingDgu__pi_frameFactor)}
                  placeholder=""
                  onChange={(e) => {
                    e.preventDefault();
                    updateQuantityValue(schema.glazingDgu__pi_frameFactor, e.target.value);
                  }}
                  fullWidth
                  required
                  onClick={(e) => {
                    e.preventDefault();
                    updateQuantityValue(schema.glazingDgu__pi_frameFactor, getQuantityDefaultValue(schema.glazingDgu__pi_frameFactor));
                  }}
                  disabled={false}
                />
              </Grid>
              <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">{getQuantityUnit(schema.glazingDgu__pi_frameFactor)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default GlazingDguCustomBasicParameters;
