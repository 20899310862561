import React, { useState } from 'react';
import { Grid, Typography, withWidth, Modal } from '@material-ui/core';
import PrimaryButton from '../../../../../common/button/Button';
import CloseIcon from '@material-ui/icons/Close';
import infoIcon from '../../../../../../assets/interventions/info-icon.svg';
import useStyles from '../styles';

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const CustomModal = ({ deleteInterventionSet }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  return (
    <>
      <CloseIcon className={classes.closeIcon} onClick={() => setShowModal(true)} />

      <Modal open={showModal} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <div style={modalStyle} className={classes.modalPaper}>
          <Grid direction="column" container alignItems="center" justifyContent="center" style={{ marginBottom: '3%' }}>
            <Typography variant="h5">
              <b>Delete Parameter Set</b>
            </Typography>
            <img
              src={infoIcon}
              alt="..."
              style={{
                marginTop: '20px',
                height: '72px',
                width: '72px',
                color: '#ffffff',
              }}
            />
            <Typography variant="h6" style={{ paddingTop: '20px' }}>
              This will not affect your selected parameter set.
            </Typography>

            <Grid xs={12} container alignItems="center" justifyContent="flex-end" style={{ marginTop: '5%', paddingRight: '3%', paddingLeft: '3%' }}>
              <Grid xs={3} container alignItems="left" justifyContent="flex-start">
                <Typography className={classes.linkText} onClick={() => handleClose()}>
                  CANCEL
                </Typography>
              </Grid>
              <Grid xs={4} container alignItems="center" justifyContent="flex-end">
                <PrimaryButton
                  children="Delete"
                  style={{ width: '217px' }}
                  onClick={async () => {
                    await deleteInterventionSet();
                    handleClose();
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  );
};

export default withWidth()(CustomModal);
