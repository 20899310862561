/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, FormControl, Select, MenuItem } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import PrimaryButton from '../../../../common/button/Button';
import CloseIcon from '@material-ui/icons/Close';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import Fields from '../../../../common/textfield/Fields';
import { useParams } from 'react-router';
import axios from '../../../../../config/Axios';
import swal from 'sweetalert';
import AttachFileForCustomIntervention from '../../../editproject/AttachFileForCustomIntervention.js';
import { UserContext } from '../../../../../routing/IndexRouting';

export const AllProjectContext = React.createContext();

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '650px',
    outline: 'none',
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: theme.spacing(2, 4, 3),
    maxHeight: '700px',
    minHeight: '500px',
    overflowY: 'auto',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },

  modalhead: {
    fontStyle: 'normal',
    fontWeight: 850,
    fontSize: '20px',
    lineHeight: '25px',
    textAlign: 'left',
    letterSpacing: '0.714286px',
    color: '#000000',
  },
  modalsubhead: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '10px',
    textAlign: 'left',
    letterSpacing: '0.714286px',
    color: 'rgba(0, 0, 0, 0.38)',
    marginTop: '2%',
  },
  close: {
    marginLeft: '95%',
    cursor: 'pointer',
  },
  custom: {
    marginTop: '35%',
    fontSize: '14px',
    fontWeight: 850,
    lineHeight: '24px',
    letterSpacing: '0.71px',
    color: '#00C58A',
    cursor: 'pointer',
  },
  fieldname: {
    fontStyle: 'medium',
    fontWeight: 500,
    lineHeight: '22px',
    fontSize: '16px',
    textAlign: 'left',
    letterSpacing: '0.5px',
    color: '#666666',
    // marginBottom: '4%',
  },
  spacing: {
    marginTop: '4%',
  },
  spacing2: {
    marginTop: '2%',
  },
  formControl: {
    minWidth: 155,
    '& > *': {
      borderRadius: '50px',
      height: '45px',
    },
  },
  // select: {
  //   overflow: 'hidden',
  // },
}));

const AddCustomIntervention = (props) => {
  const classes = useStyles();
  const [updateState, setUpdateState] = React.useState(false);
  const [nameError, setNameError] = useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [triggerFileSave, setTriggerFileSave] = useState(false);
  const [triggerFileDelete, setTriggerFileDelete] = useState(false);
  const [customInterventionId, setCustomInterventionId] = useState();
  const [customInterventionFileNames, setCustomInterventionFileNames] = useState([]);
  const [createOrEdit, setCreateOrEdit] = useState('');
  const [isCreateUpdateDisabled, setIsCreateUpdateDisabled] = useState(false);
  const params = useParams();
  const { userDetails } = useContext(UserContext);

  const initialValues = {
    name: '',
    category: '',
    capex: '',
    totalCapex: '',
    opex: '',
    resourceSavings: '',
    carbonSavings: '',
    description: '',
  };
  const [form, setForm] = useState(Object.assign({}, initialValues));
  const [link, setLink] = useState('');

  const handleOpen = () => {
    setCustomInterventionFileNames([]);
    setOpen(true);
  };

  useEffect(() => {
    if (props.editCustomintervention === true && props.editCustominterventionID) {
      setCustomInterventionFileNames(props.customInterventionFileNames);
      axios.get(`/custom-intervention/${props.editCustominterventionID}`).then((response) => {
        if (response && response.data.data && response.data.data.data) {
          axios.get(`/categorySchemeV2/${response.data.data.data.category}`).then((res) => {
            if (res && res.data.data && res.data.data.data) {
              const responseForm = response.data.data.data;
              setUpdateState(true);
              setLink(responseForm?.link);
              setForm({
                name: responseForm?.name,
                category: res.data.data.data.name,
                capex: responseForm?.capex,
                totalCapex: responseForm?.totalCapex,
                opex: responseForm?.opex,
                resourceSavings: responseForm?.resourceSavings,
                carbonSavings: responseForm?.carbonSavings,
                description: responseForm?.description,
              });
              props.handleOpenBackdrop(false);
              setOpen(true);
              setCustomInterventionId(props.editCustominterventionID);
            }
          });
        }
      });
    }
  }, [props.editCustomintervention, props.editCustominterventionID]);

  const handleUpdateForm = (key, value) => {
    setForm((oldFormValues) => {
      return {
        ...oldFormValues,
        [key]: value,
      };
    });
  };

  const handleResetForm = () => {
    return setForm(Object.assign({}, initialValues));
  };

  const handleClose = () => {
    handleResetForm();
    setNameError(false);
    if (props.editCustomintervention === true && props.editCustominterventionID) {
      props.editModalClose(false);
      setUpdateState(false);
      props.deselectID('');

      setOpen(false);
    } else {
      setOpen(false);
    }
  };

  const handledeleteIntervention = (customInterventionID) => {
    axios
      .delete(`/custom-intervention/${customInterventionID}`)
      .then((res) => {
        if (res && res.data.data) {
          swal({ icon: 'success', title: 'Custom intervention Deleted' });
          props.handleOpenBackdrop(true);
          props.editModalClose(false);
          setUpdateState(false);
          props.deselectID('');
          props.handleRefresh();
          props.handleClose();
        }
      })
      .catch((err) => {
        console.log(err, 'err');
      });
  };

  const handleUpdateCustomintervention = (customInterventionID) => {
    setCreateOrEdit('EDIT');
    setTriggerFileSave(true);
    setIsCreateUpdateDisabled(true);
    setTriggerFileDelete(true);
    const send = {
      projectID: params?.id,
      name: form?.name,
      category: form?.category,
      capex: Number(form?.capex),
      opex: Number(form?.opex),
      resourceSavings: Number(form?.resourceSavings),
      carbonSavings: Number(form?.carbonSavings),
      createdBy: userDetails._id,
      totalCapex: Number(form?.totalCapex),
      description: form?.description,
      link,
    };
    axios
      .put(`/custom-intervention/${customInterventionID}`, send)
      .then((res) => {
        if (res && res.data.data) {
          props.editModalClose(false);
          setUpdateState(false);
          props.deselectID('');
        }
      })
      .catch((err) => {
        console.log(err, 'err');
      });
  };

  const handleCreateIntervention = async () => {
    setCreateOrEdit('CREATE');
    setIsCreateUpdateDisabled(true);
    const body = {
      projectID: params?.id,
      name: form?.name,
      category: form?.category,
      capex: Number(form?.capex),
      opex: Number(form?.opex),
      resourceSavings: Number(form?.resourceSavings),
      carbonSavings: Number(form?.carbonSavings),
      createdBy: userDetails._id,
      totalCapex: Number(form?.totalCapex),
      description: form?.description,
      enabled: true,
      link,
    };
    axios.post('/custom-intervention/validate', body).then((response) => {
      if (response.data && response.data.data && response.data.data.data === false) {
        axios.post('/custom-intervention', body).then((res) => {
          if (res && res.data.data) {
            const customInterventionData = res.data.data.data;
            setCustomInterventionId(customInterventionData._id);
            setTriggerFileSave(true);
          }
        });
      } else {
        swal({ icon: 'error', title: 'Intervention already exists' });
      }
    });
  };

  useEffect(() => {
    setIsCreateUpdateDisabled(
      Object.entries(form)
        .filter(([key]) => key !== 'description')
        .filter(([_, val]) => val.length === 0).length > 0
    );
  }, [form]);

  return (
    <div>
      <Typography variant="h6" className={clsx(classes.custom, { 'add-intervention-summary': true })} onClick={handleOpen} data-tour="custom">
        <AddBoxRoundedIcon style={{ paddingRight: '3px', marginBottom: '-5px', fontSize: '19px' }} />
        ADD INTERVENTION
      </Typography>
      <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <div style={modalStyle} className={classes.paper}>
          <CloseIcon className={classes.close} onClick={handleClose} />
          {updateState && updateState === true ? (
            <Typography className={classes.modalhead}>Update Custom Intervention</Typography>
          ) : (
            <Typography className={classes.modalhead}>Add A New Custom Intervention</Typography>
          )}
          <Typography className={classes.modalsubhead}>All fields are mandatory</Typography>
          <Grid container item xs={12} className={classes.spacing}>
            <Grid item xs={6}>
              <Grid container direction="column" item xs={9}>
                <Grid alignItems="left" item xs={12}>
                  <Typography className={classes.fieldname}>Name</Typography>
                </Grid>
                <Grid container item xs={12} className={classes.spacing2}>
                  <Grid item xs={8} alignItems="left">
                    <Fields
                      disabled={updateState === true ? true : false}
                      placeholder="Enter Name"
                      value={form?.name}
                      onChange={(e) => {
                        if (props.existingCustomInterventionNames.includes(e.target.value.toLowerCase())) {
                          setNameError(true);
                        } else {
                          setNameError(false);
                        }
                        handleUpdateForm('name', e.target.value);
                      }}
                      fullWidth
                      required
                      error={nameError ? true : false}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3} alignItems="right"></Grid>
                </Grid>
                {nameError && (
                  <Typography
                    style={{
                      color: 'rgba(237, 94, 104, 1)',
                      fontSize: '12px',
                      paddingLeft: '10px',
                    }}
                  >
                    Name not unique
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="column" item xs={9}>
                <Grid alignItems="left" item xs={12}>
                  <Typography className={classes.fieldname}>Category</Typography>
                </Grid>
                <Grid container item xs={12} className={classes.spacing2}>
                  <Grid item xs={8} alignItems="left">
                    <FormControl variant="outlined" className={classes.formControl}>
                      <Select
                        className={classes.select}
                        value={form?.category}
                        onChange={(e) => {
                          e.preventDefault();
                          // setCategory(e.target.value);
                          // handleActivatestatus('category', e.target.value);
                          handleUpdateForm('category', e.target.value);
                        }}
                      >
                        <MenuItem value="Energy" name="Energy">
                          Energy
                        </MenuItem>
                        <MenuItem value="Water" name="Water">
                          Water
                        </MenuItem>
                        <MenuItem value="Waste" name="Waste">
                          Waste
                        </MenuItem>
                        <MenuItem value="Materials" name="Materials">
                          Materials
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3} alignItems="right"></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.spacing}>
            <Grid item xs={6}>
              <Grid container direction="column" item xs={9}>
                <Grid alignItems="left" item xs={12}>
                  <Typography className={classes.fieldname}>CAPEX Increase</Typography>
                </Grid>
                <Grid container item xs={12} className={classes.spacing2}>
                  <Grid item xs={8} alignItems="left">
                    <Fields
                      placeholder="Enter Amount"
                      value={form?.capex}
                      onChange={(e) => {
                        e.preventDefault();
                        // setCapex(e.target.value);
                        // handleActivatestatus('capex', e.target.value);
                        handleUpdateForm('capex', e.target.value);
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3} alignItems="right">
                    <Typography variant="h6" style={{ marginTop: '27%' }}>
                      &nbsp; Rs
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="column" item xs={9}>
                <Grid alignItems="left" item xs={12}>
                  <Typography className={classes.fieldname}>Total CAPEX</Typography>
                </Grid>
                <Grid container item xs={12} className={classes.spacing2}>
                  <Grid item xs={8} alignItems="left">
                    <Fields
                      placeholder="Enter Amount"
                      value={form?.totalCapex}
                      onChange={(e) => {
                        e.preventDefault();
                        // setTotalCapex(e.target.value);
                        // handleActivatestatus('totalCapex', e.target.value);
                        handleUpdateForm('totalCapex', e.target.value);
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3} alignItems="right">
                    <Typography variant="h6" style={{ marginTop: '27%' }}>
                      &nbsp; Rs
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.spacing}>
            <Grid item xs={6}>
              <Grid container direction="column" item xs={9}>
                <Grid alignItems="left" item xs={12}>
                  <Typography className={classes.fieldname}>OPEX Savings</Typography>
                </Grid>

                <Grid container item xs={12} className={classes.spacing2}>
                  <Grid item xs={8} alignItems="left">
                    <Fields
                      placeholder="Enter Amount"
                      value={form?.opex}
                      onChange={(e) => {
                        e.preventDefault();
                        // setOpex(e.target.value);
                        // handleActivatestatus('opex', e.target.value);
                        handleUpdateForm('opex', e.target.value);
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3} alignItems="right">
                    <Typography variant="h6" style={{ marginTop: '27%' }}>
                      &nbsp; Rs
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="column" item xs={9}>
                <Grid alignItems="left" item xs={12}>
                  <Typography className={classes.fieldname}>Resource Savings</Typography>
                </Grid>

                <Grid container item xs={12} className={classes.spacing2}>
                  <Grid item xs={8} alignItems="left">
                    <Fields
                      placeholder="Enter Value"
                      value={form?.resourceSavings}
                      onChange={(e) => {
                        e.preventDefault();
                        // setResourcesaving(e.target.value);
                        // handleActivatestatus('resoucesaving', e.target.value);
                        handleUpdateForm('resourceSavings', e.target.value);
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3} alignItems="right"></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.spacing}>
            <Grid item xs={6}>
              <Grid container direction="column" item xs={9}>
                <Grid alignItems="left" item xs={12}>
                  <Typography className={classes.fieldname}>Carbon Savings</Typography>
                </Grid>

                <Grid container item xs={12} className={classes.spacing2}>
                  <Grid item xs={8} alignItems="left">
                    <Fields
                      placeholder="Enter Value"
                      value={form?.carbonSavings}
                      onChange={(e) => {
                        e.preventDefault();
                        // setCarbonSaving(e.target.value);
                        // handleActivatestatus('carbonsaving', e.target.value);
                        handleUpdateForm('carbonSavings', e.target.value);
                      }}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3} alignItems="right">
                    <Typography variant="h6" style={{ marginTop: '27%' }}>
                      &nbsp; Tons
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.spacing}>
            <Grid item xs={12}>
              <Grid container direction="column" item xs={12}>
                {/* <Grid container item xs={12} className={classes.spacing2}> */}
                <Grid item xs={12} alignItems="left">
                  <AttachFileForCustomIntervention
                    setLink={setLink}
                    link={link}
                    updateState={updateState}
                    handleUpdateCustomintervention={handleUpdateCustomintervention}
                    customInterventionFileNames={customInterventionFileNames}
                    customInterventionId={customInterventionId}
                    triggerFileSave={triggerFileSave}
                    triggerFileDelete={triggerFileDelete}
                    handleOpenBackdrop={props.handleOpenBackdrop}
                    handleRefresh={props.handleRefresh}
                    createOrEdit={createOrEdit}
                    setIsCreateUpdateDisabled={setIsCreateUpdateDisabled}
                    setModelOpen={setOpen}
                    style={{ width: '100%', height: 'auto' }}
                  />
                </Grid>
                {/* </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.spacing}>
            <Grid item xs={12}>
              <Grid container direction="column" item xs={12}>
                <Grid alignItems="left" item xs={12}>
                  <Typography className={classes.fieldname}>Intervention Description (optional)</Typography>
                </Grid>
                {/* <Grid container item xs={12} className={classes.spacing2}> */}
                <Grid item xs={12} alignItems="left">
                  <Fields
                    placeholder="Enter Value"
                    value={form?.description}
                    onChange={(e) => {
                      // setDescription(e.target.value);
                      handleUpdateForm('description', e.target.value);
                    }}
                    fullWidth
                    required
                  />
                </Grid>
                {/* </Grid> */}
              </Grid>
            </Grid>
          </Grid>
          <>
            <Grid container alignItems="center" item xs={12} style={{ marginTop: '8%' }}>
              {updateState && updateState === true ? (
                <>
                  <Grid item xs={4} align="left">
                    <Typography
                      variant="h6"
                      style={{ color: '#ED5E68', fontSize: '12px', fontWeight: 900, cursor: 'pointer' }}
                      onClick={() =>
                        swal({
                          title: `Are you sure you want to permanently delete ${form?.name} ?`,
                          icon: 'warning',
                          buttons: true,
                          dangerMode: true,
                        }).then((willDelete) => {
                          if (willDelete) {
                            handledeleteIntervention(props.editCustominterventionID && props.editCustominterventionID);
                          }
                        })
                      }
                    >
                      DELETE INTERVENTION
                    </Typography>
                  </Grid>
                  <Grid item xs={2} />{' '}
                </>
              ) : (
                <Grid item xs={6} align="left" />
              )}
              <Grid item xs={2} align="left">
                <Typography variant="h6" style={{ color: '#1FDEDB', fontSize: '12px', fontWeight: 900, cursor: 'pointer' }} onClick={() => handleClose()}>
                  CANCEL
                </Typography>
              </Grid>
              <Grid item xs={4}>
                {updateState && updateState === true ? (
                  <PrimaryButton
                    children="update intervention"
                    onClick={() => handleUpdateCustomintervention(props.editCustominterventionID && props.editCustominterventionID)}
                    disabled={isCreateUpdateDisabled || nameError || triggerFileSave}
                  />
                ) : (
                  <PrimaryButton
                    children="create intervention"
                    onClick={() => handleCreateIntervention()}
                    disabled={isCreateUpdateDisabled || nameError || triggerFileSave}
                  />
                )}
              </Grid>
            </Grid>
          </>
        </div>
      </Modal>
    </div>
  );
};
export default AddCustomIntervention;
