import React, { useState } from 'react';
import AddBoxIcon from '@material-ui/icons/AddBox';
import uploadicon from '../../../../../assets/manageusers/uploadicon.svg';
import PrimaryButton from '../../../../common/button/Button';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import deleteIconForImage from '../../../../../assets/manageusers/deleteIconForImage.svg';
import imageUnavailable from '../../../../../assets/Image unavailable.png';

const ERROR_MESSAGES = {
  upload: 'Error uploading file',
  size: 'File size exceeds limit',
  type: 'Incompatible file type',
};

export const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/jpg'];
export const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

export const RenderErrorMessage = ({ errorType, resetState }) => (
  <Typography variant="h5" color="error" style={{ marginLeft: 10 }}>
    <b>{ERROR_MESSAGES[errorType]}</b>
    <b
      onClick={resetState}
      style={{
        marginLeft: 10,
        color: '#00C58A',
        textDecoration: 'underline',
        cursor: 'pointer',
      }}
    >
      Try Again
    </b>
  </Typography>
);

export const RenderPreviewImage = ({ isHovered, logo, isLoadingImage }) => (
  <div
    style={{
      width: '100%',
      height: '100%',
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
      opacity: isHovered ? 0.5 : 1,
      transition: 'opacity 0.3s ease',
      textAlign: 'center',
      justify: 'center',
    }}
  >
    {isLoadingImage && <CircularProgress color="inherit" style={{ zIndex: 3, color: '#66E3BE', marginTop: '28%' }} />}
    {!isLoadingImage && <img src={logo || imageUnavailable} alt="Uploaded Preview" style={{ maxWidth: '100%', height: '100%' }} />}
  </div>
);

export const RenderUploadOverlay = ({ isHovered, open, setOpen }) =>
  (isHovered || open) && (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      <Grid item onClick={() => setOpen(true)}>
        <img src={uploadicon} alt="upload icon" style={{ height: '100%', width: '100%' }} />
        <Typography>Upload</Typography>
      </Grid>
    </div>
  );

export const RenderUploadContent = ({
  uploadState,
  handleFileChange,
  resetState,
  setOpen,
  file,
  errorType,
  organisationId,
  publicUrl,
  handleUpdateURL,
  isFetchingOrganisation,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <Typography variant="h5">
        <b>Upload Image</b>
      </Typography>
      <Typography variant="h6" color="textSecondary">
        Supported formats - <b>.JPG, .PNG, .jpeg</b>
      </Typography>
      <Typography variant="h6" style={{ marginTop: '2%' }} color="textSecondary">
        Max file size 5 MB.
      </Typography>

      {uploadState === 'idle' && (
        <label htmlFor="file-input">
          <input type="file" accept={ALLOWED_FILE_TYPES.join(',')} style={{ display: 'none' }} id="file-input" onChange={handleFileChange} />
          <Grid container alignItems="center" justifyContent="flex-start">
            <AddBoxIcon style={{ fontSize: '30px', color: '#00C58A', cursor: 'pointer' }} />
            <Typography variant="h5" style={{ color: '#00C58A', cursor: 'pointer', marginLeft: 10 }}>
              <b>Browse</b>
            </Typography>
          </Grid>
        </label>
      )}

      {uploadState === 'uploading' && (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
          <CircularProgress size={24} />
          <Typography variant="h5" style={{ marginLeft: 10, color: '#00C58A' }}>
            <b>Uploading...</b>
          </Typography>
        </div>
      )}

      {uploadState === 'success' && file && (
        <div style={{ marginTop: 20 }}>
          <Typography>File uploaded successfully!</Typography>
          <Grid item onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} style={{ position: 'relative', width: 160, height: 160 }}>
            <div
              style={{
                borderRadius: '8px',
                opacity: isHovered ? 0.3 : 1,
                transition: 'opacity 0.3s ease',
                textAlign: 'center',
                justify: 'center',
                maxWidth: '100%',
                height: '100%',
              }}
            >
              <img src={URL.createObjectURL(file)} alt="Uploaded Preview" style={{ maxWidth: '100%', height: '100%' }} />
            </div>

            {isHovered && (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                <Grid item container justifyContent="center" alignItems="center">
                  <img src={deleteIconForImage} alt="upload icon" style={{ height: 60, width: 60 }} onClick={resetState} />
                </Grid>
              </div>
            )}
          </Grid>
        </div>
      )}

      {uploadState === 'error' && <RenderErrorMessage errorType={errorType} resetState={resetState} />}

      <Grid container justifyContent="flex-end" style={{ marginTop: '3%', marginBottom: '3%' }}>
        <Grid xs={6} item container justifyContent="flex-end" alignItems="center">
          <Typography
            variant="h6"
            style={{ color: '#00C58A', cursor: 'pointer', marginRight: 60 }}
            onClick={() => {
              setOpen(false);
              resetState();
            }}
          >
            Cancel
          </Typography>
        </Grid>
        <Grid xs={5} item container justifyContent="flex-start" alignItems="center" style={{ paddingRight: 40 }}>
          <PrimaryButton
            children={!isFetchingOrganisation ? 'Save' : 'Saving'}
            disabled={uploadState !== 'success'}
            onClick={() => {
              if (file && uploadState === 'success' && organisationId && publicUrl) {
                handleUpdateURL();
              }
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};
