import UserResponsePage from '../components/views/marketplace/views/productDetails/components/UserResponse';
import userRoles from '../config/roles';
import ForgotPassword from '../views/ForgotPassword';
import ResetPassword from '../views/ResetPassword';

import SignUp from '../views/SignUp';

const publicRouting = [
  {
    path: '/user-response',
    component: UserResponsePage,
    exact: true,
    requiredRoles: userRoles.all,
    isPublic: true,
  },
  {
    path: '/sign-up',
    component: SignUp,
    exact: false,
    requiredRoles: userRoles.all,
    isPublic: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    exact: true,
    requiredRoles: userRoles.all,
    isPublic: true,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    exact: true,
    requiredRoles: userRoles.all,
    isPublic: true,
  },
];

export default publicRouting;

// import Marketplace from '../components/views/marketplace/views/landing/Marketplace';
// import Categories from '../components/views/marketplace/views/categories/Categories';
// import ProductDetails from '../components/views/marketplace/views/productDetails/ProductDetails';
// import CertificationAndSuperCategories from '../components/views/marketplace/views/certification-and-super-categories/CertificationAndSuperCategories';
// import LegalTerms from '../components/views/marketplace/views/terms-and-conditions/Terms-and-conditions';
// import Faq from '../components/views/marketplace/views/faq/Faq';
// import PrivacyPolicy from '../components/views/marketplace/views/privacy-policy/PrivacyPolicy';
// import ResourcesKnowledgeHub from '../components/views/marketplace/views/resources-and-knowledgehub/ResourcesKnowledgeHub';
// import Cement from '../components/views/marketplace/views/resources-and-knowledgehub/material-selection-guide/Cement';
// import Steel from '../components/views/marketplace/views/resources-and-knowledgehub/material-selection-guide/Steel';
// import WallBlocks from '../components/views/marketplace/views/resources-and-knowledgehub/material-selection-guide/WallBlocks';
// import Paint from '../components/views/marketplace/views/resources-and-knowledgehub/material-selection-guide/Paints';
// import Glazing from '../components/views/marketplace/views/resources-and-knowledgehub/material-selection-guide/Glazing';
// import InteriorFlooring from '../components/views/marketplace/views/resources-and-knowledgehub/material-selection-guide/InteriorFlooring';
// import ImagePreview from '../components/views/marketplace/common/ImageCheck';

// {
//   path: '/marketplace/resources/steel',
//   component: Steel,
//   exact: true,
//   requiredRoles: [],
// },
// {
//   path: '/marketplace/image',
//   component: ImagePreview,
//   exact: true,
//   requiredRoles: [],
// },
// {
//   path: '/marketplace/resources/wallblocks',
//   component: WallBlocks,
//   exact: true,
//   requiredRoles: [],
// },
// {
//   path: '/marketplace/resources/cement',
//   component: Cement,
//   exact: true,
//   requiredRoles: [],
// },
// {
//   path: '/marketplace/resources/paints',
//   component: Paint,
//   exact: true,
//   requiredRoles: [],
// },
// {
//   path: '/marketplace/resources/glazing',
//   component: Glazing,
//   exact: true,
//   requiredRoles: [],
// },
// {
//   path: '/marketplace/resources/interiorflooring',
//   component: InteriorFlooring,
//   exact: true,
//   requiredRoles: [],
// },
// {
//   path: '/marketplace/resources',
//   component: ResourcesKnowledgeHub,
//   exact: true,
//   requiredRoles: [],
// },
// {
//   path: '/marketplace/faq',
//   component: Faq,
//   exact: true,
//   requiredRoles: [],
// },
// {
//   path: '/marketplace/privacy-policy',
//   component: PrivacyPolicy,
//   exact: true,
//   requiredRoles: [],
// },
// {
//   path: '/marketplace/categories/:id/products/:iid',
//   component: ProductDetails,
//   exact: true,
//   requiredRoles: [],
// },
// {
//   path: '/marketplace/categories',
//   component: Categories,
//   exact: true,
//   requiredRoles: [],
// },
// {
//   path: '/marketplace/terms-and-conditions',
//   component: LegalTerms,
//   exact: true,
//   requiredRoles: [],
// },
// {
//   path: '/marketplace/categorydetails',
//   component: CertificationAndSuperCategories,
//   exact: true,
//   requiredRoles: [],
// },
// {
//   path: '/marketplace',
//   component: Marketplace,
//   exact: true,
//   requiredRoles: [],
// },
// {
//   path: '/team',
//   component: NewTeam,
//   exact: true,
//   requiredRoles: [],
// },
