import React, { useEffect, useState, useContext } from 'react';
import { Document, Page, StyleSheet, PDFViewer, Font, View, Text } from '@react-pdf/renderer';
import { useParams } from 'react-router';
/*utils & hooks imports*/
import { useAppApiRequest } from '../../../../../hooks/useAppApiRequest';
import { MetafireFeatures } from '../../../../../metafire/utils/types';
import { UserContext } from '../../../../../routing/IndexRouting';
import { getCategoryWiseEnabledAndActivatedInterventionsData, getCurrentDate, getDataById } from './ReportUtils';
import numberWithCommas from '../../../../../utils/numberToComma';
/*components, flow diagrams & pages imports*/
import HeadingPage from './HeadingPage';
import EnvironmentalImpactPage from './EnvironmentalImpactPage';
import CategoryWiseSummaryPage from './CategoryWiseSummaryPage';
import CategoryReportBarGraphPage from './CategoryReportBarGraphPage';
import ImplementInterventionsDetailsPage from './ImplementedInterventionsDetailsPage';
import Footer from './Footer';
import Header from './Header';
import ExecutiveReportPage from './ExecutiveReportPage';
import EnergyFlowDiagramSvg from './EnergyFlowDiagramSvg';
import WaterFlowDiagramSvg from './WaterFlowDiagramSvg';
import WasteFlowDiagramSvg from './WasteFlowDiagramSvg';
import EmbodiedCarbonChart from './EmbodiedCarbonChart';
/*fonts and icons imports*/
import LatoItalic from '../../../../fonts/Lato-Italic.ttf';
import LatoRegular from '../../../../fonts/Lato-Regular.ttf';
import LatoBold from '../../../../fonts/Lato-Bold.ttf';
import EnergyRounded from '../../../../../assets/new-pdf/EnergyRounded.png';
import WaterRounded from '../../../../../assets/new-pdf/WaterRounded.png';
import WasteRounded from '../../../../../assets/new-pdf/WasteRounded.png';
import energyIcon from '../../../../../assets/new-pdf/dummyenrgylogo.png';
import MaterialsRounded from '../../../../../assets/new-pdf/MaterialsRounded.png';
import WaterIcon from '../../../../../assets/new-pdf/WaterIcon.png';
import ProjectSustainabilityReportIcon from '../../../../../assets/ProjectSustainabilityReport.png';

const styles = StyleSheet.create({
  footerMargin: {
    top: 30,
    marginTop: '25%',
  },
  viewPage2: {
    margin: '10px 20px 20px 20px',
    padding: 10,
    flexGrow: 1,
  },
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewer: {
    width: window.innerWidth - 8,
    height: window.innerHeight - 8,
  },
});

Font.register({
  family: 'Lato',
  fonts: [
    {
      src: LatoRegular,
    },
    {
      src: LatoBold,
    },
  ],
});
Font.register({
  family: 'Lato-Italic',
  fonts: [
    {
      src: LatoItalic,
    },
  ],
});
const ProjectSustainabilityReport = () => {
  const { userDetails } = useContext(UserContext);
  const params = useParams();
  const [asset, setAsset] = useState('');
  const [intervention, setIntervention] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [goals, setGoals] = useState(null);
  const [sustainabilityLevel, setSustainabilityLevel] = useState(null);
  const [projectData, setProjectData] = React.useState('');
  const [flowDiagramData, setFlowDiagramData] = useState([]);

  const { data: insightsResponse } = useAppApiRequest(`/metafire?projectId=${params?.id}&feature=${MetafireFeatures.INSIGHTS}&numberOfYears=1`, {
    method: 'GET',
  });
  const { data: projectDataResponse } = useAppApiRequest(`/projectv2/${params?.id}/basics?NumberOfYears=1`, { method: 'GET' });
  const { data: reportResponse } = useAppApiRequest(`/metafire?projectId=${params?.id}&feature=${MetafireFeatures.REPORT}`, { method: 'GET' });
  const { data: flowDiagram } = useAppApiRequest(`/metafire?projectId=${params?.id}&feature=${MetafireFeatures.FLOWDIAGRAMS}`, { method: 'GET' });

  useEffect(() => {
    if (flowDiagram) {
      setFlowDiagramData(flowDiagram?.data?.data?.data);
    }
  }, [flowDiagram]);
  useEffect(() => {
    if (insightsResponse) {
      setIntervention(insightsResponse?.data?.data?.data?.interventions);
      setGoals(insightsResponse?.data?.data?.data?.goalsInsights);
      setSustainabilityLevel(insightsResponse?.data?.data?.data?.interventions?.quantities?.overall?.sustainabilityLevel);
    }
  }, [insightsResponse]);
  useEffect(() => {
    if (reportResponse) {
      setReportData(reportResponse?.data?.data?.data);
    }
  }, [reportResponse]);
  useEffect(() => {
    if (projectDataResponse) {
      setProjectData(projectDataResponse?.data?.data?.data);
    }
  }, [projectDataResponse]);
  const { data: assetReponse } = useAppApiRequest(`/sign-s3/${userDetails?.organisation?.payloadId}`, { method: 'GET' }, [userDetails], !!userDetails);
  useEffect(() => {
    if (assetReponse?.data?.data) {
      setAsset(assetReponse?.data?.data?.data);
    }
  }, [assetReponse]);

  return (
    <PDFViewer style={styles.viewer}>
      <Document title={`Project Sustainability Report ${getCurrentDate()}`}>
        <Page size="A4" style={styles.page}>
          <HeadingPage
            category={'Project'}
            pageIcon={ProjectSustainabilityReportIcon}
            asset={asset}
            reportName={'Project'}
            projectname={projectData && projectData.name}
            date={getCurrentDate()}
            reportHeadingColor="#13C6BD"
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <ExecutiveReportPage
            sustainabilitypercentage={sustainabilityLevel?.value}
            companyname={projectData?.name}
            projectData={projectData}
            interventionData={intervention}
            goals={goals}
            year={getCurrentDate().split('/')[2]}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <EnvironmentalImpactPage
            companyname={projectData && projectData.name}
            energy={intervention?.categorized?.filter((item) => item.name === 'Energy')}
            water={intervention?.categorized?.filter((item) => item.name === 'Water')}
            waste={intervention?.categorized?.filter((item) => item.name === 'Waste')}
            materials={intervention?.categorized?.filter((item) => item.name === 'Materials')}
            year={getCurrentDate().split('/')[2]}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <CategoryReportBarGraphPage
            heading="Energy"
            category="Energy"
            icon={EnergyRounded}
            companyname={projectData && projectData.name}
            capex={intervention?.categorized?.filter((item) => item.name === 'Energy')[0]?.quantities?.finance?.capex?.value}
            opex={intervention?.categorized?.filter((item) => item.name === 'Energy')[0]?.quantities?.finance?.opexSavings?.value}
            totalResourceSavings={intervention?.categorized?.filter((item) => item.name === 'Energy')[0]?.quantities?.resource?.category?.value}
            unit="kWh"
            baselineConsumption={intervention?.categorized?.filter((item) => item.name === 'Energy')[0]?.quantities?.resource?.annualBaselineConsumption?.value}
            year={getCurrentDate().split('/')[2]}
            title1={'Base Case Energy Consumption'}
            title2={'Sustainable Case Energy Consumption'}
            percentageResourceSaving={intervention?.categorized?.filter((item) => item.name === 'Energy')[0]?.quantities?.resource?.categorySavingPercentage}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <CategoryWiseSummaryPage
            companyname={projectData && projectData.name}
            totalResourceSavings={{
              value: intervention?.categorized?.filter((item) => item.name === 'Energy')[0]?.quantities?.resource?.category?.value,
              unit: 'kWh',
            }}
            interventionData={intervention}
            resourceSavingPercentage={
              intervention?.categorized?.filter((item) => item.name === 'Energy')[0]?.quantities?.resource?.categorySavingPercentage?.value
            }
            category="Energy"
            border="1px solid #9E9E9E"
            color="#F28D14"
            categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_energy')}
            headingIcon={EnergyRounded}
            dummyicon={energyIcon}
            year={getCurrentDate().split('/')[2]}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <ImplementInterventionsDetailsPage
            companyname={projectData && projectData.name}
            ProjectinterventionMappingData={{}}
            allVendorMappings={{}}
            allvendors={{}}
            EnabledGrouped={{}}
            border="1px solid #9E9E9E"
            color="#F28D14"
            year={getCurrentDate().split('/')[2]}
            category="Energy"
            categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_energy')}
            numnber={2}
            resourceSavingUnit={'kWh'}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.viewPage2}>
            <Header companyname={projectData && projectData.name} />
            <Text
              style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '14px', textTransform: 'uppercase', marginTop: '20px' }}
            >{`ENERGY FLOW DIAGRAM`}</Text>
            <View>
              <EnergyFlowDiagramSvg
                biogas={`${numberWithCommas(getDataById('energyFlowDiagram_biogasToCommonArea', flowDiagramData))}`}
                mainGrid={`${numberWithCommas(getDataById('energyFlowDiagram_totalMainGrid', flowDiagramData)) || ''}`}
                residentiallighting={`${numberWithCommas(getDataById('energyFlowDiagram_totalResidentialLighting', flowDiagramData)) || ''}`}
                residentialhvac={`${numberWithCommas(getDataById('energyFlowDiagram_totalResidenitalHVAC', flowDiagramData)) || ''}`}
                residentialfan={`${numberWithCommas(getDataById('energyFlowDiagram_totalResidentialFan', flowDiagramData)) || ''}`}
                residentialhotwater={`${numberWithCommas(getDataById('energyFlowDiagram_totalResidentialHotWater', flowDiagramData)) || ''}`}
                homeappliances={`${numberWithCommas(getDataById('energyFlowDiagram_totalHomeAppliances', flowDiagramData)) || ''}`}
                commonarea={`${numberWithCommas(getDataById('energyFlowDiagram_totalCommonArea', flowDiagramData)) || ''}`}
                dieselgenerator={`${numberWithCommas(getDataById('energyFlowDiagram_totalDieselGenerator', flowDiagramData)) || ''}`}
                solarweaterheater={`${numberWithCommas(getDataById('energyFlowDiagram_totalHotWaterManagement', flowDiagramData)) || ''}`}
                solarpv={`${numberWithCommas(getDataById('energyFlowDiagram_totalSolarPV', flowDiagramData))}`}
              />
            </View>
          </View>
          <View style={{ position: 'absolute', left: 20, top: 810, right: 20 }}>
            <Footer year={getCurrentDate().split('/')[2]} />
          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <CategoryReportBarGraphPage
            heading="Water"
            category="Water"
            icon={WaterRounded}
            companyname={projectData && projectData.name}
            capex={intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.finance?.capex?.value}
            opex={intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.finance?.opexSavings?.value}
            totalResourceSavings={intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.resource?.category?.value}
            unit="KL"
            baselineConsumption={intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.resource?.annualBaselineConsumption?.value}
            year={getCurrentDate().split('/')[2]}
            title1={'Base Case Water Consumption'}
            title2={'Sustainable Case Water Consumption'}
            percentageResourceSaving={intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.resource?.categorySavingPercentage}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <CategoryWiseSummaryPage
            companyname={projectData && projectData.name}
            totalResourceSavings={{
              value: intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.resource?.category?.value,
              unit: 'kL',
            }}
            interventionData={intervention}
            resourceSavingPercentage={
              intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.resource?.categorySavingPercentage?.value
            }
            totalLPCDPreSdplus={intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.resource?.totalLPCDPreSdplus?.value}
            totalLPCDPostSdplus={intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.resource?.totalLPCDPostSdplus?.value}
            category="Water"
            border="1px solid #9E9E9E"
            color="#3BC7EE"
            categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_water')}
            headingIcon={WaterRounded}
            dummyicon={WaterIcon}
            year={getCurrentDate().split('/')[2]}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <ImplementInterventionsDetailsPage
            companyname={projectData && projectData.name}
            ProjectinterventionMappingData={{}}
            allVendorMappings={{}}
            allvendors={{}}
            EnabledGrouped={{}}
            border="1px solid #9E9E9E"
            color="#3BC7EE"
            year={getCurrentDate().split('/')[2]}
            category="Water"
            categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_water')}
            numnber={2}
            resourceSavingUnit={'kL'}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.viewPage2}>
            <Header companyname={projectData && projectData.name} />
            <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '14px', textTransform: 'uppercase', marginTop: '20px' }}>{`WATER FLOW DIAGRAM`}</Text>
            <View>
              <WaterFlowDiagramSvg
                stpRO={`${numberWithCommas(getDataById('waterFlowDiagram_totalStpRo', flowDiagramData))}`}
                STP={`${numberWithCommas(getDataById('waterFlowDiagram_totalSTP', flowDiagramData))}`}
                swimmingPool={`${numberWithCommas(getDataById('waterFlowDiagram_totalSwimmingPool', flowDiagramData))}`}
                domesticOther={`${numberWithCommas(getDataById('waterFlowDiagram_totalDomesticOther', flowDiagramData))}`}
                domestiCHealthFaucet={`${numberWithCommas(getDataById('waterFlowDiagram_totalDomesticHealthFaucet', flowDiagramData))}`}
                totalRO={`${numberWithCommas(getDataById('waterFlowDiagram_totalRO', flowDiagramData))}`}
                FreshWater={`${numberWithCommas(getDataById('waterFlowDiagram_totalFreshWater', flowDiagramData))}`}
                WTP={`${numberWithCommas(getDataById('waterFlowDiagram_totalWTP', flowDiagramData))}`}
                CommonAreaUse={`${numberWithCommas(getDataById('waterFlowDiagram_totalCommonAreaUse', flowDiagramData))}`}
                Flushing={`${numberWithCommas(getDataById('waterFlowDiagram_totalFlushing', flowDiagramData))}`}
                Drinking={`${numberWithCommas(getDataById('waterFlowDiagram_totalDrinking', flowDiagramData))}`}
              />
            </View>
          </View>
          <View style={{ position: 'absolute', left: 20, top: 810, right: 20 }}>
            <Footer year={getCurrentDate().split('/')[2]} />
          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <CategoryReportBarGraphPage
            heading="Waste"
            category="Waste"
            icon={WasteRounded}
            companyname={projectData && projectData.name}
            capex={intervention?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.finance?.capex?.value}
            opex={intervention?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.finance?.opexSavings?.value}
            totalResourceSavings={intervention?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.resource?.category?.value}
            unit="Kg"
            baselineConsumption={intervention?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.resource?.annualBaselineConsumption?.value}
            year={getCurrentDate().split('/')[2]}
            title1={'Base Case Waste Generated'}
            title2={'Sustainable Case Waste Generated'}
            percentageResourceSaving={intervention?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.resource?.categorySavingPercentage}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <CategoryWiseSummaryPage
            companyname={projectData && projectData.name}
            totalResourceSavings={{
              value: intervention?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.resource?.category?.value,
              unit: 'kL',
            }}
            interventionData={intervention}
            resourceSavingPercentage={
              intervention?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.resource?.categorySavingPercentage?.value
            }
            totalLPCDPreSdplus={intervention?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.resource?.totalLPCDPreSdplus?.value}
            totalLPCDPostSdplus={intervention?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.resource?.totalLPCDPostSdplus?.value}
            category="Waste"
            border="1px solid #9E9E9E"
            color="#BC8A5F"
            categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_waste')}
            headingIcon={WasteRounded}
            dummyicon={WaterIcon}
            year={getCurrentDate().split('/')[2]}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <ImplementInterventionsDetailsPage
            companyname={projectData && projectData.name}
            ProjectinterventionMappingData={{}}
            allVendorMappings={{}}
            allvendors={{}}
            EnabledGrouped={{}}
            border="1px solid #9E9E9E"
            color="#BC8A5F"
            year={getCurrentDate().split('/')[2]}
            category="Waste"
            categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_waste')}
            numnber={2}
            resourceSavingUnit={'Kg'}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.viewPage2}>
            <Header companyname={projectData && projectData.name} />
            <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '14px', textTransform: 'uppercase', marginTop: '20px' }}>{`WASTE FLOW DIAGRAM`}</Text>
            <View>
              <WasteFlowDiagramSvg
                rejectWaste={`${numberWithCommas(getDataById('wasteFlowDiagram_rejectWasteToLandfill', flowDiagramData))}`}
                dryWaste={`${numberWithCommas(getDataById('wasteFlowDiagram_dryWasteToRecycler', flowDiagramData))}`}
                eWaste={`${numberWithCommas(getDataById('wasteFlowDiagram_eWasteToRecycler', flowDiagramData))}`}
                medicalWaste={`${numberWithCommas(getDataById('wasteFlowDiagram_medicalWasteToOffSiteIncinerator', flowDiagramData))}`}
                landfill={`${numberWithCommas(getDataById('wasteFlowDiagram_totalLandfill', flowDiagramData))}`}
                offSiteIncinerator={`${numberWithCommas(getDataById('wasteFlowDiagram_totalOffSiteIncinerator', flowDiagramData))}`}
                recycler={`${numberWithCommas(getDataById('wasteFlowDiagram_totalRecycler', flowDiagramData))}`}
                organicWaste={`${numberWithCommas(getDataById('wasteFlowDiagram_totalOrganicWaste', flowDiagramData))}`}
                biogas={`${numberWithCommas(getDataById('wasteFlowDiagram_organicWasteToBiogas', flowDiagramData))}`}
                naturalComposting={`${numberWithCommas(getDataById('wasteFlowDiagram_organicWasteToNaturalComposting', flowDiagramData))}`}
              />
            </View>
          </View>
          <View style={{ position: 'absolute', left: 20, top: 810, right: 20 }}>
            <Footer year={getCurrentDate().split('/')[2]} />
          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <CategoryReportBarGraphPage
            heading="Materials"
            category="Materials"
            icon={MaterialsRounded}
            companyname={projectData && projectData.name}
            capex={intervention?.categorized?.filter((item) => item.name === 'Materials')[0]?.quantities?.finance?.capex?.value}
            opex={intervention?.categorized?.filter((item) => item.name === 'Materials')[0]?.quantities?.finance?.opexSavings?.value}
            totalResourceSavings={intervention?.categorized?.filter((item) => item.name === 'Materials')[0]?.quantities?.resource?.category?.value}
            unit="tCO2e"
            baselineConsumption={
              intervention?.categorized?.filter((item) => item.name === 'Materials')[0]?.quantities?.resource?.annualBaselineConsumption?.value
            }
            year={getCurrentDate().split('/')[2]}
            title1={'Base Case Embodied Carbon'}
            title2={'Sustainable Case Embodied Carbon'}
            percentageResourceSaving={intervention?.categorized?.filter((item) => item.name === 'Materials')[0]?.quantities?.resource?.categorySavingPercentage}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <CategoryWiseSummaryPage
            companyname={projectData && projectData.name}
            totalResourceSavings={{
              value: intervention?.categorized?.filter((item) => item.name === 'Materials')[0]?.quantities?.resource?.category?.value,
              unit: 'tCO2e',
            }}
            interventionData={intervention}
            resourceSavingPercentage={
              intervention?.categorized?.filter((item) => item.name === 'Materials')[0]?.quantities?.resource?.categorySavingPercentage?.value
            }
            category="Materials"
            border="1px solid #9E9E9E"
            color="#916AAC"
            categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_materials')}
            headingIcon={MaterialsRounded}
            dummyicon={WaterIcon}
            year={getCurrentDate().split('/')[2]}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <ImplementInterventionsDetailsPage
            companyname={projectData && projectData.name}
            ProjectinterventionMappingData={{}}
            allVendorMappings={{}}
            allvendors={{}}
            EnabledGrouped={{}}
            border="1px solid #9E9E9E"
            color="#916AAC"
            year={getCurrentDate().split('/')[2]}
            category="Materials"
            categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_materials')}
            numnber={2}
            resourceSavingUnit={'tons'}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.viewPage2}>
            <Header companyname={projectData && projectData.name} />
            <Text
              style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '30px', textTransform: 'uppercase', marginTop: '5px', marginBottom: '5px' }}
            >{`EMBODIED CARBON IMPACT`}</Text>
            <View>
              <EmbodiedCarbonChart
                categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_materials')}
                baselineEmbodiedCarbon={
                  intervention?.categorized?.filter((item) => item.name === 'Materials')[0]?.quantities?.resource?.annualBaselineConsumption?.value
                }
              />
            </View>
          </View>
          <View style={{ position: 'absolute', left: 20, top: 810, right: 20 }}>
            <Footer year={getCurrentDate().split('/')[2]} />
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default ProjectSustainabilityReport;
