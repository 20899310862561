import React, { useState } from 'react';
import { Paper, Grid, Typography, Link } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import InquiryNavBar from '../components/common/header/InquiryNavBar';
import CustomButton from '../components/common/button/CustomButton';
import Fields from '../components/common/textfield/Fields';
import api from '../http';
import { isValidEmail } from '../v2/utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 826,
    margin: `10% auto`,
    padding: theme.spacing(2),
  },
  title: {
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: '24px',
    lineHeight: '33px',
    letterSpacing: '1.42857px',
    color: '#000000',
  },
  desc: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '33px',
    letterSpacing: '1.42857px',
    color: 'rgba(0, 0, 0, 0.82)',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '33px',
    letterSpacing: '1.42857px',
    color: 'rgba(0, 0, 0, 0.82)',
  },
}));

function ForgotPassword() {
  let history = useHistory();
  const classes = useStyles();

  const redirectToHome = () => history.push('/');

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [success, setSuccess] = useState(false);

  const handleEmail = (e) => {
    setIsEmailValid(isValidEmail(e.target.value));
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const isValid = email && isValidEmail(email);
      setIsEmailValid(isValid);

      if (isValid) {
        await api.postForgotPassword(email);
        setSuccess(true);
      }
    } catch (error) {
      setIsEmailValid(false);
    }
  };

  return (
    <>
      <InquiryNavBar />
      <Paper className={classes.paper} elevation={0}>
        <Grid container direction="column" wrap="nowrap" spacing={3}>
          <Grid item xs>
            <Typography className={classes.title}>Can’t access your account?</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.desc}>Please enter your registered email address below to recieve password reset link.</Typography>
            <Typography className={classes.desc}>
              You will not be able to reset your password if the provided email addesss does not exist in our database.
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography className={classes.text}>Registered email address</Typography>
            <Fields disabled={success} error={!isEmailValid} placeholder="Type Here" value={email} onChange={handleEmail} fullWidth />
            {!isEmailValid && (
              <Typography className={classes.text} style={{ color: 'red' }}>
                Invalid email address.
              </Typography>
            )}

            {success && (
              <>
                <Typography className={classes.text} style={{ color: '#26E0A3' }}>
                  Reset password email sent sucessfully.
                </Typography>
                <Typography className={classes.text} style={{ color: '#26E0A3' }}>
                  The link will be active for 30 minutes.
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={5}></Grid>

          <Grid container direction="row" alignItems="center" item xs={12}>
            <Grid item xs={3}>
              <CustomButton disabled={!isEmailValid || success} type="submit" variant="contained" onClick={handleSubmit} style={{ borderRadius: '40px' }}>
                Submit
              </CustomButton>
            </Grid>
            <Grid item xs={5} />
            <Grid item xs={3}>
              <CustomButton type="submit" variant="contained" onClick={redirectToHome} style={{ borderRadius: '40px' }}>
                Back
              </CustomButton>
            </Grid>
          </Grid>
          <Typography className={classes.text} style={{ paddingTop: '10%' }}>
            <b>SD+ Support team:</b>
            <span>
              <Link style={{ color: 'blue' }}> connect@sdplus.io</Link>
            </span>{' '}
          </Typography>
        </Grid>
      </Paper>
    </>
  );
}

export default ForgotPassword;
