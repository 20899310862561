import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textHeader1: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '19.2px',
    letterSpacing: '0.71px',
    color: 'rgba(46, 46, 46, 1)',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
  },
  nonselectedRadiobox: { size: '10px', marginRight: '10px', cursor: 'pointer' },
  selectedRadiobox: {
    color: 'rgba(102, 227, 190, 1)',
    fontSize: '15px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  nonselectedCheckbox: {
    color: 'rgba(102, 227, 190, 1)',
    fontSize: '25px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  selectedCheckbox: {
    color: 'rgba(102, 227, 190, 1)',
    fontSize: '25px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  textheader2: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.7px',
    color: 'rgba(46, 46, 46, 1)',
    paddingTop: '10%',
  },
  nonselectedRadiobox: {
    // color: "rgba(102, 227, 190, 1)",
    // fontSize: "25px",
    marginRight: '10px',
    cursor: 'pointer',
  },
  selectedRadiobox: {
    color: 'rgba(102, 227, 190, 1)',
    // fontSize: "25px",
    marginRight: '10px',
    cursor: 'pointer',
  },
  textHeader6: {
    paddingTop: '10px',
    fontFamily: 'Lato',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.71px',
  },
  editText: {
    color: 'rgba(102, 227, 190, 1)',
    fontFamily: "'Lato', sans-serif",
    fontWeight: 700,
    fontSize: '12px',
    letterSpacing: '1px',
  },
  textHeader3: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.71px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '10px',
  },
  textHeader3disabled: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.71px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '10px',
    color: 'rgba(158, 158, 158, 1)',
  },
  textHeader4: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.71px',
    paddingTop: '10px',
    color: 'rgba(102, 102, 102, 1)',
  },
  textconstants: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '32px',
    letterSpacing: '0.71px',
    // paddingTop: "10px",
    color: 'rgba(46, 46, 46, 1)',
  },
  textHeader5: { fontFamily: 'Lato', fontWeight: 500, fontSize: '16px', lineHeight: '19.2px', letterSpacing: '0.71px' },

  nonselectedRadioboxdisabled: {
    color: 'rgba(158, 158, 158, 1)',
    marginRight: '10px',
  },
  boxStyleSelected: {
    width: '79px',
    height: '28px',
    background: '#66E3BE',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 4,
    cursor: 'pointer',
  },
  boxStyleNonselected: {
    width: '79px',
    height: '28px',
    background: '#fffff',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 4,
    border: '1px solid #C6F2CD',
    cursor: 'pointer',
  },
  pillTextSelected: {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '14.4px',
    letterSpacing: '1px',
    textAlign: 'center',
    color: '#FFFFFF',
  },
  pillTextNonSelected: {
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '14.4px',
    letterSpacing: '1px',
    textAlign: 'center',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    color: '#666666',
  },
  mandateText: {
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.71px',
    // textAlign: 'left',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    textAlign: 'justify',
  },
  infoStyle: {
    color: '#F3FCFA',
    background: '#26E0A3',
    borderRadius: '50%',
    border: 'none',
    marginRight: '10px',
    marginTop: '10px',
  },
  mandateBox: {
    border: '2px solid #49DDB1',
    borderRadius: '10px',
    background: '#F3FCFA',
    padding: 4,
    marginTop: 20,
  },
  customTextField: {
    '& .MuiOutlinedInput-root': {
      border: 'none',
      borderRadius: 0,
      borderBottom: '1px solid #FFFFFF',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-input': {
      padding: '4px 5px',
      color: '#FFFFFF',
      fontWeight: 500,
    },
  },
  whiteAdornment: {
    color: '#FFFFFF',
    fontWeight: 500,
  },
  projectInputs: {
    marginLeft: '65px',
  },
  textDisable: {
    color: '#9E9E9E',
  },
  table: {
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    width: '100%', // Full width of the container
    borderCollapse: 'collapse', // Ensure no spacing between cells
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
    paddingLeft: '1%',
  },
  tableHeader: {
    textAlign: 'left', // Align header text to the left
    color: '#000000', // Black text for headers
    fontSize: '16px', // Slightly larger font size for headers
    fontWeight: 'bold', // Bold text for headers
    padding: '12px 8px', // Add padding for spacing
  },
  tableRow: {
    borderBottom: 'none', // Subtle row separator
    height: '56px', // Ensure uniform row height
  },
  tableCell: {
    textAlign: 'left', // Align text to the left
    color: '#7a7a7a', // Slightly dimmed text for cells
    fontSize: '14px', // Smaller font size for cell content
    padding: '12px 8px 8px 12px', // Add padding for spacing
  },
  statusActive: {
    color: '#00c851', // Green color for "Active" status
    fontWeight: 'bold', // Highlight the status text
  },
  deleteIcon: {
    textAlign: 'center',
    fontSize: '20px',
    marginLeft: '5px',
    color: '#bdbdbd', // Light gray color for delete icon
    cursor: 'pointer', // Add pointer cursor for interactivity
    '&:hover': {
      color: '#ff5252', // Red color on hover for delete icon
    },
  },
  icon: {
    marginTop: '8%',
  },
}));

export default useStyles;
