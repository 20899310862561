import React from 'react';
import { TextField, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

const SearchBar = (props) => {
  const useStyles = makeStyles((theme) => ({
    search: {
      marginTop: '8px',
      marginLeft: '0px',
      marginRight: '0px',
      marginBottom: '8px',
      width: props.width ? props.width : '30ch',
      textAlign: 'center',
    },
    searchButton: {
      position: 'absolute',
      right: '0%',
      top: '0%',
      bottom: '0%',
      background: 'rgba(24, 50, 119, 0.05)',
      border: '1px solid #9E9E9E',
      borderRadius: '0px 5px 5px 0px',
      backgroundColor: props.searchButtonbgcolor ? props.searchButtonbgcolor : '',
      color: props.color ? props.color : '',
    },
  }));
  const classes = useStyles();
  return (
    <>
      {props.placeholder === 'Search by project name' ? (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <TextField
            size="small"
            className={classes.search}
            variant="outlined"
            placeholder={props.placeholder}
            onChange={props.onChange}
            style={props.style}
            value={props.value}
          />
          <SearchIcon fontSize="large" style={{ color: '#666666', fontSize: props.fontSize, marginLeft: '2%' }} />
        </div>
      ) : (
        <>
          <TextField
            size="small"
            className={classes.search}
            variant="outlined"
            placeholder={props.placeholder}
            onChange={props.onChange}
            style={props.style}
            value={props.value}
            InputProps={
              !props.IconButton
                ? {
                    endAdornment: (
                      <IconButton className={classes.searchButton} type="submit" aria-label="search" position="end">
                        <SearchIcon fontSize="large" style={{ fontSize: props.fontSize }} />
                      </IconButton>
                    ),
                  }
                : {
                    startAdornment: <SearchIcon fontSize="large" style={{ color: '#33518E', fontSize: props.fontSize }} />,
                  }
            }
          />
        </>
      )}
    </>
  );
};

export default SearchBar;
