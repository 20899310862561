import React from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import UnitAwareTextField from '../../../../common/textfield/UnitAwareTextField';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    height: 'auto',
    marginLeft: '2%',
    marginBottom: '3%',
  },
  highlightedContainer: {
    marginTop: '3%',
  },
  description: {
    lineHeight: '22px',
    letterSpacing: '0.571429px',
    color: 'rgba(0, 0, 0, 0.38)',
    marginBottom: '1%',
  },
}));

function SimplePIPS({ disabled, id, index, field, unit, projectPIP, appendChanged, type, xs }) {
  const classes = useStyles();

  const gridStyle = id === 'project__pi_availableClubhouseRooftopArea' ? `${classes.gridContainer} ${classes.highlightedContainer}` : classes.gridContainer;

  const displayName = `${field?.displayName}${type === 'basic' ? '*' : ''}`;

  return (
    <Grid container direction="row" item xs={12} key={index} className={gridStyle}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ opacity: !disabled ? '1' : '0.5' }}>
          {displayName}
        </Typography>
      </Grid>

      {field?.description && field.description !== 'NA' && (
        <Grid item xs={12} className={classes.description}>
          <Typography variant="h6">{field.description}</Typography>
        </Grid>
      )}

      <UnitAwareTextField disabled={disabled} index={index} unit={unit} field={field} projectPIP={projectPIP} appendChanged={appendChanged} xs={xs} />
    </Grid>
  );
}

export default SimplePIPS;
