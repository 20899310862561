import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import Routes from './routing/IndexRouting';
import { responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import defaultmode from './config/themeconfig';
import { Container } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { GlobalDebug } from './utils/removeConsoles';
import { useAppAnalytics } from './hooks/useAppAnalytics';
import { NotificationProvider } from './v2/components/NotificationProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Enable Material UI Global Theme
let theme = createTheme(defaultmode());
theme = responsiveFontSizes(theme);

// Enable React-Query(v4)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

// Enable Google Analytics 4 / Stream Name: SD+ Stream
ReactGA.initialize('G-4D8NR0YQK0');

function App() {
  const { sendEvent } = useAppAnalytics();

  useEffect(() => {
    /* when running on development to see your console.logs change NODE_ENV === 'development' and  GlobalDebug(true) */
    if (process.env.NODE_ENV === 'development') {
      GlobalDebug(true);
      console.log('I am just another dummy console log, suppose to be suppressed');
    }
    sendEvent({
      eventAction: 'session_start',
    });
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Container maxWidth={false} disableGutters={true}>
          <ThemeProvider theme={theme}>
            <NotificationProvider>
              <Routes />
            </NotificationProvider>
          </ThemeProvider>
        </Container>
      </QueryClientProvider>
    </>
  );
}

export default App;
