import React, { useEffect, useState, useContext } from 'react';
import { Document, Page, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';
import { useParams } from 'react-router';
import { UserContext } from '../../../../../routing/IndexRouting';
import LatoRegular from '../../../../fonts/Lato-Regular.ttf';
import LatoBold from '../../../../fonts/Lato-Bold.ttf';
import HeadingPage from './HeadingPage';
import ExecutiveReportPage from './ExecutiveReportPage';
import EnvironmentalImpactPage from './EnvironmentalImpactPage';

import ExecutiveReportIcon from '../../../../../assets/ExecutiveReportIcon.png';

import { useAppApiRequest } from '../../../../../hooks/useAppApiRequest';
import { MetafireFeatures } from '../../../../../metafire/utils/types';
import { getCurrentDate } from './ReportUtils';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewer: {
    width: window.innerWidth - 8,
    height: window.innerHeight - 8,
  },
});

Font.register({
  family: 'Lato',
  fonts: [
    {
      src: LatoRegular,
    },
    {
      src: LatoBold,
    },
  ],
});

const ExecutiveReport = () => {
  const { userDetails } = useContext(UserContext);
  const params = useParams();
  const [asset, setAsset] = useState('');
  const [intervention, setIntervention] = useState(null);
  const [goals, setGoals] = useState(null);
  const [sustainabilityLevel, setSustainabilityLevel] = useState(null);
  const [projectData, setProjectData] = useState('');

  const { data: insightsResponse } = useAppApiRequest(`/metafire?projectId=${params?.id}&feature=${MetafireFeatures.INSIGHTS}&numberOfYears=1`, {
    method: 'GET',
  });
  const { data: projectDataResponse } = useAppApiRequest(`/projectv2/${params?.id}/basics?NumberOfYears=1`, { method: 'GET' });

  useEffect(() => {
    if (insightsResponse) {
      setIntervention(insightsResponse.data?.data?.data?.interventions);
      setGoals(insightsResponse.data?.data?.data?.goalsInsights);
      setSustainabilityLevel(insightsResponse.data?.data?.data?.interventions?.quantities?.overall?.sustainabilityLevel);
    }
  }, [insightsResponse]);
  useEffect(() => {
    if (projectDataResponse) {
      setProjectData(projectDataResponse?.data?.data?.data);
    }
  }, [projectDataResponse]);

  const { data: assetReponse } = useAppApiRequest(`/sign-s3/${userDetails?.organisation?.payloadId}`, { method: 'GET' }, [userDetails], !!userDetails);
  useEffect(() => {
    if (assetReponse?.data?.data) {
      setAsset(assetReponse?.data?.data?.data);
    }
  }, [assetReponse]);

  return (
    <PDFViewer style={styles.viewer}>
      <Document title={`Executive Report ${getCurrentDate()}`}>
        <Page size="A4" style={styles.page}>
          <HeadingPage
            reportHeadingColor="#13C6BD"
            pageIcon={ExecutiveReportIcon}
            asset={asset}
            reportName={'Executive'}
            projectname={projectData && projectData.name}
            date={getCurrentDate()}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <ExecutiveReportPage
            sustainabilitypercentage={sustainabilityLevel?.value}
            companyname={projectData?.name}
            projectData={projectData}
            interventionData={intervention}
            goals={goals}
            year={getCurrentDate().split('/')[2]}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <EnvironmentalImpactPage
            companyname={projectData && projectData.name}
            energy={intervention?.categorized?.filter((item) => item.name === 'Energy')}
            water={intervention?.categorized?.filter((item) => item.name === 'Water')}
            waste={intervention?.categorized?.filter((item) => item.name === 'Waste')}
            materials={intervention?.categorized?.filter((item) => item.name === 'Materials')}
            year={getCurrentDate().split('/')[2]}
          />
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default ExecutiveReport;
