import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import ResetFields from '../common/textFiledwithReset';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import numberWithCommas from '../../../../../utils/numberToComma';

const NonStructuralCustomAdvancedParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();
  const handleNumericInputChange = (schemaId, value, updateFunction) => {
    const numericValue = parseFloat(value) || 0;
    updateFunction(schemaId, numericValue);
  };
  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      {/* <Grid container item xs={12}> */}
      <Typography className={classes.textHeader1} style={{ paddingTop: '1%' }}>
        Intervention Cost Assumptions
        <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention </Typography>} placement="right" />
      </Typography>
      <Typography className={classes.textHeader4}>CAPEX (per bag)</Typography>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {getQuantityValue(schema.nonStructuralCement__pi_capexMultiplierSource) === 'VENDOR' ? (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => updateQuantityValue(schema.nonStructuralCement__pi_capexMultiplierSource, 'VENDOR')}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  className={classes.nonselectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.nonStructuralCement__pi_capexMultiplierSource, 'VENDOR');
                  }}
                />
              )}
              Selected Vendor
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <Typography className={classes.textconstants}>
                {numberWithCommas(getQuantityValue(schema.nonStructuralCement_vendorCapex))} {getQuantityUnit(schema.nonStructuralCement_vendorCapex)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {getQuantityValue(schema.nonStructuralCement__pi_capexMultiplierSource) === 'CUSTOM' ? (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.nonStructuralCement__pi_capexMultiplierSource, 'CUSTOM');
                  }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  className={classes.nonselectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.nonStructuralCement__pi_capexMultiplierSource, 'CUSTOM');
                  }}
                />
              )}
              Custom
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.nonStructuralCement__pi_customCapex)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.nonStructuralCement__pi_customCapex, e.target.value);
                }}
                fullWidth
                required
                onClick={() =>
                  updateQuantityValue(schema.nonStructuralCement__pi_customCapex, getQuantityDefaultValue(schema.nonStructuralCement__pi_customCapex))
                }
                disabled={getQuantityValue(schema.nonStructuralCement__pi_capexMultiplierSource) !== 'CUSTOM' ? true : false}
                removeResetIcon={true}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography
                variant="h6"
                className={getQuantityValue(schema.nonStructuralCement__pi_capexMultiplierSource) !== 'CUSTOM' ? classes.textDisable : undefined}
              >
                {' '}
                {getQuantityUnit(schema.nonStructuralCement__pi_customCapex)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.nonStructuralCement__pi_opcCapexPerBag)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.nonStructuralCement__pi_opcCapexPerBag)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  handleNumericInputChange(schema.nonStructuralCement__pi_opcCapexPerBag, e.target.value, updateQuantityValue);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.nonStructuralCement__pi_opcCapexPerBag, getQuantityDefaultValue(schema.nonStructuralCement__pi_opcCapexPerBag));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.nonStructuralCement__pi_opcCapexPerBag)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.nonStructuralCement__pi_opcEmbodiedCarbon)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.nonStructuralCement__pi_opcEmbodiedCarbon)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.nonStructuralCement__pi_opcEmbodiedCarbon, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(
                    schema.nonStructuralCement__pi_opcEmbodiedCarbon,
                    getQuantityDefaultValue(schema.nonStructuralCement__pi_opcEmbodiedCarbon)
                  );
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={5} className={classes.grid} style={{ paddingLeft: '5%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.nonStructuralCement__pi_opcEmbodiedCarbon)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* </Grid> */}
    </Grid>
  );
};

export default NonStructuralCustomAdvancedParameters;
