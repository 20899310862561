import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import axios from '../../../../config/Axios';
import PrimaryButton from '../../../common/button/Button';
import { useHistory } from 'react-router-dom';
import { ProjectContext } from '../../../../routing/IndexRouting';
import HeadAndBackIcon from '../../../common/header/HeadAndBackIcon';
import { useParams } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import useApiRequest from '../../../hooks/useApiRequest';
import { UserContext } from '../../../../routing/IndexRouting';
import cookie from 'react-cookies';
import { SomethingChanged } from '../EditProject';
import Addlink from './components/Addlink';
import Addfile from './components/Addfile';
import Addname from './components/Addname';
import TableData from './components/TableData';
import useAppProjectDetails from '../hooks/useAppProjectDetails';

const useStyles = makeStyles((theme) => ({
  Typography: {
    fontWeight: 500,
    marginTop: '3%',
    marginLeft: '3%',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: '600px',
    outline: 'none',
    maxHeight: 600,
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    padding: theme.spacing(2, 2, 2, 3),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  multilineColor: {
    color: 'red',
    borderColor: 'red',
  },
}));

const EditProjectFiles = (props) => {
  let { type, next, name } = props;
  const classes = useStyles();
  let history = useHistory();
  let { id } = useParams();
  const [open, setOpen] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSizeError(false);
    setFileError(false);
    setFileLink('');
    setIsSuccess(false);
    setduplicate(false);
    setSelectedFile('');
    setStartUpload(false);
    setFilename('');
  };

  const [projectFile, setProjectFile] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [filename, setFilename] = useState('');
  const [sizeError, setSizeError] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [filetype, setFileType] = useState('');
  const [startUpload, setStartUpload] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [fileLink, setFileLink] = useState('');
  const [duplicate, setduplicate] = useState(false);

  const { somethingChanged, handlSetSomethingChanged } = useContext(SomethingChanged);
  const { data: projectData } = useAppProjectDetails(id);

  const handleGoBack = (type) => {
    if (type === 'basic') {
      history.push(`/projects`);
    } else {
      history.push(`/projects/${id}/edit?type=basic`);
    }
  };
  const handleUploadClick = (event) => {
    if (filename) {
      projectFile.map((files) => {
        if (files.name.toLowerCase() === filename.toLocaleLowerCase()) {
          setduplicate(true);
          setStartUpload(false);
        }
      });
    }
    setSelectedFile('');
    const file = event.target.files[0];
    if (file) {
      let size = (file.size / 1024 / 1024).toFixed(2);
      let filearr = file.name.split('.');
      let type = filearr[filearr.length - 1];
      setFileType(type);
      if (size > 50) {
        setSizeError(true);
      } else if (
        type !== 'rvt' &&
        type !== 'dwg' &&
        type !== 'skp' &&
        type !== 'doc' &&
        type !== 'docx' &&
        type !== 'xlsx' &&
        type !== 'xls' &&
        type !== 'pdf'
      ) {
        setFileError(true);
      } else if (sizeError === false && fileError === false) {
        setSelectedFile(file);
      }
    }
  };
  const [{ status, response }, makeRequest] = useApiRequest(`/project-asset?project=${id}`, {
    verb: 'get',
  });
  if (status === null) makeRequest();

  useEffect(() => {
    props.toogleShowModal(false);
    handlSetSomethingChanged(false);
    props.setFalseAfterSomethingChanged();
  }, []);

  useEffect(() => {
    if (response && response.data && response.data.data && response.data.data.data) {
      setProjectFile(response.data.data.data);
    }
  }, [response]);
  const { userDetails } = useContext(UserContext);
  const handleSave = () => {
    if (selectedFile && isSuccess === false && duplicate === false) {
      setStartUpload(true);
      let filearr = selectedFile.name.split('.');
      let type = filearr[filearr.length - 1];
      const body = {
        projectID: id,
        createdBy: userDetails && userDetails._id,
        name: filename,
        type: type,
        organisationId: projectData.organisation,
      };
      axios.post(`/project-asset`, body).then((re) => {
        if (re && re.data && re.data.data && re.data.data.data) {
          let url = re.data.data.data.url;
          let intentId = re.data.data.data.intentId;
          let removeheader = delete axios.defaults.headers.common['Authorization'];
          if (removeheader === true) {
            try {
              axios.put(url, selectedFile, {
                headers: {
                  'Content-Type': selectedFile.type,
                },
              });
              axios.post('/project-asset/ackIntent', { IntentID: intentId }).then((resp) => {
                let token = cookie.load('SDPLUS_TOKEN', { path: '/' });
                axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
                setStartUpload(false);
                setIsSuccess(true);
                makeRequest();
                setOpen(false);
                setFilename('');
                setSelectedFile('');
                setFileLink('');
                setFileType('');
                setIsSuccess(false);
                setduplicate(false);
              });
            } catch (err) {
              console.log(err);
              setUploadError(true);
              setStartUpload(false);
            }
          }
        }
      });
    } else if (fileLink && duplicate === false) {
      const body2 = {
        projectID: id,
        createdBy: userDetails && userDetails._id,
        name: filename,
        type: 'url',
        url: fileLink,
      };
      axios
        .post('/project-asset', body2)
        .then((res) => {
          if (res && res.data.data) {
            makeRequest();
            setOpen(false);
            setFilename('');
            setSelectedFile('');
            setFileLink('');
            setduplicate(false);
          }
        })
        .catch((err) => {
          console.log(err, 'err');
        });
    }
  };

  const handleNext = (next) => {
    if (next && next === 'goals') history.push(`/projects/${id}/goals`);
  };

  return (
    <>
      <Grid container direction="column" item xs={12} style={{ marginTop: '3%', marginLeft: '1%' }}>
        <HeadAndBackIcon
          showArrow={false}
          heading={`${name && name} ${projectData ? projectData.name : ''}`}
          handleClick={() => handleGoBack(type && type)}
          visitGoals={true}
          headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
        />
      </Grid>
      <Grid container justify="left" direction="row" item xs={12}>
        <Grid xs={12} item>
          <Typography variant="h5" className={classes.Typography}>
            Upload your project files and links
          </Typography>
        </Grid>
        {projectFile.length < 1 && (
          <Grid xs={10} container item style={{ marginBottom: '5%', marginTop: '3%' }}>
            <Typography variant="h5" color="textSecondary">
              No files uploaded yet
            </Typography>
          </Grid>
        )}

        <Grid xs={12} container item style={{ marginLeft: '3%' }}>
          <AddBoxIcon style={{ color: 'rgb(102, 227, 190)', width: '40px', height: '110%' }} />

          <Typography variant="h5" style={{ color: '#00C58A', cursor: 'pointer' }} onClick={handleOpen}>
            Add new file/link
          </Typography>
        </Grid>
        {/* Table Data */}
        {projectFile.length > 0 ? (
          <TableData projectFile={projectFile && projectFile} makeRequest={() => makeRequest()} userDetails={userDetails && userDetails._id} projectID={id} />
        ) : (
          ''
        )}
      </Grid>

      <Grid container direction="row" alignItems="end" justify="flex-end" item xs={12} style={{ marginBottom: '6%' }}>
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleNext(next && next)} children="next" />
        </Grid>
      </Grid>

      {/* add file modal */}
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Grid container justify="left" direction="row" item xs={12}>
              <Grid xs={12} item>
                <Typography variant="h5">
                  <b>Upload File </b>{' '}
                </Typography>
              </Grid>
              {/* ADD NAME */}
              <Addname
                duplicate={duplicate && duplicate}
                filename={filename && filename}
                setFilename={(v) => setFilename(v)}
                setduplicate={(v) => setduplicate(v)}
              />
              {/* ADD FILE */}
              <Addfile
                uploadError={uploadError && uploadError}
                selectedFile={selectedFile && selectedFile}
                sizeError={sizeError && sizeError}
                fileError={fileError && fileError}
                filename={filename && filename}
                fileLink={fileLink && fileLink}
                startUpload={startUpload && startUpload}
                isSuccess={isSuccess && isSuccess}
                handleUploadClick={handleUploadClick}
                setSizeError={(v) => setSizeError(v)}
                setFileError={(v) => setFileError(v)}
                setUploadError={(v) => setUploadError(v)}
              />
              {/* ADD LINK */}
              <Addlink
                selectedFile={selectedFile && selectedFile}
                filename={filename && filename}
                fileLink={fileLink && fileLink}
                projectFile={projectFile && projectFile}
                setduplicate={(v) => setduplicate(v)}
                setStartUpload={(v) => setStartUpload(v)}
                setFileLink={(v) => setFileLink(v)}
              />
              {/* BUTTON GROUP */}
              <Grid container direction="row" alignItems="end" justify="flex-end" item xs={12} style={{ marginTop: '3%' }}>
                <Grid item xs={2}>
                  <Typography variant="h6" style={{ color: '#00C58A', cursor: 'pointer', marginTop: '8%' }} onClick={handleClose}>
                    Cancel
                  </Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={3}>
                  <PrimaryButton children="Save" onClick={handleSave} />
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default EditProjectFiles;
