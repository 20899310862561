/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Modal, Backdrop, CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import EnergyFlowDiagram from './EnergyFlowDiagram';
import WasteFlowDiagram from './WasteFlowDiagram';
import WaterFlowDiagram from './WaterFlowDiagram';
import MaterialsFlowDiagram from './MaterialsFlowDiagram';

import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';
import { MetafireFeatures } from '../../../../../metafire/utils/types';
import numberWithCommas from '../../../../../utils/numberToComma';
import api, { queryKeys } from '../../../../../http';
import { useQuery } from '@tanstack/react-query';
import LoadingAnimation from '../../../../../v2/components/LoadingAnimation';
import { useAppProductTour } from '../../../../../hooks/useAppProductTour';
import { TOUR_INDEX } from '../../../../../v2/utils/tour';

const FlowDiagrams = ({ isOpen, categoryName, setIsShowFlowDiagram }) => {
  const params = useParams();
  const classes = useStyles();

  const [flowDiagram, setFlowDiagram] = useState(null);
  const [isFetchEnabled, setIsFetchEnabled] = useState(false);

  const { getRoundOffValue } = useAppMetafireUtilities();

  const { state: tourState, setState: setTourState } = useAppProductTour();

  useEffect(() => {
    // This is to prevent the api from being called multiple times
    if (isOpen && !isFetchEnabled) setIsFetchEnabled(true);
  }, [isOpen]);

  const { data: flowDiagramResponse, isLoading: isFlowDiagramFetching } = useQuery({
    queryKey: [queryKeys.metafireFlowDiagrams, params?.id],
    queryFn: () => api.getMetafireRequest({ projectId: params?.id, feature: MetafireFeatures.FLOWDIAGRAMS }),
    enabled: isFetchEnabled,
  });

  useEffect(() => {
    if (flowDiagramResponse) {
      setFlowDiagram(flowDiagramResponse?.data?.data?.data);
    }
  }, [flowDiagramResponse]);

  useEffect(() => {
    if (isOpen) {
      if (tourState.tourActive) {
        // PATCH: To make the tooltip work after modal mount
        if (tourState.stepIndex === TOUR_INDEX.FlowDiagram) {
          setTimeout(() => setTourState({ stepIndex: TOUR_INDEX.FlowDiagram }), 0);
        }

        if (tourState.stepIndex === 12) {
          setIsShowFlowDiagram(false);
        }
      }
    }
  }, [isOpen, tourState.tourActive, tourState.stepIndex]);

  const getDataById = (id) => {
    const item = flowDiagram?.find((item) => item.id === id);
    const value = item ? getRoundOffValue(item.value) : '-';
    return numberWithCommas(value);
  };

  const getMaterialsFlowDiagramData = () => {
    return (flowDiagram || [])?.reduce((acc, item) => {
      if (item.tags.includes('materials') && item.value.enabled && item.value.activated)
        acc.push({ ...item.value, interventionKey: item.id.split('materialsFlowDiagram_')[1] });

      return acc;
    }, []);
  };

  const getEnergyFlowDiagram = () => {
    return (
      <EnergyFlowDiagram
        mainGrid={`${getDataById('energyFlowDiagram_totalMainGrid')}`}
        residentiallighting={`${getDataById('energyFlowDiagram_totalResidentialLighting')}`}
        residentialhvac={`${getDataById('energyFlowDiagram_totalResidenitalHVAC')}`}
        residentialfan={`${getDataById('energyFlowDiagram_totalResidentialFan')}`}
        residentialhotwater={`${getDataById('energyFlowDiagram_totalResidentialHotWater')}`}
        homeappliances={`${getDataById('energyFlowDiagram_totalHomeAppliances')}`}
        commonarea={`${getDataById('energyFlowDiagram_totalCommonArea')}`}
        dieselgenerator={`${getDataById('energyFlowDiagram_totalDieselGenerator')}`}
        solarweaterheater={`${getDataById('energyFlowDiagram_totalHotWaterManagement')}`}
        solarpv={`${getDataById('energyFlowDiagram_totalSolarPV')}`}
        biogas={`${getDataById('energyFlowDiagram_biogasToCommonArea')}`}
      />
    );
  };

  const getWasteFlowDiagram = () => {
    return (
      <WasteFlowDiagram
        rejectWaste={`${getDataById('wasteFlowDiagram_rejectWasteToLandfill')}`}
        dryWaste={`${getDataById('wasteFlowDiagram_dryWasteToRecycler')}`}
        eWaste={`${getDataById('wasteFlowDiagram_eWasteToRecycler')}`}
        medicalWaste={`${getDataById('wasteFlowDiagram_medicalWasteToOffSiteIncinerator')}`}
        landfill={`${getDataById('wasteFlowDiagram_totalLandfill')}`}
        offSiteIncinerator={`${getDataById('wasteFlowDiagram_totalOffSiteIncinerator')}`}
        recycler={`${getDataById('wasteFlowDiagram_totalRecycler')}`}
        organicWaste={`${getDataById('wasteFlowDiagram_totalOrganicWaste')}`}
        biogas={`${getDataById('wasteFlowDiagram_organicWasteToBiogas')}`}
        naturalComposting={`${getDataById('wasteFlowDiagram_organicWasteToNaturalComposting')}`}
      />
    );
  };

  const getWaterFlowDiagram = () => {
    return (
      <WaterFlowDiagram
        stpRO={`${getDataById('waterFlowDiagram_totalStpRo')}`}
        STP={`${getDataById('waterFlowDiagram_totalSTP')}`}
        swimmingPool={`${getDataById('waterFlowDiagram_totalSwimmingPool')}`}
        domesticOther={`${getDataById('waterFlowDiagram_totalDomesticOther')}`}
        domestiCHealthFaucet={`${getDataById('waterFlowDiagram_totalDomesticHealthFaucet')}`}
        totalRO={`${getDataById('waterFlowDiagram_totalRO')}`}
        FreshWater={`${getDataById('waterFlowDiagram_totalFreshWater')}`}
        WTP={`${getDataById('waterFlowDiagram_totalWTP')}`}
        CommonAreaUse={`${getDataById('waterFlowDiagram_totalCommonAreaUse')}`}
        Flushing={`${getDataById('waterFlowDiagram_totalFlushing')}`}
        Drinking={`${getDataById('waterFlowDiagram_totalDrinking')}`}
      />
    );
  };

  const getMaterialsFlowDiagram = () => {
    return (
      <MaterialsFlowDiagram
        categorisedReportData={getMaterialsFlowDiagramData()}
        baselineEmbodiedCarbon={flowDiagram?.find((item) => item.id === 'materialsFlowDiagram_annualBaselineConsumption')?.value}
      />
    );
  };

  const ComponentMapper = {
    Energy: getEnergyFlowDiagram(),
    Waste: getWasteFlowDiagram(),
    Water: getWaterFlowDiagram(),
    Materials: getMaterialsFlowDiagram(),
  };

  return (
    <>
      <LoadingAnimation open={isFlowDiagramFetching && isOpen} />
      <Modal
        disableEnforceFocus
        style={{ zIndex: 200 }}
        open={isOpen}
        onClose={() => setIsShowFlowDiagram(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={{ top: '50%', left: '50%', transform: `translate(-50%, -50%)` }} className={clsx(classes.paper, { 'flow-diagram-modal-summary': true })}>
          <CloseIcon className={classes.close} onClick={() => setIsShowFlowDiagram(false)} />

          {ComponentMapper[categoryName] || 'Oops, Flow diagram not available!'}
        </div>
      </Modal>
    </>
  );
};

export default FlowDiagrams;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    outline: 'none',
    height: 'auto',
    width: 'auto',
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: theme.spacing(2, 4, 2),
  },
  close: {
    marginLeft: '95%',
  },
}));
