import React, { useEffect } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAppProductTour } from '../../../hooks/useAppProductTour';
import { TOUR_INDEX } from '../../../v2/utils/tour';

export const StyledButton = withStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    border: '1px solid ' + theme.palette.grey[400],
    boxSizing: 'border-box',
    borderRadius: '10px',
    minWidth: '150px',
    maxWidth: '105px',
    '&:focus': {
      outline: 'none',
    },
    minHeight: '65px',
    textTransform: 'capitalize',
  },
  selected: {
    border: '1px solid ' + theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.dark + '!important',
    color: theme.palette.common.white + '!important',
  },
}))(ToggleButton);
function getButtons() {
  return [
    'Basic Information',
    'Land Details',
    'Dwelling Unit Details',
    'Common Area Details',
    'Plumbing Details',
    'Material Details',
    'Envelope Details',
    'File Upload',
  ];
}
function getType() {
  return ['basic', 'land', 'dwelling', 'common', 'fixture', 'materials', 'envelope', 'file'];
}

function TabButtonGrp(props) {
  let history = useHistory();
  const types = getType();
  const { state: tourState, setState: setTourState } = useAppProductTour();

  useEffect(() => {
    if (tourState.tourActive) {
      setTourState({ stepIndex: TOUR_INDEX.LandDetals });
    }
  }, []);

  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" item xs={12}>
        {getButtons().map((btn, index) => (
          <Grid item xs key={index}>
            <StyledButton
              value={btn}
              selected={props.activeStep === types[index]}
              onChange={() => {
                if (props.somethingChanged === true) {
                  props.toogleShowModal(true);
                  props.SETNEXT(types[index]);
                } else history.push(`/projects/${props.projectId}/edit?type=${types[index]}`);
              }}
              className={btn.split(' ').join('-')}
            >
              <Typography variant="body1">{btn}</Typography>
            </StyledButton>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default TabButtonGrp;
