import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Fields from '../../../common/textfield/Fields';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginTop: '3vh',
  },
}));

function LocationInput(props) {
  const { open, requiredpips, setOpen, setNewloc, appendChanged, setInputChange, clickedContinue, newloc, inputchange, loading, options, handleSearch } = props;
  const classes = useStyles();

  return (
    <>
      <Grid container justify="left" direction="row" item xs={12} style={{ marginBottom: '1%' }}>
        <Grid item xs={12} className={classes.spacing}>
          <Typography variant="h5">Project Location*</Typography>
        </Grid>
        <Grid item xs={12}>
          {requiredpips &&
            requiredpips.data.map((field, index) => {
              if (field.name === 'location') {
                return (
                  <>
                    <Grid item xs={7} style={{ marginBottom: '1%' }}>
                      <Typography variant="h6" style={{ color: 'rgba(0, 0, 0, 0.38)', lineHeight: '22px', letterSpacing: '0.571429px' }}>
                        {field.description}
                      </Typography>
                    </Grid>
                  </>
                );
              }
            })}
        </Grid>

        <Grid item xs={2}>
          <Autocomplete
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            onChange={(event, value) => {
              setNewloc(value);
              requiredpips &&
                requiredpips.data.map((field, index) => {
                  if (field.name === 'location') {
                    appendChanged(field._id, value.description);
                  }
                });
            }}
            onInputChange={(e, newInputValue) => {
              setInputChange(newInputValue);
            }}
            isOptionEqualToValue={(option, value) => option.description === value.description}
            getOptionLabel={(option) => option.description}
            options={options}
            loading={loading}
            renderInput={(params) => (
              <Fields
                error={clickedContinue === true && (!newloc || !inputchange) ? true : false}
                id="standard-error-helper-text"
                helperText={clickedContinue === true && (!newloc || !inputchange) ? 'Location missing.' : ''}
                placeholder="Location"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {<SearchIcon onClick={handleSearch} style={{ color: '#66E3BE', marginRight: '-67%', fontSize: '40px', cursor: 'pointer' }} />}
                    </React.Fragment>
                  ),
                }}
                fullWidth
                required
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default LocationInput;
