import React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import CustomTextField from '../../../marketplace/views/productDetails/components/CustomTextFeild';
import PrimaryButton from '../../../../common/button/Button';
import api from '../../../../../http/index';
import { useNotification } from '../../../../../v2/components/NotificationProvider';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';

const AddInterventionSetModal = ({ open, handleClose, projectId, interventionId }) => {
  const { setProjectIntervention } = useInterventionDetailsContext();
  const classes = useStyles();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const { notifySuccess, notifyFailure } = useNotification();

  const handleSave = async () => {
    try {
      const response = await api.createInterventionSet({ name, description, projectId, interventionId });
      const updatedProjectIntervention = response?.data?.body?.data;
      setProjectIntervention(updatedProjectIntervention);
      if (response?.success) notifySuccess(`${updatedProjectIntervention?.interventionSets?.length}/4 parameter sets added successfully.`);
    } catch (error) {
      notifyFailure('4/4 parameter sets already added. Please delete a parameter set to add more.');
    }
    handleClose();
  };

  if (!open) return null;

  return (
    <Grid container className={classes.overlay}>
      <Grid item className={classes.paper}>
        <Grid container direction="column" spacing={2}>
          <Grid item container justifyContent="center" alignItems="center">
            <Typography className={classes.title}>Save Parameter Set</Typography>
            <Typography className={classes.description}>Save the current set of parameters to test and compare between different sets.</Typography>
          </Grid>
          <Grid xs={5} item>
            <CustomTextField
              value={name}
              color="rgba(31, 222, 219, 1)"
              height="56px"
              onChange={(e) => {
                setName(e.target.value);
              }}
              error={false}
              disabled={false}
              placeholder="Name"
              helperText={''}
            />
          </Grid>
          <Grid item>
            <CustomTextField
              value={description}
              color="rgba(31, 222, 219, 1)"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              height="15vh"
              disabled={false}
              placeholder="Additional Information (Optional)"
            />
          </Grid>
          <Grid item>
            <Typography className={classes.description}>
              You can save up to 4 parameter sets. After which you will have to remove saved parameter sets to add more.
            </Typography>
          </Grid>
          <Grid item container className={classes.footer}>
            <Typography className={classes.cancelButton} onClick={handleClose}>
              CANCEL
            </Typography>
            <PrimaryButton onClick={handleSave} children="Save" style={{ width: '150px', borderRadius: '40px', height: '30px' }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddInterventionSetModal;

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: '640px',
    heigh: '429px',
    backgroundColor: '#fff',
    borderRadius: '8px',
    padding: theme.spacing(2, 4),
    outline: 'none',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: '10px',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    fontWeight: 600,
    color: '#333',
  },

  inputField: {
    marginBottom: theme.spacing(2),
  },
  description: { fontWeight: 300, fontSize: '12px', color: '#666' },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  cancelButton: {
    color: '#1FDEDB',
    padding: theme.spacing(1, 2),
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '14.4px',
    letterSpacing: '1px',
    textAlign: 'center',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
  },
}));
