import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles';
import { InputBase, InputAdornment, Typography, Grid } from '@material-ui/core';
import ReplaySharpIcon from '@material-ui/icons/ReplaySharp';
import { isValidNumber } from '../../intervention/utils';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 20,
    border: 'rgba(0, 0, 0, 0.38)',
    color: 'black',
    height: 43,
    padding: '13px 10px',
    '&.Mui-focused': {
      boxShadow: `${alpha('rgba(0, 0, 0, 0.38', 0.25)} 0 0 0 0.1rem`,
      borderColor: 'rgba(0, 0, 0, 0.38)',
    },
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.38);',
    },
  },
}));
/*this comp to be used in intervention details page, where error handling is mandatory*/

const ResetFields = ({
  onClick,
  value,
  onChange,
  disabled = false,
  removeResetIcon = false,
  isError = false,
  setExternalValidationError = () => {},
  unit,
  ...props
}) => {
  const classes = useStyles();
  const contextValue = useInterventionDetailsContext();
  const setValidationError = contextValue?.setValidationError || setExternalValidationError;

  const valueInValid = useMemo(() => {
    const invalid = !isValidNumber(value);
    return invalid;
  }, [value]);

  useMemo(() => {
    if (!disabled && valueInValid) {
      setValidationError(true);
    } else {
      setValidationError(false);
    }
  }, [valueInValid, disabled, setValidationError]);

  const getInputStyles = useMemo(
    () => ({
      border: disabled ? '1px solid #9E9E9E' : isError || valueInValid ? '1px solid #FF0000' : '1px solid #2E2E2E',
    }),
    [disabled, isError, valueInValid]
  );

  const getIconStyles = useMemo(
    () => ({
      fontSize: '20px',
      color: disabled ? '#9E9E9E' : '#2E2E2E',
      cursor: 'pointer',
    }),
    [disabled]
  );

  const renderResetIcon = useMemo(() => {
    if (removeResetIcon) return null;

    return (
      <InputAdornment position="end">
        <ReplaySharpIcon style={getIconStyles} onClick={onClick} />
      </InputAdornment>
    );
  }, [removeResetIcon, getIconStyles, onClick]);

  return (
    <Grid container>
      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
        <InputBase
          className={classes.root}
          id="outlined-adornment-weight"
          endAdornment={renderResetIcon}
          value={value}
          onChange={onChange}
          aria-describedby="outlined-weight-helper-text"
          labelWidth={0}
          style={getInputStyles}
          inputProps={{
            style: { color: disabled ? '#9E9E9E' : '#2E2E2E' },
          }}
          disabled={disabled}
          {...props}
        />

        {unit ? <Typography style={{ marginLeft: '2%', color: disabled ? '#9E9E9E' : '#2E2E2E' }}>{unit}</Typography> : null}
      </Grid>

      {!disabled && (isError || valueInValid) ? (
        <Grid item xs={12}>
          <Typography style={{ margin: '5px 0 0 10px', color: 'rgba(237, 94, 104, 1)' }}>Invalid input</Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ResetFields;
