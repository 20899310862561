import React, { useContext, useEffect, useState } from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ResidentHealthAndWellBeingContext } from '../..';
import PrimaryButton from '../../../../../../common/button/Button';
import Fields from '../../../../../../common/textfield/Fields';
import CustomDropDown from '../../common/CustomDropDown';

import { Sections, OpenableType, UnitMap } from '../../DataTypes';
import { GetUnit } from '../../utils';

const useStyles = makeStyles((theme) => ({
  main: {
    paddingBottom: '20%',
    paddingTop: '14%',
  },
  actions: {
    position: 'fixed',
    bottom: 0,
    width: '500px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  form: {
    paddingLeft: '3%',
    paddingRight: '3%',
    paddingBottom: '3%',
  },
}));

const WindowTypesHandler = ({ handleClose, editTable, editableData, setUpdate, update, indexforEdit, mount, setMount }) => {
  const { data, handleData } = useContext(ResidentHealthAndWellBeingContext);
  const classes = useStyles();

  const [name, setName] = useState('');
  const [length, setLength] = useState('');
  const [breadth, setbreadth] = useState('');
  const [openableType, setOpenableType] = useState('');
  const [windowGlazing, setWindowGlazing] = useState('');

  useEffect(() => {
    if (editTable === true && editableData) {
      const { data } = editableData;
      const { name, length, breadth, openableType, windowGlazing } = data;
      setName(name);
      setLength(length);
      setbreadth(breadth);
      setOpenableType(openableType);
      setWindowGlazing(windowGlazing);
    }
  }, [editTable, editableData]);

  const handleSaveData = (data, name, length, breadth, openableType, windowGlazing) => {
    const obj = {};
    obj['name'] = name;
    obj['length'] = length;
    obj['breadth'] = breadth;
    obj['openableType'] = openableType;
    obj['windowGlazing'] = windowGlazing;
    const findData = data?.customData[0]?.data?.find((d) => d.section === Sections.Window_Types)?.data;
    if (findData) data?.customData[0]?.data?.find((d) => d.section === Sections.Window_Types)?.data?.push(obj);
    else handleClose();
    let newData = data;
    handleData(newData);
    handleClose();
    setUpdate(!update);
    setMount(!mount);
  };

  const handleUpdateData = (data, indexforEdit, name, length, breadth, openableType, windowGlazing) => {
    const obj = {};
    obj['name'] = name;
    obj['length'] = length;
    obj['breadth'] = breadth;
    obj['openableType'] = openableType;
    obj['windowGlazing'] = windowGlazing;
    data?.customData[0]?.data?.find((d) => d.section === Sections.Window_Types)?.data?.splice(indexforEdit, 1, obj);
    handleData(data);
    handleClose();
    setUpdate(!update);
    setMount(!mount);
  };

  return (
    <Grid container className={classes.main}>
      <Grid className={classes.form}>
        <Grid container>
          <Grid xs={12} container alignItems="center">
            <Grid>
              <CloseIcon fontSize="large" style={{ cursor: 'pointer' }} onClick={handleClose} />
            </Grid>
            <Grid style={{ paddingLeft: '4%' }}>
              <Typography variant="h5">
                <Typography variant="h5">{!editTable ? <b>Add Window Type</b> : <b>Edit Window Type</b>}</Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container alignItems="center" style={{ marginBottom: '10%' }}>
            <Grid xs={12} container style={{ marginBottom: '5%' }}>
              <Grid xs={12}>
                <Typography variant="h6">Name</Typography>
              </Grid>
              <Grid xs={4} style={{ marginTop: '1%' }}>
                <Fields id="outlined-basic" variant="outlined" fullWidth required value={name} onChange={(e) => setName(e.target.value)} />
              </Grid>
              <Grid xs={12} style={{ marginTop: '1%' }}>
                <Typography variant="h6">Max 20 characters</Typography>
              </Grid>
            </Grid>
            <Grid xs={6} style={{ marginBottom: '5%' }}>
              <Grid xs={12}>
                <Typography variant="h6">Length</Typography>
              </Grid>
              <Grid xs={12} container style={{ marginTop: '2%' }} alignItems="center">
                <Grid xs={9}>
                  <Fields id="outlined-basic" variant="outlined" fullWidth required value={length} onChange={(e) => setLength(e.target.value)} />
                </Grid>
                <Grid xs={3} style={{ paddingLeft: '2%' }}>
                  <Typography variant="h6">{GetUnit(UnitMap, 'length')}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={6} style={{ marginBottom: '5%' }}>
              <Grid xs={12}>
                <Typography variant="h6">Breadth</Typography>
              </Grid>
              <Grid xs={10} container style={{ marginTop: '2%' }} alignItems="center">
                <Grid xs={9}>
                  <Fields id="outlined-basic" variant="outlined" fullWidth required value={breadth} onChange={(e) => setbreadth(e.target.value)} />
                </Grid>
                <Grid xs={3} style={{ paddingLeft: '2%' }}>
                  <Typography variant="h6">{GetUnit(UnitMap, 'breadth')}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={6} style={{ marginBottom: '5%' }}>
              <Grid xs={12}>
                <Typography variant="h6">Openable Type</Typography>
              </Grid>
              <Grid xs={9} style={{ marginTop: '2%' }}>
                <CustomDropDown dataArr={OpenableType} selecteddata={openableType} setSelectedData={(e) => setOpenableType(e)} />
              </Grid>
            </Grid>
            <Grid xs={6} style={{ marginBottom: '5%' }}>
              <Grid xs={12}>
                <Typography variant="h6">Window Glazing vlt %</Typography>
              </Grid>
              <Grid xs={10} container style={{ marginTop: '2%' }} alignItems="center">
                <Grid xs={9} style={{ paddingLeft: '2%' }}>
                  <Fields id="outlined-basic" variant="outlined" fullWidth required value={windowGlazing} onChange={(e) => setWindowGlazing(e.target.value)} />
                </Grid>
                <Grid xs={3} style={{ paddingLeft: '2%' }}>
                  <Typography variant="h6">{GetUnit(UnitMap, 'windowGlazingVLTpercentage')}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Paper className={classes.actions}>
          <Grid item xs={12} container direction="row" alignItems="center" justifyContent="flex-end">
            <Grid item xs={2}>
              <Typography variant="h6" onClick={handleClose} style={{ cursor: 'pointer', color: '#49DDB1' }}>
                Cancel
              </Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={4}>
              {!editTable && <PrimaryButton children="Save" onClick={() => handleSaveData(data, name, length, breadth, openableType, windowGlazing)} />}
              {editTable && (
                <PrimaryButton children="Update" onClick={() => handleUpdateData(data, indexforEdit, name, length, breadth, openableType, windowGlazing)} />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default WindowTypesHandler;
