import { api } from './http';

const getNotesPresignedURL = async (fileName) => {
  const response = await api.get(`/v2/file-upload/notes?name=${fileName}`);
  return response?.data?.data?.body?.data;
};

const getVendorsPresignedURL = async (fileName) => {
  const response = await api.get(`/v2/file-upload/vendors?name=${fileName}`);
  return response?.data?.data?.body?.data;
};

const getPlatformPresignedURL = async (fileName) => {
  const response = await api.get(`/v2/file-upload/platform?name=${fileName}`);
  return response?.data?.data?.body?.data;
};

const getIGBCPresignedURL = async (fileName) => {
  const response = await api.get(`/v2/file-upload/igbc?name=${fileName}`);
  return response?.data?.data?.body?.data;
};

const getOrganisationPresignedURL = async (fileName) => {
  const response = await api.get(`/v2/file-upload/organisation?name=${fileName}`);
  return response?.data?.data?.body?.data;
};

const createStaticAsset = async (payload) => {
  const response = await api.post(`/v1/sign-s3`, payload);
  return response;
};

export { getNotesPresignedURL, getVendorsPresignedURL, getPlatformPresignedURL, getIGBCPresignedURL, getOrganisationPresignedURL, createStaticAsset };
