import { Default, FilterSections, FilterSubSections, NavigatorSource } from '../../../utils/Defaults';

export const getURLStrings = (appliedfilters) => {
  let subcategorystr = '';
  let brandsstr = '';
  let filterstr = '';
  let categoriesstr = '';
  let ecolabelstr = '';
  const filtersubcategory = appliedfilters.filter((ele) => ele.id === FilterSubSections.SubCategory);
  const filterbrands = appliedfilters.filter((ele) => ele.id === FilterSections.Brands);
  const filterfilters = appliedfilters.filter(
    (ele) =>
      ele.id !== FilterSections.Brands &&
      ele.id !== FilterSubSections.SubCategory &&
      ele.id !== NavigatorSource.categories &&
      ele.id !== FilterSections.Contextual
  );
  const filtercontextual = appliedfilters.filter((ele) => ele.id === FilterSections.Contextual && ele.value === 'Hide Shortlisted Products');
  const filtercontextualforshowproduct = appliedfilters.filter((ele) => ele.id === FilterSections.Contextual);

  const categoryfilters = appliedfilters.filter((ele) => ele.id === NavigatorSource.categories);
  const ecolabelfilters = appliedfilters.filter((ele) => ele.id === NavigatorSource.ecolabels);

  filtersubcategory.length !== 0 &&
    filtersubcategory.map((ele, index) => (index > 0 ? (subcategorystr += `,${ele.value}`) : (subcategorystr += `${ele.value}`)));
  filterbrands.length !== 0 && filterbrands.map((ele, index) => (index > 0 ? (brandsstr += `,${ele.value}`) : (brandsstr += `${ele.value}`)));
  filterfilters.length !== 0 && filterfilters.map((ele, index) => (index > 0 ? (filterstr += `,${ele.value}`) : (filterstr += `${ele.value}`)));
  categoryfilters.length !== 0 && categoryfilters.map((ele, index) => (index > 0 ? (categoriesstr += `,${ele.value}`) : (categoriesstr += `${ele.value}`)));
  ecolabelfilters.length !== 0 && ecolabelfilters.map((ele, index) => (index > 0 ? (ecolabelstr += `,${ele.value}`) : (ecolabelstr += `${ele.value}`)));
  // console.log(ecolabelfilters, 'appliedfilters 4');

  return { subcategorystr, brandsstr, filterstr, categoriesstr, ecolabelstr, filtercontextual, filtercontextualforshowproduct };
};

export const constructURlforSourceCategory = (source, category, subcategorystr, brandsstr, filterstr, page, perpageresult, isDefault, sort) => {
  let pagenumber = isDefault ? Default.page : page;
  let perpageresultsize = isDefault ? Default.pagesize : perpageresult;
  let mainURL;
  // console.log(sort, 'sort');
  if (sort === null) {
    mainURL = `/marketplace/categoryDetails?source=${source}&category=${category}&page=${pagenumber}&pagesize=${perpageresultsize}&sort=default`;
  } else {
    mainURL = `/marketplace/categoryDetails?source=${source}&category=${category}&page=${pagenumber}&pagesize=${perpageresultsize}&sort=${sort}`;
  }
  const brandsURL = `&brands=${brandsstr}`;
  const filtersURL = `&filters=${filterstr}`;
  const subcategoryURL = `&subcategory=${subcategorystr}`;

  /*no sub category, yes brand, yes filter*/
  if (!subcategorystr && brandsstr && filterstr) return mainURL + brandsURL + filtersURL;
  /*no sub category, yes brand, no filter*/ else if (!subcategorystr && brandsstr && !filterstr) return mainURL + brandsURL;
  /*no sub category, no brand, yes filter*/ else if (!subcategorystr && !brandsstr && filterstr) return mainURL + filtersURL;
  /*no sub category, no brand, no filter*/ else if (!subcategorystr && !brandsstr && !filterstr) return mainURL;
  /*yes sub category, no brand, no filter*/ else if (subcategorystr && !brandsstr && !filterstr) return mainURL + subcategoryURL;
  /*yes sub category, no brand, yes filter*/ else if (subcategorystr && !brandsstr && filterstr) return mainURL + subcategoryURL + filtersURL;
  /*yes sub category, yes brand, yes filter*/ else if (subcategorystr && brandsstr && filterstr) return mainURL + subcategoryURL + brandsURL + filtersURL;
  /*yes sub category, yes brand, no filter*/ else if (subcategorystr && brandsstr && !filterstr) return mainURL + subcategoryURL + brandsURL;
};

export const constructURLforSourceEcolabels = (source, brandsstr, ecolabelstr, page, pagesize, sort) => {
  // console.log(ecolabelstr, 'appliedfilters');
  // console.log(sort, 'sort');
  let mainURL;
  if (sort === null) {
    mainURL = `/marketplace/categoryDetails?source=${source}&page=${page}&pagesize=${pagesize}&sort=default`;
  } else {
    mainURL = `/marketplace/categoryDetails?source=${source}&page=${page}&pagesize=${pagesize}&sort=${sort}`;
  }
  if (brandsstr) mainURL += `&brands=${brandsstr}`;
  if (ecolabelstr) mainURL += `&filters=${ecolabelstr}`;
  return mainURL;
};

export const constructURLforSourceIntervention = (
  source,
  category,
  subcategorystr,
  brandsstr,
  filterstr,
  page,
  perpageresult,
  isDefault,
  sort,
  mappingid,
  projectId
) => {
  let pagenumber = isDefault ? Default.page : page;
  const defaultPerPageResult = isDefault ? Default.pagesize : perpageresult;
  const sortParam = sort || 'default';

  let mainURL = `/marketplace/categoryDetails?source=${source}&category=${category}&page=${pagenumber}&pagesize=${defaultPerPageResult}&sort=${sortParam}&mappingID=${mappingid}&projectId=${projectId}`;

  if (brandsstr) {
    mainURL += `&brands=${brandsstr}`;
  }

  if (filterstr) {
    mainURL += `&filters=${filterstr}`;
  }

  if (subcategorystr) {
    mainURL += `&subcategory=${subcategorystr}`;
  }

  return mainURL;
};

export const constructURlforSourceSuperCategory = (source, supercategory, subcategorystr, brandsstr, filterstr, categoriesstr, page, perpageresult, sort) => {
  let mainURL;
  if (sort === null) {
    mainURL = `/marketplace/categoryDetails?source=${source}&supercategory=${supercategory}&page=${page}&pagesize=${perpageresult}&sort=default`;
  } else {
    mainURL = `/marketplace/categoryDetails?source=${source}&supercategory=${supercategory}&page=${page}&pagesize=${perpageresult}&sort=${sort}`;
  }
  const categoryURl = `&category=${categoriesstr}`;
  const subcategoryURl = `&subcategory=${subcategorystr}`;
  const brandURl = `&brands=${brandsstr}`;
  const filtersURl = `&filters=${filterstr}`;

  // Category: Yes, Subcategory: Yes, Brands: Yes, Filter: Yes
  if (categoriesstr && subcategorystr && brandsstr && filterstr) return mainURL + categoryURl + subcategoryURl + brandURl + filtersURl;
  // Category: Yes, Subcategory: Yes, Brands: Yes, Filter: No
  else if (categoriesstr && subcategorystr && brandsstr && !filterstr) return mainURL + categoryURl + subcategoryURl + brandURl;
  // Category: Yes, Subcategory: Yes, Brands: No, Filter: Yes
  else if (categoriesstr && subcategorystr && !brandsstr && filterstr) return mainURL + categoryURl + subcategoryURl + filtersURl;
  // Category: Yes, Subcategory: Yes, Brands: No, Filter: No
  else if (categoriesstr && subcategorystr && !brandsstr && !filterstr) return mainURL + categoryURl + subcategoryURl;
  // Category: Yes, Subcategory: No, Brands: Yes, Filter: Yes
  else if (categoriesstr && !subcategorystr && brandsstr && filterstr) return mainURL + categoryURl + brandURl + filtersURl;
  // Category: Yes, Subcategory: No, Brands: Yes, Filter: No
  else if (categoriesstr && !subcategorystr && brandsstr && !filterstr) return mainURL + categoryURl + brandURl;
  // Category: Yes, Subcategory: No, Brands: No, Filter: Yes
  else if (categoriesstr && !subcategorystr && !brandsstr && filterstr) return mainURL + categoryURl + filtersURl;
  // Category: Yes, Subcategory: No, Brands: No, Filter: No
  else if (categoriesstr && !subcategorystr && !brandsstr && !filterstr) return mainURL + categoryURl;
  // Category: No, Subcategory: Yes, Brands: Yes, Filter: Yes
  else if (!categoriesstr && subcategorystr && brandsstr && filterstr) return mainURL + subcategoryURl + brandURl + filtersURl;
  // Category: No, Subcategory: Yes, Brands: Yes, Filter: No
  else if (!categoriesstr && subcategorystr && brandsstr && !filterstr) return mainURL + subcategoryURl + brandURl;
  // Category: No, Subcategory: Yes, Brands: No, Filter: Yes
  else if (!categoriesstr && subcategorystr && !brandsstr && filterstr) return mainURL + subcategoryURl + filtersURl;
  // Category: No, Subcategory: Yes, Brands: No, Filter: No
  else if (!categoriesstr && subcategorystr && !brandsstr && !filterstr) return mainURL + subcategoryURl;
  // Category: No, Subcategory: No, Brands: Yes, Filter: Yes
  else if (!categoriesstr && !subcategorystr && brandsstr && filterstr) return mainURL + brandURl + filtersURl;
  // Category: No, Subcategory: No, Brands: Yes, Filter: No
  else if (!categoriesstr && !subcategorystr && brandsstr && !filterstr) return mainURL + brandURl;
  // Category: No, Subcategory: No, Brands: No, Filter: Yes
  else if (!categoriesstr && !subcategorystr && !brandsstr && filterstr) return mainURL + filtersURl;
  // Category: No, Subcategory: No, Brands: No, Filter: No
  else if (!categoriesstr && !subcategorystr && !brandsstr && !filterstr) return mainURL;
};

export const constructURLforSourceSearch = (source, brandsstr, ecolabelstr, page, pagesize, sort, searchquery) => {
  // console.log(ecolabelstr, 'appliedfilters');
  // console.log(sort, 'sort');
  let mainURL;
  if (sort === null) {
    mainURL = `/marketplace/categoryDetails?source=${source}&page=${page}&pagesize=${pagesize}&searchquery=${searchquery}&sort=default`;
  } else {
    mainURL = `/marketplace/categoryDetails?source=${source}&page=${page}&pagesize=${pagesize}&searchquery=${searchquery}&sort=${sort}`;
  }
  if (brandsstr) mainURL += `&brands=${brandsstr}`;
  if (ecolabelstr) mainURL += `&filters=${ecolabelstr}`;
  return mainURL;
};
