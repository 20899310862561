import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Button, Typography } from '@material-ui/core';
import axios from 'axios';
import swal from 'sweetalert';
import CustomizedTooltips from '../../../common/tooltip/TooltipV2';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {},
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
  tableheader: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.571429px',
    textTransform: 'uppercase',
    color: '#000000',
  },
  delete: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.571429px',
    color: '#ED5E68',
    cursor: 'pointer',
  },
  background: {
    background: 'rgba(24, 50, 119, 0.07)',
  },
  addicon: {
    color: '#42E198',
    fontSize: 'large',
  },
  sectiontext: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.571429px',
    color: '#2E2E2E',
    width: '35%',
  },
  sectiontext2: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.571429px',
    color: '#2E2E2E',
    width: '20%',
  },
  filename: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 350,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.571429px',
    textDecorationLine: 'underline',
    color: 'rgba(0, 0, 0, 0.82)',
  },
  button: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.714286px',
    textTransform: 'uppercase',
    color: '#00C58A',
  },
  tableheading: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.714286px',
    color: '#000000',
    paddingBottom: '15px',
    marginTop: '-10px',
  },
});

export default function DocumentTable({
  creditassets,
  creditdata,
  activeCategoryid,
  setCreditAssets,
  setOpen,
  setSection,
  setPhase,
  creditDetailsForaSingleCredit,
  projectID,
  setCreditId,
  source,
  requestid,
  setPercentage,
}) {
  const classes = useStyles();
  function getAsset(sectioname, creditId, sourcee, requestidd, setPercentagee, activeCategoryidd, setCreditAssetss) {
    let assetinfo = creditassets && creditassets.filter((asset) => asset.section === sectioname && asset.creditId === creditId);
    return (
      <>
        {assetinfo.length > 0 &&
          assetinfo.map((asset) => (
            <StyledTableRow key={asset.fileName}>
              <StyledTableCell
                component="th"
                scope="row"
                className={classes.filename}
                style={{ cursor: 'pointer' }}
                onClick={() => window.open(asset.location.URL)}
              >
                <Typography variant="body1"> {asset.fileName}</Typography>
              </StyledTableCell>
              <StyledTableCell align="left" className={classes.filename}>
                <Typography variant="body1"> {asset.fileType}</Typography>
              </StyledTableCell>
              <StyledTableCell align="left" className={classes.filename}>
                <Typography variant="body1"> {asset.createdAt.slice(0, 10)}</Typography>
              </StyledTableCell>
              <StyledTableCell align="left" className={classes.filename}></StyledTableCell>
              <StyledTableCell
                align="center"
                className={classes.delete}
                onClick={() => {
                  swal({
                    title: `Are you sure you want to delete the document - ${asset.fileName}`,
                    icon: 'warning',
                    buttons: true,
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      axios.delete(`/igbc-credit-asset/${asset._id}`).then((re) => {
                        swal({ icon: 'success', title: 'Document deleted successfully' });
                        axios.get(`/igbc-credit-asset/${projectID}?${sourcee}=${requestidd}`).then((re) => {
                          if (re && re.data && re.data.data && re.data.data.data) {
                            setCreditAssetss(re.data.data.data);
                          }
                        });
                        axios.get(`/igbc-credit-asset/${projectID}/percentage?${sourcee}=${requestidd}`).then((resp) => {
                          if (resp && resp.data && resp.data.data && resp.data.data.data && resp.data.data.data.percentage) {
                            setPercentagee(resp.data.data.data.percentage);
                          } else {
                            setPercentagee(0);
                          }
                        });
                      });
                    }
                  });
                }}
              >
                <Typography variant="body1"> Delete</Typography>
              </StyledTableCell>{' '}
            </StyledTableRow>
          ))}
      </>
    );
  }
  return (
    <>
      {creditDetailsForaSingleCredit ? (
        <>
          <Typography className={classes.tableheading}>{creditDetailsForaSingleCredit && creditDetailsForaSingleCredit.creditName}</Typography>
          {creditDetailsForaSingleCredit &&
            creditDetailsForaSingleCredit.CreditMedadata &&
            creditDetailsForaSingleCredit.CreditMedadata.documentationPhases.map((phase) => (
              <>
                <TableContainer component={Paper} style={{ borderRadius: '10px', border: '1px solid rgba(0, 0, 0, 0.14)', marginBottom: '30px' }}>
                  <Table className={classes.table} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="left" className={classes.tableheader}>
                          <Typography variant="body1">{phase.name}</Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left" className={classes.tableheader}>
                          <Typography variant="body1">Format</Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left" className={classes.tableheader}>
                          <Typography variant="body1"> Date of upload</Typography>
                        </StyledTableCell>
                        <StyledTableCell align="left" className={classes.tableheader}></StyledTableCell>
                        <StyledTableCell align="left" className={classes.tableheader}></StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {phase.sections.map((row) => (
                        <>
                          <StyledTableRow key={row.name} className={classes.background}>
                            <StyledTableCell component="th" scope="row" className={classes.sectiontext}>
                              <Typography variant="body1">
                                {' '}
                                {row.name}{' '}
                                {row && row.metadata && row.metadata[0]?.tooltip && (
                                  <CustomizedTooltips
                                    icon="info"
                                    title={<Typography>{row && row.metadata[0] ? row.metadata[0]?.tooltip : ''}</Typography>}
                                    placement="right"
                                  />
                                )}
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left" className={classes.sectiontext2}>
                              -
                            </StyledTableCell>
                            <StyledTableCell align="left">-</StyledTableCell>
                            <StyledTableCell align="left"></StyledTableCell>
                            <StyledTableCell align="left" style={{ color: '#00C58A' }}>
                              <Button
                                className={classes.button}
                                onClick={() => {
                                  setOpen(true);
                                  setSection(row.name);
                                  setPhase(phase.name);
                                  setCreditId(creditDetailsForaSingleCredit._id);
                                }}
                              >
                                <AddBoxIcon className={classes.addicon} />
                                <Typography variant="body1"> Add new file/link</Typography>
                              </Button>
                            </StyledTableCell>
                          </StyledTableRow>
                          {getAsset(
                            row && row.name,
                            creditDetailsForaSingleCredit._id,
                            source && source,
                            requestid && requestid,
                            setPercentage && setPercentage,
                            activeCategoryid && activeCategoryid,
                            setCreditAssets && setCreditAssets
                          )}
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ))}
        </>
      ) : (
        creditdata &&
        creditdata.map((crd) => {
          return (
            <>
              <Typography className={classes.tableheading}>{crd && crd.creditName}</Typography>
              {crd &&
                crd.CreditMedadata &&
                crd.CreditMedadata.documentationPhases.map((phase) => (
                  <>
                    <TableContainer component={Paper} style={{ borderRadius: '10px', border: '1px solid rgba(0, 0, 0, 0.14)', marginBottom: '30px' }}>
                      <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left" className={classes.tableheader}>
                              <Typography variant="body1"> {phase.name}</Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left" className={classes.tableheader}>
                              <Typography variant="body1">Format</Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left" className={classes.tableheader}>
                              <Typography variant="body1"> Date of upload</Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left" className={classes.tableheader}></StyledTableCell>
                            <StyledTableCell align="left" className={classes.tableheader}></StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {phase.sections.map((row) => (
                            <>
                              <StyledTableRow key={row.name} className={classes.background}>
                                <StyledTableCell component="th" scope="row" className={classes.sectiontext}>
                                  {row.name}{' '}
                                  {row && row.metadata && row.metadata[0]?.tooltip && (
                                    <CustomizedTooltips
                                      icon="info"
                                      title={<Typography>{row.metadata && row.metadata[0]?.tooltip}</Typography>}
                                      placement="right"
                                    />
                                  )}
                                </StyledTableCell>
                                <StyledTableCell align="left" className={classes.sectiontext2}>
                                  -
                                </StyledTableCell>
                                <StyledTableCell align="left">-</StyledTableCell>
                                <StyledTableCell align="left"></StyledTableCell>
                                <StyledTableCell align="left" style={{ color: '#00C58A' }}>
                                  <Button
                                    className={classes.button}
                                    onClick={() => {
                                      setOpen(true);
                                      setSection(row.name);
                                      setPhase(phase.name);
                                      setCreditId(crd._id);
                                    }}
                                  >
                                    <AddBoxIcon className={classes.addicon} />
                                    <Typography variant="body1"> Add new file/link</Typography>
                                  </Button>
                                </StyledTableCell>
                              </StyledTableRow>
                              {getAsset(
                                row && row.name,
                                crd._id,
                                source && source,
                                requestid && requestid,
                                setPercentage && setPercentage,
                                activeCategoryid && activeCategoryid,
                                setCreditAssets && setCreditAssets
                              )}
                            </>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                ))}
            </>
          );
        })
      )}
    </>
  );
}
