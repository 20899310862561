import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  uploadContainer: {
    width: 160,
    height: 160,
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
    },
  },
  icon: {
    fontSize: '36px',
    color: '#64dba2',
    transition: 'opacity 0.3s ease',
  },
  dialogActions: {
    justifyContent: 'center',
  },
  hiddenInput: {
    display: 'none',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: '600px',
    outline: 'none',
    maxHeight: 600,
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    padding: theme.spacing(2, 2, 2, 3),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
}));
