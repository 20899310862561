import React from 'react';
import { Grid, Typography, TableRow, TableContainer, TableCell, TableBody, Table, makeStyles } from '@material-ui/core';

import edit from '../../../../../assets/interventions/materials/edit.svg';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';
const useStyles = makeStyles((theme) => ({
  textHeader1: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '19.2px',
    letterSpacing: '0.71px',
    color: 'rgba(46, 46, 46, 1)',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
  },
  editText: {
    color: 'rgba(106, 227, 190, 1)',
    fontFamily: "'Lato', sans-serif",
    fontWeight: 700,
    fontSize: '12px',
    letterSpacing: '1px',
  },
}));
export default function ProjectInputsTable({ tableData, headerMappings, tableLabel, interventionKey }) {
  const classes = useStyles();
  const headers = headerMappings.map((item) => item.header);

  const getHeaderDataKey = (header) => {
    const mapping = headerMappings.find((item) => item.header === header);
    return mapping ? mapping.key : '';
  };

  const { getRoundOffValue } = useAppMetafireUtilities();

  const { id } = useParams();

  return (
    <>
      {tableData.length > 0 && (
        <Grid container xs={12} style={{ marginBottom: '3%' }}>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center', paddingBottom: '1%' }}>
            <Typography className={classes.textHeader1}>{tableLabel}</Typography>
            {['glazingSgu', 'glazingDgu'].includes(interventionKey) && (
              <Grid
                xs={8}
                style={{ display: 'flex', paddingLeft: '1%', paddingTop: '1%', cursor: 'pointer' }}
                onClick={() => {
                  window.open(`/projects/${id}/edit?type=envelope`);
                }}
              >
                <Typography>
                  <img src={edit} alt="..." style={{}} />
                </Typography>
                <Typography style={{ paddingLeft: '10px' }} className={classes.editText}>
                  Edit Retv Details
                </Typography>
              </Grid>
            )}
          </Grid>
          <TableContainer>
            <Table style={{ width: 800 }} aria-label="simple table">
              <TableBody>
                {/* Render headers */}
                {headers.map((header, headerIndex) => (
                  <TableRow key={headerIndex}>
                    <TableCell width="140" style={{ backgroundColor: '#F7F7F7', color: '#1D1C2B', borderBottomWidth: '0px' }} component="th" scope="row">
                      <Typography style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '14px', lineHeight: '21px', letterSpacing: '0px' }}>
                        {header}
                      </Typography>
                    </TableCell>
                    {/* Render data cells */}
                    {tableData.map((towerData, towerIndex) => (
                      <TableCell
                        align="center"
                        width={1000 / tableData?.length}
                        key={towerIndex}
                        style={{ backgroundColor: '#F7F7F7', borderBottomWidth: '0px', top: '10px' }}
                        component="th"
                        scope="row"
                      >
                        <Typography variant="h6">{getRoundOffValue(towerData[getHeaderDataKey(header)]) || '-'}</Typography>
                      </TableCell>
                    ))}
                    <TableCell align="center" style={{ border: 'none' }}></TableCell> {/* Empty cell for spacing */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
}
