import { useQuery } from '@tanstack/react-query';
import api, { queryKeys } from '../../../../http';

const useAppProjectDetails = (projectId) => {
  const {
    data: mo,
    isError,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: [queryKeys.projectBasics, projectId],
    queryFn: () => api.getProjectBasics(projectId),
    enabled: projectId !== null && projectId !== undefined,
  });
  const projectData = mo?.data?.data?.data;

  return {
    data: projectData,
    isError,
    isFetching,
    isLoading,
  };
};
export default useAppProjectDetails;
