export const LegalQuantityTypes = {
  String: 'string',
  ArrayWithStrings: 'string[]',
  Number: 'number',
  ArrayWithNumbers: 'number[]',
  Boolean: 'boolean',
  ArrayWithBooleans: 'boolean[]',
  UserActualDefault: 'UserActualDefault',
  Donuts: 'Donuts',
  Overview: 'Overview',
  PassiveImpact: 'PassiveImpact',
  Object: 'object',
};

export const MetafireFeatures = {
  INTERVENTIONS: 'INTERVENTIONS',
  INSIGHTS: 'INSIGHTS',
  GOALS: 'GOALS',
  FLOWDIAGRAMS: 'FLOWDIAGRAMS',
  REPORT: 'REPORT',
};

export const MetafireInterventions = {
  // kong interventions
  spvApartment: 'SPV for Apartment',
  hwmVilla: 'Hot Water Management Villa',
  efficientFixturesShower: 'Efficient Fixtures - Showers',
  twr: 'Treated Water Reuse',
  spvVilla: 'SPV for Villa',
  spvPenthouse: 'SPV for Penthouse',
  spvClubhouse: 'SPV for Clubhouse',
  hwmPenthouse: 'Hot Water Management Penthouse',
  hwmClubhouse: 'Hot Water Management Clubhouse',
  efficientFixturesFaucet: 'Efficient Fixtures - Faucet',
  efficientFixturesKitchenSink: 'Efficient Fixtures - Kitchen Faucet',
  efficientFixturesDualFlush: 'Efficient Fixtures - Dual Flush',
  nstp: 'Natural STP',
  hwmApartment: 'Hot Water Management Apartment',
  hwmSwimmingPool: 'Hot Water Management Swimming Pool',
  efficientFixturesHealthFaucet: 'Efficient Fixtures - Health Faucet',
  wwm: 'Wet Waste Management',
  csp: 'Collection & Segregation Point',
  swm: 'Smart Water Meters',
  exteriorPaintsSunny: 'Exterior Paints - Sunny',
  exteriorPaintsNonSunny: 'Exterior Paints - Non-Sunny',
  rrwh: 'Rooftop Rain Water Harvesting',
  srwh: 'Surface Rain Water Harvesting',
  sc: 'Structural Concrete',
  structuralSteel: 'Structural Steel',
  indoorFlooringLnD: 'Indoor Flooring - Living & Dining',
  indoorFlooringBedroom: 'Indoor Flooring - Bedroom',
  indoorFlooringKitchen: 'Indoor Flooring - Kitchen',
  indoorFlooringBathroom: 'Indoor Flooring - Bathroom',
  indoorFlooringUnB: 'Indoor Flooring - Utility and Balcony',
  interiorPaints: 'Interior Paints',
  wallBlockExterior: 'Wall Block - Exterior',
  wallBlockInterior: 'Wall Block - Interior',
  glazingDgu: 'Glazing - DGU',
  glazingSgu: 'Glazing - SGU',
  coolRoof: 'Cool Roof',
  // older interventions
  greyWaterReuse: 'Grey Water Re-use',
  roSystemForDrinkingWater: 'RO system for Drinking Water',
  sewageTreatmentPlant: 'Sewage Treatment Plant',
  fixtureFlowOrFlushRates: 'Fixture Flow/Flush Rates',
  centralizedRO: 'Centralized RO',
  efficientFixtures: 'Efficient Fixtures',
  dualFlush: 'Dual Flush',
  rooftopRWH: 'Rooftop RWH',
  surfaceRWH: 'Surface RWH',
  blackWaterReuse: 'Black Water Re-use',
  solarPV: 'Solar PV',
  solarWaterHeater: 'Solar Water Heater',
  sanitaryNapkinIncinerator: 'Sanitary Napkin Incinerator',
  exteriorPaints: 'Exterior Paints',
  concrete: 'Concrete',
  bedroom: 'Bedroom',
  livingDining: 'Living & Dining',
  bathroom: 'Bathroom',
  biogasPlant: 'Biogas Plant',
  eWasteHandling: 'E-Waste Handling',
  medicalWasteHandling: 'Medical Waste Handling',
  toxicWasteHandling: 'Toxic Waste Handling',
  incineratorBioSanitaryWaste: 'Incinerator - Bio Sanitary Waste',
  recyclingDryWaste: 'Recycling Dry Waste',
  biogasToElectricityGeneration: 'Biogas To Electricity Generation',
};
