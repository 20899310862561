import { InterventionIcons } from '../utils/icons';
import { LegalQuantityTypes } from '../utils/types';

export const useAppMetafireUtilities = () => {
  const defaultRoundOff = 2;
  const allowDecimalsRegex = /^(0*(\.0*[0]*|\.0*)?|[1-9][0-9]*(\.0*)?)$|^(0*[0-9]*\.(0*[0-9]*[0])?)$/;

  const getUiRoundTag = (data = {}) => {
    return data?.tags?.find((tag) => tag.startsWith('uiRound:'));
  };

  const getDecimalPlacesByTag = (roundTag) => {
    if (roundTag) {
      const parts = roundTag.split(':');
      return parseInt(parts[1]);
    }
    return null;
  };

  const getRoundOffValue = (value, roundOff = defaultRoundOff) => {
    //added this to handle the case where string is - 1:4 - Cement Sand
    const trimmedValue = typeof value === 'string' ? value.trim() : value;
    // Strictly check for non-numeric strings and return them directly
    if (typeof trimmedValue === 'string' && Number.isNaN(Number(trimmedValue))) {
      return trimmedValue;
    }

    return allowDecimalsRegex.test(value) || isNaN(parseFloat(value)) || String(value).endsWith('.')
      ? value
      : parseFloat(parseFloat(value).toFixed(roundOff ?? defaultRoundOff));
  };

  const getValue = (data = {}, roundOff = defaultRoundOff) => {
    if (data && Object.keys(data).length !== 0) {
      const roundOffDecimalTag = getUiRoundTag(data);
      const roundOffDecimalValue = getDecimalPlacesByTag(roundOffDecimalTag) ?? roundOff;

      switch (data?.type) {
        case LegalQuantityTypes.Number:
          // A temporary implementation of roundOffs
          return getRoundOffValue(data?.value, roundOffDecimalValue);
        // return isNaN(parseFloat(data?.value)) ? data?.value : parseFloat(data?.value);
        case LegalQuantityTypes.UserActualDefault:
          const selectedValueForUse = data?.value?.actual ?? data?.value?.default;
          // A temporary implementation of roundOffs
          return getRoundOffValue(selectedValueForUse, roundOffDecimalValue);
        case LegalQuantityTypes.String:
          return data?.value ? String(data?.value) : data?.value;
        case LegalQuantityTypes.Boolean:
          return data?.value;
        case LegalQuantityTypes.Object:
          return data?.value;
        default:
          // return data?.value
          // A temporary implementation of roundOffs
          return getRoundOffValue(data?.value, roundOffDecimalValue);
      }
    }
    return '-';
  };

  const getLabel = (data = {}) => {
    if (data && data?.label) {
      return data?.label;
    }
    return '-';
  };

  const getUnit = (data = {}) => {
    if (data && data?.unit) {
      return data?.unit;
    }
    return '';
  };
  const getUserUpdatedValue = (value) => {
    return allowDecimalsRegex.test(value) || isNaN(value) || String(value).endsWith('.') ? value : value === '' ? Number(0) : value === null ? null : value;
  };

  const getIconByFeatureKey = (key, roundIcon) => (roundIcon ? InterventionIcons[key]?.roundIcon || null : InterventionIcons[key]?.squareIcon || null);

  return {
    getValue,
    getLabel,
    getUnit,
    getRoundOffValue,
    getIconByFeatureKey,
    utils: {
      getDecimalPlacesByTag,
      getUiRoundTag,
      getUserUpdatedValue,
      defaultRoundOff,
    },
  };
};
