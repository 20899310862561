/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Box, Typography, Grid, Paper, makeStyles } from '@material-ui/core';

// import components
import SignupForm, { LoadingSpinner } from '../features/auth/SignupForm';

// import assets
import CityIllustration from '../assets/signup/Illustration.svg';

// import util
import { isValidEmail, validatePayload } from '../v2/utils';
import api from '../http';
import InquiryNavBar from '../components/common/header/InquiryNavBar';

// set constants
const ERROR_MESSAGES = {
  alreadyRegistered: 'This email ID is already registered',
  notRegistered: 'This email ID is not registered. Please contact your administrator.',
  accountCreated: 'Account created successfully. Please proceed to login.',
};

const SignUp = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [payload, setPayload] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const [isAccountCreated, setIsAccountCreated] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
    name: '',
    password: '',
    confirmPassword: '',
  });
  const [formError, setFormErrors] = useState({
    email: '',
    name: '',
    password: '',
  });

  const redirectToHome = () => {
    history.push('/');
  };

  const getPayloadFromUrl = () => {
    try {
      const payload = validatePayload(new URLSearchParams(location.search).get('payload'));
      setPayload(payload);
    } catch (error) {
      // TODO: show error message
      redirectToHome();
      return;
    }
  };

  useEffect(() => getPayloadFromUrl(), [location]);

  const updateFormErrors = (field, error) => setFormErrors({ ...formError, [field]: error });

  const onFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (e.target.name === 'email') validateEmail(e.target.value);
    if (e.target.name === 'name') validateName(e.target.value);
    if (e.target.name === 'password') validatePassword(e.target.value);
    if (e.target.name === 'confirmPassword') validateConfirmPassword(e.target.value);
  };

  const validateEmail = (email) => {
    if (!email || !isValidEmail(email)) {
      updateFormErrors('email', 'Please enter a valid email');
      return false;
    }

    updateFormErrors('email', '');
    return true;
  };

  const validateName = (name) => {
    if (!name) {
      updateFormErrors('name', 'Please enter a valid name');
      return false;
    }

    updateFormErrors('name', '');
    return true;
  };

  const validatePassword = (password) => {
    if (!password) {
      updateFormErrors('password', 'Please enter a valid password');
      return false;
    }

    if (password !== formData.confirmPassword) {
      updateFormErrors('password', 'Password does not match');
      return false;
    }

    updateFormErrors('password', '');
    return true;
  };

  const validateConfirmPassword = (confirmPassword) => {
    if (!confirmPassword || confirmPassword !== formData.password) {
      updateFormErrors('password', 'Password does not match');
      return false;
    }

    updateFormErrors('password', '');
    return true;
  };

  const onClickVerifyEmail = async () => {
    const isValid = validateEmail(formData.email);
    if (!isValid) return;

    setDisabled(true);

    try {
      const response = await api.postVerifyInvitationCode(formData.email, payload.invitationCode);
      setIsEmailSubmitted(true);
      if (!response.isValid) {
        updateFormErrors('email', ERROR_MESSAGES.notRegistered);
        return;
      }

      if (response.isAlreadyAccepted) {
        updateFormErrors('email', ERROR_MESSAGES.alreadyRegistered);
        setIsEmailSubmitted(true);
        return;
      }

      setIsEmailValid(true);
    } catch (error) {
      console.error('Error verifying email:', error);
    } finally {
      setDisabled(false);
    }
  };

  const onClickCreateAccount = async () => {
    let isValid = true;

    isValid = validateName(formData.name);
    isValid = validatePassword(formData.password);
    isValid = validateConfirmPassword(formData.confirmPassword);
    if (!isValid) return;

    setDisabled(true);

    try {
      await api.postCreateAccount({ email: formData.email, name: formData.name, invitationCode: payload.invitationCode, password: formData.password });
      setIsAccountCreated(true);
    } catch (error) {
      console.error('Error creating account:', error);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <>
      <InquiryNavBar />
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={0}>
          <LoadingSpinner loading={disabled} />
          <Grid container justifyContent="flex-start" alignItems="flex-start">
            <SignupForm
              isAccountCreated={isAccountCreated}
              isEmailValid={isEmailValid}
              isEmailSubmitted={isEmailSubmitted}
              formData={formData}
              formError={formError}
              onFormChange={onFormChange}
              onClickVerifyEmail={onClickVerifyEmail}
              onClickCreateAccount={onClickCreateAccount}
              redirectToHome={redirectToHome}
              disabled={disabled}
            />
          </Grid>
        </Paper>

        <Box component="img" src={CityIllustration} alt="City Illustration" className={classes.illustration} />
        <Box className={classes.footer}>
          <Typography variant="body1" color="textSecondary">
            Copyright © 2023 SD+. All Rights Reserved
          </Typography>
        </Box>
      </div>
    </>
  );
};

export default SignUp;

const useStyles = makeStyles((theme) => ({
  root: {
    height: '91vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.up('xl')]: {
      height: '95vh',
    },
  },
  paper: {
    padding: theme.spacing(2, 4, 2, 4),
    position: 'relative',
    zIndex: 2,
    width: '480px',
    height: '488px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    backgroundColor: '#fff',
  },
  illustration: {
    position: 'absolute',
    bottom: 30,
    right: 0,
    zIndex: 1,
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      opacity: 0.6,
    },
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    left: 40,
    zIndex: 1,
  },
}));
