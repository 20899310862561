import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import iconforgotoproject from '../../../assets/databaseImages/iconforgotoproject.svg';

const HeadAndBackIcon = ({
  showArrow = true,
  arrowColor = '#66E3BE',
  heading,
  headingVariant = 'h4',
  headingStyle = {},
  visitGoals = false,
  goToInsights = false,
  endIcon = false,
  navigatingText = 'VISIT GOALS',
  somethingChanged = false,
  toogleShowModal,
  SETNEXT,
}) => {
  const history = useHistory();
  const { id } = useParams();

  const handleClick = () => {
    if (history.length > 1) {
      history.goBack();
    }
  };

  const handleVisitGoals = () => {
    history.push(`/projects/${id}/goals`);
  };

  const handleNavigation = () => {
    if (somethingChanged) {
      toogleShowModal(true);
      SETNEXT('insights');
    } else {
      history.push(`/projects/${id}/insights`);
    }
  };

  const visitURLS = {
    'VISIT GOALS': handleVisitGoals,
    'GO TO INSIGHTS': handleNavigation,
  };

  const handleAction = visitURLS[navigatingText] || (() => {});

  return (
    <Grid container alignItems="flex-start" justifyContent="flex-start" item xs={12} style={{ flexGrow: 10 }}>
      {showArrow && (
        <Grid xs={0} item>
          <ArrowBackIosIcon
            onClick={handleClick}
            style={{
              color: arrowColor,
              cursor: 'pointer',
              width: '30.41px',
              height: '100%',
            }}
          />
        </Grid>
      )}
      <Grid item xs={9}>
        <Typography variant={headingVariant} style={headingStyle}>
          {heading}
        </Typography>
      </Grid>
      <Grid item xs={2} container justifyContent="center">
        {endIcon && (
          <Grid item xs={1}>
            <img src={iconforgotoproject} alt="End Icon" />
          </Grid>
        )}
        <Grid item xs={10} style={{ paddingLeft: '3%' }}>
          {(visitGoals || goToInsights) && (
            <Typography
              variant="h6"
              onClick={handleAction}
              style={{
                background: 'transparent',
                color: '#49DDB1',
                cursor: 'pointer',
              }}
            >
              {navigatingText}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HeadAndBackIcon;
