import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import SDLogoNew from '../../../assets/header/SDLogoNew.svg';
import Grid from '@material-ui/core/Grid';
import { Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    textAlign: 'center',
    color: '#000000',
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: '24px',
    lineHeight: '33px',
    letterSpacing: '1.42857px',
  },
  appbar: {
    backgroundColor: '#ffffff',
  },
  toolbar: {
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: '70px',
  },
}));

export default function InquiryNavBar() {
  const classes = useStyles();
  return (
    <>
      <AppBar position="static" className={classes.appbar}>
        <Toolbar>
          <Grid container alignItems="center">
            {/* Logo on the left */}
            <Grid item xs={6} className={classes.logo}>
              <img src={SDLogoNew} alt="SD+" height="52vh" style={{ width: 'auto' }} />
            </Grid>

            {/* Link on the right */}
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <Link href="https://sdplus.io" className={classes.link} underline="none" target="_blank" rel="noopener">
                Visit sdplus.io
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}
