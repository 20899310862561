import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { MarketplaceColors } from '../../../config/index';
import { useState } from 'react';
import { useContext } from 'react';
import { ProductsPageContext } from '../../../../../../routing/IndexRouting';
import { useLocation } from 'react-router-dom';
import {
  getURLStrings,
  constructURlforSourceCategory,
  constructURlforSourceSuperCategory,
  constructURLforSourceEcolabels,
  constructURLforSourceSearch,
  constructURLforSourceIntervention,
} from '../utils/URLParser';
import { useHistory } from 'react-router-dom';
import { NavigatorSource, Default } from '../../../utils/Defaults';
import { useEffect } from 'react';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  onSelectStyle: {
    backgroundColor: MarketplaceColors.CommonWhite,
    color: MarketplaceColors.NonselectedCategory,
    '&:hover': {
      color: MarketplaceColors.NonselectedCategory,
      backgroundColor: MarketplaceColors.CommonWhite,
    },
  },
  menuitemstyle: {
    backgroundColor: MarketplaceColors.CommonWhite,
    color: MarketplaceColors.CommonBlack,
  },
  styledbuttontext: {
    fontSize: '12px',
    fontWeight: 300,
    lineHeight: '20px',
    letterSpacing: '0.5px',
    textAlign: 'center',
  },
}));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&.Mui-selected': {
      backgroundColor: MarketplaceColors.Primary,
      '&:hover': {
        backgroundColor: MarketplaceColors.Primary,
      },
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    width: '110px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledButton = withStyles({
  root: {
    border: '1px solid #A7EFEF',
    background: 'transparent',
    color: MarketplaceColors.TextColorPrimary,
    textTransform: 'lowercase',
    width: '110px',
    '&:hover': {
      color: MarketplaceColors.TextColorPrimary,
      background: 'transparent',
      boxShadow: 'none',
    },
    boxShadow: 'none',
  },
})(Button);

export default function ResultLengthSelector(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const { URLS, handleURL, appliedfilters } = useContext(ProductsPageContext);
  const { pagesize, sort: isSortParam } = URLS;
  const [length, setLength] = useState(Number(pagesize));
  const [sortOrder, setSortOrder] = useState(query.get('sort') || 'default');
  let history = useHistory();

  useEffect(() => {
    setLength(Number(pagesize));
  }, [pagesize]);

  const handleSelect = (value) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    let sort = '';
    let page = 1;
    let pagesize;
    if (value === 'default' || value === 'asc' || value === 'desc') {
      setSortOrder(value === 'asc' ? 'A -> Z' : value === 'desc' ? 'Z -> A' : 'default');
      sort = value;
      pagesize = query.get('pagesize') || 20;
      page = 1;
    } else {
      setLength(value);
      pagesize = value;
      page = query.get('page') || 1;
      sort = isSortParam || 'default';
    }
    let category = query.get('category');
    let subcategory = query.get('subcategory');
    let brands = query.get('brands');
    let supercategory = query.get('supercategory');
    let source = query.get('source');
    let filter = query.get('filters');
    let searchquery = query.get('searchquery');
    let mappingid = query.get('mappingID');
    let productids = query.get('productids');
    let productstatus = query.get('productstatus');
    let projectId = query.get('projectId');
    handleURL(category, subcategory, brands, supercategory, page, pagesize, source, filter, sort, searchquery, mappingid, productids, productstatus, projectId);
    if (source === NavigatorSource.categories) {
      const { subcategorystr, brandsstr, filterstr } = getURLStrings(appliedfilters);
      const url = constructURlforSourceCategory(source, category, subcategorystr, brandsstr, filterstr, page, pagesize, false, sort);
      history.push(url);
    } else if (source === NavigatorSource.supercategories) {
      const { subcategorystr, brandsstr, filterstr, categoriesstr } = getURLStrings(appliedfilters);
      const url = constructURlforSourceSuperCategory(source, supercategory, subcategorystr, brandsstr, filterstr, categoriesstr, page, pagesize, sort);
      history.push(url);
    } else if (URLS.source === NavigatorSource.ecolabels) {
      const { subcategorystr, brandsstr, filterstr, categoriesstr, ecolabelstr } = getURLStrings(appliedfilters);
      const url = constructURLforSourceEcolabels(URLS.source, brandsstr, ecolabelstr, page, pagesize, sort);
      history.push(url);
    } else if (URLS.source === NavigatorSource.search) {
      const { subcategorystr, brandsstr, filterstr, categoriesstr, ecolabelstr } = getURLStrings(appliedfilters);
      const url = constructURLforSourceSearch(URLS.source, brandsstr, ecolabelstr, page, pagesize, sort, searchquery);
      history.push(url);
    } else if (URLS.source === NavigatorSource.intervention) {
      const { subcategorystr, brandsstr, filterstr } = getURLStrings(appliedfilters);
      const url = constructURLforSourceIntervention(source, category, subcategorystr, brandsstr, filterstr, 1, pagesize, false, sort, mappingid, projectId);
      history.push(url);
    }
    handleClose();
  };
  return (
    <>
      {props.title === 'A -> Z' ? (
        <div style={{ textTransform: 'capitalize', display: 'flex', justifyContent: 'flex-end' }}>
          <StyledButton aria-controls="customized-menu" aria-haspopup="true" variant="contained" color="#A7EFEF" onClick={handleClick}>
            {sortOrder === 'default' && (
              <Typography className={classes.styledbuttontext}>
                <span style={{ textTransform: 'uppercase' }}>{sortOrder.slice(0, 1)}</span>
                {sortOrder.slice(1)}
              </Typography>
            )}
            {sortOrder !== 'default' && <span style={{ textTransform: 'uppercase' }}>{sortOrder.slice(0)}</span>}
            <ArrowDropDownIcon style={{ fontSize: 'large', color: '#000000' }} />
          </StyledButton>
          <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem
              className={isSortParam === 'default' || isSortParam === null ? classes.onSelectStyle : classes.menuitemstyle}
              onClickCapture={(e) => handleSelect('default')}
            >
              <Typography className={classes.styledbuttontext}>Default</Typography>
            </MenuItem>
            <MenuItem className={isSortParam === 'asc' ? classes.onSelectStyle : classes.menuitemstyle} onClickCapture={(e) => handleSelect('asc')}>
              <Typography className={classes.styledbuttontext}>{'A -> Z'}</Typography>
            </MenuItem>
            <MenuItem className={isSortParam === 'desc' ? classes.onSelectStyle : classes.menuitemstyle} onClickCapture={(e) => handleSelect('desc')}>
              <Typography className={classes.styledbuttontext}>{'Z -> A'}</Typography>
            </MenuItem>
          </StyledMenu>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <StyledButton aria-controls="customized-menu" aria-haspopup="true" variant="contained" color="#A7EFEF" onClick={handleClick}>
            {length}
            <ArrowDropDownIcon style={{ fontSize: 'large', color: '#000000' }} />
          </StyledButton>
          <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <StyledMenuItem className={length === 20 ? classes.onSelectStyle : classes.menuitemstyle} onClickCapture={(e) => handleSelect(20)}>
              <ListItemText primary="20" />
            </StyledMenuItem>
            <StyledMenuItem className={length === 50 ? classes.onSelectStyle : classes.menuitemstyle} onClickCapture={(e) => handleSelect(50)}>
              <ListItemText primary="50" />
            </StyledMenuItem>
            <StyledMenuItem className={length === 100 ? classes.onSelectStyle : classes.menuitemstyle} onClickCapture={(e) => handleSelect(100)}>
              <ListItemText primary="100" />
            </StyledMenuItem>
          </StyledMenu>
        </div>
      )}
    </>
  );
}
