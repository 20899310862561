/* eslint-disable react-hooks/exhaustive-deps */
// React and Material UI imports
import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';

// Common components
import Header from '../../../../common/header/Header';
import HeadAndBackIcon from '../../../../common/header/HeadAndBackIcon';
import { useStyles } from './Styles';

// Feature components
import StatusComponent from '../status/StatusBasedComponents';
import ManageUsers from '../manageUsers/ManageUsers';
import { BackdropLoader, OrganizationDetails, SearchField, UserManagementHeader } from './Components';

// Hooks and utilities
import api from '../../../../../http';
import { UserContext } from '../../../../../routing/IndexRouting';
import { useAppApiRequest } from '../../../../../hooks/useAppApiRequest';
import EmailInputWithChips from '../inviteByEmail/InviteByEmails';
import { StatusProvider as AdminComponentStatusProvider } from '../../../../../features/providers/StatusProvider';

const mapUserStatus = (user) => {
  if (user.isLinkExpired) return 'Link Expired';
  if (user.status === 'pending') return 'Pending';
  if (user.status === 'accepted') return 'Active';
  return 'Invite Failed';
};

const AdminPage = () => {
  const classes = useStyles();
  const { userDetails } = useContext(UserContext);

  const [searchValue, setSearchValue] = useState('');
  const [selectedUser, setSelectedUser] = useState([]);
  const [organisation, setOrganisation] = useState({});
  const [roles, setRoles] = useState([]);
  const [currentUserData, setCurrentUserData] = useState(null);

  // updation
  const [organisationName, setOrganisationName] = useState('');
  const [numberOfUsersLimit, setNumberOfUsersLimit] = useState(null);

  const {
    data: usersData,
    isFetching: fetchingUsersData,
    refetch: refetchUsers,
  } = useAppApiRequest(() => api.getUsersByOrganisationId(userDetails?.organisation?._id), { method: 'GET' }, [], true);

  const fetchBaseData = async () => {
    const rolesResponse = await api.getRoles();
    setRoles(rolesResponse);
  };

  useEffect(() => {
    if (userDetails) {
      fetchBaseData();
      setCurrentUserData(userDetails);
      setOrganisation(userDetails?.organisation);
      setOrganisationName(userDetails?.organisation?.name);
      setNumberOfUsersLimit(userDetails?.organisation?.numberOfUsers);
    }
  }, [userDetails]);

  const users =
    usersData?.map((user, index) => ({
      index,
      _id: user._id,
      username: user.name,
      email: user.email,
      role: user.role,
      status: mapUserStatus(user),
    })) || [];

  const active = users?.filter((user) => user.status === 'Active').length;
  const activeUsers = `${active}/${numberOfUsersLimit}`;
  const usersLimit = numberOfUsersLimit - users.length;

  return (
    <>
      <AdminComponentStatusProvider>
        <Header />
        <BackdropLoader open={fetchingUsersData} />
        <Grid style={{ marginTop: '8%', paddingLeft: '9%' }}>
          <HeadAndBackIcon heading="Admin" visitGoals={false} endIcon={false} />
        </Grid>
        <Grid container justifyContent="center" direction="column" xs={12} style={{ flexGrow: 10, paddingLeft: '8%', paddingRight: '8%' }}>
          <OrganizationDetails
            organisationId={organisation._id}
            organisationName={organisationName}
            setOrganisationName={(val) => setOrganisationName(val)}
            classes={classes}
          />

          {/* User Manangement */}
          <Grid item xs={12} className={classes.card}>
            <UserManagementHeader
              classes={classes}
              renderInviteComponent={<EmailInputWithChips users={users} organisationId={organisation._id} maxEmails={usersLimit} />}
            />
            <SearchField setSearchValue={setSearchValue} classes={classes} />
            <ManageUsers
              classes={classes}
              roles={roles}
              users={users}
              searchItem={searchValue}
              activeUsers={activeUsers}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              currentUserData={currentUserData}
              handleResendInvite={async (userId) => {
                await api.postResendInvitation(organisation?._id, userId);
              }}
            />

            <Typography style={{ marginTop: 16, textAlign: 'center' }}>Add more users to your project by clicking on the "Invite Users"</Typography>
          </Grid>
        </Grid>
        <StatusComponent roles={roles} userId={selectedUser} organisationId={organisation._id} fetchBaseData={fetchBaseData} fetchUsers={refetchUsers} />
      </AdminComponentStatusProvider>
    </>
  );
};

export default AdminPage;
