import React, { useEffect, useState } from 'react';
import { Typography, Grid, Box, TextField, InputAdornment } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ResetFields from '../common/textFiledwithReset';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import useStyles from './styles';
import InfoIcon from '@material-ui/icons/Info';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@material-ui/icons';
import CustomDropdown from '../common/CustomDropdown';
import { update } from 'lodash';
const GlazingSguCustomBasicParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();
  const [typeOfConstruction, setTypeOfConstruction] = useState('');
  useEffect(() => {
    setTypeOfConstruction(getQuantityValue(schema.nonStructuralCement_typeOfConstruction));
  }, [schema]);

  const handleGlazinFeature = (option) => {
    updateQuantityValue(schema.glazingSgu__pi_glazingFeature, option);
  };
  const handleGlazingColor = (option) => {
    updateQuantityValue(schema.glazingSgu__pi_color, option);
  };

  const glazingFeature = getQuantityValue(schema.glazingSgu__pi_glazingFeature);
  const glazingColor = getQuantityValue(schema.glazingSgu__pi_color);

  return (
    <Grid container direction="row" alignItems="left" item xs={12}>
      <Grid container item xs={12}>
        <Grid container item xs={12}>
          <Grid item xs={4} direction="column">
            <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
              <Typography className={classes.textHeader5}>{getQuantityLabel(schema.glazingSgu__pi_glazingFeature)}</Typography>
            </Grid>

            {glazingFeature === 'Solar Control' ? (
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                <CheckBox className={classes.nonselectedCheckbox} onClick={() => handleGlazinFeature('Solar Control')} />
                <Typography>Solar Control</Typography>
              </Grid>
            ) : (
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => handleGlazinFeature('Solar Control')} />
                <Typography>Solar Control</Typography>
              </Grid>
            )}

            {glazingFeature === 'Acoustic' ? (
              <Grid
                xs={12}
                container
                alignItems="flex-start"
                direction="row"
                style={{ opacity: 0.5, display: 'flex', alignItems: 'center', marginBottom: '5px' }}
              >
                <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => handleGlazinFeature('Solar Control')} />
                <Typography>Acoustic</Typography>
              </Grid>
            ) : (
              <Grid
                xs={12}
                container
                alignItems="flex-start"
                direction="row"
                style={{ opacity: 0.5, display: 'flex', alignItems: 'center', marginBottom: '5px' }}
              >
                <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => handleGlazinFeature('Solar Control')} />
                <Typography>Acoustic</Typography>
              </Grid>
            )}

            {glazingFeature === 'Fire Resistant' ? (
              <Grid
                xs={12}
                container
                alignItems="flex-start"
                direction="row"
                style={{ opacity: 0.5, display: 'flex', alignItems: 'center', marginBottom: '5px' }}
              >
                <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => handleGlazinFeature('Solar Control')} />
                <Typography>Fire Resistant</Typography>
              </Grid>
            ) : (
              <Grid
                xs={12}
                container
                alignItems="flex-start"
                direction="row"
                style={{ opacity: 0.5, display: 'flex', alignItems: 'center', marginBottom: '5px' }}
              >
                <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => handleGlazinFeature('Solar Control')} />
                <Typography>Fire Resistant</Typography>
              </Grid>
            )}

            {glazingFeature === 'Bird Friendly' ? (
              <Grid
                xs={12}
                container
                alignItems="flex-start"
                direction="row"
                style={{ opacity: 0.5, display: 'flex', alignItems: 'center', marginBottom: '5px' }}
              >
                <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => handleGlazinFeature('Solar Control')} />
                <Typography>Bird Friendly</Typography>
              </Grid>
            ) : (
              <Grid
                xs={12}
                container
                alignItems="flex-start"
                direction="row"
                style={{ opacity: 0.5, display: 'flex', alignItems: 'center', marginBottom: '5px' }}
              >
                <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => handleGlazinFeature('Solar Control')} />
                <Typography>Bird Friendly</Typography>
              </Grid>
            )}
            {glazingFeature === 'Smart Glass' ? (
              <Grid
                xs={12}
                container
                alignItems="flex-start"
                direction="row"
                style={{ opacity: 0.5, display: 'flex', alignItems: 'center', marginBottom: '5px' }}
              >
                <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => handleGlazinFeature('Solar Control')} />
                <Typography>Smart Glass</Typography>
              </Grid>
            ) : (
              <Grid
                xs={12}
                container
                alignItems="flex-start"
                direction="row"
                style={{ opacity: 0.5, display: 'flex', alignItems: 'center', marginBottom: '5px' }}
              >
                <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => handleGlazinFeature('Solar Control')} />
                <Typography>Smart Glass</Typography>
              </Grid>
            )}
            {glazingFeature === 'UV Protective' ? (
              <Grid
                xs={12}
                container
                alignItems="flex-start"
                direction="row"
                style={{ opacity: 0.5, display: 'flex', alignItems: 'center', marginBottom: '5px' }}
              >
                <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => handleGlazinFeature('Solar Control')} />
                <Typography>UV Protective</Typography>
              </Grid>
            ) : (
              <Grid
                xs={12}
                container
                alignItems="flex-start"
                direction="row"
                style={{ opacity: 0.5, display: 'flex', alignItems: 'center', marginBottom: '5px' }}
              >
                <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => handleGlazinFeature('Solar Control')} />
                <Typography>UV Protective</Typography>
              </Grid>
            )}
            {glazingFeature === 'Low-E' ? (
              <Grid
                xs={12}
                container
                alignItems="flex-start"
                direction="row"
                style={{ opacity: 0.5, display: 'flex', alignItems: 'center', marginBottom: '5px' }}
              >
                <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => handleGlazinFeature('Solar Control')} />
                <Typography>Low-E</Typography>
              </Grid>
            ) : (
              <Grid
                xs={12}
                container
                alignItems="flex-start"
                direction="row"
                style={{ opacity: 0.5, display: 'flex', alignItems: 'center', marginBottom: '5px' }}
              >
                <CheckBoxOutlineBlankOutlined className={classes.nonselectedCheckbox} onClick={() => handleGlazinFeature('Solar Control')} />
                <Typography>Low-E</Typography>
              </Grid>
            )}
          </Grid>
          <Grid item xs={4} direction="column">
            <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
              <Typography className={classes.textHeader5}>{getQuantityLabel(schema.glazingSgu__pi_color)}</Typography>
            </Grid>

            {glazingColor === 'Blue' ? (
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.textHeader3} onClick={() => handleGlazingColor('Blue')}>
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                  Blue
                </Typography>
              </Grid>
            ) : (
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.textHeader3} onClick={() => handleGlazingColor('Blue')}>
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} />
                  Blue
                </Typography>
              </Grid>
            )}

            {glazingColor === 'Green' ? (
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.textHeader3} onClick={() => handleGlazingColor('Green')}>
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                  Green
                </Typography>
              </Grid>
            ) : (
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.textHeader3} onClick={() => handleGlazingColor('Green')}>
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} />
                  Green
                </Typography>
              </Grid>
            )}

            {glazingColor === 'ClearNeutral' ? (
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.textHeader3} onClick={() => handleGlazingColor('ClearNeutral')}>
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                  Neutral/Clear
                </Typography>
              </Grid>
            ) : (
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.textHeader3} onClick={() => handleGlazingColor('ClearNeutral')}>
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} />
                  Neutral/Clear
                </Typography>
              </Grid>
            )}

            {glazingColor === 'SilverReflective' ? (
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.textHeader3} onClick={() => handleGlazingColor('SilverReflective')}>
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                  Silver/Reflective
                </Typography>
              </Grid>
            ) : (
              <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.textHeader3} onClick={() => handleGlazingColor('SilverReflective')}>
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} />
                  Silver/Reflective
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid item xs={4} direction="column">
            <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
              <Typography className={classes.textHeader5}>{getQuantityLabel(schema.glazingSgu__pi_frameFactor)}</Typography>
            </Grid>
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  value={getQuantityValue(schema.glazingSgu__pi_frameFactor)}
                  placeholder=""
                  onChange={(e) => {
                    e.preventDefault();
                    updateQuantityValue(schema.glazingSgu__pi_frameFactor, e.target.value);
                  }}
                  fullWidth
                  required
                  onClick={(e) => {
                    e.preventDefault();
                    updateQuantityValue(schema.glazingSgu__pi_frameFactor, getQuantityDefaultValue(schema.glazingSgu__pi_frameFactor));
                  }}
                  disabled={false}
                />
              </Grid>
              <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">{getQuantityUnit(schema.glazingSgu__pi_frameFactor)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default GlazingSguCustomBasicParameters;
