import React, { useEffect, useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import axios from '../../../../config/Axios';
import useApiRequest from '../../../hooks/useApiRequest';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from '../../../../routing/IndexRouting';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Skeleton from '@material-ui/lab/Skeleton';
import SearchBar from '../../../common/Searchbar/Search';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import swal from 'sweetalert';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '90%',
  },
  heading2: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  doneicon: {
    color: 'green',
  },
  closeicon: {
    color: 'red',
  },
}));

const PIPActivity = () => {
  const data = [1, 2, 3, 4, 5, 6];
  const classes = useStyles();
  const [activities, setActivities] = useState('');
  const { userDetails } = useContext(UserContext);
  const [{ status, response }, makeRequest] = useApiRequest(`/PIPActivityv2?admin=${userDetails._id}`, {
    verb: 'get',
  });
  const [searchValue, setSearchValue] = useState('');
  if (status === null && userDetails) makeRequest();

  useEffect(() => {
    if (response && response.data) {
      setActivities(response.data.data.data);
    }
  }, [response]);

  useEffect(() => {
    if (userDetails) {
      axios
        .get(`/users/${userDetails._id}`)
        .then((res) => {
          console.log(res, 'res');
        })
        .catch((err) => console.log(err, 'err'));
    }
  }, [userDetails]);

  const handleTextField = (e) => {
    setSearchValue(e.target.value);
  };

  const handleApprove = (data) => {
    let body = {
      ProjectPIPMapping: data.ProjectPIPMapping,
      user: data.user._id,
      oldValue: data.oldValue,
      newValue: data.newValue,
      approved: 'false',
      setApproved: true,
    };
    let ActivityData = {
      ProjectPIPMapping: data.ProjectPIPMapping,
      oldValue: data.oldValue,
      newValue: data.newValue,
      user: data.user._id,
      superUser: userDetails._id,
      status: 'approved',
    };
    axios
      .put(`/PIPAprovalv2`, body)
      .then((res) => {
        swal({ icon: 'success', title: 'Successfully Approved' });
        axios
          .post(`/PIPActivityv2`, { values: [ActivityData] })
          .then(() => {
            makeRequest();
          })
          .catch((err) => console.log(err, 'err'));
      })
      .catch((err) => console.log(err, 'err'));
  };
  const handleReject = (data) => {};
  return (
    <>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        <Grid item xs={7} align="left">
          <Typography variant="h3">PIP Activities</Typography>
        </Grid>
        <Grid item xs={5} align="left">
          <SearchBar onChange={handleTextField} value={searchValue} placeholder="Search by value" />
        </Grid>
        <Grid item xs />
      </Grid>
      <div className={classes.root}>
        {activities ? (
          activities.map((data, index) => {
            return (
              <>
                <Accordion>
                  <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                    <Grid container spacing={0} alignItems="center">
                      <Grid item xs={2}>
                        <Typography className={classes.heading}>
                          <b>Requested User</b>
                        </Typography>
                        <br />
                        <Typography className={classes.secondaryHeading}>
                          <b>{data.user.name}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className={classes.heading}>
                          <b>Project</b>
                        </Typography>
                        <br />
                        <Typography className={classes.secondaryHeading}>
                          <b>{data.projectScheme.name}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className={classes.heading}>
                          <b>Old Value</b>
                        </Typography>
                        <br />
                        <Typography className={classes.secondaryHeading}>
                          <b>{data.oldValue}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className={classes.heading}>
                          <b>New Value</b>
                        </Typography>
                        <br />
                        <Typography className={classes.secondaryHeading}>
                          <b>{data.newValue}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography className={classes.heading}>
                          <b>Status</b>
                        </Typography>
                        <br />
                        <Typography className={classes.secondaryHeading}>
                          <b>{data.status}</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={1} align="center">
                        <Typography className={classes.heading2}>
                          <b>Approve</b>
                        </Typography>
                        <br />
                        <DoneIcon className={classes.doneicon} onClick={() => handleApprove(data)} />
                      </Grid>
                      <Grid item xs={1} align="center">
                        <Typography className={classes.heading2}>
                          <b>Reject</b>
                        </Typography>
                        <br />
                        <CloseIcon className={classes.closeicon} onClick={() => handleReject(data)} />
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                </Accordion>
                <br />
              </>
            );
          })
        ) : (
          <>
            {data &&
              data.map((data) => {
                return <Skeleton animation="wave" height={50} />;
              })}
          </>
        )}
      </div>
    </>
  );
};
export default PIPActivity;
