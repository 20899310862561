/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useMemo, useState } from 'react';
import { Collapse, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import swal from 'sweetalert';
import axios from 'axios';
import PrimaryButton from '../../../../common/button/Button';
import HeadAndBackIcon from '../../../../common/header/HeadAndBackIcon';
import SimplePIPS from '../../common/components/SimplePIPS';
import { SomethingChanged } from '../../EditProject';
import useAppProjectPIPMapping from '../../hooks/useAppProjectPIPMapping';
import useAppPIPScheme from '../../hooks/useAppPIPScheme';
import useAppProjectDetails from '../../hooks/useAppProjectDetails';
import ApiError from '../../../../common/Error/ApiError';
import TowerSpecifications from './TowerSepecifications';
import OpeningSchedule from './OpeningSchedule';
import OpeningPerTower from './OpeningPerTower';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import concreteSelected from '../../../../../assets/project-details/concrete-block-selected.svg';
import concreteNonSelected from '../../../../../assets/project-details/concrete-block-non-selected.svg';
import claySelected from '../../../../../assets/project-details/clay-brick-selected.svg';
import clayNonSelected from '../../../../../assets/project-details/clay-brick-non-selected.svg';
import flyAshNonSelected from '../../../../../assets/project-details/fly-ash-bricks-non-selected.svg';
import flyAshSelected from '../../../../../assets/project-details/fly-ash-bricks-selected.svg';
import TextFeildWithDropDown from '../common/TextFeildWithDropDown';

export const getSortedSimplePIPs = (data, basicPIPs) => {
  if (!data) return [];
  const filteredPIPs = data?.filter((field) => basicPIPs.includes(field.name));
  return filteredPIPs.sort((a, b) => basicPIPs.indexOf(a.name) - basicPIPs.indexOf(b.name));
};

const EditEnvelopeDetails = (props) => {
  const [mount, setMount] = useState(false);
  const [isTowerAvailable, setIsTowerAvailable] = useState(false);
  const [isOpeningAvailable, setIsOpeningAvailable] = useState(false);
  const [towerData, setTowerData] = useState();
  const [openingData, setOpeningData] = useState();
  const [collapseSection, setCollapseSection] = useState(true);
  const [projectSubcategory, setProjectSubcategory] = useState('');
  let history = useHistory();
  let { id } = useParams();
  let { type, next, name } = props;
  const { somethingChanged } = useContext(SomethingChanged);

  const { data: projectData } = useAppProjectDetails(id);
  const {
    get: { form, projectPIP, isError },
    set: { handlePIPValueUpdate },
  } = useAppProjectPIPMapping(id);

  const { data } = useAppPIPScheme(type);
  const { data: envelopeData } = useAppPIPScheme('envelope');
  const { data: materialsData } = useAppPIPScheme('materials');
  const basicPIPs = ['project__pi_floorToFloorHeight', 'project__pi_perimeter', 'project__pi_wwr'];
  useEffect(() => {
    setProjectSubcategory(projectData?.subcategory);
  }, [projectData]);
  const simplePIPs = getSortedSimplePIPs(data, basicPIPs);
  const wallBlockPIPs = ['project__pi_mortarAgent', 'project__pi_setPointTemperature', 'project__pi_ratedCOP'];
  const wallBlockPIPData = getSortedSimplePIPs(envelopeData, wallBlockPIPs);
  const typeofWallBlock = getSortedSimplePIPs(envelopeData, 'project__pi_typeOfExteriorWallBlock');
  const typeofConstruction = getSortedSimplePIPs(materialsData, 'project__pi_typeofConstruction');
  useEffect(() => {
    (async () => {
      if (props.saveAfterSomethingChanged === true && next) {
        await handleSave(next && next);
        props.toogleShowModal(false);
        props.setFalseAfterSomethingChanged();
        props.SETNEXT('');
      }
    })();
  }, [props.saveAfterSomethingChanged, next]);

  const handleNext = (next) => {
    if (next && next === 'goals') history.push(`/projects/${id}/goals`);
    else {
      if (somethingChanged) {
        props.toogleShowModal(true);
        props.SETNEXT(next);
      } else {
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      }
    }
  };
  const handleExpandIcon = () => {
    setCollapseSection((prev) => !prev);
  };
  const handleGoBack = (type) => {
    if (type === 'basic') history.push(`/projects/${id}/insights`);
    else history.push(`/projects/${id}/edit?type=basic`);
  };

  const getPIPValues = (pipvalues) => {
    const body = Object.assign({}, pipvalues);
    return Object.entries(body).map(([key, value]) => ({
      PIPScheme: key,
      value,
    }));
  };

  const handleSave = async (next) => {
    axios
      .put(`/ProjectPIPMapping`, {
        projectId: id,
        values: getPIPValues(form?.pipvalues),
      })
      .then((response) => {
        props.toogleShowModal(false);
        props.setFalseAfterSomethingChanged();
        props.SETNEXT('');
        swal({ icon: 'success', title: 'Data Saved' });
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      });
  };
  if (isError)
    return (
      <div style={{ display: 'flex', alignItems: 'center', paddingTop: 24, width: '100%' }}>
        <ApiError />
      </div>
    );
  return (
    <>
      <Grid item container justifyContent="left" direction="column" xs={12} style={{ marginTop: '3%', paddingLeft: '3%', marginBottom: '3%' }}>
        <HeadAndBackIcon
          heading={`${name && name} ${projectData?.name ? projectData?.name : ''}`}
          handleClick={() => handleGoBack(type && type)}
          visitGoals={false}
          headingVariant="h5"
          headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
          showArrow={false}
        />
      </Grid>
      {/*Working*/}
      {type && type === 'dwelling' && simplePIPs && projectPIP && projectPIP.length !== 0
        ? simplePIPs.map((field, index) => {
            return (
              <Grid xs={12} style={{ marginLeft: '10px' }}>
                <SimplePIPS
                  index={index}
                  unit={form?.unitPIPValue}
                  field={field && field}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                  type={type}
                />
              </Grid>
            );
          })
        : null}{' '}
      {form?.pipvalues[typeofConstruction[0]?._id] === 'RCC frame structure' && (
        <Grid style={{ marginLeft: '3%' }}>
          <Grid xs={12} container style={{ marginBottom: '5px' }}>
            <Typography
              style={{
                fontFamily: 'Lato',
                fontWeight: 700,
                fontSize: '20px',
                lineHeight: '24px',
                letterSpacing: '0.71px',
              }}
            >
              Wall Assembly Specifications
            </Typography>
            <Grid item xs={12} style={{ lineHeight: '22px', letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)', marginBottom: '1%', marginTop: '2%' }}>
              <Typography variant="h6">This will be considered for both interior and exterior wall block specifications</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" style={{}}>
                Type of Wall Block
              </Typography>
            </Grid>
            <Grid container item xs={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Grid xs={2}>
                <img
                  src={form?.pipvalues[typeofWallBlock[0]?._id] === 'Concrete Blocks' ? concreteSelected : concreteNonSelected}
                  alt="..."
                  style={{ height: '96px', width: '96px', cursor: 'pointer' }}
                  onClick={() => handlePIPValueUpdate(typeofWallBlock[0]?._id, 'Concrete Blocks')}
                />
                <Typography style={{ lineHeight: '22px', letterSpacing: '0.571429px', fontSize: '8px', textAlign: 'left', paddingLeft: '10%' }}>
                  Concrete Blocks
                </Typography>
              </Grid>
              <Grid xs={2}>
                <img
                  src={form?.pipvalues[typeofWallBlock[0]?._id] === 'Clay Bricks' ? claySelected : clayNonSelected}
                  alt="..."
                  style={
                    form?.pipvalues[typeofWallBlock[0]?._id] === 'Clay Bricks'
                      ? { height: '96px', width: '96px', border: '1px solid rgba(38, 224, 163, 1)', borderRadius: '10px', cursor: 'pointer' }
                      : { height: '96px', width: '96px', cursor: 'pointer' }
                  }
                  onClick={() => handlePIPValueUpdate(typeofWallBlock[0]?._id, 'Clay Bricks')}
                />
                <Typography style={{ lineHeight: '22px', letterSpacing: '0.571429px', fontSize: '8px', textAlign: 'left', paddingLeft: '10%' }}>
                  Clay Bricks
                </Typography>
              </Grid>
              <Grid xs={2}>
                <img
                  src={form?.pipvalues[typeofWallBlock[0]?._id] === 'Fly Ash Bricks' ? flyAshSelected : flyAshNonSelected}
                  alt="..."
                  style={
                    form?.pipvalues[typeofWallBlock[0]?._id] === 'Fly Ash Bricks'
                      ? { height: '96px', width: '96px', border: '1px solid rgba(38, 224, 163, 1)', borderRadius: '10px', cursor: 'pointer' }
                      : { height: '96px', width: '96px', cursor: 'pointer' }
                  }
                  onClick={() => handlePIPValueUpdate(typeofWallBlock[0]?._id, 'Fly Ash Bricks')}
                />
                <Typography style={{ lineHeight: '22px', letterSpacing: '0.571429px', fontSize: '8px', textAlign: 'left', paddingLeft: '10%' }}>
                  Fly Ash Bricks
                </Typography>
              </Grid>
              <Grid alignItems="flex-start" direction="row" xs={12} item style={{ paddingBottom: '4%' }}>
                {wallBlockPIPData &&
                  wallBlockPIPData.map((pip, index) => {
                    return (
                      <>
                        <TextFeildWithDropDown
                          key={index}
                          _id={pip._id}
                          displayName={pip.displayName}
                          appendChanged={handlePIPValueUpdate}
                          disabledValue={false}
                          values={pip?.options}
                          projectPIP={projectPIP && projectPIP}
                          pipValues={form?.pipvalues}
                        />
                      </>
                    );
                  })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid style={{ marginLeft: '3%' }}>
        <Grid xs={12} container style={{ marginBottom: '5px' }}>
          <Typography
            style={{
              fontFamily: 'Lato',
              fontWeight: 700,
              fontSize: '20px',
              lineHeight: '24px',
              letterSpacing: '0.71px',
            }}
          >
            Resident Envelope Transmittance Values (RETV) Calculations
          </Typography>
          <ExpandMoreIcon
            style={{
              color: '#49DDB1',
              fontSize: '30px',
              transform: `rotate(${!collapseSection ? -90 : 0}deg)`,
              transition: 'transform 0.2s ease-in-out',
            }}
            onClick={handleExpandIcon}
          />
        </Grid>
        <Typography style={{ fontWeight: '400', color: '#00000061', marginBottom: '10px' }}>
          Calculate the thermal performance of your building's envelope benchmarked against BEE: Eco Niwas Samhita
        </Typography>
        <Collapse in={collapseSection}>
          <TowerSpecifications
            form={form}
            handlePIPValueUpdate={handlePIPValueUpdate}
            projectSubcategory={projectSubcategory}
            isTowerAvailable={isTowerAvailable}
            setTowerData={setTowerData}
            setIsTowerAvailable={setIsTowerAvailable}
            mount={mount}
            setMount={setMount}
          />
          <OpeningSchedule
            form={form}
            handlePIPValueUpdate={handlePIPValueUpdate}
            projectSubcategory={projectSubcategory}
            isOpeningAvailable={isOpeningAvailable}
            setOpeningData={setOpeningData}
            setIsOpeningAvailable={setIsOpeningAvailable}
            isTowerAvailable={isTowerAvailable}
            mount={mount}
            setMount={setMount}
          />
          <OpeningPerTower
            form={form}
            handlePIPValueUpdate={handlePIPValueUpdate}
            projectSubcategory={projectSubcategory}
            towerData={towerData}
            openingData={openingData}
            isOpeningAvailable={isOpeningAvailable}
            mount={mount}
            setMount={setMount}
          />
        </Collapse>
      </Grid>
      {/* Window types */}
      <Grid container direction="row" alignItems="end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '7%' }}>
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleSave(next && next)} children="Save" />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleNext(next && next)} children="next" />
        </Grid>
      </Grid>
    </>
  );
};
export default EditEnvelopeDetails;
