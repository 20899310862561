import { api } from './http';

const postVerifyInvitationCode = async (email, invitationCode) => {
  const response = await api.post(`/v2/users/verify-invitation`, { email, invitationCode });
  return response.data.data.body.data;
};

const postCreateAccount = async (payload) => {
  await api.post(`/v2/users/sign-up`, payload);
  return;
};

const getRoles = async () => {
  const response = await api.get(`/v2/users/get-roles`);
  return response.data.data.body.data;
};

const getUsersByOrganisationId = async (organisationID) => {
  const response = await api.get(`/v2/users/${organisationID}`);
  return response?.data?.data?.body?.data;
};

const deleteUser = async (payload) => {
  await api.post(`/v2/users/delete-user`, payload);
  return true;
};

const postUserRole = async (payload) => {
  await api.post(`/v2/users/update-role`, payload);
  return true;
};

const postSendEmails = async (organisationId, emailList) => {
  await api.post(`/v2/users/invite-users`, { organisationId, emails: emailList });
  return true; // Indicate success
};

const postResendInvitation = async (organisationId, userId) => {
  const res = await api.post(`/v2/users/resend-invitation`, {
    organisationId,
    userId,
  });
  return res;
};

const postUserCurrentProject = async (userId, organisationId, projectId) => {
  const res = await api.post(`/v2/users/update-current-project`, {
    userId,
    organisationId,
    projectId,
  });
  return res;
};

const postUserOnboarding = async (userId, payload) => {
  const res = await api.post(`v2/users/update-onboarding/${userId}`, payload);
  return res;
};

const postForgotPassword = async (email) => {
  await api.post('/v2/users/forgot-password', { email });
  return;
};

const postResetPassword = async (token, password, email) => {
  await api.post('v2/users/reset-password', { token, password, email });
  return;
};

export {
  postVerifyInvitationCode,
  postCreateAccount,
  getRoles,
  getUsersByOrganisationId,
  deleteUser,
  postUserRole,
  postSendEmails,
  postResendInvitation,
  postUserCurrentProject,
  postUserOnboarding,
  postForgotPassword,
  postResetPassword,
};
