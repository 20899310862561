import React, { useState, useEffect, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import privateRouting from './PrivateRouting';
import publicRouting from './PublicRouting';
import axios from '../config/Axios';
import cookie from 'react-cookies';
import Error from '../components/common/notfound/Error';
import api, { queryKeys } from '../http';
import { updateToken } from '../http/http';
import { useQuery } from '@tanstack/react-query';
import NotFound from '../components/common/notfound/Index';
import RedirectComponent from './Redirect';
import Home from '../components/views/landingPage/Home';
import RouteWithSubRoutes from './RouteWithSubRoutes';
import ProductTourContext from '../hooks/useAppProductTour';

export const Pages = {
  vendorDetails: 'vendorDetails',
  certification: 'certification',
  marketing: 'marketing',
  certificationDocumentUpload: 'certificationDocumentUpload',
  projects: 'projects',
  insights: 'insights',
  interventions: 'interventions',
  interventionDetails: 'interventionDetails',
  certificationCredit: 'certificationCredit',
  goals: 'goals',
};

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, [pathname]);

  return null;
}

export const ProjectContext = React.createContext();
export const UserContext = React.createContext({});
export const PreviousPageContext = React.createContext();
export const ProductsPageContext = React.createContext();

const Routes = (props) => {
  const token = cookie.load('SDPLUS_TOKEN');
  useEffect(() => {
    if (token) {
      // Set default headers for v1 existing API calls
      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
      // Set default headers for new v2 API calls
      updateToken(token);
    }
  }, [token]);

  const [loginToken, setLoginToken] = useState(token);
  const [userDetails, setUserDetails] = useState(null);
  const {
    data: userData,
    isError: isErrorUserData,
    isLoading: isLoadingUserData,
    refetch: fetchUserData,
  } = useQuery({
    queryKey: [queryKeys.userDetails, token],
    queryFn: () => api.getAuthWhoAmI(),
    enabled: !!token,
  });

  useEffect(() => {
    if (userData) {
      setUserDetails(userData);
    }
  }, [userData]);

  useEffect(() => {
    if (isErrorUserData) {
      cookie.remove('SDPLUS_TOKEN');
      window.location.href = '/';
    }
  }, [isErrorUserData]);

  const [previousPage, setPreviousPage] = useState('projects');
  const [projectID, setProjectID] = useState('');
  const [interventionMappingID, setIntervnetionMappingID] = useState('');
  const [interventionID, setInterventionID] = useState('');
  const [interventionVendorMappingID, setInterventionVendorMappingID] = useState('');
  const [IGBCCreditID, setIGBCCreditID] = useState('');

  const [projectData, setProjectData] = useState(null);

  const isUserLoggedIn = !!loginToken;
  const isUserDetailsAvailable = !!userDetails;
  const applicationRoutes = [...publicRouting, ...privateRouting];

  const handleProjectData = (data) => setProjectData(data);
  const handleLoginToken = (data) => setLoginToken(data);

  // states
  const [contactUs, setContactUs] = React.useState(false);
  const handleShowContactUs = (d) => setContactUs(d);

  const [filtersData, setFiltersData] = useState([]);
  const [appliedfilters, setAppliedFilters] = useState([]);
  const [categoryarr, setCategoryarr] = useState([]);
  const [filterarr, setFilterarr] = useState([]);
  const [isDefault, setIsDefault] = useState(false);
  // /For Marketplace context
  const [URLS, setURLs] = useState({
    category: '',
    subcategory: '',
    brands: '',
    supercategory: '',
    page: 1,
    pagesize: 20,
    source: '',
    filters: '',
    sort: '',
    searchquery: '',
    mappingid: '',
    productids: '',
    productstatus: '',
    projectId: '',
  });

  const handleURL = (
    category,
    subcategory,
    brands,
    supercategory,
    page,
    pagesize,
    source,
    filters,
    sort = 'default',
    searchquery,
    mappingid,
    productids,
    productstatus,
    projectId
  ) => {
    setURLs({
      category: category,
      subcategory: subcategory,
      brands: brands,
      supercategory: supercategory,
      page: page,
      pagesize: pagesize,
      source: source,
      filters: filters,
      sort: sort,
      searchquery: searchquery,
      mappingid: mappingid,
      productids: productids,
      productstatus: productstatus,
      projectId: projectId,
    });
  };

  const handleCategory = (name, subname, checked) => {
    const body = { name, subname, checked };
    let n = categoryarr && categoryarr.find((data) => data.name === name && data.subname === subname);
    if (n) {
      n.checked = checked;
      setCategoryarr((oldArray) => [...oldArray]);
    } else setCategoryarr((oldArray) => [...oldArray, body]);
  };

  const handleFilterArr = (name, subname, checked) => {
    const body = { name, subname, checked };
    let n = filterarr && filterarr.find((data) => data.name === name && data.subname === subname);
    if (n) {
      n.checked = checked;
      setFilterarr((oldArray) => [...oldArray]);
    } else setFilterarr((oldArray) => [...oldArray, body]);
  };

  const handleFilterData = (id, value, checked) => {
    const body = { id, value, checked };
    let n = filtersData && filtersData.find((data) => data.value === value);
    if (n) {
      n.value = value;
      n.checked = checked;
      if (n.checked === false) {
        const updatedFiltersArray = filtersData.filter((data) => data.checked !== false);
        setFiltersData(updatedFiltersArray);
      } else setFiltersData((oldArray) => [...oldArray]);
    } else setFiltersData((oldArray) => [...oldArray, body]);
  };

  const handleAppliedFilters = (id, value, checked) => {
    const body = { id, value, checked };
    let n = appliedfilters && appliedfilters.find((data) => data.value === value);
    if (n) {
      n.value = value;
      n.checked = checked;
      if (n.checked === false) {
        const updatedFiltersArray = appliedfilters.filter((data) => data.checked !== false);
        setAppliedFilters(updatedFiltersArray);
        // setAppliedFilters((oldArray) => [...oldArray]);
      }
    } else setAppliedFilters((oldArray) => [...oldArray, body]);
  };

  const handleReset = () => {
    setFiltersData([]);
    setAppliedFilters([]);
    setCategoryarr([]);
    setFilterarr([]);
  };

  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        <ProductsPageContext.Provider
          value={{
            URLS,
            handleURL,
            filtersData: filtersData,
            appliedfilters: appliedfilters,
            handleFilterData,
            handleAppliedFilters,
            setFiltersData,
            setAppliedFilters,
            handleReset,
            handleCategory,
            categoryarr,
            setCategoryarr,
            setFilterarr,
            handleFilterArr,
            filterarr,
            isDefault,
            setIsDefault,
            contactUs,
            handleShowContactUs,
          }}
        >
          <PreviousPageContext.Provider
            value={{
              previousPage,
              setPreviousPage,
              projectID,
              setProjectID,
              interventionMappingID,
              setIntervnetionMappingID,
              interventionID,
              setInterventionID,
              interventionVendorMappingID,
              setInterventionVendorMappingID,
              IGBCCreditID,
              setIGBCCreditID,
            }}
          >
            <UserContext.Provider value={{ loginToken, handleLoginToken, userDetails, setUserDetails }}>
              <ProjectContext.Provider value={{ projectData, handleProjectData }}>
                <ProductTourContext userDetails={userDetails} onCallback={() => fetchUserData()}>
                  <Suspense fallback={<Error />}>
                    <Switch>
                      <Route path="/" exact={true} render={() => (isUserLoggedIn ? <RedirectComponent /> : <Home />)} />
                      {applicationRoutes.map((route, i) => (
                        <RouteWithSubRoutes
                          key={i}
                          {...route}
                          isUserLoggedIn={isUserLoggedIn}
                          isRouteAllowedToUser={!userDetails || route?.requiredRoles?.includes(userDetails?.role)}
                        />
                      ))}
                      <Route path="*" render={() => <NotFound />} />
                    </Switch>
                  </Suspense>
                </ProductTourContext>
              </ProjectContext.Provider>
            </UserContext.Provider>
          </PreviousPageContext.Provider>
        </ProductsPageContext.Provider>
      </BrowserRouter>
    </div>
  );
};

export default Routes;
