/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useStyles } from './Styles';
import { EMAIL_REGEX, InviteUsers, RenderInviteComponent } from './Components';
import api from '../../../../../http';

import { CustomModal } from './Components';
import { useStatusContext } from '../../../../../features/providers/StatusProvider';
import { useAppApiRequest } from '../../../../../hooks/useAppApiRequest';

const EmailInputWithChips = ({ users, maxEmails = 4, organisationId }) => {
  const classes = useStyles();
  const { showStatus } = useStatusContext();

  const [open, setOpen] = useState(false);
  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');
  const [registeredEmails, setRegisteredEmails] = useState([]);
  const [enabled, setEnabled] = useState(false);

  const { data, isFetching, refetch } = useAppApiRequest(
    () =>
      api.postSendEmails(
        organisationId,
        emails.map((item) => item.email)
      ),
    { method: 'GET' },
    [],
    enabled
  );

  useEffect(() => {
    if (data) {
      setOpen(false);
      showStatus('inviteSent');
      setEmails([]);
      setEnabled(false);
    }
  }, [data]);

  useEffect(() => {
    if (users) setRegisteredEmails(users.map((user) => user.email));
  }, [users]);

  const validateAndAddEmail = (email) => {
    if (!email) return false;

    if (!EMAIL_REGEX.test(email)) {
      setError('Invalid email format.');
      return false;
    }

    if (emails.some((emailObj) => emailObj.email === email)) {
      setError('Email already added.');
      return false;
    }

    if (registeredEmails.includes(email)) {
      setEmails([...emails, { email, error: 'registered' }]);
    } else if (emails.length >= maxEmails) {
      setEmails([...emails, { email, error: 'limit' }]);
    } else {
      setEmails([...emails, { email }]);
    }

    return true;
  };

  const handleAddEmail = (e) => {
    if (['Enter', ' ', 'Tab', ','].includes(e.key)) {
      e.preventDefault();
      const email = inputValue.trim();
      if (validateAndAddEmail(email)) {
        setInputValue('');
        setError('');
      }
    }
  };

  const handleDeleteEmail = (emailToDelete) => {
    setEmails(emails.filter((emailObj) => emailObj.email !== emailToDelete));
  };

  const handleSendEmails = async () => {
    setEnabled(true);
    refetch();
  };

  return (
    <>
      <InviteUsers setOpen={() => setOpen(true)} />
      <CustomModal open={open} onClose={() => setOpen(false)} className={classes.modal}>
        <RenderInviteComponent
          classes={classes}
          emails={emails}
          error={error}
          inputValue={inputValue}
          handleDeleteEmail={(val) => handleDeleteEmail(val)}
          setInputValue={setInputValue}
          handleAddEmail={handleAddEmail}
          handleSendEmails={handleSendEmails}
          handleCloseParent={() => {
            setOpen(false);
            setEmails([]);
            setInputValue('');
          }}
          isFetching={isFetching}
        />
      </CustomModal>
    </>
  );
};

export default EmailInputWithChips;
