/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Insights from './insights/insights';
import Rightbar from './components/Rightbar';
import { useParams } from 'react-router';
import { walkthroughContext } from '../../../projectRouting/Index';

import { PreviousPageContext, UserContext } from '../../../routing/IndexRouting';
import { MockSortOfInterventionInsights } from './MockSortOfInterventionInsights';
import { MetafireFeatures } from '../../../metafire/utils/types';
import ApiError from '../../common/Error/ApiError';
import CasaSkeleton from '../../common/skeleton/CasaSkeleton';
import api, { queryKeys } from '../../../http';
import { useQuery } from '@tanstack/react-query';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(11),
  },
  tourtexthead: {
    fontStyle: 'normal',
    fontWeight: 850,
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0.714286px',
    color: '#000000',
    paddingBottom: '10px',
  },
  tourtext: {
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '24px',
    textAlign: 'justify',
    letterSpacing: '0.714286px',
    color: '#000000',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function MainGrid(props) {
  const classes = useStyles();
  const params = useParams();

  const [intervention, setIntervention] = useState(null);
  const [goals, setGoals] = useState(null);
  const [insights, setinsights] = useState(null);
  const [sustainabilityLevel, setSustainabilityLevel] = useState(null);
  const [numberOfYears, setNumberOfYears] = useState(3);

  /*Context for how it works*/
  const { uiWalkthroughContext, handleMyWalkthrough } = useContext(walkthroughContext);
  const { setPreviousPage } = useContext(PreviousPageContext);
  const { userDetails } = useContext(UserContext);

  const {
    data: insightsResponse,
    isError,
    isLoading,
  } = useQuery({
    queryKey: [queryKeys.metafireInsights, params?.id, numberOfYears],
    queryFn: () => api.getMetafireRequest({ projectId: params?.id, feature: MetafireFeatures.INSIGHTS, numberOfYears }),
  });

  useEffect(() => {
    setPreviousPage('insights');
  }, []);

  useEffect(() => {
    if (insightsResponse) {
      const mockResponse = MockSortOfInterventionInsights;
      setIntervention(insightsResponse.data?.data?.data?.interventions);
      setGoals(insightsResponse.data?.data?.data?.goalsInsights);
      setSustainabilityLevel(insightsResponse.data?.data?.data?.interventions?.quantities?.overall?.sustainabilityLevel?.value);

      // TODO: Mock for now
      setinsights(mockResponse.data?.data?.data?.locationBasedInsights);
    }
  }, [insightsResponse, numberOfYears]);

  if (isError)
    return (
      <div className={classes.container}>
        <ApiError />
      </div>
    );

  return (
    <>
      {/* uncomment this out while using this component in play area - without data */}
      {/* <main className={classes.content}> */}
      <Grid container>
        <Grid item sm={8} lg={8} xl={8} style={{ backgroundColor: 'rgba(24, 50, 119, 0.07)', minHeight: '100vh' }}>
          <Insights
            sustainabilityLevel={sustainabilityLevel}
            intervention={intervention}
            insights={insights}
            goals={goals}
            setNumberOfYears={setNumberOfYears}
            numberOfYears={numberOfYears}
            isFetching={isLoading}
          />
        </Grid>

        <Grid item sm={4} lg={4} xl={4} style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.12)' }}>
          {isLoading ? (
            <div className={classes.container}>
              <CasaSkeleton variant="rect" style={{ margin: '0 auto' }} height="300px" width="94%" animation="wave" />
            </div>
          ) : (
            <Rightbar goals={goals} intervention={intervention} />
          )}
        </Grid>
      </Grid>
      {/* </main> */}
    </>
  );
}
