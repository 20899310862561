import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  actionCell: {
    width: 100,
  },
  table: {
    minWidth: 650,
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    width: '100%', // Full width of the container
    borderCollapse: 'collapse', // Ensure no spacing between cells
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow
    paddingLeft: '1%',
  },
  tableHeader: {
    backgroundColor: theme.palette.grey[100],
    textAlign: 'left', // Align header text to the left
    color: '#000000', // Black text for headers
    fontSize: '16px', // Slightly larger font size for headers
    fontWeight: 'bold', // Bold text for headers
    padding: '12px 8px', // Add padding for spacing
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
      cursor: 'pointer',
    },
    borderBottom: 'none', // Subtle row separator
    height: '56px', // Ensure uniform row height
  },
  tableCell: {
    textAlign: 'left', // Align text to the left
    color: '#7a7a7a', // Slightly dimmed text for cells
    fontSize: '14px', // Smaller font size for cell content
    padding: '12px 8px 8px 12px', // Add padding for spacing
  },
}));
