import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { barChartColorMap, sortData } from './ReportUtils';
import { MetafireInterventions } from '../../../../../metafire/utils/types';

const MAX_BAR_HEIGHT = 513;

const styles = StyleSheet.create({
  chartContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: 550,
    margin: 0,
    position: 'relative',
    gap: 40,
  },
  chart: {
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    textAlign: 'center',
  },
  label: {
    marginBottom: 10,
    fontWeight: 'bold',
    fontSize: 12,
  },
  barContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-around',
  },
  bar: {
    flexDirection: 'column',
    width: 110,
    overflow: 'hidden',
  },
  leftLineContainer: {
    left: -30,
    height: MAX_BAR_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  leftLineContainerText: {
    left: -40,
    height: MAX_BAR_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dashedLineTop: {
    fontSize: 30,
    height: 2,
    width: 12,
    backgroundColor: '#666666',
    borderBottom: '0.5px solid #666666',
    alignItems: 'center',
  },
  dashedLineTopTwo: {
    fontSize: 30,
    height: 2,
    width: 12,
    borderBottom: '2px solid #666666',
    alignItems: 'center',
    backgroundColor: 'transparent',
    position: 'absolute',
  },
  solidLineMiddle: {
    width: 2,
    borderTop: '0.5px solid #666666',
    borderBottom: '0.5px solid #666666',
    backgroundColor: '#666666',
    height: MAX_BAR_HEIGHT,
  },
  solidLineMiddleText: {
    width: 0,
    backgroundColor: '#666666',
    height: MAX_BAR_HEIGHT,
  },
  dashedLineBottom: {
    height: 2,
    width: 12,
    backgroundColor: '#666666',
    borderTop: '0.5px solid #666666',
    borderBottom: '0.5px solid #666666',
  },
  segment: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#fff',
    width: '100%',
  },
  chartLabel: {
    marginTop: 10,
    fontWeight: 'Bold',
    fontFamily: 'Lato',
    fontSize: 17,
    color: '#2E2E2E',
  },
  reductionLabel: {
    position: 'absolute',
    bottom: 10,
    textAlign: 'center',
    width: '100%',
    fontSize: 14,
  },
});
const legendStyles = StyleSheet.create({
  legendContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20,
  },
  column: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginHorizontal: 20,
  },
  legendItem: {
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: 10,
  },
  legendColorBox: {
    width: '20px',
    height: '20px',
    marginRight: 5,
  },
  legendText: {
    fontFamily: 'Lato',
    fontSize: '8px',
    color: '#2E2E2E',
    textAlign: 'center',
    justifyContent: 'center',
    width: '65px',
    flexWrap: 'wrap',
  },
});

const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

const Legend = ({ items }) => {
  const groupedItems = chunkArray(items, 3);
  const formatLabel = (label) => {
    /*react-pdf does not support word-break, white-space css properties yet, hence using a custom function.*/
    if (label === 'Structural Concrete') return 'Structural \n Concrete';
    if (label === 'Bathroom Flooring') return 'Bathroom \n Flooring';
    return label;
  };
  return (
    <View style={legendStyles.legendContainer}>
      {groupedItems.map((group, index) => (
        <View key={index} style={legendStyles.column}>
          {group.map((item) => (
            <View key={item.key} style={legendStyles.legendItem}>
              <View style={[legendStyles.legendColorBox, { backgroundColor: barChartColorMap[item.key] }]} />
              <Text style={legendStyles.legendText}>{formatLabel(item.label)}</Text>
            </View>
          ))}
        </View>
      ))}
    </View>
  );
};

const calculateScaledHeight = (value, total, maxTotal) => {
  const scaledHeight = (value / total) * MAX_BAR_HEIGHT;
  return scaledHeight;
};

const MaterialsBarChart = ({ data, label, isLeftMost, maxTotal, maxActualTotal, lowerBarHeight, reduction, reductionPercent }) => {
  const { total, segments } = data;
  let isTopMostLayer = false;
  let isTopLayerBorderCurved = false;
  const barHeight = (total / maxTotal) * MAX_BAR_HEIGHT;

  return (
    <View style={styles.chart}>
      <View style={styles.barContainer}>
        {isLeftMost && (
          <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <View style={styles.leftLineContainerText}>
              <Text style={{ fontFamily: 'Lato', fontSize: '10px', marginTop: '-6px', color: '#2E2E2E' }}>{Math.round(maxActualTotal)} tCO2e</Text>
              <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <View style={styles.solidLineMiddleText} />
                <View style={{ position: 'absolute', marginTop: 500 - lowerBarHeight, width: 100 }}>
                  <Text style={{ color: '#15B7B9', fontFamily: 'Lato', fontSize: '10px', fontWeight: 400 }}>{Math.round(reduction)} tCO2e</Text>
                  <Text style={{ color: '#15B7B9', fontFamily: 'Lato', fontSize: '14px', fontWeight: 400 }}>{reductionPercent.toFixed(2)}%</Text>
                  <Text style={{ color: '#2E2E2E', fontFamily: 'Lato', fontSize: '14px', fontWeight: 400 }}>Reduction</Text>
                </View>
              </View>{' '}
              <Text style={{ fontFamily: 'Lato', fontSize: '10px', marginBottom: '-6px', color: '#2E2E2E' }}>0 tCO2e</Text>
            </View>
            <View style={{ ...styles.leftLineContainer, marginRight: '10px' }}>
              <View style={styles.dashedLineTop}></View>
              <View style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <View style={styles.solidLineMiddle} />
                <View style={{ ...styles.dashedLineTopTwo, marginTop: 513 - lowerBarHeight }}></View>
              </View>
              <View style={{ ...styles.dashedLineBottom }}></View>
            </View>
          </View>
        )}

        <View style={[styles.bar, { height: barHeight }]}>
          {segments.map((segment, index) => {
            const height = calculateScaledHeight(segment.value, total, maxTotal);
            if (height !== 0 && !isTopMostLayer && !isTopLayerBorderCurved) {
              isTopMostLayer = true;
              isTopLayerBorderCurved = true;
            }
            const segmentStyle = {
              height: height,
              backgroundColor: segment.color,
              borderTopLeftRadius: isTopMostLayer ? 20 : 0,
              borderTopRightRadius: isTopMostLayer ? 20 : 0,
            };
            isTopMostLayer = false;
            return (
              <View key={index} style={[styles.segment, segmentStyle]}>
                <Text style={{ fontFamily: 'Lato', textAlign: 'center', fontSize: '10px', color: '#000000' }}>{Math.round(segment.actualValue)}</Text>
              </View>
            );
          })}
        </View>
      </View>
      <Text style={{ ...styles.chartLabel, marginLeft: label === 'Base Case' ? 85 : 0 }}>{label}</Text>
    </View>
  );
};

const EmbodiedCarbonChart = (props) => {
  const segmentsPreSdplus = sortData(
    props?.categorisedReportData?.map((interv) => {
      return {
        actualValue: interv.resourceSavingsPreSdPlus,
        value: Math.abs(interv.resourceSavingsPreSdPlus),
        interventionKey: interv?.interventionKey,
        color: barChartColorMap[interv.interventionKey],
      };
    }) || []
  );

  const segmentsPostSdplus = sortData(
    props?.categorisedReportData?.map((interv) => {
      return {
        actualValue: interv.resourceSavings,
        value: Math.abs(interv.resourceSavings),
        interventionKey: interv?.interventionKey,
        color: barChartColorMap[interv.interventionKey],
      };
    }) || []
  );
  const resourceSavingPreSdplusSum = props?.categorisedReportData?.reduce((initialValue, interv) => {
    return initialValue + Math.abs(interv.resourceSavingsPreSdPlus);
  }, 0);
  const resourceSavingPreSdplusSumActual = props?.categorisedReportData?.reduce((initialValue, interv) => {
    return initialValue + interv.resourceSavingsPreSdPlus;
  }, 0);
  const resourceSavingPostSdplusSum = props?.categorisedReportData?.reduce((initialValue, interv) => {
    return initialValue + Math.abs(interv.resourceSavings);
  }, 0);
  const resourceSavingPostSdplusSumActual = props?.categorisedReportData?.reduce((initialValue, interv) => {
    return initialValue + interv.resourceSavings;
  }, 0);
  const otherMaterialsPreSdplus = props?.baselineEmbodiedCarbon - resourceSavingPreSdplusSum;
  const otherMaterialsPreSdplusActual = props?.baselineEmbodiedCarbon - resourceSavingPreSdplusSumActual;

  const baseCase = {
    total: resourceSavingPreSdplusSum + Math.abs(otherMaterialsPreSdplus),
    actualTotal: resourceSavingPreSdplusSumActual + otherMaterialsPreSdplusActual,
    segments: [
      ...segmentsPreSdplus,
      {
        actualValue: otherMaterialsPreSdplusActual,
        value: Math.abs(otherMaterialsPreSdplus),
        interventionKey: 'otherMaterials',
        color: '#99FFD9',
      },
    ],
  };

  const otherMaterialsPostSdplus = otherMaterialsPreSdplus;
  const otherMaterialsPostSdplusActual = otherMaterialsPreSdplusActual;
  const sdPlusImpact = {
    total: resourceSavingPostSdplusSum + Math.abs(otherMaterialsPostSdplus),
    actualTotal: resourceSavingPostSdplusSumActual + otherMaterialsPostSdplusActual,
    segments: [
      ...segmentsPostSdplus,
      {
        actualValue: otherMaterialsPostSdplusActual,
        value: Math.abs(otherMaterialsPostSdplus),
        interventionKey: 'otherMaterials',
        color: '#99FFD9',
      },
    ],
  };
  const legendItems = props?.categorisedReportData.map((interv) => ({
    key: interv.interventionKey,
    label: MetafireInterventions[interv.interventionKey],
  }));

  const maxTotal = Math.max(baseCase.total, sdPlusImpact.total);
  const maxActualTotal = Math.max(baseCase.actualTotal, sdPlusImpact.actualTotal);
  const reduction = baseCase.actualTotal - sdPlusImpact.actualTotal;
  const reductionPercent = (reduction * 100) / baseCase.actualTotal;
  const lowerBarHeight = Math.min((baseCase.total / maxTotal) * MAX_BAR_HEIGHT, (sdPlusImpact.total / maxTotal) * MAX_BAR_HEIGHT);
  return (
    <View>
      <View style={styles.chartContainer}>
        <MaterialsBarChart
          lowerBarHeight={lowerBarHeight}
          data={baseCase}
          label="Base Case"
          isLeftMost={true}
          maxTotal={maxTotal}
          maxActualTotal={maxActualTotal}
          reduction={reduction}
          reductionPercent={reductionPercent}
        />
        <MaterialsBarChart
          lowerBarHeight={lowerBarHeight}
          data={sdPlusImpact}
          label="SD+ Impact"
          isLeftMost={false}
          maxTotal={maxTotal}
          maxActualTotal={maxActualTotal}
          reduction={reduction}
          reductionPercent={reductionPercent}
        />
      </View>
      <View style={{ marginTop: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Text style={{ color: '#15B7B9', fontFamily: 'Lato', fontSize: '13px', fontWeight: 400 }}>
          {reductionPercent.toFixed(2)}% ({Math.round(reduction)} tCO2e)
        </Text>
        <Text style={{ color: '#2E2E2E', fontFamily: 'Lato', fontSize: '13px', fontWeight: 400 }}> reduction in Embodied Carbon</Text>
      </View>
      <Legend items={[...legendItems, { key: 'otherMaterials', label: 'Other Materials' }]} />
    </View>
  );
};

export default EmbodiedCarbonChart;
