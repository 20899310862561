import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Typography } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import CategoryRow from './categoryRow';
import FlowDiagrams from '../flow-diagram/FlowDiagrams';
import CustomCheckBox from '../../../../common/checkbox/CustomCheckBox';

import { getLabelDynamically } from '../utils';

const CategoryTable = ({
  setCustomInterventionFileNames,
  data = {},
  handleRefresh,
  projectID,
  handleEditCustomIntervention,
  handleEditCustominterventionID,
  handleOpenBackdrop,
}) => {
  const classes = useStyles();

  const { name: categoryName = '', subcategories = [] } = data;

  const [isShowFlowDiagram, setIsShowFlowDiagram] = React.useState(false);
  const [isShowCapex, setIsShowCapex] = useState(true);

  const isMaterialsCategory = categoryName === 'Materials';

  return (
    <>
      <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12}>
        <Grid container item xs={4} data-tour="diagram">
          <Typography className={clsx(classes.flowDiagram, { 'show-flow-diagram-summary': true })} onClick={() => setIsShowFlowDiagram(true)}>
            {isMaterialsCategory ? 'SHOW EMBODIED CARBON IMPACT' : 'SHOW FLOW DIAGRAM'}
          </Typography>
        </Grid>

        <Grid container item xs={6} justify="flex-end" alignItems="flex-end">
          <CustomCheckBox
            checkState={isShowCapex}
            handleChange={() => setIsShowCapex(!isShowCapex)}
            label={<Typography className={classes.checkboxlabel}>Show CAPEX (Rs)</Typography>}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.container} justify="center" alignItems="center" item xs={12} data-tour="table">
        <TableContainer component={Paper} className={classes.table}>
          <Table>
            <colgroup>
              <col style={{ width: '33.34%' }} />
            </colgroup>

            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Typography variant="body1">Category</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1">Capex Increase</Typography>{' '}
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1"> Total Capex</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1">OPEX Savings</Typography>
                  <Typography variant="body1">(Annual)</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1">{`${getLabelDynamically(categoryName).toUpperCase()} SAVINGS`}</Typography>
                  <Typography variant="body1">{isMaterialsCategory ? '(One-Time)' : '(ANNUAL)'}</Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {subcategories.map((row, index) => {
                if (row?.name === 'Custom Intervention' && !row?.interventions.length) return null;
                return (
                  <CategoryRow
                    index={index}
                    key={index}
                    row={row}
                    StyledTableCell={StyledTableCell}
                    handleRefresh={handleRefresh}
                    projectID={projectID}
                    category={categoryName}
                    isShowCapex={isShowCapex}
                    data={categoryName}
                    name={row?.name}
                    handleEditCustomIntervention={(value) => handleEditCustomIntervention(value)}
                    handleEditCustominterventionID={(value) => handleEditCustominterventionID(value)}
                    handleOpenBackdrop={(v) => handleOpenBackdrop(v)}
                    setCustomInterventionFileNames={setCustomInterventionFileNames}
                    feature={categoryName}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <FlowDiagrams isOpen={isShowFlowDiagram} categoryName={categoryName} setIsShowFlowDiagram={(value) => setIsShowFlowDiagram(value)} />
    </>
  );
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.571429px',
    textTransform: 'uppercase',
  },
  body: {
    paddingTop: 0,
    paddingBottom: 0,
    whiteSpace: 'nowrap',

    lineHeight: '22px',
    letterSpacing: '0.571429px',
    color: '#000000',
    fontWeight: '600',
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
    maxWidth: '100%',
    border: '1px solid rgba(0, 0, 0, 0.14)',
    boxSizing: 'border-box',
    borderRadius: '10px',
  },
  container: {
    width: '100%',
    marginBottom: '3%',
  },
  checkboxlabel: {
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.571429px',
    color: 'rgba(0, 0, 0, 0.82)',
  },
  flowDiagram: {
    textDecoration: 'underline',
    color: '#00C58A',
    cursor: 'pointer',
  },
}));

export default CategoryTable;
