import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Grid } from '@material-ui/core';

const ToogleButton = withStyles({
  root: {
    minWidth: 1,
  },
})(Button);

export default function CustomPopover({ onEdit, onDelete, closePopover }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  useEffect(() => {
    if (closePopover) {
      handleClose();
    }
  }, [closePopover]);

  return (
    <div>
      <ToogleButton onClick={handleClick}>
        <MoreVertIcon style={{ transform: 'rotate(90deg)' }} />
      </ToogleButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container style={{ margin: '10%' }}>
          <Grid xs={12} style={{ margin: '2%', cursor: 'pointer' }}>
            <Typography variant="h6" onClick={onEdit}>
              Edit Type
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginLeft: '2%', marginTop: '10%', color: 'red', cursor: 'pointer' }}>
            <Typography variant="h6" onClick={onDelete}>
              Delete
            </Typography>
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
}
