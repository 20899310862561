import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ResetFields from '../common/textFiledwithReset';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@material-ui/icons';
const TwrCustomBasicParameters = () => {
  const classes = useStyles();
  const {
    get: { getQuantityValue, getQuantityUnit, getQuantityLabel, getQuantityDefaultValue },
    set: { updateQuantityValue },
    schema,
  } = useInterventionDetailsContext();
  const flushing = getQuantityValue(schema.twr__pi_flushing);
  const landscape = getQuantityValue(schema.twr__pi_landScaping);
  const carWash = getQuantityValue(schema.twr__pi_carWash);
  const commonAreaCleaning = getQuantityValue(schema.twr__pi_commonAreaCleaning);
  const pool = getQuantityValue(schema.twr__pi_pool);
  const domestic = getQuantityValue(schema.twr__pi_domestic);
  const reuseTreatedWater = getQuantityValue(schema.twr__pi_reuseTreatedWater);
  const offsiteReuse = getQuantityValue(schema.twr__pi_offsiteReuse);
  return (
    <Grid item xs={12} container alignItems="flex-start" direction="row">
      <div style={{ display: 'flex' }}>
        <>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textheader2}>{getQuantityLabel(schema.twr__pi_userStpCapacity)}</Typography>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '2%', marginLeft: '2%' }}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.twr__pi_userStpCapacity)}
                placeholder=""
                fullWidth
                required
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.twr__pi_userStpCapacity, +e.target.value);
                }}
                onClick={(e) => {
                  updateQuantityValue(schema.twr__pi_userStpCapacity, getQuantityDefaultValue(schema.twr__pi_userStpCapacity));
                }}
                disabled={false}
              />
              <Typography style={{ marginLeft: '5%' }}>{getQuantityUnit(schema.twr__pi_userStpCapacity)}</Typography>
            </div>
          </Grid>
        </>
        <>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textheader2}>{getQuantityLabel(schema.twr__pi_userRoCapacity)}</Typography>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '2%', marginLeft: '2%' }}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.twr__pi_userRoCapacity)}
                placeholder=""
                fullWidth
                required
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.twr__pi_userRoCapacity, +e.target.value);
                }}
                onClick={(e) => {
                  updateQuantityValue(schema.twr__pi_userRoCapacity, getQuantityDefaultValue(schema.twr__pi_userRoCapacity));
                }}
                disabled={false}
              />
              <Typography style={{ marginLeft: '5%' }}>{getQuantityUnit(schema.twr__pi_userRoCapacity)}</Typography>
            </div>
          </Grid>
        </>
        <>
          <Grid xs={12} container alignItems="flex-start" direction="column">
            <Typography className={classes.textheader2}>{getQuantityLabel(schema.twr_dischargePercentage)}</Typography>
            <Typography style={{ marginTop: '4%', marginLeft: '35%' }}>
              {getQuantityValue(schema.twr_dischargePercentage)}
              {getQuantityUnit(schema.twr_dischargePercentage)}
            </Typography>
          </Grid>
        </>
      </div>
      <div style={{ display: 'flex' }}>
        <Grid xs={10}>
          <Typography className={classes.textheader2}>Non Potable use</Typography>
          <div style={{ display: 'flex', marginTop: '2%' }}>
            {!flushing && (
              <CheckBoxOutlineBlankOutlined
                className={classes.nonselectedCheckbox}
                onClick={() => {
                  updateQuantityValue(schema.twr__pi_flushing, !flushing);
                }}
              />
            )}
            {flushing && (
              <CheckBox
                className={classes.nonselectedCheckbox}
                onClick={() => {
                  updateQuantityValue(schema.twr__pi_flushing, !flushing);
                }}
              />
            )}
            <Typography>{getQuantityLabel(schema.twr__pi_flushing)}</Typography>
          </div>
          <div style={{ display: 'flex' }}>
            {!landscape && (
              <CheckBoxOutlineBlankOutlined
                className={classes.nonselectedCheckbox}
                onClick={() => {
                  updateQuantityValue(schema.twr__pi_landScaping, !landscape);
                }}
              />
            )}
            {landscape && (
              <CheckBox
                className={classes.nonselectedCheckbox}
                onClick={() => {
                  updateQuantityValue(schema.twr__pi_landScaping, !landscape);
                }}
              />
            )}
            <Typography>{getQuantityLabel(schema.twr__pi_landScaping)}</Typography>
          </div>
          <div style={{ display: 'flex' }}>
            {!carWash && (
              <CheckBoxOutlineBlankOutlined
                className={classes.nonselectedCheckbox}
                onClick={() => {
                  updateQuantityValue(schema.twr__pi_carWash, !carWash);
                }}
              />
            )}
            {carWash && (
              <CheckBox
                className={classes.nonselectedCheckbox}
                onClick={() => {
                  updateQuantityValue(schema.twr__pi_carWash, !carWash);
                }}
              />
            )}
            <Typography>{getQuantityLabel(schema.twr__pi_carWash)}</Typography>
          </div>
          <div style={{ display: 'flex' }}>
            {!commonAreaCleaning && (
              <CheckBoxOutlineBlankOutlined
                className={classes.nonselectedCheckbox}
                onClick={() => {
                  updateQuantityValue(schema.twr__pi_commonAreaCleaning, !commonAreaCleaning);
                }}
              />
            )}
            {commonAreaCleaning && (
              <CheckBox
                className={classes.nonselectedCheckbox}
                onClick={() => {
                  updateQuantityValue(schema.twr__pi_commonAreaCleaning, !commonAreaCleaning);
                }}
              />
            )}
            <Typography>{getQuantityLabel(schema.twr__pi_commonAreaCleaning)}</Typography>
          </div>
        </Grid>
        <Grid xs={12} xm={12} xl={12} style={{ marginLeft: '15%' }}>
          <Typography className={classes.textheader2}>{getQuantityLabel(schema.twr__pi_reuseTreatedWater)}</Typography>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex' }}>
              {(reuseTreatedWater === '' || reuseTreatedWater === 'offsite-reuse') && (
                <RadioButtonUncheckedIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.twr__pi_reuseTreatedWater, 'potable-use');
                    updateQuantityValue(schema.twr__pi_offsiteReuse, false);
                  }}
                />
              )}
              {reuseTreatedWater === 'potable-use' && (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.twr__pi_reuseTreatedWater, '');
                    updateQuantityValue(schema.twr__pi_pool, false);
                    updateQuantityValue(schema.twr__pi_domestic, false);
                  }}
                />
              )}
              <Typography>Potable Use (requires RO filtration)</Typography>
            </div>
            <div style={{ marginLeft: '10%' }}>
              <div style={{ display: 'flex' }}>
                {!pool && (
                  <CheckBoxOutlineBlankOutlined
                    className={reuseTreatedWater === 'potable-use' ? classes.nonselectedCheckbox : classes.nonselectedRadioboxdisabled}
                    onClick={() => {
                      if (reuseTreatedWater === 'potable-use' && reuseTreatedWater !== '') {
                        updateQuantityValue(schema.twr__pi_pool, !pool);
                      }
                    }}
                  />
                )}
                {pool && (
                  <CheckBox
                    className={classes.nonselectedCheckbox}
                    onClick={() => {
                      updateQuantityValue(schema.twr__pi_pool, !pool);
                    }}
                  />
                )}
                <Typography style={{ color: reuseTreatedWater === 'potable-use' ? '' : '#9E9E9E' }}>{getQuantityLabel(schema.twr__pi_pool)}</Typography>
              </div>
              <div style={{ display: 'flex' }}>
                {!domestic && (
                  <CheckBoxOutlineBlankOutlined
                    className={reuseTreatedWater === 'potable-use' ? classes.nonselectedCheckbox : classes.nonselectedRadioboxdisabled}
                    onClick={() => {
                      if (reuseTreatedWater === 'potable-use' && reuseTreatedWater !== '') {
                        updateQuantityValue(schema.twr__pi_domestic, !domestic);
                      }
                    }}
                  />
                )}
                {domestic && (
                  <CheckBox
                    className={classes.nonselectedCheckbox}
                    onClick={() => {
                      updateQuantityValue(schema.twr__pi_domestic, !domestic);
                    }}
                  />
                )}
                <Typography style={{ color: reuseTreatedWater === 'potable-use' ? '' : '#9E9E9E' }}>{getQuantityLabel(schema.twr__pi_domestic)}</Typography>
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex' }}>
              {(reuseTreatedWater === '' || reuseTreatedWater === 'potable-use') && (
                <RadioButtonUncheckedIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.twr__pi_pool, false);
                    updateQuantityValue(schema.twr__pi_domestic, false);
                    updateQuantityValue(schema.twr__pi_reuseTreatedWater, 'offsite-reuse');
                  }}
                />
              )}
              {reuseTreatedWater === 'offsite-reuse' && (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.twr__pi_reuseTreatedWater, '');
                    updateQuantityValue(schema.twr__pi_offsiteReuse, false);
                  }}
                />
              )}
              <Typography>Offsite Reuse</Typography>
            </div>
            <div style={{ marginLeft: '10%' }}>
              <div style={{ display: 'flex' }}>
                {!offsiteReuse && (
                  <CheckBoxOutlineBlankOutlined
                    className={reuseTreatedWater === 'offsite-reuse' ? classes.nonselectedCheckbox : classes.nonselectedRadioboxdisabled}
                    onClick={() => {
                      if (reuseTreatedWater === 'offsite-reuse' && reuseTreatedWater !== '') {
                        updateQuantityValue(schema.twr__pi_offsiteReuse, !offsiteReuse);
                      }
                    }}
                  />
                )}
                {offsiteReuse && (
                  <CheckBox
                    className={classes.nonselectedCheckbox}
                    onClick={() => {
                      updateQuantityValue(schema.twr__pi_offsiteReuse, !offsiteReuse);
                    }}
                  />
                )}
                <Typography style={{ color: reuseTreatedWater === 'offsite-reuse' ? '' : '#9E9E9E' }}>
                  {getQuantityLabel(schema.twr__pi_offsiteReuse)}
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
      </div>
    </Grid>
  );
};
export default TwrCustomBasicParameters;
