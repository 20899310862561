import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';

const CustomTextField = ({ value, onChange, disabled, placeholder, helperText, height, error, color }) => {
  const useStyles = makeStyles(() => ({
    textField: {
      minWidth: '100%',
      '& .MuiOutlinedInput-root': {
        height: height ? height : '9vh',
        '&:hover fieldset': {
          borderColor: color || 'rgba(51, 81, 142, 1)',
        },
        '&.Mui-focused fieldset': {
          border: `3px solid ${color || 'rgba(51, 81, 142, 1)'}`,
        },
      },
      '& .MuiInputLabel-root': {
        color: 'rgba(121, 116, 126, 1)',
      },
      '& .Mui-focused.MuiInputLabel-root': {
        color: color || 'rgba(51, 81, 142, 1)',
      },
    },
  }));
  const classes = useStyles();

  return (
    <TextField
      id="outlined-helperText"
      label={placeholder}
      variant="outlined"
      value={value}
      onChange={onChange}
      disabled={disabled}
      className={classes.textField}
      fullWidth
      helperText={helperText}
      error={error}
    />
  );
};

export default CustomTextField;
