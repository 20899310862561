import React, { useEffect, useState } from 'react';
import { Collapse, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import CustomHeader from '../../../certification/common/CustomHeader';
import { Sections } from '../../../certification/edit/ResidentHealthAndWellBeing/DataTypes';
import CustomDrawerEnvelope from './CustomDrawerEnvelope';
import TowersTable from './envelopeTables/TowersTable';
import { useParams } from 'react-router';
import useAppProjectDetails from '../../hooks/useAppProjectDetails';
import useAppPIPScheme from '../../hooks/useAppPIPScheme';
import { getPIP, mergeArrays } from '../../../interventions/intervention/utils';
import useAppProjectPIPMapping from '../../hooks/useAppProjectPIPMapping';
import axios from 'axios';
import DeleteModal from './DeleteModal';

const TowerSpecifications = ({ form, handlePIPValueUpdate, projectSubcategory, isTowerAvailable, setTowerData, setIsTowerAvailable, mount, setMount }) => {
  const history = useHistory();
  let { id } = useParams();
  const [collapseSection, setCollapseSection] = useState(true);
  const [localTowerData, setLocalTowerData] = useState([]);
  const [editTable, setEditTable] = useState(false);
  const [editableId, setEditableId] = useState();
  const [editableData, setEditableData] = useState('');
  const [update, setUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [towerToDelete, setTowerToDelete] = useState();
  const [closePopover, setClosePopover] = useState(false);
  const { data: envelopePIPs } = useAppPIPScheme('envelope');
  const requiredPipId = getPIP(envelopePIPs, 'project__pi_towers')?._id;
  setIsTowerAvailable(form?.pipvalues?.[requiredPipId]?.length > 0 || localTowerData?.length > 0);
  useEffect(() => {
    setTowerData(mergeArrays(localTowerData, form?.pipvalues[requiredPipId] || []));
  }, [isTowerAvailable, localTowerData, form]);
  const handleExpandIcon = () => setCollapseSection((prev) => !prev);
  const handleDelete = () => {
    const towersPipId = getPIP(envelopePIPs, 'project__pi_towerOpenings')?._id;
    const towerPerOpeningValues = form?.pipvalues[towersPipId];
    const existingPipValue = form?.pipvalues[requiredPipId];
    let updatedPipValues = [];
    let updatedTowerPerOpeningValues = [];
    if (existingPipValue.length > 0) {
      updatedPipValues = existingPipValue.filter((item) => item._id !== towerToDelete);
      updatedTowerPerOpeningValues = towerPerOpeningValues.filter((item) => item.towerId !== towerToDelete);
    } else updatedPipValues = localTowerData.filter((item) => item._id !== towerToDelete);
    setLocalTowerData(updatedPipValues);
    handlePIPValueUpdate(requiredPipId, updatedPipValues);
    handlePIPValueUpdate(towersPipId, updatedTowerPerOpeningValues);
    setUpdate(!update);
  };
  return (
    <>
      <Grid xs={12}>
        <DeleteModal setClosePopover={setClosePopover} handleDelete={handleDelete} showModal={showModal} setShowModal={setShowModal} />
      </Grid>
      {/* Header */}
      <CustomHeader
        sectionName={projectSubcategory === 'apartment' ? 'Tower Specifications' : 'Villa Type Specifications'}
        collapseSection={collapseSection}
        i={1}
        history={history}
        expand={true}
        handleExpandIcon={handleExpandIcon}
      />
      <Typography style={{ fontWeight: '400', color: '#00000061' }}>
        Enter the external façade lengths for each orientation of primary living areas (living, dining, study, bedroom, kitchen and bathrooms). These values
        will be used for RETV computation to account for envelope thermal performance.
      </Typography>
      {/* Drawer */}
      <CustomDrawerEnvelope
        form={form}
        handlePIPValueUpdate={handlePIPValueUpdate}
        projectSubcategory={projectSubcategory}
        setClosePopover={setClosePopover}
        setLocalTowerData={setLocalTowerData}
        envelopePIPs={envelopePIPs}
        editableId={editableId}
        customAddButton={true}
        title={projectSubcategory === 'apartment' ? 'ADD TOWER' : 'ADD VILLA TYPE'}
        section={'TOWER'}
        editTable={editTable}
        handleEditTable={(val) => setEditTable(val)}
        editableData={editableData}
        handleEditableData={(d) => setEditableData(d)}
        setUpdate={setUpdate}
        update={update}
        mount={mount}
        setMount={setMount}
      />

      {/* Table */}
      <Collapse in={collapseSection}>
        <TowersTable
          projectSubcategory={projectSubcategory}
          closePopover={closePopover}
          localTowerData={localTowerData}
          handleEdit={(towerId) => {
            setEditableId(towerId);
            setEditTable(true);
          }}
          setTowerToDelete={setTowerToDelete}
          setShowModal={setShowModal}
          setEditableData={setEditableData}
          editTable={editTable}
          editableId={editableId}
          update={update}
          requiredPipId={requiredPipId}
          setUpdate={setUpdate}
          mount={mount}
          setMount={setMount}
        />
      </Collapse>
    </>
  );
};

export default TowerSpecifications;
