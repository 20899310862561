import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import useStyles from './styles';
import ResetFields from '../../../interventions/interventionDetails/common/textFiledwithReset';

const SteelMaterialDetails = ({ data, appendChanged, pipValues }) => {
  const [selectedCustom, setSelectedCustom] = React.useState(false);
  const classes = useStyles();
  const factorUserInput = data?.find((item) => item.name === 'project__pi_quantityFactorOptionFive')?._id;

  const customLabels = ['37.67 (3.5 kg / sqft)', '43.06 (4 kg / sqft) (Default)', '48.44 (4.5 kg / sqft)', '53.82 (5 kg / sqft)', 'Custom'];

  const handleSelection = (selectedId) => {
    data?.forEach((pip) => {
      if (pip.type === 'boolean') appendChanged(pip._id, false);
    });
    appendChanged(selectedId, true);
  };

  const renderRadioOption = (pip, index) => {
    const isSelected = !!pipValues[pip._id];
    return (
      <Typography className={classes.textHeader3}>
        {isSelected ? (
          <RadioButtonCheckedIcon
            className={classes.selectedRadiobox}
            onClick={() => {
              handleSelection(pip._id);
              if (pip._id === factorUserInput) setSelectedCustom(true);
              else setSelectedCustom(false);
            }}
          />
        ) : (
          <RadioButtonUncheckedIcon
            className={classes.nonselectedRadiobox}
            onClick={() => {
              handleSelection(pip._id);
              if (pip._id === factorUserInput) setSelectedCustom(true);
              else setSelectedCustom(false);
            }}
          />
        )}
        {customLabels[index]}
      </Typography>
    );
  };

  const renderNumberInput = (pip) => (
    <Grid item key={pip._id} container alignItems="center" spacing={1} style={{ marginLeft: '2%', marginTop: '1%' }}>
      <Grid xs={10} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
        <Grid xs={2}>
          <ResetFields
            id="outlined-basic"
            variant="outlined"
            value={pipValues[pip._id]}
            placeholder=""
            onChange={(e) => {
              e.preventDefault();
              appendChanged(pip._id, e.target.value);
            }}
            fullWidth
            required
            disabled={!pipValues[factorUserInput]}
            onClick={() => appendChanged(pip._id, 20)}
          />
        </Grid>
        <Grid className={classes.grid} style={{ paddingLeft: '10px' }}>
          <Typography className={classes.textHeader2}>kg / sqft</Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '1%' }}>
        <Grid item xs={12}>
          <Typography variant="h5">
            <b>Structural Steel Quantity Factor</b>
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginLeft: '2%', marginBottom: '1%' }}>
          {data?.map((pip, index) => {
            if (pip.type === 'boolean' && index < customLabels.length) return renderRadioOption(pip, index);
            if (pip.type === 'number' && selectedCustom) return renderNumberInput(pip);
            return null;
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default SteelMaterialDetails;
