import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, TableRow, TableHead, TableContainer, TableCell, TableBody, Table } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';

import { Sections } from '../../DataTypes';
import CustomPopover from '../../common/CustomPopover';
import { ResidentHealthAndWellBeingContext } from '../..';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

export default function CustomTable({ handleEdit, update, setUpdate, mount, setMount }) {
  const classes = useStyles();

  const { data, handleData } = useContext(ResidentHealthAndWellBeingContext);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (data && data['customData'] && data['customData'][0] && data['customData'][0]['data']) {
      const customData = data['customData'][0]['data'];
      const foundData = customData.find((d) => d.section === Sections.Dwelling_Unit_Types);
      if (foundData) setTableData(foundData['data']);
    }
  }, [data, update]);

  const handleDelete = (data, row, type, index, update) => {
    const length =
      data['customData'] &&
      data['customData'][0] &&
      data['customData'][0]['data'].find((d) => d.section === Sections.Dwelling_Unit_Types)?.data?.find((f) => f.type === type)['data'].length;
    if (length === 1) {
      const typeDefined = type + ` ` + row.name;
      const dwellingUnitInteriorIndex = data['customData'][0]['data']
        .find((d) => d.section === Sections.Dwelling_Unit_Interiors)
        ?.data.findIndex((s) => s.type === typeDefined);
      data['customData'][0]['data'].find((d) => d.section === Sections.Dwelling_Unit_Interiors)?.data.splice(dwellingUnitInteriorIndex, 1);
      const index = data['customData'][0]['data'].find((d) => d.section === Sections.Dwelling_Unit_Types)?.data.findIndex((s) => s.type === type);
      data['customData'][0]['data'].find((d) => d.section === Sections.Dwelling_Unit_Types)?.data.splice(index, 1);
    } else {
      const typeDefined = type + ` ` + row.name;
      const dwellingUnitInteriorIndex = data['customData'][0]['data']
        .find((d) => d.section === Sections.Dwelling_Unit_Interiors)
        ?.data.findIndex((s) => s.type === typeDefined);
      data['customData'][0]['data'].find((d) => d.section === Sections.Dwelling_Unit_Interiors)?.data.splice(dwellingUnitInteriorIndex, 1);
      data['customData'][0]['data']
        .find((d) => d.section === Sections.Dwelling_Unit_Types)
        ?.data?.find((f) => f.type === type)
        ['data'].splice(index, 1);
    }
    handleData(data);
    setUpdate(!update);
    setMount(!mount);
  };

  return (
    <>
      {tableData && tableData.length != 0 && (
        <Grid container xs={12} style={{ marginBottom: '3%' }}>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ border: 'none' }}>
                    <Typography>Name</Typography>
                  </TableCell>
                  <TableCell align="left" style={{ border: 'none' }}>
                    <Typography>Floor Area(sqm)</Typography>
                  </TableCell>
                  <TableCell align="left" style={{ border: 'none' }}>
                    <Typography>Daylighting Area</Typography>
                  </TableCell>
                  <TableCell align="left" style={{ border: 'none' }}>
                    <Typography>Cross Ventilation Area</Typography>
                  </TableCell>
                  <TableCell align="left" style={{ border: 'none' }}>
                    <Typography>Connectivity To Exteriors Area</Typography>
                  </TableCell>
                  <TableCell align="left" style={{ border: 'none' }}>
                    <Typography>Ventilation Credit</Typography>
                  </TableCell>
                  <TableCell align="left" style={{ border: 'none' }}>
                    <Typography>Ventilation Mandate</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              {tableData &&
                tableData.map((d, index) => {
                  return (
                    <>
                      <Grid
                        container
                        style={
                          index > 0 ? { marginTop: '15%', marginBottom: '2%', paddingLeft: '4%' } : { marginTop: '3%', marginBottom: '2%', paddingLeft: '4%' }
                        }
                        alignItems="center"
                      >
                        <Grid>
                          <Typography variant="h6">
                            <b>{d.type}</b>
                          </Typography>
                        </Grid>
                        <Grid>
                          <ExpandMoreIcon style={{ color: '#49DDB1' }} fontSize="large" />
                        </Grid>
                      </Grid>
                      <TableBody style={{ backgroundColor: '#F6F6F6', borderBottom: '1px solid #9E9E9E' }}>
                        {d?.data.map((row, index) => (
                          <TableRow key={row.name}>
                            <TableCell>
                              <Typography variant="h6">{row.name}</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="h6">{row.floorarea}</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="h6">{row.dayLightingArea}</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="h6">{row.crossVentilationArea}</Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="h6">{row.connectivityToExteriorsArea}</Typography>
                            </TableCell>
                            <TableCell align="left">
                              {row.ventilationCredit ? (
                                <CheckBoxIcon style={{ color: '#66E3BE', cursor: 'pointer' }} onClick={() => false} />
                              ) : (
                                <CheckBoxOutlineBlankIcon style={{ color: '#66E3BE', cursor: 'pointer' }} onClick={() => true} />
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {row.ventilationMandate ? (
                                <CheckBoxIcon style={{ color: '#66E3BE', cursor: 'pointer' }} onClick={() => false} />
                              ) : (
                                <CheckBoxOutlineBlankIcon style={{ color: '#66E3BE', cursor: 'pointer' }} onClick={() => true} />
                              )}
                            </TableCell>
                            <TableCell align="left" style={{ borderBottom: '1px solid #fbfbfb', backgroundColor: '#fbfbfb' }}>
                              <CustomPopover onEdit={() => handleEdit(row, d.type, index)} onDelete={() => handleDelete(data, row, d.type, index, update)} />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </>
                  );
                })}
            </Table>
          </TableContainer>
        </Grid>
      )}
    </>
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 10,
  },
});
