import React from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  MenuItem,
  Select,
  TableCell,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import deleteIconDisabled from '../../../../../assets/manageusers/deleteicondisabled.svg';
import deleteIconEnabled from '../../../../../assets/manageusers/deleteiconenabled.svg';
import { truncateAnyText } from '../../../../../utils/truncketAnyText';
import PrimaryButton from '../../../../common/button/Button';
import { makeStyles } from '@material-ui/core';
import { capitalizeFirstLetter } from '../../../../../utils/capitaliseFirstLetter';

export const STATUS_COLORS = {
  Active: '#4caf50',
  Pending: '#ffa726',
  'Link Expired': '#f44336',
  'Invite Failed': '#f44336',
  default: '#757575',
};

export const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 200,
    maxWidth: '100%',
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  menuPaper: {
    maxHeight: 300,
  },
  organizationName: {
    fontWeight: 500,
  },
  organizationCode: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1),
    fontSize: '0.875rem',
  },
  noOrganizations: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  icon: {
    marginTop: '3%',
  },
}));

export const getStatusColor = (status) => STATUS_COLORS[status] || STATUS_COLORS.default;

export const TableHeaderCell = ({ width = '15%', children, classes, handleResendInvite, user }) => (
  <TableCell style={{ border: 'none', width, textAlign: 'left' }} className={classes.tableCell}>
    <Grid container xs={12} justifyContent="flex-start" alignItems="center">
      <Grid container item xs={2} justifyContent="flex-start" alignItems="center">
        <Typography variant="h6" style={{ color: 'black' }}>
          <b>{children}</b>
        </Typography>
      </Grid>
      <Grid container item xs={5} justifyContent="flex-start" alignItems="center">
        {children === 'Status' && (user?.status === 'Link Expired' || user?.status === 'Invite Failed') && (
          <span style={{ color: '#00C58A', cursor: 'pointer', marginLeft: '8px' }} onClick={() => handleResendInvite(user?._id)}>
            Resend Invite
          </span>
        )}
      </Grid>
    </Grid>
  </TableCell>
);

export const UserTableRow = ({ user, roles, onRoleChange, setHoveredUser, classes, currentUserData }) => {
  return (
    <TableRow key={user.id} className={classes.tableCell}>
      <TableCell style={{ textAlign: 'left', wordWrap: 'break-word' }} className={classes.tableCell}>
        <Typography variant="h6">{user.username}</Typography>
      </TableCell>

      <TableCell style={{ textAlign: 'left', wordWrap: 'break-word' }} className={classes.tableCell}>
        <Typography variant="h6">{truncateAnyText(user.email, 35)}</Typography>
      </TableCell>

      <TableCell className={classes.tableCell}>
        <Select value={user.role} onChange={(e) => onRoleChange(user._id, e.target.value)} classes={{ icon: classes.icon }} disableUnderline>
          {roles.map(({ name }) => (
            <MenuItem key={name} disabled={user?.email === currentUserData?.email} value={name}>
              {capitalizeFirstLetter(name)}
            </MenuItem>
          ))}
        </Select>
      </TableCell>

      <TableCell
        style={{
          color: getStatusColor(user.status),
          textAlign: 'left',
        }}
        className={classes.tableCell}
      >
        <Typography variant="h6">{user.status}</Typography>
      </TableCell>

      <TableCell>
        <Grid container justifyContent="flex-start" textAlign="center">
          <img
            disabled={user.role.toLowerCase() === 'admin'}
            src={user.role.toLowerCase() === 'admin' ? deleteIconDisabled : deleteIconEnabled}
            alt="delete icon"
            className={classes.deleteIcon}
            onMouseEnter={() => setHoveredUser(user._id)}
            onMouseLeave={() => setHoveredUser(null)}
            onClick={() => (user.role.toLowerCase() === 'admin' ? null : onRoleChange(user._id, 'removeUser'))}
          />
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export const UserStats = ({ activeUsers, pendingUsers }) => (
  <Grid item container xs={12} justifyContent="flex-start" alignItems="center">
    <Grid item container xs={3} justifyContent="flex-start" alignItems="center">
      <Typography variant="h6" style={{ marginTop: 16 }}>
        Active Users :
      </Typography>
      <Typography color="primary" style={{ marginTop: 16, marginLeft: '10px' }}>
        <strong>{activeUsers}</strong>
      </Typography>
    </Grid>
  </Grid>
);

export const UserList = ({ filteredUsers, roles, onRoleChange, hoveredUser, setHoveredUser, classes, currentUserData, handleResendInvite }) => (
  <>
    {filteredUsers.map((user, index) => (
      <TableContainer key={user._id} component={Paper} className={classes.table} style={{ marginTop: 16 }}>
        <Table>
          <TableHead className={classes.tableHeader}>
            <TableRow className={classes.tableRow}>
              <TableHeaderCell classes={classes} children="Username" />
              <TableHeaderCell classes={classes} width="25%" children="Email" />
              <TableHeaderCell classes={classes} children="Role" />
              <TableHeaderCell classes={classes} children="Status" user={user} handleResendInvite={(userId) => handleResendInvite(userId)} />
              <TableHeaderCell classes={classes} width="8%" children="Delete" />
            </TableRow>
          </TableHead>

          <TableBody>
            <UserTableRow
              currentUserData={currentUserData}
              user={user}
              index={index}
              roles={roles}
              onRoleChange={onRoleChange}
              hoveredUser={hoveredUser}
              setHoveredUser={setHoveredUser}
              classes={classes}
            />
          </TableBody>
        </Table>
      </TableContainer>
    ))}
  </>
);

export const OrganizationSelector = ({
  organizations = [],
  selectedOrg,
  setSelectedOrg,
  disabled = false,
  error = false,
  helperText = '',
  required = false,
}) => {
  const classes = useStyles();

  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedOrg(newValue);
  };

  return (
    <FormControl variant="outlined" className={classes.formControl} error={error} required={required} disabled={disabled}>
      <InputLabel id="organization-select-label">Organization</InputLabel>
      <Select
        labelId="organization-select-label"
        id="organization-select"
        value={selectedOrg || ''}
        onChange={handleChange}
        label="Organization"
        classes={{
          root: classes.select,
          icon: classes.icon,
        }}
        MenuProps={{
          classes: { paper: classes.menuPaper },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
      >
        {organizations.length === 0 ? (
          <MenuItem value="" disabled>
            <Typography className={classes.noOrganizations}>No organizations available</Typography>
          </MenuItem>
        ) : (
          organizations.map((org) => (
            <MenuItem key={org._id} value={org._id}>
              <span className={classes.organizationName}>{org.name}</span>
            </MenuItem>
          ))
        )}
      </Select>
      {helperText && (
        <Typography variant="caption" color={error ? 'error' : 'textSecondary'}>
          {helperText}
        </Typography>
      )}
    </FormControl>
  );
};

export const NoUsersAvailable = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h3 className="text-xl font-semibold text-gray-700 mb-2">No Users Available</h3>
      <p className="text-gray-500 mb-4">There are no users available for this organization.</p>
    </div>
  );
};

export const DeleteUserConfirmation = ({ deleteConfirmOpen, onClose, onCancel, handleDeleteConfirm, selectedUser }) => {
  return (
    <Dialog
      open={deleteConfirmOpen}
      onClose={onClose}
      aria-labelledby="delete-dialog-title"
      style={{ textAlign: 'center' }}
      PaperProps={{
        style: {
          width: '30%',
          padding: '20px',
          height: 'auto',
        },
      }}
    >
      <DialogTitle id="delete-dialog-title">
        <Typography variant="h6">Please Confirm</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h5">Are you sure to delete this user?</Typography>
      </DialogContent>
      <DialogActions>
        <Grid container style={{ marginTop: '4%' }} alignItems="center" justifyContent="center">
          <Grid xs={7}>
            <Typography variant="h6" onClick={onCancel} color="primary" style={{ cursor: 'pointer' }}>
              CANCEL
            </Typography>
          </Grid>
          <Grid xs={5}>
            <PrimaryButton
              variant="contained"
              color="primary"
              children="delete"
              onClick={() => handleDeleteConfirm(selectedUser)}
              style={{ cursor: 'pointer' }}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
