import { api } from './http';

const getIGBCData = async (projectId) => {
  const response = await api.get(`/v1/projectv2/${projectId}/igbc-data`);
  return response;
};

const getIGBCCategory = async () => {
  const response = await api.get(`/v1/igbc-category`);
  return response;
};

export { getIGBCData, getIGBCCategory };
