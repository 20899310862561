import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

// Common components
import PrimaryButton from '../../../../common/button/Button';
import CasaSkeleton from '../../../../common/skeleton/CasaSkeleton';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import CustomBreadCrumbs from '../../../../common/breadcrumbs/CustomBreadCrumbs';

import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  logo: {
    height: '90%',
    marginLeft: '13%',
  },
  cardheading: {
    textAlign: 'center',
    marginLeft: '0%',
    paddingLeft: '3%',
    paddingRight: '5%',
  },
  cardbody: {
    textAlign: 'center',
    marginTop: '8%',
  },
  subBody: {
    color: '#9E9E9E',
    paddingLeft: '2%',
  },
  paper: {
    height: '9vh',
    width: '120%',
    background: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
  },
  grid1: {
    marginTop: '-2%',
  },
  grid2: {
    marginLeft: '3%',
  },
  line: {
    height: 40,
  },
}));

const InterventionDetailsHeader = () => {
  const classes = useStyles();
  const params = useParams();
  const { intervention, key } = useInterventionDetailsContext();
  const { getIconByFeatureKey } = useAppMetafireUtilities();
  return (
    <>
      <Grid container alignItems="center" className={classes.root}>
        <CustomBreadCrumbs
          links={[{ href: `/projects/${params.id}/interventions`, text: 'Interventions' }]}
          stage={
            intervention ? <b>{intervention.name}</b> : <CasaSkeleton variant="rect" height="2vh" width={80} animation="wave" style={{ marginTop: '1%' }} />
          }
        />
        {/* Only for changing values interventions cost and assumption box */}
        <Grid container item xs={12} alignItems="center" justify="flex-start" spacing={2} className={classes.root}>
          {!getIconByFeatureKey(key, true) ? null : (
            <Grid item xs={1}>
              <img src={getIconByFeatureKey(key, true)} alt="Back" />
            </Grid>
          )}
          <Grid item xs={9}>
            {intervention ? (
              <Typography variant="h3" style={{ marginLeft: '-2%' }}>
                {intervention.name}
              </Typography>
            ) : (
              <CasaSkeleton variant="rect" height="6vh" width={200} animation="wave" />
            )}
          </Grid>
          <Grid item xs={2} style={{ display: 'none' }}>
            <PrimaryButton children="save changes" onClick={() => {}} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default InterventionDetailsHeader;
