import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import useStyles from './styles';

const EfficientFixturesShowerCustomBasicParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityLabel, getQuantityUnit },
  } = useInterventionDetailsContext();

  const {
    efficientFixturesShower_brand,
    efficientFixturesShower_productName,
    efficientFixturesShower_fixtureFlowRate,
    efficientFixturesShower_vendorPerUnitCapex,
  } = schema;

  const brandTitle = getQuantityLabel(efficientFixturesShower_brand);
  const brandValue = getQuantityValue(efficientFixturesShower_brand);
  const productTitle = getQuantityLabel(efficientFixturesShower_productName);
  const productValue = getQuantityValue(efficientFixturesShower_productName);
  const fixtureFlowTitle = getQuantityLabel(efficientFixturesShower_fixtureFlowRate);
  const fixtureFlowValue = getQuantityValue(efficientFixturesShower_fixtureFlowRate);
  const fixtureFlowUnit = getQuantityUnit(efficientFixturesShower_fixtureFlowRate);
  const perUnitCapexTitle = getQuantityLabel(efficientFixturesShower_vendorPerUnitCapex);
  const perUnitCapexValue = getQuantityValue(efficientFixturesShower_vendorPerUnitCapex);
  const perUnitCapexUnit = getQuantityUnit(efficientFixturesShower_vendorPerUnitCapex);

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid item xs={8} container alignItems="flex-start">
        <Grid item xs={6}>
          <Typography className={classes.textHeader3}>{brandTitle}</Typography>
          <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
            {brandValue}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.textHeader3}>{productTitle}</Typography>
          <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
            {productValue}
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="row" alignItems="left" item xs={12} style={{ marginTop: '3%' }}>
        <Grid item xs={8} container alignItems="flex-start">
          <Grid item xs={6}>
            <Typography className={classes.textHeader3}>{fixtureFlowTitle}</Typography>
            <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
              {fixtureFlowValue} {fixtureFlowUnit}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.textHeader3}>{perUnitCapexTitle}</Typography>
            <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
              {perUnitCapexValue} {perUnitCapexUnit}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default EfficientFixturesShowerCustomBasicParameters;
