/* eslint-disable react-hooks/exhaustive-deps */
import { Typography, Grid } from '@material-ui/core';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import useStyles from './styles';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@material-ui/icons';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ResetFields from '../common/textFiledwithReset';
import numberWithCommas from '../../../../../utils/numberToComma';
import { useEffect, useState } from 'react';

const WallBlocksInteriorCustomAdvancedParameters = (props) => {
  const { setSaveError } = props;
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();

  const [customSpecificationError, setCustomSpecificationError] = useState([]);
  const [customCapexError, setCustomCapexError] = useState(false);

  const isCustomSpecification = getQuantityValue(schema.wallBlocksInterior__pi_customWallBlockSpecification);
  const isCustomCapex = getQuantityValue(schema.wallBlocksInterior__pi_capexMultiplierSource) === 'CUSTOM' || isCustomSpecification;

  useEffect(() => {
    validateCustomSpecification();
  }, [
    isCustomSpecification,
    getQuantityValue(schema.wallBlocksInterior__pi_customBlockWeight),
    getQuantityValue(schema.wallBlocksInterior__pi_customBlockThickness),
    getQuantityValue(schema.wallBlocksInterior__pi_customBlockVolume),
    getQuantityValue(schema.wallBlocksInterior__pi_customBlockEC),
    isCustomCapex,
    getQuantityValue(schema.wallBlocksInterior__pi_customCapex),
  ]);

  const validateCustomSpecification = () => {
    let isValid = true;

    const requiredFields = [
      schema.wallBlocksInterior__pi_customBlockWeight,
      schema.wallBlocksInterior__pi_customBlockThickness,
      schema.wallBlocksInterior__pi_customBlockVolume,
      schema.wallBlocksInterior__pi_customBlockEC,
    ];

    let errorFields = [];

    if (isCustomSpecification) {
      errorFields = requiredFields.filter((field) => {
        const value = getQuantityValue(field);
        return value === null || value === undefined || value === '';
      });
    }

    if (errorFields.length > 0) {
      isValid = false;
      setCustomSpecificationError(errorFields);
    }

    if (isCustomCapex && !getQuantityValue(schema.wallBlocksInterior__pi_customCapex)) {
      isValid = false;
      setCustomCapexError(true);
    }

    if (isValid) {
      setSaveError(false);
      setCustomSpecificationError([]);
      setCustomCapexError(false);
    } else {
      setSaveError(true);
    }
  };

  return (
    <Grid container direction="row" alignItems="left" item xs={12}>
      <Grid item xs={4} direction="column">
        <Grid container item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.textheader2}>{getQuantityLabel(schema.wallBlocksInterior__pi_cementPerVolume)}</Typography>
        </Grid>
        <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
          <Grid xs={7}>
            <ResetFields
              id="outlined-basic"
              variant="outlined"
              value={getQuantityValue(schema.wallBlocksInterior__pi_cementPerVolume)}
              placeholder=""
              onChange={(e) => {
                e.preventDefault();
                updateQuantityValue(schema.wallBlocksInterior__pi_cementPerVolume, e.target.value);
              }}
              fullWidth
              required
              removeResetIcon={true}
              disabled={false}
            />
          </Grid>
          <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
            <Typography variant="h6">{getQuantityUnit(schema.wallBlocksInterior__pi_cementPerVolume)}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid style={{ marginTop: '2%' }} container direction="row" alignItems="left" item xs={12}>
        <Grid item xs={4} direction="column">
          <Typography className={classes.textHeader1} style={{ paddingTop: '1%' }}>
            Intervention Cost Assumptions
            <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention </Typography>} placement="right" />
          </Typography>
          <Typography className={classes.textHeader4}>CAPEX (Rs/block)</Typography>
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={4} direction="column">
            <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
              <Typography className={classes.textHeader3}>
                {!isCustomCapex ? (
                  <CheckCircleIcon className={classes.selectedCheckbox} />
                ) : (
                  <RadioButtonUncheckedIcon
                    className={classes.nonselectedCheckbox}
                    onClick={() => {
                      updateQuantityValue(schema.wallBlocksInterior__pi_capexMultiplierSource, 'VENDOR');
                      updateQuantityValue(schema.wallBlocksInterior__pi_customWallBlockSpecification, false);
                    }}
                  />
                )}
                Selected Vendor
              </Typography>
            </Grid>

            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <Typography style={{ marginLeft: '4%', marginTop: '4%' }} className={isCustomCapex ? classes.textconstantsDisabled : classes.textconstants}>
                  {numberWithCommas(getQuantityValue(schema.wallBlocksInterior_vendorCapex))} {getQuantityUnit(schema.wallBlocksInterior_vendorCapex)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4} direction="column">
            <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
              <Typography className={classes.textHeader3}>
                {isCustomCapex ? (
                  <CheckCircleIcon className={classes.selectedCheckbox} />
                ) : (
                  <RadioButtonUncheckedIcon
                    className={classes.nonselectedCheckbox}
                    onClick={() => {
                      updateQuantityValue(schema.wallBlocksInterior__pi_capexMultiplierSource, 'CUSTOM');
                    }}
                  />
                )}
                Custom
              </Typography>
            </Grid>

            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  value={getQuantityValue(schema.wallBlocksInterior__pi_customCapex)}
                  placeholder=""
                  onChange={(e) => {
                    e.preventDefault();
                    updateQuantityValue(schema.wallBlocksInterior__pi_customCapex, e.target.value);
                  }}
                  fullWidth
                  required
                  disabled={!isCustomCapex}
                  removeResetIcon={true}
                  isError={customCapexError}
                  unit={getQuantityUnit(schema.wallBlocksInterior_vendorCapex)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid style={{ marginTop: '2%' }} container direction="row" alignItems="left" item xs={12}>
        <Grid item xs={4} direction="column">
          <Typography className={classes.textHeader1} style={{ paddingTop: '1%' }}>
            Custom Wall Block Specifications
          </Typography>
        </Grid>
        <Grid item xs={12} direction="column" style={{ marginTop: '2%' }}>
          <Grid xs={12} container alignItems="center" direction="row">
            {isCustomSpecification ? (
              <CheckBox
                className={classes.selectedCheckbox}
                onClick={() => updateQuantityValue(schema.wallBlocksInterior__pi_customWallBlockSpecification, false)}
              />
            ) : (
              <CheckBoxOutlineBlankOutlined
                className={classes.selectedCheckbox}
                onClick={() => updateQuantityValue(schema.wallBlocksInterior__pi_customWallBlockSpecification, true)}
              />
            )}
            <Typography>I have custom Wall Block specifications</Typography>
          </Grid>

          {isCustomSpecification && (customCapexError || customSpecificationError.length) ? (
            <Grid item xs={12}>
              <Typography variant="caption" style={{ color: 'red' }}>
                All fields are mandatory including Custom CAPEX
              </Typography>
            </Grid>
          ) : null}
        </Grid>

        <Grid item xs={12} direction="row" style={{ display: 'flex' }}>
          <Grid item xs={4} container alignItems="flex-start" direction="row">
            <Grid xs>
              <Typography className={classes.textHeader5}>{getQuantityLabel(schema.wallBlocksInterior__pi_customBlockWeight)}</Typography>
            </Grid>

            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  value={getQuantityValue(schema.wallBlocksInterior__pi_customBlockWeight) || ''}
                  placeholder=""
                  onChange={(e) => {
                    e.preventDefault();
                    updateQuantityValue(schema.wallBlocksInterior__pi_customBlockWeight, e.target.value);
                  }}
                  fullWidth
                  required
                  removeResetIcon={true}
                  disabled={!isCustomSpecification}
                  isError={customSpecificationError.includes(schema.wallBlocksInterior__pi_customBlockWeight)}
                />
              </Grid>

              <Grid xs={3} className={isCustomSpecification ? classes.grid : classes.textHeader3disabled} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">{getQuantityUnit(schema.wallBlocksInterior__pi_customBlockWeight)}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4} container alignItems="flex-start" direction="row">
            <Grid xs>
              <Typography className={classes.textHeader5}>{getQuantityLabel(schema.wallBlocksInterior__pi_customBlockThickness)}</Typography>
            </Grid>

            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  value={getQuantityValue(schema.wallBlocksInterior__pi_customBlockThickness) || ''}
                  placeholder=""
                  onChange={(e) => {
                    e.preventDefault();
                    updateQuantityValue(schema.wallBlocksInterior__pi_customBlockThickness, e.target.value);
                  }}
                  fullWidth
                  required
                  removeResetIcon={true}
                  disabled={!isCustomSpecification}
                  isError={customSpecificationError.includes(schema.wallBlocksInterior__pi_customBlockThickness)}
                />
              </Grid>
              <Grid xs={3} className={isCustomSpecification ? classes.grid : classes.textHeader3disabled} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">{getQuantityUnit(schema.wallBlocksInterior__pi_customBlockThickness)}</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4} direction="column">
            <Grid container item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <Typography className={classes.textHeader5}>{getQuantityLabel(schema.wallBlocksInterior__pi_customBlockVolume)}</Typography>
            </Grid>

            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  value={getQuantityValue(schema.wallBlocksInterior__pi_customBlockVolume) || ''}
                  placeholder=""
                  onChange={(e) => {
                    e.preventDefault();
                    updateQuantityValue(schema.wallBlocksInterior__pi_customBlockVolume, e.target.value);
                  }}
                  fullWidth
                  required
                  disabled={!isCustomSpecification}
                  isError={customSpecificationError.includes(schema.wallBlocksInterior__pi_customBlockVolume)}
                />
              </Grid>
              <Grid xs={3} className={isCustomSpecification ? classes.grid : classes.textHeader3disabled} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">{getQuantityUnit(schema.wallBlocksInterior__pi_customBlockVolume)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.wallBlocksInterior__pi_customBlockEC)}</Typography>
          </Grid>

          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.wallBlocksInterior__pi_customBlockEC) || ''}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.wallBlocksInterior__pi_customBlockEC, e.target.value);
                }}
                fullWidth
                required
                removeResetIcon={true}
                disabled={!isCustomSpecification}
                isError={customSpecificationError.includes(schema.wallBlocksInterior__pi_customBlockEC)}
              />
            </Grid>
            <Grid xs={3} className={isCustomSpecification ? classes.grid : classes.textHeader3disabled} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.wallBlocksInterior__pi_customBlockEC)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WallBlocksInteriorCustomAdvancedParameters;
