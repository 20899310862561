import React, { useEffect, useState } from 'react';
import { Collapse, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import CustomHeader from '../../../certification/common/CustomHeader';
import CustomDrawerEnvelope from './CustomDrawerEnvelope';
import { useParams } from 'react-router';
import useAppPIPScheme from '../../hooks/useAppPIPScheme';
import { getPIP } from '../../../interventions/intervention/utils';
import DeleteModal from './DeleteModal';
import OpeningPerTowerTable from './envelopeTables/OpeningPerTowerTable';

const OpeningPerTower = ({ form, handlePIPValueUpdate, projectSubcategory, towerData, openingData, isOpeningAvailable, mount, setMount }) => {
  const history = useHistory();
  let { id } = useParams();
  const [collapseSection, setCollapseSection] = useState(true);
  const [localTowerOpeningData, setLocalTowerOpeningData] = useState([]);
  const [editTable, setEditTable] = useState(false);
  const [editableId, setEditableId] = useState();
  const [editableData, setEditableData] = useState('');
  const [update, setUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [towerToDelete, setTowerToDelete] = useState();
  const [closePopover, setClosePopover] = useState(false);
  const { data: envelopePIPs } = useAppPIPScheme('envelope');
  const requiredPipId = getPIP(envelopePIPs, 'project__pi_towerOpenings')?._id;
  const handleExpandIcon = () => {
    setCollapseSection((prev) => !prev);
  };
  const handleDelete = () => {
    const existingPipValue = form?.pipvalues[requiredPipId];
    let updatedPipValues = [];
    if (existingPipValue.length > 0) {
      updatedPipValues = existingPipValue.filter((item) => item._id !== towerToDelete);
    } else updatedPipValues = localTowerOpeningData.filter((item) => item._id !== towerToDelete);
    setLocalTowerOpeningData(updatedPipValues);
    handlePIPValueUpdate(requiredPipId, updatedPipValues);
    setUpdate(!update);
  };
  return (
    <>
      <Grid xs={6}>
        <DeleteModal setClosePopover={setClosePopover} handleDelete={handleDelete} showModal={showModal} setShowModal={setShowModal} />
      </Grid>
      {/* Header */}
      <CustomHeader
        sectionName={projectSubcategory === 'apartment' ? 'Openings Per Tower' : 'Opening Schedule By Villa Type'}
        i={1}
        collapseSection={collapseSection}
        history={history}
        expand={true}
        handleExpandIcon={handleExpandIcon}
      />
      {!isOpeningAvailable && (
        <Typography style={{ fontWeight: '400', color: '#00000061' }}>
          Add tower and opening schedule specifications to enable opening schedule by tower
        </Typography>
      )}{' '}
      {towerData?.map((tower) => (
        <React.Fragment key={tower._id}>
          {' '}
          {/* Important: Add a key for each rendered element */}
          <CustomDrawerEnvelope
            form={form}
            handlePIPValueUpdate={handlePIPValueUpdate}
            openingData={openingData}
            headerForBrowseButton={isOpeningAvailable}
            tower={tower}
            setLocalTowerOpeningData={setLocalTowerOpeningData}
            envelopePIPs={envelopePIPs}
            editableId={editableId}
            customAddButton={isOpeningAvailable}
            title={projectSubcategory === 'apartment' ? 'ADD OPENINGS FOR TOWER' : 'ADD VILLA OPENING SCHEDULE'}
            section={'OPENING TOWER'}
            editTable={editTable}
            handleEditTable={(val) => setEditTable(val)}
            editableData={editableData}
            handleEditableData={(d) => setEditableData(d)}
            setUpdate={setUpdate}
            update={update}
            mount={mount}
            setMount={setMount}
            projectSubcategory={projectSubcategory}
          />
          {/* Table for each tower */}
          <Collapse in={collapseSection}>
            {' '}
            {/* You might want separate collapse controls per tower */}
            {isOpeningAvailable && (
              <OpeningPerTowerTable
                tower={tower}
                closePopover={closePopover}
                openingData={openingData}
                localTowerOpeningData={localTowerOpeningData} // Filter openings for this tower
                handleEdit={(towerId) => {
                  setEditableId(towerId);
                  setEditTable(true);
                }}
                setTowerToDelete={setTowerToDelete}
                setShowModal={setShowModal}
                setEditableData={setEditableData}
                editTable={editTable}
                editableId={editableId}
                update={update}
                requiredPipId={requiredPipId}
                setUpdate={setUpdate}
                mount={mount}
                setMount={setMount}
              />
            )}
          </Collapse>
        </React.Fragment>
      ))}
    </>
  );
};

export default OpeningPerTower;
