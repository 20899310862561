import React, { useEffect, useState } from 'react';
import { Grid, Typography, TableRow, TableHead, TableContainer, TableCell, TableBody, Table } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import CustomPopover from '../../../../certification/edit/ResidentHealthAndWellBeing/common/CustomPopover';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import useAppProjectPIPMapping from '../../../hooks/useAppProjectPIPMapping';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function TowersTable({
  projectSubcategory,
  setTowerToDelete,
  setEditableData,
  editTable,
  editableId,
  localTowerData,
  requiredPipId,
  handleEdit,
  update,
  setShowModal,
  closePopover,
  mount,
  setMount,
}) {
  let { id } = useParams();
  const classes = useStyles();
  const {
    get: { form },
  } = useAppProjectPIPMapping(id);

  const [tableData, setTableData] = useState();
  useEffect(() => {
    if (form?.pipvalues && form.pipvalues[requiredPipId]) {
      setTableData([...form.pipvalues[requiredPipId]]);
    } else {
      setTableData([]);
    }
  }, [requiredPipId, form]);

  useEffect(() => {
    if (form?.pipvalues && form.pipvalues[requiredPipId]) {
      setTableData(localTowerData);
    }
  }, [update]);
  useEffect(() => {
    if (editTable) {
      const editableTower = localTowerData?.find((item) => item._id === editableId) || tableData?.find((item) => item._id === editableId);
      setEditableData(editableTower);
    }
  }, [editableId, editTable]);
  const towerHeader = projectSubcategory === 'apartment' ? 'Tower Name' : 'Villa Type Name';
  const headers = [
    towerHeader,
    'No. of Floors',
    'North Wall Length',
    'South Wall Length',
    'East Wall Length',
    'West Wall Length',
    'North East Wall Length',
    'North West Wall Length',
    'South East Wall Length',
    'South West Wall Length',
  ];

  const getHeaderDataKey = (header) => {
    switch (header) {
      case 'Tower Name':
        return 'name';
      case 'Villa Type Name':
        return 'name';
      case 'No. of Floors':
        return 'numberOfFloors';
      case 'North Wall Length':
        return 'north';
      case 'South Wall Length':
        return 'south';
      case 'East Wall Length':
        return 'east';
      case 'West Wall Length':
        return 'west';
      case 'North East Wall Length':
        return 'northEast';
      case 'North West Wall Length':
        return 'northWest';
      case 'South East Wall Length':
        return 'southEast';
      case 'South West Wall Length':
        return 'southWest';
      default:
        return '';
    }
  };
  return (
    <>
      <>
        {tableData && tableData.length > 0 && (
          <Grid container xs={12} style={{ marginBottom: '3%' }}>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  {/* Render Popover row FIRST */}
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        paddingTop: 0.8,
                        paddingBottom: 0,
                        height: '10px',
                        backgroundColor: '#white',
                        color: '#1D1C2B',
                        borderBottomWidth: '0px',
                      }}
                    >
                      <Typography style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '14px', lineHeight: '21px', letterSpacing: '0px' }}></Typography>
                    </TableCell>
                    {tableData.map((towerData, towerIndex) => (
                      <TableCell key={towerIndex} style={{ borderBottomWidth: '0px', position: 'relative' }}>
                        {' '}
                        {/* Key change: position relative */}
                        <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                          {' '}
                          {/* Key change: absolute positioning */}
                          <CustomPopover
                            closePopover={closePopover}
                            onEdit={() => handleEdit(towerData._id)}
                            onDelete={() => {
                              setShowModal(true);
                              setTowerToDelete(towerData._id);
                            }}
                          />
                        </div>
                      </TableCell>
                    ))}
                    <TableCell align="left" style={{ border: 'none' }}></TableCell> {/* Empty cell for spacing */}
                  </TableRow>

                  {/* Render headers */}
                  {headers.map((header, headerIndex) => (
                    <TableRow key={headerIndex}>
                      <TableCell width="120" style={{ backgroundColor: '#F7F7F7', color: '#1D1C2B', borderBottomWidth: '0px' }} component="th" scope="row">
                        <Typography style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '14px', lineHeight: '21px', letterSpacing: '0px' }}>
                          {header}
                        </Typography>
                      </TableCell>
                      {/* Render data cells */}
                      {tableData.map((towerData, towerIndex) => (
                        <TableCell
                          align="center"
                          width={1000 / tableData?.length}
                          key={towerIndex}
                          style={{ backgroundColor: '#F7F7F7', borderBottomWidth: '0px', top: '10px' }}
                          component="th"
                          scope="row"
                        >
                          <Typography variant="h6">{towerData[getHeaderDataKey(header)] || '-'}</Typography>
                        </TableCell>
                      ))}
                      <TableCell align="center" style={{ border: 'none' }}></TableCell> {/* Empty cell for spacing */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </>
    </>
  );
}
