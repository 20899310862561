import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Grid, Typography, Tooltip } from '@material-ui/core';
import { extractData } from '../../../../utils/dataConditionsCheck';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(0),
    borderRadius: '0px 100px 100px 0px',
  },
  color: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  spacing: {
    marginTop: '25%',
  },
}));

export default function CustomizedLongBar({ bgcolor, barvalue, width, barColor, categoryData }) {
  const classes = useStyles();
  const BorderLinearProgress = withStyles(() => ({
    root: {
      height: 40,
      width: width,
      background: `${bgcolor}`,
      borderRadius: '0px 8px 8px 0px',
    },
    bar: {
      borderRadius: '0px 8px 8px 0px',
      background: `${barColor}`,
    },
  }))(LinearProgress);

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: '#f3f3f3',
    },
    tooltip: {
      backgroundColor: '#FFFFFF',
      border: '1px solid rgba(0, 0, 0, 0.18)',
      borderRadius: '8px',
      color: 'black',
      boxSizing: 'border-box',
    },
  }));
  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();

    return <Tooltip arrow classes={classes} {...props} />;
  }

  const getExtractedPointsDisplayData = (data) => {
    return data ? extractData(data) : 0;
  };

  return (
    <Grid container className={classes.root}>
      <Grid container item xs={12} className={classes.spacing}>
        <Grid item xs={12}>
          <BootstrapTooltip
            title={
              <>
                <Typography style={{ color: '#9E9E9E', fontSize: '10px', paddingBottom: '8px', textAlign: 'center', fontWeight: 700 }}>
                  {categoryData?.name}
                </Typography>
                <Typography style={{ fontSize: '10px', paddingBottom: '8px', textAlign: 'center', fontWeight: 700 }}>
                  {`${getExtractedPointsDisplayData(categoryData?.overall?.totalpointsachieved)}/${getExtractedPointsDisplayData(
                    categoryData?.overall?.totalpoints
                  )} IGBC Points`}
                </Typography>
              </>
            }
            placement="top"
          >
            <BorderLinearProgress className={classes.margin} variant="determinate" value={`${barvalue}`} />
          </BootstrapTooltip>
        </Grid>
      </Grid>
    </Grid>
  );
}
