import React from 'react';
import ProductComparison from './ProductComparison';
import { withWidth } from '@material-ui/core';
import MarketplaceNavbar from '../../navbar/MarketplaceNavbar';
import MarketplaceFooter from '../../footer/MarketplaceFooter';
const ProductComparisonMarketplace = () => {
  return (
    <>
      <MarketplaceNavbar />
      <ProductComparison />
      <MarketplaceFooter />
    </>
  );
};

export default withWidth()(ProductComparisonMarketplace);
