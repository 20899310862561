import React, { useState } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Typography, Grid, Popover, Backdrop, CircularProgress, TableRow, TableCell } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import CustomCheckBox from '../../../../common/checkbox/CustomCheckBox';
import Bold from '../../../../../v2/components/Bold';

import rsToCr from '../../../../../utils/rsToCr';
import unit from '../../../../../utils/unitConfig';
import numberWithCommas from '../../../../../utils/numberToComma';
import { capitalize } from '../utils';

import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';
import { toggleCustomInterventionEnabled, toggleEnabled } from '../../../../../http/projectIntervention';
import LoadingAnimation from '../../../../../v2/components/LoadingAnimation';

const CategoryRow = ({
  row,
  StyledTableCell,
  category,
  isShowCapex,
  projectID: iid,
  handleRefresh,
  handleOpenBackdrop,
  handleEditCustomIntervention,
  handleEditCustominterventionID,
  setCustomInterventionFileNames,
  name: interventionName,
  index,
}) => {
  const classes = useStyles();
  let history = useHistory();

  const { getValue, getUnit, getIconByFeatureKey } = useAppMetafireUtilities();

  const [spinner, setSpinner] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => setAnchorEl(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const onClickEnableOrDisable = async (id, state, isCustomIntervention) => {
    setSpinner(true);

    try {
      if (isCustomIntervention) {
        await toggleCustomInterventionEnabled({ customInterventionId: id, enabled: state });
      } else {
        await toggleEnabled({ interventionId: id, projectId: iid, enabled: state });
      }
      handleRefresh();
      setSpinner(false);
    } catch (error) {
      setSpinner(false);
      console.error(error); // TODO: throw user friendly error
    }
  };

  const openCustomIntervention = (customInterventionId, fileNames) => {
    handleOpenBackdrop(true);
    handleEditCustomIntervention(true);
    handleEditCustominterventionID(customInterventionId);
    setCustomInterventionFileNames(fileNames);
  };

  const filteredInterventions = row?.interventions?.filter((data) => getValue(data?.quantities?.activated)) || [];
  const financeSavings = row?.quantities?.finance;
  const resourceSavings = row?.quantities?.resource;

  const isCustomIntervention = interventionName === 'Custom Intervention';
  return (
    <>
      <LoadingAnimation open={spinner} />

      {/* Sub Category Row */}

      <StyledTableRow style={{ height: '8vh' }}>
        {/* Category Name */}
        <StyledTableCell component="th" scope="row">
          {capitalize(row.name)}
        </StyledTableCell>

        {/* Capex Increase */}
        {!isShowCapex ? (
          <StyledTableCell>
            <Bold isHighlight={true} value={numberWithCommas(getValue(financeSavings?.capexConverted))} unit={getUnit(financeSavings?.capexConverted)} />
          </StyledTableCell>
        ) : (
          <StyledTableCell>
            <Bold isHighlight={true} value={numberWithCommas(rsToCr('Rs', getValue(financeSavings?.capex)))} unit={unit('cr')} />
          </StyledTableCell>
        )}

        {/* Total Capex */}
        {!isShowCapex ? (
          <StyledTableCell>
            <Bold
              isHighlight={true}
              value={numberWithCommas(getValue(financeSavings?.totalCapexConverted))}
              unit={getUnit(financeSavings?.totalCapexConverted)}
            />
          </StyledTableCell>
        ) : (
          <StyledTableCell>
            <Bold isHighlight={true} value={numberWithCommas(rsToCr('Rs', getValue(financeSavings?.totalCapex)))} unit={unit('cr')} />
          </StyledTableCell>
        )}

        {/* Opex Savings */}
        <StyledTableCell>
          <Bold isHighlight={true} value={numberWithCommas(rsToCr('Rs', getValue(financeSavings?.opex)))} unit={unit('cr')} />
        </StyledTableCell>

        {/* Energy Savings */}
        <StyledTableCell>
          <Bold isHighlight={true} value={numberWithCommas(getValue(resourceSavings?.category))} unit={unit(getUnit(resourceSavings?.category))} />
        </StyledTableCell>
      </StyledTableRow>

      {/* Intervention Row */}
      {filteredInterventions?.map((row, rowIndex) => {
        return (
          <StyledTableSubRow key={rowIndex}>
            <StyledRowCell component="th" scope="row">
              <Grid container xs>
                {/* Enable/Disable Intervention */}
                <Grid xs={1} style={{ paddingTop: 5 }}>
                  <CustomCheckBox
                    color="rgba(0, 0, 0, 0.38)"
                    checkState={getValue(row?.quantities?.enabled)}
                    uncheckicon={<RadioButtonUncheckedIcon style={{ fontSize: 20 }} />}
                    checkicon={<CheckCircleIcon style={{ fontSize: 20 }} />}
                    handleChange={() =>
                      onClickEnableOrDisable(row?.customInterventionId || row?.interventionId, !getValue(row?.quantities?.enabled), isCustomIntervention)
                    }
                    disabled={false}
                  />
                </Grid>

                {/* Intervention Icon */}
                <Grid xs={2}>
                  <img
                    style={{ borderRadius: '10px', height: '40px', width: '40px' }}
                    src={getIconByFeatureKey(isCustomIntervention ? category : row.feature, false)}
                    alt="..."
                  />
                </Grid>

                {/* Intervention Name */}
                <Grid
                  xs={9}
                  className={classes.grid}
                  onClick={() => {
                    if (!isCustomIntervention) {
                      history.push(`/projects/${iid}/interventions/${row.interventionId}`);
                    } else {
                      openCustomIntervention(row.customInterventionId, row.fileNames);
                    }
                  }}
                  style={{ paddingTop: '3px', paddingLeft: '2%', cursor: 'pointer' }}
                  item
                  container
                >
                  <span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    className={clsx({ 'intervention-details-summary': index === 0 && rowIndex === 0 })}
                  >
                    {row.name.charAt(0).toUpperCase() + row.name.slice(1)}
                  </span>
                </Grid>
              </Grid>
            </StyledRowCell>

            {/* Capex row wise */}
            {!isShowCapex ? (
              <StyledRowCell style={row.locked !== false ? { color: 'grey' } : {}}>
                <Bold
                  isHighlight={true}
                  value={numberWithCommas(getValue(row?.quantities?.finance?.capexConverted))}
                  unit={getUnit(financeSavings?.capexConverted)} // TODO: change to row?.quantities?.finance?.capexConverted once the backend is updated
                />
              </StyledRowCell>
            ) : (
              <StyledRowCell style={row.locked !== false ? { color: 'grey' } : {}}>
                <Bold isHighlight={true} value={rsToCr('Rs', getValue(row?.quantities?.finance?.capex))} unit={unit('cr')} />
              </StyledRowCell>
            )}

            {/* Total Capex row wise */}
            <StyledRowCell style={row.locked !== false ? { color: 'grey' } : {}}>
              {!isShowCapex ? (
                <Bold
                  isHighlight={true}
                  value={getValue(row?.quantities?.finance?.totalCapexConverted)}
                  unit={getUnit(row?.quantities?.finance?.totalCapexConverted)}
                />
              ) : (
                <Bold isHighlight={true} value={rsToCr('Rs', getValue(row?.quantities?.finance?.totalCapex))} unit={unit('cr')} />
              )}
            </StyledRowCell>

            {/* Opex Savings wise */}
            <StyledRowCell style={row.locked !== false ? { color: 'grey' } : {}}>
              <Bold isHighlight={true} value={rsToCr('Rs', getValue(row?.quantities?.finance?.opex))} unit={unit('cr')} />
            </StyledRowCell>

            {/* Energy Savings wise */}
            <StyledRowCell style={row.locked !== false ? { color: 'grey' } : {}}>
              <Bold
                isHighlight={true}
                value={numberWithCommas(getValue(row?.quantities?.resource?.category))}
                unit={unit(getUnit(row?.quantities?.resource?.category))}
              />
            </StyledRowCell>
          </StyledTableSubRow>
        );
      })}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Typography className={classes.typography}>{row?.name}</Typography>
      </Popover>
    </>
  );
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    background: 'rgba(0, 0, 0, 0.03)',
    paddingRight: 1,
    paddingLeft: 1,
  },
}))(TableRow);

const StyledTableSubRow = withStyles((theme) => ({
  root: {
    paddingRight: 1,
    paddingLeft: 1,
  },
}))(TableRow);

const StyledRowCell = withStyles((theme) => ({
  body: {
    paddingTop: 4,
    paddingBottom: 4,

    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '19px',
    letterSpacing: '0.571429px',
    color: 'rgba(0, 0, 0, 0.82)',
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  linkfont: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    color: '#00C58A',
  },
  decoration: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  decoration2: {
    cursor: 'pointer',
  },
  grid: {
    marginTop: '3%',
  },
  typography: {
    padding: theme.spacing(2),
  },
  modal: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default CategoryRow;
