import { api } from './http';

const postLogin = async (email, password) => {
  const response = await api.post('/v2/auth/login', { email, password });
  return response.data;
};

const getAuthWhoAmI = async () => {
  const response = await api.get(`/v2/auth/whoami`);
  return response?.data?.data;
};

export { getAuthWhoAmI, postLogin };
