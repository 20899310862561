export const MockOldDataForInsights = {
  id: '661d0bc9cc4a19663e43250a',
  name: 'Citrine',
  category: 'residential',
  createdBy: '62c67f17fd7444f41d0bb06a',
  location: {
    name: 'Sannatammanahalli, Battarahalli, Bengaluru, Karnataka 560049, India',
    type: 'Point',
    coordinates: [13.0214234, 77.708722],
  },
  organization: {
    uri: '',
  },
  uri: '',
  subcategory: 'apartment',
  locationBasedInsights: {
    aqi: {
      id: '',
      name: 'aqi',
      label: 'AQI',
      uri: '',
      value: {
        aqi: 79,
        biggestPollutant: 'PM2.5',
        color: 'Yellow',
        stationName: 'BWSSB Kadabesanahalli, Bengaluru - CPCB',
      },
      tags: [],
      unit: 'AQI',
    },
    temperature: {
      id: '',
      name: 'temperature',
      label: 'Temperature',
      uri: '',
      value: {
        avgHumidity: 64.01,
        avgRainfall: 1018.2,
        avgTemp: 26.38,
        avgWindSpeed: 12.81,
        maxTemp: 29.63,
        minTemp: 20.18,
      },
      tags: [],
      unit: 'Temperature',
    },
    igbc: {
      id: '',
      name: 'IGBC',
      label: 'IGBC',
      uri: '',
      value: [
        {
          _id: '61a9ba737f39333ab0c90123',
          stationName: 'Renaissance Nature Walk',
          latitude: 13.00652656,
          longitude: 77.71813698,
          igbcRating: 'Gold',
          type: 'Luxury',
          launchDate: 'Dec-2019',
          sitearea: '4.2 Acres',
          builtupArea: null,
          noOfapts: 147,
          configurations: '4 BHK',
          avgPricePerSqftInArea: 8947,
          avgPricePerSqftForProject: null,
          startingPrice: '2.64 cr',
          address: '541, 10th Main Rd, 5th Block, Jayanagar, Bengaluru, Karnataka 560041, India',
          createdAt: '2021-12-03T06:34:27.324Z',
          __v: 0,
        },
        {
          _id: '61a9be437f39333ab0c90129',
          stationName: 'Fortius Waterscape',
          latitude: 13.0197,
          longitude: 77.7211,
          igbcRating: 'Gold',
          type: 'Affordable',
          launchDate: 'Jun 2017',
          sitearea: '2 Acres',
          builtupArea: null,
          noOfapts: 156,
          configurations: '2,3 BHK',
          avgPricePerSqftInArea: 5200,
          avgPricePerSqftForProject: null,
          startingPrice: '0.68 cr',
          address: 'K R Puram, Bangalore, Karnataka',
          createdAt: '2021-12-03T06:50:43.351Z',
          __v: 0,
        },
      ],
      tags: [],
      unit: 'IGBC',
    },
  },
};

export const MockSortOfInterventionInsights = {
  data: {
    success: true,
    data: {
      version: 'v1',
      data: {
        ...MockOldDataForInsights,
      },
    },
  },
};
