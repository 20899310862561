import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WaterCard from '../../views/marketing/water/Card';
import sidebaricon1 from '../../../assets/sidebaricon1.svg';
import sidebaricon3 from '../../../assets/sidebaricon3.svg';
// import { ProjectContext } from '../../../routing/IndexRouting';
import { Link } from 'react-router-dom';
import SwitchProjectModal from '../../views/projects/Modal';
import Skeleton from '@material-ui/lab/Skeleton';
// import Loader from '../../../config/Loader';
// import userRoles from '../../../config/roles';

const useStyles = makeStyles((theme) => ({
  buttongroup: {
    paddingTop: '20px',
  },
  line: {
    paddingTop: '30px',
    paddingBottom: '10%',
  },
  textcolor: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  spacing: {
    paddingBottom: '4%',
    lineHeight: '25px',
  },
}));

function ProjectBar(props) {
  const { projectData } = props;
  const classes = useStyles();
  // const { projectData } = useContext(ProjectContext);
  return (
    <>
      <WaterCard
        body={
          <>
            <Typography variant="body2" className={classes.spacing}>
              {projectData ? projectData.name : <Skeleton variant="text" animation="wave" />}
            </Typography>
            <>
              <Grid container alignItems="center" item xs={12} className={classes.buttongroup}>
                <Grid item xs={4} align="left">
                  <Link
                    style={{
                      background: 'transparent',
                      color: 'white',
                      width: '30%',
                    }}
                    to={'/projects'}
                  >
                    <img src={sidebaricon1} alt="..." title="My Projects" />
                  </Link>
                </Grid>
                <Grid item xs={4}>
                  <SwitchProjectModal />
                </Grid>
                <Grid item xs={4}>
                  <Link
                    style={{
                      background: 'transparent',
                      color: 'white',
                      width: '30%',
                    }}
                    to={'/projects/create'}
                  >
                    <img src={sidebaricon3} alt="..." title="Create New Project" />
                  </Link>
                </Grid>
              </Grid>
            </>
            {/* <img src={sidebarline} alt="..." className={classes.line} style={{ width: '100%' }} /> */}
          </>
        }
        style={{ background: 'transparent', border: 'none', height: '90%' }}
      />
    </>
  );
}

export default ProjectBar;
