/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import useStyles from './styles';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ResetFields from '../common/textFiledwithReset';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import CustomHeader from '../../../certification/common/CustomHeader';

const CoolRoofAdvanceParameters = (props) => {
  const { setSaveError } = props;
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityUnit, getQuantityLabel, getQuantityDefaultValue },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();

  const [isExpanded, setIsExpanded] = useState(true);
  const [customSpecificationError, setCustomSpecificationError] = useState([]);
  const [customCapexError, setCustomCapexError] = useState(false);

  const isCustomSpecification = getQuantityValue(schema.coolRoof__pi_customSpecification);
  const isCustomCapex = getQuantityValue(schema.coolRoof__pi_capexMultiplierSource) === 'CUSTOM' || isCustomSpecification;

  const genericLevelingConcreteBed = getQuantityValue(schema.coolRoof_genericLevelingConcreteBed);
  const genericWaterproofingMembrane = getQuantityValue(schema.coolRoof_genericWaterproofingMembrane);
  const genericSlab = getQuantityValue(schema.coolRoof_genericSlab);
  const genericInteriorFinish = getQuantityValue(schema.coolRoof_genericInteriorFinish);

  useEffect(() => {
    validateCustomSpecification();
  }, [
    isCustomSpecification,
    getQuantityValue(schema.coolRoof__pi_surfaceThermalEmittance),
    getQuantityValue(schema.coolRoof__pi_surfaceReflectance),
    getQuantityValue(schema.coolRoof__pi_surfaceMaterial),
    getQuantityValue(schema.coolRoof__pi_surfaceThickness),
    getQuantityValue(schema.coolRoof__pi_surfaceThermalConductivity),
    getQuantityValue(schema.coolRoof__pi_subSurfaceMaterial),
    getQuantityValue(schema.coolRoof__pi_subSurfaceThickness),
    getQuantityValue(schema.coolRoof__pi_subSurfaceThermalConductivity),
    isCustomCapex,
    getQuantityValue(schema.coolRoof__pi_customCapex),
  ]);

  const updateCustomSpecificationValue = (field, value) => {
    updateQuantityValue(field, value);
  };

  const validateCustomSpecification = () => {
    let isValid = true;

    const requiredFields = [
      schema.coolRoof__pi_surfaceThermalEmittance,
      schema.coolRoof__pi_surfaceReflectance,
      schema.coolRoof__pi_surfaceMaterial,
      schema.coolRoof__pi_surfaceThickness,
      schema.coolRoof__pi_surfaceThermalConductivity,
      schema.coolRoof__pi_subSurfaceMaterial,
      schema.coolRoof__pi_subSurfaceThickness,
      schema.coolRoof__pi_subSurfaceThermalConductivity,
    ];

    let errorFields = [];

    if (isCustomSpecification) {
      errorFields = requiredFields.filter((field) => {
        const value = getQuantityValue(field);
        return value === null || value === undefined || value === '';
      });
    }

    if (errorFields.length > 0) {
      isValid = false;
      setCustomSpecificationError(errorFields);
    }

    if (isCustomCapex && !getQuantityValue(schema.coolRoof__pi_customCapex)) {
      isValid = false;
      setCustomCapexError(true);
    }

    if (isValid) {
      setSaveError(false);
      setCustomSpecificationError([]);
      setCustomCapexError(false);
    } else {
      setSaveError(true);
    }
  };

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid xs={4}>
        <Grid item style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
          <Typography className={classes.textHeader1} style={{ marginRight: '8px' }}>
            Intervention Cost Assumptions
          </Typography>
          <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention</Typography>} placement="right" />
        </Grid>

        <Typography color="textSecondary" variant="h6" style={{ marginTop: '5%' }}>
          {`CAPEX (${getQuantityUnit(schema.coolRoof_vendorCapex)})`}
        </Typography>
      </Grid>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Grid xs={4} style={{ display: 'flex', flexDirection: 'column' }}>
          <Grid xs={6} style={{ display: 'flex' }}>
            {!isCustomCapex ? (
              <CheckCircleIcon className={classes.selectedCheckbox} />
            ) : (
              <RadioButtonUncheckedIcon
                className={classes.selectedCheckbox}
                onClick={() => {
                  updateQuantityValue(schema.coolRoof__pi_capexMultiplierSource, 'VENDOR');
                  updateQuantityValue(schema.coolRoof__pi_customSpecification, false);
                }}
              />
            )}

            <Typography>Selected Vendor</Typography>
          </Grid>

          <Grid style={{ display: 'flex', alignContent: 'unset', marginLeft: '15%' }}>
            <Typography className={isCustomCapex && classes.textDisable}>{getQuantityValue(schema.coolRoof_vendorCapex)}</Typography>
            <Typography style={{ marginLeft: '2%' }} className={isCustomCapex && classes.textDisable}>
              {getQuantityUnit(schema.coolRoof_vendorCapex)}
            </Typography>
          </Grid>
        </Grid>

        <Grid xs={4} style={{ display: 'flex', flexDirection: 'column' }}>
          <Grid xs={6} style={{ display: 'flex' }}>
            {isCustomCapex ? (
              <CheckCircleIcon className={classes.selectedCheckbox} />
            ) : (
              <RadioButtonUncheckedIcon
                className={classes.selectedCheckbox}
                onClick={() => {
                  updateQuantityValue(schema.coolRoof__pi_capexMultiplierSource, 'CUSTOM');
                }}
              />
            )}
            <Typography>Custom</Typography>
          </Grid>

          <Grid style={{ display: 'flex' }}>
            <ResetFields
              id="outlined-basic"
              variant="outlined"
              value={getQuantityValue(schema.coolRoof__pi_customCapex)}
              placeholder=""
              fullWidth
              required
              onChange={(e) => {
                e.preventDefault();
                updateQuantityValue(schema.coolRoof__pi_customCapex, e.target.value);
              }}
              disabled={!isCustomCapex}
              removeResetIcon={true}
              isError={customCapexError}
              unit={getQuantityUnit(schema.coolRoof_vendorCapex)}
            />
          </Grid>
        </Grid>
      </div>

      {/* Cool Roof Specifications */}
      <Grid container direction="column" style={{ marginTop: '24px' }}>
        <Grid container alignItems="center">
          <Typography className={classes.textHeader1}>Cool Roof Specifications</Typography>
          <CustomizedTooltips
            icon="info"
            placement="right"
            title={<Typography>Surface Thermal Remittance, Surface Reflectance, and U-Roof values are as per the Selected Product by default</Typography>}
          />
        </Grid>

        <Grid container item xs={12} style={{ marginTop: '16px' }}>
          <Grid item xs={12}>
            <Typography style={{ display: 'flex', alignItems: 'center' }}>
              {isCustomSpecification ? (
                <CheckBoxIcon
                  className={classes.selectedCheckbox}
                  onClick={() => updateQuantityValue(schema.coolRoof__pi_customSpecification, !isCustomSpecification)}
                />
              ) : (
                <CheckBoxOutlineBlankIcon
                  className={classes.selectedCheckbox}
                  onClick={() => updateQuantityValue(schema.coolRoof__pi_customSpecification, !isCustomSpecification)}
                />
              )}
              <span>I have custom cool roof specifications</span>
            </Typography>
          </Grid>

          {isCustomSpecification && (customCapexError || customSpecificationError.length) ? (
            <Grid item xs={12}>
              <Typography variant="caption" style={{ color: 'red' }}>
                All fields are mandatory including Custom CAPEX
              </Typography>
            </Grid>
          ) : null}
        </Grid>

        <Grid xs={12} container spacing={3} style={{ marginTop: '16px' }}>
          <Grid item xs={4}>
            <Typography className={classes.textHeader5}>Surface Thermal Emittance</Typography>
            <Grid xs={8} style={{ marginTop: '10px' }}>
              <ResetFields
                variant="outlined"
                value={getQuantityValue(schema.coolRoof__pi_surfaceThermalEmittance)}
                onChange={(e) => updateCustomSpecificationValue(schema.coolRoof__pi_surfaceThermalEmittance, e.target.value)}
                disabled={!isCustomSpecification}
                onClick={() =>
                  updateCustomSpecificationValue(
                    schema.coolRoof__pi_surfaceThermalEmittance,
                    getQuantityDefaultValue(schema.coolRoof__pi_surfaceThermalEmittance)
                  )
                }
                isError={customSpecificationError.includes(schema.coolRoof__pi_surfaceThermalEmittance)}
              />
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Typography className={classes.textHeader5}>Surface Reflectance</Typography>
            <Grid xs={8} style={{ marginTop: '10px' }}>
              <ResetFields
                variant="outlined"
                value={getQuantityValue(schema.coolRoof__pi_surfaceReflectance)}
                onChange={(e) => updateCustomSpecificationValue(schema.coolRoof__pi_surfaceReflectance, e.target.value)}
                disabled={!isCustomSpecification}
                onClick={() =>
                  updateCustomSpecificationValue(schema.coolRoof__pi_surfaceReflectance, getQuantityDefaultValue(schema.coolRoof__pi_surfaceReflectance))
                }
                isError={customSpecificationError.includes(schema.coolRoof__pi_surfaceReflectance)}
              />
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Typography className={classes.textHeader5}>
              {getQuantityLabel(schema.coolRoof_uValue)}
              <CustomizedTooltips
                icon="info"
                placement="right"
                title={
                  <Typography>
                    The U Value of roof assembly is calculated from the table below using thickness, thermal conductivity and surface resistance (Rse & Rsi)
                  </Typography>
                }
              />
            </Typography>

            <Typography className={classes.textHeader3}>
              {getQuantityValue(schema.coolRoof_uValue)} {getQuantityUnit(schema.coolRoof_uValue)}
            </Typography>
          </Grid>
        </Grid>

        {/* Roofing Assembly Table */}
        <Grid container style={{ marginTop: '24px' }}>
          <Grid container item xs={12}>
            <Grid item xs={3}>
              <Typography className={classes.textHeader1}>Roofing Assembly</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.textHeader1}>Specification</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.textHeader1}>Thickness (mm)</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.textHeader1}>Thermal Conductivity (W/m·K)</Typography>
            </Grid>
          </Grid>

          {/* Surface Layer */}
          <Grid container item xs={12} style={{ marginTop: '16px' }}>
            <Grid item xs={3}>
              <Typography>Surface Layer (Exterior)</Typography>
            </Grid>
            <Grid item xs={3}>
              <ResetFields
                variant="outlined"
                value={getQuantityValue(schema.coolRoof__pi_surfaceMaterial)}
                onChange={(e) => updateCustomSpecificationValue(schema.coolRoof__pi_surfaceMaterial, e.target.value)}
                disabled={!isCustomSpecification}
                removeResetIcon={true}
                isError={customSpecificationError.includes(schema.coolRoof__pi_surfaceMaterial)}
              />
            </Grid>
            <Grid item xs={3}>
              <Grid xs={5}>
                <ResetFields
                  variant="outlined"
                  value={getQuantityValue(schema.coolRoof__pi_surfaceThickness)}
                  onChange={(e) => updateCustomSpecificationValue(schema.coolRoof__pi_surfaceThickness, e.target.value)}
                  disabled={!isCustomSpecification}
                  onClick={() =>
                    updateCustomSpecificationValue(schema.coolRoof__pi_surfaceThickness, getQuantityDefaultValue(schema.coolRoof__pi_surfaceThickness))
                  }
                  isError={customSpecificationError.includes(schema.coolRoof__pi_surfaceThickness)}
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid xs={5}>
                <ResetFields
                  variant="outlined"
                  value={getQuantityValue(schema.coolRoof__pi_surfaceThermalConductivity)}
                  onChange={(e) => updateCustomSpecificationValue(schema.coolRoof__pi_surfaceThermalConductivity, e.target.value)}
                  disabled={!isCustomSpecification}
                  onClick={() =>
                    updateCustomSpecificationValue(
                      schema.coolRoof__pi_surfaceThermalConductivity,
                      getQuantityDefaultValue(schema.coolRoof__pi_surfaceThermalConductivity)
                    )
                  }
                  isError={customSpecificationError.includes(schema.coolRoof__pi_surfaceThermalConductivity)}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Sub-Surface Layer */}
          <Grid container item xs={12} style={{ marginTop: '8px' }}>
            <Grid item xs={3}>
              <Typography>Sub-Surface Layer</Typography>
            </Grid>
            <Grid item xs={3}>
              <ResetFields
                variant="outlined"
                value={getQuantityValue(schema.coolRoof__pi_subSurfaceMaterial)}
                onChange={(e) => updateCustomSpecificationValue(schema.coolRoof__pi_subSurfaceMaterial, e.target.value)}
                disabled={!isCustomSpecification}
                removeResetIcon={true}
                isError={customSpecificationError.includes(schema.coolRoof__pi_subSurfaceMaterial)}
              />
            </Grid>
            <Grid item xs={3}>
              <Grid xs={5}>
                <ResetFields
                  variant="outlined"
                  value={getQuantityValue(schema.coolRoof__pi_subSurfaceThickness)}
                  onChange={(e) => updateCustomSpecificationValue(schema.coolRoof__pi_subSurfaceThickness, e.target.value)}
                  disabled={!isCustomSpecification}
                  onClick={() =>
                    updateCustomSpecificationValue(schema.coolRoof__pi_subSurfaceThickness, getQuantityDefaultValue(schema.coolRoof__pi_subSurfaceThickness))
                  }
                  isError={customSpecificationError.includes(schema.coolRoof__pi_subSurfaceThickness)}
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid xs={5}>
                <ResetFields
                  variant="outlined"
                  value={getQuantityValue(schema.coolRoof__pi_subSurfaceThermalConductivity)}
                  onChange={(e) => updateCustomSpecificationValue(schema.coolRoof__pi_subSurfaceThermalConductivity, e.target.value)}
                  disabled={!isCustomSpecification}
                  onClick={() =>
                    updateCustomSpecificationValue(
                      schema.coolRoof__pi_subSurfaceThermalConductivity,
                      getQuantityDefaultValue(schema.coolRoof__pi_subSurfaceThermalConductivity)
                    )
                  }
                  isError={customSpecificationError.includes(schema.coolRoof__pi_subSurfaceThermalConductivity)}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Generic Layers Section */}
          <Grid container item xs={12} style={{ marginTop: '16px' }}>
            <CustomHeader sectionName={'Generic layers'} collapseSection={isExpanded} i={1} expand={true} handleExpandIcon={() => setIsExpanded(!isExpanded)} />
          </Grid>

          {isExpanded && (
            <>
              {/* Generic Leveling Concrete Bed */}
              <Grid container item xs={12} style={{ marginTop: '8px' }}>
                <Grid item xs={3}>
                  <Typography>Generic Leveling Concrete Bed</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{genericLevelingConcreteBed.material}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{genericLevelingConcreteBed.thickness}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{genericLevelingConcreteBed.thermalConductivity}</Typography>
                </Grid>
              </Grid>

              {/* Generic Waterproofing Membrane */}
              <Grid container item xs={12} style={{ marginTop: '8px' }}>
                <Grid item xs={3}>
                  <Typography>Generic Waterproofing Membrane</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{genericWaterproofingMembrane.material}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{genericWaterproofingMembrane.thickness}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{genericWaterproofingMembrane.thermalConductivity}</Typography>
                </Grid>
              </Grid>

              {/* Generic Slab */}
              <Grid container item xs={12} style={{ marginTop: '8px' }}>
                <Grid item xs={3}>
                  <Typography>Generic Slab</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{genericSlab.material}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{genericSlab.thickness}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{genericSlab.thermalConductivity}</Typography>
                </Grid>
              </Grid>

              {/* Generic Interior Finish */}
              <Grid container item xs={12} style={{ marginTop: '8px' }}>
                <Grid item xs={3}>
                  <Typography>Generic Interior Finish</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{genericInteriorFinish.material}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{genericInteriorFinish.thickness}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>{genericInteriorFinish.thermalConductivity}</Typography>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CoolRoofAdvanceParameters;
