import React from 'react';
import { Grid, Typography, TableRow, TableContainer, TableCell, TableBody, Table, TableHead } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  headerRow: {
    border: 'none',
    backgroundColor: '#F7F7F7',
  },
  tableCell: { borderBottom: 'none' },
});

const headers = [
  { label: 'Thickness', key: 'thickness', unit: '' },
  { label: 'U-Value', key: 'uValue', unit: 'W/sqm.k' },
  { label: 'SHGC', key: 'shgc', unit: '' },
  { label: 'Per Unit Emission', key: 'unitEc', unit: 'kgCO2-eq/sqm' },
  { label: 'Unit Price', key: 'unitPrice', unit: 'Rs' },
];

export default function GlazingDguTable({ tableData }) {
  const classes = useStyles();
  const { getRoundOffValue } = useAppMetafireUtilities();

  const uniqueThicknesses = ['5 - 12 - 6', '6 - 12 - 6'];
  const filteredData = uniqueThicknesses
    .map((thickness) => (Array.isArray(tableData) ? tableData.find((row) => row.thickness === thickness) : null))
    .filter(Boolean);

  return (
    filteredData &&
    filteredData.length > 0 && (
      <Grid container xs={12} style={{ marginBottom: '3%' }}>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow className={classes.headerRow}>
                {headers.map(({ label }) => (
                  <TableCell key={label} className={classes.tableCell}>
                    <Typography style={{ fontWeight: 'bold' }}>{label}</Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody style={{ backgroundColor: '#FBFBFB', borderBottom: 'none' }}>
              {filteredData.map((row) => (
                <TableRow key={row.name}>
                  {headers.map(({ key, unit }) => (
                    <TableCell key={key} className={classes.tableCell} align="left">
                      <Typography variant="h6">
                        {getRoundOffValue(row[key])}
                        {` ${unit}`}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    )
  );
}
