import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import useApiRequest from '../../../../hooks/useApiRequest';
import { useAppApiRequest } from '../../../../../hooks/useAppApiRequest';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  inputLabel: {
    backgroundColor: 'white',
    padding: '0 4px',
    transform: 'translate(14px, -6px)',
  },
}));

export default function OrganisationSelect({ selectedOrganisationID, handleChange }) {
  const classes = useStyles();
  const { data: re } = useAppApiRequest(`/organisationsschemev2`, {
    method: 'GET',
  });
  const allOrganisations = re?.data?.data?.data;

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="outlined-age-native-simple" className={classes.inputLabel} shrink>
          Organisation
        </InputLabel>
        <Select native value={selectedOrganisationID} onChange={(event) => handleChange(event.target.value)} label="Organisation">
          <>
            <option aria-label="None" value="" />
            {allOrganisations &&
              allOrganisations.map(({ name, _id }, index) => {
                return (
                  <option value={_id} key={index}>
                    {name}
                  </option>
                );
              })}
          </>
        </Select>
      </FormControl>
    </div>
  );
}
