import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import PrimaryButton from '../../../../common/button/Button';

function ShowSaveAndCancel({ handleClose, handleSave, disabled }) {
  const classes = useStyles();
  return (
    <>
      <Grid container item xs={12} alignItems="center">
        <Grid item xs={8}></Grid>
        <Grid item xs={4}>
          <Grid container item xs={12} alignItems="center">
            <Grid item xs={4}>
              <Typography variant="h6" className={classes.edit} onClick={handleClose}>
                <b>Cancel</b>
              </Typography>
            </Grid>
            <Grid item xs={1} className="intervention-details-parameters-save">
              <PrimaryButton disabled={disabled} onClick={handleSave} children="Save" style={{ width: '150px', borderRadius: '40px', height: '30px' }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ShowSaveAndCancel;

const useStyles = makeStyles((theme) => ({
  edit: {
    color: '#00C58A',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));
