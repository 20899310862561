import React, { useContext, useState } from 'react';

const StatusContext = React.createContext();

export const StatusProvider = ({ children }) => {
  const [openStatus, setOpenStatus] = useState(false);
  const [statusCode, setStatusCode] = useState(null);

  const showStatus = (code) => {
    setStatusCode(code);
    setOpenStatus(true);
  };

  const hideStatus = () => {
    setOpenStatus(false);
    setStatusCode(null);
  };

  return <StatusContext.Provider value={{ openStatus, statusCode, showStatus, hideStatus }}>{children}</StatusContext.Provider>;
};

export const useStatusContext = () => useContext(StatusContext);
