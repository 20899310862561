import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Occupiedspacearr, Sections, TypeofWindowarr } from '../../DataTypes';
import { ResidentHealthAndWellBeingContext } from '../..';
import CustomDrawer from '../../common/CustomDrawer';
import { Grid, TableBody, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TableDropdown from './tabledropdown';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomPopover from '../../common/CustomPopover';

export default function InteriorTable({
  handleEdit,
  update,
  dwellingtype,
  windowsdata,
  dwellingunitInterior,
  residentHealthAndWellBeingData,
  mount,
  setMount,
  setUpdate,
  editableData,
  setEditableData,
  editTable,
  indexforEdit,
  setEditTable,
}) {
  const classes = useStyles();
  const occupiedspacearr = Occupiedspacearr;
  const typeofWindowarr = TypeofWindowarr;

  const { handleData } = useContext(ResidentHealthAndWellBeingContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const [state, setState] = useState('');

  // delete a row
  const handleDelete = (data, row, type, index, update) => {
    const length = data['customData'][0]['data'].find((d) => d.section === Sections.Dwelling_Unit_Interiors)?.data?.find((f) => f.type === type)['data'].length;
    if (length === 1) {
      const index = data['customData'][0]['data'].find((d) => d.section === Sections.Dwelling_Unit_Interiors)?.data.findIndex((s) => s.type === type);
      data['customData'][0]['data'].find((d) => d.section === Sections.Dwelling_Unit_Interiors)?.data.splice(index, 1);
    } else {
      data['customData'][0]['data']
        .find((d) => d.section === Sections.Dwelling_Unit_Interiors)
        ?.data?.find((f) => f.type === type)
        ['data'].splice(index, 1);
    }
    handleData(data);
    setUpdate(!update);
    setMount(!mount);
  };

  function GetTablerow(row, type, index, residentHealthAndWellBeingData) {
    return (
      <TableRow style={{ backgroundColor: '#F6F6F6', borderBottom: '1px solid #9E9E9E' }}>
        <TableCell>
          <Typography variant="h6" align="centre">
            {row.regularlyOccupiedSpace}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="h6">{row.typeOfRegularlyOccupiedSpace}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="h6">{row.typeofWindow}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="h6">{row.floorArea}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="h6">{row.openabledoorarea}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="h6">{row.skylightArea}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="h6">{row.skylightglazingVLT} </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="h6">
            {row.openinlesstwooreintation ? (
              <CheckBoxIcon style={{ color: '#66E3BE', cursor: 'pointer' }} onClick={() => false} />
            ) : (
              <CheckBoxOutlineBlankIcon style={{ color: '#66E3BE', cursor: 'pointer' }} onClick={() => true} />
            )}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="h6">
            {row.connectivitytoexterior ? (
              <CheckBoxIcon style={{ color: '#66E3BE', cursor: 'pointer' }} onClick={() => false} />
            ) : (
              <CheckBoxOutlineBlankIcon style={{ color: '#66E3BE', cursor: 'pointer' }} onClick={() => true} />
            )}
          </Typography>
        </TableCell>
        <TableCell align="left" style={{ borderBottom: '1px solid #fbfbfb', backgroundColor: '#fbfbfb' }}>
          <CustomPopover onEdit={() => handleEdit(row, type, index)} onDelete={() => handleDelete(residentHealthAndWellBeingData, row, type, index, update)} />
        </TableCell>
      </TableRow>
    );
  }

  function GetHeader(sectionName) {
    let finddata = dwellingunitInterior && dwellingunitInterior.filter((ele) => ele.type === sectionName);
    return (
      <>
        <Grid container item xs={12}>
          <Grid item xs={dwellingunitInterior.length > 0 ? 10 : 3}>
            <div style={{ display: 'flex' }}>
              <Typography variant="h5" style={{ fontWeight: 600, fontSize: '16px' }}>
                {sectionName}
              </Typography>
              <Typography>
                <ExpandMoreIcon style={{ color: '#49DDB1', fontSize: '32px', marginTop: 4 }} />
              </Typography>
            </div>
          </Grid>
          <Grid item xs={dwellingunitInterior.length > 0 ? 2 : 6}>
            <Grid container item xs={12}>
              <Grid item>
                <Typography variant="h5" style={{ fontWeight: 600 }}>
                  <CustomDrawer
                    //  customAddButton={true}
                    editTable={editTable}
                    handleEditTable={(val) => setEditTable(val)}
                    setUpdate={setUpdate}
                    update={update}
                    indexforEdit={indexforEdit}
                    section="Dwelling Unit Interiors"
                    sectionName={sectionName}
                    residentHealthAndWellBeingData={residentHealthAndWellBeingData}
                    mount={mount}
                    setMount={setMount}
                    editableData={editableData}
                    handleEditableData={(d) => setEditableData(d)}
                  />
                </Typography>
              </Grid>
              <Grid item xs={10} style={{ marginTop: dwellingunitInterior.length > 0 ? '2%' : '1%' }} alignItems="flex-end">
                <Typography variant="h5" style={{ fontWeight: 600, fontSize: '12px', color: '#49DDB1', paddingLeft: '10px' }}>
                  Add Row
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          {finddata.length === 0 ? (
            <Typography variant="h6" style={{ fontSize: '14px', fontWeight: 400, color: '#666666' }}>
              Add Dwelling Interior details
            </Typography>
          ) : (
            ''
          )}
        </Grid>
      </>
    );
  }
  return (
    <Grid container xs={12} justifyContent="space-between" style={{ paddingBottom: '20px' }}>
      {dwellingtype && dwellingtype.length > 0 && windowsdata.length > 0 ? (
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            {dwellingunitInterior.length > 0 && (
              <TableHead>
                <TableRow>
                  <TableCell style={{ border: 'none' }}>
                    <Typography>Regulalry occupied space</Typography>
                  </TableCell>
                  <TableCell align="left" style={{ border: 'none' }}>
                    {menuOpen && state === 'space' ? (
                      <TableDropdown menuOpen={menuOpen} setMenuOpen={setMenuOpen} arr={occupiedspacearr} tableboxname="Type of regularly occupied space" />
                    ) : (
                      <>
                        <Grid container item xs={12}>
                          <Grid item xs={10}>
                            <Typography
                              onClick={() => {
                                setState('space');
                                setMenuOpen(true);
                              }}
                            >
                              Type of regularly occupied space
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <ArrowDropDownIcon style={{ color: '#49DDB1' }} />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </TableCell>
                  <TableCell align="left" style={{ border: 'none' }}>
                    {menuOpen && state === 'window' ? (
                      <TableDropdown menuOpen={menuOpen} setMenuOpen={setMenuOpen} arr={typeofWindowarr} tableboxname="Type of Window" />
                    ) : (
                      <>
                        <Grid container item xs={12}>
                          <Grid item xs={10}>
                            <Typography
                              onClick={() => {
                                setState('window');
                                setMenuOpen(true);
                              }}
                            >
                              Type of Window
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <ArrowDropDownIcon style={{ color: '#49DDB1' }} />
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </TableCell>
                  <TableCell align="left" style={{ border: 'none' }}>
                    <Typography>Floor Area(sqm)</Typography>
                  </TableCell>
                  <TableCell align="left" style={{ border: 'none' }}>
                    <Typography>Openable Door Area (towards exterior) sqm</Typography>
                  </TableCell>
                  <TableCell align="left" style={{ border: 'none' }}>
                    <Typography>Skylight Area (sq.m)</Typography>
                  </TableCell>
                  <TableCell align="left" style={{ border: 'none' }}>
                    <Typography>Skylight Glazing VLT(%)</Typography>
                  </TableCell>
                  <TableCell align="left" style={{ border: 'none' }}>
                    <Typography>Opening in at least two orientations</Typography>
                  </TableCell>
                  <TableCell align="left" style={{ border: 'none' }}>
                    <Typography>Connectivity to exteriors</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
            )}

            {dwellingtype && dwellingtype.length > 0 && windowsdata.length > 0
              ? dwellingtype.map((element) =>
                  element.data.map((dwellingUnit) => {
                    return (
                      <>
                        <TableRow>
                          <TableCell colSpan={12} style={{ borderBottom: 'none' }}>
                            {GetHeader(element.type + ' ' + dwellingUnit.name)}
                          </TableCell>
                        </TableRow>
                        {dwellingunitInterior &&
                          dwellingunitInterior.map((interior, index2) => {
                            return (
                              <>
                                {interior.type === element.type + ' ' + dwellingUnit.name &&
                                  interior['data'].map((row, index3) => {
                                    return <>{GetTablerow(row, element.type + ' ' + dwellingUnit.name, index3, residentHealthAndWellBeingData)}</>;
                                  })}
                              </>
                            );
                          })}
                      </>
                    );
                  })
                )
              : ''}
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="h6" style={{ color: '#666666' }}>
          Add Dwelling Unit Types and Window Types to create Dwelling Unit Interiors
        </Typography>
      )}
    </Grid>
  );
}

const useStyles = makeStyles({
  table: {
    minWidth: 10,
  },
  tableRow: {
    '&:last-child td': {
      borderBottom: 'none', // Remove border for the last table row
    },
  },
});
