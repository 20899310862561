// React and hooks
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
// Material UI components and icons
import { Grid, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

// Custom components
// components / common / backdropLoader / BackdropLoader;
import { BackdropLoader, SearchField } from '../components/views/users/admin/main/Components';
import HeadAndBackIcon from '../components/common/header/HeadAndBackIcon';
import OrganizationFormModal from '../components/views/adminpannel/superAdmins/organisation/form';
import OrganizationManagementTable from '../components/views/adminpannel/superAdmins/organisation/table';

// Hooks and API
import { useAppApiRequest } from '../hooks/useAppApiRequest';
import api from '../http';

// Styles
const useStyles = makeStyles((theme) => ({
  searchInput: {
    width: '100%',
  },
  formControl: {
    minWidth: 200,
    maxWidth: '100%',
  },
  select: {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  menuPaper: {
    maxHeight: 300,
  },
  organizationName: {
    fontWeight: 500,
  },
  organizationCode: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1),
    fontSize: '0.875rem',
  },
  noOrganizations: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  searchField: {
    marginTop: '3%',
    marginBottom: '3%',
  },
}));

const Organisations = () => {
  const classes = useStyles();

  const [openOrganisationModal, setOpenOrganizationModal] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [loader, showLoader] = useState(false);

  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [editOrganisationDetails, setEditOrganisationDetails] = useState(null);

  // Your existing API hook
  const {
    data: organizationsData,
    isError: errorFetchingOrganizationsData,
    isFetching: fetchingOrganizationsData,
    refetch: refetchOrganizations,
  } = useAppApiRequest(() => api.getOrganisations, {}, [], true);

  // Handle edit organization
  const handleEditOrganization = (org) => {
    setSelectedOrganization(org);
    setOpenOrganizationModal(true);
    let obj = {};
    obj['organizationName'] = org?.name;
    obj['organizationCode'] = org?.code;
    obj['ownerEmail'] = org?.email;
    obj['organizationType'] = org?.type;
    obj['subscriptionType'] = org?.subscription?.type;
    obj['numberOfUsers'] = org?.subscription?.numberOfUsers;
    setEditOrganisationDetails(obj);
  };

  // Handle modal close
  const handleModalClose = () => {
    setSelectedOrganization(null);
    setEditOrganisationDetails(null);
    setOpenOrganizationModal(false);
  };

  useEffect(() => {
    if (organizationsData) {
      organizationsData
        .then((data) => {
          showLoader(false);
          setOrganizations(data?.data?.data?.body?.data);
        })
        .catch((error) => console.error('Error fetching organizations:', error));
    }
  }, [organizationsData]);

  useEffect(() => {
    if (fetchingOrganizationsData) showLoader(true);
  }, [fetchingOrganizationsData]);

  const renderOrganisationOps = () => (
    <Grid container justifyContent="space-between" className={classes.searchField}>
      <Grid item xs={9} container justifyContent="flex-start" alignItems="center">
        <SearchField setSearchValue={setSearchValue} classes={classes} />
      </Grid>
      <Grid item xs={3} container justifyContent="flex-end" alignItems="center">
        <Typography onClick={() => setOpenOrganizationModal(true)} variant="h5" style={{ color: '#00C58A', cursor: 'pointer' }}>
          <b>Create An Organisation</b>
        </Typography>
        <Grid item xs={1} container justifyContent="flex-end" alignItems="center" style={{ marginLeft: '5%' }}>
          <AddCircleIcon style={{ fontSize: '30px', color: '#00C58A' }} />
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <div>
      <BackdropLoader open={loader} />
      <Grid container justifyContent="space-between">
        <Grid item xs={10} container justifyContent="flex-start" alignItems="center">
          <HeadAndBackIcon heading="Organisations Management" showArrow={false} />
        </Grid>
      </Grid>
      {renderOrganisationOps()}
      <OrganizationManagementTable
        organizations={organizations || []}
        onEditOrganization={handleEditOrganization}
        searchValue={searchValue}
        onDeleteOrganization={() => {}} // Add delete functionality here
      />
      <OrganizationFormModal
        selectedOrganization={selectedOrganization}
        setSelectedOrganization={setSelectedOrganization}
        open={openOrganisationModal}
        onClose={handleModalClose}
        initialData={editOrganisationDetails}
        refetchOrganizations={refetchOrganizations}
        fetchingOrganizationsData={fetchingOrganizationsData}
      />
    </div>
  );
};

export default Organisations;
