import axios from 'axios';
import React, { useEffect } from 'react';
import { useLocation, Redirect } from 'react-router-dom';

const UserResponsePage = () => {
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const response = params.get('response');

  useEffect(() => {
    let body = {
      email: params.get('email'),
      name: params.get('name'),
      phone: params.get('phone'),
      additionalInfo: params.get('info'),
      vendorName: params.get('vendorName'),
      vendoremail: params.get('vendorEmail'),
      vendorPhoneNumber: params.get('vendorPhoneNumber'),
      vendorPointOfContact: params.get('venroPointofContact'),
      productName: params.get('productName'),
      emailresponse: params.get('response'),
    };
    axios.post(`/joinus/user-response`, body).then(() => {
      console.log('Successfully Sent The Response');
    });
  }, []);

  if (!response) {
    // If there is no response parameter in the URL, redirect to an error page or homepage
    return <Redirect to="/" />;
  }

  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      {response === 'Yes' ? <h2>Thank you for confirming your response!</h2> : <h2>Thank you for letting us know!</h2>}
      <p>We appreciate your feedback.</p>
    </div>
  );
};

export default UserResponsePage;
