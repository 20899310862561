import { api } from './http';

const getByProjectId = async (projectId) => {
  const res = await api.get(`/v2/project-interventions/project/${projectId}`);
  return res.data;
};

const getByProjectAndInterventionId = async (projectId, interventionId) => {
  const res = await api.get(`/v2/project-interventions/project/${projectId}/intervention/${interventionId}`);
  return res.data;
};

const createInterventionSet = async (payload) => {
  const res = await api.post('/v2/project-interventions/intervention-set', payload);
  return res.data;
};

const deleteInterventionSet = async (payload) => {
  const res = await api.post('/v2/project-interventions/intervention-set/remove', payload);
  return res.data;
};

const updateInterventionSet = async (payload) => {
  const res = await api.post('/v2/project-interventions/update-intervention-set', payload);
  return res.data;
};

const updateProjectIntervention = async (payload) => {
  const res = await api.put('/v2/project-interventions', payload);
  return res.data;
};

const addShortlistedProduct = async (payload) => {
  const res = await api.post('/v2/project-interventions/shortlisted-product', payload);
  return res.data;
};

const removeShortlistedProduct = async (payload) => {
  const res = await api.delete('/v2/project-interventions/shortlisted-product', payload);
  return res.data;
};

const updateActiveSet = async (payload) => {
  const res = await api.post('/v2/project-interventions/update-active-set', payload);
  return res.data;
};
const toggleEnabled = async (payload) => {
  const res = await api.post('/v2/project-interventions/toggle-enabled', payload);
  return res.data;
};
const toggleCustomInterventionEnabled = async (payload) => {
  const res = await api.post('/v2/project-interventions/toggle-enabled-custom-intervention', payload);
  return res.data;
};

export {
  getByProjectId,
  getByProjectAndInterventionId,
  createInterventionSet,
  deleteInterventionSet,
  updateInterventionSet,
  updateProjectIntervention,
  addShortlistedProduct,
  removeShortlistedProduct,
  updateActiveSet,
  toggleEnabled,
  toggleCustomInterventionEnabled,
};
