import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';

export const organizationTypes = ['builder', 'consultant', 'architect'];
export const subscriptionTypes = ['trial', 'enterprise'];
export const formFields = [
  {
    name: 'organizationName',
    label: 'Organization Name',
    required: true,
  },
  {
    name: 'organizationCode',
    label: 'Organization Code',
    required: true,
    inputProps: { maxLength: 3 },
  },
  {
    name: 'ownerEmail',
    label: 'Owner Email',
    type: 'email',
  },
  {
    name: 'organizationType',
    label: 'Organization Type',
    required: true,
    select: true,
    options: organizationTypes,
  },
  {
    name: 'subscriptionType',
    label: 'Subscription Type',
    required: true,
    select: true,
    options: subscriptionTypes,
  },
  {
    name: 'numberOfUsers',
    label: 'Number of Users',
    type: 'number',
    required: true,
    inputProps: { min: 1 },
  },
];

export const FormField = ({ name, label, value, onChange, error, type = 'text', disabled = false, select = false, options = [], ...props }) => {
  const fieldProps = {
    id: 'outlined-basic',
    variant: 'outlined',
    label,
    name,
    value,
    onChange,
    error: !!error,
    helperText: error,
    fullWidth: true,
    disabled,
    ...props,
  };

  if (select) {
    return (
      <TextField select {...fieldProps}>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </MenuItem>
        ))}
      </TextField>
    );
  }

  return <TextField {...fieldProps} type={type} />;
};
