import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Card } from '@material-ui/core';
import LinearProgressComponent from '../../../insightsPage/insights/child-components/LinerProgress';
import { getCategoryByFeature, getGoalContributionCardInfo } from '../../intervention/utils';
import numberWithCommas from '../../../../../utils/numberToComma';
import { getProgressValue } from '../../../../../utils/common';

function ContributionToGoalsCard(props) {
  const useStyles = makeStyles((theme) => ({
    paper4: {
      outline: 'none',
      height: props.height,
      width: props.width,
      padding: theme.spacing(1, 2, 2, 0),
      background: '#FFFFFF',
      borderRadius: '10px',
      boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
    },
    progress: {
      flexGrow: 1,
      marginTop: '8px',
    },
    grid: {
      marginTop: '7%',
    },
    text: {
      fontWeight: 350,
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: 0.71,
      color: '#9E9E9E',
    },
    cardHeader: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '22px',
      letterSpacing: 0.71,
      color: '#000000D1',
    },
  }));
  const classes = useStyles();
  const { featureKey, goalsProgressPercentage, goalContribution } = props;

  const cardInfo = getGoalContributionCardInfo(featureKey);
  return (
    <>
      <Card className={classes.paper4}>
        <Grid container alignItems="left" direction="column" xs={12}>
          <Grid container alignItems="center" direction="row" spacing={1} style={{ padding: 15 }}>
            <Grid item>
              <img src={cardInfo?.icon} alt="..." style={{ height: '2.5vh' }} />
            </Grid>
            <Grid item>
              <Typography className={classes.cardHeader}>{cardInfo?.text}</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ padding: 15 }}>
            <Grid item xs={12}>
              <Typography className={classes.text}>Net Zero Achieved</Typography>
            </Grid>
            <LinearProgressComponent
              bgcolor={cardInfo?.color}
              barvalue={getProgressValue(goalsProgressPercentage)}
              value={numberWithCommas(Math.round(goalsProgressPercentage))}
            />
          </Grid>
          <Grid container item xs={12} style={{ padding: 15 }}>
            <Grid item xs={12}>
              <Typography className={classes.text}>Contribution</Typography>
            </Grid>
            <LinearProgressComponent
              bgcolor={cardInfo?.color}
              barvalue={getProgressValue(goalContribution)}
              value={Math.round(numberWithCommas(goalContribution))}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default ContributionToGoalsCard;
