import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import useStyles from './styles';

const EfficientFixturesDualFlushCustomBasicParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityLabel, getQuantityUnit },
  } = useInterventionDetailsContext();

  const {
    efficientFixturesDualFlush_brand,
    efficientFixturesDualFlush_productName,

    efficientFixturesDualFlush_flushRateHalf,
    efficientFixturesDualFlush_flushRateFull,

    efficientFixturesDualFlush_vendorPerUnitCapex,
  } = schema;

  const brandTitle = getQuantityLabel(efficientFixturesDualFlush_brand);
  const brandValue = getQuantityValue(efficientFixturesDualFlush_brand);
  const productTitle = getQuantityLabel(efficientFixturesDualFlush_productName);
  const productValue = getQuantityValue(efficientFixturesDualFlush_productName);
  const fixtureHalfFlowTitle = getQuantityLabel(efficientFixturesDualFlush_flushRateHalf);
  const fixtureHalfFlowValue = getQuantityValue(efficientFixturesDualFlush_flushRateHalf);
  const fixtureHalfFlowUnit = getQuantityUnit(efficientFixturesDualFlush_flushRateHalf);
  const fixtureFullFlowTitle = getQuantityLabel(efficientFixturesDualFlush_flushRateFull);
  const fixtureFullFlowValue = getQuantityValue(efficientFixturesDualFlush_flushRateFull);
  const fixtureFullFlowUnit = getQuantityUnit(efficientFixturesDualFlush_flushRateFull);
  const perUnitCapexTitle = getQuantityLabel(efficientFixturesDualFlush_vendorPerUnitCapex);
  const perUnitCapexValue = getQuantityValue(efficientFixturesDualFlush_vendorPerUnitCapex);
  const perUnitCapexUnit = getQuantityUnit(efficientFixturesDualFlush_vendorPerUnitCapex);

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid item xs={8} container alignItems="flex-start">
        <Grid item xs={6}>
          <Typography className={classes.textHeader3}>{brandTitle}</Typography>
          <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
            {brandValue}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.textHeader3}>{productTitle}</Typography>
          <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
            {productValue}
          </Typography>
        </Grid>
      </Grid>

      <Grid container direction="row" alignItems="left" item xs={12} style={{ marginTop: '3%' }}>
        <Grid item xs={12} container alignItems="flex-start">
          <Grid item xs={4}>
            <Typography className={classes.textHeader3}>{fixtureHalfFlowTitle}</Typography>
            <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
              {fixtureHalfFlowValue} {fixtureHalfFlowUnit}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.textHeader3}>{fixtureFullFlowTitle}</Typography>
            <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
              {fixtureFullFlowValue} {fixtureFullFlowUnit}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.textHeader3}>{perUnitCapexTitle}</Typography>
            <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
              {perUnitCapexValue} {perUnitCapexUnit}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default EfficientFixturesDualFlushCustomBasicParameters;
