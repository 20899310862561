import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import Loader from '../../../../config/Loader';
import userRoles from '../../../../config/roles';
import { useHistory } from 'react-router-dom';

function SchemeBased() {
  let history = useHistory();
  const handleClick = () => {
    history.push(`/projects/create`);
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '200px' }}>
      <Typography className="project-create-button" variant="h5" style={{ cursor: 'pointer', color: '#29E1AC' }} onClick={handleClick}>
        CREATE PROJECT
      </Typography>
      <AddCircleRoundedIcon style={{ fontSize: '32px', color: '#29E1AC' }} />
    </div>
  );
}

const Heading = (props) => {
  return (
    <>
      {(props.width || window.innerWidth) > 1279 ? (
        <>
          <Grid container alignItems="center" item xs={12}>
            <Grid item xs={5} align="left">
              <Typography variant="h3" style={props.style}>
                My Projects
              </Typography>
            </Grid>
            <Grid item xs={4} />

            <Grid item xs={3} style={{ marginLeft: '73%' }}></Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container alignItems="center" item xs={12} align="center" justifyContent="center" style={props.style}>
            <Grid item xs={5} align="left">
              <Typography variant="h3">My Projects</Typography>
            </Grid>
            <Grid item xs={5} />

            <Grid item xs={2} align="right">
              {Loader(SchemeBased)(userRoles.all)}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default Heading;
