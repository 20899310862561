import { makeStyles, Modal, Typography } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import homepageBigOne from '../../assets/VERSION-TWO/landingPage/homepageBigOne.svg';
import projectDetails from '../../assets/tour/project-details.svg';
import projectGoals from '../../assets/tour/project-goals.svg';
import projectInsights from '../../assets/tour/project-insights.svg';
import projectInterventionDetails from '../../assets/tour/project-intervention-details.svg';
import projectSummary from '../../assets/tour/project-summary.svg';
import PrimaryButton from '../../components/common/button/Button';
import { useHistory } from 'react-router-dom';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    width: '65%',
    height: '90%',
  };
}

const CustomModalForTour = ({ image = '', handleClose = () => {}, title = '', children }) => {
  const classes = useStyles();
  const getStyle = useMemo(() => getModalStyle(), []);
  return (
    <>
      <div style={getStyle} className={classes.paper}>
        <CloseIcon className={classes.close} onClick={handleClose} />
        <div className={classes.modalImageSection}>
          <img className={classes.modalImage} src={image} alt={title} />
        </div>
        <div className={classes.modelSectionWrapper}>
          <div className={classes.modalSection}>
            <Typography variant="h4" className={classes.modalTitle}>
              {title}
            </Typography>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

const TourModal = (props) => {
  const { step, showModal, isTourFinished, handleEndTour = () => {} } = props;
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const ProjectListingTour = () => {
    return (
      <CustomModalForTour image={homepageBigOne} title="Welcome to SD+" handleClose={handleClose}>
        <div>
          <Typography className={classes.modalText}>
            We're excited to have you here. To help you get started, you can take a product tour by exploring our demo project. Let's work together to make a
            positive impact on the planet!
          </Typography>
        </div>
        <div className={classes.modalActions}>
          <PrimaryButton fullWidth={false} onClick={handleClose}>
            Start with project creation
          </PrimaryButton>
        </div>
      </CustomModalForTour>
    );
  };

  const ProjectDetailsTour = () => {
    return (
      <>
        <CustomModalForTour image={projectDetails} title="Welcome to Project Details" handleClose={handleClose}>
          <div>
            <Typography className={classes.modalText}>
              Here is where all of your project related inputs live. You just need some basic information to get started. You can come back and fill the other
              details any time. With more detailed information, the accuracy of your sustainability outcomes will be higher. Let's get started!
            </Typography>
          </div>
          <div className={classes.modalActions}>
            <PrimaryButton fullWidth={false} onClick={handleClose}>
              Continue
            </PrimaryButton>
          </div>
        </CustomModalForTour>
      </>
    );
  };

  const ProjectGoalsTour = () => {
    return (
      <>
        <CustomModalForTour image={projectGoals} title="Welcome to Goals" handleClose={handleClose}>
          <div>
            <Typography className={classes.modalText}>
              Goals are automatically suggested on a best case scenario once project details are entered. You can push the boundaries further with specific
              goals or pull back a bit based on certain project specific targets!
            </Typography>
          </div>
          <div className={classes.modalActions}>
            <PrimaryButton fullWidth={false} onClick={handleClose}>
              Continue
            </PrimaryButton>
          </div>
        </CustomModalForTour>
      </>
    );
  };

  const ProjectInsightsTour = () => {
    return (
      <>
        <CustomModalForTour image={projectInsights} title="Welcome to Insights" handleClose={handleClose}>
          <div>
            <Typography className={classes.modalText}>
              Here, you'll find a high-level overview of your project's resource and financial insights, goals, top performing interventions. This dashboard
              helps you quickly understand your project's status and identify areas for improvement. Let's dive in!
            </Typography>
          </div>
          <div className={classes.modalActions}>
            <PrimaryButton fullWidth={false} onClick={handleClose}>
              Continue
            </PrimaryButton>
          </div>
        </CustomModalForTour>
      </>
    );
  };

  const ProjectInterventionSummaryTour = () => {
    return (
      <>
        <CustomModalForTour image={projectSummary} title="Welcome to Interventions" handleClose={handleClose}>
          <div>
            <Typography className={classes.modalText}>
              Here, you can explore category-specific insights on energy, water, waste, and materials. Activate or deactivate interventions to see their impact
              on your project's sustainability and financial performance. You can even create your own interventions tailored to your project.
            </Typography>
          </div>
          <div className={classes.modalActions}>
            <PrimaryButton fullWidth={false} onClick={handleClose}>
              Continue
            </PrimaryButton>
          </div>
        </CustomModalForTour>
      </>
    );
  };

  const ProjectInterventionDetailsTour = () => {
    return (
      <>
        <CustomModalForTour image={projectInterventionDetails} title="Welcome to Intervention Details" handleClose={handleClose}>
          <div>
            <Typography className={classes.modalText}>
              This is where the magic happens. Here, you can explore in-depth information about a specific intervention. Adjust parameters to tailor the
              solution to your project needs, and add a vendor to implement your chosen solution.
            </Typography>
          </div>
          <div className={classes.modalActions} onClick={handleClose}>
            <PrimaryButton fullWidth={false}>Continue</PrimaryButton>
          </div>
        </CustomModalForTour>
      </>
    );
  };

  const ProjectEndTour = () => {
    return (
      <>
        <CustomModalForTour image={homepageBigOne} title="Congratulations" handleClose={handleClose}>
          <div>
            <Typography className={classes.modalText}>
              You're all set! Thank you for taking the time to explore SD+. If you need further assistance, such as FAQs or if you'd like to take the product
              tour again, please visit the Help section. Now it's time to dive in and start making your project sustainable.
            </Typography>
          </div>
          <div className={classes.modalActions}>
            <PrimaryButton
              fullWidth={false}
              onClick={async () => {
                await handleEndTour();
              }}
            >
              Close Tour
            </PrimaryButton>
          </div>
        </CustomModalForTour>
      </>
    );
  };

  const getTourStepModal = () => {
    const currentStep = step?.data?.currentStep;
    const currentPath = history?.location?.pathname;
    if (currentStep) {
      //PATCH: show the start tour modal only on projects listing page
      if (currentStep === 'My Projects' && currentPath.split('/').filter((i) => i.length).length <= 2) {
        return <ProjectListingTour />;
      } else if (currentStep === 'Project Details') {
        return <ProjectDetailsTour />;
      } else if (currentStep === 'Goals') {
        return <ProjectGoalsTour />;
      } else if (currentStep === 'Insights') {
        return <ProjectInsightsTour />;
      } else if (currentStep === 'Intervention') {
        return <ProjectInterventionSummaryTour />;
      } else if (currentStep === 'Intervention Details' && !isTourFinished) {
        return <ProjectInterventionDetailsTour />;
      }
    } else if (isTourFinished) {
      return <ProjectEndTour />;
    }
  };

  useEffect(() => {
    if (showModal) {
      setOpen(true);
    }
  }, [showModal, history?.location?.pathname, isTourFinished]);

  if (!showModal) return null;
  return (
    <>
      <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <>{getTourStepModal()}</>
      </Modal>
    </>
  );
};

export default TourModal;

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '870px',
    outline: 'none',
    height: 530,
    backgroundColor: '#FFFFFF',
    borderRadius: '16px',
  },
  modalImageSection: {
    borderRadius: '16px',
    height: '60%',
    background: '#EAFCF8',
    textAlign: 'center',
    padding: 16,
  },
  modalImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  modalTitle: {
    color: ' #26E0A3',
    textAlign: 'center',
    margin: theme.spacing(3),
    fontSize: 24,
  },
  modalText: {
    color: '#32363E',
    margin: theme.spacing(3),
    lineHeight: 1.6,
  },
  modelSectionWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '30%',
  },
  modalSection: {
    width: '70%',
    margin: '0 auto',
  },
  modalActions: {
    display: 'flex',
    justifyContent: 'end',
    gap: 8,
  },
  close: {
    right: 16,
    top: 16,
    fontSize: '24px',
    position: 'fixed',
    cursor: 'pointer',
  },
}));
