import React, { useEffect, useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import ResetFields from '../common/textFiledwithReset';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import useStyles from './styles';
import CustomDropdown from '../common/CustomDropdown';
const ScCustomBasicParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();
  const handleNumericInputChange = (schemaId, value, updateFunction) => {
    const numericValue = parseFloat(value) || 0;
    updateFunction(schemaId, numericValue);
  };
  const [fixingTypes, setFixingTypes] = useState([]);
  const [mortarOptions, setMortarOptions] = useState([]);
  const userTypeofWallBlock = getQuantityValue(schema.wallBlockExterior__pi_userTypeofWallBlock);
  const fixingType = getQuantityValue(schema.wallBlockExterior__pi_fixingType);
  const mortarAgent = getQuantityValue(schema.wallBlockExterior__pi_usermortaragent);
  const wallBlockTypes = {
    'Clay Bricks': ['Wet Fix'],
    'Concrete Blocks': ['Wet Fix'],
    'AAC Blocks': ['Wet Fix', 'Dry Fix'],
    'Fly Ash Bricks': ['Wet Fix'],
    'Porotherm Bricks': ['Wet Fix', 'Dry Fix'],
    'CSEB Blocks': ['Wet Fix'],
    'Low Carbon Blocks': ['Wet Fix'],
  };

  useEffect(() => {
    if (!userTypeofWallBlock) return;
    let options = [];
    let defaultValue = '';
    if (userTypeofWallBlock === 'Porotherm Bricks' && fixingType === 'Dry Fix') {
      options = ['Porotherm Chemical'];
      defaultValue = 'Porotherm Chemical';
    } else if (userTypeofWallBlock === 'AAC Blocks' && fixingType === 'Dry Fix') {
      options = ['AAC Chemical'];
      defaultValue = 'AAC Chemical';
    } else {
      options = ['1:4 - Cement Sand', '1:6 - Cement Sand'];
      defaultValue = '1:4 - Cement Sand';
    }
    setMortarOptions(options);
    updateQuantityValue(schema.wallBlockExterior__pi_usermortaragent, defaultValue);
    updateQuantityValue(schema.wallBlockExterior__pi_blockQuantity, null, { replaceActualValue: false });
  }, [fixingType, userTypeofWallBlock]);

  const handleWallBlockTypeChange = (event) => {
    event.preventDefault();
    updateQuantityValue(schema.wallBlockExterior__pi_userTypeofWallBlock, event.target.value);
    updateQuantityValue(schema.wallBlockExterior__pi_selectedProductIdentifier, null);
    updateQuantityValue(schema.wallBlockExterior__pi_blockQuantity, null, { replaceActualValue: false });
    if (event.target.value) {
      const validFixingTypes = wallBlockTypes[event.target.value] || [];
      setFixingTypes(validFixingTypes);
      // If the current fixing type is not valid for the selected block type, update it
      if (!validFixingTypes.includes(fixingType)) {
        updateQuantityValue(schema.wallBlockExterior__pi_fixingType, validFixingTypes[0] || '');
      }
    }
  };

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid xs>
            <Typography className={classes.textheader2}>{getQuantityLabel(schema.wallBlockExterior__pi_userTypeofWallBlock)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <CustomDropdown
                value={userTypeofWallBlock}
                onChange={handleWallBlockTypeChange}
                options={['Clay Bricks', 'Concrete Blocks', 'AAC Blocks', 'Fly Ash Bricks', 'Porotherm Bricks', 'CSEB Blocks', 'Low Carbon Blocks']}
                disabled={false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid xs>
            <Typography className={classes.textheader2}>{getQuantityLabel(schema.wallBlockExterior__pi_fixingType)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <CustomDropdown
                value={fixingType}
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.wallBlockExterior__pi_fixingType, e.target.value);
                  updateQuantityValue(schema.wallBlockExterior__pi_blockQuantity, null, { replaceActualValue: false });
                }}
                options={fixingTypes}
                disabled={false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid xs>
            <Typography className={classes.textheader2}>{getQuantityLabel(schema.wallBlockExterior__pi_blockQuantity)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.wallBlockExterior__pi_blockQuantity)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  handleNumericInputChange(schema.wallBlockExterior__pi_blockQuantity, e.target.value, updateQuantityValue);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.wallBlockExterior__pi_blockQuantity, getQuantityDefaultValue(schema.wallBlockExterior__pi_blockQuantity));
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.wallBlockExterior__pi_blockQuantity)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4} container alignItems="flex-start" direction="column" style={{ marginTop: '2%' }}>
        <Grid xs>
          <Typography className={classes.textheader2}>{getQuantityLabel(schema.wallBlockExterior__pi_usermortaragent)}</Typography>
        </Grid>
        <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
          <Grid xs={7}>
            <CustomDropdown
              value={mortarAgent}
              onChange={(e) => {
                e.preventDefault();
                updateQuantityValue(schema.wallBlockExterior__pi_usermortaragent, e.target.value);
                updateQuantityValue(schema.wallBlockExterior__pi_blockQuantity, null, { replaceActualValue: false });
              }}
              options={mortarOptions}
              disabled={false}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ScCustomBasicParameters;
