import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper } from '@material-ui/core';
import rsToCr from '../../../../../utils/rsToCr';
import CasaSkeleton from '../../../../common/skeleton/CasaSkeleton';
import numberWithCommas from '../../../../../utils/numberToComma';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';
import { getLabelDynamically } from '../utils';
import Bold from '../../../../../v2/components/Bold';

const useStyles = makeStyles((theme) => ({
  smallpaper1: {
    width: '80%',
    padding: theme.spacing(1.5, 1.5, 1.5, 2),
    boxShadow: '2px 8px 16px rgba(0, 197, 138, 0.08)',
    borderRadius: '10px',
    // background: 'grey',
  },
  smallpaper2: {
    width: '100%',
    borderRadius: '10px',
    padding: theme.spacing(1.5, 1.5, 1.5, 2),
    boxShadow: '2px 8px 16px rgba(0, 197, 138, 0.08)',
    // background: 'grey',
  },
  textColor: {
    color: 'rgba(255, 255, 255, 0.82)',
  },
  content: {
    marginTop: '28%',
  },
}));

const TooltipData = [
  { category: 'Energy', data: 'A home is assumed to consume 1,080 kWh of electricity annually (or 90 kWh per month) ' },
  { category: 'Water', data: 'Capacity of 1 Water Tanker is taken as 6 kL' },
  { category: 'Waste', data: 'Capacity of 1 Waste Truck is taken as 10 tonnes or 10,000 Kg' },
  { category: 'Materials', data: null },
];

function TableOverview({ data, isLoading = true }) {
  const classes = useStyles();
  // const name = categoryname;
  const { getValue, getUnit } = useAppMetafireUtilities();

  const resource = data?.name;
  const financeSavings = data?.quantities?.finance;
  const resourceSavings = data?.quantities?.resource;
  const capex = financeSavings?.capex;
  const opex = financeSavings?.opexSavings;
  const capexTotal = financeSavings?.totalCapex;
  const category = resourceSavings?.category;
  const carbon = resourceSavings?.carbon;
  const categoryConverted = resourceSavings?.categoryConverted;
  const capexValue = rsToCr(getUnit(capex), getValue(capex));
  const opexValue = rsToCr('Rs', getValue(opex));

  const categoryValue = getValue(category);
  const carbonValue = getValue(carbon);
  const capexTotalValue = rsToCr(getUnit(capexTotal), getValue(capexTotal));

  const categoryUnit = getUnit(category);
  const carbonUnit = getUnit(carbon);
  const financialImpactItemsUnit = 'Cr';

  return (
    <>
      <Grid container xs={6} align="left" direction="column">
        <Typography variant="h5">Financial Impact</Typography>
        <Paper className={classes.smallpaper1}>
          <Grid container alignItems="center" item xs={12} direction="row">
            <Grid container xs={4} direction="column" style={{ paddingTop: '3%' }}>
              <Typography variant="h6" color="textSecondary">
                Capex Increase
              </Typography>
              <Grid xs style={{ marginTop: '-5%' }}></Grid>
              {!isLoading ? (
                <Typography variant="h5">
                  <b>{`${numberWithCommas(capexValue)} ${financialImpactItemsUnit}`}</b>
                </Typography>
              ) : (
                <CasaSkeleton variant="rect" height="2vh" width="60%" animation="wave" style={{ margin: '16px 0', marginLeft: '-2%' }} />
              )}
            </Grid>
            <Grid container xs={4} direction="column" style={{ paddingTop: '3%' }}>
              <Typography variant="h6" color="textSecondary">
                Total Capex
              </Typography>
              <Grid xs style={{ marginTop: '-5%' }}></Grid>
              {!isLoading ? (
                <Typography variant="h5">
                  <b>{`${numberWithCommas(capexTotalValue)} ${financialImpactItemsUnit}`}</b>
                </Typography>
              ) : (
                <CasaSkeleton variant="rect" height="2vh" width="60%" animation="wave" style={{ margin: '16px 0', marginLeft: '-2%' }} />
              )}
            </Grid>
            {/* <Grid item xs={1}></Grid> */}
            <Grid container xs={4} direction="column" style={{ paddingTop: '3%' }}>
              <Typography variant="h6" color="textSecondary">
                Annual Savings
              </Typography>
              <Grid xs style={{ marginTop: '-5%' }}></Grid>
              {!isLoading ? (
                <Typography variant="h5">
                  <b>{`${numberWithCommas(opexValue)} ${financialImpactItemsUnit}`}</b>
                </Typography>
              ) : (
                <CasaSkeleton variant="rect" height="2vh" width="60%" animation="wave" style={{ margin: '16px 0', marginLeft: '-2%' }} />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid container xs={6} align="left" direction="column">
        <Typography variant="h5">Resource Impact</Typography>

        <Paper className={classes.smallpaper2}>
          <Grid container alignItems="left" item xs={12} direction="row">
            <Grid container xs={4} direction="column" style={{ paddingTop: '2%' }}>
              <Typography variant="h6" color="textSecondary">
                {resource
                  ? resource === 'Waste'
                    ? getLabelDynamically(resource) + ` Avoided`
                    : getLabelDynamically(resource) + ` Savings`
                  : 'Resource Savings'}
              </Typography>
              <Grid xs style={{ marginTop: '-4%' }}></Grid>
              {!isLoading ? (
                <Bold isHighlight={true} variant="h5" value={numberWithCommas(categoryValue)} unit={categoryUnit} />
              ) : (
                <CasaSkeleton variant="rect" height="2vh" width="60%" animation="wave" style={{ margin: '16px 0', marginLeft: '-2%' }} />
              )}
            </Grid>
            {resource !== 'Materials' && (
              <Grid container xs={4} direction="column" style={{ borderRight: '1px solid #CCCCCC', paddingTop: '2%', marginRight: 8 }}>
                <Typography variant="h6" color="textSecondary">
                  Carbon Savings
                </Typography>
                <Grid xs style={{ marginTop: '-4%' }}></Grid>
                {!isLoading ? (
                  <Bold isHighlight={true} variant="h5" value={numberWithCommas(carbonValue)} unit={carbonUnit} />
                ) : (
                  <CasaSkeleton variant="rect" height="2vh" width="60%" animation="wave" style={{ margin: '16px 0', marginLeft: '-2%' }} />
                )}
              </Grid>
            )}
            <Grid container item xs={3} alignItems="center" justifyContent="center">
              {!isLoading ? (
                <>
                  <Typography variant="h6" color="textSecondary">
                    {`${numberWithCommas(getValue(categoryConverted))} ${getUnit(categoryConverted)}`}
                    <CustomizedTooltips
                      marginTop="3%"
                      icon="info"
                      placement="left"
                      title={
                        TooltipData && TooltipData.find((d) => d.category === resource) && TooltipData.find((d) => d.category === resource).data !== null ? (
                          <Typography variant="h6" style={{ lineHeight: '20px' }}>
                            {TooltipData.find((d) => d.category === resource).data}
                          </Typography>
                        ) : (
                          <Typography variant="h6">6 trees are needed to mitigate 1 tCO2e</Typography>
                        )
                      }
                    />
                  </Typography>
                </>
              ) : (
                <CasaSkeleton variant="rect" height="2vh" width="60%" animation="wave" style={{ marginTop: '8%', marginLeft: '-2%' }} />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}

export default TableOverview;
