export const MultipleProductsQuery = (productIds) => `query Q {
  products(filters:{id:{ in:[${productIds}] }}){
    data {
    id
      attributes{
        sku_name
 catalog_sku_id
        sku_name
        sdplus_interventions
        environmental_impact_ecosystem_health
        environmental_impact_human_health
        environmental_impact_circularity
        environmental_impact_climate_health
        quality_assurance_and_reliability
        warranty_and_after_sales
        references
    sdg
        brand{
          data{
            attributes{
              name
              manufacturing_locations
              region_of_operation
            }
          }
        }
        short_description
        image
        description
        price
        brochure
        properties
        certifications
        metadata
      }
    }        
}
}`;
