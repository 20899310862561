import { api } from './http';

const getProjects = async (organizationId) => {
  const response = await api.get(`/v2/projects/${organizationId}`);
  return response.data.data.body.data;
};

const getProjectBasics = async (projectId) => {
  const response = await api.get(`/v1/projectv2/${projectId}/basics`);
  return response;
};

const getCurrentProject = async (organizationId) => {
  const response = await api.get(`/v2/projects/${organizationId}/current`);
  return response.data.data.body.data;
};

export { getProjects, getProjectBasics, getCurrentProject };
