/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import swal from 'sweetalert';
import axios from '../../../../config/Axios';
import PrimaryButton from '../../../common/button/Button';
import HeadAndBackIcon from '../../../common/header/HeadAndBackIcon';
import PlumbingDetailsComponent from '../plumbing/PlumbingDetailsComponent';
import { SomethingChanged } from '../EditProject';
import useAppProjectDetails from '../hooks/useAppProjectDetails';
import useAppProjectPIPMapping from '../hooks/useAppProjectPIPMapping';
import useAppPIPScheme from '../hooks/useAppPIPScheme';
import ApiError from '../../../common/Error/ApiError';

const EditProjectsPlumbingPIP = (props) => {
  let history = useHistory();
  let { id } = useParams();
  let { type, next, name } = props;
  const { somethingChanged } = useContext(SomethingChanged);

  const { data: projectData } = useAppProjectDetails(id);
  const {
    get: { form, projectPIP, isError },
    set: { handlePIPValueUpdate },
  } = useAppProjectPIPMapping(id);

  const compare = (a, b) => {
    if (a.createdAt < b.createdAt) return -1;
    if (a.createdAt > b.createdAt) return 1;
    else return 0;
  };

  const { data: pipSchemeData } = useAppPIPScheme(type);
  const data = pipSchemeData ? pipSchemeData?.sort((a, b) => compare(a, b)) : [];

  const { mannuals, customs } = useMemo(() => {
    if (data) {
      const mannuals = [
        'project__pi_domesticKitchenSinkFlow',
        'project__pi_domesticShowerFlow',
        'project__pi_domesticFaucetFlow',
        'project__pi_domestichHealthFaucetFlow',
        'project__pi_domesticFlushingFullFlow',
        'project__pi_domesticFlushingHalfFlow',
        'project__pi_domesticShowerPrice',
        'project__pi_domesticHealthFaucetPrice',
        'project__pi_domesticKitchenSinkPrice',
        'project__pi_domesticFaucetPrice',
        'project__pi_domesticFlushingFullPrice',
        'project__pi_domesticFlushingHalfPrice',
      ];
      const custom = ['project__pi_customLPCD'];
      const mannualData = data?.filter((d) => d?.category === 'fixture' && mannuals.includes(d.name));
      const customData = data?.filter((d) => d?.category === 'fixture' && custom.includes(d.name));

      return {
        mannuals: mannualData,
        customs: customData,
      };
    }
    return {};
  }, [data]);

  const { builtup, dwelling } = useMemo(() => {
    if (projectPIP) {
      const builtupArea = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_builtUpArea');
      const studio = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_studio');
      const single = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_oneBHK');
      const double = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_twoBHK');
      const three = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_threeBHK');
      const four = projectPIP && projectPIP.find((d) => d.PIPScheme.name === 'project__pi_fourBHK');
      const StudioUnits = studio && studio.value ? parseInt(studio.value) : 0;
      const OneBHKUnits = single && single.value ? parseInt(single.value) : 0;
      const TwoBHKUnits = double && double.value ? parseInt(double.value) : 0;
      const ThreeBHKUnits = three && three.value ? parseInt(three.value) : 0;
      const FourBHKUnits = four && four.value ? parseInt(four.value) : 0;

      return {
        builtup: builtupArea?.value,
        dwelling: StudioUnits + OneBHKUnits + TwoBHKUnits + ThreeBHKUnits + FourBHKUnits,
      };
    }
    return {};
  }, [projectPIP]);

  const handleNext = (next) => {
    if (next && next === 'goals') history.push(`/projects/${id}/goals`);
    else {
      if (somethingChanged) {
        props.toogleShowModal(true);
        props.SETNEXT(next);
      } else {
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (props.saveAfterSomethingChanged === true && next) {
        await handleSave(next && next);
        props.toogleShowModal(false);
        props.setFalseAfterSomethingChanged();
        props.SETNEXT('');
      }
    })();
  }, [props.saveAfterSomethingChanged, next]);

  const handleGoBack = (type) => {
    if (type === 'basic') history.push(`/projects/${id}/insights`);
    else history.push(`/projects/${id}/edit?type=basic`);
  };
  const handleSave = (next) => {
    axios
      .put(`/ProjectPIPMapping`, {
        projectId: id,
        values: Object.entries(form?.pipvalues).map(([key, value]) => ({
          PIPScheme: key,
          value,
        })),
      })
      .then((response) => {
        props.toogleShowModal(false);
        props.setFalseAfterSomethingChanged();
        props.SETNEXT('');
        swal({ icon: 'success', title: 'Data Saved' });
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      });
  };
  if (isError)
    return (
      <div style={{ display: 'flex', alignItems: 'center', paddingTop: 24, width: '100%' }}>
        <ApiError />
      </div>
    );
  return (
    <>
      <Grid item container justifyContent="left" direction="column" xs={12} style={{ marginTop: '3%', marginLeft: '1%' }}>
        <HeadAndBackIcon
          heading={`${name && name} ${projectData?.name ? projectData.name : ''}`}
          handleClick={() => handleGoBack(type && type)}
          visitGoals={false}
          headingVariant="h5"
          headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
          showArrow={false}
        />
      </Grid>

      {/* for fixture new component*/}
      {type && type === 'fixture' && (
        <PlumbingDetailsComponent
          pipValues={form?.pipvalues}
          fixturesData={data && data}
          projectPIP={projectPIP && projectPIP}
          appendChanged={(id, value) => handlePIPValueUpdate(id, value)}
          type={type && type}
          mannuals={mannuals && mannuals}
          builtup={builtup && builtup}
          dwelling={dwelling && dwelling}
          customs={customs && customs}
        />
      )}

      <Grid container direction="row" alignItems="end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '7%' }}>
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleSave(next && next)} children="Save" />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleNext(next && next)} children="next" />
        </Grid>
      </Grid>
    </>
  );
};
export default EditProjectsPlumbingPIP;
