import { useState } from 'react';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import ShowCustomizableParameters from '../components/showCustomizableParameters';
import ShowMandates from '../components/showMandates';
import ShowProjectParameters from '../components/showProjectInputParameters';
import { Grid } from '@material-ui/core';

function BasicInterventionDetails(props) {
  const [source, setSource] = useState('');
  const [minError, setMinError] = useState(false);
  const [maxError, setMaxError] = useState(false);

  const { meta } = useInterventionDetailsContext();

  // TODO: ????
  // useEffect(() => {
  //   if (customizable && myArray) {
  //     const b = !myArray.find((d) => d.id === 'rooftoprwh.rrwhtanksizesource')
  //       ? customizable.find((d) => d.tags.includes('tank-size-options'))
  //       : myArray.find((d) => d.id === 'rooftoprwh.rrwhtanksizesource');
  //     if (b && b.value === 'Default') setSource('Default');
  //     else setSource('Custom');
  //   }
  // }, [customizable, myArray]);

  const { customizable, boundaryMin, boundaryMax, mandates, mandateGenerals, projectinputs } = meta;
  return (
    <>
      <Grid container alignItems="flex-start" direction="row" xs={12} style={{ marginBottom: '3%' }}>
        <ShowCustomizableParameters
          setMaxError={(a) => setMaxError(a)}
          setMinError={(b) => setMinError(b)}
          setSource={(s) => setSource(s)}
          customizable={customizable}
          // myArray={myArray && myArray}
          appendChanged={() => {}} // TODO:
          boundaryMin={boundaryMin}
          boundaryMax={boundaryMax}
          source={source}
          // minError={minError && minError}
          // maxError={maxError && maxError}
        />
        <ShowMandates
          mandates={mandates}
          mandateicon={props.mandateicon}
          style={{ background: '#FDF5F2', borderRadius: '10px', paddingTop: '5px', paddingBottom: '5px', border: `2px solid #E29578`, marginBottom: '10px' }}
        />
        <ShowMandates
          mandates={mandateGenerals}
          mandateicon={props.mandateGeneralsIcon}
          style={{ background: `#F3FCFA`, borderRadius: '10px', paddingTop: '5px', paddingBottom: '5px', border: `2px solid #49DDB1`, marginBottom: '10px' }}
        />
        <ShowProjectParameters projectinputs={projectinputs} />
      </Grid>
    </>
  );
}

export default BasicInterventionDetails;
