/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from '../styles';
import { useInterventionDetailsContext } from '../../context/InterventionDetailsV2';
import { useAppMetafireUtilities } from '../../../../../../metafire/hooks/useAppMetafireUtilities';
import { LegalQuantityTypes } from '../../../../../../metafire/utils/types';
import CasaSkeleton from '../../../../../common/skeleton/CasaSkeleton';
import numberWithCommas from '../../../../../../utils/numberToComma';

const DataTableSection = ({ title, headers, rows, getRowContent, isAdditionalInfo }) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" item xs={12}>
      <Typography className={classes.tableTextPrimary}>{title}</Typography>
      <Grid container item xs={12} className={classes.tableStyle}>
        <Grid className={classes.rowStyle}>
          {headers?.map((header, idx) => {
            const maxHeaderHeight = header.length > 25 ? 60 : 40;
            const maxContentHeight = Math.max(
              ...rows.map((row) => {
                const content = getRowContent(row)[idx];
                return typeof content === 'string' && content.length > 25 ? 60 : 40;
              })
            );
            const calculatedHeight = Math.max(maxHeaderHeight, maxContentHeight);
            return (
              <Typography key={idx} className={classes.tableTitle} style={{ height: `${calculatedHeight}px` }}>
                {header}
              </Typography>
            );
          })}
        </Grid>

        {rows.map((row, index) => {
          const backgroundColor = index % 2 === 0 ? 'rgba(245, 245, 245, 1)' : 'rgba(251, 251, 251, 1)';

          if (!row.length)
            return (
              <Grid key={index} className={classes.rowStyle} style={{ backgroundColor }}>
                <CasaSkeleton />
              </Grid>
            );

          return (
            <Grid key={index} className={classes.rowStyle} style={{ backgroundColor }}>
              {!isAdditionalInfo ? (
                getRowContent(row).map((content, idx) => {
                  const maxHeaderHeight = headers[idx].length > 25 ? 60 : 40;
                  const contentHeight = typeof content === 'string' && content.length > 25 ? 60 : 40;
                  const calculatedHeight = Math.max(maxHeaderHeight, contentHeight);
                  return (
                    <Typography
                      key={idx}
                      className={classes.tableContent}
                      style={{
                        height: `${calculatedHeight}px`,
                      }}
                    >
                      {content}
                    </Typography>
                  );
                })
              ) : (
                <Typography key={index} className={classes.additionalInfo}>
                  {getRowContent()[index]}
                </Typography>
              )}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

const InterventionDataTable = ({ metafireData, setDescriptions }) => {
  const classes = useStyles();
  const { getUnit, getValue, getRoundOffValue } = useAppMetafireUtilities();
  const {
    get: { getQuantitiesByTypeDefinition },
    meta,
  } = useInterventionDetailsContext();
  const { customizable, result1, result2 } = meta;

  const getValueForTable = (contextObject, mData) => {
    let metafireObject = mData.find((data) => data.id === contextObject.id);
    if (!metafireObject) return '-';

    let value = getValue(metafireObject);
    if (typeof value === 'string' && isNaN(value)) return value;
    if (value == null || isNaN(value)) return '-';
    let unit = getUnit(metafireObject);
    return unit !== '-' ? `${numberWithCommas(Number(value))} ${unit}` : `${value}`;
  };

  const overviewValues = useMemo(() => getQuantitiesByTypeDefinition(LegalQuantityTypes?.Overview), [result1]);
  const interventionResults = useMemo(() => [...result1, ...result2], [result1, result2]);
  const donutValues = useMemo(() => getQuantitiesByTypeDefinition(LegalQuantityTypes?.Donuts), [result1]);
  const secondaryImpact = useMemo(() => getQuantitiesByTypeDefinition(LegalQuantityTypes.PassiveImpact), [result1]);

  if (!metafireData.length) {
    return (
      <Grid container direction="column" item xs={12} className={classes.spacing}>
        <CasaSkeleton variant="rect" height="25vh" width={900} animation="wave" style={{ marginTop: '3%', marginBottom: '3%' }} />
      </Grid>
    );
  }

  const getUsageByCategoryRowContent = (data) =>
    (overviewValues[0]?.value?.breakup || []).map((ele) => {
      const matchedData = data.find((d) => d.id === overviewValues[0]?.id)?.value?.breakup.find((d) => d.label === ele.label);

      return `${numberWithCommas(Math.round(matchedData?.value ?? ele?.value))} ${ele?.unit}`;
    });

  const getContributionToTotalSavingsRowContent = (data) =>
    (donutValues[0]?.value || []).map((ele) => {
      const matchedData = data.find((d) => d.id === donutValues[0]?.id)?.value.find((d) => d.label === ele.label);

      return `${matchedData?.value != null ? getRoundOffValue(matchedData.value, 0) : ele?.value != null ? getRoundOffValue(ele.value, 0) : '0.00'}%`;
    });

  const getSecondaryImpactRowContent = (data) =>
    (secondaryImpact[0]?.value || []).flatMap((ele) => {
      const matchedData = data.find((d) => d.id === secondaryImpact[0]?.id)?.value.find((d) => d.category === ele.category);

      return [
        getRoundOffValue(matchedData?.resource?.value, 0) ?? getRoundOffValue(ele?.resource?.value, 0),
        getRoundOffValue(matchedData?.opex?.value, 0) ?? getRoundOffValue(ele?.opex?.value),
      ];
    });
  return (
    <>
      <DataTableSection
        title="Intervention Parameters"
        headers={customizable?.map((ele) => ele.label)}
        rows={metafireData}
        getRowContent={(data) => customizable?.map((ele) => getValueForTable(ele, data))}
      />
      <DataTableSection
        title="Intervention Results"
        headers={interventionResults?.map((ele) => ele.label)}
        rows={metafireData}
        getRowContent={(data) => interventionResults?.map((ele) => getValueForTable(ele, data))}
      />
      <DataTableSection
        title="Usage by Category"
        headers={overviewValues?.[0]?.value?.breakup?.map((ele) => ele.label) || []}
        rows={metafireData}
        getRowContent={getUsageByCategoryRowContent}
      />
      <DataTableSection
        title="Contribution to Total Savings"
        headers={donutValues[0]?.value?.map((ele) => ele.label) || []}
        rows={metafireData}
        getRowContent={getContributionToTotalSavingsRowContent}
      />
      <DataTableSection
        title="Secondary Impact"
        headers={(secondaryImpact[0]?.value || []).flatMap((ele) => [ele?.resource?.label, ele?.opex?.label])}
        rows={metafireData}
        getRowContent={getSecondaryImpactRowContent}
      />
      <DataTableSection title="Others" headers={['Additional Information']} rows={metafireData} getRowContent={setDescriptions} isAdditionalInfo={true} />
    </>
  );
};

export default InterventionDataTable;
