/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { MultipleProductsQuery } from '../../graphql-queries/MultipleProducts.query';
import useGraphqlRequest from '../../../catalogue/Common/SendRequest';
import HeaderAndBackIcon from '../../common/Header/HeaderAndBackIcon';
import { MarketplaceColors, MarketplaceTypography } from '../../config';
import useScreenType from '../../../../hooks/checkScreenType';
import CustomBreadCrumbs from '../../../../common/breadcrumbs/CustomBreadCrumbs';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import circularity from '../../../../../assets/Marketplace/product/circularity.svg';
import climate from '../../../../../assets/Marketplace/product/climatehealth.svg';
import human from '../../../../../assets/Marketplace/product/humanhealth.svg';
import ecosystem from '../../../../../assets/Marketplace/product/ecosystem.svg';
import ProductCardComparisonPage from './ProductCardComparisonPage';
import ProductDetailsAddToInterventions from '../productDetails/components/ProductDetailsAddToInterventions';
import swal from 'sweetalert';
import { BackdropLoader } from '../../../users/admin/main/Components';
import { MetafireInterventions } from '../../../../../metafire/utils/types';
import { addShortlistedProduct, getByProjectAndInterventionId, updateInterventionSet } from '../../../../../http/projectIntervention';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
  },
  productCardText: {
    fontSize: '14px',
    lineHeight: '17px',
    letterSpacing: '0.71px',
    color: '#000000',
    fontWeight: 700,
    margin: '2%',
  },
  MuiAccordionroot: {
    '&.MuiAccordion-root:before': {
      backgroundColor: '#fafafa',
    },
    '&.MuiAccordionSummary-root': {
      border: '1px solid #E5E5E5',
      borderRadius: '8px',
    },
    '&.Mui-expanded': {
      border: '1px solid #C6F2CD',
      borerRadius: '8px',
    },
  },
  accordion: {
    border: '1px solid #E5E5E5',
    marginBottom: '8px',
    borderRadius: '8px', // Default border for all accordions
    '&.Mui-expanded': {
      border: '1px solid #00C58A', // Border for the expanded accordion
      borderRadius: '8px',
    },
  },
  MuiAccordionrootstyle: {
    '&.MuiAccordionDetails-root': {
      padding: '0px',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  expand_icon_top: {
    color: '#26E0A3',
    height: '40px',
    width: '40px',
    borderRadius: '50%',
  },
  productDataHeader: {
    fontSize: '20px',
    lineHeight: '17px',
    letterSpacing: '0.71px',
    color: '#000000',
    fontWeight: 700,
  },
  productDataSubHeader: {
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.71px',
    color: '#000000',
    fontWeight: 700,
    marginLeft: '1%',
  },
  nestedKeyHeader: {
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.71px',
    color: '#000000',
    fontWeight: 700,
  },
  valueKey: {
    fontSize: '16px',
    lineHeight: '21px',
    fontWeight: 700,
    color: '#2E2E2E',
  },
  value: {
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 400,
    color: '#2E2E2E',
    paddingLeft: '8px',
  },
  valueKeyBox: {
    minHeight: '70px',
    width: '210px',
    overflowWrap: 'anywhere',
    backgroundColor: '#FBFBFB',
    paddingLeft: '8px',
  },
}));

function ProductComparison() {
  const classes = useStyles();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const history = useHistory();
  const selectedProductIds = query.get('selectedProducts');
  const projectId = query.get('projectId');
  const interventionId = query.get('interventionId');
  const featureKey = query.get('interventionKey');
  const source = query.get('source');
  const selectedProduct = query.get('selectedProduct');
  const { isMobile } = useScreenType();
  const [state, SendRequest] = useGraphqlRequest(MultipleProductsQuery(selectedProductIds));
  const [envioronMentalAccordion, setEnvioronMentalAccordion] = useState(true);
  const [certificationsAccordion, setCertificationsAccordion] = useState(true);
  const [propertiesAccordion, setPropertiesAccordion] = useState(true);
  const [generalAccordion, setGeneralAccordion] = useState(true);
  const [showAddToInterventionModal, setShowAddToInterventionModal] = useState(false);
  const [productAddedToIntervention, setProductAddToIntervention] = useState('');
  const [loading, setLoading] = useState(true);
  const products = state?.response?.data?.data?.products?.data || [];
  const links = [{ href: `/marketplace/categories`, text: 'Categories' }];
  const procurementInformationKeysLabels = [
    { label: 'Product Code', key: 'catalog_sku_id' },
    { label: 'Manufacturing Locations', key: 'brand.data.attributes.manufacturing_locations' },
    { label: 'Region Of Operation', key: 'brand.data.attributes.region_of_operation' },
    { label: 'Quality Assurance and Reliability (QAR)', key: 'quality_assurance_and_reliability' },
    { label: 'Warranty and After Sales', key: 'warranty_and_after_sales' },
  ];
  const sdgKeysLabels = [{ label: 'SDG', key: 'sdg' }];
  function getNestedValue(obj, path, nestedPath = '', dataKey = '') {
    if (dataKey !== 'environmental_impact_circularity') {
      return path.split('.').reduce((current, key) => current && current[key], obj);
    }
    if (dataKey === 'environmental_impact_circularity') {
      return obj[dataKey][path][nestedPath];
    }
  }
  const convertStringToObject = (data = '') => {
    const obj = {};
    data?.split('\n')?.forEach((item) => {
      const value = item.includes('https') || item.includes('http') ? item.split(': ') : item.split(':');
      obj[value[0]] = value[1] || '';
    });
    return obj;
  };
  const getAllKeys = (data = [], dataKey) => {
    let keys = new Set();
    data?.forEach((d) => {
      const dataObject = dataKey === 'certifications' || dataKey === 'references' ? convertStringToObject(d?.attributes[dataKey]) : d?.attributes[dataKey];
      keys = new Set([...keys, ...Object.keys(dataObject)]);
    });
    return [...keys].filter((key) => key);
  };
  const getAllNestedKeys = (data, dataKey, innerKey) => {
    let keys = new Set();
    data?.forEach((d) => {
      const dataObject = d?.attributes[dataKey][innerKey];
      keys = new Set([...keys, ...Object.keys(dataObject)]);
    });
    return [...keys].filter((key) => key);
  };
  const formatValue = (data) => {
    return data && data !== ' ' ? data : '-';
  };
  useEffect(() => {
    if (state?.response?.data?.data?.products?.data) setLoading(false);
  }, [state]);
  useEffect(() => {
    SendRequest();
  }, []);
  const handleAddProduct = async (productId) => {
    if (source === 'interventionDetails') {
      setLoading(true);
      const projectInterventionReponse = await getByProjectAndInterventionId(projectId, interventionId);
      const currentPiValues = projectInterventionReponse?.data?.body?.data?.values || {};
      const existingProductKeyRemoved = {};
      Object.keys(currentPiValues || {}).map((key) => {
        if (key !== `${featureKey}__pi_selectedProductIdentifier`) {
          existingProductKeyRemoved[key] = currentPiValues[key];
        }
      });
      const updatePiValues = { ...existingProductKeyRemoved, [`${featureKey}__pi_selectedProductIdentifier`]: productId };
      const updateResp = await updateInterventionSet({ projectId, interventionId, values: updatePiValues });
      if (updateResp.success) {
        setProductAddToIntervention(productId);
        setLoading(false);
      }
    }
    if (source === 'marketplace') {
      setShowAddToInterventionModal(true);
    }
    if (source === 'shortlist') {
      setLoading(true);
      const updateShortlistResponse = await addShortlistedProduct({ projectId: projectId, interventionId: interventionId, shortlistedProduct: [productId] });
      if (updateShortlistResponse.success) {
        setLoading(false);
        swal({ icon: 'success', title: 'Successfully added the product to the Intervention' });
      }
    }
  };
  const ClickableLink = (data) => {
    if (data.includes('http') || data.includes('www'))
      return (
        <a href={data} rel="noreferrer" target="_blank" style={{ color: '#33518E' }}>
          <Typography variant="h6">Link</Typography>
        </a>
      );
    else return data;
  };
  const DataTab = ({ dataKey, innerKey, isNested, nestedKey }) => {
    return (
      <Box display="flex">
        <Box textAlign="left" display="flex" alignItems="center" justifyContent="left" className={classes.valueKeyBox}>
          <Typography className={classes.valueKey}>{innerKey}</Typography>
        </Box>
        {products &&
          products.map((product, index) => {
            return (
              <Box
                textAlign="left"
                display="flex"
                alignItems="center"
                justifyContent="left"
                className={classes.valueKeyBox}
                style={{ backgroundColor: index % 2 === 0 ? '#F5F5F5' : '#FBFBFB' }}
              >
                {dataKey !== 'certifications' && dataKey !== 'references' && !isNested && (
                  <Typography className={classes.value}>{formatValue(product?.attributes[dataKey][innerKey])}</Typography>
                )}
                {(dataKey === 'certifications' || dataKey === 'references') && !isNested && (
                  <Typography className={classes.value}>{ClickableLink(formatValue(convertStringToObject(product?.attributes[dataKey])[innerKey]))}</Typography>
                )}
                {dataKey !== 'certifications' && dataKey !== 'references' && isNested && (
                  <Typography className={classes.value}>{formatValue(getNestedValue(product?.attributes, nestedKey, innerKey, dataKey))}</Typography>
                )}
              </Box>
            );
          })}
      </Box>
    );
  };
  const DataComponent = ({ dataKey, data, isNested }) => {
    return (
      <Box>
        <Box display="flex">
          {data && (
            <Box>
              {data &&
                data.map(({ label, key }) => {
                  return <DataTab dataKey={dataKey} innerKey={label} isNested={isNested} nestedKey={key} />;
                })}
            </Box>
          )}
          {dataKey && (
            <Box>
              {getAllKeys(products, dataKey).map((innerKey) => {
                return <DataTab dataKey={dataKey} innerKey={innerKey} />;
              })}
            </Box>
          )}
        </Box>
      </Box>
    );
  };
  const DataComponentEnvironMentalImpact = ({ heading, dataKey, isNested }) => {
    return (
      <Box>
        <Box style={{ height: '30px', marginBottom: '3%', marginTop: '3%' }} display="flex" alignItems="center">
          {dataKey === 'environmental_impact_climate_health' && <img src={climate} alt={'...'} />}
          {dataKey === 'environmental_impact_human_health' && <img src={human} alt={'...'} />}
          {dataKey === 'environmental_impact_ecosystem_health' && <img src={ecosystem} alt={'...'} />}
          {dataKey === 'environmental_impact_circularity' && <img src={circularity} alt={'...'} />}
          <Typography className={classes.productDataSubHeader}>{heading}</Typography>
        </Box>
        <Box display="flex">
          {!isNested && (
            <Box>
              {getAllKeys(products, dataKey).length > 0 &&
                getAllKeys(products, dataKey).map((innerKey) => {
                  return <DataTab dataKey={dataKey} innerKey={innerKey} />;
                })}
            </Box>
          )}
          {isNested && (
            <Box>
              {getAllKeys(products, dataKey).length > 0 &&
                getAllKeys(products, dataKey)?.map((subKey) => {
                  return (
                    <>
                      <Typography style={{ marginTop: '1%', marginBottom: '1%' }} className={classes.productDataSubHeader}>
                        {subKey}
                      </Typography>
                      <Box display="flex">
                        <Box>
                          {getAllNestedKeys(products, dataKey, subKey).length > 0 &&
                            getAllNestedKeys(products, dataKey, subKey).map((innerKey) => {
                              return <DataTab dataKey={dataKey} innerKey={innerKey} isNested={isNested} nestedKey={subKey} />;
                            })}
                        </Box>
                      </Box>
                    </>
                  );
                })}
            </Box>
          )}
        </Box>
      </Box>
    );
  };
  const ProductInfoCompared = () => {
    return (
      <Box>
        {getAllKeys(products, 'environmental_impact_circularity').length > 0 &&
          getAllKeys(products, 'environmental_impact_climate_health').length > 0 &&
          getAllKeys(products, 'environmental_impact_ecosystem_health').length > 0 &&
          getAllKeys(products, 'environmental_impact_human_health').length > 0 && (
            <Accordion className={classes.MuiAccordionroot} style={{ border: 'none', boxShadow: 'none', padding: '0px' }} expanded={envioronMentalAccordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.expand_icon_top} />}
                aria-controls="panel1-content"
                id="panel1-header"
                IconButtonProps={{
                  onClick: () => {
                    setEnvioronMentalAccordion(!envioronMentalAccordion);
                  },
                  style: {
                    padding: '8px',
                  },
                }}
                style={{ alignItems: 'center', width: 'fit-content', padding: '0px' }}
              >
                <Typography className={classes.productDataHeader}>Environmental Impact</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'flex', flexDirection: 'column', padding: '0px' }}>
                <Box>
                  {getAllKeys(products, 'environmental_impact_circularity').length > 0 && (
                    <DataComponentEnvironMentalImpact
                      heading={'Circularity'}
                      dataKey={'environmental_impact_circularity'}
                      isNested={products[0]?.attributes?.metadata?.environmental_impact_circularity_is_nested}
                    />
                  )}
                  {getAllKeys(products, 'environmental_impact_climate_health').length > 0 && (
                    <DataComponentEnvironMentalImpact heading={'Climate Health'} dataKey={'environmental_impact_climate_health'} />
                  )}
                  {getAllKeys(products, 'environmental_impact_ecosystem_health').length > 0 && (
                    <DataComponentEnvironMentalImpact heading={'Ecosystem Health'} dataKey={'environmental_impact_ecosystem_health'} />
                  )}
                  {getAllKeys(products, 'environmental_impact_human_health').length > 0 && (
                    <DataComponentEnvironMentalImpact heading={'Human Health'} dataKey={'environmental_impact_human_health'} />
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          )}
        {getAllKeys(products, 'certifications').length > 0 && (
          <Accordion className={classes.MuiAccordionroot} style={{ border: 'none', boxShadow: 'none', padding: '0px' }} expanded={certificationsAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expand_icon_top} />}
              aria-controls="panel1-content"
              id="panel1-header"
              IconButtonProps={{
                onClick: () => {
                  setCertificationsAccordion(!certificationsAccordion);
                },
                style: {
                  padding: '8px',
                },
              }}
              style={{ alignItems: 'center', padding: '0px', width: 'fit-content' }}
            >
              <Typography className={classes.productDataHeader}>Certifications and Standards</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'flex', flexDirection: 'column', padding: '0px' }}>
              <DataComponent dataKey={'certifications'} />
            </AccordionDetails>
          </Accordion>
        )}
        {getAllKeys(products, 'properties').length > 0 && (
          <Accordion className={classes.MuiAccordionroot} style={{ border: 'none', boxShadow: 'none', padding: '0px' }} expanded={propertiesAccordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={classes.expand_icon_top} />}
              aria-controls="panel1-content"
              id="panel1-header"
              IconButtonProps={{
                onClick: () => {
                  setPropertiesAccordion(!propertiesAccordion);
                },
                style: {
                  padding: '8px',
                },
              }}
              style={{ alignItems: 'center', padding: '0px', width: 'fit-content' }}
            >
              <Typography className={classes.productDataHeader}>Properties</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'flex', flexDirection: 'column', padding: '0px' }}>
              <DataComponent dataKey={'properties'} />
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion className={classes.MuiAccordionroot} style={{ border: 'none', boxShadow: 'none', padding: '0px' }} expanded={generalAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.expand_icon_top} />}
            aria-controls="panel1-content"
            id="panel1-header"
            IconButtonProps={{
              onClick: () => {
                setGeneralAccordion(!generalAccordion);
              },
              style: {
                padding: '8px',
              },
            }}
            style={{ alignItems: 'center', padding: '0px', width: 'fit-content' }}
          >
            <Typography className={classes.productDataHeader}>General</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'flex', flexDirection: 'column', padding: '0px' }}>
            <Box>
              <Typography style={{ marginLeft: '0', marginBottom: '2%', marginTop: '2%' }} className={classes.productDataSubHeader}>
                Procurement Information
              </Typography>
              <DataComponent data={procurementInformationKeysLabels} isNested={true} />
            </Box>
            {getAllKeys(products, 'references').length > 0 && (
              <Box>
                <Typography style={{ marginLeft: '0', marginBottom: '2%', marginTop: '2%' }} className={classes.productDataSubHeader}>
                  References
                </Typography>
                <DataComponent dataKey={'references'} />
              </Box>
            )}
            {products
              .map((product) => {
                return product.attributes.sdg;
              })
              .filter((sdg) => sdg).length > 0 && (
              <Box>
                <Typography style={{ marginLeft: '0', marginBottom: '2%', marginTop: '2%' }} className={classes.productDataSubHeader}>
                  Sustainable Development Goal
                </Typography>
                <DataComponent data={sdgKeysLabels} isNested={true} />
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  return (
    <Box style={{ backgroundColor: 'white', height: '100%' }}>
      {loading && <BackdropLoader open={loading} />}
      {!loading && (
        <Box style={{ overflow: 'auto', width: '100%', backgroundColor: 'white', marginTop: '5%' }}>
          <Box style={{ marginLeft: '5%', marginBottom: '2%' }}>
            {source === 'interventionDetails' && (
              <CustomBreadCrumbs
                links={[
                  { href: `/projects/${projectId}/interventions`, text: 'Interventions' },
                  { href: `/projects/${projectId}/interventions/${interventionId}`, text: MetafireInterventions[featureKey] },
                ]}
                stage={<b>Comparison</b>}
              />
            )}
          </Box>

          <Container maxWidth="xl">
            <Grid style={{ paddingRight: '5%', paddingLeft: '5%' }} display="flex" container alignItems="flex-start" justifyContent="flex-start">
              <HeaderAndBackIcon
                heading={'Compare Products'}
                arrowColor={source === 'interventionDetails' ? '#26E0A3' : MarketplaceColors.Secondary}
                variant={!isMobile ? 'h4' : null}
                style={MarketplaceTypography.PageHeading}
                iconHeight={isMobile ? '18px' : null}
                iconWidth={isMobile ? '20px' : null}
                bold={true}
                handleClick={() => history.goBack()}
                hideArrowBack={true}
              />
              {source !== 'interventionDetails' && (
                <Grid style={{ marginTop: '10px' }} container xs={12} className={classes.bredCrumb}>
                  <CustomBreadCrumbs links={links} stage={<b>{'Compare'}</b>} />
                </Grid>
              )}
              <Box display="flex" flexDirection="column">
                <Box display="flex" style={{ marginLeft: '200px' }}>
                  {products.map((product, index) => (
                    <>
                      <ProductCardComparisonPage
                        selectedProduct={selectedProduct}
                        productAddedToIntervention={productAddedToIntervention}
                        source={source}
                        handleAddProduct={handleAddProduct}
                        products={products}
                        product={product}
                        index={index}
                      />
                      <ProductDetailsAddToInterventions
                        showAddToIntervention={showAddToInterventionModal}
                        handleShowModalForAddToIntervention={() => setShowAddToInterventionModal(!showAddToInterventionModal)}
                        productDetailsData={product && product}
                      />
                    </>
                  ))}
                </Box>
                <Box style={{ marginTop: '8%' }}>
                  <ProductInfoCompared />
                </Box>
              </Box>
            </Grid>
          </Container>
        </Box>
      )}
    </Box>
  );
}

export default ProductComparison;
