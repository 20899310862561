import { api } from './http';

const getFileUploadUrl = async (payload) => {
  const res = await api.post('/v1/project-asset', payload);
  return res.data?.data?.data;
};

const deleteFile = async (orgnaisationId, projectId, customInterventionId, fileName) => {
  const res = await api.delete(
    `/v1/s3Services/deleteFile?orgnaisationId=${orgnaisationId}&projectId=${projectId}&customInterventionId=${customInterventionId}&fileName=${fileName}`
  );
  return res;
};

const getOpenFileUrl = async (orgnaisationId, projectId, customInterventionId, fileName) => {
  const res = await api.get(
    `/v1/s3Services/open-file?orgnaisationId=${orgnaisationId}&projectId=${projectId}&customInterventionId=${customInterventionId}&fileName=${fileName}`
  );
  return res.data?.data?.data;
};

const deleteProjectAsset = async (assetId) => {
  const res = await api.delete(`/v1/project-asset/${assetId}`);
  return res.data;
};

export { deleteProjectAsset, getFileUploadUrl, deleteFile, getOpenFileUrl };
