// React
import React from 'react';

// Material UI components
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip, Typography } from '@material-ui/core';

// Material UI icons
import { Edit as EditIcon } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';

// Styles
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  actionCell: {
    width: 100,
  },
  table: {
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    width: '100%',
    borderCollapse: 'collapse',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    paddingLeft: '1%',
  },
  tableHeader: {
    textAlign: 'left',
    color: '#000000',
    fontSize: '16px',
    fontWeight: 'bold',
    padding: '12px 8px',
  },
  tableRow: {
    borderBottom: 'none',
    height: '56px',
  },
  tableCell: {
    textAlign: 'left',
    color: '#7a7a7a',
    fontSize: '14px',
    padding: '12px 8px 8px 12px',
  },
}));

const isSuperUser = (user) => user.role === 'super';

const UsersManagementTable = ({ users = [], onEditUser, searchValue = '', onDeleteUser }) => {
  const classes = useStyles();

  // Filter function for search
  const searchLower = searchValue.toLowerCase();

  const filteredUsers = users.filter(({ name = '', email = '' }) => name?.toLowerCase().includes(searchLower) || email?.toLowerCase().includes(searchLower));

  // Table headers configuration
  const headers = [
    { id: 'name', label: 'Name' },
    { id: 'email', label: 'Email' },
    { id: 'role', label: 'Role' },
    { id: 'action', label: 'Actions' },
  ];

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table>
        <TableHead className={classes.tableHeader}>
          <TableRow className={classes.tableRow}>
            {headers.map((header) => (
              <TableCell
                key={header.id}
                className={classes.tableCell}
                children={
                  <Typography variant="h6" style={{ color: 'black' }}>
                    <b>{header.label}</b>
                  </Typography>
                }
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredUsers.map((user) => (
            <TableRow key={user._id} className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <Typography variant="h6">{user.name}</Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="h6">{user.email}</Typography>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Typography variant="h6">{user.role}</Typography>
              </TableCell>

              <TableCell className={classes.actionCell}>
                <Tooltip title="Edit User">
                  <IconButton
                    disabled={isSuperUser(user)}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      onEditUser(user);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete User">
                  <IconButton
                    style={{ marginLeft: '20px' }}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteUser(user);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersManagementTable;
