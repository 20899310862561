import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import Fields from '../../../../common/textfield/Fields';

function LocationBox(props) {
  const {
    field,
    type,
    index,
    options,
    loading,
    open,
    onOpen,
    onClose,
    onChange,
    handleSearch,
    onInputChange,
    getOptionLabel,
    locationLabel,
    isOptionEqualToValue,
    setNewloc,
    defaultValue,
  } = props;
  return (
    <>
      <Grid container direction="row" item xs={12} key={index} style={index === 0 ? { height: 'Auto', marginTop: '2%' } : { height: 'Auto' }}>
        <Grid alignItems="left" style={{ marginBottom: '1%', marginLeft: '5px' }} item xs={12}>
          <Typography variant="h5">{defaultValue.fieldLabel + `${type && type === 'basic' ? '*' : ''}`}</Typography>
        </Grid>
        {field.description && field.description !== 'NA' ? (
          <Grid alignItems="center" item xs={12}>
            <Typography variant="h6" style={{ lineHeight: '22px', letterSpacing: '0.571429px', color: 'rgba(0, 0, 0, 0.38)' }}>
              {field?.description}
            </Typography>
          </Grid>
        ) : null}
        <Grid style={{ marginRight: '1%' }} item xs={2}>
          <Autocomplete
            id="combo-box-demo"
            options={options}
            getOptionLabel={(option) => option.description || defaultValue.label || locationLabel}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            loading={loading}
            defaultValue={defaultValue}
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            onChange={(event, value) => {
              setNewloc(value);
            }}
            onInputChange={onInputChange}
            renderInput={(params) => (
              <Fields
                placeholder="Location"
                {...params}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {
                        <SearchIcon
                          onClick={handleSearch}
                          style={{
                            color: '#66E3BE',
                            marginRight: '-58%',
                            fontSize: '25px',
                            cursor: 'pointer',
                          }}
                        />
                      }
                    </React.Fragment>
                  ),
                }}
                fullWidth
                required
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default LocationBox;
