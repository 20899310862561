import React, { useContext, useEffect, useRef, useState } from 'react';
import { Drawer, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { ResidentHealthAndWellBeingContext } from '../..';
import PrimaryButton from '../../../../../../common/button/Button';
import CustomDropDown from '../../common/CustomDropDown';
import Fields from '../../../../../../common/textfield/Fields';
import { Sections, DwellingUnits, UnitMap } from '../../DataTypes';
import { GetUnit } from '../../utils';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const useStyles = makeStyles((theme) => ({
  main: {
    paddingBottom: '20%',
    paddingTop: '14%',
  },
  actions: {
    position: 'fixed',
    bottom: 0,
    width: '500px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  form: {
    paddingLeft: '3%',
    paddingRight: '3%',
    paddingBottom: '3%',
  },
}));

const DwellingUnitTypesHandler = ({ handleClose, editTable, editableData, setUpdate, update, indexforEdit, mount, setMount }) => {
  const classes = useStyles();
  const { data, handleData } = useContext(ResidentHealthAndWellBeingContext);
  const [dwellingUnitType, setDwellingUnitType] = useState('');
  const [name, setName] = useState('');
  const [floorArea, setFloorArea] = useState('');
  const [dayLightingArea, setDayLightingArea] = useState();
  const [connectivityToExteriorsArea, setConnectivityToExteriorsArea] = useState();
  const [crossVentilationArea, setCrossVentilationArea] = useState();
  const [ventilationCredit, setVentilationCredit] = useState(false);
  const [ventilationMandate, setVentilationMandate] = useState(false);

  useEffect(() => {
    if (editTable === true && editableData) {
      const { data, type } = editableData;
      const { name, floorarea, dayLightingArea, connectivityToExteriorsArea, crossVentilationArea, ventilationCredit, ventilationMandate } = data;
      setDwellingUnitType(type);
      setName(name);
      setFloorArea(parseInt(floorarea));
      setDayLightingArea(dayLightingArea);
      setConnectivityToExteriorsArea(connectivityToExteriorsArea);
      setCrossVentilationArea(crossVentilationArea);
      setVentilationCredit(ventilationCredit);
      setVentilationMandate(ventilationMandate);
    }
  }, [editTable, editableData]);

  const handleSaveData = (data, dwellingUnitType, name, floorArea) => {
    const obj = {};
    obj['name'] = name;
    obj['floorarea'] = floorArea;
    obj['dayLightingArea'] = dayLightingArea;
    obj['connectivityToExteriorsArea'] = connectivityToExteriorsArea;
    obj['crossVentilationArea'] = crossVentilationArea;
    obj['ventilationCredit'] = ventilationCredit;
    obj['ventilationMandate'] = ventilationMandate;
    const obj2 = {};
    obj2['type'] = dwellingUnitType;
    let arr = [];
    arr.push(obj);
    obj2['data'] = arr;
    const findData = data?.customData[0]?.data?.find((d) => d.section === Sections.Dwelling_Unit_Types)?.data?.find((f) => f.type === dwellingUnitType);
    if (findData) {
      data?.customData[0]?.data
        ?.find((d) => d.section === Sections.Dwelling_Unit_Types)
        ?.data?.find((f) => f.type === dwellingUnitType)
        ?.data?.push(obj);
    } else if (dwellingUnitType && name) data?.customData[0]?.data?.find((d) => d.section === Sections.Dwelling_Unit_Types)?.data.push(obj2);
    // currently the drawer is closed but show user an error
    else handleClose();
    let newData = data;
    handleData(newData);
    handleClose();
    setUpdate(!update);
    setMount(!mount);
  };

  // update data handler
  const handleUpdateData = (data, dwellingUnitType, name, floorArea, indexforEdit) => {
    const obj = {};
    obj['name'] = name;
    obj['floorarea'] = floorArea;
    obj['dayLightingArea'] = dayLightingArea;
    obj['connectivityToExteriorsArea'] = connectivityToExteriorsArea;
    obj['crossVentilationArea'] = crossVentilationArea;
    obj['ventilationCredit'] = ventilationCredit;
    obj['ventilationMandate'] = ventilationMandate;
    data?.customData[0]?.data
      ?.find((d) => d.section === Sections.Dwelling_Unit_Types)
      ?.data?.find((f) => f.type === dwellingUnitType)
      ['data'].splice(indexforEdit, 1, obj);
    handleData(data);
    handleClose();
    setUpdate(!update);
    setMount(!mount);
  };

  return (
    <Grid container className={classes.main}>
      <Grid className={classes.form}>
        <Grid container alignContent="flex-start">
          <Grid item xs={12} container alignItems="center">
            <Grid>
              <CloseIcon fontSize="large" style={{ cursor: 'pointer' }} onClick={handleClose} />
            </Grid>
            <Grid style={{ paddingLeft: '4%' }}>
              <Typography variant="h5">{!editTable ? <b>Add Dwelling Unit Type</b> : <b>Edit Dwelling Unit Type</b>}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container alignItems="center" style={{ marginBottom: '5%' }}>
            <Grid xs={12} container style={{ padding: '2%' }}>
              <Grid xs={12}>
                <Typography variant="h6">Dwelling Unit</Typography>
              </Grid>
              <Grid xs={5} style={{ marginTop: '4%' }}>
                <CustomDropDown
                  dataArr={DwellingUnits}
                  selecteddata={dwellingUnitType}
                  setSelectedData={(e) => setDwellingUnitType(e)}
                  disabled={!editTable ? false : true}
                />
              </Grid>
            </Grid>
            <Grid xs={6} style={{ padding: '2%' }}>
              <Grid xs={12}>
                <Typography variant="h6">Name</Typography>
              </Grid>
              <Grid xs={10} style={{ marginTop: '4%' }}>
                <Fields id="outlined-basic" variant="outlined" fullWidth required value={name} onChange={(e) => setName(e.target.value)} />
              </Grid>
              <Grid xs={10} style={{ textAlign: 'center' }}>
                <Typography style={{ fontSize: '12px' }}>Max 20 characters</Typography>
              </Grid>
            </Grid>
            <Grid xs={6}>
              <Grid xs={12}>
                <Typography variant="h6">Floorarea(sqm)</Typography>
              </Grid>
              <Grid xs={12} container style={{ marginTop: '4%', paddingBottom: '10%' }} alignItems="center">
                <Grid xs={9}>
                  <Fields id="outlined-basic" variant="outlined" fullWidth required value={floorArea} onChange={(e) => setFloorArea(e.target.value)} />
                </Grid>
                <Grid xs={3} style={{ paddingLeft: '2%' }}>
                  <Typography variant="h6">{GetUnit(UnitMap, 'floorarea')}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={6}>
              <Grid xs={12}>
                <Typography variant="h6" style={{ marginLeft: '15px' }}>
                  Daylighting Area
                </Typography>
              </Grid>
              <Grid xs={12} container style={{ paddingLeft: '4%', marginTop: '4%', paddingBottom: '10%' }} alignItems="center">
                <Grid xs={9}>
                  <Fields
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    required
                    value={dayLightingArea}
                    onChange={(e) => setDayLightingArea(e.target.value)}
                  />
                </Grid>
                <Grid xs={3} style={{ paddingLeft: '2%' }}>
                  <Typography variant="h6">{GetUnit(UnitMap, 'floorarea')}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={6}>
              <Grid xs={12}>
                <Typography variant="h6" style={{ marginLeft: '10px' }}>
                  Cross Ventilation Area
                </Typography>
              </Grid>
              <Grid xs={12} container style={{ paddingLeft: '4%', marginTop: '4%', paddingBottom: '10%' }} alignItems="center">
                <Grid xs={9}>
                  <Fields
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    required
                    value={crossVentilationArea}
                    onChange={(e) => setCrossVentilationArea(e.target.value)}
                  />
                </Grid>
                <Grid xs={3} style={{ paddingLeft: '2%' }}>
                  <Typography variant="h6">{GetUnit(UnitMap, 'floorarea')}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={6}>
              <Grid xs={12}>
                <Typography variant="h6" style={{ marginLeft: '15px' }}>
                  Connectivity To Exteriors Area
                </Typography>
              </Grid>
              <Grid xs={12} container style={{ paddingLeft: '4%', marginTop: '4%' }} alignItems="center">
                <Grid xs={9}>
                  <Fields
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    required
                    value={connectivityToExteriorsArea}
                    onChange={(e) => setConnectivityToExteriorsArea(e.target.value)}
                  />
                </Grid>
                <Grid xs={3} style={{ paddingLeft: '2%' }}>
                  <Typography variant="h6">{GetUnit(UnitMap, 'floorarea')}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} style={{ paddingBottom: '5%' }}>
            <Grid item xs>
              {ventilationCredit ? (
                <CheckBoxIcon style={{ color: '#66E3BE', cursor: 'pointer' }} onClick={() => setVentilationCredit(false)} />
              ) : (
                <CheckBoxOutlineBlankIcon style={{ color: '#66E3BE', cursor: 'pointer' }} onClick={() => setVentilationCredit(true)} />
              )}
            </Grid>
            <Grid item xs={11}>
              <Typography>Ventilation Credit</Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs>
              {ventilationMandate ? (
                <CheckBoxIcon style={{ color: '#66E3BE', cursor: 'pointer' }} onClick={() => setVentilationMandate(false)} />
              ) : (
                <CheckBoxOutlineBlankIcon style={{ color: '#66E3BE', cursor: 'pointer' }} onClick={() => setVentilationMandate(true)} />
              )}
            </Grid>
            <Grid item xs={11}>
              <Typography>Ventilation Mandate</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <Paper className={classes.actions}>
          <Grid item xs={12} container direction="row" alignItems="center" justifyContent="flex-end">
            <Grid item xs={2}>
              <Typography variant="h6" onClick={handleClose} style={{ cursor: 'pointer', color: '#49DDB1' }}>
                Cancel
              </Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={4}>
              {!editTable && <PrimaryButton children="Save" onClick={() => handleSaveData(data, dwellingUnitType, name, floorArea)} />}
              {editTable && <PrimaryButton children="Update" onClick={() => handleUpdateData(data, dwellingUnitType, name, floorArea, indexforEdit)} />}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DwellingUnitTypesHandler;
