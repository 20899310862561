/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

// Material-UI Components
import { Modal, Box, Typography, Grid, CircularProgress } from '@material-ui/core';

// Common Components
import PrimaryButton from '../../../../../common/button/Button';
import { FormField } from '../../organisation/form/form.components';

// Hooks
import { useAppApiRequest } from '../../../../../../hooks/useAppApiRequest';

// API Endpoints
import api from '../../../../../../http';

// Styles
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    width: '50%',
    maxWidth: '90%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  actions: {
    marginTop: theme.spacing(5),
  },
}));

const roles = ['admin', 'normal'];

const UserFormModal = ({ open, onClose, initialData = null, selectedUser, setSelectedUser, refetchUsers, organisationID }) => {
  const classes = useStyles();

  const [formData, setFormData] = useState({
    email: '',
    name: '',
    password: '',
    role: '',
  });

  const [errors, setErrors] = useState({});

  const [createUser, setCreateUser] = useState(false);
  const [updateUser, setUpdateUser] = useState(false);
  const [availableRoles, setRoles] = useState([]);

  const { data: rolesData } = useAppApiRequest(() => api.getRoles(), {}, [], true);
  const { data, isFetching, refetch } = useAppApiRequest(
    () =>
      api.postOrganisationUser({
        email: formData.email,
        name: formData.name,
        password: formData.password,
        organisationId: organisationID,
        roleId: availableRoles.find((role) => role?.name === formData?.role)?._id,
      }),
    {
      method: 'POST',
    },
    [],
    createUser
  );

  const {
    data: editedUserDetails,
    isFetching: processingEditUserDetails,
    refetch: editUserDetails,
  } = useAppApiRequest(
    () =>
      api.putOrganisationUser(selectedUser?._id, {
        email: formData.email,
        name: formData.name,
        password: formData.password,
        organisationId: organisationID,
        roleId: availableRoles.find((role) => role?.name === formData?.role)?._id,
      }),
    {
      method: 'PUT',
    },
    [],
    updateUser
  );

  useEffect(() => {
    if (rolesData) setRoles(rolesData);
  }, [rolesData]);

  useEffect(() => {
    if (data) {
      setSelectedUser(null);
      onClose();
      setCreateUser(false);
      refetchUsers();
      setErrors({});
      setFormData({
        email: '',
        name: '',
        password: '',
        role: '',
      });
    }
  }, [data]);

  useEffect(() => {
    if (editedUserDetails) {
      setSelectedUser(null);
      onClose();
      setUpdateUser(false);
      refetchUsers();
      setErrors({});
      setFormData({
        email: '',
        name: '',
        password: '',
        role: '',
      });
    }
  }, [editedUserDetails]);

  useEffect(() => {
    if (initialData) {
      setFormData(initialData);
    } else {
      setFormData({
        email: '',
        name: '',
        password: '',
        role: '',
      });
    }
    setErrors({});
  }, [initialData]);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }

    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }

    if (!selectedUser && !formData.password.trim()) {
      newErrors.password = 'Password is required';
    }

    if (formData.password && formData.password.trim().length < 6) {
      newErrors.password = 'Password must be at least 6 characters long';
    }

    if (!formData.role.trim()) {
      newErrors.role = 'Role is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) selectedUser ? handleEdit() : handleCreateNew();
  };

  const handleCreateNew = async () => {
    setCreateUser(true);
    refetch();
  };

  const handleEdit = () => {
    setUpdateUser(true);
    editUserDetails();
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
        setSelectedUser(null);
        setErrors({});
        setFormData({
          email: '',
          name: '',
          password: '',
          role: '',
        });
      }}
      className={classes.modal}
      aria-labelledby="user-modal-title"
    >
      <div className={classes.paper}>
        <Typography variant="h5" id="user-modal-title" gutterBottom>
          {initialData ? 'Update User' : 'New User'}
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormField name="email" label="Email" value={formData.email} onChange={handleChange} error={errors.email} required />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField name="name" label="Name" value={formData.name} onChange={handleChange} error={errors.name} required />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormField
                name="password"
                label="Password"
                type="password"
                value={formData.password}
                onChange={handleChange}
                error={errors.password}
                required={!selectedUser}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormField name="role" label="Role" select options={roles} value={formData.role} onChange={handleChange} error={errors.role} required />
            </Grid>
          </Grid>

          <Grid item container justifyContent="space-between" className={classes.actions}>
            <Grid item xs={7} container justifyContent="flex-end" alignItems="center">
              <Typography
                onClick={() => {
                  onClose();
                  setErrors({});
                  setSelectedUser(null);
                  setFormData({
                    email: '',
                    name: '',
                    password: '',
                    role: '',
                  });
                }}
                color="primary"
                style={{ cursor: 'pointer' }}
              >
                Cancel
              </Typography>
            </Grid>
            <Grid item xs={4} container justifyContent="flex-end" alignItems="center">
              <PrimaryButton
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isFetching || processingEditUserDetails}
                style={{ cursor: 'pointer' }}
              >
                <Box display="flex" alignItems="center" gap={1}>
                  {initialData ? 'Update' : 'Create'}
                  {(initialData ? processingEditUserDetails : isFetching) && <CircularProgress size={20} color="inherit" style={{ marginLeft: '3%' }} />}
                </Box>
              </PrimaryButton>
            </Grid>
          </Grid>
        </form>
      </div>
    </Modal>
  );
};

export default UserFormModal;
