import React, { useEffect, useState } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import SimplePIPS from '../../common/components/SimplePIPS';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import useStyles from './styles';

const ConcreteMaterialDetails = ({ data, appendChanged, projectPIP, pipValues }) => {
  const classes = useStyles();
  const [constructionType, setConstructionType] = useState('');
  useEffect(() => {
    const matchingPip = data?.find((item) => item.name === 'project__pi_typeofConstruction');
    setConstructionType(pipValues[matchingPip?._id]);
  }, [data]);
  const renderDropdown = (pip) => (
    <Grid alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '1%' }}>
      <Grid item xs={12}>
        <Typography style={{ fontSize: '16px' }}>{pip.displayName}</Typography>
      </Grid>
      <Grid container item xs={12} alignItems="center" style={{ marginTop: '2%' }}>
        <Grid item>
          <FormControl variant="outlined" style={{ width: '166px', borderRadius: `50px` }} className={classes.dropdown}>
            <InputLabel id="demo-simple-select-label">Select</InputLabel>
            <Select
              disabled={false}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label={pip.displayName}
              placeholder={pip.displayName}
              className={classes.select}
              value={pipValues[pip._id] || ''}
            >
              {pip.options.map((value) => (
                <MenuItem onClick={() => appendChanged(pip._id, Number(value))} value={value} name={value} style={{ paddingTop: '2%' }}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item style={{ paddingLeft: '10px' }}>
          <Typography>{pip.unit}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderRadioOption = (pip, value, label) => (
    <Typography className={classes.textHeader3}>
      {pipValues[pip._id] === value ? (
        <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => appendChanged(pip._id, value)} />
      ) : (
        <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => appendChanged(pip._id, value)} />
      )}
      {label}
    </Typography>
  );

  const renderConstructionType = (pip) => (
    <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '1%' }}>
      <Grid item xs={12}>
        <Typography style={{ fontSize: '16px' }}>Type of Construction</Typography>
      </Grid>
      <Grid item xs={6} style={{ marginLeft: '2%', marginBottom: '1%', marginTop: '1%' }}>
        {renderRadioOption(pip, 'RCC frame structure', 'RCC frame structure')}
        {renderRadioOption(pip, 'Aluminium form work', 'Aluminium form work')}
      </Grid>
    </Grid>
  );

  const renderPipField = (pip, index) => {
    if (pip.type === 'number') {
      if (pip.options.length !== 0) {
        return renderDropdown(pip);
      }
      return (
        <SimplePIPS
          key={index}
          index={index}
          unit="Sqm"
          field={pip}
          disabled={constructionType === 'RCC frame structure'}
          projectPIP={projectPIP}
          appendChanged={(fieldId, value) => appendChanged(fieldId, value)}
          type="material"
          displayNameStyle={{ fontSize: '16px', marginTop: '1%' }}
        />
      );
    }
    return renderConstructionType(pip);
  };

  return (
    <>
      <Grid container direction="row" xs={12} style={{ height: 'Auto' }}>
        <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '4%' }}>
          <Typography variant="h5">
            <b>Concrete</b>
          </Typography>
        </Grid>
      </Grid>
      {data && data.map((pip, index) => renderPipField(pip, index))}
    </>
  );
};

export default ConcreteMaterialDetails;
