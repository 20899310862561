import React from 'react';
import { FormControl, Select, MenuItem, Checkbox, ListItemText, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    '& > *': {
      borderRadius: '50px',
      height: '7vh',
    },
  },
  icon: {
    marginTop: '3%',
  },
}));

const CustomDropdown = ({ value = [], onChange, options, disabled, placeholder, multiple = false }) => {
  const classes = useStyles();

  const handleChange = (event) => onChange(event);

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        style={{
          border: '1px solid #666666',
          width: 160,
          height: 50,
        }}
        classes={{ icon: classes.icon }}
        value={value}
        onChange={handleChange}
        fullWidth
        disabled={disabled}
        displayEmpty
        multiple={multiple}
        renderValue={(selected) => {
          if (placeholder) return placeholder;
          else return selected;
        }}
      >
        {options.map((option, index) => (
          <MenuItem value={option.hasOwnProperty('option') ? option.option : option} key={index} disabled={option.hasOwnProperty('enabled') && !option.enabled}>
            {multiple && (
              <Checkbox
                style={{ fontSize: '20px', color: 'rgba(102, 227, 190, 1)' }}
                checked={value.includes(option.hasOwnProperty('option') ? option.option : option)}
              />
            )}
            <ListItemText primary={option.hasOwnProperty('option') ? option.option : option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomDropdown;
