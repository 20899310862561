import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Grid, Typography } from '@material-ui/core';
import Bold from '../../../../../../v2/components/Bold';
import useStyles from '../styles';

export default function CustomProgressBar({ value, bgcolor, barvalue, unit, textColor }) {
  const classes = useStyles();
  const BorderLinearProgress = withStyles(() => ({
    root: {
      height: 20,
      background: 'rgba(229, 229, 229, 1)',
      borderRadius: '0px 100px 100px 0px',
    },
    bar: {
      borderRadius: '0px 100px 100px 0px',
      background: `${bgcolor}`,
    },
  }))(LinearProgress);

  return (
    <Grid container className={classes.progressBarRoot}>
      <>
        <Grid container item xs={12} className={classes.spacing}>
          <Grid item xs={7}>
            <BorderLinearProgress className={classes.margin} variant="determinate" value={`${barvalue}`} />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={4}>
            <Typography variant="h6" style={{ color: `${textColor}` }}>
              <Bold isHighlight={true} value={value} unit={unit} />
            </Typography>
          </Grid>
        </Grid>
      </>
    </Grid>
  );
}
