export const query = `query{categories(pagination:{limit:${100}}sort: "name"){data {
    id
    attributes {
      name
      subcategories {
        data {
          id
          attributes {
            name
          }
        }
      }
    }
  }}}`;
