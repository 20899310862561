import * as React from 'react';

function EnergyFlowDiagram(props) {
  const {
    mainGrid,
    residentiallighting,
    residentialhvac,
    residentialfan,
    residentialhotwater,
    homeappliances,
    commonarea,
    dieselgenerator,
    solarweaterheater,
    solarpv,
    biogas,
  } = props;

  return (
    <svg width={550} height={550} viewBox="0 0 1380 1384" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 20C0 8.954 8.954 0 20 0h1340c11.05 0 20 8.954 20 20v1344c0 11.05-8.95 20-20 20H20c-11.046 0-20-8.95-20-20V20Z" fill="#fff" />
      <path fill="url(#a)" fillOpacity={0.14} d="M1129 320.867h145.991v177.054H1129z" />
      <path fill="#F1AD18" d="M1275.27 320.867h10.859v177.054h-10.859zM1118.42 320.867h10.859v177.054h-10.859z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1197.95 363.876h8.25c.08-1.275.36-3.032 1.27-4.246 3.94-5.058 4.12-9.842 4.12-9.889 0-5.235-4.27-9.499-9.52-9.499-5.26 0-9.53 4.264-9.53 9.505 0 .041.19 4.828 4.1 9.861.93 1.228 1.23 2.993 1.31 4.268Zm9.74 2.971h-11.21a1.483 1.483 0 0 1-1.48-1.575c.05-1.042-.09-3.012-.73-3.859-4.65-5.989-4.71-11.611-4.71-11.667 0-6.88 5.61-12.477 12.51-12.477 6.89 0 12.5 5.597 12.5 12.477 0 .056-.05 5.68-4.73 11.683-.64.853-.72 2.95-.66 3.828a1.49 1.49 0 0 1-1.49 1.59ZM1207.9 370.158h-11.67a1.49 1.49 0 0 1-1.49-1.486c0-.82.67-1.486 1.49-1.486h11.67c.82 0 1.49.666 1.49 1.486s-.67 1.486-1.49 1.486ZM1206.49 373.42h-8.85a1.49 1.49 0 0 1-1.49-1.486c0-.821.67-1.486 1.49-1.486h8.85c.82 0 1.49.665 1.49 1.486 0 .82-.67 1.486-1.49 1.486Z"
        fill="#2E2E2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m1199.4 365.163-1.89-15.526 1.48-.178 1.89 15.526-1.48.178ZM1204.73 365.163l-1.47-.178 1.88-15.526 1.48.178-1.89 15.526Z"
        fill="#2E2E2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1204.71 353.365c-.45 0-.73-.264-.92-.535-.18.271-.46.535-.91.535-.46 0-.74-.26-.92-.529-.18.269-.46.529-.91.529s-.73-.258-.91-.526c-.18.268-.46.526-.91.526-.71 0-1-.645-1.14-.954-.03-.071-.08-.18-.1-.212.03.046.15.141.33.141v-1.486c.45 0 .73.257.91.526.18-.269.46-.526.91-.526s.73.258.91.527c.18-.269.46-.527.91-.527s.74.261.92.532c.18-.271.46-.532.91-.532.46 0 .74.263.92.535.18-.272.47-.535.92-.535v1.486c.17 0 .29-.094.33-.14-.03.033-.07.141-.11.213-.14.309-.42.952-1.14.952Z"
        fill="#2E2E2E"
      />
      <g transform="translate(50, 0)">
        <text
          fill="#000"
          fillOpacity={0.82}
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={20}
          letterSpacing={0.714}
          textAnchor="middle"
        >
          <tspan x={1149.61} y={417.107}>
            {'Residential'}
          </tspan>
        </text>
        <text
          fill="#000"
          fillOpacity={0.82}
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={20}
          letterSpacing={0.714}
          textAnchor="middle"
        >
          <tspan x={1149.61} y={446.107}>
            {'Lighting'}
          </tspan>
        </text>
        <text
          fill="#2E2E2E"
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={22}
          fontWeight={900}
          letterSpacing={0.571}
          textAnchor="middle"
        >
          <tspan x={1144.52} y={475.514}>
            {residentiallighting}
          </tspan>
        </text>
      </g>{' '}
      <path id="path1" d="M252.322 190.576h827.758" stroke="url(#b)" strokeWidth={48} />
      <path id="path2" d="M252.322 215.678c289.874 0 512.209 194.932 831.738 194.932" stroke="url(#c)" strokeWidth={31} />
      <path id="path3" d="M252.322 206.116c290.709 0 513.299 409.997 833.748 409.997" stroke="url(#d)" strokeWidth={26} />
      <path id="path4" d="M252.322 219.265c291.545 0 514.774 601.248 836.148 601.248" stroke="url(#e)" strokeWidth={22} />
      <path id="path5" d="M252.322 233.608c292.798 0 516.986 792.502 839.738 792.502" stroke="url(#f)" strokeWidth={16} />
      <path id="path6" d="M252.322 233.608c293.633 0 518.461 1005.272 842.138 1005.272" stroke="url(#g)" strokeWidth={10} />
      <path id="path7" d="M252.322 612.527c288.621 0 513.59-188.945 831.738-188.945" stroke="url(#h)" strokeWidth={8} />
      <path id="path8" d="M252.322 612.527c290.709 0 513.288 19.603 833.738 19.603" stroke="url(#i)" strokeWidth={6} />
      <path id="path9" d="M252.322 612.527c291.127 0 514.036 209.182 834.948 209.182" stroke="url(#j)" strokeWidth={6} />
      <path id="path10" d="M252.322 614.917c293.633 0 518.461 623.963 842.138 623.963" stroke="url(#k)" strokeWidth={6} />
      <path id="path11" d="M252.322 820.513c286.95 0 511.432-606.976 827.738-606.976" stroke="url(#l)" strokeWidth={10} />
      <path id="path12" d="M259.51 1027.3c291.127 0 514.036 211.58 834.95 211.58" stroke="url(#m)" strokeWidth={10} />
      <path id="path13" d="M248.729 1232.9c295.721 0 522.148 5.98 848.121 5.98" stroke="url(#n)" strokeWidth={10} />
      <path fill="#FFC60B" fillOpacity={0.14} d="M103.781 115.27h144.948v178.103H103.781z" />
      <path fill="#FFC60B" d="M93 115.271h10.781v178.103H93zM248.729 115.27h10.781v178.103h-10.781z" />
      <g transform="translate(50, 0)">
        <text
          fill="#2E2E2E"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={20}
          letterSpacing={0.714}
          textAnchor="middle"
        >
          {' '}
          <tspan x={129.367} y={234.141}>
            {'Main Grid'}
          </tspan>
        </text>
        <text
          fill="#2E2E2E"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={22}
          fontWeight={900}
          letterSpacing={0.571}
          textAnchor="middle"
        >
          <tspan x={123.912} y={262.857}>
            {mainGrid}
          </tspan>
        </text>
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m183.348 139.763-7.262-4.057-6.983 4.065v.724l-13.305 5.361.247 1.268h1.978v1.974h1.319v-1.974h9.761v6.445l-17.541 7.56.262 1.262h1.978v1.974h1.319v-1.974h13.982v9.23l-6.625 18.328 13.623-11.82 14.702 12.324-7.192-18.838v-9.224h13.982v1.974h1.319v-1.974h1.714l.262-1.262-17.277-7.461v-6.544h9.761v1.974h1.319v-1.974h1.714l.247-1.268-13.304-5.361v-.732Zm-12.503.517 5.255-3.058 5.473 3.058h-10.728Zm-1.742 1.634-9.664 3.894h9.664v-3.894Zm1.319.429v3.465h4.167l-4.167-3.465Zm0 7.106v-2.322h3.489l-3.489 2.322Zm0 2.335v1.922h3.556l-3.556-1.922Zm11.87 5.054v-1.813h-3.494l3.494 1.813Zm0-5.212v2.08h-4.009l4.009-2.08Zm0 6.773v2.677h-4.65l4.65-2.677Zm-3.399 3.996 3.399 1.696v-1.696h-3.399Zm4.718-1.32h13.831l-13.831-5.973v5.973Zm-7.505-5.963 4.869 2.526-4.875 2.807-4.584-2.744 4.59-2.589Zm-2.526-.087-3.158 1.781v-1.781h3.158Zm1.011 6.051-4.169-2.496v2.496h4.169Zm-19.577-.001h14.089v-6.073l-14.089 6.073Zm26.358-10.455-5.272-3.367-5.061 3.367 5.06 2.736 5.273-2.736Zm-10.95 11.775h2.968l-2.968 1.54v-1.54Zm10.426 2.447-4.749-2.369-4.562 2.367 4.54 2.454.01-.006.022.013 4.739-2.459Zm-6.085 3.239-4.341-2.347v4.946l4.341-2.599Zm2.701 0 4.828-2.505v5.284l-4.828-2.779Zm-12.025 17.555 4.645-12.852 5.296 4.227-9.941 8.625Zm22.324.549-10.937-9.167 5.817-4.242 5.12 13.409Zm-11.663-17.37 5.443 3.134-5.432 3.96-5.083-4.058 5.072-3.036Zm6.192-21.688v2.518l-3.941-2.518h3.941Zm0-1.319h-4.649l4.649-3.711v3.711Zm1.319-3.788 9.4 3.788h-9.4v-3.788Zm-7.509 3.333-4.518-3.757h9.224l-4.706 3.757Z"
        fill="#2E2E2E"
      />
      <path fill="url(#o)" fillOpacity={0.14} d="M1129 938.849h144.948v176.908H1129z" />
      <path fill="#F1AD18" d="M1274.14 938.849h10.781v176.908h-10.781zM1118.42 938.849h10.781v176.908h-10.781z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1185.36 966.5a3.59 3.59 0 0 1 3.59-3.586h26.36a3.59 3.59 0 0 1 3.59 3.586v14.344a3.59 3.59 0 0 1-3.59 3.586h-26.36a3.59 3.59 0 0 1-3.59-3.586V966.5Zm3.59-1.195c-.66 0-1.19.535-1.19 1.195v14.344c0 .66.53 1.195 1.19 1.195h26.36a1.2 1.2 0 0 0 1.2-1.195V966.5a1.2 1.2 0 0 0-1.2-1.195h-26.36Z"
        fill="#2E2E2E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1189.55 968.89a1.2 1.2 0 0 1 1.2-1.196h22.76c.66 0 1.2.536 1.2 1.196a1.2 1.2 0 0 1-1.2 1.195h-22.76a1.2 1.2 0 0 1-1.2-1.195ZM1189.55 973.669a1.2 1.2 0 0 1 1.2-1.195h22.76c.66 0 1.2.535 1.2 1.195a1.2 1.2 0 0 1-1.2 1.196h-22.76a1.2 1.2 0 0 1-1.2-1.196ZM1189.55 978.452a1.2 1.2 0 0 1 1.2-1.195h22.76c.66 0 1.2.535 1.2 1.195a1.2 1.2 0 0 1-1.2 1.195h-22.76a1.2 1.2 0 0 1-1.2-1.195ZM1218.9 968.292c.99 0 1.8.803 1.8 1.793v7.172c0 .991-.81 1.793-1.8 1.793s-1.8-.802-1.8-1.793v-7.172a1.8 1.8 0 0 1 1.8-1.793ZM1185.36 968.292c.99 0 1.8.803 1.8 1.793v7.172c0 .991-.81 1.793-1.8 1.793s-1.8-.802-1.8-1.793v-7.172a1.8 1.8 0 0 1 1.8-1.793Z"
        fill="#2E2E2E"
      />
      <g transform="translate(50,0)">
        <text
          fill="#2E2E2E"
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={20}
          letterSpacing={0.714}
          textAnchor="middle"
        >
          <tspan x={1160.61} y={1028.12}>
            {'Residential\n'}
          </tspan>
          <tspan x={1150.53} y={1052.12}>
            {'HVAC '}
          </tspan>
        </text>
        <text
          fill="#2E2E2E"
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={22}
          fontWeight={900}
          letterSpacing={0.571}
          textAnchor="middle"
        >
          <tspan x={1150.02} y={1083.51}>
            {residentialhvac}
          </tspan>
        </text>
      </g>
      <path fill="url(#p)" fillOpacity={0.14} d="M1129 527.658h146.146v176.908H1129z" />
      <path fill="#F1AD18" d="M1118.42 527.658h10.781v176.908h-10.781zM1275.34 527.658h10.781v176.908h-10.781z" />
      <path
        d="M1206.32 559.952c-1.1 0-2.02-.905-1.79-1.985a9.544 9.544 0 0 1 2.6-4.776 9.607 9.607 0 0 1 6.78-2.801c2.54 0 4.98 1.007 6.77 2.801a9.544 9.544 0 0 1 2.6 4.776c.23 1.08-.69 1.985-1.79 1.985h-15.17ZM1204.32 565.539c0-1.104.91-2.021 1.99-1.792a9.578 9.578 0 0 1 7.6 9.355 9.545 9.545 0 0 1-2.81 6.762 9.585 9.585 0 0 1-4.79 2.593c-1.08.228-1.99-.688-1.99-1.792v-15.126ZM1200.73 557.953c0 1.105-.91 2.021-1.99 1.793a9.593 9.593 0 0 1-4.79-2.593 9.536 9.536 0 0 1-2.8-6.762c0-2.536 1-4.969 2.8-6.762a9.578 9.578 0 0 1 4.79-2.593c1.08-.229 1.99.688 1.99 1.792v15.125ZM1198.73 563.539c1.1 0 2.02.905 1.79 1.986a9.552 9.552 0 0 1-2.6 4.776 9.594 9.594 0 0 1-13.55 0 9.552 9.552 0 0 1-2.6-4.776c-.23-1.081.69-1.986 1.79-1.986h15.17Z"
        stroke="#2E2E2E"
        strokeWidth={2}
      />
      <g transform="translate(50,0)">
        <text
          fill="#000"
          fillOpacity={0.82}
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={20}
          letterSpacing={0.714}
          textAnchor="middle"
        >
          <tspan x={1150.02} y={626.352}>
            {'Residential\n'}
          </tspan>
          <tspan x={1150.02} y={650.352}>
            {'Fan'}
          </tspan>
        </text>
        <text
          fill="#2E2E2E"
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={22}
          fontWeight={900}
          letterSpacing={0.571}
          textAnchor="middle"
        >
          <tspan x={1150.02} y={681.514}>
            {residentialfan}
          </tspan>
        </text>
      </g>
      <path fill="url(#q)" fillOpacity={0.14} d="M1129 733.255h144.948v176.908H1129z" />
      <path fill="#F1AD18" d="M1274.14 733.255h10.781v176.908h-10.781zM1118.42 733.255h10.781v176.908h-10.781z" />
      <g transform="translate(50,0)">
        <text
          fill="#000"
          fillOpacity={0.82}
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={20}
          letterSpacing={0.714}
          textAnchor="middle"
        >
          <tspan x={1154.42} y={832.904}>
            {'Home\n'}
          </tspan>
          <tspan x={1151.03} y={856.904}>
            {'Appliances'}
          </tspan>
        </text>
      </g>
      <text
        fill="#2E2E2E"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={22}
        fontWeight={900}
        letterSpacing={0.571}
        textAnchor="middle"
      >
        <tspan x={1195.02} y={888.514}>
          {homeappliances}
        </tspan>
      </text>
      <path d="M1185.57 748.38c0-.552.45-1 1-1h21.96c.55 0 1 .448 1 1v10.954h-23.96V748.38Z" stroke="#2E2E2E" strokeWidth={2} strokeLinejoin="round" />
      <path d="M1185.57 759.333h23.96v29.883h-22.96c-.55 0-1-.448-1-1v-28.883Z" stroke="#2E2E2E" strokeWidth={2} strokeLinejoin="round" />
      <path d="M1190.36 750.966v4.781M1190.36 764.115v4.782" stroke="#2E2E2E" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M1209.53 770.09h8.58c.56 0 1 .448 1 1v3.782h-9.58v-4.782ZM1209.53 774.873h9.58v13.344c0 .552-.44 1-1 1h-8.58v-14.344Z"
        stroke="#2E2E2E"
        strokeWidth={2}
      />
      <path
        d="M1209.58 778.789a4.814 4.814 0 0 1 2.05-.322c.7.043 1.38.236 1.97.564.6.327 1.08.777 1.42 1.308.33.531.51 1.126.5 1.73 0 .604-.19 1.197-.53 1.724-.34.528-.84.972-1.43 1.292-.6.32-1.28.506-1.99.54a4.8 4.8 0 0 1-2.04-.346"
        stroke="#2E2E2E"
        strokeWidth={2}
      />
      <path fill="url(#r)" fillOpacity={0.14} d="M1129 1144.45h144.948v176.908H1129z" />
      <path fill="#F1AD18" d="M1274.14 1144.45h10.781v176.908h-10.781zM1118.42 1144.45h10.781v176.908h-10.781z" />
      <g transform="translate(50,0)">
        <text
          fill="#000"
          fillOpacity={0.82}
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={22}
          letterSpacing={0.714}
          textAnchor="middle"
        >
          <tspan x={1153.34} y={1232.07}>
            {'Common\n'}
          </tspan>
          <tspan x={1155.73} y={1258.07}>
            {'Area'}
          </tspan>
        </text>
        <text
          fill="#2E2E2E"
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={22}
          fontWeight={900}
          letterSpacing={0.571}
          textAnchor="middle"
        >
          <tspan x={1156.02} y={1291.51}>
            {commonarea}
          </tspan>
        </text>
      </g>
      <path d="M1202.51 1175.54v-11.06h-13.78c-.55 0-1 .44-1 1v7.96M1216.38 1172.84v-7.36c0-.56-.45-1-1-1h-12.86v11.06" stroke="#2E2E2E" strokeWidth={1.7} />
      <path
        d="M1184.57 1181.14h.94v4.15c0 .25-.21.46-.47.46s-.47-.21-.47-.46v-4.15ZM1218.34 1181.14h.94v4.15c0 .25-.21.46-.47.46s-.47-.21-.47-.46v-4.15Z"
        fill="#000"
        stroke="#2E2E2E"
        strokeWidth={1.319}
      />
      <path
        d="M1187.73 1170.01h-4.54c-.55 0-1 .45-1 1v8.6c0 .55.45 1 1 1h37.73c.55 0 1-.45 1-1v-8.6c0-.55-.45-1-1-1h-4.55v4.53c0 .55-.44 1-1 1h-26.64c-.55 0-1-.45-1-1v-4.53Z"
        stroke="#2E2E2E"
        strokeWidth={1.7}
      />
      <path fill="url(#s)" fillOpacity={0.14} d="M104 523.865h145v176.617H104z" />
      <path fill="#FFCD60" d="M93 523.865h10.781v177.116H93zM248.729 523.865h10.781v177.116h-10.781z" />
      <rect x={158.754} y={544.82} width={34.03} height={21.294} rx={2} stroke="#2E2E2E" strokeWidth={1.5} />
      <path
        d="M169.479 566.664c.372-.353.865-.55 1.378-.55h9.823c.513 0 1.006.197 1.378.55l4.873 4.631c.655.622.214 1.725-.689 1.725h-20.947c-.903 0-1.344-1.103-.689-1.725l4.873-4.631ZM192.783 547.697l6.085 1.869a1.998 1.998 0 0 1 1.413 1.911v8.555a2 2 0 0 1-1.413 1.912l-6.085 1.868v-16.115Z"
        stroke="#2E2E2E"
        strokeWidth={1.5}
      />
      <path d="M196.289 551.725v8.058" stroke="#2E2E2E" strokeWidth={1.5} strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M178.751 548.83a.61.61 0 0 1 .272.712l-1.314 4.25h1.64c.218 0 .417.133.511.34a.622.622 0 0 1-.069.633l-5.288 6.92a.55.55 0 0 1-.718.148.612.612 0 0 1-.26-.72l1.392-4.194h-1.621a.565.565 0 0 1-.51-.336.62.62 0 0 1 .064-.63l5.187-6.965a.549.549 0 0 1 .714-.158Zm-4.289 6.894h1.255a.56.56 0 0 1 .463.252c.106.158.133.36.073.543l-.57 1.715 2.48-3.246h-1.235a.56.56 0 0 1-.46-.247.627.627 0 0 1-.08-.536l.564-1.823-2.49 3.342ZM157.701 561.51a1 1 0 0 1-1-1v-9.511a1 1 0 0 1 1-1h.797v11.511h-.797Z"
        fill="#2E2E2E"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M153.562 555.358a.5.5 0 0 1 .5-.5h4.114v1.793h-4.114a.5.5 0 0 1-.5-.5v-.793Z" fill="#2E2E2E" />
      <g transform="translate(50,0)">
        <text
          fill="#2E2E2E"
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={20}
          letterSpacing={0.714}
          textAnchor="middle"
        >
          <tspan x={132.922} y={616.709}>
            {'Diesel \n'}
          </tspan>
          <tspan x={128.987} y={640.709}>
            {'Generator'}
          </tspan>
        </text>
        <text
          fill="#2E2E2E"
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={22}
          letterSpacing={0.571}
          textAnchor="middle"
        >
          <tspan x={130.984} y={672.366}>
            {dieselgenerator}
          </tspan>
        </text>
      </g>
      <path fill="url(#t)" fillOpacity={0.14} d="M105 937.967h145v176.617H105z" />
      <path fill="#F34949" d="M94.197 937.967h10.781v176.596H94.197zM249.926 937.967h10.781v176.596h-10.781z" />
      <g translate="transform(50,0)">
        <text
          fill="#000"
          fillOpacity={0.82}
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={20}
          letterSpacing={0.714}
          textAnchor="middle"
        >
          <tspan x={174.699} y={1047.12}>
            {'Solar PV'}
          </tspan>
        </text>
        <text
          fill="#2E2E2E"
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={22}
          fontWeight={900}
          letterSpacing={0.571}
          textAnchor="middle"
        >
          <tspan x={174.068} y={1075.51}>
            {solarpv}
          </tspan>
        </text>
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M177.362 979.144c-3.601 0-6.533 2.925-6.533 6.522 0 3.594 2.932 6.519 6.533 6.519 3.604 0 6.535-2.925 6.535-6.519 0-3.597-2.931-6.522-6.535-6.522Zm0 15.804c-5.128 0-9.301-4.163-9.301-9.282s4.173-9.285 9.301-9.285c5.13 0 9.304 4.166 9.304 9.285s-4.174 9.282-9.304 9.282ZM177.556 973.748a1.382 1.382 0 0 1-1.384-1.382v-3.082c0-.764.619-1.382 1.384-1.382.766 0 1.384.618 1.384 1.382v3.082c0 .764-.618 1.382-1.384 1.382ZM170.869 975.431c-.479 0-.944-.247-1.2-.69l-1.545-2.669a1.383 1.383 0 0 1 2.397-1.381l1.545 2.669c.384.66.157 1.505-.506 1.886a1.376 1.376 0 0 1-.691.185ZM165.916 980.231c-.235 0-.473-.059-.691-.185l-2.675-1.543a1.381 1.381 0 1 1 1.384-2.392l2.675 1.543a1.381 1.381 0 0 1-.693 2.577ZM164.036 986.853h-3.089a1.381 1.381 0 1 1 0-2.762h3.089a1.38 1.38 0 1 1 0 2.762ZM163.053 995.068c-.479 0-.944-.248-1.201-.69a1.379 1.379 0 0 1 .507-1.887l2.676-1.54a1.385 1.385 0 0 1 1.891.506 1.38 1.38 0 0 1-.507 1.886l-2.676 1.54a1.37 1.37 0 0 1-.69.185ZM168.989 1001.14c-.235 0-.472-.06-.69-.19a1.375 1.375 0 0 1-.507-1.886l1.542-2.669a1.385 1.385 0 0 1 1.891-.505c.663.382.89 1.226.506 1.886l-1.542 2.664c-.256.45-.721.7-1.2.7ZM177.169 1003.43c-.765 0-1.384-.62-1.384-1.38v-3.087c0-.764.619-1.381 1.384-1.381.766 0 1.385.617 1.385 1.381v3.087c0 .76-.619 1.38-1.385 1.38ZM185.409 1001.33c-.479 0-.944-.25-1.199-.69l-1.546-2.67a1.376 1.376 0 0 1 .504-1.887 1.38 1.38 0 0 1 1.891.503l1.546 2.668a1.375 1.375 0 0 1-.504 1.886c-.219.13-.457.19-.692.19ZM191.483 995.404c-.235 0-.473-.059-.691-.185l-2.678-1.542a1.38 1.38 0 0 1-.507-1.886 1.385 1.385 0 0 1 1.891-.506l2.679 1.542c.663.381.89 1.225.506 1.886a1.385 1.385 0 0 1-1.2.691ZM193.777 987.239h-3.088a1.381 1.381 0 1 1 0-2.763h3.088a1.38 1.38 0 1 1 0 2.763ZM189.003 980.562a1.382 1.382 0 0 1-.692-2.577l2.675-1.539a1.382 1.382 0 1 1 1.382 2.392l-2.676 1.539a1.369 1.369 0 0 1-.689.185ZM184.19 975.625a1.378 1.378 0 0 1-1.197-2.071l1.546-2.672a1.384 1.384 0 0 1 2.397 1.382l-1.546 2.671c-.256.443-.721.69-1.2.69Z"
        fill="#2E2E2E"
      />
      <path fill="url(#u)" fillOpacity={0.14} d="M1129 115.27h146.146v176.908H1129z" />
      <path fill="#F1AD18" d="M1118.42 115.271h10.781v176.908h-10.781zM1275.34 115.27h10.781v176.908h-10.781z" />
      <g transform="translate(50,0)">
        <text
          fill="#000"
          fillOpacity={0.82}
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={20}
          letterSpacing={0.714}
          textAnchor="middle"
        >
          <tspan x={1150.61} y={218.435}>
            {'Residential\n'}
          </tspan>
          <tspan x={1150.61} y={242.435}>
            {'Hot Water'}
          </tspan>
        </text>
        <text
          fill="#2E2E2E"
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={22}
          fontWeight={900}
          letterSpacing={0.571}
          textAnchor="middle"
        >
          <tspan x={1144.35} y={274.514}>
            {residentialhotwater}
          </tspan>
        </text>
      </g>
      <path d="M1178.04 155.746h49.29v8.413c0 2.761-2.24 5-5 5h-39.29c-2.76 0-5-2.239-5-5v-8.413Z" stroke="#22412" strokeWidth={1} />
      <path
        d="M1184.76 147.922s-2.24-2.236-2.24-3.912c0-1.677 2.24-2.795 2.24-4.471 0-1.677-2.24-3.912-2.24-3.912M1190.37 147.922s-2.24-2.236-2.25-3.912c0-1.677 2.25-2.795 2.25-4.471 0-1.677-2.25-3.912-2.25-3.912M1195.96 147.922s-2.24-2.236-2.24-3.912c0-1.677 2.24-2.795 2.24-4.471 0-1.677-2.24-3.912-2.24-3.912M1193.73 169.158l-5.61 5.589M1211.65 169.158l5.6 5.589"
        stroke="#000"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
      <rect x={1173.56} y={152.392} width={58.247} height={3.353} rx={1} stroke="#000" strokeWidth={1.5} />
      <path
        d="M1217.51 138.221c.19-.227.47-.359.77-.359h.18c.3 0 .58.132.77.359l1.81 2.174c.28.325.04.82-.38.82h-4.58a.5.5 0 0 1-.39-.82l1.82-2.174Z"
        stroke="#000"
        strokeWidth={1.5}
      />
      <path d="M1226.77 151.833v-19.118c0-.553-.45-1-1-1h-6.4c-.56 0-1 .447-1 1v4.588" stroke="#000" strokeWidth={1.5} />
      <path fill="url(#v)" fillOpacity={0.14} d="M104 731.415h145v176.617H104z" />
      <path fill="#FF8162" d="M93 731.415h10.781v176.357H93zM248.729 731.416h10.781v176.357h-10.781z" />
      <g transform="translate(50,0)">
        <text
          fill="#000"
          fillOpacity={0.82}
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={20}
          letterSpacing={0.714}
          textAnchor="middle"
        >
          <tspan x={136.403} y={831.285}>
            {'Hot Water\n'}
          </tspan>
          <tspan x={132.585} y={855.285}>
            {'Management'}
          </tspan>
        </text>
        <text
          fill="#2E2E2E"
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={22}
          fontWeight={900}
          letterSpacing={0.571}
          textAnchor="middle"
        >
          <tspan x={128.568} y={886.514}>
            {solarweaterheater}
          </tspan>
        </text>
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M177.976 745.136a.75.75 0 0 0-1.5 0v2.961a.75.75 0 1 0 1.5 0v-2.961Zm10.341 15.079h-5.563a5.787 5.787 0 0 0 .611-2.593c0-3.314-2.774-5.957-6.14-5.957-3.366 0-6.14 2.643-6.14 5.957 0 .93.22 1.81.611 2.593h-9.366a5.768 5.768 0 0 0-2.159 11.117l.826 1.943 5.705 13.411c.235.554.779.913 1.38.913H195.05a1.5 1.5 0 0 0 1.398-2.044l-5.216-13.411-.372-.956h-.057a5.767 5.767 0 0 0-2.486-10.973Zm-6.452-2.593c0 .966-.32 1.861-.866 2.593h-7.674l.083-.059a4.313 4.313 0 0 1-.823-2.534c0-2.438 2.053-4.457 4.64-4.457 2.587 0 4.64 2.019 4.64 4.457Zm-13.672 8.36a5.728 5.728 0 0 1-1.903 4.268h22.027a4.268 4.268 0 0 0 0-8.535H166.29a5.728 5.728 0 0 1 1.903 4.267Zm-1.5 0c0 2.357-1.932 4.268-4.315 4.268-2.365 0-4.285-1.881-4.315-4.212l-.001-.056.002-.105c.056-2.308 1.966-4.162 4.314-4.162 2.383 0 4.315 1.911 4.315 4.267Zm-4.315 6.706h7.825l5.095 11.911.642 1.5h-7.858l-5.066-11.911-.638-1.5Zm12.088 0h-1.609l.58 1.5 4.606 11.911h7.143l-.583-1.5-4.632-11.911h-5.505Zm8.254 0h7.114l4.632 11.911.584 1.5h-7.144l-4.606-11.911-.58-1.5Zm4.233-24.911a.74.74 0 0 1 0 1.054l-2.089 2.066a.761.761 0 0 1-1.067 0 .741.741 0 0 1 0-1.055l2.089-2.065a.76.76 0 0 1 1.067 0Zm2.795 9.812a.75.75 0 1 0 0-1.5h-2.954a.75.75 0 0 0 0 1.5h2.954Zm-21.319-.75a.75.75 0 0 1-.75.75h-2.954a.75.75 0 0 1 0-1.5h2.954a.75.75 0 0 1 .75.75Zm.277-9.062a.76.76 0 0 0-1.067 0 .74.74 0 0 0 0 1.054l2.089 2.066a.761.761 0 0 0 1.067 0 .741.741 0 0 0 0-1.055l-2.089-2.065Z"
        fill="#2E2E2E"
      />
      <path fill="url(#w)" fillOpacity={0.14} d="M105 1144.52h145v176.617H105z" />
      <path fill="#D34848" d="M94.197 1144.52h10.781v176.835H94.197zM249.926 1144.52h10.781v176.835h-10.781z" />
      <g transform="translate(50, 0)">
        <text
          fill="#000"
          fillOpacity={0.82}
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={20}
          letterSpacing={0.714}
          textAnchor="middle"
        >
          <tspan x={130.675} y={1255.75}>
            {'Biogas'}
          </tspan>
        </text>
        <text
          fill="#2E2E2E"
          xmlSpace="preserve"
          style={{
            whiteSpace: 'pre',
          }}
          fontFamily="Lato"
          fontSize={22}
          fontWeight={900}
          letterSpacing={0.571}
          textAnchor="middle"
        >
          <tspan x={130.568} y={1284.51}>
            {biogas}
          </tspan>
        </text>
      </g>
      <path
        opacity={0.9}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M175.808 1172.47c.177-.03.358-.02.531.03.169.05.326.14.457.26 4.86 4.31 7.974 7.87 9.877 11.31 1.917 3.47 2.561 6.79 2.561 10.5 0 5.5-4.579 9.79-9.488 10.48-.251.04-.508 0-.732-.12a1.16 1.16 0 0 1-.521-.55c-.11-.23-.138-.5-.083-.75.055-.25.187-.47.377-.63 1.544-1.42 3.142-3.6 3.181-6.25.025-1.74-.682-3.85-2.714-6.38-.12.83-.334 1.71-.705 2.55-.438.98-1.163 2.04-2.404 2.41-.896.26-1.848.02-2.765-.46-.457.92-.796 1.75-.828 2.7-.043 1.29.45 3.01 2.572 5.54.167.2.265.45.279.7.014.26-.058.52-.205.73a1.165 1.165 0 0 1-1.357.45c-2.311-.77-4.583-1.99-6.319-3.69-1.746-1.72-2.96-3.97-2.96-6.67 0-2.66.559-4.62 1.442-6.23.862-1.57 2.016-2.77 3.135-3.94l.043-.05c1.143-1.19 2.278-2.39 3.272-4.05.989-1.66 1.853-3.81 2.389-6.91.038-.24.148-.46.317-.64.172-.17.396-.29.64-.34h.008Zm.413 1.79c-.57 2.97-1.448 5.14-2.479 6.87-1.09 1.82-2.335 3.13-3.476 4.32-1.152 1.2-2.184 2.29-2.947 3.67-.751 1.37-1.257 3.07-1.257 5.51 0 2.22.986 4.1 2.512 5.6 1.282 1.26 2.928 2.25 4.691 2.95-1.701-2.27-2.265-4.07-2.212-5.66.05-1.5.692-2.76 1.268-3.89l.087-.17a.739.739 0 0 1 .482-.38.73.73 0 0 1 .609.1c1.019.7 1.749.85 2.221.71.608-.18 1.083-.74 1.458-1.58.549-1.24.699-2.64.713-3.79.004-.3.193-.58.479-.69a.758.758 0 0 1 .818.19c3.078 3.28 4.317 6.18 4.28 8.75-.041 2.77-1.459 5.03-2.919 6.59 3.872-1 7.185-4.52 7.185-8.79 0-3.51-.603-6.56-2.374-9.77-1.731-3.13-4.579-6.45-9.139-10.54Zm-5.223 33.63c0-.42.336-.75.75-.75h9.87c.414 0 .75.33.75.75 0 .41-.336.75-.75.75h-9.87a.753.753 0 0 1-.75-.75Zm-2.253 2.67c-.414 0-.75.34-.75.75 0 .42.336.75.75.75h15.447c.414 0 .75-.33.75-.75 0-.41-.336-.75-.75-.75h-15.447Z"
        fill="#2E2E2E"
      />
      <path
        d="m1105.56 192.583-25.5 25.924v-51.849l25.5 25.925ZM1105.56 411.608l-21.75 15.987v-31.974l21.75 15.987ZM1105.56 619.158l-19.5 15.987v-31.974l19.5 15.987ZM1105.56 820.522l-17.25 11.968v-23.937l17.25 11.969ZM1105.56 1026.13l-13.5 7.99v-15.99l13.5 8ZM1105.84 1238.87l-8.99 4.96v-9.93l8.99 4.97Z"
        fill="#F1AD18"
      />
      <text
        fill="#000"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={22}
        letterSpacing={0.714}
      >
        <tspan x={22} y={47.458}>
          {'All values are in daily'}
        </tspan>
      </text>
      <text
        fill="#000"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={22}
        fontWeight={500}
        letterSpacing={0.714}
      >
        <tspan x={238.362} y={47.458} />
      </text>
      <text
        fill="#000"
        xmlSpace="preserve"
        style={{
          whiteSpace: 'pre',
        }}
        fontFamily="Lato"
        fontSize={22}
        fontWeight={900}
        letterSpacing={0.714}
      >
        <tspan x={244.641} y={47.458}>
          {'kWh'}
        </tspan>
      </text>
      <defs>
        <linearGradient id="a" x1={1202} y1={320.867} x2={1202} y2={497.921} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCD60" />
          <stop offset={1} stopColor="#FFCD60" stopOpacity={0.38} />
        </linearGradient>
        <linearGradient id="b" x1={252.322} y1={190.576} x2={1080.08} y2={190.577} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC60B" />
          <stop offset={1} stopColor="#F1AD18" />
        </linearGradient>
        <linearGradient id="c" x1={259.019} y1={252.455} x2={1077.94} y2={252.455} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC60B" />
          <stop offset={1} stopColor="#F1AD18" />
        </linearGradient>
        <linearGradient id="d" x1={259.038} y1={283.504} x2={1080.32} y2={283.504} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC60B" />
          <stop offset={1} stopColor="#F1AD18" />
        </linearGradient>
        <linearGradient id="e" x1={259.058} y1={332.752} x2={1082.7} y2={332.752} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC60B" />
          <stop offset={1} stopColor="#F1AD18" />
        </linearGradient>
        <linearGradient id="f" x1={259.087} y1={383.195} x2={1086.26} y2={383.195} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC60B" />
          <stop offset={1} stopColor="#F1AD18" />
        </linearGradient>
        <linearGradient id="g" x1={259.106} y1={423.356} x2={1088.64} y2={423.356} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC60B" />
          <stop offset={1} stopColor="#F1AD18" />
        </linearGradient>
        <linearGradient id="h" x1={258.99} y1={576.879} x2={1074.37} y2={576.878} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCD60" />
          <stop offset={1} stopColor="#F1AD18" />
        </linearGradient>
        <linearGradient id="i" x1={259.038} y1={616.137} x2={1080.32} y2={616.138} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCD60" />
          <stop offset={1} stopColor="#F1AD18" />
        </linearGradient>
        <linearGradient id="j" x1={259.048} y1={652.011} x2={1081.51} y2={652.011} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCD60" />
          <stop offset={1} stopColor="#F1AD18" />
        </linearGradient>
        <linearGradient id="k" x1={259.106} y1={732.692} x2={1088.64} y2={732.692} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCD60" />
          <stop offset={1} stopColor="#F1AD18" />
        </linearGradient>
        <linearGradient id="l" x1={258.951} y1={705.671} x2={1069.61} y2={705.671} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8162" />
          <stop offset={1} stopColor="#F1AD18" />
        </linearGradient>
        <linearGradient id="m" x1={266.235} y1={1067.24} x2={1088.69} y2={1067.24} gradientUnits="userSpaceOnUse">
          <stop stopColor="#F34949" />
          <stop offset={1} stopColor="#F1AD18" />
        </linearGradient>
        <linearGradient id="n" x1={255.56} y1={1234.03} x2={1091} y2={1234.03} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D34848" />
          <stop offset={1} stopColor="#F1AD18" />
        </linearGradient>
        <linearGradient id="o" x1={1201.47} y1={938.849} x2={1201.47} y2={1115.76} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCD60" />
          <stop offset={1} stopColor="#FFCD60" stopOpacity={0.38} />
        </linearGradient>
        <linearGradient id="p" x1={1202.07} y1={527.658} x2={1202.07} y2={704.566} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCD60" />
          <stop offset={1} stopColor="#FFCD60" stopOpacity={0.38} />
        </linearGradient>
        <linearGradient id="q" x1={1201.47} y1={733.255} x2={1201.47} y2={910.163} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCD60" />
          <stop offset={1} stopColor="#FFCD60" stopOpacity={0.38} />
        </linearGradient>
        <linearGradient id="r" x1={1201.47} y1={1144.45} x2={1201.47} y2={1321.35} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCD60" />
          <stop offset={1} stopColor="#FFCD60" stopOpacity={0.38} />
        </linearGradient>
        <linearGradient id="s" x1={176.5} y1={523.865} x2={176.5} y2={700.482} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCD60" />
          <stop offset={1} stopColor="#FF8162" stopOpacity={0.38} />
          <stop offset={1} stopColor="#FFCD60" stopOpacity={0.38} />
        </linearGradient>
        <linearGradient id="t" x1={177.5} y1={937.967} x2={177.5} y2={1114.58} gradientUnits="userSpaceOnUse">
          <stop stopColor="#F34949" />
          <stop offset={1} stopColor="#FF8162" stopOpacity={0.38} />
          <stop offset={1} stopColor="#F34949" stopOpacity={0.38} />
        </linearGradient>
        <linearGradient id="u" x1={1202.07} y1={115.27} x2={1202.07} y2={292.178} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFCD60" />
          <stop offset={1} stopColor="#FFCD60" stopOpacity={0.38} />
        </linearGradient>
        <linearGradient id="v" x1={176.5} y1={731.415} x2={176.5} y2={908.032} gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8162" />
          <stop offset={1} stopColor="#FF8162" stopOpacity={0.38} />
        </linearGradient>
        <linearGradient id="w" x1={177.5} y1={1144.52} x2={177.5} y2={1321.14} gradientUnits="userSpaceOnUse">
          <stop stopColor="#D34848" />
          <stop offset={1} stopColor="#D34848" stopOpacity={0.38} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default EnergyFlowDiagram;
