import React, { useEffect } from 'react';
import { TextField, IconButton, Typography, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { MarketplaceColors } from '../../views/marketplace/config';
import { useHistory } from 'react-router-dom';
import { Default, NavigatorSource } from '../../views/marketplace/utils/Defaults';
import { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useGraphqlRequest from '../../views/catalogue/Common/SendRequest';
import { searchSuggestionQuery } from '../../views/marketplace/graphql-queries/Search.query';
import { SearchResultList } from './SearchResultList';

const MarketplaceSearchBar = (props) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const useStyles = makeStyles((theme) => ({
    search: {
      margin: theme.spacing(1),
      width: props.width ? props.width : '30ch',
      textAlign: 'center',
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: error ? MarketplaceColors.error : MarketplaceColors.Secondary, // Border color on hover
        },
        '&.Mui-focused fieldset': {
          borderColor: error ? MarketplaceColors.error : MarketplaceColors.Secondary, // Border color when focused
        },
      },
    },
    // autocomplete: {
    //   backgroundColor: MarketplaceColors.CommonBlack,
    // },
    searchButton: {
      position: 'absolute',
      right: '0%',
      top: '0%',
      bottom: '0%',
      background: 'rgba(24, 50, 119, 0.05)',
      border: '1px solid #9E9E9E',
      borderRadius: '0px 5px 5px 0px',
      backgroundColor: props.searchButtonbgcolor ? props.searchButtonbgcolor : '',
      color: props.color ? props.color : '',
      '&:hover': {
        backgroundColor: MarketplaceColors.Primary,
        border: '1px solid #9E9E9E',
        borderRadius: '0px 5px 5px 0px',
      },
    },
    paperstyle: {
      backgroundColor: MarketplaceColors.CommonWhite,
      boxShadow: '2px 2px 8px 0px #1832771A',
      '& .MuiAutocomplete-listbox': {
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
          width: 0,
          height: 0,
        },
      },
      paddingBottom: '20px',
    },
    textstyle: {
      fontFamily: 'Lato',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.5px',
      color: '#2E2E2E',
    },
  }));
  const [state, SendRequest] = useGraphqlRequest(searchSuggestionQuery(value.toString()));
  // states
  const history = useHistory();
  const classes = useStyles();
  const handleClick = () => {
    if (value.length >= 3) {
      history.push(`/marketplace/categoryDetails?source=${NavigatorSource.search}&page=${Default.page}&pagesize=${Default.pagesize}&searchquery=${value}`);
      // SendRequest();
    } else {
      setError(true);
    }
  };
  const handleChange = (e) => {
    setError(false);
    setValue(e.target.value);
    if (e.target.value.length >= 3) {
      SendRequest();
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (value.length >= 3) {
        history.push(`/marketplace/categoryDetails?source=${NavigatorSource.search}&page=${Default.page}&pagesize=${Default.pagesize}&searchquery=${value}`);
        SendRequest();
      } else {
        setError(true);
      }
    }
  };
  const [suggestionarray, setSuggestionarray] = useState([]);
  useEffect(() => {
    if (state && state?.response !== null) {
      const { searchSuggestions } = state?.response?.data?.data;

      if (searchSuggestions) {
        const combinedArray = [
          ...(searchSuggestions?.supercategory || []),
          ...(searchSuggestions?.category || []),
          ...(searchSuggestions?.subcategory || []),
          ...(searchSuggestions?.products || []),
        ];

        setSuggestionarray(combinedArray);
        // console.log(searchSuggestions, 'searchSuggestions');
      }
    }
  }, [state]);
  const CustomPaper = (props) => <Paper {...props} className={classes.paperstyle} />;
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <Autocomplete
        id="free-solo-demo"
        freeSolo
        className={classes.autocomplete}
        PaperComponent={CustomPaper}
        options={suggestionarray}
        getOptionLabel={(option) => (option?.productname ? option?.productname : option?.sku_name)}
        filterOptions={(options) => options}
        renderOption={(option) => <SearchResultList option={option} />}
        onChange={(event, newValue) => {
          // Handle the click event here
          // console.log('Option clicked:', newValue);
          event.preventDefault();
          if (newValue?.supercategoryname) {
            history.push(
              `/marketplace/categoryDetails?source=${NavigatorSource.supercategories}&supercategory=${newValue?.supercategoryname}&page=${Default.page}&pagesize=${Default.pagesize}`
            );
          } else if (newValue?.categoryname) {
            history.push(
              `/marketplace/categoryDetails?source=${NavigatorSource.categories}&&category=${newValue?.categoryname}&page=${Default.page}&pagesize=${Default.pagesize}`
            );
          } else if (newValue?.sku_name) {
            history.push(`/marketplace/categories/${newValue?.category?.id}/products/${newValue?.id}`);
          }
        }}
        // onKeyDown={handleKeyDown}
        inputValue={value}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            variant="outlined"
            placeholder={props.placeholder}
            className={classes.search}
            onChange={(e) => {
              e.preventDefault();
              handleChange(e);
            }}
            // style={props.style}
            value={value}
            getOptionSelected={(option, value) => false}
            onKeyDown={handleKeyDown}
            InputProps={{
              ...params.InputProps,
              endAdornment: !props.IconButton ? (
                <IconButton className={classes.searchButton} type="submit" aria-label="search" position="end">
                  <SearchIcon fontSize="large" style={{ fontSize: props.fontSize }} onClick={handleClick} />
                  {/* onClick={handleClick} */}
                </IconButton>
              ) : (
                <SearchIcon fontSize="large" style={{ color: '#33518E', fontSize: props.fontSize }} onClick={handleClick} /> //onClick={handleClick}
              ),
            }}
            onFocus={handleOpen}
            onBlur={handleClose}
          />
        )}
        open={open && !!suggestionarray.length}
      />
      {error && <Typography style={{ color: MarketplaceColors.error, fontSize: '8px' }}>need atleast 3 letters to start search</Typography>}
    </>
  );
};

export default MarketplaceSearchBar;

/* <TextField
  size="small"
  className={classes.search}
  variant="outlined"
  id="validation-outlined-input"
  placeholder={props.placeholder}
  onChange={(e) => handleChange(e)}
  style={props.style}
  value={value}
  onKeyDown={handleKeyDown}
  InputProps={
    !props.IconButton
      ? {
          endAdornment: (
            <IconButton className={classes.searchButton} type="submit" aria-label="search" position="end">
              <SearchIcon fontSize="large" style={{ fontSize: props.fontSize }} onClick={handleClick} />
            </IconButton>
          ),
        }
      : {
          startAdornment: <SearchIcon fontSize="large" style={{ color: '#33518E', fontSize: props.fontSize }} onClick={handleClick} />,
        }
  }
/> */
