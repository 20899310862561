import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Drawer, Grid } from '@material-ui/core';
import CustomAddButton from '../../../certification/edit/ResidentHealthAndWellBeing/common/CustomAddButton';
import AddIcon from '@material-ui/icons/Add';
import { Sections } from '../../../certification/edit/ResidentHealthAndWellBeing/DataTypes';
import TowersInputHandler from './InputHandlers/TowersInputHandler';
import { getPIP } from '../../../interventions/intervention/utils';
import OpeningsHandler from './InputHandlers/OpeningsHandler';
import OpeningPerTowerHandler from './InputHandlers/OpeningPerTowerHandler';

export default function CustomDrawerEnvelope({
  title,
  section,
  customAddButton,
  editTable,
  handleEditTable,
  editableData,
  handleEditableData,
  setUpdate,
  update,
  indexforEdit,
  mount,
  setMount,
  envelopePIPs,
  setLocalTowerData,
  setLocalOpeningData,
  setLocalTowerOpeningData,
  tower,
  headerForBrowseButton,
  editableId,
  setClosePopover,
  openingData,
  projectSubcategory,
  form,
  handlePIPValueUpdate,
}) {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [state, setState] = React.useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
    handleEditTable(false);
    handleEditableData('');
  };

  useEffect(() => {
    if (editTable === true) setState({ ...state, ['right']: true });
  }, [editTable]);
  const list = (anchor, section) => (
    <div
      role="presentation"
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      style={{ paddingTop: '16%' }}
    >
      {section === 'TOWER' && (
        <TowersInputHandler
          form={form}
          handlePIPValueUpdate={handlePIPValueUpdate}
          projectSubcategory={projectSubcategory}
          setClosePopover={setClosePopover}
          editableId={editableId}
          setLocalTowerData={setLocalTowerData}
          requiredPipId={getPIP(envelopePIPs, 'project__pi_towers')?._id}
          update={update}
          setUpdate={setUpdate}
          handleClose={toggleDrawer('right', false)}
          editTable={editTable}
          editableData={!editTable ? null : editableData}
          indexforEdit={!editTable ? null : indexforEdit}
          mount={mount}
          setMount={setMount}
        />
      )}
      {section === 'OPENING' && (
        <OpeningsHandler
          form={form}
          handlePIPValueUpdate={handlePIPValueUpdate}
          editableId={editableId}
          setLocalOpeningData={setLocalOpeningData}
          requiredPipId={getPIP(envelopePIPs, 'project__pi_openings')?._id}
          update={update}
          setUpdate={setUpdate}
          handleClose={toggleDrawer('right', false)}
          editTable={editTable}
          editableData={!editTable ? null : editableData}
          indexforEdit={!editTable ? null : indexforEdit}
          mount={mount}
          setMount={setMount}
        />
      )}
      {section === 'OPENING TOWER' && (
        <OpeningPerTowerHandler
          form={form}
          handlePIPValueUpdate={handlePIPValueUpdate}
          projectSubcategory={projectSubcategory}
          openingData={openingData}
          editableId={editableId}
          setLocalTowerOpeningData={setLocalTowerOpeningData}
          requiredPipId={getPIP(envelopePIPs, 'project__pi_towerOpenings')?._id}
          update={update}
          setUpdate={setUpdate}
          handleClose={toggleDrawer('right', false)}
          editTable={editTable}
          editableData={!editTable ? null : editableData}
          indexforEdit={!editTable ? null : indexforEdit}
          mount={mount}
          setMount={setMount}
          tower={tower}
        />
      )}
    </div>
  );

  return (
    <>
      {['right'].map((anchor) => (
        <React.Fragment key={anchor}>
          {section === 'Dwelling Unit Interiors' ? (
            <Typography variant="h5" style={{ fontWeight: 600 }}>
              <AddIcon style={{ backgroundColor: '#49DDB1', color: '#ffff', borderRadius: '2px' }} onClick={toggleDrawer(anchor, true)} />{' '}
            </Typography>
          ) : (
            <></>
          )}
          <Grid xs={12} onClick={() => setOpenDrawer(true)}>
            {headerForBrowseButton && (
              <Typography style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '14px' }}>
                {projectSubcategory === 'apartment' ? `Tower ${tower?.name}` : `Villa Type ${tower?.name}`}
              </Typography>
            )}
            {customAddButton && <CustomAddButton title={title} handleOpenDrawer={toggleDrawer(anchor, true)} />}
          </Grid>
          <Drawer
            ModalProps={{
              keepMounted: false,
              style: {
                zIndex: 10,
                boxShadow: 'none',
                paddingTop: 200,
              },
            }}
            hideBackdrop={true}
            anchor={anchor}
            open={state[anchor]}
            onClose={() => {
              if (tower?._id === editableData?.towerId) {
                setOpenDrawer(false);
                toggleDrawer(anchor, false);
              } else if (openDrawer) {
                toggleDrawer(anchor, false);
              }
            }}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {list(anchor, section)}
          </Drawer>
        </React.Fragment>
      ))}
    </>
  );
}

const useStyles = makeStyles({
  list: {
    width: 450,
  },
  fullList: {
    width: 'auto',
  },
  drawerPaper: {
    width: '500px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    boxShadow: 'none',
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
  },
});
