import React from 'react';

// Material-UI Core Components
import { Card, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

// Material-UI Icons
import StopRoundedIcon from '@material-ui/icons/StopRounded';

// Utils
import numberWithCommas from '../../../../../utils/numberToComma';
import { LegalQuantityTypes, MetafireInterventions } from '../../../../../metafire/utils/types';
import { useAppMetafireUtilities } from '../../../../../metafire/hooks/useAppMetafireUtilities';

import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';

// Components
import Bold from '../../../../../v2/components/Bold';

const secondaryLabelByIntervention = (interventionName) => {
  switch (interventionName) {
    case MetafireInterventions.indoorFlooringLnD:
    case MetafireInterventions.indoorFlooringBedroom:
    case MetafireInterventions.indoorFlooringBathroom:
    case MetafireInterventions.indoorFlooringUnB:
    case MetafireInterventions.indoorFlooringKitchen:
      return 'Total EC Saved';
    default:
      return 'Total Savings';
  }
};

const secondaryLabelByCategory = (categoryName, interventionName) => {
  switch (categoryName) {
    case 'Materials':
      return secondaryLabelByIntervention(interventionName);
    default:
      return 'Savings (Annual)';
  }
};

const useStyles = makeStyles((theme) => ({
  paper1: {
    position: 'relative',
    width: 'auto',
    height: '435px',
    borderRadius: '10px',
    padding: theme.spacing(3, 2, 5, 2),
    boxShadow: '2px 2px 8px rgba(24, 50, 119, 0.1)',
  },
  paper2: {
    position: 'absolute',
    top: '10%',
    height: '80%',
    width: '50px',
    margin: '1%',
    display: 'flex',
    flexDirection: ' column-reverse',
  },
  alignUnits: {
    fontWeight: 100,
    marginBottom: '50%',
  },
  textcolor: {
    color: '#9E9E9E',
  },
  position: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  paper3: {
    textAlign: 'center',
    boxShadow: 'none',
    borderRadius: '0px',
  },
  paper4: {
    position: 'absolute',
    top: '12%',
    height: '80%',
    width: '10px',
    display: 'flex',
    flexDirection: ' column',
    boxShadow: 'none',
  },
  paper5: {
    height: '100%',
    textAlign: 'center',
    boxShadow: 'none',
    borderRadius: '0px',
    color: '#9E9E9E',
  },
  paper6: {
    position: 'absolute',
    top: '12%',
    height: '80%',
    width: '30px',
    display: 'flex',
    flexDirection: ' column',
    boxShadow: 'none',
  },
}));

const ValueMapForInterventions = ({ value, unit }) => (
  <Typography variant="body2" style={{ marginTop: '5%' }}>
    <Bold isHighlight={true} value={value} unit={unit} />
  </Typography>
);

const RightOverview = (props) => {
  const classes = useStyles();
  const {
    intervention,
    get: { getQuantitiesByTypeDefinition },
  } = useInterventionDetailsContext();
  const { getValue, getUnit } = useAppMetafireUtilities();

  let color = props.color;

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);

  const overviewValues = getQuantitiesByTypeDefinition(LegalQuantityTypes?.Overview);
  return (
    <div>
      <Card className={classes.paper1}>
        {!!overviewValues?.length && (
          <Grid container direction="row" xs={12} justifyContent="space-between">
            {overviewValues?.map((overview, i) => (
              <>
                <Grid item xs={6} container alignItems="flex-start" justifyContent="flex-start" direction="column" key={i}>
                  <Grid xs item>
                    <Typography variant="h5" style={{ color: '#9E9E9E', lineHeight: '20px' }}>
                      {overviewValues[0]?.value?.total?.label}
                    </Typography>
                  </Grid>
                  <Grid xs item style={{ marginTop: '5%' }}>
                    <Typography variant="h6">{secondaryLabelByCategory(intervention?.category?.name, intervention?.name)}</Typography>{' '}
                  </Grid>
                  <Grid xs item style={{ marginTop: '3%' }}>
                    <ValueMapForInterventions value={numberWithCommas(getValue(overview?.value?.total, 0))} unit={getUnit(overview?.value?.total)} />
                  </Grid>
                  <Grid item container xs direction="row" alignItems="center" alignContent="center" style={{ paddingTop: '5%' }}>
                    {overview?.value?.breakup?.map((ele, index) => {
                      return (
                        <>
                          <Grid item xs={2} style={{ paddingTop: '2%' }}>
                            <StopRoundedIcon style={{ border: 'none', color: `${color[index]}`, height: '40px', width: '40px' }} />
                          </Grid>
                          <Grid item xs={9} alignItems="center" alignContent="center" style={{ paddingTop: '3%' }}>
                            <Typography variant="h6">
                              <span className={classes.alignUnits}>{ele?.label}</span>
                            </Typography>
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end" direction="row" alignItems="flex-start">
                  <Grid item xs={4} container alignItems="flex-end" direction="column">
                    <Grid item xs style={{ position: 'absolute', marginTop: '5%' }}>
                      <Bold isHighlight={true} value={numberWithCommas(getValue(overview?.value?.max, 0))} unit={getUnit(overview?.value?.max)} />
                    </Grid>
                    <Grid item xs style={{ position: 'absolute', bottom: '40px' }}>
                      <Bold isHighlight={true} value={numberWithCommas(getValue(overview?.value?.min, 0))} unit={getUnit(overview?.value?.min)} />
                    </Grid>
                  </Grid>
                  <Grid item xs={3} container justifyContent="flex-end">
                    <Paper className={classes.paper2}>
                      {overview?.value?.breakup?.map((tiles, index) => {
                        return (
                          <>
                            {tiles?.value > 0 ? (
                              <LightTooltip
                                title={`${numberWithCommas(getValue(tiles, 0))} ${getUnit(tiles)} (${tiles?.label}) / ${numberWithCommas(
                                  getValue(overview?.value?.max, 0)
                                )} ${getUnit(tiles)}`}
                                placement="top"
                              >
                                <Paper
                                  style={{
                                    backgroundColor: `${color[index]}`,
                                    height: `${(getValue(tiles) / getValue(overview?.value?.total)) * 100}%`,
                                    textAlign: 'center',
                                    verticalAlign: 'middle',
                                  }}
                                  className={classes.paper3}
                                >
                                  <Typography variant="h6" style={{ color: '#FFFFFF', paddingTop: `${tiles?.value >= 50 ? 80 : 10}%` }}>
                                    {tiles?.value > 20 ? getValue(tiles) : ''}
                                  </Typography>
                                </Paper>
                              </LightTooltip>
                            ) : (
                              <Paper
                                style={{
                                  backgroundColor: `${color[index]}`,
                                  height: `${(getValue(tiles) / getValue(overview?.value?.total)) * 100}%`,
                                  textAlign: 'center',
                                  verticalAlign: 'middle',
                                }}
                                className={classes.paper3}
                              >
                                <Typography variant="h6" style={{ color: '#FFFFFF', paddingTop: `${tiles?.value >= 50 ? 80 : 10}%` }}>
                                  {tiles?.value > 20 ? getValue(tiles) : ''}
                                </Typography>
                              </Paper>
                            )}
                          </>
                        );
                      })}
                    </Paper>
                  </Grid>
                  <Grid item xs={1}>
                    <Grid container alignItems="center" direction="column" xs={12}>
                      <Paper className={classes.paper4}>
                        <Paper className={classes.paper5} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }}>
                          <Paper className={classes.paper5} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.2)', width: '50%' }}></Paper>
                        </Paper>
                        <Paper className={classes.paper5} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }}>
                          <Paper className={classes.paper5} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.2)', width: '50%' }}></Paper>
                        </Paper>
                        <Paper className={classes.paper5} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)' }}>
                          <Paper className={classes.paper5} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.2)', width: '50%' }}></Paper>
                        </Paper>
                        <Paper className={classes.paper5} style={{ borderTop: '1px solid rgba(0, 0, 0, 0.2)', borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}>
                          <Paper className={classes.paper5} style={{ borderRight: '1px solid rgba(0, 0, 0, 0.2)', width: '50%' }}></Paper>
                        </Paper>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Grid item xs={2} container>
                    <Grid container alignItems="flex-start" direction="column" xs={12}>
                      <Paper className={classes.paper6}>
                        <Paper className={classes.paper5}>
                          <Typography variant="h6" style={{ textAlign: 'left' }}>
                            100%
                          </Typography>
                        </Paper>
                        <Paper className={classes.paper5}>
                          <Typography variant="h6" style={{ textAlign: 'left' }}>
                            75%
                          </Typography>
                        </Paper>
                        <Paper className={classes.paper5}>
                          <Typography variant="h6" style={{ textAlign: 'left' }}>
                            50%
                          </Typography>
                        </Paper>
                        <Paper className={classes.paper5}>
                          <Typography variant="h6" style={{ textAlign: 'left' }}>
                            20%
                          </Typography>
                        </Paper>
                        <Typography variant="h6" style={{ textAlign: 'left', color: '#9E9E9E' }}>
                          0%
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ))}
          </Grid>
        )}
      </Card>
    </div>
  );
};

export default RightOverview;
