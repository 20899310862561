import swal from 'sweetalert';
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import WindowTypes from './WindowTypes/index';
import axios from '../../../../../config/Axios';
import DwellingUnitTypes from './DwellingUnitTypes';
import CustomHeader from '../../common/CustomHeader';
import PrimaryButton from '../../../../common/button/Button';
import useApiRequest from '../../../../hooks/useApiRequest';
import DwellingUnitInteriors from './DwellingUnitInteriors';
import { ResponseObject, Category } from './DataTypes';
import IIPData from './IIPData';
import { IGBCCategories } from '../../utils/types';

/* data to be used overall*/

export const ResidentHealthAndWellBeingContext = React.createContext();

const ResidentHealthAndWellBeing = ({ projectId, handleNext, next }) => {
  const history = useHistory();
  const [mount, setMount] = useState(false);
  const [myArray, setMyArray] = useState([]);

  // response will come from a custom api hook
  const [residentHealthAndWellBeingData, setResidentHealthAndWellBeingData] = useState(ResponseObject);
  const handleResidentHealthAndWellBeingData = (data) => setResidentHealthAndWellBeingData(data);

  const [{ status, response }, makeRequest] = useApiRequest(`/iip-category-mapping?projectID=${projectId}`, {
    verb: 'get',
  });

  const [mo, doRequest] = useApiRequest(`/igbc-category`, {
    verb: 'get',
  });

  const appendChanged = (id, value) => {
    const body = { id: id, value: value };
    let n =
      myArray &&
      myArray.find((data) => {
        return data.id === id;
      });
    if (n) {
      n.value = value;
      setMyArray((oldArray) => [...oldArray]);
    } else {
      setMyArray((oldArray) => [...oldArray, body]);
    }
  };

  useEffect(() => {
    if (status === null && mo.status === null) {
      doRequest();
      makeRequest();
    }
  }, [status, mo]);

  useEffect(() => {
    if (projectId) {
      axios.get(`iip-category-mapping?projectID=${projectId}`).then((res) => {
        if (res && res.data && res.data.data && res.data.data.data) setMyArray(res.data.data.data[0].data);
      });
    }
  }, [projectId]);

  useEffect(() => {
    if (response && mo.response) {
      const CategoryDetails = mo.response?.data?.data?.data.find((d) => d.categoryName === Category);
      if (response?.data?.data?.data[0]) {
        const { customData } = response?.data?.data?.data[0];
        if (CategoryDetails && customData?.length !== 0) {
          axios.get(`/igbc-input-parameter?category=${CategoryDetails?._id}`).then((res) => {
            if (res && res.data && res.data.data) {
              const IIPdetails = res.data.data.data.find((iip) => (iip.name = 'resident-health-and-well-being'));
              const responseObj = {};
              responseObj['name'] = IIPdetails?.name;
              responseObj['description'] = IIPdetails?.description;
              responseObj['label'] = IIPdetails?.label;
              responseObj['type'] = 'object';
              responseObj['unit'] = IIPdetails?.unit;
              responseObj['valueOptions'] = IIPdetails?.valueOptions;
              responseObj['section'] = IIPdetails?.section;
              responseObj['subSection'] = IIPdetails?.subSection;
              responseObj['category'] = IIPdetails?.category;
              responseObj['subSectionType'] = IIPdetails?.subSectionType;
              responseObj['customData'] = customData;
              setResidentHealthAndWellBeingData(responseObj);
            }
          });
        } else setResidentHealthAndWellBeingData(ResponseObject);
      } else setResidentHealthAndWellBeingData(ResponseObject);
    }
  }, [response, mo]);

  // step 1
  const handleSave = (projectId, residentHealthAndWellBeingData, next) => {
    const body = {
      projectID: projectId,
      data: myArray,
      customData: residentHealthAndWellBeingData['customData'][0],
    };
    axios.get(`/iip-category-mapping?projectID=${projectId}`).then((res) => {
      if (res && res.data && res.data.data && res.data.data.data.length === 0) {
        axios.post(`/iip-category-mapping`, body).then((res) => {
          if (res && res.data && res.data.data) {
            swal({ icon: 'success', title: 'Data Saved' });
            handleNext(next && next);
          }
        });
      } else {
        axios.put(`/iip-category-mapping`, body).then((res) => {
          if (res && res.data && res.data.data) {
            swal({ icon: 'success', title: 'Data Updated' });
            handleNext(next && next);
          }
        });
      }
    });
  };

  return (
    <>
      <ResidentHealthAndWellBeingContext.Provider value={{ data: residentHealthAndWellBeingData, handleData: handleResidentHealthAndWellBeingData }}>
        <Grid container style={{ marginBottom: '3%', marginTop: '4%' }}>
          <CustomHeader variant="h4" sectionName={IGBCCategories.residentHealth} i={0} history={history} projectId={projectId} />
        </Grid>
        <IIPData myArray={myArray} appendChanged={appendChanged} />
        {/* dwelling unit */}
        <DwellingUnitTypes mount={mount} setMount={setMount} />
        {/* Window types */}
        <WindowTypes mount={mount} setMount={setMount} residentHealthAndWellBeingData={residentHealthAndWellBeingData} />
        {/* dwelling unit interiors */}
        <DwellingUnitInteriors mount={mount} setMount={setMount} residentHealthAndWellBeingData={residentHealthAndWellBeingData} />
        {/* Save and Next */}
        <Grid container direction="row" alignItems="end" justifyContent="flex-end" item xs={12} style={{ marginTop: '4%', marginBottom: '7%' }}>
          <Grid item xs={2}>
            <PrimaryButton onClick={() => handleSave(projectId, residentHealthAndWellBeingData, next && next)} children="Save" />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={2}>
            {/* <PrimaryButton onClick={() => handleNext(next && next)} children="next" /> */}
            <PrimaryButton onClick={() => handleNext(next && next)} children="Exit" />
          </Grid>
        </Grid>
      </ResidentHealthAndWellBeingContext.Provider>
    </>
  );
};

export default ResidentHealthAndWellBeing;
