/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import Fields from './Fields';

export function sqmToSqft(val) {
  return val * 10.764;
}

export function sqftToSqm(val) {
  let result = (val / 10.764).toFixed(2);
  return result;
}

function UnitAwareTextField(props) {
  const {
    unit,
    field,
    projectPIP,
    appendChanged,
    xs,
    disabled = false,
    requiredFields = [
      'project__pi_builtUpArea',
      'project__pi_siteArea',
      'project__pi_rooftopArea',
      'project__pi_availableVillaRooftopArea',
      'project__pi_clubhouseArea',
    ],
  } = props;
  const [error, setError] = useState(false);

  const validateValue = (value) => {
    if (requiredFields.includes(field?.name)) {
      if (!value || Number(value) <= 0) {
        setError(true);
        return false;
      }
    }
    setError(false);
    return true;
  };

  const getApplicableValue = (v) => {
    if (field?.unit !== ('Sqm' || 'Sqft')) return v;
    if (unit === 'Sqm') return v;
    else if (unit === 'Sqft') return sqmToSqft(v).toFixed(2);
  };

  const getFormattedValue = (v) => {
    if (field?.unit !== ('Sqm' || 'Sqft')) return Number(v).toLocaleString('en-in');
    if (unit === 'Sqm') return Number(v).toLocaleString('en-in');
    else if (unit === 'Sqft') return Number(v).toLocaleString('en-in');
  };

  let val =
    field && projectPIP && projectPIP.find((d) => d.PIPScheme.name === field?.name) ? projectPIP.find((d) => d.PIPScheme.name === field?.name).value : '';

  const valRef = useRef(getApplicableValue(val && val));
  const [actualValue, setActualValue] = useState(null);

  useEffect(() => {
    if (val !== null || val !== undefined) {
      setActualValue(getFormattedValue(getApplicableValue(val)));
      valRef.current = getApplicableValue(val);
      validateValue(val);
    }
  }, [val, field, unit, valRef]);

  const handleChange = (e) => {
    const input = e.target.value;
    let final = null;
    if (input === '') final = input;
    const matched = input.match('^[0-9][0-9]*\\.?[0-9]{0,2}$');
    if (matched) final = input;
    if (final === null) return;
    valRef.current = final;
    setActualValue(valRef.current);
    validateValue(final);
  };

  const handleBlur = (e) => {
    const formatted = Number(e.target.value).toLocaleString('en-in');
    setActualValue(formatted);
    validateValue(e.target.value);
  };

  const handleFocus = (e) => {
    setActualValue(Number(valRef.current));
  };

  const handleClick = (e) => {
    e.target.select();
  };
  return (
    <>
      <Grid container direction="row" alignItems="center" item xs={12}>
        <Grid item xs={xs ? xs : 2}>
          <Fields
            value={actualValue && actualValue}
            onChange={(e) => {
              const roundedValue = (value) =>
                field?.type === 'number'
                  ? Number(value).toFixed(2) // Restrict to 2 decimal points
                  : value;

              handleChange(e);

              if (field?.unit !== ('Sqm' || 'Sqft')) {
                appendChanged(field?._id, roundedValue(e.target.value));
              } else if (unit === 'Sqm') {
                appendChanged(field?._id, roundedValue(e.target.value));
              } else if (unit === 'Sqft') {
                appendChanged(
                  field?._id,
                  field?.type === 'number'
                    ? Number(sqftToSqm(Number(e.target.value))) // Restrict conversion result to 2 decimals
                    : sqftToSqm(e.target.value)
                );
              }
            }}
            onClick={handleClick}
            onBlur={handleBlur}
            onFocus={handleFocus}
            disabled={disabled}
            error={error}
          />
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6" style={{ opacity: !disabled ? '1' : '0.5' }}>
            &nbsp;{field?.unit === ('NA' || 'na') ? <></> : field?.unit !== ('Sqm' || 'Sqft') ? field?.unit : unit && unit}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default UnitAwareTextField;
