import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Header from './Header';
import Footer from './Footer';
import numberWithCommas from '../../../../../utils/numberToComma';
import EnergyIcon from '../../../../../assets/new-pdf/energyIcon.png';
import WaterIcon from '../../../../../assets/new-pdf/WaterIcon.png';
import WasteIcon from '../../../../../assets/new-pdf/WasteIcon.png';
import MaterialsIcon from '../../../../../assets/new-pdf/MaterialsIcon.png';
import { getAnnualBaselineConsumption, getCurrentDate } from './ReportUtils';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewer: {
    width: window.innerWidth - 20,
    height: window.innerHeight - 20,
  },
  viewPage2: {
    margin: '20px 30px 30px 30px',
    padding: 10,
    flexGrow: 1,
  },
  footerMargin: {
    padding: 10,
  },
  page: {
    padding: 20,
    fontSize: 10,
  },
  table: {
    display: 'flex',
    flexDirection: 'column',
    borderWidth: 0,
    borderColor: '#ddd',
  },
  row: {
    flexDirection: 'row',
    marginBottom: '8px',
  },
  cell: {
    flex: '1 1 40px',
    textAlign: 'center',
    borderWidth: 0,
    borderColor: '#ddd',
    height: '90px',
    width: '195px',
    backgroundColor: '#F7F7F7',
  },
  headerCell: {
    flex: '1 1 40px',
    height: '35px',
    width: '120px',
    borderWidth: 0,
    borderColor: '#ddd',
    textAlign: 'center',
  },
  iconCell: {
    flex: 1,
    backgroundColor: '#f7f7f7',
    flexDirection: 'row',
    alignItems: 'center',
    borderWidth: 0,
    borderColor: '#ddd',
    height: '90px',
    width: '90px',
  },
  emptyCell: {
    flex: 1,
    backgroundColor: '#ffffff',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  header: {
    backgroundColor: '#00b8b8',
    color: 'white',
    padding: 10,
    textAlign: 'center',
    fontWeight: 'bold',
  },
});

const SdPlusImpactPageTwo = (props) => {
  const interventionData = props?.interventionData;
  const getResourceSavingAfter = (category) => {
    const before = getAnnualBaselineConsumption(interventionData, category)?.value;
    return before - interventionData?.categorized?.filter((item) => item.name === category)[0]?.quantities.resource.category.value;
  };
  return (
    <View style={styles.viewPage2}>
      <Header companyname={props.companyname} />
      {/* new view */}
      <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '10px' }}>
        <View>
          <Text style={{ fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '30px', textTransform: 'uppercase' }}>Your Project Is</Text>
        </View>
        <View>
          <Text
            style={{
              fontFamily: 'Lato',
              fontWeight: 'Bold',
              fontSize: '30px',
              textTransform: 'uppercase',
              color: '#10DDC2',
              paddingLeft: '5px',
            }}
          >
            {`${props.sustainabilitypercentage}% More`}
          </Text>
        </View>
      </View>
      <View style={{ flexDirection: 'row', display: 'flex' }}>
        <View>
          <Text
            style={{
              fontFamily: 'Lato',
              fontWeight: 'Bold',
              fontSize: '30px',
              textTransform: 'uppercase',
              color: '#10DDC2',
              // textAlign: 'center',
            }}
          >
            Sustainable
          </Text>
        </View>
        <View>
          <Text style={{ fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '30px', textTransform: 'uppercase', paddingLeft: '5px' }}>When</Text>
        </View>
      </View>
      <View style={{ flexDirection: 'row', display: 'flex' }}>
        <View>
          <Text style={{ fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '30px', textTransform: 'uppercase' }}>Compared to the base case</Text>
        </View>
      </View>
      {/* new view */}
      <View style={styles.page}>
        {/* Table Header */}
        <View style={styles.row}>
          <View style={{ ...styles.emptyCell, marginRight: '8px' }}></View>
          <View style={{ ...styles.headerCell, marginRight: '8px', backgroundColor: '#15B7B9' }}>
            <Text style={{ color: '#FFFFFF', fontFamily: 'Lato', fontSize: '14px', fontWeight: 'Bold', margin: 'auto 0' }}>BEFORE</Text>
          </View>
          <View style={{ ...styles.headerCell, marginRight: '8px', backgroundColor: '#15B7B9' }}>
            <Text style={{ color: '#FFFFFF', fontFamily: 'Lato', fontSize: '14px', fontWeight: 'Bold', margin: 'auto 0' }}>AFTER</Text>
          </View>
        </View>

        {/* Table Rows */}
        {[
          { label: 'Energy', backgroundColour: '#FFF4D5', unit: 'kWh', icon: EnergyIcon },
          { label: 'Water', backgroundColour: '#C9F5FF', unit: 'KL', icon: WaterIcon },
          { label: 'Waste', backgroundColour: '#EEE1D7', unit: 'kg', icon: WasteIcon },
          { label: 'Materials', backgroundColour: '#EDCDFF', unit: 'tCo2e', icon: MaterialsIcon },
        ].map((item, index) => (
          <View key={index} style={styles.row}>
            {/* Icon and Label */}
            <View style={{ ...styles.iconCell, backgroundColor: item.backgroundColour, marginRight: '8px' }}>
              <View style={{ margin: 'auto', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Image style={{ height: '20px', width: '20px', marginTop: '2px', marginRight: '10px' }} src={item.icon} />
                <Text style={{ color: 'black', fontFamily: 'Lato', fontSize: '14px', fontWeight: 'Bold' }}>{item.label}</Text>
              </View>
            </View>
            {/* Before */}
            <View style={{ ...styles.cell, marginRight: '8px' }}>
              <View style={{ margin: 'auto 0' }}>
                <Text style={{ color: '#2E2E2E', fontFamily: 'Lato', fontSize: '14px', fontWeight: 'Bold' }}>
                  {numberWithCommas(Math.round(getAnnualBaselineConsumption(interventionData, item.label)?.value))}
                </Text>
                <Text style={{ color: '#666666', fontFamily: 'Lato', fontSize: '12px', fontWeight: 'Bold', marginTop: '2px' }}>{item.unit}</Text>
              </View>
            </View>
            {/* After */}
            <View style={{ ...styles.cell, marginRight: '8px' }}>
              <View style={{ margin: 'auto 0' }}>
                <Text style={{ color: '#2E2E2E', fontFamily: 'Lato', fontSize: '14px', fontWeight: 'Bold' }}>
                  {numberWithCommas(Math.round(getResourceSavingAfter(item.label)))}
                </Text>
                <Text style={{ color: '#666666', fontFamily: 'Lato', fontSize: '12px', fontWeight: 'Bold', marginTop: '2px' }}>{item.unit}</Text>
              </View>
            </View>
          </View>
        ))}
      </View>
      <View style={{ position: 'absolute', left: 20, top: 810, right: 20 }}>
        <Footer year={getCurrentDate().split('/')[2]} />
      </View>
    </View>
  );
};

export default SdPlusImpactPageTwo;
