import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Backdrop, CircularProgress, makeStyles, Grid } from '@material-ui/core';
import Header from '../../common/header/Header';
import solarPV from '../../../assets/solarPV.svg';
import HotWaterManagement from '../../../assets/HotWaterManagement.svg';
import swm from '../../../assets/smartwatermeter.svg';
import ef from '../../../assets/efficientFixtures.svg';
import twr from '../../../assets/greywater.svg';
import rrwh from '../../../assets/interventions/rrwh.svg';
import paints from '../../../assets/interventions/materials/exterior-paints.svg';
import nstp from '../../../assets/interventions/NSTPicon.svg';
import wallBlocks from '../../../assets/interventions/materials/wall-blocks-rectangle.svg';

import solarData from '../../../assets/faq-data/SolarPV';
import hotWaterManagementData from '../../../assets/faq-data/HotWaterManagement';
import efficientFixturesData from '../../../assets/faq-data/EfficientFixtures';
import smartWaterMetersData from '../../../assets/faq-data/SmartWaterMeter';
import treatedWaterReuseData from '../../../assets/faq-data/TreateWaterReuse';
import rainWaterHarvestingData from '../../../assets/faq-data/RainWaterHarvesting';
import paintsData from '../../../assets/faq-data/Paints';
import naturalSTPData from '../../../assets/faq-data/NaturalSTP';
import wallBlocksData from '../../../assets/faq-data/WallBlocks';

import Sidebar from '../../common/sidebar/Sidebar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import HeaderAndBackIcon from '../marketplace/common/Header/HeaderAndBackIcon';
import { MarketplaceTypography } from '../marketplace/config';

const FaqUsers = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: '10%',
    },
    heading: {
      marginTop: '3%',
      width: '100%',
    },
    link: {
      fontSize: '18px',
      lineHeight: '28px',
      fontWeight: 850,
      textDecorationLine: 'underline',
      color: '#00C58A',
      paddingTop: '2%',
    },
    subheading: {
      fontWeight: 850,
      color: '#00C58A',
      cursor: 'pointer',
    },
    question: {
      lineHeight: '22px',
      fontWeight: 900,
      letterSpacing: '0.714286px',
      color: '#000000',
      paddingTop: '2%',
    },
    answer: {
      fontSize: '14px',
      lineHeight: '22px',
      fontWeight: 500,
      letterSpacing: '0.714286px',
      color: '#000000',
      paddingLeft: '12px',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    expand_icon: {
      color: '#26E0A3',
      height: '32px',
      width: '32px',
      borderRadius: '50%',
      backgroundColor: '#F0FBF2',
      marginRight: '8px',
    },
    expand_icon_top: {
      color: '#26E0A3',
      height: '40px',
      width: '40px',
      borderRadius: '50%',
      backgroundColor: '#F0FBF2',
    },
    imageText: {
      textAlign: 'left',
      display: 'inline-block',
      '& div': {
        textAlign: 'center',
      },
    },
    fontTextBold: {
      fontFamily: 'Lato',
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '100%',
      letterSpacing: '0.7142857313156128px',
    },
    fontSelected: {
      fontSize: '14px',
      lineHeight: '100%',
      letterSpacing: '0.714286px',
      color: 'rgba(0, 0, 0, 0.82)',
      fontFamily: 'Lato',
      fontWeight: 700,
      textColor: 'rgba(0, 0, 0, 0.82)',
    },
    fontText: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '100%',
      letterSpacing: '0.714286px',
      color: 'rgba(0, 0, 0, 0.6)',
      '&:focus, &:active, &:hover': {
        textDecoration: 'none',
        color: 'rgba(0, 0, 0, 0.82)',
      },
      fontFamily: 'Lato, sans-serif',
    },
    textInfo: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '32px',
      letterSpacing: '0.714286px',
      color: '#000000',
      fontFamily: 'Lato',
    },
    fontTextTwo: {
      fontFamily: 'Lato',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '32px',
      letterSpacing: '0.7142857313156128px',
      padding: '0px 14px 14px 0px',
    },
    headingText: {
      fontFamily: 'Lato',
      fontSize: '30px',
      fontWeight: 700,
      lineHeight: '100%',
      letterSpacing: '1.07px',
    },
    questionText: {
      fontFamily: 'Lato',
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '100%',
      letterSpacing: '0.7142857313156128px',
      padding: '10px 10px 10px 18px',
    },
    mainHeading: {
      fontFamily: 'Lato',
      fontSize: '40px',
      fontWeight: 700,
      lineHeight: '48px',
      letterSpacing: '1.4285714626312256px',
    },
    MuiAccordionroot: {
      '&.MuiAccordion-root:before': {
        backgroundColor: '#fafafa',
      },
      '&.MuiAccordionSummary-root': {
        border: '1px solid #E5E5E5',
        borderRadius: '8px',
      },
      '&.Mui-expanded': {
        border: '1px solid #C6F2CD',
        borerRadius: '8px',
      },
    },
    accordion: {
      border: '1px solid #E5E5E5',
      marginBottom: '8px',
      borderRadius: '8px', // Default border for all accordions
      '&.Mui-expanded': {
        border: '1px solid #00C58A', // Border for the expanded accordion
        borderRadius: '8px',
      },
    },
    MuiAccordionrootstyle: {
      '&.MuiAccordionDetails-root': {
        padding: '0px',
      },
    },
  }));

  const classes = useStyles();
  let currentprojects = null;
  const history = useHistory();

  const [activeCategory, setActiveCategory] = useState('Solar PV');

  const categories = [
    { id: 'Solar PV', title: 'Solar PV', icon: solarPV, data: solarData.data },
    { id: 'Hot Water Management', title: 'Hot Water Management', icon: HotWaterManagement, data: hotWaterManagementData.data },
    { id: 'Smart Water Meters', title: 'Smart Water Meters', icon: swm, data: smartWaterMetersData.data },
    { id: 'Efficient Fixtures', title: 'Efficient Fixtures', icon: ef, data: efficientFixturesData.data },
    { id: 'Treated Water Reuse', title: 'Treated Water Reuse', icon: twr, data: treatedWaterReuseData.data },
    { id: 'Rain Water Harvesting', title: 'Rain Water Harvesting', icon: rrwh, data: rainWaterHarvestingData.data },
    { id: 'Paints', title: 'Paints', icon: paints, data: paintsData.data },
    { id: 'Natural STP', title: 'Natural STP', icon: nstp, data: naturalSTPData.data },
    { id: 'Wall Blocks', title: 'Wall Blocks', icon: wallBlocks, data: wallBlocksData.data },
  ];

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <Backdrop className={classes.backdrop}>
          <CircularProgress style={{ color: '#28DEAA' }} />
        </Backdrop>
        <Header currentprojects={currentprojects && currentprojects} />
      </div>
      <div style={{ marginTop: '4%', display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '14vw' }}>
          <Sidebar fromFaq={true}>
            <>
              <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '10%' }}>
                <h4 className={classes.fontTextBold}>Categories</h4>
              </div>
              {categories.map((category) => (
                <div
                  key={category.id}
                  onClick={() => setActiveCategory(category.id)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '10%',
                    cursor: 'pointer',
                    height: '35px',
                    background: activeCategory === category.id ? `linear-gradient(to right, rgba(64, 225, 156, 0.1) 0%, rgba(64, 225, 156, 0.1) 100%)` : '',
                    border: 'none',
                    borderRight: activeCategory === category.id ? `3px solid #26E0A3` : '',
                  }}
                >
                  <Typography className={activeCategory === category.id ? classes.fontSelected : classes.fontText}>{category.title}</Typography>
                </div>
              ))}
            </>
          </Sidebar>
        </div>
        <div style={{ width: '80vw', marginLeft: '2%', marginTop: '1%' }}>
          <HeaderAndBackIcon
            headingStyle={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '40px', lineHeight: '48px', letterSpacing: '1.43px' }}
            heading={'FAQs'}
            arrowColor={'#26E0A3'}
            variant={'h4'}
            style={MarketplaceTypography.PageHeading}
            iconHeight={'40px'}
            iconWidth={'40px'}
            bold={true}
            handleClick={() => history.goBack()}
            hideArrowBack={false}
          />
          <Typography className={classes.textInfo} style={{ marginTop: '2%', marginBottom: '2%' }}>
            This section addresses common questions you may have about interventions, mandates or calculations to help you get the most out of SD+. If you don't
            find what you're looking for here, feel free to contact us at <span style={{ color: '#26E0A3' }}>connect@sdplus.io</span>
          </Typography>

          {/* Map through categories to create accordion sections */}
          {categories.map((category) => (
            <Accordion
              key={category.id}
              className={classes.MuiAccordionroot}
              style={{ border: 'none', boxShadow: 'none', backgroundColor: '#fafafa', padding: '0px' }}
              expanded={activeCategory === category.id}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.expand_icon_top} />}
                aria-controls={`panel-${category.id}-content`}
                id={`panel-${category.id}-header`}
                IconButtonProps={{
                  onClick: () => setActiveCategory(activeCategory === category.id ? '' : category.id),
                }}
                style={{ alignItems: 'center', width: 'fit-content', padding: '0px' }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img style={{ height: '50px', width: '50px' }} src={category.icon} alt={category.title} />
                  <Typography className={classes.headingText} variant="h5" style={{ marginLeft: '2px' }}>
                    {category.title}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'flex', flexDirection: 'column', padding: '0px' }}>
                {category.data.map((d, index) => (
                  <Accordion key={`${category.id}-question-${index}`} className={classes.accordion} style={{ boxShadow: 'none', backgroundColor: '#fafafa' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className={classes.expand_icon} />}
                      aria-controls={`${category.id}-question-${index}-content`}
                      id={`${category.id}-question-${index}-header`}
                      style={{ padding: '0px' }}
                    >
                      <Typography className={classes.questionText}>{d.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className={classes.fontTextTwo}>
                        <span className={classes.imageText} dangerouslySetInnerHTML={{ __html: d.response }} />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FaqUsers;
