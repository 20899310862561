import React, { createContext, useContext } from 'react';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import 'react-toastify/dist/ReactToastify.css';
import infoIcon from '../../assets/interventions/info-icon.svg';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Close } from '@material-ui/icons';
import { Grid, Typography } from '@material-ui/core';
import TickIcon from '../../assets/interventions/TickIcon.svg';

const NotificationContext = createContext();

const useStyles = makeStyles(() => ({
  toastContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '5px',
    width: '100%',
    textAlign: 'center',
    color: '#333',
    justifyContent: 'space-between',
  },
  icon: {
    marginRight: '10px',
  },
  message: {
    fontFamily: 'Lato',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '22px',
    letterSpacing: '0.7142857313156128px',
    textAlign: 'center',
  },
  errorImageIcon: {
    marginTop: '5px',
    height: '24px',
    width: '24px',
  },
  successIcon: {
    marginTop: '5px',
    height: '24px',
    width: '24px',
    backgroundColor: '#fffff',
    color: 'rgba(38, 224, 163, 1)',
  },
  overrideToastify: {
    '& .Toastify__toast': {
      width: '600px !important',
    },
  },
  closeIcon: {
    height: '24px',
    width: '24px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
    color: '#333',
  },
  typography: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const CustomToastContent = ({ type, message }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.toastContainer}>
      <Typography className={classes.typography}>
        <span className={`${classes.icon}`}>
          {type === 'success' ? (
            <img className={classes.successIcon} src={TickIcon} alt="..." />
          ) : (
            <img className={classes.errorImageIcon} src={infoIcon} alt="..." />
          )}
        </span>
        <span className={classes.message}>{message}</span>
      </Typography>
      <Close className={classes.closeIcon} onClick={() => toast.dismiss()} aria-label="Close"></Close>
    </Grid>
  );
};

export const NotificationProvider = ({ children }) => {
  const classes = useStyles();

  const notify = (message, options = {}) => {
    toast(<CustomToastContent type={options.type} message={message} />, {
      position: 'top-center',
      autoClose: options.autoClose || 5000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: true,
      draggable: true,
      transition: Bounce,
      icon: false,
      toastClassName: classes.toastContainer,
      className: classes.toastContainer,
      style: { border: options.type === 'success' ? '1px solid #26E0A3' : '1px solid #ED5E68' },
      toastStyle: {
        width: options.width || 'auto',
        minWidth: 'unset',
      },
      ...options,
    });
  };

  const notifySuccess = (message, options = {}) => {
    notify(message, { ...options, type: 'success' });
  };

  const notifyFailure = (message, options = {}) => {
    notify(message, { ...options, type: 'failure' });
  };

  return (
    <NotificationContext.Provider value={{ notifySuccess, notifyFailure }}>
      {children}
      <ToastContainer className={classes.overrideToastify} />
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
