import { api } from './http';

const getOrganisations = async () => {
  const data = await api.get(`/v2/admin/organisations`);
  return data;
};

const postOrganisation = async (payload) => {
  const data = await api.post(`/v2/admin/organisation`, payload);
  return data;
};

const getOrganisationUsers = async (orgID) => {
  const data = await api.get(`/v2/admin/users/${orgID}`);
  return data;
};

const postOrganisationUser = async (payload) => {
  const data = await api.post(`/v2/admin/user`, payload);
  return data;
};

const putOrganisationUser = async (userId, payload) => {
  const data = await api.put(`/v2/admin/user/${userId}`, payload);
  return data;
};

export { getOrganisations, postOrganisation, getOrganisationUsers, postOrganisationUser, putOrganisationUser };
