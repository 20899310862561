import { Typography, Grid } from '@material-ui/core';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import useStyles from './styles';
import CustomDropdown from '../common/CustomDropdown';

const CoolRoofBasicParameters = (props) => {
  const classes = useStyles();

  const {
    schema,
    get: { getQuantityValue, getQuantityLabel },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={4}>
        <Grid container direction="column">
          <Typography className={classes.textheader2}>{getQuantityLabel(schema.coolRoof__pi_surface)}</Typography>
          <Grid item style={{ marginTop: '10px' }}>
            <CustomDropdown
              value={getQuantityValue(schema.coolRoof__pi_surface)}
              onChange={(e) => {
                e.preventDefault();
                updateQuantityValue(schema.coolRoof__pi_surface, e.target.value);
              }}
              options={['High SRI Tile', 'High SRI Coating']}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Grid container direction="column">
          <Typography className={classes.textheader2}>{getQuantityLabel(schema.coolRoof__pi_subSurface)}</Typography>
          <Grid item style={{ marginTop: '10px' }}>
            <CustomDropdown
              value={getQuantityValue(schema.coolRoof__pi_subSurface)}
              onChange={(e) => {
                e.preventDefault();
                updateQuantityValue(schema.coolRoof__pi_subSurface, e.target.value);
              }}
              options={['Insulated Roofing', 'Non-Insulated Roofing']}
              disabled={false}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CoolRoofBasicParameters;
