import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import IndoorFlooringPIPs from './IndoorFlooringPIPs';

const Cement = ({ data, appendChanged, projectPIP, pipValues }) => {
  return (
    <>
      <Grid container direction="row" xs={12} style={{ height: 'auto' }}>
        <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '3%' }}>
          <Typography variant="h5">
            <b>Cement</b>
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-start" direction="row" xs={12}>
        {data &&
          data.map((pip, index) => {
            return (
              <Grid container item xs={5} alignItems="center" style={{ margin: '2% 0 0 1%' }}>
                <Grid item xs={5} key={index}>
                  {' '}
                  <IndoorFlooringPIPs
                    _id={pip._id}
                    displayName={pip.displayName}
                    appendChanged={(a, b) => appendChanged(a, b)}
                    disabledValue={false}
                    values={pip?.options}
                    projectPIP={projectPIP && projectPIP}
                    pipValues={pipValues}
                  />
                </Grid>
                <Grid item>
                  <Typography>{pip.unit === 'NA' ? '' : pip.unit}</Typography>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default Cement;
