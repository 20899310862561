// React Imports
import React, { useState, useContext } from 'react';

// Material-UI Components and Styling
import { Grid, Popover, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

// Configuration and Utilities
import { MarketplaceColors } from '../../../config';
import UseNavigatorData from '../../../hooks/useNavigatorData';
import { HoverText } from '../../../utils/HoverText';
import { DummyNavigatorData } from '../../StaticNavigatorData';
import { NavigatorSource } from '../../../utils/Defaults';

// Contexts
import { ProductsPageContext } from '../../../../../../routing/IndexRouting';

// Components
import NavigatorColumnsAndCollections from './NavigatorColumnsAndCollections';

const StyledPopover = withStyles({
  root: {
    marginTop: 50,
    boxShadow: 'none',
  },
  paper: {
    color: '#ffffff',
    background: '#FFF',
    height: 'auto',
    width: '1148px',
    paddingLeft: '1%',
    paddingBottom: '1%',
    overflowX: 'auto',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
    '&:focus': {
      outline: 'none',
    },
  },
})(Popover);

const useStyles = makeStyles((theme) => ({
  tabs: {
    padding: theme.spacing(2, 20, 2, 2),
  },
}));

export default function MarketplaceNavigatorDesktop(props) {
  const classes = useStyles();
  const { Primary, TextColorTertiary } = MarketplaceColors;
  const { handleReset, URLS } = useContext(ProductsPageContext);
  const { open, setOpen } = props;

  const { navigatorData } = UseNavigatorData();
  const navigatorColumns = navigatorData?.columns;
  const superCategoryCollections = navigatorColumns?.find((col) => col.source === NavigatorSource.supercategories)?.collections;
  const { tabs: NavigatorTabs } = DummyNavigatorData;
  const [selectedTab, setSelectedTab] = useState(NavigatorTabs[0]);

  const handleClick = () => setOpen((prev) => !prev);
  const handleClickAway = () => setOpen(false);

  const renderSelectedText = (text) => (
    <Typography variant="body2" color="textSecondary" style={{ transition: 'color 0.3s ease', cursor: 'pointer', color: Primary }}>
      {text}
    </Typography>
  );

  const renderNavigatorTabs = (
    <Grid container justifyContent="flex-start" className={classes.tabs}>
      {NavigatorTabs.map((tab, index) => (
        <Grid
          key={index}
          xs={2}
          container
          justifyContent="flex-start"
          style={{ paddingTop: '0.5%', paddingBottom: '0.5%' }}
          onClick={() => setSelectedTab(tab)}
        >
          {selectedTab === tab ? renderSelectedText(tab) : HoverText(tab, Primary, TextColorTertiary, true)}
        </Grid>
      ))}
    </Grid>
  );

  const renderColumnsAndCollections = (
    <Grid container>
      {navigatorColumns?.length ? (
        navigatorColumns.map(({ id, source, collections }, index) => (
          <NavigatorColumnsAndCollections
            key={id || index}
            index={index}
            source={source}
            data={collections}
            handleReset={handleReset}
            superCategory_collections={superCategoryCollections}
          />
        ))
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress style={{ color: '#28DEAA' }} />
        </Grid>
      )}
    </Grid>
  );

  const renderCollections = (
    <StyledPopover
      open={open}
      onMouseLeave={handleClickAway}
      onClose={handleClickAway}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {renderNavigatorTabs}
      {selectedTab === NavigatorTabs[0] && renderColumnsAndCollections}
      {selectedTab === NavigatorTabs[1] && renderColumnsAndCollections}
    </StyledPopover>
  );

  return (
    <div className={classes.root}>
      <Typography variant="body1" style={{ cursor: 'pointer' }} onMouseEnter={handleClick} onClick={handleClick}>
        CATEGORIES
      </Typography>
      {renderCollections}
    </div>
  );
}
