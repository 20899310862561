import { Redirect, Route } from 'react-router-dom';
import NotFound from '../components/common/notfound/Index';

function RouteWithSubRoutes(props) {
  const { isRouteAllowedToUser = true, isUserLoggedIn, ...route } = props;

  if (!isUserLoggedIn && !route?.isPublic) return <Redirect to="/" />;
  if (!isRouteAllowedToUser) return <NotFound />;
  return (
    <Route
      exact={route.exact}
      path={route.path}
      render={(props) => {
        return <route.component {...props} />;
      }}
    />
  );
}

export default RouteWithSubRoutes;
