import { withWidth, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import useScreenType from '../../../../../hooks/checkScreenType';
import MarketplaceButton from '../../../common/Button';
import CustomTextFeild from './CustomTextFeild';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Loader from '../../../../../common/progressbar/Loader';
import { useAppApiRequest } from '../../../../../../hooks/useAppApiRequest';

const ProductContactDetails = ({ handleClose, setJoinus, companyDetails, productDetails }) => {
  const { isMobile } = useScreenType();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhoneNumber] = useState('');
  const [additionalInfo, setinfo] = useState('');
  const [rfqRequestSent, setRfqRequestSent] = useState(false);
  const [error, setError] = useState({ email: false, name: false, phoneNumber: false });
  const [errorMessages, setErrorMessages] = useState({ email: '', name: '', phoneNumber: '' });
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation
    return emailRegex.test(email);
  };
  const isValidPhoneNumber = (phone) => {
    let isError = false;
    let message;
    if (!/^\d+$/.test(phone)) {
      message = 'Only numers allowed';
      isError = true;
    }
    if (phone.length !== 10) {
      message = 'Must be 10 digits';
      isError = true;
    }
    return { isError, message };
  };
  const { data: response, isFetching } = useAppApiRequest(
    '/joinus',
    {
      method: 'POST',
      data: {
        email,
        name,
        phone,
        additionalInfo,
        vendorName: companyDetails?.name ? companyDetails?.name : null,
        vendoremail: companyDetails?.email ? companyDetails?.email : null,
        vendorPhoneNumber: companyDetails?.contact ? companyDetails?.contact : null,
        vendorPointOfContact: companyDetails?.poc ? companyDetails?.poc : null,
        productName: productDetails?.sku_name ? productDetails?.sku_name : null,
      },
    },
    [],
    rfqRequestSent
  );

  const handleSendQuoteRequest = () => {
    const emailError = email === '' || !isValidEmail(email);
    const nameError = name === '';
    const phoneError = phone && isValidPhoneNumber(phone).isError;

    setError({
      email: emailError,
      name: nameError,
      phoneNumber: phoneError,
    });
    setErrorMessages({
      email: emailError ? (email === '' ? 'Email is required.' : 'Please enter a valid email address.') : '',
      name: nameError ? 'Name is required.' : '',
      phoneNumber: phoneError ? isValidPhoneNumber(phone).message : '',
    });
    if (!emailError && !nameError && !phoneError) {
      setRfqRequestSent(true);
    }
  };

  const body = (
    <Grid container alignItems="flex-start" justifyContent="flex-start" style={isMobile ? { paddingLeft: '3%', marginBottom: '3%' } : null}>
      <Grid xs={12}>
        <Typography variant="h5">
          <b>Request for Quote</b>
        </Typography>
      </Grid>
      <Grid xs={12} style={{ marginTop: '4%' }} container>
        <Grid item xs={5}>
          <CustomTextFeild
            value={email}
            onChange={(e) => {
              setError({ ...error, email: false });
              setEmail(e.target.value);
            }}
            error={error.email}
            disabled={false}
            placeholder="Email Address"
            helperText={error.email ? errorMessages.email : ''}
          />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={5}>
          <CustomTextFeild
            value={name}
            onChange={(e) => {
              setError({ ...error, name: false });
              setName(e.target.value);
            }}
            error={error.name}
            disabled={false}
            placeholder="Name"
            helperText={error.name ? errorMessages.name : ''}
          />
        </Grid>
      </Grid>
      <Grid xs={12} style={{ marginTop: '4%' }} container>
        <Grid item xs={5}>
          <CustomTextFeild
            value={phone}
            onChange={(e) => {
              setError({ ...error, phoneNumber: false });
              setPhoneNumber(e.target.value);
            }}
            error={error.phoneNumber}
            disabled={false}
            placeholder="Phone Number"
            helperText={error.phoneNumber ? errorMessages.phoneNumber : 'Optional'}
          />
        </Grid>
        <Grid item xs={7}></Grid>
      </Grid>
      <Grid xs={12} style={{ marginTop: '4%' }} container>
        <CustomTextFeild
          value={additionalInfo}
          onChange={(e) => {
            e.preventDefault();
            setinfo(e.target.value);
          }}
          height="15vh"
          disabled={false}
          placeholder="Additional Information"
          helperText="You can specify details like location, project size, rooftop space, tonnage, and unit quantity."
        />
      </Grid>
      <Grid
        xs={12}
        container
        alignItems="center"
        justifyContent={!isMobile ? 'flex-end' : 'center'}
        style={{ marginTop: '5%', paddingRight: '3%', paddingLeft: '3%' }}
      >
        {!isFetching && (
          <Grid xs={!isMobile ? 4 : 12} container alignItems="center" justifyContent={!isMobile ? 'flex-end' : 'center'}>
            <MarketplaceButton children="Send Request" style={isMobile ? { width: '217px' } : null} onClick={handleSendQuoteRequest} />
          </Grid>
        )}
        {isFetching && (
          <Grid container direction="column" alignItems="center" item xs={12}>
            <Grid item xs={12}>
              <Loader />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  const successBody = (
    <Grid direction="column" container alignItems="center" justifyContent="center" style={isMobile ? { paddingLeft: '3%', marginBottom: '3%' } : null}>
      <Typography variant="h5">
        <b>Request Successful</b>
      </Typography>
      <CheckCircleIcon
        style={{
          marginTop: '20px',
          height: '72px',
          width: '72px',
          background: 'rgba(51, 81, 142, 1)',
          borderRadius: '50px',
          border: 'rgba(51, 81, 142, 1)',
          color: '#ffffff',
        }}
      />
      <Typography variant="h6" style={{ paddingTop: '20px' }}>
        Your request was shared to the vendor
      </Typography>
      <Grid
        xs={12}
        container
        alignItems="center"
        justifyContent={!isMobile ? 'flex-end' : 'center'}
        style={{ marginTop: '5%', paddingRight: '3%', paddingLeft: '3%' }}
      >
        <Grid xs={!isMobile ? 4 : 12} container alignItems="center" justifyContent={!isMobile ? 'flex-end' : 'center'}>
          <MarketplaceButton children="Ok" style={isMobile ? { width: '217px' } : null} onClick={!isMobile ? handleClose : () => setJoinus(false)} />
        </Grid>
      </Grid>
    </Grid>
  );

  return response ? successBody : body;
};

export default withWidth()(ProductContactDetails);
