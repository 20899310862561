/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography, FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import axios from '../../../../config/Axios';

// Common Components
import Fields from '../../../common/textfield/Fields';
import HeadAndBackIcon from '../../../common/header/HeadAndBackIcon';
import CustomizedTooltips from '../../../common/tooltip/TooltipV2';
import PrimaryButton from '../../../common/button/Button';
import { GreenRadio } from '../../../common/RadioButton/GreenRadio';
import ApiError from '../../../common/Error/ApiError';

// Material Components
import MaterialPresetBrand from './components/MaterialsPresetBrand';
import MaterialPresetPaint from './components/MaterialsPresetPaint';
import MaterialPresetFinish from './components/MaterialsPresetFinish';
import InteriorPaints from './components/InteriorPaints';
import IndoorFlooring from './components/IndoorFlooring';
import RooftopMaterialDetails from './components/RooftopMaterialDetails';
import ConcreteMaterialDetails from './components/ConcreteMaterialDeatails';
import SteelMaterialDetails from './components/SteelMaterialDetails';

// Context and Static Data
import { SomethingChanged } from '../EditProject';
import { TooltipData, ParentPage, ChildPage, ComponentName, ComponentTooltipData } from '../../../../staticData/TooltipStaticData';

// Custom Hooks
import useAppProjectDetails from '../hooks/useAppProjectDetails';
import useAppProjectPIPMapping from '../hooks/useAppProjectPIPMapping';
import useAppPIPScheme from '../hooks/useAppPIPScheme';
import Cement from './components/Cement';

// PIP Configurations
const rooftopMaterialPipsOrder = [
  'project__pi_cementTilesRoof',
  'project__pi_roofGardenThin',
  'project__pi_roofGardenMedium',
  'project__pi_roofGardenThick',
  'project__pi_roofGardenHeavy',
];

const presetPipValues = [
  'project__pi_exteriorPaintWaterBasedBrandName',
  'project__pi_exteriorPaintWaterBasedFinish',
  'project__pi_exteriorPaintWaterBasedPaintName',
  'project__pi_exteriorPaintManualCoverageArea',
  'project__pi_exteriorPaintManualEC',
  'project__pi_exteriorPaintManualCapex',
];

const indoorFlooringPipsInOrder = [
  'project__pi_indoorFlooringLivingandDining',
  'project__pi_indoorFlooringKitchen',
  'project__pi_indoorFlooringBedroom',
  'project__pi_indoorFlooringBathroom',
  'project__pi_indoorFlooringUtilityAndBalcony',
];

const cementPipsInOrder = [
  'project__pi_flooringThickness',
  'project__pi_flooringMortarRatio',
  'project__pi_internalPlasterThickness',
  'project__pi_internalPlasterMortarRatio',
  'project__pi_externalPlasterThickness',
  'project__pi_externalPlasterMortarRatio',
];

const exteriorPaintManualInOrder = [
  'project__pi_exteriorPaintManualPaintName',
  'project__pi_exteriorPaintManualBrandName',
  'project__pi_exteriorPaintManualCoverageArea',
  'project__pi_exteriorPaintManualEC',
  'project__pi_exteriorPaintManualCapex',
];

const concreteMaterialPipsOrder = ['project__pi_typeofConstruction', 'project__pi_slabThickness', 'project__pi_beamDepth', 'project__pi_wallThickness'];

const ManualPipField = ({ index, pip, form, handlePIPValueUpdate }) => {
  return (
    <Grid
      container
      xs={12}
      justifyContent="flex-start"
      alignItems="flex-start"
      style={index === 0 ? { marginLeft: '6%', marginTop: '3%' } : { marginLeft: '6%', marginTop: '9%' }}
    >
      <Grid container alignItems="flex-start" justifyContent="flex-start" item xs={12}>
        <Typography variant="h6">{pip.displayName}</Typography>
      </Grid>
      <Grid container alignItems="flex-start" justifyContent="flex-start" item xs={12}>
        {pip?.description && pip?.description !== 'NA' ? (
          <Typography variant="body1" color="textSecondary" style={{ marginTop: '2%' }}>
            {pip.description}
          </Typography>
        ) : null}
      </Grid>
      <Grid container alignItems="flex-start" justifyContent="flex-start" item xs={4} style={{ marginTop: '3%' }}>
        <Fields
          value={form?.pipvalues[pip._id]}
          onChange={(e) => handlePIPValueUpdate(pip._id, e.target.value)}
          onClick={(e) => e.target.select()}
          fullWidth
          required
        />
      </Grid>
    </Grid>
  );
};
const MaterialDetails = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const { type, next, name } = props;

  // Paint State
  const [paintBrands, setPaintBrands] = useState('');
  const [paintFinishes, setPaintFinishes] = useState('');
  const [paintNames, setPaintNames] = useState('');
  const [interiorPaintBrands, setInteriorPaintBrands] = useState('');

  const { somethingChanged, handlSetSomethingChanged } = useContext(SomethingChanged);

  const { data: projectData } = useAppProjectDetails(id);
  const {
    get: { form, projectPIP, isError },
    set: { handlePIPValueUpdate },
  } = useAppProjectPIPMapping(id);
  const { data } = useAppPIPScheme(type);

  const manualPips = data?.filter((d) => exteriorPaintManualInOrder.includes(d.name));

  const presetPips = data?.filter((d) => presetPipValues.includes(d?.name)).sort((a, b) => presetPipValues.indexOf(a.name) - presetPipValues.indexOf(b.name));

  const indoorFlooringPips = data
    ?.filter((d) => indoorFlooringPipsInOrder.includes(d.name))
    .sort((a, b) => indoorFlooringPipsInOrder.indexOf(a.name) - indoorFlooringPipsInOrder.indexOf(b.name));

  const cementPips = data
    ?.filter((d) => cementPipsInOrder.includes(d.name))
    .sort((a, b) => cementPipsInOrder.indexOf(a.name) - cementPipsInOrder.indexOf(b.name));

  const rooftopMaterialPips = data
    ?.filter((d) => rooftopMaterialPipsOrder.includes(d.name))
    .sort((a, b) => rooftopMaterialPipsOrder.indexOf(a.name) - rooftopMaterialPipsOrder.indexOf(b.name));

  const steelMaterialPips = data?.filter((d) =>
    [
      'project__pi_quantityFactorOptionOne',
      'project__pi_quantityFactorOptionTwo',
      'project__pi_quantityFactorOptionThree',
      'project__pi_quantityFactorOptionFour',
      'project__pi_quantityFactorOptionFive',
      'project__pi_quantityFactorUserInput',
    ].includes(d.name)
  );

  const concreteMaterialPips = data
    ?.filter((d) => concreteMaterialPipsOrder.includes(d.name))
    .sort((a, b) => concreteMaterialPipsOrder.indexOf(a.name) - concreteMaterialPipsOrder.indexOf(b.name));

  // Find specific PIPs
  const exteriorPaintUserChoicePIP = data?.find((d) => d.name === 'project__pi_exteriorPaintUserChoice');
  const interiorPaintUserChoicePIP = data?.find((d) => d.name === 'project__pi_interiorPaintUserChoice');
  const presetBrandUserChoicePIP = data?.find((d) => d.name === 'project__pi_exteriorPaintWaterBasedBrandName');
  const presetFinishUserChoicePIP = data?.find((d) => d.name === 'project__pi_exteriorPaintWaterBasedFinish');
  const presetPaintUserChoicePIP = data?.find((d) => d.name === 'project__pi_exteriorPaintWaterBasedPaintName');

  const selectedPaintBrand = form?.pipvalues[presetBrandUserChoicePIP?._id];
  const selectedPaintFinish = form?.pipvalues[presetFinishUserChoicePIP?._id];
  const selectedPaintName = form?.pipvalues[presetPaintUserChoicePIP?._id];
  const exteriorPaintUserChoiceValue = form?.pipvalues[exteriorPaintUserChoicePIP?._id];
  const interiorPaintUserChoiceValue = form?.pipvalues[interiorPaintUserChoicePIP?._id];

  // Effects
  useEffect(() => {
    axios.get(`/PaintProvider?list=Brand&type=Exterior Paints`).then((res) => {
      setPaintBrands(res?.data.data.data);
      axios.get(`/PaintProvider?list=Brand&type=Interior Paints`).then((res) => setInteriorPaintBrands(res?.data.data.data));
    });
  }, []);

  useEffect(() => {
    if (selectedPaintBrand) {
      axios.get(`PaintProvider?list=Finish&type=Exterior Paints&brand=${selectedPaintBrand}`).then((res) => setPaintFinishes(res?.data.data.data));
    }
  }, [selectedPaintBrand]);

  useEffect(() => {
    if (selectedPaintBrand && selectedPaintFinish) {
      axios
        .get(`PaintProvider?list=Paint&brand=${selectedPaintBrand}&finish=${selectedPaintFinish}&type=Exterior Paints`)
        .then((res) => setPaintNames(res?.data.data.data));
    }
  }, [selectedPaintBrand, selectedPaintFinish]);

  useEffect(() => {
    if (projectPIP) {
      projectPIP.forEach((d) => {
        handlePIPValueUpdate(d.PIPScheme._id, d.value);
      });
    }
  }, [projectPIP]);

  useEffect(() => {
    (async () => {
      if (props.saveAfterSomethingChanged === true && next) {
        await handleSave(next);
        props.toogleShowModal(false);
        props.setFalseAfterSomethingChanged();
        props.SETNEXT('');
      }
    })();
  }, [props.saveAfterSomethingChanged, next]);

  const handleNext = (next) => {
    if (next === 'goals') {
      history.push(`/projects/${id}/goals`);
    } else if (somethingChanged) {
      props.toogleShowModal(true);
      props.SETNEXT(next);
    } else {
      history.push(`/projects/${id}/edit?type=${next}`);
    }
  };

  const handleGoBack = (type) => {
    if (type === 'basic') {
      history.push('/projects');
    } else {
      history.push(`/projects/${id}/edit?type=basic`);
    }
  };

  const handleSave = async (next) => {
    try {
      await axios.put(`/ProjectPIPMapping`, {
        projectId: id,
        values: Object.entries(form?.pipvalues).map(([key, value]) => ({
          PIPScheme: key,
          value,
        })),
      });
      swal({ icon: 'success', title: 'Data Saved' });
      props.setFalseAfterSomethingChanged();
      handlSetSomethingChanged(false);
      history.push(`/projects/${id}/edit?type=${next}`);
    } catch (error) {
      handleError(error);
    }
  };

  const filterFromData = (tooltipData, ParentPage, ChildPage, ComponentName) => {
    const result = tooltipData?.find((d) => d.parentPage === ParentPage && d.childPage === ChildPage && d.componentName === ComponentName);
    return result?.content;
  };

  const ExteriorPaintsTooltipData = filterFromData(
    TooltipData,
    ParentPage.INTERVENTIONS,
    ChildPage.INTERVENTIONS_SUMMARY,
    ComponentName.EXTERIOR_PAINTS,
    ComponentTooltipData.EXTERIOR_PAINTS
  );

  const handleError = (error) => swal({ icon: 'error', title: error });

  if (isError) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', paddingTop: 24, width: '100%' }}>
        <ApiError />
      </div>
    );
  }

  return (
    <>
      <Grid container justifyContent="flex-start" direction="column" item xs={12} style={{ marginTop: '3%', marginLeft: '1%' }}>
        <HeadAndBackIcon
          heading={`${name} ${projectData?.name || ''}`}
          handleClick={() => handleGoBack(type)}
          visitGoals={false}
          headingVariant="h5"
          headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
          showArrow={false}
        />
      </Grid>

      {data && (
        <>
          {/* Exterior Paint Section */}
          <Grid container direction="row" xs={12} style={{ height: 'Auto' }}>
            <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '2%', marginTop: '3%' }}>
              <Typography variant="h5">
                <b>Exterior Paint</b>
              </Typography>
              <CustomizedTooltips
                marginTop="15px"
                icon="info"
                title={ExteriorPaintsTooltipData?.map((v) => (
                  <Typography>{v.data}</Typography>
                ))}
                placement="right"
              />
            </Grid>
            <Grid container alignItems="flex-start" direction="row" xs={12} item style={{ marginLeft: '2%' }}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="exterior paint"
                  name="paint schemes"
                  value={exteriorPaintUserChoiceValue || ''}
                  onChange={(e) => handlePIPValueUpdate(exteriorPaintUserChoicePIP?._id, e.target.value)}
                >
                  {exteriorPaintUserChoicePIP.options.map((option, index) => (
                    <>
                      <FormControlLabel key={index} value={option} control={<GreenRadio />} label={option} />
                      {exteriorPaintUserChoiceValue === 'Water Based' &&
                        index === 1 &&
                        presetPips?.map((pip) => {
                          switch (pip.name) {
                            case 'project__pi_exteriorPaintWaterBasedBrandName':
                              return (
                                <Grid style={{ marginTop: '16px' }}>
                                  <MaterialPresetBrand
                                    pip={pip}
                                    selectedPaintBrand={selectedPaintBrand}
                                    paintBrands={paintBrands}
                                    appendChanged={handlePIPValueUpdate}
                                    pipValues={form?.pipvalues}
                                  />
                                </Grid>
                              );
                            case 'project__pi_exteriorPaintWaterBasedFinish':
                              return (
                                <Grid style={{ paddingTop: '25px' }}>
                                  <MaterialPresetFinish
                                    pip={pip}
                                    selectedPaintBrand={selectedPaintBrand}
                                    selectedPaintFinish={selectedPaintFinish}
                                    paintFinishes={paintFinishes}
                                    appendChanged={handlePIPValueUpdate}
                                  />
                                </Grid>
                              );
                            case 'project__pi_exteriorPaintWaterBasedPaintName':
                              return (
                                <Grid style={{ paddingTop: '25px', marginBottom: '16px' }}>
                                  <MaterialPresetPaint
                                    pip={pip}
                                    selectedPaintBrand={selectedPaintBrand}
                                    selectedPaintFinish={selectedPaintFinish}
                                    paintNames={paintNames}
                                    selectedPaintName={selectedPaintName}
                                    appendChanged={handlePIPValueUpdate}
                                  />
                                </Grid>
                              );
                            default:
                              return null;
                          }
                        })}
                      {exteriorPaintUserChoiceValue === 'Manual' &&
                        index === 2 &&
                        manualPips?.map((pip, index) => (
                          <ManualPipField key={index} index={index} pip={pip} form={form} handlePIPValueUpdate={handlePIPValueUpdate} />
                        ))}
                    </>
                  ))}
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>

          <InteriorPaints
            data={data}
            interiorPaintBrands={interiorPaintBrands}
            interiorPaintFinish={interiorPaintBrands}
            interiorPaintNames={interiorPaintBrands}
            appendChanged={handlePIPValueUpdate}
            projectPIP={projectPIP}
            pipValues={form?.pipvalues}
            interiorPaintUserChoicePIP={interiorPaintUserChoicePIP}
            interiorPaintUserChoicePIPValue={interiorPaintUserChoiceValue}
            manualPips={manualPips}
          />
        </>
      )}

      <ConcreteMaterialDetails data={concreteMaterialPips} appendChanged={handlePIPValueUpdate} projectPIP={projectPIP} pipValues={form?.pipvalues} />

      <SteelMaterialDetails data={steelMaterialPips} appendChanged={handlePIPValueUpdate} projectPIP={projectPIP} pipValues={form?.pipvalues} />

      <IndoorFlooring data={indoorFlooringPips} appendChanged={handlePIPValueUpdate} pipValues={form?.pipvalues} projectPIP={projectPIP} />

      <Cement data={cementPips} appendChanged={handlePIPValueUpdate} pipValues={form?.pipvalues} projectPIP={projectPIP} />

      <RooftopMaterialDetails data={rooftopMaterialPips} appendChanged={handlePIPValueUpdate} projectPIP={projectPIP} />

      <Grid container direction="row" alignItems="flex-end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '6%' }}>
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleSave(next)} children="Save" />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleNext(next)} children="next" />
        </Grid>
      </Grid>
    </>
  );
};

export default MaterialDetails;
