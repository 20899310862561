import React, { useEffect, useState, useContext } from 'react';
import { Document, Page, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';
import LatoRegular from '../../../../fonts/Lato-Regular.ttf';
import LatoBold from '../../../../fonts/Lato-Bold.ttf';
import { useParams } from 'react-router';
import { UserContext } from '../../../../../routing/IndexRouting';
import EnergyRounded from '../../../../../assets/new-pdf/EnergyRounded.png';
import WaterRounded from '../../../../../assets/new-pdf/WaterRounded.png';
import WasteRounded from '../../../../../assets/new-pdf/WasteRounded.png';
import energyIcon from '../../../../../assets/new-pdf/dummyenrgylogo.png';
import MaterialsRounded from '../../../../../assets/new-pdf/MaterialsRounded.png';

import HeadingPage from './HeadingPage';

import SdImpactReport from '../../../../../assets/SdImpactReport.png';

import { useAppApiRequest } from '../../../../../hooks/useAppApiRequest';
import { MetafireFeatures } from '../../../../../metafire/utils/types';
import SdPlusImpactPageTwo from './SdPlusImpactPageTwo';
import CategoryWiseSummaryPage from './CategoryWiseSummaryPage';
import { getCategoryWiseEnabledAndActivatedInterventionsData, getCurrentDate } from './ReportUtils';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewer: {
    width: window.innerWidth - 8,
    height: window.innerHeight - 8,
  },
});

Font.register({
  family: 'Lato',
  fonts: [
    {
      src: LatoRegular,
    },
    {
      src: LatoBold,
    },
  ],
});

const SdPlusImpactReport = () => {
  const { userDetails } = useContext(UserContext);
  const params = useParams();
  const [asset, setAsset] = useState('');
  const [intervention, setIntervention] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [goals, setGoals] = useState(null);
  const [sustainabilityLevel, setSustainabilityLevel] = useState(null);
  const [projectData, setProjectData] = React.useState('');

  const { data: insightsResponse } = useAppApiRequest(`/metafire?projectId=${params?.id}&feature=${MetafireFeatures.INSIGHTS}&numberOfYears=1`, {
    method: 'GET',
  });
  const { data: projectDataResponse } = useAppApiRequest(`/projectv2/${params?.id}/basics?NumberOfYears=1`, { method: 'GET' });
  const { data: reportResponse } = useAppApiRequest(`/metafire?projectId=${params?.id}&feature=${MetafireFeatures.REPORT}`, { method: 'GET' });
  useEffect(() => {
    if (insightsResponse) {
      setIntervention(insightsResponse?.data?.data?.data?.interventions);
      setGoals(insightsResponse?.data?.data?.data?.goalsInsights);
      setSustainabilityLevel(insightsResponse?.data?.data?.data?.interventions?.quantities?.overall?.sustainabilityLevel);
    }
  }, [insightsResponse]);
  useEffect(() => {
    if (reportResponse) {
      setReportData(reportResponse?.data?.data?.data);
    }
  }, [reportResponse]);
  useEffect(() => {
    if (projectDataResponse) {
      setProjectData(projectDataResponse?.data?.data?.data);
    }
  }, [projectDataResponse]);
  const { data: assetReponse } = useAppApiRequest(`/sign-s3/${userDetails?.organisation?.payloadId}`, { method: 'GET' }, [userDetails], !!userDetails);
  useEffect(() => {
    if (assetReponse?.data?.data) {
      setAsset(assetReponse?.data?.data?.data);
    }
  }, [assetReponse]);

  return (
    <PDFViewer style={styles.viewer}>
      <Document title={`SD+ Impact Report ${getCurrentDate()}`}>
        <Page size="A4" style={styles.page}>
          <HeadingPage
            reportHeadingColor="#13C6BD"
            pageIcon={SdImpactReport}
            asset={asset}
            reportName={'SD+ Impact'}
            projectname={projectData && projectData.name}
            date={getCurrentDate()}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <SdPlusImpactPageTwo
            sustainabilitypercentage={sustainabilityLevel?.value}
            companyname={projectData?.name}
            projectData={projectData}
            interventionData={intervention}
            goals={goals}
            year={getCurrentDate().split('/')[2]}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <CategoryWiseSummaryPage
            companyname={projectData && projectData.name}
            totalResourceSavings={{
              value: intervention?.categorized?.filter((item) => item.name === 'Energy')[0]?.quantities?.resource?.category?.value,
              unit: 'kWh',
            }}
            interventionData={intervention}
            resourceSavingPercentage={
              intervention?.categorized?.filter((item) => item.name === 'Energy')[0]?.quantities?.resource?.categorySavingPercentage?.value
            }
            category="Energy"
            border="0.5625px solid #9E9E9E"
            color="#F28D14"
            categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_energy')}
            headingIcon={EnergyRounded}
            dummyicon={energyIcon}
            year={getCurrentDate().split('/')[2]}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <CategoryWiseSummaryPage
            companyname={projectData && projectData.name}
            totalResourceSavings={{
              value: intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.resource?.category?.value,
              unit: 'kL',
            }}
            interventionData={intervention}
            resourceSavingPercentage={
              intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.resource?.categorySavingPercentage?.value
            }
            totalLPCDPreSdplus={intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.resource?.totalLPCDPreSdplus?.value}
            totalLPCDPostSdplus={intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.resource?.totalLPCDPostSdplus?.value}
            categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_water')}
            year={getCurrentDate().split('/')[2]}
            projectdata={projectData && projectData}
            category="Water"
            border="0.5625px solid #9E9E9E"
            color="#3BC7EE"
            headingIcon={WaterRounded}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <CategoryWiseSummaryPage
            companyname={projectData && projectData.name}
            totalResourceSavings={{
              value: intervention?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.resource?.category?.value,
              unit: 'kg',
            }}
            interventionData={intervention}
            resourceSavingPercentage={
              intervention?.categorized?.filter((item) => item.name === 'Waste')[0]?.quantities?.resource?.categorySavingPercentage?.value
            }
            categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_waste')}
            year={getCurrentDate().split('/')[2]}
            projectdata={projectData && projectData}
            category="Waste"
            border="0.5625px solid #9E9E9E"
            color="#BC8A5F"
            headingIcon={WasteRounded}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <CategoryWiseSummaryPage
            companyname={projectData && projectData.name}
            totalResourceSavings={{
              value: intervention?.categorized?.filter((item) => item.name === 'Materials')[0]?.quantities?.resource?.category?.value,
              unit: 'tCO2e',
            }}
            interventionData={intervention}
            resourceSavingPercentage={
              intervention?.categorized?.filter((item) => item.name === 'Materials')[0]?.quantities?.resource?.categorySavingPercentage?.value
            }
            categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_materials')}
            year={getCurrentDate().split('/')[2]}
            projectdata={projectData && projectData}
            category="Materials"
            border="0.5625px solid #9E9E9E"
            color="#916AAC"
            headingIcon={MaterialsRounded}
          />
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default SdPlusImpactReport;
