import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Login from '../../../../views/Login';
import RequestDemo from '../RequestDemo';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxHeight: 'auto',
    marginLeft: '11%',
    marginRight: '10%',
    paddingTop: '5%',
    paddingBottom: '2%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  typo: {
    paddingTop: '15px',
    paddingButtom: '15px',
  },
}));

function SignupAndLogin({ login, toggleSignUpLogin, purpose, mobileRequestDemo }) {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={3} square style={{ borderRadius: '10px' }} alignContent="center" alignItems="center">
        <div className={classes.paper}>
          {purpose && purpose === 'White Paper' ? (
            <>
              {!login ? (
                <>
                  {' '}
                  <Typography variant="body2" gutterBottom>
                    Enter details to download
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    whitepapers
                  </Typography>
                </>
              ) : (
                <Typography variant="body2" gutterBottom>
                  Login to SD+
                </Typography>
              )}
            </>
          ) : (
            <Typography variant="body2" gutterBottom>
              {!login ? 'Signup to request a free demo' : 'Login to SD+'}
            </Typography>
          )}
          {!login ? <RequestDemo purpose={purpose && purpose} login={login} /> : <Login />}
          {(purpose && purpose === 'White Paper') || (mobileRequestDemo && mobileRequestDemo === true) ? (
            <Typography variant="body1" gutterBottom className={classes.typo}></Typography>
          ) : (
            <Typography variant="body1" gutterBottom className={classes.typo}>
              {!login ? 'Already a member?' : 'New to SD+?'}{' '}
              <Link onClick={toggleSignUpLogin} style={{ color: '#2EE1AC', textDecoration: 'none' }}>
                {!login ? 'Login here' : ' Request Demo'}{' '}
              </Link>
            </Typography>
          )}
        </div>
      </Grid>
    </>
  );
}
export default SignupAndLogin;
