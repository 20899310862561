import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import { MarketplaceColors } from '../../../config/index';
import { ProductsPageContext } from '../../../../../../routing/IndexRouting';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  getURLStrings,
  constructURlforSourceCategory,
  constructURlforSourceSuperCategory,
  constructURLforSourceEcolabels,
  constructURLforSourceSearch,
  constructURLforSourceIntervention,
} from '../utils/URLParser';
import { useHistory } from 'react-router-dom';
import { NavigatorSource } from '../../../utils/Defaults';

const CustomPagination = withStyles((theme) => ({
  root: {
    '& .Mui-selected': {
      backgroundColor: MarketplaceColors.Primary,
      color: MarketplaceColors.Primary,
    },
    '& .MuiPaginationItem-root': {
      color: MarketplaceColors.Primary,
    },
    '& .MuiPaginationItem-root.Mui-selected': {
      backgroundColor: MarketplaceColors.Primary,
      color: MarketplaceColors.CommonWhite,
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))(Pagination);

export default function Paginator(props) {
  const { URLS, handleURL, appliedfilters, isDefault } = useContext(ProductsPageContext);
  const { page, pagesize } = URLS;
  const { totalproducts } = props;
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [currentpage, setCurrentPage] = useState(Number(page && page));
  let history = useHistory();
  const handlePagination = (event, clickedpage, appliedfilters) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    event.preventDefault();
    setCurrentPage(clickedpage);
    let category = query.get('category');
    let subcategory = query.get('subcategory');
    let brands = query.get('brands');
    let supercategory = query.get('supercategory');
    let page = clickedpage;
    let pagesize = query.get('pagesize');
    let source = query.get('source');
    let filter = query.get('filters');
    let sort = query.get('sort');
    let searchquery = query.get('searchquery');
    let mappingid = query.get('mappingID');
    let productids = query.get('productids');
    let productstatus = query.get('productstatus');
    let projectId = query.get('projectId');
    handleURL(category, subcategory, brands, supercategory, page, pagesize, source, filter, sort, searchquery, mappingid, productids, productstatus);
    if (source === NavigatorSource.categories) {
      const { subcategorystr, brandsstr, filterstr } = getURLStrings(appliedfilters);
      const url = constructURlforSourceCategory(source, category, subcategorystr, brandsstr, filterstr, page, pagesize, false, sort);
      history.push(url);
    } else if (source === NavigatorSource.supercategories) {
      const { subcategorystr, brandsstr, filterstr, categoriesstr } = getURLStrings(appliedfilters);
      const url = constructURlforSourceSuperCategory(source, supercategory, subcategorystr, brandsstr, filterstr, categoriesstr, page, pagesize, sort);
      history.push(url);
    } else if (URLS.source === NavigatorSource.ecolabels) {
      const { subcategorystr, brandsstr, filterstr, categoriesstr, ecolabelstr } = getURLStrings(appliedfilters);
      const url = constructURLforSourceEcolabels(URLS.source, brandsstr, ecolabelstr, page, pagesize, sort);
      history.push(url);
    } else if (URLS.source === NavigatorSource.search) {
      const { subcategorystr, brandsstr, filterstr, categoriesstr, ecolabelstr } = getURLStrings(appliedfilters);
      const url = constructURLforSourceSearch(URLS.source, brandsstr, ecolabelstr, page, pagesize, sort, searchquery);
      history.push(url);
    } else if (URLS.source === NavigatorSource.intervention) {
      const { subcategorystr, brandsstr, filterstr } = getURLStrings(appliedfilters);
      const url = constructURLforSourceIntervention(source, category, subcategorystr, brandsstr, filterstr, page, pagesize, false, sort, mappingid, projectId);
      history.push(url);
    }
  };
  // console.log(currentpage, page, 'totalproducts');
  return (
    <div>
      <CustomPagination
        count={totalproducts && Math.ceil(totalproducts / pagesize)}
        variant="text"
        page={Number(page && page)}
        size="small"
        siblingCount={2}
        boundaryCount={2}
        rowsPerPage={5}
        onChange={(event, clickedpage) => {
          handlePagination(event, clickedpage, appliedfilters);
        }}
      />
    </div>
  );
}
