import React from 'react';
import { Grid, Typography, TextField, InputAdornment, Backdrop, CircularProgress } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import Fields from '../../../../common/textfield/Fields';
import PrimaryButton from '../../../../common/button/Button';
import UploadComponent from '../imageUpload/ImageUpload';
import api from '../../../../../http';
import { useStatusContext } from '../../../../../features/providers/StatusProvider';

export const OrganizationDetails = ({ organisationName, setOrganisationName, classes, organisationId }) => {
  const { showStatus } = useStatusContext();

  const handleUpdateOrganisation = async () => {
    const success = await api.putOrganisation(organisationId, { name: organisationName });
    if (success) showStatus('organisationNameUpdated');
  };

  return (
    <Grid item xs={12} className={classes.card} justifyContent="flex-start">
      <Grid container spacing={3} alignItems="flex-start">
        <UploadComponent organisationId={organisationId} />
        <Grid item xs={12} sm={6} md={3} container justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={12} style={{ paddingBottom: '5%' }}>
            <Typography variant="h6">Organisation Name</Typography>
          </Grid>
          <Grid item xs={8}>
            <Fields
              id="standard-error-helper-text"
              placeholder="Organisation Name"
              value={organisationName}
              onChange={(e) => setOrganisationName(e.target.value)}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6} style={{ marginTop: '5%' }}>
            <PrimaryButton onClick={handleUpdateOrganisation} children="Update" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const UserManagementHeader = ({ classes, renderInviteComponent }) => (
  <Grid container justifyContent="space-between">
    <Grid item xs={10} container justifyContent="flex-start" alignItems="center">
      <Typography variant="h5" className={classes.sectionTitle}>
        User Management
      </Typography>
    </Grid>
    <Grid item xs={2} container justifyContent="flex-end" alignItems="center">
      {renderInviteComponent}
    </Grid>
  </Grid>
);

export const SearchField = ({ setSearchValue, classes, placeholder = 'Search by name' }) => (
  <Grid container spacing={2} alignItems="center">
    <Grid item xs={12} sm={4}>
      <TextField
        className={classes.searchInput}
        variant="outlined"
        size="small"
        placeholder={placeholder}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          ),
        }}
        fullWidth
        onChange={(e) => setSearchValue(e.target.value)}
      />
    </Grid>
  </Grid>
);

export const BackdropLoader = ({ open }) => (
  <Backdrop open={open} style={{ zIndex: 3, color: '#66E3BE' }}>
    <CircularProgress color="inherit" />
  </Backdrop>
);
