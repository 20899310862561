import { Grid } from '@material-ui/core';
import ShowCapexAndDetails from '../components/showCapexAndDetails';
import ShowInterventionCostAssumptionsParameters from '../components/showInterventionCostAssumptionParameters';
import ShowProjectAndConstAssumptionData from '../components/showProjectAndCostAssumptionData';
import ShowConstants from '../components/showConstants';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';

const AdvancedInterventionDetails = (props) => {
  const { state, meta } = useInterventionDetailsContext();
  const { interventionCustomizable, projectCustomizable, constant, multiplierSource, capexMultiplier, VendorcapexMultiplier } = meta;

  return (
    <>
      <Grid container alignItems="flex-start" xs={12} style={{ marginTop: '2%' }}>
        <ShowInterventionCostAssumptionsParameters
          interventionCustomizable={interventionCustomizable}
          myArray={state}
          appendChanged={() => {}} // TODO:
          onClick={props.onClick}
        />
        {multiplierSource && (
          <ShowCapexAndDetails
            source={multiplierSource?.value || null}
            setSource={() => () => {}} // TODO:
            capexMultiplier={capexMultiplier}
            VendorcapexMultiplier={VendorcapexMultiplier}
            multiplierSource={multiplierSource}
            myArray={state}
            appendChanged={() => {}} // TODO:
          />
        )}
        <ShowProjectAndConstAssumptionData
          projectCustomizable={projectCustomizable}
          myArray={state}
          appendChanged={() => {}} // TODO:
          onClick={props.onClick}
        />
        <ShowConstants
          constant={constant}
          myArray={state}
          appendChanged={() => {}} // TODO:
        />
      </Grid>
    </>
  );
};

export default AdvancedInterventionDetails;
