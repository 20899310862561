import React from 'react';
import { Grid, makeStyles, withWidth } from '@material-ui/core';
import CategoryCard from '../../../common/CategoryCard/CategoryCard';
import useCategories from '../../../hooks/useCategoriesData';
import CategoriesSkeletons from '../../../common/Skeletons/CategoriesSkeletons';

const CategoriesGrid = (props) => {
  const dummySkeletonCount = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

  // data
  const { allCategories } = useCategories();
  const useStyles = makeStyles((theme) => ({
    paddingGrid: {
      padding: theme.spacing(0, 0, 3, 0),
    },
  }));

  const classes = useStyles();
  return (
    <Grid container alignItems="center" justifyContent="center" style={{ marginBottom: '5%' }}>
      {!allCategories
        ? dummySkeletonCount.map((ske, index) => <CategoriesSkeletons />)
        : allCategories.map((category, index) => (
            <Grid
              className={classes.paddingGrid}
              key={index}
              container
              direction="row"
              xs={4}
              sm={3}
              md={3}
              lg={2}
              xl={2}
              alignItems="center"
              justifyContent="center"
              style={{ marginTop: '2%', marginLeft: '3%' }}
            >
              <CategoryCard index={index} name={category?.attributes?.name} />
            </Grid>
          ))}
    </Grid>
  );
};

export default withWidth()(CategoriesGrid);
