import { PrimaryFont } from '../../../../config/themeconfig';

export const MarketplaceColors = {
  Primary: '#213162',
  Secondary: '#33518E',
  Background: '#EBF7FF',
  Tertiary: '#1AB8E8',
  BorderCards: '#CCCCCC',
  UIBackground: '#EBF7FF',
  DropdownBorder: '#A7EFEF',
  TagPrimary: '#FFC400',
  TagSecondary: '#FFFAE6',
  TextColorTertiary: 'rgba(0, 0, 0, 0.54)',
  NonselectedCategory: '#9E9E9E',
  TextColorPrimary: '#2E2E2E',
  CommonWhite: '#FFFFFF',
  Breadcrumb: '#666666',
  CommonBlack: '#000',
  error: '#ED5E68',
  steppertextcolor: '#213162',
  checkboxColor: '#66E3BE',
};

export const MarketplaceStyles = {
  sectionPadding: {
    // paddingBottom: '90px',
  },
  gridList: {
    flexWrap: 'nowrap',
    justifyContent: 'center',
    width: '100%',
    transform: 'translateZ(0)',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
};

export const MarketplaceTypography = {
  HeaderLarge: {
    fontFamily: PrimaryFont,
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: 'auto',
    letterSpacing: '0.71px',
  },
  HeaderSmall: {
    fontFamily: PrimaryFont,
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: 'auto',
    letterSpacing: '0.71px',
  },
  BodySmall: {
    fontFamily: PrimaryFont,
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: 'auto',
    letterSpacing: '0%',
  },
  BodyRegular: {
    fontFamily: PrimaryFont,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '32px',
    letterSpacing: '0.71px',
  },
  BodyRegularForTandC: {
    fontFamily: PrimaryFont,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '28px',
    // letterSpacing: '0.71px',
  },
  BodyMedium: {
    fontFamily: PrimaryFont,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: 'auto',
    letterSpacing: '0.71px',
  },
  BodySamiBold: {
    fontFamily: PrimaryFont,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: 'auto',
    letterSpacing: '0%',
  },
  SubHeaderSmall: {
    fontFamily: PrimaryFont,
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: 'auto',
    letterSpacing: '0%',
  },
  SubHeaderMedium: {
    fontFamily: PrimaryFont,
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '17.2px',
    letterSpacing: '0.71px',
  },
  AllCapsSmall: {
    fontFamily: PrimaryFont,
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '20px',
    letterSpacing: '0.71px',
  },
  ChipCapsSmall: {
    fontFamily: PrimaryFont,
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '12px',
    letterSpacing: '0.71px',
  },
  AllCaps: {
    fontFamily: PrimaryFont,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: 'auto',
    letterSpacing: '0.71px',
  },
  CaptionSmall: {
    fontFamily: PrimaryFont,
    fontWeight: 700,
    fontSize: '8px',
    lineHeight: '12.2px',
    letterSpacing: '0%',
  },
  CaptionLarge: {
    fontFamily: PrimaryFont,
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.5px',
  },
  Button: {
    fontFamily: PrimaryFont,
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: 'auto',
    letterSpacing: '1px',
  },
  ButtonSmall: {
    fontFamily: PrimaryFont,
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: 'auto',
    letterSpacing: '1px',
  },
  Link: {
    fontFamily: PrimaryFont,
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: 'auto',
    letterSpacing: '0.71px',
  },
  PageHeader: {
    fontFamily: 'Lato',
    fontSize: '30px',
    fontWeight: 700,
    lineHeight: '36px',
    letterSpacing: '1.0714285373687744px',
  },
  ButtonCaps: {
    fontFamily: 'Lato',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '15.6px',
    letterSpacing: '0.071px',
  },
  PageHeading: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    letterSpacing: ' 0.857px',
  },
  Heading3: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '28.8px',
    letterSpacing: ' 0.86px',
  },
};
