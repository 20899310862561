// Status data configuration object
import React from 'react';
import Typography from '@material-ui/core/Typography';
import PrimaryButton from '../../../../common/button/Button';
import { Backdrop, Fade, Grid, Modal } from '@material-ui/core';

// Button component for status modal
export const StatusButton = ({ button, handleButtonClick, classes, statusCode }) => {
  const { label, color } = button;

  const handleClick = () => {
    const extraParam = label === 'OK' ? statusCode : null;
    handleButtonClick(label, extraParam);
  };

  if (label === 'Cancel') {
    return (
      <Typography variant="h6" className={classes.text} style={{ cursor: 'pointer' }} color={color} onClick={handleClick}>
        {label}
      </Typography>
    );
  }

  return (
    <PrimaryButton variant="contained" color={color} className={classes.button} onClick={handleClick}>
      {label}
    </PrimaryButton>
  );
};

// Button container
export const ButtonContainer = ({ status, handleButtonClick, classes, statusCode }) => (
  <div className={classes.buttonContainer}>
    <Grid container justifyContent="center" alignItems="center">
      {status.buttons.map((button, index) => (
        <Grid
          key={index}
          item
          xs={button.label === 'Cancel' ? 8 : 4}
          container
          justifyContent={button.label === 'Cancel' ? 'flex-end' : 'center'}
          alignItems="center"
          style={button.label === 'Cancel' ? { paddingRight: 70, marginTop: '4%' } : null}
        >
          <StatusButton button={button} handleButtonClick={handleButtonClick} classes={classes} statusCode={statusCode} />
        </Grid>
      ))}
    </Grid>
  </div>
);

export const CustomModal = ({ children, open, onClose, className }) => (
  <Modal
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    className={className}
    open={open}
    onClose={onClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{ timeout: 500 }}
  >
    <Fade in={open}>{children}</Fade>
  </Modal>
);

export const Status = ({ status, handleButtonClick, classes, statusCode }) => (
  <div className={classes.container}>
    <Typography variant="h5" className={classes.title}>
      <b>{status.title}</b>
    </Typography>

    <div className={classes.icon}>{status.icon}</div>

    <Typography variant="h6" className={classes.text}>
      {status.text}
    </Typography>

    <ButtonContainer status={status} handleButtonClick={handleButtonClick} classes={classes} statusCode={statusCode} />
  </div>
);
