import React from 'react';
import { Box, Typography, Grid, Card, CardContent, IconButton, Link, makeStyles } from '@material-ui/core';
import Header from '../../common/header/Header';
import { MarketplaceTypography } from '../marketplace/config';
import HeaderAndBackIcon from '../marketplace/common/Header/HeaderAndBackIcon';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import FaqIcon from '../../../assets/FaqIcon.svg';
import GarbageTruckIcon from '../../../assets/GarbageTruckIcon.svg';
import TagClickIcon from '../../../assets/TagClickIcon.svg';
import VideoPlayerStreamingIcon from '../../../assets/VideoPlayerStreamingIcon.svg';
import { useAppProductTour } from '../../../hooks/useAppProductTour';

const Help = () => {
  const { state: tourState, handleStart } = useAppProductTour();
  const useStyles = makeStyles((theme) => ({
    boxText: {
      fontFamily: 'Lato',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.71px',
      textAlign: 'center',
      color: '#666666',
      marginTop: '2%',
      marginBottom: '2%',
    },
    titleText: {
      fontFamily: 'Lato',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '100%',
      letterSpacing: '0.71px',
      textAlign: 'center',
      marginTop: '12%',
      marginBottom: '6%',
    },
    fontTextBold: {
      fontFamily: 'Lato',
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '100%',
      letterSpacing: '0.7142857313156128px',
    },
    fontText: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '32px',
      letterSpacing: '0.714286px',
      color: '#000000',
      fontFamily: 'Lato',
    },
    fontTextTwo: {
      fontFamily: 'Lato',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '17px',
      letterSpacing: '0.7142857313156128px',
      padding: '0px 14px 14px 0px',
    },
  }));
  const history = useHistory();

  const classes = useStyles();

  const handleTourStart = async () => {
    if (tourState.tourActive) {
      await handleStart();
      history.push('/projects');
    }
  };

  return (
    <div>
      <Header />
      <div style={{ marginTop: '4%', display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '80vw', marginLeft: '10%', marginTop: '1%' }}>
          <HeaderAndBackIcon
            headingStyle={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '40px', lineHeight: '48px', letterSpacing: '1.43px' }}
            heading={'Help'}
            arrowColor={'#26E0A3'}
            variant={'h4'}
            style={MarketplaceTypography.PageHeading}
            iconHeight={'40px'}
            iconWidth={'40px'}
            bold={true}
            handleClick={() => history.goBack()}
            hideArrowBack={false}
          />
          <div style={{ margin: '32px 0' }}>
            <Typography className={classes.fontText}>
              This section addresses common questions you may have about interventions, mandates or calculations to help you get the most out of SD+. If you
              don't find what you're looking for here, feel free to contact us at <span style={{ color: '#26E0A3' }}>connect@sdplus.io</span>
            </Typography>
          </div>
          <Grid
            container
            style={{
              flexGrow: 1,
            }}
            spacing={3}
          >
            <Grid
              item
              md={3}
              onClick={() => {
                history.push('/faq');
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  borderRadius: '8px',
                  color: '#26E0A3',
                  border: '1px solid #26E0A3',
                  cursor: 'pointer',
                  cursor: 'pointer',
                }}
              >
                <img style={{ height: '56px', width: '56px', marginTop: '8%' }} src={FaqIcon} alt="FAQ Icon" />
                <Typography className={classes.titleText} style={{ color: '#26E0A3' }}>
                  FAQs
                </Typography>
                <Typography className={classes.boxText}>Platform & Intervention</Typography>
                <Typography className={classes.boxText}>Questions Answered</Typography>
              </div>
            </Grid>
            <Grid
              item
              md={3}
              onClick={() => {
                history.push('/marketplace/resources');
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  borderRadius: '8px',
                  color: '#26E0A3',
                  border: '1px solid #0390B4',
                  cursor: 'pointer',
                }}
              >
                <img style={{ height: '56px', width: '56px', marginTop: '8%' }} src={GarbageTruckIcon} alt="Garbage Truck Icon" />
                <Typography className={classes.titleText} style={{ color: '#0390B4' }}>
                  Material Selection Guide
                </Typography>
                <Typography className={classes.boxText}>Comprehensive guides to help</Typography>
                <Typography className={classes.boxText}>Choose the best materials</Typography>
              </div>
            </Grid>
            <Grid item md={3} onClick={handleTourStart}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  borderRadius: '8px',
                  color: '#26E0A3',
                  border: '1px solid #F38D15',
                  cursor: 'pointer',
                }}
              >
                <img style={{ height: '56px', width: '56px', marginTop: '8%' }} src={TagClickIcon} alt="Garbage Truck Icon" />
                <Typography className={classes.titleText} style={{ color: '#F38D15' }}>
                  Product Tour
                </Typography>
                <Typography className={classes.boxText}>A guided walktrough of SD+</Typography>
                <Typography className={classes.boxText}>covering all the basic</Typography>
              </div>
            </Grid>
            <Grid item md={3}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  borderRadius: '8px',
                  color: '#26E0A3',
                  border: '1px solid #9E9E9E',
                }}
              >
                <img style={{ height: '56px', width: '56px', marginTop: '8%' }} src={VideoPlayerStreamingIcon} alt="Garbage Truck Icon" />
                <Typography className={classes.titleText} style={{ color: '#9E9E9E' }}>
                  SD+ Guide
                </Typography>
                <Typography className={classes.boxText} style={{ color: '#9E9E9E' }}>
                  Explainer video to help you get
                </Typography>
                <Typography className={classes.boxText} style={{ color: '#9E9E9E' }}>
                  the best of SD+
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Help;
