import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import Header from './Header';
import Footer from './Footer';
import energyIcon from '../../../../../assets/new-pdf/energyIcon.png';
import WaterIcon from '../../../../../assets/new-pdf/WaterIcon.png';
import WasteIcon from '../../../../../assets/new-pdf/WasteIcon.png';
import MaterialsIcon from '../../../../../assets/new-pdf/MaterialsIcon.png';
import numberWithCommas from '../../../../../utils/numberToComma';
import { getCurrentDate } from './ReportUtils';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },

  viewPage2: {
    margin: '10px 20px 20px 20px',
    padding: 10,
    flexGrow: 1,
  },
  pageCover: {
    height: '100%',
    width: '521px',
    paddingTop: '20px',
  },
  footerMargin: {
    padding: 10,
  },
});

const EnvironmentalImpactPage = (props) => {
  const energySaving = props.energy && props.energy[0]?.quantities.resource.category.value;
  const waterSaving = props.water && props.water[0]?.quantities.resource.category.value;
  const wasteSaving = props.waste && props.waste[0]?.quantities.resource.category.value;
  const materialsSaving = props.materials && props.materials[0]?.quantities.resource.category.value;
  const energySavingConverted = props.energy && props.energy[0]?.quantities.resource.categoryConverted.value;
  const waterSavingConverted = props.water && props.water[0]?.quantities.resource.categoryConverted.value;
  const wasteSavingConverted = props.waste && props.waste[0]?.quantities.resource.categoryConverted.value;
  const materialsSavingConverted = props.materials && props.materials[0]?.quantities.resource.categoryConverted.value;
  const energyPercentage = props.energy && props.energy[0]?.quantities.resource.categorySavingPercentage.value;
  const waterPercentage = props.water && props.water[0]?.quantities.resource.categorySavingPercentage.value;
  const wastePercentage = props.waste && props.waste[0]?.quantities.resource.categorySavingPercentage.value;
  const materialsPercentage = props.materials && props.materials[0]?.quantities.resource.categorySavingPercentage.value;
  return (
    <View style={styles.viewPage2}>
      <View style={{ marginTop: '2%' }}>
        <Header companyname={props.companyname} />
      </View>
      {/* new view */}
      <View style={{ flexDirection: 'row', display: 'flex', paddingTop: '10px' }}>
        <View>
          <Text style={{ fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '30px', textTransform: 'uppercase' }}>Environmental Impact</Text>
        </View>
      </View>
      {/* new view */}
      <View style={{ backgroundColor: '#FFF4D5', height: '50px', marginTop: '20px' }}>
        <View style={{ flexDirection: 'row', display: 'flex' }}>
          <View style={{ width: '6%' }} />
          <View style={{ width: '10%' }}>
            <Image src={energyIcon} style={{ height: '30px', width: '30px', top: 10 }} />
          </View>
          {/* <View style={{ width: '%' }} /> */}
          <View>
            <Text
              style={{
                fontFamily: 'Lato',
                fontWeight: 'Bold',
                fontSize: '20px',
                textTransform: 'uppercase',
                top: 12,
              }}
            >
              ENERGY
            </Text>
          </View>
        </View>
      </View>
      <View style={{ flexDirection: 'row', display: 'flex', marginTop: '20px' }}>
        <View style={{ width: '5%' }} />
        <View style={{ width: '60%', flexDirection: 'row', display: 'flex' }}>
          <View>
            <Text
              style={{
                fontFamily: 'Lato',
                fontWeight: 'Bold',
                fontSize: '30px',
              }}
            >
              {numberWithCommas(Math.round(energySaving))}
            </Text>
          </View>
          <View>
            <Text
              style={{
                fontFamily: 'Lato',
                //   fontWeight: 'Bold',
                fontSize: '20px',
                paddingTop: '8px',
                paddingLeft: '15px',
                // opacity: '0.5',
                color: 'grey',
              }}
            >
              kWh
            </Text>
          </View>
        </View>
        <View style={{ width: '25%' }}>
          <Text style={{ fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '30px' }}>{`${energyPercentage ? Math.round(energyPercentage) : 0} %`}</Text>
        </View>
      </View>
      {/* new view */}
      <View style={{ flexDirection: 'row', display: 'flex', marginTop: '15px' }}>
        <View style={{ width: '5%' }} />
        <View style={{ width: '60%' }}>
          <Text style={{ fontFamily: 'Lato', fontSize: '12px', color: 'grey' }}>
            {`equivalent to powering ${numberWithCommas(energySavingConverted)} homes`}{' '}
          </Text>
        </View>
        <View style={{ width: '25%' }}>
          <Text style={{ fontFamily: 'Lato', fontSize: '12px', color: 'grey' }}>Energy saved annually</Text>
        </View>
      </View>

      {/* new view */}
      <View style={{ backgroundColor: '#CFF2FA', height: '50px', marginTop: '20px' }}>
        <View style={{ flexDirection: 'row', display: 'flex' }}>
          <View style={{ width: '6%' }} />
          <View style={{ width: '10%' }}>
            <Image src={WaterIcon} style={{ height: '30px', width: '30px', top: 10 }} />
          </View>
          {/* <View style={{ width: '%' }} /> */}
          <View>
            <Text
              style={{
                fontFamily: 'Lato',
                fontWeight: 'Bold',
                fontSize: '20px',
                textTransform: 'uppercase',
                top: 12,
              }}
            >
              Water
            </Text>
          </View>
        </View>
      </View>
      <View style={{ flexDirection: 'row', display: 'flex', marginTop: '20px' }}>
        <View style={{ width: '5%' }} />
        <View style={{ width: '60%', flexDirection: 'row', display: 'flex' }}>
          <View>
            <Text
              style={{
                fontFamily: 'Lato',
                fontWeight: 'Bold',
                fontSize: '30px',
              }}
            >
              {numberWithCommas(Math.round(waterSaving))}
            </Text>
          </View>
          <View>
            <Text
              style={{
                fontFamily: 'Lato',
                //   fontWeight: 'Bold',
                fontSize: '20px',
                paddingTop: '8px',
                paddingLeft: '15px',
                // opacity: '0.5',
                color: 'grey',
              }}
            >
              KL
            </Text>
          </View>
        </View>
        <View style={{ width: '25%' }}>
          <Text style={{ fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '30px' }}>{`${waterPercentage ? Math.round(waterPercentage) : 0} %`}</Text>
        </View>
      </View>
      {/* new view */}
      <View style={{ flexDirection: 'row', display: 'flex', marginTop: '15px' }}>
        <View style={{ width: '5%' }} />
        <View style={{ width: '60%' }}>
          <Text style={{ fontFamily: 'Lato', fontSize: '12px', color: 'grey' }}>{`saved ${numberWithCommas(
            waterSavingConverted
          )} tankers of water a year`}</Text>
        </View>
        <View style={{ width: '25%' }}>
          <Text style={{ fontFamily: 'Lato', fontSize: '12px', color: 'grey' }}>Water saved annually</Text>
        </View>
      </View>
      {/* new view */}
      <View style={{ backgroundColor: 'rgba(188, 138, 95, 0.3)', height: '50px', marginTop: '20px' }}>
        <View style={{ flexDirection: 'row', display: 'flex' }}>
          <View style={{ width: '6%' }} />
          <View style={{ width: '10%' }}>
            <Image src={WasteIcon} style={{ height: '30px', width: '30px', top: 10 }} />
          </View>
          {/* <View style={{ width: '%' }} /> */}
          <View>
            <Text
              style={{
                fontFamily: 'Lato',
                fontWeight: 'Bold',
                fontSize: '20px',
                textTransform: 'uppercase',
                top: 12,
              }}
            >
              Waste
            </Text>
          </View>
        </View>
      </View>
      <View style={{ flexDirection: 'row', display: 'flex', marginTop: '20px' }}>
        <View style={{ width: '5%' }} />
        <View style={{ width: '60%', flexDirection: 'row', display: 'flex' }}>
          <View>
            <Text
              style={{
                fontFamily: 'Lato',
                fontWeight: 'Bold',
                fontSize: '30px',
              }}
            >
              {numberWithCommas(Math.round(wasteSaving))}
            </Text>
          </View>
          <View>
            <Text
              style={{
                fontFamily: 'Lato',
                //   fontWeight: 'Bold',
                fontSize: '20px',
                paddingTop: '8px',
                paddingLeft: '15px',
                // opacity: '0.5',
                color: 'grey',
              }}
            >
              kg
            </Text>
          </View>
        </View>
        <View style={{ width: '25%' }}>
          <Text style={{ fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '30px' }}>{`${wastePercentage ? Math.round(wastePercentage) : 0} %`}</Text>
        </View>
      </View>
      {/* new view */}
      <View style={{ flexDirection: 'row', display: 'flex', marginTop: '15px' }}>
        <View style={{ width: '5%' }} />
        <View style={{ width: '60%' }}>
          <Text style={{ fontFamily: 'Lato', fontSize: '12px', color: 'grey' }}>
            {`equivalent to ${numberWithCommas(Math.round(wasteSavingConverted))} trucks of waste`}{' '}
          </Text>
        </View>
        <View style={{ width: '25%' }}>
          <Text style={{ fontFamily: 'Lato', fontSize: '12px', color: 'grey' }}>Waste avoided annually</Text>
        </View>
      </View>
      {/* {} */}
      <View style={{ backgroundColor: '#EDCDFF', height: '50px', marginTop: '20px' }}>
        <View style={{ flexDirection: 'row', display: 'flex' }}>
          <View style={{ width: '6%' }} />
          <View style={{ width: '10%' }}>
            <Image src={MaterialsIcon} style={{ height: '30px', width: '35px', top: 10 }} />
          </View>
          {/* <View style={{ width: '%' }} /> */}
          <View>
            <Text
              style={{
                fontFamily: 'Lato',
                fontWeight: 'Bold',
                fontSize: '20px',
                textTransform: 'uppercase',
                top: 12,
              }}
            >
              Materials
            </Text>
          </View>
        </View>
      </View>
      <View style={{ flexDirection: 'row', display: 'flex', marginTop: '20px' }}>
        <View style={{ width: '5%' }} />
        <View style={{ width: '60%', flexDirection: 'row', display: 'flex' }}>
          <View>
            <Text
              style={{
                fontFamily: 'Lato',
                fontWeight: 'Bold',
                fontSize: '30px',
              }}
            >
              {numberWithCommas(Math.round(materialsSaving))}
            </Text>
          </View>
          <View>
            <Text
              style={{
                fontFamily: 'Lato',
                //   fontWeight: 'Bold',
                fontSize: '20px',
                paddingTop: '8px',
                paddingLeft: '15px',
                // opacity: '0.5',
                color: 'grey',
              }}
            >
              tCO2e
            </Text>
          </View>
        </View>
        <View style={{ width: '25%' }}>
          <Text style={{ fontFamily: 'Lato', fontWeight: 'Bold', fontSize: '30px' }}>{`${materialsPercentage ? Math.round(materialsPercentage) : 0} %`}</Text>
        </View>
      </View>
      {/* new view */}
      <View style={{ flexDirection: 'row', display: 'flex', marginTop: '15px' }}>
        <View style={{ width: '5%' }} />
        <View style={{ width: '60%' }}>
          <Text style={{ fontFamily: 'Lato', fontSize: '12px', color: 'grey' }}>
            {`equivalent to ${numberWithCommas(Math.round(materialsSavingConverted))} cars off the road for a year`}{' '}
          </Text>
        </View>
        <View style={{ width: '25%' }}>
          <Text style={{ fontFamily: 'Lato', fontSize: '12px', color: 'grey' }}>Reduction in carbon footprint</Text>
        </View>
      </View>
      <View fixed style={{ position: 'absolute', left: 20, top: 800, right: 20 }}>
        <Footer year={getCurrentDate().split('/')[2]} />
      </View>
    </View>
  );
};

export default EnvironmentalImpactPage;
