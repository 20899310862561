import React, { useEffect, useState, useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { normalizeFileName } from '../interventions/intervention/utils';
import AddFileForCustomIntervention from './files/components/AddFileForCustomIntervention';
import Addlink from './files/components/Addlink';
import { UserContext } from '../../../routing/IndexRouting';
import { useParams } from 'react-router';
import swal from 'sweetalert';
import cookie from 'react-cookies';
import useAppProjectDetails from './hooks/useAppProjectDetails';
import { useAppApiRequest } from '../../../hooks/useAppApiRequest';
import api from '../../../http';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: 600,
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
}));

const AttachFileForCustomIntervention = ({
  link,
  setLink,
  updateState,
  handleUpdateCustomintervention,
  customInterventionFileNames,
  customInterventionId,
  triggerFileSave,
  triggerFileDelete,
  projectID,
  setModelOpen,
  handleRefresh,
  handleOpenBackdrop,
  createOrEdit,
  setIsCreateUpdateDisabled,
}) => {
  const classes = useStyles();
  const [fileLink, setFileLink] = useState('');
  const [projectFile, setProjectFile] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [allExistingFileNames, setAllExistingFileNames] = useState([]);
  const [isSelectedFilePresent, setIsSelectedFilePresent] = useState();
  const [deleteFile, setDeleteFile] = useState(false);
  const { userDetails } = useContext(UserContext);
  const [fileToDeleteLocally, setFileToDeleteLocally] = useState([]);
  const [isFilePresentLocally, setIsFilePresentLocally] = useState(false);
  let { id } = useParams();

  const { data: projectData } = useAppProjectDetails(id);

  const { data: projectFiles, isError: isErrorData, isFetching: isFetchingData } = useAppApiRequest(`/project-asset?project=${id}`, { method: 'GET' });
  useEffect(() => {
    if (projectFiles && !isErrorData && !isFetchingData) {
      setUploadedFiles(projectFiles?.data?.data?.data);
    }
  }, [projectFiles, isErrorData, isFetchingData]);

  const [errors, setErrors] = useState({
    sizeError: false,
    fileError: false,
    uploadError: false,
    duplicate: false,
    startUpload: false,
    isSuccess: false,
  });

  const resetOnClick = () => {
    const myElement = document.getElementById('upload-photo');
    myElement.value = null;
  };

  useEffect(() => {
    setAllExistingFileNames((prevFiles) => prevFiles.filter((file) => !fileToDeleteLocally.includes(file)));
  }, [fileToDeleteLocally]);
  const handleUploadClick = (event) => {
    const files = event.target.files;
    const validFiles = [];
    let hasError = false;
    for (const file of files) {
      const size = (file.size / 1024 / 1024).toFixed(2);
      const type = file.name.split('.').pop().toLowerCase();
      const existingFileNames = (selectedFile || []).map((file) => normalizeFileName(file.name));
      setAllExistingFileNames([...existingFileNames, ...customInterventionFileNames, file.name]);
      if (size > 50) {
        hasError = true;
        setErrors((prev) => ({ ...prev, sizeError: true }));
      } else if (!['rvt', 'dwg', 'skp', 'doc', 'docx', 'xlsx', 'xls', 'pdf'].includes(type)) {
        hasError = true;
        setErrors((prev) => ({ ...prev, fileError: true }));
      } else if (allExistingFileNames.includes(normalizeFileName(file.name)) && !fileToDeleteLocally.includes(normalizeFileName(file.name))) {
        setErrors((prev) => ({ ...prev, duplicate: true }));
      } else {
        if (!errors.duplicate) {
          validFiles.push(file);
          setFileToDeleteLocally((prevFilesToDelete) => prevFilesToDelete.filter((fileName) => fileName !== normalizeFileName(file.name)));
        }
      }
    }
    if (!hasError && !errors.duplicate) {
      setSelectedFile((prevFiles) => {
        return [...prevFiles.filter((file) => !fileToDeleteLocally.includes(normalizeFileName(file.name))), ...validFiles];
      });
    }
  };

  useEffect(() => {
    const existingFileNames = (selectedFile || []).map((file) => normalizeFileName(file.name));
    setAllExistingFileNames([...existingFileNames, ...customInterventionFileNames]);
    setIsSelectedFilePresent(selectedFile?.[0] || null);
  }, [selectedFile]);

  useEffect(() => {
    if (triggerFileSave) handleSave();
  }, [triggerFileSave]);

  useEffect(() => {
    if (updateState) setFileLink(link);
  }, [updateState]);
  useEffect(() => {
    if (triggerFileDelete) handleDeleteAll();
  }, [triggerFileDelete]);
  useEffect(() => {
    setLink(fileLink);
  }, [fileLink]);

  const handleSave = () => {
    if (!selectedFile.some((file) => fileToDeleteLocally.includes(normalizeFileName(file.name)))) {
      if (selectedFile.length > 0 && !errors.isSuccess && !errors.duplicate) {
        setErrors((prev) => ({ ...prev, startUpload: true }));
        Promise.all(
          selectedFile.map((file) => {
            const filearr = file.name.split('.');
            const type = filearr[filearr.length - 1];
            const fileName = filearr[0];
            const body = {
              projectID: id,
              createdBy: userDetails && userDetails._id,
              name: fileName,
              type: type,
              customInterventionId: customInterventionId,
              organisationId: projectData.organisation,
            };
            return api.getFileUploadUrl(body).then(async (res) => {
              if (res) {
                await api.putUploadToS3(res.url, file).then(async (uploadSuccessful) => {
                  if (uploadSuccessful) {
                    setErrors((prev) => ({ ...prev, startUpload: false, isSuccess: true }));
                  } else {
                    setErrors((prev) => ({ ...prev, uploadError: true, startUpload: false }));
                  }
                });
              }
            });
          })
        ).then(() => {
          setModelOpen(false);
          if (createOrEdit === 'EDIT') {
            swal({ icon: 'success', title: 'Custom Intervention Updated' });
          }
          if (createOrEdit === 'CREATE') {
            swal({ icon: 'success', title: 'Data Saved' });
          }
          handleRefresh();
          handleOpenBackdrop(true);
          setIsCreateUpdateDisabled(false);
        });
      }
      if (selectedFile.length === 0) {
        setModelOpen(false);
        if (createOrEdit === 'EDIT') {
          swal({ icon: 'success', title: 'Custom Intervention Updated' });
        }
        if (createOrEdit === 'CREATE') {
          swal({ icon: 'success', title: 'Data Saved' });
        }
        handleRefresh();
        handleOpenBackdrop(true);
        setIsCreateUpdateDisabled(false);
      }
    }
  };

  const handleOpenFile = (fileName) => {
    api.getOpenFileUrl(projectData.organisation, id, customInterventionId, fileName).then((url) => {
      if (url) {
        window.open(url);
      }
    });
  };
  const handleDeleteAll = () => {
    Promise.all(
      fileToDeleteLocally.forEach(async (fileName) => {
        if (customInterventionFileNames.includes(fileName)) {
          const fileKey = `${projectData.organisation}/project/${id}/custom-interventions/${customInterventionId}/${fileName}`;
          await api.deleteFile(projectData.organisation, id, customInterventionId, fileName).then(async () => {
            const matchingObject = uploadedFiles.find((item) => item.staticAssetID?.URL.includes(fileKey));
            if (matchingObject) {
              await api.deleteProjectAsset(matchingObject._id);
            }
          });
        }
      })
    );
  };
  return (
    <div className={classes.container}>
      <Grid container>
        <AddFileForCustomIntervention
          updateState={updateState}
          customInterventionFileNames={customInterventionFileNames && customInterventionFileNames}
          uploadError={errors.uploadError}
          selectedFile={selectedFile && selectedFile}
          sizeError={errors.sizeError}
          fileError={errors.fileError}
          duplicate={errors.duplicate}
          filename={true}
          fileToDeleteLocally={fileToDeleteLocally}
          fileLink={fileLink && fileLink}
          startUpload={errors.startUpload}
          isSuccess={errors.isSuccess}
          handleUploadClick={handleUploadClick}
          handleOpenFile={handleOpenFile}
          setDuplicate={(v) => setErrors((prev) => ({ ...prev, duplicate: v }))}
          setSizeError={(v) => setErrors((prev) => ({ ...prev, sizeError: v }))}
          setFileError={(v) => setErrors((prev) => ({ ...prev, fileError: v }))}
          setUploadError={(v) => setErrors((prev) => ({ ...prev, uploadError: v }))}
          setDeleteFile={setDeleteFile}
          setFileToDeleteLocally={setFileToDeleteLocally}
          setIsFilePresentLocally={setIsFilePresentLocally}
          resetOnClick={resetOnClick}
        />
        <Addlink
          selectedFile={isFilePresentLocally && isFilePresentLocally}
          filename={true}
          fileLink={fileLink && fileLink}
          projectFile={projectFile && projectFile}
          setduplicate={(v) => setErrors((prev) => ({ ...prev, duplicate: v }))}
          setStartUpload={(v) => setErrors((prev) => ({ ...prev, startUpload: v }))}
          setFileLink={(v) => setFileLink(v)}
        />
      </Grid>
    </div>
  );
};

export default AttachFileForCustomIntervention;
