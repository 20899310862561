import { jwtDecode } from 'jwt-decode';

export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isValid = emailRegex.test(email) && email.trim() !== '';
  return isValid;
};

export const validatePayload = (payload) => {
  if (!payload) throw new Error('Invalid invitation code');

  const decodedPayload = jwtDecode(payload);

  if (!decodedPayload || !decodedPayload.exp) {
    throw new Error('Invalid invitation code');
  }

  const isExpired = Date.now() > decodedPayload.exp * 1000;

  if (isExpired) throw new Error('Expired invitation code');

  return decodedPayload;
};
