const queryKeys = {
  projectsList: 'projects-list',
  userDetails: 'user-details',
  userCurrentProject: 'user-current-project',
  metafireInsights: 'metafire-insights',
  metafireInterventions: 'metafire-interventions',
  metafireInterventionsFeature: 'metafire-interventions-feature',
  metafireFlowDiagrams: 'metafire-flow-diagrams',
  metafireGoals: 'metafire-goals',
  metafireInterventionSets: 'metafire-intervention-sets',
  createMetafireGoals: 'create-metafire-goals',
  projectBasics: 'project-basics',
  projectTargets: 'project-targets',
  createProjectTargets: 'create-project-targets',
  goalsScheme: 'goals-scheme',
  igbcData: 'igbc-data',
  igbcCategory: 'igbc-category',
  pipSchemeV2: 'pip-scheme-v2',
  pipMapping: 'pip-mapping',
  organisationsSchemeV2: 'organisations-scheme-v2',
  projectIntervention: 'project-intervention',
  intervention: 'intervention',
  projectInterventionMapping: 'project-intervention-mapping',
};

export default queryKeys;
