import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ResetFields from '../common/textFiledwithReset';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';

const HWMVillaCustomBasicParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();

  const optionforswhVilla = getQuantityValue(schema.hwmVilla__pi_option);

  const handleHwmVillaOption = (option) => {
    updateQuantityValue(schema.hwmVilla__pi_option, option);
    updateQuantityValue(schema.hwmVilla__pi_selectedProductIdentifier, null);
  };

  const handleSWhType = (type) => {
    updateQuantityValue(schema.hwmVilla__pi_swhType, type);
  };

  const handleSWhTechnology = (technology) => {
    updateQuantityValue(schema.hwmVilla__pi_swhVariant, technology);
  };

  const handleHpType = (type) => {
    updateQuantityValue(schema.hwmVilla__pi_hpType, type);
  };

  const handleHpTemp = (temp) => {
    updateQuantityValue(schema.hwmVilla__pi_hpHeatingSpeed, temp);
  };

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid container direction="column" alignItems="left" item xs={12}>
        <Grid container item xs={12}>
          <Grid item xs={4}>
            <Typography className={classes.textHeader1}>
              {optionforswhVilla === 'solar water heater' ? (
                <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => handleHwmVillaOption('solar water heater')} />
              ) : (
                <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => handleHwmVillaOption('solar water heater')} />
              )}
              Solar Water Heater
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.textHeader1}>
              {optionforswhVilla === 'heat pump' ? (
                <CheckCircleIcon className={classes.selectedCheckbox} onClick={() => handleHwmVillaOption('heat pump')} />
              ) : (
                <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => handleHwmVillaOption('heat pump')} />
              )}
              Heat Pump
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {optionforswhVilla === 'solar water heater' && (
        <>
          <Grid item xs={4} container alignItems="flex-start" direction="column" style={{ marginTop: '3%' }}>
            <Grid xs>
              <Typography className={classes.textheader2}>{getQuantityLabel(schema.hwmVilla__pi_userSwhCapacity)}</Typography>
            </Grid>
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  value={getQuantityValue(schema.hwmVilla__pi_userSwhCapacity)}
                  placeholder=""
                  onChange={(e) => {
                    e.preventDefault();
                    updateQuantityValue(schema.hwmVilla__pi_userSwhCapacity, Number(e.target.value));
                  }}
                  fullWidth
                  required
                  onClick={() => updateQuantityValue(schema.hwmVilla__pi_userSwhCapacity, getQuantityDefaultValue(schema.hwmVilla__pi_userSwhCapacity))}
                  disabled={false}
                />
              </Grid>
              <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">{getQuantityUnit(schema.hwmVilla__pi_userSwhCapacity)}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} container alignItems="flex-start">
            <Grid item xs={6}>
              <Typography className={classes.textheader2}>Type</Typography>
              <Typography className={classes.textHeader5}>
                {<RadioButtonUncheckedIcon className={classes.nonselectedRadioboxdisabled} />}
                Central
              </Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.hwmVilla__pi_swhType) === 'individual' && (
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleSWhType('individual')} />
                )}
                {getQuantityValue(schema.hwmVilla__pi_swhType) !== 'individual' && (
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhType('individual')} />
                )}
                Individual
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.textheader2}>Technology</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.hwmVilla__pi_swhVariant) === 'etc' && (
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleSWhTechnology('etc')} />
                )}
                {getQuantityValue(schema.hwmVilla__pi_swhVariant) !== 'etc' && (
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhTechnology('etc')} />
                )}
                ETC
                <CustomizedTooltips
                  icon="info"
                  title={<Typography>ETC (Evacuated Tube Collector): Employs glass tubes with absorber coatings. </Typography>}
                  placement="right"
                />
              </Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.hwmVilla__pi_swhVariant) === 'fpc' && (
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleSWhTechnology('fpc')} />
                )}
                {getQuantityValue(schema.hwmVilla__pi_swhVariant) !== 'fpc' && (
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhTechnology('fpc')} />
                )}
                FPC
                <CustomizedTooltips
                  icon="info"
                  title={<Typography>FPC (Flat Plate Collector): Utilizes a flat, rectangular panel to absorb sunlight.</Typography>}
                  placement="right"
                />
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      {optionforswhVilla === 'heat pump' && (
        <>
          <Grid item xs={4} container alignItems="flex-start" direction="column" style={{ marginTop: '3%' }}>
            <Grid xs>
              <Typography className={classes.textheader2}>{getQuantityLabel(schema.hwmVilla__pi_userHpCapacity)}</Typography>
            </Grid>
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  value={getQuantityValue(schema.hwmVilla__pi_userHpCapacity)}
                  placeholder=""
                  onChange={(e) => {
                    e.preventDefault();
                    updateQuantityValue(schema.hwmVilla__pi_userHpCapacity, Number(e.target.value));
                  }}
                  fullWidth
                  required
                  onClick={() => updateQuantityValue(schema.hwmVilla__pi_userHpCapacity, getQuantityDefaultValue(schema.hwmVilla__pi_userHpCapacity))}
                  disabled={false}
                />
              </Grid>
              <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">{getQuantityUnit(schema.hwmVilla__pi_userHpCapacity)}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} container alignItems="flex-start">
            <Grid item xs={6}>
              <Typography className={classes.textheader2}>Type</Typography>
              <Typography className={classes.textHeader5}>{<RadioButtonUncheckedIcon className={classes.nonselectedRadioboxdisabled} />}Commercial</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.hwmVilla__pi_hpType) === 'domestic' && (
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHpType('domestic')} />
                )}
                {getQuantityValue(schema.hwmVilla__pi_hpType) !== 'domestic' && (
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpType('domestic')} />
                )}
                Domestic
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.textheader2}>Heating</Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.hwmVilla__pi_hpHeatingSpeed) === 'slow' && (
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHpTemp('slow')} />
                )}
                {getQuantityValue(schema.hwmVilla__pi_hpHeatingSpeed) !== 'slow' && (
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpTemp('slow')} />
                )}
                Slow Heating
              </Typography>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.hwmVilla__pi_hpHeatingSpeed) === 'fast' && (
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} onClick={() => handleHpTemp('fast')} />
                )}
                {getQuantityValue(schema.hwmVilla__pi_hpHeatingSpeed) !== 'fast' && (
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpTemp('fast')} />
                )}
                Fast Heating
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      {optionforswhVilla === 'solar water heater' && (
        <Grid container item xs={12} direction="row">
          <Grid item xs={6}>
            <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}> {getQuantityLabel(schema.hwmVilla_swhIndividualProductCapacity)}</Typography>
                {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(individualproductcapacity?.value))} ${individualproductcapacity?.unit}`}</Typography> */}
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {getQuantityValue(schema.hwmVilla_swhIndividualProductCapacity)} {getQuantityUnit(schema.hwmVilla_swhIndividualProductCapacity)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmVilla_swhNumberOfProducts)}</Typography>
                {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberofproducts?.value} ${numberofproducts?.unit}`}</Typography> */}
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {getQuantityValue(schema.hwmVilla_swhNumberOfProducts)} {getQuantityUnit(schema.hwmVilla_swhNumberOfProducts)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmVilla_swhAreaRequired)}</Typography>
                {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(hwmVillaareaRequired?.value))} ${hwmVillaareaRequired?.unit}`}</Typography> */}
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {getQuantityValue(schema.hwmVilla_swhAreaRequired)} {getQuantityUnit(schema.hwmVilla_swhAreaRequired)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {optionforswhVilla === 'heat pump' && (
        <Grid container item xs={12} direction="row">
          <Grid item xs={6}>
            <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmVilla_hpIndividualProductCapacity)}</Typography>
                {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(individualproductcapacity?.value))} ${individualproductcapacity?.unit}`}</Typography> */}
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {getQuantityValue(schema.hwmVilla_hpIndividualProductCapacity)}
                  {getQuantityUnit(schema.hwmVilla_hpIndividualProductCapacity)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmVilla_hpNumberOfProducts)}</Typography>
                {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberofproducts?.value} ${numberofproducts?.unit}`}</Typography> */}
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {getQuantityValue(schema.hwmVilla_hpNumberOfProducts)} {getQuantityUnit(schema.hwmVilla_hpNumberOfProducts)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
              <Grid item xs={6}>
                <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmVilla_hpAreaRequired)}</Typography>
                {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(hwmVillaareaRequired?.value))} ${hwmVillaareaRequired?.unit}`}</Typography> */}
                <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                  {getQuantityValue(schema.hwmVilla_hpAreaRequired)} {getQuantityUnit(schema.hwmVilla_hpAreaRequired)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default HWMVillaCustomBasicParameters;
