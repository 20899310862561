import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { MarketplaceColors } from '../../../config';
import MarketplaceButton from '../../../common/Button';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const ProductDetailsAddToInterventionSuccess = ({ style, className, intervention, onClose }) => {
  return (
    <div style={style} className={className}>
      <Grid container>
        <Grid xs={12} container alignItems="center" justifyContent="center">
          <Typography variant="h5">
            <b>Added Successfully</b>
          </Typography>
        </Grid>
        <Grid xs={12} container alignItems="center" justifyContent="center" style={{ marginTop: '4%' }}>
          <CheckCircleOutlineIcon style={{ fontSize: '80px', color: MarketplaceColors.Secondary }} />
        </Grid>
        <Grid xs={12} container alignItems="center" justifyContent="center" style={{ marginTop: '8%' }}>
          <Typography variant="h6">Refresh "{intervention}" page to see added products</Typography>
        </Grid>
        <Grid xs={12} container alignItems="flex-end" justifyContent="flex-end" style={{ marginTop: '8%' }}>
          <Grid xs={3}>
            <Typography style={{ color: MarketplaceColors.Secondary, textTransform: 'uppercase', cursor: 'pointer' }} onClick={onClose}>
              Close
            </Typography>
          </Grid>
          <Grid xs={7}>
            <MarketplaceButton style={{ fontSize: '10px' }} children="Continue Adding Products" onClick={onClose} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductDetailsAddToInterventionSuccess;
