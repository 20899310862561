import React, { useCallback, useEffect, useState } from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PrimaryButton from '../../../../../common/button/Button';
import CustomField from '../../common/CustomField';
import { generateId } from '../../../../interventions/intervention/utils';

const TowersInputHandler = ({
  requiredPipId,
  handleClose,
  editTable,
  editableId,
  editableData,
  setUpdate,
  update,
  form,
  handlePIPValueUpdate,
  setLocalTowerData,
  projectSubcategory,
  setClosePopover,
}) => {
  const classes = useStyles();
  const existingPipValue = form?.pipvalues[requiredPipId];

  const [towerData, setTowerData] = useState({
    name: '',
    numberOfFloors: '',
    east: '',
    north: '',
    south: '',
    west: '',
    northWest: '',
    northEast: '',
    southEast: '',
    southWest: '',
  });

  const [isWallLengthValid, setIsWallLengthValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (editTable && editableData) {
      setTowerData(editableData);
    }
  }, [editTable, editableData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTowerData((prev) => ({ ...prev, [name]: value }));
  };

  const calculateValidLengths = useCallback(() => {
    const { east, north, south, west, northEast, northWest, southEast, southWest } = towerData;
    const lengths = [north, south, east, west, northEast, northWest, southEast, southWest];
    return lengths.filter((length) => length && parseFloat(length) !== 0).length;
  }, [towerData]);

  useEffect(() => {
    const count = calculateValidLengths();
    setIsWallLengthValid(count >= 4);

    const isNameValid = towerData.name.trim() !== '';
    const isNumberOfFloorsValid = towerData.numberOfFloors && parseFloat(towerData.numberOfFloors) > 0;

    setIsFormValid(count >= 4 && isNameValid && isNumberOfFloorsValid);
  }, [towerData, calculateValidLengths]);

  const handleSaveOrUpdate = () => {
    if (editTable) {
      const updatedPipValues = existingPipValue.map((item) => {
        if (item._id === editableId) {
          return {
            ...item,
            ...towerData,
            numberOfFloors: parseFloat(towerData.numberOfFloors),
            east: parseFloat(towerData.east),
            north: parseFloat(towerData.north),
            south: parseFloat(towerData.south),
            west: parseFloat(towerData.west),
            northWest: parseFloat(towerData.northWest),
            northEast: parseFloat(towerData.northEast),
            southEast: parseFloat(towerData.southEast),
            southWest: parseFloat(towerData.southWest),
          };
        }
        return item;
      });

      setLocalTowerData(updatedPipValues);
      handlePIPValueUpdate(requiredPipId, updatedPipValues);
      if (setClosePopover) setClosePopover(true);
    } else {
      const newEntry = {
        _id: generateId(),
        ...towerData,
        numberOfFloors: parseFloat(towerData.numberOfFloors),
        east: parseFloat(towerData.east),
        north: parseFloat(towerData.north),
        south: parseFloat(towerData.south),
        west: parseFloat(towerData.west),
        northWest: parseFloat(towerData.northWest),
        northEast: parseFloat(towerData.northEast),
        southEast: parseFloat(towerData.southEast),
        southWest: parseFloat(towerData.southWest),
      };

      const updatedData = existingPipValue ? [...existingPipValue, newEntry] : [newEntry];
      setLocalTowerData(updatedData);
      handlePIPValueUpdate(requiredPipId, updatedData);
    }
    setUpdate(!update);
    handleClose();
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} style={{ paddingLeft: '4%', paddingBottom: '8%' }}>
        <Grid item xs={12} container alignItems="center">
          <Grid>
            <CloseIcon fontSize="large" style={{ cursor: 'pointer' }} onClick={handleClose} />
          </Grid>
          <Grid style={{ paddingLeft: '4%' }}>
            <Typography variant="h5">
              <b>
                {editTable ? 'EDIT' : 'ADD'} {projectSubcategory === 'apartment' ? 'TOWER' : 'VILLA TYPE'}
              </b>
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} container alignItems="center" style={{ marginBottom: '5%' }}>
          {/* Name Field */}
          <Grid xs={6} style={{ padding: '2%' }}>
            <Grid xs={12}>
              <Typography variant="h6">Name</Typography>
            </Grid>
            <Grid xs={10} style={{ marginTop: '4%' }}>
              <CustomField
                id="outlined-basic"
                variant="outlined"
                placeholder="Enter Name"
                fullWidth
                required
                name="name"
                value={towerData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid xs={10} style={{ textAlign: 'left' }}>
              <Typography style={{ fontSize: '12px', opacity: 0.5 }}>Max 20 characters</Typography>
            </Grid>
          </Grid>

          {/* Number of Floors Field */}
          <Grid xs={6}>
            <Grid xs={12}>
              <Typography variant="h6">No. of Floors</Typography>
            </Grid>
            <Grid xs={12} container style={{ marginTop: '4%', paddingBottom: '10%' }} alignItems="center">
              <Grid xs={7}>
                <CustomField
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  required
                  name="numberOfFloors"
                  value={towerData.numberOfFloors}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Wall Length Section */}
          <Grid xs={12}>
            <Grid xs={12} style={{ marginLeft: '9px' }}>
              <Typography variant="h5">Wall Length</Typography>
              <Grid xs={12}>
                <Typography style={{ color: !isWallLengthValid ? '#ED5E68' : '', fontSize: '12px', opacity: 0.5, marginBottom: '20px' }}>
                  Minimum 4 required for calculations
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Wall Length Fields */}
          {[
            { label: 'North', name: 'north' },
            { label: 'South', name: 'south' },
            { label: 'East', name: 'east' },
            { label: 'West', name: 'west' },
            { label: 'North East', name: 'northEast' },
            { label: 'North West', name: 'northWest' },
            { label: 'South East', name: 'southEast' },
            { label: 'South West', name: 'southWest' },
          ].map((direction, index) => (
            <Grid xs={6} key={direction.name}>
              <Grid xs={12}>
                <Typography variant="h6" style={{ marginLeft: '15px' }}>
                  {direction.label}
                </Typography>
              </Grid>
              <Grid xs={12} container style={{ paddingLeft: '4%', marginTop: '4%', paddingBottom: '10%' }} alignItems="center">
                <Grid xs={7}>
                  <CustomField
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    required
                    name={direction.name}
                    value={towerData[direction.name]}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid xs={3} style={{ paddingLeft: '22px' }}>
                  <Typography variant="h6">m</Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>

      {/* Actions Footer */}
      <Grid item xs={12}>
        <Paper className={classes.actions}>
          <Grid item xs={12} container direction="row" alignItems="center" justifyContent="flex-end">
            <Grid item xs={2}>
              <Typography variant="h6" onClick={handleClose} style={{ cursor: 'pointer' }}>
                Cancel
              </Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={4}>
              <PrimaryButton children={editTable ? 'Update' : 'Save'} disabled={!isFormValid} onClick={handleSaveOrUpdate} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TowersInputHandler;

const useStyles = makeStyles((theme) => ({
  actions: {
    position: 'fixed',
    bottom: 0,
    width: '500px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
}));
