// React
import React from 'react';

// Material UI components
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip, Typography } from '@material-ui/core';

// Material UI icons
import { Edit as EditIcon } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';

import { RenderPreviewImage } from '../../../../users/admin/imageUpload/components';

// Styles
import { useStyles } from './table.styles';

const OrganizationManagementTable = ({ organizations = [], onEditOrganization, searchValue = '', onDeleteOrganization }) => {
  const classes = useStyles();

  // Filter function for search
  const filteredOrganizations = organizations.filter((org) => {
    const searchLower = searchValue.toLowerCase();
    return org['name'].toLowerCase().includes(searchLower);
  });

  // Table headers configuration
  const headers = [
    { id: 'organizationName', label: 'Name' },
    { id: 'logo', label: 'Logo' },
    { id: 'actions', label: 'Actions' },
  ];

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table>
        <TableHead className={classes.tableHeader}>
          <TableRow className={classes.tableRow}>
            {headers.map((header) => (
              <TableCell
                key={header.id}
                className={classes.tableCell}
                children={
                  <Typography variant="h6" style={{ color: 'black' }}>
                    <b>{header.label}</b>
                  </Typography>
                }
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredOrganizations.map((org) => (
            <TableRow key={org._id} className={classes.tableRow}>
              <TableCell className={classes.tableCell}>
                <Typography variant="h6">{org.name}</Typography>
              </TableCell>

              <TableCell className={classes.tableCell}>
                <div style={{ position: 'relative', width: 80, height: 80 }}>
                  <RenderPreviewImage isHovered={false} logo={org.payloadId?.URL} isLoadingImage={false} />
                </div>
              </TableCell>

              <TableCell className={classes.actionCell}>
                <Tooltip title="Edit Organization">
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      onEditOrganization(org);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete Organization">
                  <IconButton
                    disabled
                    style={{ marginLeft: '20px' }}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteOrganization(org);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrganizationManagementTable;
