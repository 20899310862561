import React, { useState, useEffect, useContext } from 'react';
import { Grid, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TabButtonGrp from '../../common/togglebutton/ToogleButtonGrp';
import Header from '../../common/header/Header';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import EditProjectFiles from './files/EditProjectFiles';
import MaterialDetails from './materials/EditProjectMaterialDetails';
import HeadAndBackIcon from '../../common/header/HeadAndBackIcon';
import saveicon from '../../../assets/save-icon.svg';
import PrimaryButton from '../../common/button/Button';
import { Pages, PreviousPageContext } from '../../../routing/IndexRouting';
import { EditProjectBasicPIP } from './basic/EditProjectBasicPIP';
import { EditProjectLandDetailsPIP } from './land/EditProjectLandDetailsPIP';
import EditProjectPageDwellingPIP from './dwelling/EditProjectPageDwellingPIP';
import EditProjectsCommonPIP from './common/EditProjectsCommonPIPs';
import EditProjectsPlumbingPIP from './plumbing/EditProjectsPlumbingPIP';
import EditEnvelopeDetails from './envelope/components/EditEnvelopeDetails';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    marginLeft: '13%',
    marginRight: '13%',
  },
  paper: {
    position: 'absolute',
    width: 500,
    outline: 'none',
    maxHeight: 600,
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: theme.spacing(1, 2, 2),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export const SomethingChanged = React.createContext();

const EditProject = (props) => {
  const classes = useStyles();
  const params = new URLSearchParams(props.location.search);
  const activeStep = params.get('type');
  let { id } = useParams();
  let history = useHistory();

  const { previousPage, interventionID, interventionVendorMappingID, IGBCCreditID } = useContext(PreviousPageContext);

  const [showModal, setShowModal] = useState(false);
  const [somethingChanged, setSomethingChanged] = useState(false);

  const [saveAfterSomethingChanged, setSaveAfterSomethingChanged] = useState(false);
  const [NEXT, SETNEXT] = useState('');
  const [previouspage, setPreviouspage] = useState(previousPage);
  const [modalStyle] = useState(getModalStyle);

  const handlSetSomethingChanged = (data) => setSomethingChanged(data);
  const handleClose = () => setShowModal(false);

  const handleGoBack = (previouspage) => {
    if (somethingChanged === true && previouspage) {
      setShowModal(true);
      SETNEXT(previouspage);
    } else {
      if (previouspage === Pages.projects) history.push(`/projects`);
      else if (previouspage === Pages.insights) history.push(`/projects/${id}/insights`);
      else if (previouspage === Pages.interventions) history.push(`/projects/${id}/interventions`);
      else if (previouspage === Pages.interventionDetails) history.push(`/projects/${id}/interventions/${interventionID}`);
      else if (previouspage === Pages.marketing) history.push(`/projects/${id}/marketing`);
      else if (previouspage === Pages.certification) history.push(`/projects/${id}/certification`);
      else if (previouspage === Pages.certificationDocumentUpload) history.push(`/projects/${id}/certification/upload`);
      else if (previouspage === Pages.certificationCredit) history.push(`/projects/${id}/certification/credit/${IGBCCreditID}`);
      else if (previouspage === Pages.goals) history.push(`/projects/${id}/goals`);
      else if (previouspage === Pages.vendorDetails) history.push(`/projects/${id}/interventions/${interventionID}/vendors/${interventionVendorMappingID}`);
    }
  };

  useEffect(() => {
    const cat = localStorage.getItem('previouspage');
    setPreviouspage(cat);
  }, []);

  useEffect(() => {
    if (previousPage !== previouspage) setPreviouspage(previousPage);
  }, [previouspage, previousPage]);

  return (
    <>
      <Header />
      <Grid style={{ flexGrow: 10, marginTop: '8%', marginLeft: '8%' }}>
        <HeadAndBackIcon
          heading="Project Details"
          handleClick={() => handleGoBack(previouspage && previouspage)}
          visitGoals={false}
          goToInsights={true}
          navigatingText="GO TO INSIGHTS"
          handleNavigation={() => history.push(`/projects/${id}/insights`)}
          endIcon={true}
          somethingChanged={somethingChanged}
          toogleShowModal={(d) => setShowModal(d)}
          SETNEXT={(s) => SETNEXT(s)}
        />
      </Grid>
      <div style={{ flexGrow: 10, marginTop: '2%', marginLeft: '8%', marginRight: '8%' }}>
        <TabButtonGrp
          activeStep={activeStep}
          projectId={props.match.params.id}
          somethingChanged={somethingChanged}
          toogleShowModal={(d) => setShowModal(d)}
          SETNEXT={(s) => SETNEXT(s)}
        />
      </div>
      <main className={classes.content}>
        <SomethingChanged.Provider value={{ somethingChanged: somethingChanged, handlSetSomethingChanged }}>
          <Grid container justifyContent="flex-start">
            {activeStep === 'basic' && (
              <EditProjectBasicPIP
                type="basic"
                next={NEXT ? NEXT : 'land'}
                name="Basic Information for"
                toogleShowModal={(d) => setShowModal(d)}
                SETNEXT={(s) => SETNEXT(s)}
                saveAfterSomethingChanged={saveAfterSomethingChanged && saveAfterSomethingChanged}
                setFalseAfterSomethingChanged={() => setSaveAfterSomethingChanged(false)}
              />
            )}
            {activeStep === 'land' && (
              <EditProjectLandDetailsPIP
                type="land"
                next={NEXT ? NEXT : 'dwelling'}
                name="Land Details for"
                toogleShowModal={(d) => setShowModal(d)}
                SETNEXT={(s) => SETNEXT(s)}
                saveAfterSomethingChanged={saveAfterSomethingChanged && saveAfterSomethingChanged}
                setFalseAfterSomethingChanged={() => setSaveAfterSomethingChanged(false)}
              />
            )}
            {activeStep === 'dwelling' && (
              <EditProjectPageDwellingPIP
                type="dwelling"
                next={NEXT ? NEXT : 'common'}
                name="Dwelling Unit Details for"
                toogleShowModal={(d) => setShowModal(d)}
                SETNEXT={(s) => SETNEXT(s)}
                saveAfterSomethingChanged={saveAfterSomethingChanged && saveAfterSomethingChanged}
                setFalseAfterSomethingChanged={() => setSaveAfterSomethingChanged(false)}
              />
            )}
            {activeStep === 'common' && (
              <EditProjectsCommonPIP
                type="common"
                next={NEXT ? NEXT : 'fixture'}
                name="Common Area Details for"
                toogleShowModal={(d) => setShowModal(d)}
                SETNEXT={(s) => SETNEXT(s)}
                saveAfterSomethingChanged={saveAfterSomethingChanged && saveAfterSomethingChanged}
                setFalseAfterSomethingChanged={() => setSaveAfterSomethingChanged(false)}
              />
            )}
            {activeStep === 'fixture' && (
              <EditProjectsPlumbingPIP
                type="fixture"
                next={NEXT ? NEXT : 'materials'}
                name="Plumbing Details for"
                toogleShowModal={(d) => setShowModal(d)}
                SETNEXT={(s) => SETNEXT(s)}
                saveAfterSomethingChanged={saveAfterSomethingChanged && saveAfterSomethingChanged}
                setFalseAfterSomethingChanged={() => setSaveAfterSomethingChanged(false)}
              />
            )}
            {activeStep === 'materials' && (
              <MaterialDetails
                type="materials"
                next={NEXT ? NEXT : 'envelope'}
                name="Material Details for"
                toogleShowModal={(d) => setShowModal(d)}
                SETNEXT={(s) => SETNEXT(s)}
                saveAfterSomethingChanged={saveAfterSomethingChanged && saveAfterSomethingChanged}
                setFalseAfterSomethingChanged={() => setSaveAfterSomethingChanged(false)}
              />
            )}
            {activeStep === 'envelope' && (
              <EditEnvelopeDetails
                type="dwelling"
                next={NEXT ? NEXT : 'file'}
                name="Envelope Details for"
                toogleShowModal={(d) => setShowModal(d)}
                SETNEXT={(s) => SETNEXT(s)}
                saveAfterSomethingChanged={saveAfterSomethingChanged && saveAfterSomethingChanged}
                setFalseAfterSomethingChanged={() => setSaveAfterSomethingChanged(false)}
              />
            )}
            {activeStep === 'file' && (
              <EditProjectFiles
                type="file"
                next="goals"
                name="Files Upload for"
                toogleShowModal={(d) => setShowModal(d)}
                saveAfterSomethingChanged={saveAfterSomethingChanged && saveAfterSomethingChanged}
                setFalseAfterSomethingChanged={() => setSaveAfterSomethingChanged(false)}
              />
            )}
          </Grid>
        </SomethingChanged.Provider>
      </main>

      <Modal open={showModal} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <div style={modalStyle} className={classes.paper}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid xs={12} style={{ textAlign: 'center' }}>
              <Typography variant="h5">
                <b>Save Changes</b>
              </Typography>
            </Grid>
            <Grid xs={12} style={{ textAlign: 'center' }}>
              <img alt="icon" src={saveicon} />
            </Grid>
            <Grid xs={12} style={{ textAlign: 'center', marginTop: '2%' }}>
              <Typography variant="h6" style={{ lineHeight: '28px' }}>
                It looks like you were editing something on this page.
                <br /> If you leave all the unsaved changes will be lost.
              </Typography>
            </Grid>
            <Grid xs={12} container justifyContent="space-around" alignItems="center" style={{ marginTop: '5%', paddingRight: '3%', paddingLeft: '3%' }}>
              <Grid xs={7}>
                <Typography
                  variant="h6"
                  onClick={() => {
                    setShowModal(false);
                    setSomethingChanged(false);
                    setSaveAfterSomethingChanged(false);
                    if (NEXT && NEXT === Pages.insights) history.push(`/projects/${id}/insights`);
                    else if (NEXT && NEXT === Pages.interventions) history.push(`/projects/${id}/interventions`);
                    else if (NEXT && NEXT === Pages.marketing) history.push(`/projects/${id}/marketing`);
                    else if (NEXT && NEXT === Pages.certification) history.push(`/projects/${id}/certification`);
                    else if (NEXT && NEXT === Pages.projects) history.push(`/projects`);
                    else if (NEXT && NEXT === Pages.interventionDetails) history.push(`/projects/${id}/interventions/${interventionID}`);
                    else if (NEXT && NEXT === Pages.certificationDocumentUpload) history.push(`/projects/${id}/certification/upload`);
                    else if (NEXT && NEXT === Pages.certificationCredit) history.push(`/projects/${id}/certification/credit/${IGBCCreditID}`);
                    else if (NEXT && NEXT === Pages.goals) history.push(`/projects/${id}/goals`);
                    else if (NEXT && NEXT === Pages.vendorDetails)
                      history.push(`/projects/${id}/interventions/${interventionID}/vendors/${interventionVendorMappingID}`);
                    else history.push(`/projects/${id}/edit?type=${NEXT}`);
                  }}
                  style={{ color: 'red', cursor: 'pointer' }}
                >
                  Continue Without Saving
                </Typography>
              </Grid>
              <Grid xs={5} container alignItems="center">
                <Grid xs={4}>
                  <Typography variant="h6" onClick={() => setShowModal(false)} style={{ color: '#1FDEDB', cursor: 'pointer' }}>
                    <b>Cancel</b>
                  </Typography>
                </Grid>
                <Grid xs={8}>
                  <PrimaryButton children="save" onClick={() => setSaveAfterSomethingChanged(true)} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  );
};
export default EditProject;
