import { Typography, Grid } from '@material-ui/core';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import useStyles from './styles';
import CustomDropdown from '../common/CustomDropdown';
import { useEffect, useState } from 'react';
import ResetFields from '../common/textFiledwithReset';

const WallBlocksInteriorCustomBasicParameters = (props) => {
  const typeOfWallBlocks = ['Concrete Blocks', 'Low Carbon Blocks', 'Fly Ash Bricks', 'Clay Bricks', 'CSEB Blocks', 'Porotherm Bricks', 'AAC Blocks'];
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();
  const [fixingTypeOptions, setFixingTypeOptions] = useState([]);
  const [fixingMortarAgentOptions, setFixingMortarAgentOptions] = useState([]);
  useEffect(() => {
    const selectedTypeOfWallBlocks = getQuantityValue(schema.wallBlocksInterior__pi_typeOfBlock);
    if (['Low Carbon Blocks', 'Fly Ash Bricks', 'Clay Bricks', 'CSEB Blocks'].includes(selectedTypeOfWallBlocks)) {
      updateQuantityValue(schema.wallBlocksInterior__pi_fixingType, 'Wet Fix');
      setFixingTypeOptions(['Wet Fix']);
    }
    if (['Porotherm Bricks', 'AAC Blocks'].includes(selectedTypeOfWallBlocks)) {
      updateQuantityValue(schema.wallBlocksInterior__pi_fixingType, 'Wet Fix');
      setFixingTypeOptions(['Wet Fix', 'Dry Fix']);
    }
  }, [getQuantityValue(schema.wallBlocksInterior__pi_typeOfBlock)]);
  useEffect(() => {
    const selectedTypeOfWallBlocks = getQuantityValue(schema.wallBlocksInterior__pi_typeOfBlock);
    const selectedFixingType = getQuantityValue(schema.wallBlocksInterior__pi_fixingType);
    if (['Low Carbon Blocks', 'Fly Ash Bricks', 'Clay Bricks', 'CSEB Blocks'].includes(selectedTypeOfWallBlocks) && selectedFixingType === 'Wet Fix') {
      updateQuantityValue(schema.wallBlocksInterior__pi_fixingMortarAgent, '1:4 - Cement Sand');
      setFixingMortarAgentOptions(['1:4 - Cement Sand', '1:6 - Cement Sand']);
    }
    if ('AAC Blocks' === selectedTypeOfWallBlocks && selectedFixingType === 'Wet Fix') {
      updateQuantityValue(schema.wallBlocksInterior__pi_fixingMortarAgent, '1:4 - Cement Sand');
      setFixingMortarAgentOptions(['1:4 - Cement Sand', '1:6 - Cement Sand']);
    }
    if ('AAC Blocks' === selectedTypeOfWallBlocks && selectedFixingType === 'Dry Fix') {
      updateQuantityValue(schema.wallBlocksInterior__pi_fixingMortarAgent, 'AAC Chemical');
      setFixingMortarAgentOptions(['AAC Chemical']);
    }
    if ('Porotherm Bricks' === selectedTypeOfWallBlocks && selectedFixingType === 'Wet Fix') {
      updateQuantityValue(schema.wallBlocksInterior__pi_fixingMortarAgent, '1:4 - Cement Sand');
      setFixingMortarAgentOptions(['1:4 - Cement Sand', '1:6 - Cement Sand']);
    }
    if ('Porotherm Bricks' === selectedTypeOfWallBlocks && selectedFixingType === 'Dry Fix') {
      updateQuantityValue(schema.wallBlocksInterior__pi_fixingMortarAgent, 'Porotherm Chemical');
      setFixingMortarAgentOptions(['Porotherm Chemical']);
    }
  }, [getQuantityValue(schema.wallBlocksInterior__pi_typeOfBlock), getQuantityValue(schema.wallBlocksInterior__pi_fixingType)]);
  return (
    <Grid container direction="row" alignItems="left" item xs={12}>
      <Grid item xs={4} container alignItems="flex-start" direction="row">
        <Grid xs>
          <Typography className={classes.textheader2}>{getQuantityLabel(schema.wallBlocksInterior__pi_typeOfBlock)}</Typography>
        </Grid>
        <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
          <Grid xs={7}>
            <CustomDropdown
              value={getQuantityValue(schema.wallBlocksInterior__pi_typeOfBlock)}
              onChange={(e) => {
                e.preventDefault();
                updateQuantityValue(schema.wallBlocksInterior__pi_selectedProductIdentifier, null);
                updateQuantityValue(schema.wallBlocksInterior__pi_typeOfBlock, e.target.value);
              }}
              options={typeOfWallBlocks}
              disabled={false}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} container alignItems="flex-start" direction="row">
        <Grid xs>
          <Typography className={classes.textheader2}>{getQuantityLabel(schema.wallBlocksInterior__pi_fixingType)}</Typography>
        </Grid>
        <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
          <Grid xs={7}>
            <CustomDropdown
              value={getQuantityValue(schema.wallBlocksInterior__pi_fixingType)}
              onChange={(e) => {
                e.preventDefault();
                updateQuantityValue(schema.wallBlocksInterior__pi_fixingType, e.target.value);
              }}
              options={fixingTypeOptions}
              disabled={false}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} direction="column">
        <Grid container item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.textheader2}>{getQuantityLabel(schema.wallBlocksInterior__pi_blockQuantity)}</Typography>
        </Grid>
        <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
          <Grid xs={7}>
            <ResetFields
              id="outlined-basic"
              variant="outlined"
              value={getQuantityValue(schema.wallBlocksInterior__pi_blockQuantity)}
              placeholder=""
              onChange={(e) => {
                e.preventDefault();
                updateQuantityValue(schema.wallBlocksInterior__pi_blockQuantity, e.target.value);
              }}
              fullWidth
              required
              onClick={(e) => {
                e.preventDefault();
                updateQuantityValue(schema.wallBlocksInterior__pi_blockQuantity, null);
              }}
              disabled={false}
            />
          </Grid>
          <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
            <Typography variant="h6">{getQuantityUnit(schema.wallBlocksInterior__pi_blockQuantity)}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4} style={{ marginTop: '2%' }} container alignItems="flex-start" direction="row">
        <Grid xs>
          <Typography className={classes.textheader2}>{getQuantityLabel(schema.wallBlocksInterior__pi_fixingMortarAgent)}</Typography>
        </Grid>
        <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
          <Grid xs={7}>
            <CustomDropdown
              value={getQuantityValue(schema.wallBlocksInterior__pi_fixingMortarAgent)}
              onChange={(e) => {
                e.preventDefault();
                updateQuantityValue(schema.wallBlocksInterior__pi_fixingMortarAgent, e.target.value);
              }}
              options={fixingMortarAgentOptions}
              disabled={false}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WallBlocksInteriorCustomBasicParameters;
