import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PrimaryButton from '../../../../../common/button/Button';
import CustomField from '../../common/CustomField';
import HandlerDropdown from '../../common/HandlerDropdown';
import { generateId } from '../../../../interventions/intervention/utils';

const OpeningPerTowerHandler = ({
  requiredPipId,
  handleClose,
  editTable,
  editableId,
  editableData,
  setUpdate,
  update,
  tower,
  openingData,
  setLocalTowerOpeningData,
  projectSubcategory,
  form,
  handlePIPValueUpdate,
}) => {
  const classes = useStyles();
  const existingPipValue = form?.pipvalues[requiredPipId];

  // Combined state for all direction units
  const [formData, setFormData] = useState({
    openingSchedule: '',
    eastUnits: '',
    northUnits: '',
    southUnits: '',
    westUnits: '',
    northWestUnits: '',
    northEastUnits: '',
    southEastUnits: '',
    southWestUnits: '',
  });

  const [openingOptions, setOpeningOptions] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);

  // Load opening options
  useEffect(() => {
    if (openingData?.length) {
      setOpeningOptions(openingData.map((item) => item.name));
    }
  }, [openingData]);

  // Load edit data if in edit mode
  useEffect(() => {
    if (editTable && editableData) {
      setFormData(editableData);
    }
  }, [editTable, editableData]);

  // Form validation
  useEffect(() => {
    setIsFormValid(!!formData.openingSchedule);
  }, [formData]);

  // Get opening ID from selected opening schedule
  const getOpeningId = () => {
    const selectedOpening = openingData.find((item) => item.name === formData.openingSchedule);
    return selectedOpening?._id;
  };

  // Handle form field changes
  const handleChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Handle number inputs - only allow whole numbers
  const handleNumberChange = (field, e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      handleChange(field, value);
    }
  };

  // Save or update data
  const handleSaveOrUpdate = () => {
    const { openingSchedule, eastUnits, northUnits, southUnits, westUnits, southEastUnits, southWestUnits, northWestUnits, northEastUnits } = formData;

    const openingEntry = {
      openingSchedule,
      eastUnits: parseFloat(eastUnits),
      northUnits: parseFloat(northUnits),
      southUnits: parseFloat(southUnits),
      westUnits: parseFloat(westUnits),
      southEastUnits: parseFloat(southEastUnits),
      southWestUnits: parseFloat(southWestUnits),
      northWestUnits: parseFloat(northWestUnits),
      northEastUnits: parseFloat(northEastUnits),
      towerId: tower._id,
      openingId: getOpeningId(),
    };

    if (editTable) {
      // Update existing entry
      const updatedPipValues = existingPipValue.map((item) => (item._id === editableId ? { ...item, ...openingEntry } : item));
      setLocalTowerOpeningData(updatedPipValues);
      handlePIPValueUpdate(requiredPipId, updatedPipValues);
    } else {
      // Create new entry
      const newEntry = {
        _id: generateId(),
        ...openingEntry,
      };

      const updatedData = existingPipValue && existingPipValue !== 0 ? [...existingPipValue, newEntry] : [newEntry];

      setLocalTowerOpeningData(updatedData);
      handlePIPValueUpdate(requiredPipId, updatedData);
    }

    setUpdate(!update);
    handleClose();
  };

  // Render direction input block if tower has that direction
  const renderDirectionBlock = (direction, label) => {
    const directionMap = {
      north: 'northUnits',
      south: 'southUnits',
      east: 'eastUnits',
      west: 'westUnits',
      northEast: 'northEastUnits',
      northWest: 'northWestUnits',
      southEast: 'southEastUnits',
      southWest: 'southWestUnits',
    };

    const fieldName = directionMap[direction];

    // Only render if tower has this direction
    if (tower && tower[direction] && tower[direction] !== '0' && tower[direction] !== 0) {
      return (
        <Grid xs={6} key={direction}>
          <Grid xs={12}>
            <Typography variant="h6" style={{ marginLeft: '15px' }}>
              {label}
            </Typography>
          </Grid>
          <Grid xs={12} container style={{ paddingLeft: '4%', marginTop: '4%', paddingBottom: '10%' }} alignItems="center">
            <Grid xs={7}>
              <CustomField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                required
                value={formData[fieldName] || ''}
                onChange={(e) => handleNumberChange(fieldName, e)}
              />
            </Grid>
            <Grid xs={3} style={{ paddingLeft: '20px' }}>
              <Typography variant="h6">Units</Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  const buildingType = projectSubcategory === 'apartment' ? 'Tower' : 'Villa';

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} style={{ paddingLeft: '4%', paddingBottom: '8%' }}>
        <Grid item xs={12} container alignItems="center">
          <Grid>
            <CloseIcon fontSize="large" style={{ cursor: 'pointer' }} onClick={handleClose} />
          </Grid>
          <Grid style={{ paddingLeft: '4%' }}>
            <Typography variant="h5">
              <b>
                Opening Schedule for {buildingType} {tower?.name}
              </b>
            </Typography>
          </Grid>
        </Grid>
        <Typography style={{ fontSize: '12px', opacity: 0.5 }}>All fields are mandatory</Typography>

        {/* Opening Schedule Dropdown */}
        <Grid xs={12} style={{ marginTop: '5%' }}>
          <Grid xs={6}>
            <Typography variant="h6" style={{ marginLeft: '7%' }}>
              Opening Schedule Name
            </Typography>
          </Grid>
          <Grid xs={5} container style={{ marginLeft: '2%', marginTop: '2%', paddingBottom: '10%' }} alignItems="center">
            <HandlerDropdown
              value={formData.openingSchedule}
              onChange={(e) => handleChange('openingSchedule', e.target.value)}
              options={openingOptions}
              disabled={false}
            />
          </Grid>
        </Grid>

        {/* Direction Units Fields */}
        <Grid item xs={12} container alignItems="center" style={{ marginBottom: '5%' }}>
          {renderDirectionBlock('north', 'North')}
          {renderDirectionBlock('south', 'South')}
          {renderDirectionBlock('east', 'East')}
          {renderDirectionBlock('west', 'West')}
          {renderDirectionBlock('northEast', 'North East')}
          {renderDirectionBlock('northWest', 'North West')}
          {renderDirectionBlock('southEast', 'South East')}
          {renderDirectionBlock('southWest', 'South West')}
        </Grid>
      </Grid>

      {/* Action Buttons */}
      <Grid item xs={12}>
        <Paper className={classes.actions}>
          <Grid item xs={12} container direction="row" alignItems="center" justifyContent="flex-end">
            <Grid item xs={2}>
              <Typography variant="h6" onClick={handleClose} style={{ cursor: 'pointer' }}>
                Cancel
              </Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={4}>
              <PrimaryButton children={editTable ? 'Update' : 'Save'} disabled={!isFormValid} onClick={handleSaveOrUpdate} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default OpeningPerTowerHandler;

const useStyles = makeStyles((theme) => ({
  actions: {
    position: 'fixed',
    bottom: 0,
    width: '500px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
}));
