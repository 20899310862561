import React, { useEffect, useState, useContext } from 'react';
import { Document, Page, StyleSheet, PDFViewer, Font, View, Text } from '@react-pdf/renderer';
import LatoItalic from '../../../../fonts/Lato-Italic.ttf';

import LatoRegular from '../../../../fonts/Lato-Regular.ttf';
import LatoBold from '../../../../fonts/Lato-Bold.ttf';
import { useParams } from 'react-router';
import { UserContext } from '../../../../../routing/IndexRouting';
import WaterRounded from '../../../../../assets/new-pdf/WaterRounded.png';

import HeadingPage from './HeadingPage';

import WaterReportIcon from '../../../../../assets/new-pdf/WaterReportIcon.png';
import WaterIcon from '../../../../../assets/new-pdf/WaterIcon.png';

import { useAppApiRequest } from '../../../../../hooks/useAppApiRequest';
import { MetafireFeatures } from '../../../../../metafire/utils/types';
import CategoryWiseSummaryPage from './CategoryWiseSummaryPage';
import CategoryReportBarGraphPage from './CategoryReportBarGraphPage';
import ImplementInterventionsDetailsPage from './ImplementedInterventionsDetailsPage';
import Footer from './Footer';
import Header from './Header';
import numberWithCommas from '../../../../../utils/numberToComma';
import WaterFlowDiagramSvg from './WaterFlowDiagramSvg';
import { getCategoryWiseEnabledAndActivatedInterventionsData, getCurrentDate, getDataById } from './ReportUtils';

const styles = StyleSheet.create({
  footerMargin: {
    top: 30,
    marginTop: '25%',
  },
  viewPage2: {
    margin: '10px 20px 20px 20px',
    padding: 10,
    flexGrow: 1,
  },
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFFFFF',
  },
  viewer: {
    width: window.innerWidth - 8,
    height: window.innerHeight - 8,
  },
});
Font.register({
  family: 'Lato',
  fonts: [
    {
      src: LatoRegular,
    },
    {
      src: LatoBold,
    },
  ],
});
Font.register({
  family: 'Lato-Italic',
  fonts: [
    {
      src: LatoItalic,
    },
  ],
});
const WaterReport = () => {
  const { userDetails } = useContext(UserContext);
  const params = useParams();
  const [asset, setAsset] = useState('');
  const [intervention, setIntervention] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [projectData, setProjectData] = React.useState('');
  const [flowDiagramData, setFlowDiagramData] = useState([]);

  const { data: insightsResponse } = useAppApiRequest(`/metafire?projectId=${params?.id}&feature=${MetafireFeatures.INSIGHTS}&numberOfYears=1`, {
    method: 'GET',
  });
  const { data: projectDataResponse } = useAppApiRequest(`/projectv2/${params?.id}/basics?NumberOfYears=1`, { method: 'GET' });
  const { data: reportResponse } = useAppApiRequest(`/metafire?projectId=${params?.id}&feature=${MetafireFeatures.REPORT}`, { method: 'GET' });
  const { data: flowDiagram } = useAppApiRequest(`/metafire?projectId=${params?.id}&feature=${MetafireFeatures.FLOWDIAGRAMS}`, { method: 'GET' });

  useEffect(() => {
    if (flowDiagram) {
      setFlowDiagramData(flowDiagram?.data?.data?.data);
    }
  }, [flowDiagram]);
  useEffect(() => {
    if (insightsResponse) {
      setIntervention(insightsResponse?.data?.data?.data?.interventions);
    }
  }, [insightsResponse]);
  useEffect(() => {
    if (reportResponse) {
      setReportData(reportResponse?.data?.data?.data);
    }
  }, [reportResponse]);
  useEffect(() => {
    if (projectDataResponse) {
      setProjectData(projectDataResponse?.data?.data?.data);
    }
  }, [projectDataResponse]);

  const { data: assetReponse } = useAppApiRequest(`/sign-s3/${userDetails?.organisation?.payloadId}`, { method: 'GET' }, [userDetails], !!userDetails);
  useEffect(() => {
    if (assetReponse?.data?.data) {
      setAsset(assetReponse?.data?.data?.data);
    }
  }, [assetReponse]);
  return (
    <PDFViewer style={styles.viewer}>
      <Document title={`Water Use Report ${getCurrentDate()}`}>
        <Page size="A4" style={styles.page}>
          <HeadingPage
            category={'Water'}
            pageIcon={WaterReportIcon}
            asset={asset}
            reportName={'Water Use'}
            projectname={projectData && projectData.name}
            date={getCurrentDate()}
            reportHeadingColor="#3BC7EE"
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <CategoryReportBarGraphPage
            heading="Water"
            category="Water"
            icon={WaterRounded}
            companyname={projectData && projectData.name}
            capex={intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.finance?.capex?.value}
            opex={intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.finance?.opexSavings?.value}
            totalResourceSavings={intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.resource?.category?.value}
            unit="KL"
            baselineConsumption={intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.resource?.annualBaselineConsumption?.value}
            year={getCurrentDate().split('/')[2]}
            title1={'Base Case Water Consumption'}
            title2={'Sustainable Case Water Consumption'}
            percentageResourceSaving={intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.resource?.categorySavingPercentage}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <CategoryWiseSummaryPage
            companyname={projectData && projectData.name}
            totalResourceSavings={{
              value: intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.resource?.category?.value,
              unit: 'kL',
            }}
            interventionData={intervention}
            resourceSavingPercentage={
              intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.resource?.categorySavingPercentage?.value
            }
            totalLPCDPreSdplus={intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.resource?.totalLPCDPreSdplus?.value}
            totalLPCDPostSdplus={intervention?.categorized?.filter((item) => item.name === 'Water')[0]?.quantities?.resource?.totalLPCDPostSdplus?.value}
            category="Water"
            border="1px solid #9E9E9E"
            color="#3BC7EE"
            categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_water')}
            headingIcon={WaterRounded}
            dummyicon={WaterIcon}
            year={getCurrentDate().split('/')[2]}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <ImplementInterventionsDetailsPage
            companyname={projectData && projectData.name}
            ProjectinterventionMappingData={{}}
            allVendorMappings={{}}
            allvendors={{}}
            EnabledGrouped={{}}
            border="1px solid #9E9E9E"
            color="#3BC7EE"
            year={getCurrentDate().split('/')[2]}
            category="Water"
            categorisedReportData={getCategoryWiseEnabledAndActivatedInterventionsData(reportData && reportData, 'reportImpactSummary_water')}
            numnber={2}
            resourceSavingUnit={'kL'}
          />
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.viewPage2}>
            <Header companyname={projectData && projectData.name} />
            <Text style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '14px', textTransform: 'uppercase', marginTop: '20px' }}>{`WATER FLOW DIAGRAM`}</Text>
            <View>
              <WaterFlowDiagramSvg
                stpRO={`${numberWithCommas(getDataById('waterFlowDiagram_totalStpRo', flowDiagramData))}`}
                STP={`${numberWithCommas(getDataById('waterFlowDiagram_totalSTP', flowDiagramData))}`}
                swimmingPool={`${numberWithCommas(getDataById('waterFlowDiagram_totalSwimmingPool', flowDiagramData))}`}
                domesticOther={`${numberWithCommas(getDataById('waterFlowDiagram_totalDomesticOther', flowDiagramData))}`}
                domestiCHealthFaucet={`${numberWithCommas(getDataById('waterFlowDiagram_totalDomesticHealthFaucet', flowDiagramData))}`}
                totalRO={`${numberWithCommas(getDataById('waterFlowDiagram_totalRO', flowDiagramData))}`}
                FreshWater={`${numberWithCommas(getDataById('waterFlowDiagram_totalFreshWater', flowDiagramData))}`}
                WTP={`${numberWithCommas(getDataById('waterFlowDiagram_totalWTP', flowDiagramData))}`}
                CommonAreaUse={`${numberWithCommas(getDataById('waterFlowDiagram_totalCommonAreaUse', flowDiagramData))}`}
                Flushing={`${numberWithCommas(getDataById('waterFlowDiagram_totalFlushing', flowDiagramData))}`}
                Drinking={`${numberWithCommas(getDataById('waterFlowDiagram_totalDrinking', flowDiagramData))}`}
              />
            </View>
          </View>
          <View style={{ position: 'absolute', left: 20, top: 810, right: 20 }}>
            <Footer year={getCurrentDate().split('/')[2]} />
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};
export default WaterReport;
