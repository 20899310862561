import React, { useState } from 'react';
import { Grid, Typography, withWidth, Modal, makeStyles } from '@material-ui/core';
import PrimaryButton from '../../../../common/button/Button';
import CloseIcon from '@material-ui/icons/Close';
import infoIcon from '../../../../../assets/interventions/info-icon.svg';

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 10,
  },
  closeIcon: {
    color: 'rgba(237, 94, 104, 0.75)',
    cursor: 'pointer',
  },
  modalPaper: {
    position: 'absolute',
    width: 500,
    outline: 'none',
    maxHeight: 600,
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: theme.spacing(2, 3, 2, 4),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  linkText: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '15px',
    letterSpacing: '1px',
    color: 'rgba(31, 222, 219, 1)',
    cursor: 'pointer',
  },
}));

const DeleteModal = ({ handleDelete, setClosePopover, showModal, setShowModal }) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const handleClose = () => setShowModal(false);
  return (
    <>
      <Modal open={showModal} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <div style={modalStyle} className={classes.modalPaper}>
          <Grid direction="column" container alignItems="center" justifyContent="center" style={{ marginBottom: '3%' }}>
            <Typography variant="h5">
              <b>Delete Tower Specifications</b>
            </Typography>
            <img
              src={infoIcon}
              alt="..."
              style={{
                marginTop: '20px',
                height: '72px',
                width: '72px',
                color: '#ffffff',
              }}
            />
            <Typography variant="h6" style={{ paddingTop: '20px' }}>
              This column will be permanently deleted
            </Typography>

            <Grid xs={12} container alignItems="center" justifyContent="flex-end" style={{ marginTop: '5%', paddingRight: '3%', paddingLeft: '3%' }}>
              <Grid xs={3} container alignItems="left" justifyContent="flex-start">
                <Typography className={classes.linkText} onClick={() => handleClose()}>
                  CANCEL
                </Typography>
              </Grid>
              <Grid xs={4} container alignItems="center" justifyContent="flex-end">
                <PrimaryButton
                  children="Delete"
                  style={{ width: '217px' }}
                  onClick={() => {
                    setClosePopover(true);
                    handleDelete();
                    handleClose();
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  );
};

export default withWidth()(DeleteModal);
