import React, { useState } from 'react';
import { useStyles } from './Styles';
import { UserList, UserStats } from './Components';
import { useStatusContext } from '../../../../../features/providers/StatusProvider';

const ManageUsers = ({ setSelectedUser, searchItem, users, activeUsers, roles, pendingUsers, currentUserData, handleResendInvite }) => {
  const classes = useStyles();
  const [hoveredUser, setHoveredUser] = useState(null);
  const { showStatus } = useStatusContext();

  const filteredUsers =
    searchItem && searchItem.length > 0
      ? users.filter(
          (user) => user?.username?.toLowerCase().includes(searchItem?.toLowerCase()) || user?.email?.toLowerCase().includes(searchItem?.toLowerCase())
        )
      : users;

  const handleRoleChangeOrDelete = (id, action) => {
    setSelectedUser(id);
    showStatus(
      action === 'removeUser'
        ? 'removeUser'
        : action === 'normal'
        ? 'changeToNormal'
        : action === 'admin'
        ? 'changeToAdmin'
        : action === 'Invite'
        ? 'inviteSent'
        : 'accountDeactivated'
    );
  };

  return (
    <>
      <UserStats activeUsers={activeUsers} pendingUsers={pendingUsers} />
      <UserList
        filteredUsers={filteredUsers}
        roles={roles}
        onRoleChange={handleRoleChangeOrDelete}
        hoveredUser={hoveredUser}
        setHoveredUser={setHoveredUser}
        classes={classes}
        currentUserData={currentUserData}
        handleResendInvite={(userId) => handleResendInvite(userId)}
      />
    </>
  );
};

export default ManageUsers;
