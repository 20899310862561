import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { Typography, Grid } from '@material-ui/core';
// import currentProjecticon from '../../../../assets/currentprojectnew1.svg';
import currentProjecticon from '../../../../assets/project-image.svg';
import line2 from '../../../../assets/Vector73.svg';
import CasaSkeleton from '../../../common/skeleton/CasaSkeleton';
import numberWithCommas from '../../../../utils/numberToComma';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 505,
    height: 'auto',
    boxShadow: '2px 8px 16px rgba(0, 197, 138, 0.08)',
    borderRadius: '15px',
    boxSizing: 'border-box',
  },
  spacing: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    width: '150px',
  },
  spacingName: {
    paddingRight: '10%',
    width: '100%',
    lineHeight: '35px',
  },
  space: {
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    width: '150px',
  },
  imagerow: {
    flexDirection: 'row',
    paddingLeft: theme.spacing(8),
    paddingTop: theme.spacing(5),
    marginLeft: '-15px',
  },
  cover: {
    width: 100,
    height: 50,
  },
  line: {
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(2),
  },
  text: {
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(0),
    fontWeight: '900',
  },
  textcolor: {
    color: 'rgba(0, 0, 0, 0.38)',
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(4),
  },
  top: {
    marginTop: '8%',
    marginLeft: '-3%',
  },
}));

function CurrentProjectCard({ name, category, location, builtup, rooftop, unit1, unit2 }) {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.root}>
        <>
          <Grid container item xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '2%', marginBottom: '2%' }}>
            <Grid item xs={6}>
              <div className={classes.imagerow}>
                <CardMedia className={classes.cover} image={currentProjecticon} title="" />
              </div>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12} className={classes.space}></Grid>
              <Typography className={classes.spacingName} variant="h4">
                {!location ? <CasaSkeleton variant="rect" height="5vh" width="60%" animation="wave" /> : name}
              </Typography>
            </Grid>
          </Grid>
        </>
        <div>
          <img src={line2} alt="..." className={classes.line} />
        </div>
        <Grid container item xs={12} style={{ marginTop: '-15px' }}>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.textcolor}>
              Project Category
            </Typography>
            <Typography className={classes.text} variant="h5">
              {!location ? (
                <CasaSkeleton variant="rect" height="3vh" width="60%" animation="wave" className={classes.top} />
              ) : (
                category.charAt(0).toUpperCase() + category.slice(1)
              )}
              {/* Residential */}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.textcolor}>
              Project Location
            </Typography>
            <Typography className={classes.text} variant="h5">
              {!location ? (
                <CasaSkeleton variant="rect" height="3vh" width="60%" animation="wave" className={classes.top} />
              ) : location.split(',')[0].length > 11 ? (
                `${location.split(',')[0].slice(0, 11)}...`
              ) : (
                location.split(',')[0]
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ marginTop: '-15px' }}>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.textcolor}>
              Rooftop Area
            </Typography>
            <Typography className={classes.text} variant="h5">
              {`${numberWithCommas(rooftop || 0)} ${unit1} `}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.textcolor}>
              Built-Up Area
            </Typography>
            <Typography className={classes.text} variant="h5">
              {`${numberWithCommas(builtup || 0)} ${unit2}`}
              {/* 87,989.30 sqm */}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} style={{ marginTop: '1%' }}></Grid>
        <br />
      </Card>
    </>
  );
}

export default CurrentProjectCard;
