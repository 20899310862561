/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import ResetFields from '../common/textFiledwithReset';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import numberWithCommas from '../../../../../utils/numberToComma';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const WallblockExteriorCustomAdvancedParameters = (props) => {
  const { setSaveError } = props;
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();

  const [customSpecificationError, setCustomSpecificationError] = useState([]);
  const [customCapexError, setCustomCapexError] = useState(false);

  const isCustomSpecification = getQuantityValue(schema.wallBlockExterior__pi_customWallBlockSpecification);
  const isCustomCapex = getQuantityValue(schema.wallBlockExterior__pi_capexSourceMultiplier) === 'CUSTOM' || isCustomSpecification;

  useEffect(() => {
    validateCustomSpecification();
  }, [
    isCustomSpecification,
    getQuantityValue(schema.wallBlockExterior__pi_blockWeight),
    getQuantityValue(schema.wallBlockExterior__pi_blockThickness),
    getQuantityValue(schema.wallBlockExterior__pi_blockVolume),
    getQuantityValue(schema.wallBlockExterior__pi_carbonEmissions),
    getQuantityValue(schema.wallBlockExterior__pi_thermalConductivity),
    isCustomCapex,
    getQuantityValue(schema.wallBlockExterior__pi_customCapex),
  ]);

  const validateCustomSpecification = () => {
    let isValid = true;

    const requiredFields = [
      schema.wallBlockExterior__pi_blockWeight,
      schema.wallBlockExterior__pi_blockThickness,
      schema.wallBlockExterior__pi_blockVolume,
      schema.wallBlockExterior__pi_carbonEmissions,
      schema.wallBlockExterior__pi_thermalConductivity,
    ];

    let errorFields = [];

    if (isCustomSpecification) {
      errorFields = requiredFields.filter((field) => {
        const value = getQuantityValue(field);
        return value === null || value === undefined || value === '';
      });
    }

    if (errorFields.length > 0) {
      isValid = false;
      setCustomSpecificationError(errorFields);
    }

    if (isCustomCapex && !getQuantityValue(schema.wallBlockExterior__pi_customCapex)) {
      isValid = false;
      setCustomCapexError(true);
    }

    if (isValid) {
      setSaveError(false);
      setCustomSpecificationError([]);
      setCustomCapexError(false);
    } else {
      setSaveError(true);
    }
  };

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Intervention Cost Assumptions
        <CustomizedTooltips icon="info" title={<Typography>Changes will only affect this intervention </Typography>} placement="right" />
      </Typography>

      <Typography className={classes.textHeader4}>CAPEX (Rs/Block)</Typography>

      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {!isCustomCapex ? (
                <CheckCircleIcon className={classes.selectedCheckbox} />
              ) : (
                <RadioButtonUncheckedIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.wallBlockExterior__pi_capexSourceMultiplier, 'VENDOR');
                    updateQuantityValue(schema.wallBlockExterior__pi_customWallBlockSpecification, false);
                  }}
                />
              )}
              Selected Vendor
            </Typography>
          </Grid>

          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <Typography className={isCustomCapex ? classes.textconstantsDisabled : classes.textconstants}>
                {numberWithCommas(getQuantityValue(schema.wallBlockExterior_vendorCapex))} {getQuantityUnit(schema.wallBlockExterior_vendorCapex)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {isCustomCapex ? (
                <CheckCircleIcon className={classes.selectedCheckbox} />
              ) : (
                <RadioButtonUncheckedIcon
                  className={classes.nonselectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.wallBlockExterior__pi_capexSourceMultiplier, 'CUSTOM');
                  }}
                />
              )}
              Custom
            </Typography>
          </Grid>

          <Grid xs={9} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <ResetFields
              id="outlined-basic"
              variant="outlined"
              value={getQuantityValue(schema.wallBlockExterior__pi_customCapex)}
              placeholder=""
              onChange={(e) => {
                e.preventDefault();
                updateQuantityValue(schema.wallBlockExterior__pi_customCapex, e.target.value);
              }}
              fullWidth
              required
              disabled={!isCustomCapex}
              removeResetIcon={true}
              isError={customCapexError}
              unit={getQuantityUnit(schema.wallBlockExterior__pi_customCapex)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Custom Wall Block Specifications
      </Typography>

      <Typography className={classes.textHeader3}>
        {isCustomSpecification ? (
          <CheckBoxIcon
            style={{ cursor: 'pointer', color: '#1FDEDB', marginRight: '10px' }}
            onClick={() => updateQuantityValue(schema.wallBlockExterior__pi_customWallBlockSpecification, !isCustomSpecification)}
          />
        ) : (
          <CheckBoxOutlineBlankIcon
            style={{ cursor: 'pointer', color: '#1FDEDB', marginRight: '10px' }}
            onClick={() => updateQuantityValue(schema.wallBlockExterior__pi_customWallBlockSpecification, !isCustomSpecification)}
          />
        )}
        I have custom Wall Block specifications
      </Typography>

      {isCustomSpecification && (customCapexError || customSpecificationError.length) ? (
        <Typography className={classes.textHeader3} style={{ color: 'rgba(237, 94, 104, 1)' }}>
          All fields are mandatory including Custom CAPEX
        </Typography>
      ) : null}

      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.wallBlockExterior__pi_blockWeight)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.wallBlockExterior__pi_blockWeight)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.wallBlockExterior__pi_blockWeight, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.wallBlockExterior__pi_blockWeight, getQuantityDefaultValue(schema.wallBlockExterior__pi_blockWeight));
                }}
                disabled={!isCustomSpecification}
                isError={customSpecificationError.includes(schema.wallBlockExterior__pi_blockWeight)}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.wallBlockExterior__pi_blockWeight)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.wallBlockExterior__pi_blockThickness)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.wallBlockExterior__pi_blockThickness)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.wallBlockExterior__pi_blockThickness, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.wallBlockExterior__pi_blockThickness, getQuantityDefaultValue(schema.wallBlockExterior__pi_blockThickness));
                }}
                disabled={!isCustomSpecification}
                isError={customSpecificationError.includes(schema.wallBlockExterior__pi_blockThickness)}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.wallBlockExterior__pi_blockThickness)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.wallBlockExterior__pi_blockVolume)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              {/* todo - decimal till 4th place */}
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.wallBlockExterior__pi_blockVolume)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.wallBlockExterior__pi_blockVolume, e.target.value, updateQuantityValue);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.wallBlockExterior__pi_blockVolume, getQuantityDefaultValue(schema.wallBlockExterior__pi_blockVolume));
                }}
                disabled={!isCustomSpecification}
                isError={customSpecificationError.includes(schema.wallBlockExterior__pi_blockVolume)}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.wallBlockExterior__pi_blockVolume)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '3%', paddingBottom: '2%' }}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.wallBlockExterior__pi_carbonEmissions)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              {/* todov - decimal till 2 place */}
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.wallBlockExterior__pi_carbonEmissions)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.wallBlockExterior__pi_carbonEmissions, e.target.value, updateQuantityValue);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.wallBlockExterior__pi_carbonEmissions, getQuantityDefaultValue(schema.wallBlockExterior__pi_carbonEmissions));
                }}
                disabled={!isCustomSpecification}
                isError={customSpecificationError.includes(schema.wallBlockExterior__pi_carbonEmissions)}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.wallBlockExterior__pi_carbonEmissions)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.wallBlockExterior__pi_thermalConductivity)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              {/* todo - decimal till 1 place */}
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.wallBlockExterior__pi_thermalConductivity)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.wallBlockExterior__pi_thermalConductivity, e.target.value, updateQuantityValue);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(
                    schema.wallBlockExterior__pi_thermalConductivity,
                    getQuantityDefaultValue(schema.wallBlockExterior__pi_thermalConductivity)
                  );
                }}
                disabled={!isCustomSpecification}
                isError={customSpecificationError.includes(schema.wallBlockExterior__pi_thermalConductivity)}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.wallBlockExterior__pi_thermalConductivity)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WallblockExteriorCustomAdvancedParameters;
