// import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
// import { Link } from 'react-router-dom';
// import ShowSaveAndCancel from './showSaveAndCancel';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ResetFields from '../common/textFiledwithReset';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import useStyles from './styles';
import CustomDropdown from '../common/CustomDropdown';

const InteriorPaintsCustomBasicParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <>
        <Grid container item xs={12} style={{ paddingTop: '1%' }}>
          <Grid item xs={3}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.interiorPaints_brand)}</Typography>
            <Typography className={classes.textHeader3}>{getQuantityValue(schema.interiorPaints_brand)}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.interiorPaints_productName)}</Typography>
            <Typography className={classes.textHeader3}>{getQuantityValue(schema.interiorPaints_productName)}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.interiorPaints_vocLevel)}</Typography>
            <Typography className={classes.textHeader3}>{getQuantityValue(schema.interiorPaints_vocLevel)}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.interiorPaints_quantity)}</Typography>
            <Typography className={classes.textHeader3}>
              {getQuantityValue(schema.interiorPaints_quantity)} {getQuantityUnit(schema.interiorPaints_quantity)}
            </Typography>
          </Grid>
        </Grid>
      </>
      <Grid container item xs={12} style={{ paddingTop: '50px', paddingBottom: '20px' }}></Grid>
    </Grid>
  );
};

export default InteriorPaintsCustomBasicParameters;
