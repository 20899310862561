import { api } from './http';

const getProjectTargets = async (projectId) => {
  const response = await api.get(`/v1/projectTarget?project=${projectId}`);
  return response;
};

const putProjectTargets = async ({ targetId, projectId, status }) => {
  const response = await api.put(`/v1/projectTarget/${targetId}`, { status, projectId });
  return response;
};

const getGoalsScheme = async () => {
  const response = await api.get(`/v1/goalsschemev2`);
  return response;
};

export { getProjectTargets, putProjectTargets, getGoalsScheme };
