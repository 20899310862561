import React, { useState, useEffect } from 'react';
import { Grid, Typography, IconButton, makeStyles } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { DeleteOutlineSharp } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RefreshIcon from '@material-ui/icons/Refresh';
import { normalizeFileName } from '../../../interventions/intervention/utils';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  addBoxIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  browseLabel: {
    fontSize: 16,
    cursor: 'pointer',
  },
  errorText: {
    color: '#ED5E68',
  },
  tryAgain: {
    color: '#00C58A',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  successText: {
    color: '#00C58A',
  },
  fileLink: {
    color: '#00C58A',
    fontFamily: 'Lato',
    fontSize: 16,
    fontWeight: 700,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  deleteIcon: {
    width: 24,
    height: 24,
    cursor: 'pointer',
    color: '#EE8D8E',
  },
}));

function AddFileForCustomIntervention(props) {
  const classes = useStyles();
  const {
    setFileToDeleteLocally,
    updateState,
    uploadError,
    selectedFile,
    sizeError,
    fileError,
    filename,
    fileLink,
    startUpload,
    isSuccess,
    handleUploadClick,
    handleOpenFile,
    setSizeError,
    setFileError,
    setUploadError,
    duplicate,
    setDuplicate,
    resetOnClick,
    customInterventionFileNames,
    setIsFilePresentLocally,
    fileToDeleteLocally,
  } = props;

  const [combinedFiles, setCombinedFiles] = useState([]);
  const [updatedFiles, setUpdatedFiles] = useState([]);

  useEffect(() => {
    const selectedFileNames = Array.isArray(selectedFile) ? selectedFile.map((file) => file.name) : selectedFile?.name ? [selectedFile.name] : [];
    const filteredCustomInterventionFileNames = customInterventionFileNames.filter((fileName) => !updatedFiles?.includes(fileName));
    setCombinedFiles([...selectedFileNames, ...filteredCustomInterventionFileNames]);
  }, [selectedFile, customInterventionFileNames]);

  useEffect(() => {
    setIsFilePresentLocally(combinedFiles.length > 0);
  }, [combinedFiles]);
  const deleteLocally = (fileToDelete) => {
    setFileToDeleteLocally((prevFiles) => [...prevFiles, normalizeFileName(fileToDelete)]);
    setCombinedFiles((prevFiles) => prevFiles.filter((currentFile) => currentFile !== fileToDelete));
  };

  useEffect(() => {
    fileToDeleteLocally?.length && setUpdatedFiles(fileToDeleteLocally);
  }, [fileToDeleteLocally]);

  return (
    <Grid container direction="column">
      <Typography variant="h5">Add file (optional)</Typography>
      <Typography variant="body1" color="textSecondary">
        Supported formats - <b>.rvt, .dwg, .skp, .doc / .docx, .xls / .xlsx, .pdf</b>
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Max file size 50 MB. Please provide link for larger files
      </Typography>
      <Grid item container alignItems="center" className={classes.container}>
        <AddBoxIcon className={classes.addBoxIcon} style={{ color: filename && !fileLink ? '#00C58A' : '#9E9E9E' }} />
        <input
          style={{ display: 'none' }}
          onClick={resetOnClick}
          id="upload-photo"
          name="upload-photo"
          type="file"
          onChange={handleUploadClick}
          disabled={!!fileLink}
          multiple
        />
        <label onClick={resetOnClick} htmlFor="upload-photo" className={classes.browseLabel} style={{ color: filename && !fileLink ? '#00C58A' : '#9E9E9E' }}>
          Browse
        </label>
      </Grid>
      {sizeError && (
        <Typography variant="h6" className={classes.errorText}>
          <b>
            File size exceeds limit
            <span className={classes.tryAgain} onClick={() => setSizeError(false)}>
              Try Again
              <RefreshIcon />
            </span>
          </b>
        </Typography>
      )}
      {duplicate && (
        <Typography variant="h6" className={classes.errorText}>
          <b>
            File with same name already exists
            <span className={classes.tryAgain} onClick={() => setDuplicate(false)}>
              Try Again
              <RefreshIcon />
            </span>
          </b>
        </Typography>
      )}
      {fileError && (
        <Typography variant="h6" className={classes.errorText}>
          <b>
            Incompatible file type
            <span className={classes.tryAgain} onClick={() => setFileError(false)}>
              Try Again
              <RefreshIcon />
            </span>
          </b>
        </Typography>
      )}
      {startUpload && (
        <Typography variant="h6" className={classes.successText}>
          <CircularProgress style={{ color: '#00C58A' }} size={20} /> {selectedFile.name}
        </Typography>
      )}
      {isSuccess && (
        <Typography variant="h6" className={classes.successText}>
          <CheckCircleIcon style={{ color: '#00C58A', size: '15px' }} /> {selectedFile.name}
        </Typography>
      )}
      {uploadError && (
        <Typography variant="h6" className={classes.errorText}>
          <b>
            Error Uploading File.
            <span className={classes.tryAgain} onClick={() => setUploadError(false)}>
              Try Again
              <RefreshIcon />
            </span>
          </b>
        </Typography>
      )}
      {combinedFiles.length > 0 && (
        <Grid container direction="column" style={{ marginTop: '10px' }}>
          {combinedFiles.map((file, index) => (
            <Grid container alignItems="center" key={index} style={{ marginBottom: '10px' }}>
              <Typography
                onClick={() => {
                  handleOpenFile(file);
                }}
                className={classes.fileLink}
              >
                {file}
              </Typography>
              <DeleteOutlineSharp
                className={classes.deleteIcon}
                onClick={() => {
                  deleteLocally(file);
                }}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Typography variant="body1" style={{ marginTop: '20px' }}>
        Or
      </Typography>
    </Grid>
  );
}

export default AddFileForCustomIntervention;
