import { Drawer, makeStyles, useTheme } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Joyride, { EVENTS, ACTIONS, LIFECYCLE } from 'react-joyride';
import swal from 'sweetalert';
import TourModal from '../v2/tour/TourModal';
import { useHistory } from 'react-router-dom';
import { TOUR_STATES, COMPLETE_TOUR, START_TOUR } from '../v2/utils/tour';
import api, { queryKeys } from '../http';
import { useQuery } from '@tanstack/react-query';

const ProductTourContextData = React.createContext(null);
export const useAppProductTour = () => {
  const context = useContext(ProductTourContextData);
  if (!context) {
    throw new Error('useAppProductTour should be used inside ProductTourContext');
  }
  return context;
};

const Complete = (props) => {
  const classes = useStyles();
  return (
    <div className={clsx([classes.tourEachStepIcon, classes.tourStepComplete])} {...props}>
      <CheckIcon style={{ margin: 8 }} />
    </div>
  );
};

const Current = (props) => {
  const classes = useStyles();

  return (
    <div className={clsx([classes.tourEachStepIcon, classes.tourStepCurrent])} {...props}>
      <FiberManualRecordIcon style={{ margin: 8 }} />
    </div>
  );
};

const NotStarted = (props) => {
  const classes = useStyles();

  return <div className={clsx([classes.tourEachStepIcon, classes.tourStepNotStarted])} {...props}></div>;
};

const Incomplete = (props) => {
  const classes = useStyles();

  return (
    <div className={clsx([classes.tourEachStepIcon, classes.tourStepIncomplete])} {...props}>
      <PlayArrowIcon style={{ margin: 8 }} />
    </div>
  );
};

const StepIndex = ({ content }) => {
  return <div style={{ position: 'absolute', bottom: 22 }}>{content}</div>;
};

const ProductTourContext = (props) => {
  const { userDetails, onCallback = () => {} } = props;
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = useState({
    showHowItWorks: false,
    showBasics: false,
    tourActive: false, // An introduced boolean value to temporarily on/off tour from CASA/FE
    stepIndex: 0,
    isTourFinished: false,
  });

  const { data: userCurrentProjectData, isError: isErrorUserCurrentProject } = useQuery({
    queryKey: [queryKeys.userCurrentProject, userDetails?.organisation?._id],
    queryFn: () => api.getCurrentProject(userDetails?.organisation?._id),
    enabled: !!userDetails && state?.showHowItWorks && state?.tourActive,
  });

  const getParamsFromPath = (historyObject, pathIndex) => {
    return historyObject?.location?.pathname?.split('/')[pathIndex];
  };

  const getTourSteps = (history) => {
    return [
      {
        target: '.project-create-button',
        content: (
          <>
            <div>Start your journey by creating a new project.</div>
            {/* <div style={{ fontWeight: 600, marginTop: 16 }}>Click on "Create Project" to continue</div> */}
            <StepIndex content={'1/1'} />
          </>
        ),
        data: {
          currentStep: 'My Projects',
          nextStep: 'Project Details',
          next: `/projects/create`,
        },
        disableBeacon: true,
        title: 'Create a New Project',
      },
      {
        target: '.basic-project-details',
        title: 'Basic Project Details',
        placement: 'right-start',
        content: (
          <>
            <div>This page asks the basic details that are mandatory to create a new project.</div>
            <div style={{ marginTop: 16 }}>Please fill out all the details and click "Continue"</div>
            <StepIndex content={'1/2'} />
          </>
        ),
        data: {
          currentStep: 'Project Details',
        },
        disableBeacon: true,
        hideBackButton: true,
      },
      {
        target: '.Land-Details',
        title: 'Project Details',
        placement: 'bottom-start',
        content: (
          <>
            <div>
              This section provides detailed information about your residential project, organised into 8 easy-to-use categories. The more data you provide, the
              richer and more accurate your intervention results will be.
            </div>
            <StepIndex content={'2/2'} />
          </>
        ),
        data: {
          next: `/projects/${getParamsFromPath(history, 2)}/goals-insights`,
          currentStep: 'Project Details',
          nextStep: 'Goals',
        },
        disableBeacon: true,
        hideBackButton: true,
      },
      {
        target: '.net-zero-goals-insights',
        title: 'Net Zero Goals',
        placement: 'bottom-start',
        content: (
          <>
            <div>
              Each project is assigned four goals across Energy, Water, Waste, and Materials. Achieving 100% completion in each category leads to absolute
              sustainability. Review each goal to assess its current status.
            </div>
            <StepIndex content={'1/2'} />
          </>
        ),
        data: {
          currentStep: 'Goals',
        },
        disableBeacon: true,
      },
      {
        target: '.achievable-targets-goals-insights',
        title: 'Targets',
        placement: 'bottom-start',
        content: (
          <>
            <div>
              To aid in achieving your goals, each category includes one or more targets. These targets focus your budget and design changes on marketable
              achievements, bringing you closer to net zero. Review each target's status, progress, tier, and cost implications.
            </div>
            <StepIndex content={'2/2'} />
          </>
        ),
        data: {
          next: `/projects/${getParamsFromPath(history, 2)}/insights`,
          currentStep: 'Goals',
          nextStep: 'Insights',
        },
        disableBeacon: true,
      },
      {
        target: '.sustainability-level-insights',
        title: 'Sustainability Level',
        content: (
          <>
            <div>
              Here you can see your sustainability level compared to your base case. This helps you understand how your efforts are paying off and how much you
              can improve.
            </div>
            <StepIndex content={'1/4'} />
          </>
        ),
        data: {
          currentStep: 'Insights',
        },
        disableBeacon: true,
      },
      {
        target: '.financial-insights-timeframe',
        title: 'Financial Insights',
        placement: 'bottom-start',
        content: (
          <>
            <div>This section gives you a clear picture of the financial benefits of your sustainability initiatives over the years.</div>
            {/* <div style={{ fontWeight: 600, marginTop: 16 }}>Please change the timeframe from 3 years to 10 years to continue.</div> */}
            <StepIndex content={'2/4'} />
          </>
        ),
        data: {
          currentStep: 'Insights',
        },
        disableBeacon: true,
      },
      {
        target: '.interventions-insights',
        title: 'Interventions Card',
        placement: 'right-end',
        content: (
          <>
            <div>This section shows all your active interventions. You can filter these interventions by various metrics.</div>
            {/* <div style={{ fontWeight: 600, marginTop: 16 }}>Change the filter from 'Top CAPEX' to 'Energy Savings' to continue.</div> */}
            <StepIndex content={'3/4'} />
          </>
        ),
        data: {
          currentStep: 'Insights',
        },
        disableBeacon: true,
      },
      {
        target: '.goals-summary-insights',
        title: 'Net Zero Goals Summary',
        placement: 'left-start',
        content: (
          <>
            <div>
              Here, you'll find a concise overview of the goals and targets you've set. This helps you stay focused on your sustainability journey and
              understand what's been accomplished. Ready to see how you can achieve these goals through various interventions?
            </div>
            <StepIndex content={'4/4'} />
          </>
        ),
        data: {
          next: `/projects/${getParamsFromPath(history, 2)}/interventions`,
          currentStep: 'Insights',
          nextStep: 'Intervention',
        },
        disableBeacon: true,
      },
      {
        target: '.financial-impacts-summary',
        title: 'Impact Summary',
        placement: 'top-start',
        content: (
          <>
            <div>Here, you can look at the financial and environmental impacts of each category—energy, water, waste, and materials.</div>
            <StepIndex content={'1/6'} />
          </>
        ),
        data: {
          currentStep: 'Intervention',
        },
        disableBeacon: true,
      },
      {
        target: '.show-flow-diagram-summary',
        title: 'Flow Diagram',
        placement: 'right-end',
        content: (
          <>
            <div>Check out the Flow Diagram. This visual representation shows how resources are consumed in your project.</div>
            <div style={{ fontWeight: 600, marginTop: 16 }}>Please click the 'Show Flow Diagram' button.</div>
            <StepIndex content={'2/6'} />
          </>
        ),
        data: {
          currentStep: 'Intervention',
        },
        disableBeacon: true,
      },
      {
        target: '.flow-diagram-modal-summary',
        title: 'Flow Diagram',
        placement: 'left-start',
        content: (
          <>
            <div>
              This view illustrates how energy is consumed in your project. Take a moment to analyze how resources are being used. When you are done, close this
              diagram to continue.
            </div>
            <StepIndex content={'3/6'} />
          </>
        ),
        data: {
          currentStep: 'Intervention',
        },
        disableBeacon: true,
      },
      {
        target: '.interventions-table-summary',
        title: 'Interventions Table',
        placement: 'top',
        content: (
          <>
            <div>
              Here, you'll find a list of all available interventions, along with their financial and resource impacts. This table is your go-to resource for
              identifying solutions that can enhance sustainability in your project.
            </div>
            <StepIndex content={'4/6'} />
          </>
        ),
        data: {
          currentStep: 'Intervention',
        },
        disableBeacon: true,
      },
      {
        target: '.add-intervention-summary',
        title: 'Add Custom Intervention',
        placement: 'left',
        content: (
          <>
            <div>
              Got an intervention that's not listed? No problem! You can create your own intervention here if your project has unique needs or solutions not
              covered in the table. Tailor your sustainability strategy to fit your project.
            </div>
            <StepIndex content={'5/6'} />
          </>
        ),
        data: {
          currentStep: 'Intervention',
        },
        disableBeacon: true,
      },
      {
        target: '.intervention-details-summary',
        title: 'Intervention Details',
        placement: 'right-end',
        content: (
          <>
            <div>Get ready—you're entering the final section of the product tour!</div>
            <div style={{ fontWeight: 600, marginTop: 16 }}>Click on following intervention to continue.</div>
            <StepIndex content={'6/6'} />
          </>
        ),
        data: {
          currentStep: 'Intervention',
          nextStep: 'Intervention Details',
        },
        disableBeacon: true,
      },
      {
        target: '.intervention-details-parameters',
        title: 'Intervention Parameters',
        placement: 'right-start',
        content: (
          <>
            <div>
              View detailed information about the intervention, like capacity and area requirements. Edit these parameters to suit your project's needs.
            </div>
            <StepIndex content={'1/7'} />
          </>
        ),
        data: {
          currentStep: 'Intervention Details',
        },
        disableBeacon: true,
      },
      {
        target: '.intervention-details-results',
        title: 'Intervention Results',
        placement: 'right-start',
        content: (
          <>
            <div>
              View detailed financial information, along with the intervention's impact on resource savings. Assess how it contributes to your sustainability
              goals.
            </div>
            <StepIndex content={'2/7'} />
          </>
        ),
        data: {
          currentStep: 'Intervention Details',
        },
        disableBeacon: true,
      },
      {
        target: '.intervention-details-overview',
        title: 'Overview Graph',
        placement: 'right-start',
        content: (
          <>
            <div>
              This section features a bar graph illustrating resource savings, broken down into real-world usage for a clearer understanding of your
              intervention's impact.
            </div>
            <StepIndex content={'3/7'} />
          </>
        ),
        data: {
          currentStep: 'Intervention Details',
        },
        disableBeacon: true,
      },
      {
        target: '.intervention-details-secondary-impact',
        title: 'Secondary Impact',
        placement: 'right-start',
        content: (
          <>
            <div>This section shows how an intervention in one category—like energy, waste, materials, or water—positively affects another category.</div>
            <StepIndex content={'4/7'} />
          </>
        ),
        data: {
          currentStep: 'Intervention Details',
        },
        disableBeacon: true,
      },
      {
        target: '.intervention-details-selected-product',
        title: 'Selected Product',
        placement: 'right-start',
        content: (
          <>
            <div>
              Each intervention comes with a default recommended product. You can add more solutions from the SD+ marketplace, compare options, and select the
              best fit for your project's needs.
            </div>
            <StepIndex content={'5/7'} />
          </>
        ),
        data: {
          currentStep: 'Intervention Details',
        },
        disableBeacon: true,
      },
      {
        target: '.intervention-details-configure-intervention',
        title: 'Configure Intervention',
        placement: 'left-start',
        content: (
          <>
            <div>
              Access advanced controls for this intervention. Here, you can edit capacity, cost assumptions and assumed constants to customise the parameters
              according to your project requirements.
            </div>
            <div style={{ fontWeight: 600, marginTop: 16 }}>Please click the 'Configure' button.</div>
            <StepIndex content={'6/7'} />
          </>
        ),
        data: {
          currentStep: 'Intervention Details',
        },
        disableBeacon: true,
      },
      {
        target: '.intervention-details-configure-parameters',
        title: 'Intervention Parameters',
        placement: 'left-start',
        content: (
          <>
            <div>
              Access detailed customization options for each intervention. Divided into three sections, this area allows you to optimize settings and achieve
              the optimal results.
            </div>
            <StepIndex content={'7/7'} />
          </>
        ),
        data: {
          currentStep: 'Intervention Details',
        },
        disableBeacon: true,
      },
      {
        target: '.intervention-details-parameters-save',
        title: 'You are All Set!',
        placement: 'top',
        content: (
          <>
            <div>Click "Save" to complete your tour!</div>
          </>
        ),
        locale: {
          last: 'Close',
        },
        data: {
          currentStep: 'Intervention Details',
        },
        disableBeacon: true,
      },
    ];
  };

  const tour = useMemo(() => {
    return userDetails?.onboarding?.tour || [];
  }, [userDetails]);
  const activeStepsFromTour = tour?.filter((tourStep) => tourStep?.active);
  const tourSteps = useMemo(() => getTourSteps(history), [history?.location?.pathname]);

  const [collapseDrawer, setCollapseDrawer] = useState(false);
  const handleUpdateState = (updates) => {
    return setState((prev) => ({ ...prev, ...updates }));
  };

  useEffect(() => {
    if (userDetails) {
      setState((prev) => ({
        ...prev,
        showHowItWorks: userDetails?.onboarding?.showHowItWorks,
        showBasics: userDetails?.onboarding?.showBasics,
      }));
    }
  }, [userDetails]);

  const handleCollapseDrawer = () => {
    return setCollapseDrawer((prev) => !prev);
  };

  const handleEnd = async () => {
    if (userDetails) {
      await api.postUserOnboarding(userDetails?._id, {
        onboarding: { showHowItWorks: false, showBasics: userDetails?.onboarding?.showBasics, tour: COMPLETE_TOUR },
      });
      await onCallback();
    }
  };

  const handleUpdateTour = async (updatedTour) => {
    if (userDetails) {
      await api.postUserOnboarding(userDetails?._id, { onboarding: { showHowItWorks: true, showBasics: true, tour: updatedTour } });
      await onCallback();
    }
  };

  const handleExit = () => {
    return swal({
      title: `Sure you want to exit the product tour?`,
      text: 'You can resume or start the tour again from the help section.',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await handleEnd();
      }
    });
  };

  const handleReset = async () => {
    if (userDetails) {
      await api.postUserOnboarding(userDetails?._id, {
        onboarding: { showHowItWorks: true, showBasics: userDetails?.onboarding?.showBasics, tour: START_TOUR },
      });
      onCallback();
    }
  };

  const handleResumeTour = async (step) => {
    if (userCurrentProjectData) {
      const currentProjectId = userCurrentProjectData?._id;
      if (step.type === 'My Projects') {
        return history.push(`/projects`);
      } else if (step.type === 'Project Details') {
        return history.push(`/projects/create`);
      } else if (step.type === 'Goals') {
        return history.push(`/projects/${currentProjectId}/goals-insights`);
      } else if (step.type === 'Insights') {
        return history.push(`/projects/${currentProjectId}/insights`);
      } else if (step.type === 'Intervention') {
        return history.push(`/projects/${currentProjectId}/interventions`);
      } else if (step.type === 'Intervention Details') {
        return history.push(`/projects/${currentProjectId}/interventions`);
      } else if (step.type === 'Certification') {
        return history.push(`/projects/${currentProjectId}/certification`);
      } else if (step.type === 'Reports') {
        return history.push(`/projects/${currentProjectId}/reports`);
      }
    }
  };

  const handleCallback = (callbackData) => {
    const { action, index, step, lifecycle, type } = callbackData;
    if (type === EVENTS.TOUR_END) {
      handleUpdateState({ isTourFinished: true });
    }

    if (action === ACTIONS.CLOSE) {
      handleUpdateState({ showHowItWorks: false });
    } else {
      if (lifecycle === LIFECYCLE.COMPLETE) {
        if (action === ACTIONS.PREV) {
          handleUpdateState({ stepIndex: index - 1 });
          if (step?.data?.previous) {
            history.goBack();
          }
        } else if (action === ACTIONS.NEXT) {
          if (step?.data?.nextStep) {
            // Update the status in the tour, once a particular step section is completed
            const updatedTour = tour?.map((tourStep) => {
              if (tourStep.type === step?.data?.nextStep) {
                return { ...tourStep, status: 'incomplete' };
              } else if (tourStep.type === step?.data?.currentStep) {
                return { ...tourStep, status: 'complete' };
              } else {
                return tourStep;
              }
            });
            handleUpdateTour(updatedTour);
          }
          handleUpdateState({ stepIndex: index + 1 });
          if (step?.data?.next) {
            history.push(step?.data?.next);
          }
        }
      }
    }
  };

  const currentStep = useMemo(() => {
    const allSteps = getTourSteps(history);
    return allSteps[state?.stepIndex];
  }, [history, state?.stepIndex]);
  const open = useMemo(() => state.showHowItWorks && state?.tourActive, [state.showHowItWorks, state?.tourActive]);
  return (
    <>
      <Joyride
        key={`joyride-${state?.stepIndex}`} // Force remount on index change
        callback={handleCallback}
        disableCloseOnEsc
        disableOverlayClose
        continuous
        hideCloseButton={false}
        showSkipButton={false}
        hideBackButton={true}
        run={open}
        stepIndex={state?.stepIndex}
        scrollOffset={80}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Last',
          next: 'Next',
          nextLabelWithProgress: 'Next',
          open: 'Open the tour',
          skip: 'Skip',
        }}
        styles={{
          options: {
            arrowColor: '#C6F2CD',
          },
          tooltip: {
            borderRadius: theme.spacing(1.5),
            boxShadow: theme.shadows[2],
            border: `2px solid #C6F2CD`,
            textAlign: 'left',
          },
          overlay: {
            opacity: 0,
          },
          tooltipTitle: {
            textAlign: 'left',
            color: '#151619',
          },
          tooltipContent: {
            textAlign: 'left',
            padding: `${theme.spacing(2)}px 0px`,
            color: ' #32363E',
            fontWeight: 500,
            fontSize: 14,
          },
          buttonNext: {
            background: 'linear-gradient(90deg, #1FDEDB 0%, #42E198 100%, #42E198 100%)',
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1.5, 2),
            textTransform: 'uppercase',
            fontSize: 14,
            letterSpacing: 1.1,
          },
          buttonBack: {
            background: '#F1F2F4',
            borderRadius: theme.spacing(1),
            padding: theme.spacing(1.5, 2),
            color: '#151619',
            textTransform: 'uppercase',
            fontSize: 14,
            letterSpacing: 1.1,
          },
        }}
        steps={tourSteps}
      />
      <ProductTourContextData.Provider value={{ state, setState: handleUpdateState, handleEnd, handleStart: handleReset }}>
        <>
          {props.children}
          <div
            className={clsx(classes.drawerMain, {
              [classes.hideDrawer]: !open,
            })}
          >
            <div className={classes.toolbar}>
              <ExpandMoreIcon
                className={clsx(classes.toggleIcon, {
                  [classes.toggleIconClosed]: collapseDrawer,
                })}
                onClick={() => handleCollapseDrawer()}
              />
            </div>
            <Drawer
              className={clsx({
                [classes.drawerOpen]: !collapseDrawer,
                [classes.drawerClose]: collapseDrawer,
                [classes.hideDrawer]: !open,
              })}
              classes={{
                paper: clsx(classes.drawerPaper, {
                  [classes.drawerOpen]: !collapseDrawer,
                  [classes.drawerClose]: collapseDrawer,
                }),
              }}
              anchor="bottom"
              variant="permanent"
              open={open}
              onClose={() => setState((prev) => ({ ...prev, showHowItWorks: false }))}
            >
              <div
                className={clsx(classes.tourSteps, {
                  [classes.hidden]: collapseDrawer,
                })}
              >
                {activeStepsFromTour?.map((step, index) => (
                  <div className={classes.tourEachStep} key={index}>
                    <div className={classes.tourEachStepIcon}>
                      {step.status === TOUR_STATES.complete ? (
                        <Complete />
                      ) : step.status === TOUR_STATES.current ? (
                        <Current />
                      ) : step.status === TOUR_STATES.incomplete ? (
                        <Incomplete
                          style={{ cursor: 'pointer' }}
                          // onClick={() => handleResumeTour(step)} // TODO:
                        />
                      ) : step.status === TOUR_STATES.notStarted ? (
                        <NotStarted />
                      ) : (
                        <NotStarted />
                      )}
                    </div>
                    <div className={classes.tourEachStepText}>{step.type}</div>
                  </div>
                ))}
                <div className={classes.exitStep} onClick={() => handleExit()}>
                  Exit
                </div>
              </div>
            </Drawer>
          </div>
          {/* <div style={{ zIndex: 201, margin: 100 }} onClick={() => handleReset()}>
            RESET
          </div> */}
          {/* Add minimal margin below to avoid buttons being hidden */}
          <div style={{ marginBottom: open ? 80 : 0 }}></div>
          <TourModal step={currentStep} showModal={open} isTourFinished={state?.isTourFinished} handleEndTour={handleEnd} />
        </>
      </ProductTourContextData.Provider>
    </>
  );
};

export default ProductTourContext;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    borderTop: 'none',
    boxShadow: theme.shadows[2],
    padding: theme.spacing(1),
  },
  drawerMain: {
    position: 'fixed',
    bottom: 0,
    right: 8,
    background: '#FFF',
    borderRadius: '8px 8px 0 0',
    zIndex: 201,
  },
  drawerOpen: {
    height: 96,
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    display: 'flex',
    justifyContent: 'center',
  },
  drawerClose: {
    height: 12,
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowY: 'hidden',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    boxShadow: theme.shadows[2],
    borderRadius: '8px 8px 0 0',
  },
  toggleIcon: {
    fontSize: 32,
    cursor: 'pointer',
  },
  toggleIconClosed: {
    transform: 'rotate(180deg)',
  },
  tourSteps: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tourEachStepIcon: {
    borderRadius: '50%',
    width: 38,
    height: 38,
    fontSize: 22,
    marginBottom: theme.spacing(1),
  },
  tourEachStepText: {
    fontSize: 10,
    color: '#6F6F6F',
  },
  tourEachStep: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 80,
  },
  tourStepComplete: {
    background: 'linear-gradient(90deg, #1FDEDB 0%, #42E198 100%, #42E198 100%)',
    color: '#FFF',
  },
  tourStepCurrent: {
    border: `1px solid #42E198`,
    color: ` #42E198`,
    fontSize: 20,
  },
  tourStepNotStarted: {
    border: '1px solid #C6C6C6',
  },
  tourStepIncomplete: {
    background: 'linear-gradient(90deg, #1FDEDB 0%, #42E198 100%, #42E198 100%)',
    color: '#FFF',
  },
  hidden: {
    visibility: 'hidden',
  },
  exitStep: {
    color: theme.palette.error.light,
    fontSize: 14,
    marginLeft: theme.spacing(1),
    fontWeight: 500,
    cursor: 'pointer',
  },
  hideDrawer: {
    display: 'none',
  },
}));
