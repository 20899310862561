import React, { useState } from 'react';
import { useParams } from 'react-router';

import { Button, Grid, Menu, MenuItem, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const HeadingAndButton = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const params = useParams();
  const history = useHistory();

  const open = Boolean(anchorEl);

  const handleClick = () => {
    setAnchorEl(document.getElementById('download-button'));
  };

  const moveToReport = (path) => {
    if ('reports' === path) history.push(`/projects/${params?.id}/reports`);
    else {
      window.open(`/project/${params?.id}/reports/${path}`);
    }
  };
  const onClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Grid container alignItems="center" item xs={12} className={props.className}>
        <Grid item xs={2} align="left">
          <Typography variant="h3" color="textPrimary" gutterBottom>
            {props.heading}
          </Typography>
        </Grid>
        <Grid item xs />
        <Grid display="flex" alignItems="center" item xs={3} data-tour="report">
          <div id="download-button" style={{ display: 'flex', alignItems: 'center' }} gap={0.5}>
            <Button
              style={{
                color: '#ffffff',
                background: 'linear-gradient(90deg, #1FDEDB 0.58%, #42E198 100%)',
                borderRadius: '40px',
                '&:focus': {
                  outline: 'none',
                },
                minHeight: '30px',
                width: '220px',
                padding: '10px',
                fontSize: '12px',
                fontWeight: 900,
                lineHeight: '20.48px',
                letterSpacing: '1px',
                textAlign: 'center',
                textUnderlinePosition: 'from-font',
                textDecorationSkipInk: 'none',
              }}
              children="download report"
              onClick={() => {
                moveToReport('project-sustainability-report');
              }}
            />
            <ArrowDropDownIcon onClick={handleClick} style={{ cursor: 'pointer', color: '#49DDB1', height: '40px', width: '40px' }} xs={2} />
          </div>
          <Menu
            id="download-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            MenuListProps={{
              'aria-labelledby': 'download-button',
            }}
            PaperProps={{
              style: {
                maxHeight: 300, // Limit dropdown height
                minWidth: 250, // Minimum dropdown width
                marginTop: '50px',
              },
            }}
          >
            <MenuItem
              onClick={() => {
                moveToReport('project-sustainability-report');
              }}
            >
              Project Sustainability Report
            </MenuItem>
            <MenuItem
              onClick={() => {
                moveToReport('executive-report');
              }}
            >
              Executive Report
            </MenuItem>
            <MenuItem
              onClick={() => {
                moveToReport('sdplus-impact-report');
              }}
            >
              SD+ Impact Report
            </MenuItem>
            <MenuItem
              onClick={() => {
                moveToReport('energy-report');
              }}
            >
              Energy Use Impact Report
            </MenuItem>
            <MenuItem
              onClick={() => {
                moveToReport('water-report');
              }}
            >
              Water Use Impact Report
            </MenuItem>
            <MenuItem
              onClick={() => {
                moveToReport('waste-report');
              }}
            >
              Waste Management Impact Report
            </MenuItem>
            <MenuItem
              onClick={() => {
                moveToReport('materials-report');
              }}
            >
              Material Selection Impact Report
            </MenuItem>
            <MenuItem
              onClick={() => {
                moveToReport('reports');
              }}
              style={{ color: '#00E7B3', fontWeight: 'bold' }}
            >
              See all
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </>
  );
};

export default HeadingAndButton;
