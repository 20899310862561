import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MarketplaceColors } from '../../../config';
import { HoverText } from '../../../utils/HoverText';
import { useContext } from 'react';
import { ProductsPageContext } from '../../../../../../routing/IndexRouting';
import { Default, FilterSubSections } from '../../../utils/Defaults';
import Links from '../../../common/Typography/Links';

const { Primary, TextColorTertiary } = MarketplaceColors;

const useStyles = makeStyles((theme) => ({
  main: {
    borderRight: '1px solid #9E9E9E',
    paddingLeft: '15px',
  },
  heading: {
    color: Primary,
    cursor: 'pointer',
    marginTop: '1%',
  },
  subHeading: {
    lineHeight: '24px',
    marginTop: '2%',
    marginBottom: '2%',
  },
}));

const CategoriesLogicalGroup = ({ data, source, from, to, index }) => {
  const classes = useStyles();
  const { handleURL, handleReset, handleAppliedFilters, handleFilterData } = useContext(ProductsPageContext);

  return (
    <Grid xs={2} item className={classes.main}>
      {data
        ?.sort((a, b) => (a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0))
        .slice(from, to)
        ?.map(({ name, subcategories }) => (
          <Grid xs={12} style={{ marginBottom: '3%' }}>
            <Links
              variant="string"
              href={`/marketplace/categoryDetails?source=${source}&category=${name}&page=${Default.page}&pagesize=${Default.pagesize}`}
              tag={name}
              isBold={true}
              onClick={() => handleReset()}
              className={classes.heading}
            />
            {subcategories.map(({ attributes }) => {
              return (
                <Links
                  variant="string"
                  href={`/marketplace/categoryDetails?source=${source}&category=${name}&subcategory=${attributes?.name}&page=${Default.page}&pagesize=${Default.pagesize}`}
                  tag={HoverText(attributes?.name, Primary, TextColorTertiary, false)}
                  isBold={true}
                  onClick={() => {
                    handleReset();
                    // handleURL(name, attributes?.name, '', '', Default.page, Default.pagesize, source, '');
                    // handleAppliedFilters(FilterSubSections.SubCategory, attributes?.name, true);
                    // handleFilterData(FilterSubSections.SubCategory, attributes?.name, true);
                  }}
                  className={classes.subHeading}
                />
              );
            })}
          </Grid>
        ))}
    </Grid>
  );
};

export default CategoriesLogicalGroup;
