import { api } from './http';

const getProjectInterventionByProjectAndInterventionId = async (projectId, interventionId) => {
  const response = await api.get(`/v2/project-interventions/project/${projectId}/intervention/${interventionId}`);
  return response?.data?.data?.body?.data;
};

const deleteProjectInterventionSetById = async (payload) => {
  const res = await api.post(`/v2/project-interventions/intervention-set/remove-set`, payload);
  return res;
};

const updateProjectInterventionById = async (payload) => {
  const updatedResponse = await api.put(`/v2/project-interventions`, payload);
  return updatedResponse;
};

export { getProjectInterventionByProjectAndInterventionId, deleteProjectInterventionSetById, updateProjectInterventionById };
