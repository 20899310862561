import * as user from './user';
import * as project from './project';
import * as auth from './auth';
import * as organisation from './organisation';
import * as s3 from './s3';
import * as admin from './admin';
import * as customIntervention from './customIntervention';
import * as interventionDetails from './interventionDetails';
import * as projectIntervention from './projectIntervention';
import * as intervention from './intervention';
import * as noteTaking from './noteTaking';
import * as fileUpload from './fileUpload';
import * as houseKeeping from './houseKeeping';
import * as metafire from './metafire';
import * as goals from './goals';
import * as igbc from './igbc';
import * as pipscheme from './pipscheme';

// queryKeys for React-Query
import queryKeysList from './utils/queryKeys';

const apiList = {
  ...user,
  ...project,
  ...auth,
  ...organisation,
  ...s3,
  ...admin,
  ...customIntervention,
  ...interventionDetails,
  ...projectIntervention,
  ...intervention,
  ...noteTaking,
  ...fileUpload,
  ...houseKeeping,
  ...metafire,
  ...goals,
  ...igbc,
  ...pipscheme,
};

export default apiList;

export const queryKeys = queryKeysList;
