export const categoryDetailsQuery = (id) => `{category(id: ${id}) {data {id  attributes {name} id } }}`;
export const productDetailsQuery = (productID) =>
  `{
  product(id: ${productID}) {
    data {
      id
      attributes {
        catalog_sku_id
        sku_name
        sdplus_interventions
        environmental_impact_ecosystem_health
        environmental_impact_human_health
        environmental_impact_circularity
        environmental_impact_climate_health
        quality_assurance_and_reliability
        warranty_and_after_sales
        references
    		sdg
        brand{
          data{
            attributes{
              name
              manufacturing_locations
              region_of_operation
              poc
              email
              contact
            }
          }
        }
        short_description
        image
        description
        price
        brochure
        properties
        metadata
        certifications
      }
    }
  }
}`;
