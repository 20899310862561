import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { alpha } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { MarketplaceColors } from '../../../marketplace/config';
const CustomInput = withStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      padding: '13px 10px',
      height: '10px',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: '1px solid #2E2E2E;',
        borderRadius: '30px',
        width: '135px',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.38);',
      },
      '&.Mui-focused fieldset': {
        boxShadow: `${alpha('rgba(0, 0, 0, 0.38', 0.25)} 0 0 0 0.1rem`,
        borderColor: 'rgba(0, 0, 0, 0.38);',
      },
    },
  },
})(TextField);

export default function CustomField(props) {
  return (
    <>
      <CustomInput id="outlined-adornment-weight" variant="outlined" {...props} />
      {!props.value && props.formValidation ? <Typography style={{ color: MarketplaceColors.error, paddingTop: '2px' }}>Oops! Missing field.</Typography> : ''}
    </>
  );
}
