import { useMemo } from 'react';

const projectInputKeysByInterventions = (schema) => {
  return {
    efficientFixturesHealthFaucet: {
      inputs: [
        [
          schema.efficientFixturesHealthFaucet_oneBHK,
          schema.efficientFixturesHealthFaucet_twoBHK,
          schema.efficientFixturesHealthFaucet_threeBHK,
          schema.efficientFixturesHealthFaucet_fourBHK,
          schema.efficientFixturesHealthFaucet_studio,
        ],
        [
          schema.efficientFixturesHealthFaucet_pentHouseOneBhk,
          schema.efficientFixturesHealthFaucet_pentHouseTwoBhk,
          schema.efficientFixturesHealthFaucet_pentHouseThreeBhk,
          schema.efficientFixturesHealthFaucet_pentHouseFourBhk,
        ],
      ],
    },
    srwh: {
      inputs: [
        [schema.srwh_landscapeArea, schema.srwh_concretePavement, schema.srwh_gravelPavement, schema.srwh_flatSurface],
        [schema.srwh_openGridConcretePavement, schema.srwh_vegetationFlatSurface, schema.srwh_grassSteepSurface],
        [schema.srwh_vegetationHillySurface, schema.srwh_vegetationSteepSurface, schema.srwh_grassSlightlySlopedSurface, schema.srwh_grassHillySurface],
        [schema.srwh_vegetationOpenGridGrassPavement, schema.srwh_vegetationSlightlySlopedSurface],
      ],
    },
    rrwh: {
      inputs: [
        [schema.rrwh_rooftopArea, schema.rrwh_cementTilesRoof, schema.rrwh_roofGardenThin],
        [schema.rrwh_roofGardenMedium, schema.rrwh_roofGardenThick, schema.rrwh_roofGardenHeavy],
      ],
    },
    spvPenthouse: {
      inputs: [[schema.spvPenthouse_availablePenthouseRooftopArea]],
    },
    nstp: {
      inputs: [
        [schema.nstp_oneBHK, schema.nstp_twoBHK, schema.nstp_threeBHK, schema.nstp_fourBHK, schema.nstp_studio],
        [schema.nstp_penthouseOneBHK, schema.nstp_penthouseTwoBHK, schema.nstp_penthouseThreeBHK, schema.nstp_penthouseFourBHK],
      ],
    },
    hwmApartment: {
      inputs: [
        [schema.hwmApartment_oneBHK, schema.hwmApartment_twoBHK, schema.hwmApartment_threeBHK, schema.hwmApartment_fourBHK, schema.hwmApartment_studio],
        [schema.hwmApartment_rooftopArea],
      ],
    },
    spvApartment: {
      inputs: [[schema.spvApartment_rooftopArea, schema.spvApartment_unavailableRooftopArea]],
    },
    spvClubhouse: {
      inputs: [[schema.spvClubhouse_availableClubhouseRooftopArea]],
    },
    efficientFixturesFaucet: {
      inputs: [
        [
          schema.efficientFixturesFaucet_oneBHK,
          schema.efficientFixturesFaucet_twoBHK,
          schema.efficientFixturesFaucet_threeBHK,
          schema.efficientFixturesFaucet_fourBHK,
          schema.efficientFixturesFaucet_studio,
        ],
        [
          schema.efficientFixturesFaucet_pentHouseOneBhk,
          schema.efficientFixturesFaucet_pentHouseTwoBhk,
          schema.efficientFixturesFaucet_pentHouseThreeBhk,
          schema.efficientFixturesFaucet_pentHouseFourBhk,
        ],
      ],
    },
    efficientFixturesKitchenSink: {
      inputs: [
        [
          schema.efficientFixturesKitchenSink_oneBHK,
          schema.efficientFixturesKitchenSink_twoBHK,
          schema.efficientFixturesKitchenSink_threeBHK,
          schema.efficientFixturesKitchenSink_fourBHK,
          schema.efficientFixturesKitchenSink_studio,
        ],
        [
          schema.efficientFixturesKitchenSink_pentHouseOneBhk,
          schema.efficientFixturesKitchenSink_pentHouseTwoBhk,
          schema.efficientFixturesKitchenSink_pentHouseThreeBhk,
          schema.efficientFixturesKitchenSink_pentHouseFourBhk,
        ],
      ],
    },
    efficientFixturesShower: {
      inputs: [
        [
          schema.efficientFixturesShower_oneBHK,
          schema.efficientFixturesShower_twoBHK,
          schema.efficientFixturesShower_threeBHK,
          schema.efficientFixturesShower_fourBHK,
          schema.efficientFixturesShower_studio,
        ],
        [
          schema.efficientFixturesShower_pentHouseOneBhk,
          schema.efficientFixturesShower_pentHouseTwoBhk,
          schema.efficientFixturesShower_pentHouseThreeBhk,
          schema.efficientFixturesShower_pentHouseFourBhk,
        ],
      ],
    },
    efficientFixturesDualFlush: {
      inputs: [
        [
          schema.efficientFixturesDualFlush_oneBHK,
          schema.efficientFixturesDualFlush_twoBHK,
          schema.efficientFixturesDualFlush_threeBHK,
          schema.efficientFixturesDualFlush_fourBHK,
          schema.efficientFixturesDualFlush_studio,
        ],
        [
          schema.efficientFixturesDualFlush_pentHouseOneBhk,
          schema.efficientFixturesDualFlush_pentHouseTwoBhk,
          schema.efficientFixturesDualFlush_pentHouseThreeBhk,
          schema.efficientFixturesDualFlush_pentHouseFourBhk,
        ],
      ],
    },
    spvVilla: {
      inputs: [[schema.spvVilla_availableVillaRooftopArea, schema.spvVilla_availableVillaRooftopArea]],
    },
    hwmClubhouse: {
      inputs: [[schema.hwmClubhouse_swimmingPoolSurfaceArea, schema.hwmClubhouse_clubhouseRooftopArea]],
    },
    hwmVilla: {
      inputs: [
        [schema.hwmVilla_villaOneBHK, schema.hwmVilla_villaTwoBHK, schema.hwmVilla_villaThreeBHK, schema.hwmVilla_villaFourBHK],
        [schema.hwmVilla_availableVillaRooftopArea, schema.hwmVilla_floorToFloorHeight, schema.hwmVilla_unavailableRooftopArea],
      ],
    },
    twr: {
      inputs: [
        [schema.twr_oneBHK, schema.twr_twoBHK, schema.twr_threeBHK, schema.twr_fourBHK, schema.twr_studio],
        [schema.twr_penthouseOneBHK, schema.twr_penthouseTwoBHK, schema.twr_penthouseThreeBHK, schema.twr_penthouseFourBHK],
      ],
    },
    hwmPenthouse: {
      inputs: [
        [schema.hwmPenthouse_penthouseOneBHK, schema.hwmPenthouse_penthouseTwoBHK, schema.hwmPenthouse_penthouseThreeBHK, schema.hwmPenthouse_penthouseFourBHK],
        [schema.hwmPenthouse_availablePenthouseRooftopArea, schema.hwmPenthouse_floorToFloorHeight, schema.hwmPenthouse_penthouseStudio],
      ],
    },
    hwmSwimmingPool: {
      inputs: [[schema.hwmSwimmingPool_swimmingPoolSurfaceArea]],
    },
    swm: {
      inputs: [
        [schema.swm_oneBHK, schema.swm_twoBHK, schema.swm_threeBHK, schema.swm_fourBHK, schema.swm_studio],
        [schema.swm_penthouseOneBHK, schema.swm_penthouseTwoBHK, schema.swm_penthouseThreeBHK, schema.swm_penthouseFourBHK],
      ],
    },
    wwm: {
      subCategoryKey: schema.wwm_subcategory,
      inputs: [[schema.wwm_oneBHK, schema.wwm_twoBHK, schema.wwm_threeBHK, schema.wwm_fourBHK, schema.wwm_studio]],
      penthouseEnabledInputs: [
        [schema.wwm_oneBHK, schema.wwm_twoBHK, schema.wwm_threeBHK, schema.wwm_fourBHK, schema.wwm_studio],
        [schema.wwm_penthouseOneBHK, schema.wwm_penthouseTwoBHK, schema.wwm_penthouseThreeBHK, schema.wwm_penthouseFourBHK],
      ],
    },
    csp: {
      subCategoryKey: schema.csp_projectSubCategory,
      inputs: [[schema.csp_oneBHK, schema.csp_twoBHK, schema.csp_threeBHK, schema.csp_fourBHK, schema.csp_studio]],
      penthouseEnabledInputs: [
        [schema.csp_oneBHK, schema.csp_twoBHK, schema.csp_threeBHK, schema.csp_fourBHK, schema.csp_studio],
        [schema.csp_penthouseOneBHK, schema.csp_penthouseTwoBHK, schema.csp_penthouseThreeBHK, schema.csp_penthouseFourBHK],
      ],
    },
    exteriorPaintsNonSunny: {
      inputs: [
        [
          schema.exteriorPaintsNonSunny_perimeter,
          schema.exteriorPaintsNonSunny_floorToFloorHeight,
          schema.exteriorPaintsNonSunny_numberOfFloors,
          schema.exteriorPaintsNonSunny_wwr,
        ],
      ],
    },
    exteriorPaintsSunny: {
      inputs: [
        [
          schema.exteriorPaintsSunny_perimeter,
          schema.exteriorPaintsSunny_floorToFloorHeight,
          schema.exteriorPaintsSunny_numberOfFloors,
          schema.exteriorPaintsSunny_wwr,
        ],
      ],
    },
    structuralSteel: {
      inputs: [[schema.structuralSteel_builtUpArea, schema.structuralSteel_projectQuantityFactorUserInput]],
    },
    sc: {
      inputs: [[schema.sc_builtUpArea, schema.sc_typeOfConstruction, schema.sc_slabThickness, schema.sc_beamDepth, schema.sc_wallThickness]],
    },
    interiorPaints: {
      subCategoryKey: schema.interiorPaints_projectSubcategory,
      inputs: [
        [
          schema.interiorPaints_studio,
          schema.interiorPaints_oneBHK,
          schema.interiorPaints_twoBHK,
          schema.interiorPaints_threeBHK,
          schema.interiorPaints_fourBHK,
        ],
        [
          schema.interiorPaints_studioCarpetArea,
          schema.interiorPaints_carpetAreaOneBHK,
          schema.interiorPaints_carpetAreaTwoBHK,
          schema.interiorPaints_carpetAreaThreeBHK,
          schema.interiorPaints_carpetAreaFourBHK,
        ],
      ],
      villaInputs: [
        [schema.interiorPaints_villaOneBHK, schema.interiorPaints_villaTwoBHK, schema.interiorPaints_villaThreeBHK, schema.interiorPaints_villaFourBHK],
        [
          schema.interiorPaints_villaCarpetAreaOneBHK,
          schema.interiorPaints_villaCarpetAreaTwoBHK,
          schema.interiorPaints_villaCarpetAreaThreeBHK,
          schema.interiorPaints_villaCarpetAreaFourBHK,
        ],
      ],
      penthouseEnabledInputs: [
        [
          schema.interiorPaints_studio,
          schema.interiorPaints_oneBHK,
          schema.interiorPaints_twoBHK,
          schema.interiorPaints_threeBHK,
          schema.interiorPaints_fourBHK,
        ],
        [
          schema.interiorPaints_studioCarpetArea,
          schema.interiorPaints_carpetAreaOneBHK,
          schema.interiorPaints_carpetAreaTwoBHK,
          schema.interiorPaints_carpetAreaThreeBHK,
          schema.interiorPaints_carpetAreaFourBHK,
        ],
        [
          schema.interiorPaints_penthouseOneBHK,
          schema.interiorPaints_penthouseTwoBHK,
          schema.interiorPaints_penthouseThreeBHK,
          schema.interiorPaints_penthouseFourBHK,
        ],
        [
          schema.interiorPaints_penthouseCarpetAreaOneBHK,
          schema.interiorPaints_penthouseCarpetAreaTwoBHK,
          schema.interiorPaints_penthouseCarpetAreaThreeBHK,
          schema.interiorPaints_penthouseCarpetAreaFourBHK,
        ],
      ],
    },
    indoorFlooringLnD: {
      inputs: [
        [
          schema.indoorFlooringLnD_studio,
          schema.indoorFlooringLnD_oneBHK,
          schema.indoorFlooringLnD_twoBHK,
          schema.indoorFlooringLnD_threeBHK,
          schema.indoorFlooringLnD_fourBHK,
        ],
        [
          schema.indoorFlooringLnD_studioCarpetArea,
          schema.indoorFlooringLnD_oneBhkCarpetArea,
          schema.indoorFlooringLnD_twoBhkCarpetArea,
          schema.indoorFlooringLnD_threeBhkCarpetArea,
          schema.indoorFlooringLnD_fourBhkCarpetArea,
        ],
        [
          schema.indoorFlooringLnD_penthouseOneBHK,
          schema.indoorFlooringLnD_penthouseTwoBHK,
          schema.indoorFlooringLnD_penthouseThreeBHK,
          schema.indoorFlooringLnD_penthouseFourBHK,
        ],
        [
          schema.indoorFlooringLnD_penthouseOneBhkCarpetArea,
          schema.indoorFlooringLnD_penthouseTwoBhkCarpetArea,
          schema.indoorFlooringLnD_penthouseThreeBhkCarpetArea,
          schema.indoorFlooringLnD_penthouseFourBhkCarpetArea,
        ],
      ],
      villaInputs: [
        [
          schema.indoorFlooringLnD_villaOneBHK,
          schema.indoorFlooringLnD_villaTwoBHK,
          schema.indoorFlooringLnD_villaThreeBHK,
          schema.indoorFlooringLnD_villaFourBHK,
        ],
        [
          schema.indoorFlooringLnD_villaOneBHKCarpetArea,
          schema.indoorFlooringLnD_villaTwoBHKCarpetArea,
          schema.indoorFlooringLnD_villaThreeBHKCarpetArea,
          schema.indoorFlooringLnD_villaFourBHKCarpetArea,
        ],
      ],
    },
    indoorFlooringBedroom: {
      inputs: [
        [
          schema.indoorFlooringBedroom_studio,
          schema.indoorFlooringBedroom_oneBHK,
          schema.indoorFlooringBedroom_twoBHK,
          schema.indoorFlooringBedroom_threeBHK,
          schema.indoorFlooringBedroom_fourBHK,
        ],
        [
          schema.indoorFlooringBedroom_studioCarpetArea,
          schema.indoorFlooringBedroom_oneBhkCarpetArea,
          schema.indoorFlooringBedroom_twoBhkCarpetArea,
          schema.indoorFlooringBedroom_threeBhkCarpetArea,
          schema.indoorFlooringBedroom_fourBhkCarpetArea,
        ],
        [
          schema.indoorFlooringBedroom_penthouseOneBHK,
          schema.indoorFlooringBedroom_penthouseTwoBHK,
          schema.indoorFlooringBedroom_penthouseThreeBHK,
          schema.indoorFlooringBedroom_penthouseFourBHK,
        ],
        [
          schema.indoorFlooringBedroom_penthouseOneBhkCarpetArea,
          schema.indoorFlooringBedroom_penthouseTwoBhkCarpetArea,
          schema.indoorFlooringBedroom_penthouseThreeBhkCarpetArea,
          schema.indoorFlooringBedroom_penthouseFourBhkCarpetArea,
        ],
      ],
      villaInputs: [
        [
          schema.indoorFlooringBedroom_villaOneBHK,
          schema.indoorFlooringBedroom_villaTwoBHK,
          schema.indoorFlooringBedroom_villaThreeBHK,
          schema.indoorFlooringBedroom_villaFourBHK,
        ],
        [
          schema.indoorFlooringBedroom_villaOneBHKCarpetArea,
          schema.indoorFlooringBedroom_villaTwoBHKCarpetArea,
          schema.indoorFlooringBedroom_villaThreeBHKCarpetArea,
          schema.indoorFlooringBedroom_villaFourBHKCarpetArea,
        ],
      ],
    },
    indoorFlooringKitchen: {
      inputs: [
        [
          schema.indoorFlooringKitchen_studio,
          schema.indoorFlooringKitchen_oneBHK,
          schema.indoorFlooringKitchen_twoBHK,
          schema.indoorFlooringKitchen_threeBHK,
          schema.indoorFlooringKitchen_fourBHK,
        ],
        [
          schema.indoorFlooringKitchen_studioCarpetArea,
          schema.indoorFlooringKitchen_oneBhkCarpetArea,
          schema.indoorFlooringKitchen_twoBhkCarpetArea,
          schema.indoorFlooringKitchen_threeBhkCarpetArea,
          schema.indoorFlooringKitchen_fourBhkCarpetArea,
        ],
        [
          schema.indoorFlooringKitchen_penthouseOneBHK,
          schema.indoorFlooringKitchen_penthouseTwoBHK,
          schema.indoorFlooringKitchen_penthouseThreeBHK,
          schema.indoorFlooringKitchen_penthouseFourBHK,
        ],
        [
          schema.indoorFlooringKitchen_penthouseOneBhkCarpetArea,
          schema.indoorFlooringKitchen_penthouseTwoBhkCarpetArea,
          schema.indoorFlooringKitchen_penthouseThreeBhkCarpetArea,
          schema.indoorFlooringKitchen_penthouseFourBhkCarpetArea,
        ],
      ],
      villaInputs: [
        [
          schema.indoorFlooringKitchen_villaOneBHK,
          schema.indoorFlooringKitchen_villaTwoBHK,
          schema.indoorFlooringKitchen_villaThreeBHK,
          schema.indoorFlooringKitchen_villaFourBHK,
        ],
        [
          schema.indoorFlooringKitchen_villaOneBHKCarpetArea,
          schema.indoorFlooringKitchen_villaTwoBHKCarpetArea,
          schema.indoorFlooringKitchen_villaThreeBHKCarpetArea,
          schema.indoorFlooringKitchen_villaFourBHKCarpetArea,
        ],
      ],
    },
    indoorFlooringBathroom: {
      inputs: [
        [
          schema.indoorFlooringBathroom_studio,
          schema.indoorFlooringBathroom_oneBHK,
          schema.indoorFlooringBathroom_twoBHK,
          schema.indoorFlooringBathroom_threeBHK,
          schema.indoorFlooringBathroom_fourBHK,
        ],
        [
          schema.indoorFlooringBathroom_studioCarpetArea,
          schema.indoorFlooringBathroom_oneBhkCarpetArea,
          schema.indoorFlooringBathroom_twoBhkCarpetArea,
          schema.indoorFlooringBathroom_threeBhkCarpetArea,
          schema.indoorFlooringBathroom_fourBhkCarpetArea,
        ],
        [
          schema.indoorFlooringBathroom_penthouseOneBHK,
          schema.indoorFlooringBathroom_penthouseTwoBHK,
          schema.indoorFlooringBathroom_penthouseThreeBHK,
          schema.indoorFlooringBathroom_penthouseFourBHK,
        ],
        [
          schema.indoorFlooringBathroom_penthouseOneBhkCarpetArea,
          schema.indoorFlooringBathroom_penthouseTwoBhkCarpetArea,
          schema.indoorFlooringBathroom_penthouseThreeBhkCarpetArea,
          schema.indoorFlooringBathroom_penthouseFourBhkCarpetArea,
        ],
      ],
      villaInputs: [
        [
          schema.indoorFlooringBathroom_villaOneBHK,
          schema.indoorFlooringBathroom_villaTwoBHK,
          schema.indoorFlooringBathroom_villaThreeBHK,
          schema.indoorFlooringBathroom_villaFourBHK,
        ],
        [
          schema.indoorFlooringBathroom_villaOneBHKCarpetArea,
          schema.indoorFlooringBathroom_villaTwoBHKCarpetArea,
          schema.indoorFlooringBathroom_villaThreeBHKCarpetArea,
          schema.indoorFlooringBathroom_villaFourBHKCarpetArea,
        ],
      ],
    },
    indoorFlooringUnB: {
      inputs: [
        [
          schema.indoorFlooringUnB_studio,
          schema.indoorFlooringUnB_oneBHK,
          schema.indoorFlooringUnB_twoBHK,
          schema.indoorFlooringUnB_threeBHK,
          schema.indoorFlooringUnB_fourBHK,
        ],
        [
          schema.indoorFlooringUnB_studioCarpetArea,
          schema.indoorFlooringUnB_oneBhkCarpetArea,
          schema.indoorFlooringUnB_twoBhkCarpetArea,
          schema.indoorFlooringUnB_threeBhkCarpetArea,
          schema.indoorFlooringUnB_fourBhkCarpetArea,
        ],
        [
          schema.indoorFlooringUnB_penthouseOneBHK,
          schema.indoorFlooringUnB_penthouseTwoBHK,
          schema.indoorFlooringUnB_penthouseThreeBHK,
          schema.indoorFlooringUnB_penthouseFourBHK,
        ],
        [
          schema.indoorFlooringUnB_penthouseOneBhkCarpetArea,
          schema.indoorFlooringUnB_penthouseTwoBhkCarpetArea,
          schema.indoorFlooringUnB_penthouseThreeBhkCarpetArea,
          schema.indoorFlooringUnB_penthouseFourBhkCarpetArea,
        ],
      ],
      villaInputs: [
        [
          schema.indoorFlooringUnB_villaOneBHK,
          schema.indoorFlooringUnB_villaTwoBHK,
          schema.indoorFlooringUnB_villaThreeBHK,
          schema.indoorFlooringUnB_villaFourBHK,
        ],
        [
          schema.indoorFlooringUnB_villaOneBHKCarpetArea,
          schema.indoorFlooringUnB_villaTwoBHKCarpetArea,
          schema.indoorFlooringUnB_villaThreeBHKCarpetArea,
          schema.indoorFlooringUnB_villaFourBHKCarpetArea,
        ],
      ],
    },

    nonStructuralCement: {
      inputs: [
        [
          schema.nonStructuralCement_builtUpArea,
          schema.nonStructuralCement_typeOfConstruction,
          schema.nonStructuralCement_flooringThickness,
          schema.nonStructuralCement_flooringMixingRatio,
        ],
        [
          schema.nonStructuralCement_internalPlasterThickness,
          schema.nonStructuralCement_internalPlasterMixingRatio,
          schema.nonStructuralCement_externalPlasterThickness,
          schema.nonStructuralCement_externalPlasterMixingRatio,
        ],
        [
          schema.nonStructuralCement_numberOfFloors,
          schema.nonStructuralCement_floorToFloorHeight,
          schema.nonStructuralCement_perimter,
          schema.nonStructuralCement_wwr,
        ],
        [schema.nonStructuralCement_typeOfWall, schema.nonStructuralCement_wallMixingRatio],
      ],
    },
    glazingSgu: {
      headerMappings: [
        { header: 'Name', key: 'name' },
        { header: 'Type', key: 'type' },
        { header: 'Thickness', key: 'thickness' },
        { header: 'Glazing Area', key: 'glazingArea' },
        { header: 'Total Count', key: 'totalCount' },
      ],
      interventionKey: 'glazingSgu',
      tableLabel: 'Glazing Schedule Details',
      tableDataKey: schema.glazingSgu_openings,
      isTableFormat: true,
      inputs: [[schema.glazingSgu_builtUpArea, schema.glazingSgu_typeOfConstruction, schema.glazingSgu_numberOfFloors, schema.glazingSgu_floorToFloorHeight]],
    },
    glazingDgu: {
      headerMappings: [
        { header: 'Name', key: 'name' },
        { header: 'Type', key: 'type' },
        { header: 'Thickness', key: 'thickness' },
        { header: 'Glazing Area', key: 'glazingArea' },
        { header: 'Total Count', key: 'totalCount' },
      ],
      interventionKey: 'glazingDgu',
      tableLabel: 'Glazing Schedule Details',
      tableDataKey: schema.glazingDgu_openings,
      isTableFormat: true,
      inputs: [[schema.glazingDgu_builtUpArea, schema.glazingDgu_typeOfConstruction, schema.glazingDgu_numberOfFloors, schema.glazingDgu_floorToFloorHeight]],
    },
    wallBlocksInterior: {
      inputs: [
        [
          schema.wallBlocksInterior_builtUpArea,
          schema.wallBlocksInterior_typeOfConstruction,
          schema.wallBlocksInterior_numberOfFloors,
          schema.wallBlocksInterior_floorToFloorHeight,
        ],
        [schema.wallBlocksInterior_perimter, schema.wallBlocksInterior_wwr],
      ],
    },
    wallBlockExterior: {
      inputs: [
        [
          schema.wallBlockExterior_builtUpArea,
          schema.wallBlockExterior_typeofConstruction,
          schema.wallBlockExterior_numberOfFloors,
          schema.wallBlockExterior_floorToFloorHeight,
        ],
        [schema.wallBlockExterior_perimeter, schema.wallBlockExterior_wwr],
      ],
    },
    coolRoof: {
      inputs: [[schema.coolRoof_builtUpArea, schema.coolRoof_slabThickness, schema.coolRoof_roofTopArea]],
    },
  };
};

const useAppProjectInputKeysByInterventions = ({ schema, key }) => {
  const inputs = useMemo(() => {
    const selectedInterventionProjectInputs = projectInputKeysByInterventions(schema)?.[key];
    return selectedInterventionProjectInputs || [];
  }, [key, schema]);

  if (!schema) return [];
  return inputs;
};

export default useAppProjectInputKeysByInterventions;
