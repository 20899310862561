import React, { useEffect, useState } from 'react';
import { Typography, Grid, Box, TextField, InputAdornment } from '@material-ui/core';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ResetFields from '../common/textFiledwithReset';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import useStyles from './styles';
import InfoIcon from '@material-ui/icons/Info';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import { update } from 'lodash';
const NonStructuralCustomBasicParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();
  const [typeOfConstruction, setTypeOfConstruction] = useState('');
  useEffect(() => {
    setTypeOfConstruction(getQuantityValue(schema.nonStructuralCement_typeOfConstruction));
  }, [schema]);

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography style={{ paddingBottom: '10px' }} className={classes.textHeader5}>
              {getQuantityLabel(schema.nonStructuralCement__pi_flooringQuantity)}
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.nonStructuralCement__pi_flooringQuantity)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.nonStructuralCement__pi_flooringQuantity, e.target.value);
                }}
                fullWidth
                required
                onClick={(e) => {
                  e.preventDefault();
                  updateQuantityValue(
                    schema.nonStructuralCement__pi_flooringQuantity,
                    getQuantityDefaultValue(schema.nonStructuralCement__pi_flooringQuantity)
                  );
                }}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.nonStructuralCement__pi_flooringQuantity)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        {typeOfConstruction !== 'Aluminium form work' && (
          <Grid item xs={4} direction="column">
            <Grid xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={{ paddingBottom: '10px' }} className={classes.textHeader5}>
                {getQuantityLabel(schema.nonStructuralCement__pi_masonryQuantity)}
              </Typography>
              <CustomizedTooltips icon="info" title={<Typography>This quantity is derived from the Wall Blocks interventions</Typography>} placement="right" />
            </Grid>
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  value={getQuantityValue(schema.nonStructuralCement__pi_masonryQuantity)}
                  placeholder=""
                  onChange={(e) => {
                    e.preventDefault();
                    updateQuantityValue(schema.nonStructuralCement__pi_masonryQuantity, e.target.value);
                  }}
                  fullWidth
                  required
                  onClick={(e) => {
                    e.preventDefault();
                    updateQuantityValue(
                      schema.nonStructuralCement__pi_masonryQuantity,
                      getQuantityDefaultValue(schema.nonStructuralCement__pi_masonryQuantity)
                    );
                  }}
                  disabled={false}
                />
              </Grid>
              <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">{getQuantityUnit(schema.nonStructuralCement__pi_masonryQuantity)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        {typeOfConstruction !== 'Aluminium form work' && (
          <Grid item xs={4} direction="column">
            <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
              <Typography className={classes.textHeader5}>{getQuantityLabel(schema.nonStructuralCement__pi_internalPlasterQuantity)}</Typography>
            </Grid>
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  value={getQuantityValue(schema.nonStructuralCement__pi_internalPlasterQuantity)}
                  placeholder=""
                  onChange={(e) => {
                    e.preventDefault();
                    updateQuantityValue(schema.nonStructuralCement__pi_internalPlasterQuantity, e.target.value);
                  }}
                  fullWidth
                  required
                  onClick={(e) => {
                    e.preventDefault();
                    updateQuantityValue(
                      schema.nonStructuralCement__pi_internalPlasterQuantity,
                      getQuantityDefaultValue(schema.nonStructuralCement__pi_internalPlasterQuantity)
                    );
                  }}
                  disabled={false}
                />
              </Grid>
              <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">{getQuantityUnit(schema.nonStructuralCement__pi_internalPlasterQuantity)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        {typeOfConstruction !== 'Aluminium form work' && (
          <Grid item xs={4} direction="column">
            <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
              <Typography className={classes.textHeader5}>{getQuantityLabel(schema.nonStructuralCement__pi_externalPlasterQuantity)}</Typography>
            </Grid>
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  value={getQuantityValue(schema.nonStructuralCement__pi_externalPlasterQuantity)}
                  placeholder=""
                  onChange={(e) => {
                    e.preventDefault();
                    updateQuantityValue(schema.nonStructuralCement__pi_externalPlasterQuantity, e.target.value);
                  }}
                  fullWidth
                  required
                  onClick={(e) => {
                    e.preventDefault();
                    updateQuantityValue(
                      schema.nonStructuralCement__pi_externalPlasterQuantity,
                      getQuantityDefaultValue(schema.nonStructuralCement__pi_externalPlasterQuantity)
                    );
                  }}
                  disabled={false}
                />
              </Grid>
              <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">{getQuantityUnit(schema.nonStructuralCement__pi_externalPlasterQuantity)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={4} style={{ paddingTop: '2%' }}>
          <Typography className={classes.textHeader5}>{getQuantityLabel(schema.nonStructuralCement_typeOfCement)}</Typography>
          <Typography className={classes.textHeader3}>{getQuantityValue(schema.nonStructuralCement_typeOfCement) || '-'}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default NonStructuralCustomBasicParameters;
