import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PrimaryButton from '../../../../../common/button/Button';
import CustomField from '../../common/CustomField';
import { generateId } from '../../../../interventions/intervention/utils';
import HandlerDropdown from '../../common/HandlerDropdown';
import CustomizedTooltips from '../../../../../common/tooltip/TooltipV2';

const OpeningsHandler = ({
  requiredPipId,
  handleClose,
  editTable,
  editableId,
  editableData,
  setUpdate,
  update,
  setLocalOpeningData,
  form,
  handlePIPValueUpdate,
  setClosePopover,
}) => {
  const classes = useStyles();
  const existingPipValue = form?.pipvalues[requiredPipId] || [];

  const [openingData, setOpeningData] = useState({
    name: '',
    width: '',
    frameType: '',
    height: '',
    overhangDepth: '',
    configuration: '',
    finsDepth: '',
    thickness: '',
    baselineGlassType: '',
    type: '',
  });

  const [dynamicOptionsForThickness, setDynamicOptionsForThickness] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (editTable && editableData) {
      setOpeningData(editableData);
    }
  }, [editTable, editableData]);

  useEffect(() => {
    if (!openingData.configuration) return;

    const isSingleGlazed = openingData.configuration === 'Single Glazed Unit';
    const newOptions = isSingleGlazed ? ['5 mm', '6 mm', '8 mm'] : ['5 - 12 - 6', '6 - 12 - 6'];

    setDynamicOptionsForThickness(newOptions);

    const defaultThickness = isSingleGlazed ? '5 mm' : '5 - 12 - 6';

    setOpeningData((prev) => ({
      ...prev,
      thickness: defaultThickness,
    }));
  }, [openingData.configuration]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOpeningData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDropdownChange = (field) => (e) => {
    setOpeningData((prev) => ({ ...prev, [field]: e.target.value }));
  };

  useEffect(() => {
    const { name, type, frameType, height, width, overhangDepth, finsDepth, configuration, thickness, baselineGlassType } = openingData;

    const isValid =
      name && type && frameType && height > 0 && width > 0 && overhangDepth >= 0 && finsDepth >= 0 && configuration && thickness && baselineGlassType;

    setIsFormValid(isValid);
  }, [openingData]);

  const handleSaveOrUpdate = () => {
    const numericData = {
      ...openingData,
      width: parseFloat(openingData.width),
      height: parseFloat(openingData.height),
      overhangDepth: parseFloat(openingData.overhangDepth),
      finsDepth: parseFloat(openingData.finsDepth),
    };

    if (editTable) {
      const updatedPipValues = existingPipValue.map((item) => {
        if (item._id === editableId) {
          return {
            ...item,
            ...numericData,
          };
        }
        return item;
      });

      setLocalOpeningData(updatedPipValues);
      handlePIPValueUpdate(requiredPipId, updatedPipValues);
      if (setClosePopover) setClosePopover(true);
    } else {
      const newEntry = {
        _id: generateId(),
        ...numericData,
      };

      const updatedData = [...existingPipValue, newEntry];
      setLocalOpeningData(updatedData);
      handlePIPValueUpdate(requiredPipId, updatedData);
    }

    setUpdate(!update);
    handleClose();
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={12} style={{ paddingLeft: '4%', paddingBottom: '8%' }}>
        <Grid item xs={12} container alignItems="center">
          <Grid>
            <CloseIcon fontSize="large" style={{ cursor: 'pointer' }} onClick={handleClose} />
          </Grid>
          <Grid style={{ paddingLeft: '4%' }}>
            <Typography variant="h5">
              <b>{editTable ? 'Edit' : 'Add'} Opening Schedule</b>
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} container alignItems="center" style={{ marginBottom: '5%' }}>
          <Grid xs={10} style={{ textAlign: 'left' }}>
            <Typography style={{ marginBottom: '14px', marginLeft: '8px', fontSize: '12px', opacity: 0.5 }}>All fields are mandatory</Typography>
          </Grid>

          {/* Name Field */}
          <Grid xs={6} style={{ paddingBottom: '2%', paddingLeft: '2%' }}>
            <Grid xs={12} style={{ marginBottom: '14px' }}>
              <Typography variant="h6">Name</Typography>
            </Grid>
            <Grid xs={8} style={{ marginTop: '4%' }}>
              <CustomField
                id="outlined-basic"
                variant="outlined"
                placeholder="Enter Name"
                fullWidth
                required
                name="name"
                value={openingData.name}
                onChange={handleChange}
              />
            </Grid>
            <Grid xs={10} style={{ textAlign: 'left' }}>
              <Typography style={{ fontSize: '12px', opacity: 0.5, marginTop: '3px' }}>Max 20 characters</Typography>
            </Grid>
          </Grid>

          {/* Type Field */}
          <Grid xs={6}>
            <Grid xs={6}>
              <Typography variant="h6" style={{ marginLeft: '3px', marginBottom: '14px' }}>
                Type
              </Typography>
            </Grid>
            <Grid xs={6} container style={{ marginTop: '4%', paddingBottom: '15%' }} alignItems="center">
              <HandlerDropdown
                value={openingData.type}
                onChange={handleDropdownChange('type')}
                options={['Window', 'Sliding Door', 'Curtain Wall']}
                disabled={false}
              />
            </Grid>
          </Grid>

          {/* Frame Type Field */}
          <Grid xs={6}>
            <Grid xs={6} style={{ marginTop: '5%' }}>
              <Typography variant="h6" style={{ marginLeft: '10px', marginBottom: '14px' }}>
                Frame Type
              </Typography>
            </Grid>
            <Grid xs={4} container style={{ marginTop: '4%', marginLeft: '10px', paddingBottom: '12%' }} alignItems="center">
              <HandlerDropdown
                value={openingData.frameType}
                onChange={handleDropdownChange('frameType')}
                options={['Aluminium', 'Aluminium with 25 mm polyamide', 'Hardwood', 'UPVC']}
                disabled={false}
              />
            </Grid>
          </Grid>

          {/* Height Field */}
          <Grid xs={6}>
            <Grid xs={12}>
              <Typography variant="h6" style={{ marginLeft: '6px', marginTop: '10px', marginBottom: '14px' }}>
                Height
              </Typography>
            </Grid>
            <Grid xs={12} container style={{ paddingRight: '3%', marginTop: '4%', marginTop: '8px', paddingBottom: '15%' }} alignItems="center">
              <Grid xs={8}>
                <CustomField id="outlined-basic" variant="outlined" fullWidth required name="height" value={openingData.height} onChange={handleChange} />
              </Grid>
              <Grid xs={3} style={{ paddingLeft: '1%' }}>
                <Typography variant="h6">m</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            {/* Width Field */}
            <Grid xs={6}>
              <Grid xs={12}>
                <Typography variant="h6" style={{ marginLeft: '15px', marginBottom: '12px' }}>
                  Width
                </Typography>
              </Grid>
              <Grid xs={12} container style={{ paddingLeft: '5%', marginTop: '4%', paddingBottom: '15%' }} alignItems="center">
                <Grid xs={8}>
                  <CustomField id="outlined-basic" variant="outlined" fullWidth required name="width" value={openingData.width} onChange={handleChange} />
                </Grid>
                <Grid xs={3} style={{ paddingLeft: '2%' }}>
                  <Typography variant="h6">m</Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* Overhang Depth Field */}
            <Grid xs={6}>
              <Grid xs={12} container>
                <Grid style={{ marginLeft: '5px', marginTop: '1px' }}>
                  <Typography variant="h6">Overhang Depth</Typography>
                </Grid>
                <Grid style={{ paddingLeft: '2px' }}>
                  <CustomizedTooltips
                    icon="info"
                    title={<Typography>Default values: Window - 0.6, Sliding Door - 0.9, Curtain Wall - 0.</Typography>}
                    placement="right"
                  />
                </Grid>
              </Grid>
              <Grid xs={12} container style={{ marginTop: '3%', paddingBottom: '15%' }} alignItems="center">
                <Grid xs={8}>
                  <CustomField
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    required
                    name="overhangDepth"
                    value={openingData.overhangDepth}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid xs={3} style={{ paddingLeft: '1%' }}>
                  <Typography variant="h6">m</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Fins Depth Field */}
          <Grid xs={6}>
            <Grid xs={12} container>
              <Grid style={{ marginLeft: '15px', marginTop: '2px' }}>
                <Typography variant="h6">Fins Depth</Typography>
              </Grid>
              <Grid style={{ paddingLeft: '3px' }}>
                <CustomizedTooltips
                  icon="info"
                  title={<Typography>Default values: Window - 0.6, Sliding Door - 0.9, Curtain Wall - 0.</Typography>}
                  placement="right"
                />
              </Grid>
            </Grid>
            <Grid xs={12} container style={{ paddingLeft: '6%', marginTop: '4%', paddingBottom: '15%' }} alignItems="center">
              <Grid xs={8}>
                <CustomField id="outlined-basic" variant="outlined" fullWidth required name="finsDepth" value={openingData.finsDepth} onChange={handleChange} />
              </Grid>
              <Grid xs={3} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">m</Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Configuration Field */}
          <Grid xs={6}>
            <Grid xs={12}>
              <Typography variant="h6" style={{ marginLeft: '6px' }}>
                Configuration
              </Typography>
            </Grid>
            <Grid xs={9} container style={{ marginTop: '5%', paddingBottom: '15%' }} alignItems="center">
              <HandlerDropdown
                value={openingData.configuration}
                onChange={handleDropdownChange('configuration')}
                options={['Single Glazed Unit', 'Double Glazed Unit']}
                disabled={false}
              />
            </Grid>
          </Grid>

          {/* Thickness Field */}
          <Grid xs={6}>
            <Grid xs={12} container>
              <Grid style={{ marginLeft: '15px', marginTop: '2px' }}>
                <Typography variant="h6">Thickness</Typography>
              </Grid>
              <Grid style={{ paddingLeft: '3px' }}>
                <CustomizedTooltips
                  icon="info"
                  title={
                    <Typography>
                      The thickness is determined by the configuration. For single glazed unit the options are 5, 6, 8 mm. For double glazed unit the options
                      are 5-12-6 and 6-12-6
                    </Typography>
                  }
                  placement="right"
                />
              </Grid>
            </Grid>
            <Grid xs={6} container style={{ marginLeft: '15px', marginTop: '4%', paddingBottom: '15%' }} alignItems="center">
              <HandlerDropdown
                value={openingData.thickness}
                onChange={handleDropdownChange('thickness')}
                options={dynamicOptionsForThickness}
                disabled={false}
              />
            </Grid>
          </Grid>

          {/* Baseline Glass Type Field */}
          <Grid xs={12}>
            <Grid xs={6}>
              <Typography
                variant="h6"
                style={{
                  marginLeft: '15px',
                  fontFamily: 'Lato',
                  fontWeight: 700,
                  fontSize: 16,
                }}
              >
                Baseline Glass Type
              </Typography>
              <Grid xs={6} container style={{ marginLeft: '15px', marginTop: '5%', paddingBottom: '15%' }} alignItems="center">
                <HandlerDropdown
                  value={openingData.baselineGlassType}
                  onChange={handleDropdownChange('baselineGlassType')}
                  options={['Clear Annealed Glass', 'Clear Tempered Glass', 'Clear Heat Strengthened Glass']}
                  disabled={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Actions Footer */}
      <Grid item xs={12}>
        <Paper className={classes.actions}>
          <Grid item xs={12} container direction="row" alignItems="center" justifyContent="flex-end">
            <Grid item xs={2}>
              <Typography variant="h6" onClick={handleClose} style={{ cursor: 'pointer' }}>
                Cancel
              </Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={4}>
              <PrimaryButton children={editTable ? 'Update' : 'Save'} disabled={!isFormValid} onClick={handleSaveOrUpdate} />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default OpeningsHandler;

const useStyles = makeStyles((theme) => ({
  actions: {
    position: 'fixed',
    bottom: 0,
    width: '500px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
}));
