import { Typography, Grid } from '@material-ui/core';
import ResetFields from '../common/textFiledwithReset';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import useStyles from './styles';

const EPSCustomBasicParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <>
        <Grid container item xs={12} style={{ paddingTop: '1%' }}>
          <Grid item xs={4}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.exteriorPaintsSunny_brand)}</Typography>
            <Typography className={classes.textHeader3}>{getQuantityValue(schema.exteriorPaintsSunny_brand)}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.exteriorPaintsSunny_productName)}</Typography>
            <Typography className={classes.textHeader3}>{getQuantityValue(schema.exteriorPaintsSunny_productName)}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.exteriorPaintsSunny_vocLevel)}</Typography>
            <Typography className={classes.textHeader3}>{getQuantityValue(schema.exteriorPaintsSunny_vocLevel)}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={4} container style={{ marginTop: '4%' }} alignItems="flex-start" direction="column">
          <Grid xs>
            <Typography className={classes.textheader2}>{getQuantityLabel(schema.project__pi_sunIncidentArea)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.project__pi_sunIncidentArea)}
                placeholder=""
                onChange={(e) => updateQuantityValue(schema.project__pi_sunIncidentArea, e.target.value)}
                fullWidth
                onClick={() => updateQuantityValue(schema.project__pi_sunIncidentArea, getQuantityDefaultValue(schema.project__pi_sunIncidentArea))}
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.project__pi_sunIncidentArea)}</Typography>
            </Grid>{' '}
            <Typography color="textSecondary" variant="h6" style={{ marginTop: '2%' }}>
              {'60% non-sun incident area'}
            </Typography>
          </Grid>
        </Grid>
      </>
      <Grid container item xs={12} style={{ paddingTop: '50px', paddingBottom: '20px' }}></Grid>
    </Grid>
  );
};

export default EPSCustomBasicParameters;
