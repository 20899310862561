import React, { useEffect, useState } from 'react';
import { Grid, Typography, TableRow, TableHead, TableContainer, TableCell, TableBody, Table } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import CustomPopover from '../../../../certification/edit/ResidentHealthAndWellBeing/common/CustomPopover';
import useAppProjectPIPMapping from '../../../hooks/useAppProjectPIPMapping';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function OpeningPerTowerTable({
  setTowerToDelete,
  setEditableData,
  editTable,
  editableId,
  localTowerOpeningData,
  requiredPipId,
  handleEdit,
  update,
  setShowModal,
  closePopover,
  tower,
  openingData,
}) {
  let { id } = useParams();
  const classes = useStyles();
  const {
    get: { form },
  } = useAppProjectPIPMapping(id);

  const [tableData, setTableData] = useState();
  const [filteredData, setFilteredData] = useState();

  useEffect(() => {
    if (form?.pipvalues && form.pipvalues[requiredPipId]) {
      setTableData([...form.pipvalues[requiredPipId]]);
    } else {
      setTableData([]);
    }
  }, [requiredPipId, form]);

  useEffect(() => {
    if (form?.pipvalues && form.pipvalues[requiredPipId]) {
      setTableData(localTowerOpeningData);
    }
  }, [update]);
  useEffect(() => {
    if (editTable) {
      const editableTower = localTowerOpeningData?.find((item) => item._id === editableId) || tableData?.find((item) => item._id === editableId);
      setEditableData(editableTower);
    }
  }, [editableId, editTable]);
  useEffect(() => {
    if (tableData && openingData) {
      setFilteredData(tableData.filter((item) => item.towerId === tower._id && openingData.some((opening) => opening._id === item.openingId)));
    }
  }, [tower, tableData, openingData]);
  const headers = [
    'Opening Schedule Name',
    'North Units',
    'South Units',
    'East Units',
    'West Units',
    'North East Units',
    'North West Units',
    'South East Units',
    'South West Units',
  ];

  const getHeaderDataKey = (header) => {
    switch (header) {
      case 'Opening Schedule Name':
        return 'openingSchedule';
      case 'North Units':
        return 'northUnits';
      case 'South Units':
        return 'southUnits';
      case 'East Units':
        return 'eastUnits';
      case 'West Units':
        return 'westUnits';
      case 'North East Units':
        return 'northEastUnits';
      case 'North West Units':
        return 'northWestUnits';
      case 'South East Units':
        return 'southEastUnits';
      case 'South West Units':
        return 'southWestUnits';
      default:
        return '';
    }
  };
  return (
    <>
      <>
        {filteredData && filteredData.length > 0 && (
          <Grid container xs={12} style={{ marginBottom: '3%' }}>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableBody>
                  {/* Render Popover row FIRST */}
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        paddingTop: 0.8,
                        paddingBottom: 0,
                        height: '10px',
                        backgroundColor: '#white',
                        color: '#1D1C2B',
                        borderBottomWidth: '0px',
                      }}
                    >
                      <Typography style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '14px', lineHeight: '21px', letterSpacing: '0px' }}></Typography>
                    </TableCell>
                    {filteredData.map((towerData, towerIndex) => (
                      <TableCell key={towerIndex} style={{ borderBottomWidth: '0px', position: 'relative' }}>
                        {' '}
                        {/* Key change: position relative */}
                        <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                          {' '}
                          {/* Key change: absolute positioning */}
                          <CustomPopover
                            closePopover={closePopover}
                            onEdit={() => handleEdit(towerData._id)}
                            onDelete={() => {
                              setShowModal(true);
                              setTowerToDelete(towerData._id);
                            }}
                          />
                        </div>
                      </TableCell>
                    ))}
                    <TableCell align="left" style={{ border: 'none' }}></TableCell> {/* Empty cell for spacing */}
                  </TableRow>

                  {/* Render headers */}
                  {headers.map((header, headerIndex) => (
                    <TableRow key={headerIndex}>
                      <TableCell width="120" style={{ backgroundColor: '#F7F7F7', color: '#1D1C2B', borderBottomWidth: '0px' }} component="th" scope="row">
                        <Typography style={{ fontFamily: 'Lato', fontWeight: 700, fontSize: '14px', lineHeight: '21px', letterSpacing: '0px' }}>
                          {header}
                        </Typography>
                      </TableCell>
                      {/* Render data cells */}
                      {filteredData.map((towerData, towerIndex) => (
                        <TableCell
                          align="center"
                          width={1000 / filteredData?.length}
                          key={towerIndex}
                          style={{ backgroundColor: '#F7F7F7', borderBottomWidth: '0px', top: '10px' }}
                          component="th"
                          scope="row"
                        >
                          <Typography variant="h6">{towerData[getHeaderDataKey(header)] || '-'}</Typography>
                        </TableCell>
                      ))}
                      <TableCell align="center" style={{ border: 'none' }}></TableCell> {/* Empty cell for spacing */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </>
    </>
  );
}
