import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
// import { Link } from 'react-router-dom';
// import ShowSaveAndCancel from './showSaveAndCancel';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ResetFields from '../common/textFiledwithReset';
// import numberWithCommas from '../../../../../utils/numberToComma';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';
import useStyles from './styles';

const HWMApartmentCustomBasicParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();

  const handleHwmApartmentOption = (option) => {
    updateQuantityValue(schema.hwmApartment__pi_option, option);
    updateQuantityValue(schema.hwmApartment__pi_selectedProductIdentifier, null);
  };

  const handleSWhTypeSWH = (type) => {
    updateQuantityValue(schema.hwmApartment__pi_swhType, type);
    if (type === 'central') {
      updateQuantityValue(schema.hwmApartment__pi_swhVariant, null);
    }
  };

  const handleSWhTechnology = (technology) => {
    updateQuantityValue(schema.hwmApartment__pi_swhVariant, technology);
  };

  const handleHybridSWhTechnology = (technology) => {
    updateQuantityValue(schema.hwmApartment__pi_hybridSwhVariant, technology);
  };

  const handleHpType = (type) => {
    updateQuantityValue(schema.hwmApartment__pi_hpType, type);
  };

  const handleHpTemp = (temp) => {
    updateQuantityValue(schema.hwmApartment__pi_hpHeatingSpeed, temp);
  };

  const handleHybridHpTemp = (temp) => {
    updateQuantityValue(schema.hwmApartment__pi_hybridHpHeatingSpeed, temp);
  };

  const hwmApartmentOption = getQuantityValue(schema.hwmApartment__pi_option);
  const swhType = getQuantityValue(schema.hwmApartment__pi_swhType);
  const swhTechnology = getQuantityValue(schema.hwmApartment__pi_swhVariant);
  const hpType = getQuantityValue(schema.hwmApartment__pi_hpType);
  const hpTemp = getQuantityValue(schema.hwmApartment__pi_hpHeatingSpeed);
  const hybridHeatingSpeed = getQuantityValue(schema.hwmApartment__pi_hybridHpHeatingSpeed);
  const hybridSwhVariant = getQuantityValue(schema.hwmApartment__pi_hybridSwhVariant);

  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid container item xs={12}>
        <Grid item xs={4}>
          <Typography className={classes.textHeader1}>
            {hwmApartmentOption === 'solar water heater' ? (
              <CheckCircleIcon className={classes.selectedCheckbox} />
            ) : (
              <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => handleHwmApartmentOption('solar water heater')} />
            )}
            Solar Water Heater
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.textHeader1}>
            {hwmApartmentOption === 'heat pump' ? (
              <CheckCircleIcon className={classes.selectedCheckbox} />
            ) : (
              <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => handleHwmApartmentOption('heat pump')} />
            )}
            Heat Pump
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.textHeader1}>
            {hwmApartmentOption === 'hybrid' ? (
              <CheckCircleIcon className={classes.selectedCheckbox} />
            ) : (
              <RadioButtonUncheckedIcon className={classes.nonselectedCheckbox} onClick={() => handleHwmApartmentOption('hybrid')} />
            )}
            Hybrid
          </Typography>
        </Grid>
      </Grid>
      {hwmApartmentOption === 'solar water heater' && (
        <>
          <Grid item xs={4} container alignItems="flex-start" direction="column" style={{ marginTop: '3%' }}>
            <Grid xs>
              <Typography className={classes.textheader2}>{getQuantityLabel(schema.hwmApartment__pi_userSwhCapacity)}</Typography>
            </Grid>
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  fullWidth
                  required
                  value={getQuantityValue(schema.hwmApartment__pi_userSwhCapacity)}
                  onChange={(e) => updateQuantityValue(schema.hwmApartment__pi_userSwhCapacity, e.target.value)}
                  onClick={() => updateQuantityValue(schema.hwmApartment__pi_userSwhCapacity, getQuantityDefaultValue(schema.hwmApartment__pi_userSwhCapacity))}
                  disabled={false}
                />
              </Grid>
              <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">{getQuantityUnit(schema.hwmApartment__pi_userSwhCapacity)}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} container alignItems="flex-start">
            <Grid item xs={6}>
              <Typography className={classes.textheader2}>Type</Typography>
              <Typography className={classes.textHeader3}>
                {swhType === 'central' ? (
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                ) : (
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhTypeSWH('central')} />
                )}
                Central
              </Typography>
              <Typography className={classes.textHeader3}>
                {swhType === 'individual' ? (
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                ) : (
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhTypeSWH('individual')} />
                )}
                Individual
              </Typography>
            </Grid>
            {getQuantityValue(schema.hwmApartment__pi_swhType) !== 'central' && (
              <Grid item xs={6}>
                <Typography className={classes.textheader2}>Technology</Typography>
                <Typography className={classes.textHeader3}>
                  {swhTechnology === 'etc' ? (
                    <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                  ) : (
                    <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhTechnology('etc')} />
                  )}
                  ETC
                  <CustomizedTooltips
                    icon="info"
                    title={<Typography>ETC (Evacuated Tube Collector): Employs glass tubes with absorber coatings. </Typography>}
                    placement="right"
                  />
                </Typography>
                <Typography className={classes.textHeader3}>
                  {swhTechnology === 'fpc' ? (
                    <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                  ) : (
                    <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleSWhTechnology('fpc')} />
                  )}
                  FPC
                  <CustomizedTooltips
                    icon="info"
                    title={<Typography>FPC (Flat Plate Collector): Utilizes a flat, rectangular panel to absorb sunlight.</Typography>}
                    placement="right"
                  />
                </Typography>
              </Grid>
            )}
            {getQuantityValue(schema.hwmApartment__pi_swhType) === 'central' && (
              <Grid item xs={6}>
                <Typography className={classes.textheader2}>Technology</Typography>
                <Typography className={classes.textHeader3} style={{ color: 'rgba(158, 158, 158, 1)' }}>
                  <RadioButtonUncheckedIcon disabled className={classes.nonselectedRadiobox} />
                  ETC
                  <CustomizedTooltips
                    icon="info"
                    title={<Typography>ETC (Evacuated Tube Collector): Employs glass tubes with absorber coatings. </Typography>}
                    placement="right"
                  />
                </Typography>
                <Typography className={classes.textHeader3} style={{ color: 'rgba(158, 158, 158, 1)' }}>
                  <RadioButtonUncheckedIcon disabled className={classes.nonselectedRadiobox} />
                  FPC
                  <CustomizedTooltips
                    icon="info"
                    title={<Typography>FPC (Flat Plate Collector): Utilizes a flat, rectangular panel to absorb sunlight.</Typography>}
                    placement="right"
                  />
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container item xs={12} direction="row">
            <Grid item xs={6}>
              <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
                <Grid item xs={6}>
                  <Typography className={classes.textHeader3}> {getQuantityLabel(schema.hwmApartment_swhIndividualProductCapacity)}</Typography>
                  {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(individualproductcapacity?.value))} ${individualproductcapacity?.unit}`}</Typography> */}
                  <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                    {getQuantityValue(schema.hwmApartment_swhIndividualProductCapacity)} {getQuantityUnit(schema.hwmApartment_swhIndividualProductCapacity)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmApartment_swhNumberOfProducts)}</Typography>
                  {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberofproducts?.value} ${numberofproducts?.unit}`}</Typography> */}
                  <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                    {getQuantityValue(schema.hwmApartment_swhNumberOfProducts)} {getQuantityUnit(schema.hwmApartment_swhNumberOfProducts)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
                <Grid item xs={6}>
                  <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmApartment_swhAreaRequired)}</Typography>
                  {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(hwmApartmentareaRequired?.value))} ${hwmApartmentareaRequired?.unit}`}</Typography> */}
                  <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                    {getQuantityValue(schema.hwmApartment_swhAreaRequired)} {getQuantityUnit(schema.hwmApartment_swhAreaRequired)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {hwmApartmentOption === 'heat pump' && (
        <>
          <Grid item xs={4} container alignItems="flex-start" direction="column" style={{ marginTop: '3%' }}>
            <Grid xs>
              <Typography className={classes.textheader2}>{getQuantityLabel(schema.hwmApartment__pi_userHpCapacity)}</Typography>
            </Grid>
            <Grid xs={12} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
              <Grid xs={7}>
                <ResetFields
                  id="outlined-basic"
                  variant="outlined"
                  placeholder=""
                  fullWidth
                  required
                  value={getQuantityValue(schema.hwmApartment__pi_userHpCapacity)}
                  onChange={(e) => updateQuantityValue(schema.hwmApartment__pi_userHpCapacity, e.target.value)}
                  onClick={() => updateQuantityValue(schema.hwmApartment__pi_userHpCapacity, getQuantityDefaultValue(schema.hwmApartment__pi_userHpCapacity))}
                  disabled={false}
                />
              </Grid>
              <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                <Typography variant="h6">{getQuantityUnit(schema.hwmApartment__pi_userHpCapacity)}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} container alignItems="flex-start">
            <Grid item xs={6}>
              <Typography className={classes.textheader2}>Type</Typography>
              <Typography className={classes.textHeader3}>
                {hpType === 'commercial' ? (
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                ) : (
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpType('commercial')} />
                )}
                Commercial
              </Typography>
              <Typography className={classes.textHeader3}>
                {hpType === 'domestic' ? (
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                ) : (
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpType('domestic')} />
                )}
                Domestic
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.textheader2}>Heating</Typography>
              <Typography className={classes.textHeader3}>
                {hpTemp === 'slow' ? (
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                ) : (
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpTemp('slow')} />
                )}
                Slow Heating
              </Typography>
              <Typography className={classes.textHeader3}>
                {hpTemp === 'fast' ? (
                  <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                ) : (
                  <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHpTemp('fast')} />
                )}
                Fast Heating
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} direction="row">
            <Grid item xs={6}>
              <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
                <Grid item xs={6}>
                  <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmApartment_hpIndividualProductCapacity)}</Typography>
                  {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(individualproductcapacity?.value))} ${individualproductcapacity?.unit}`}</Typography> */}
                  <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                    {getQuantityValue(schema.hwmApartment_hpIndividualProductCapacity)} {getQuantityUnit(schema.hwmApartment_hpIndividualProductCapacity)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmApartment_hpNumberOfProducts)}</Typography>
                  {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberofproducts?.value} ${numberofproducts?.unit}`}</Typography> */}
                  <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                    {getQuantityValue(schema.hwmApartment_hpNumberOfProducts)} {getQuantityUnit(schema.hwmApartment_hpNumberOfProducts)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
                <Grid item xs={6}>
                  <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmApartment_hpAreaRequired)}</Typography>
                  {/* <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>{`${numberWithCommas(Number(hwmApartmentareaRequired?.value))} ${hwmApartmentareaRequired?.unit}`}</Typography> */}
                  <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                    {getQuantityValue(schema.hwmApartment_hpAreaRequired)} {getQuantityUnit(schema.hwmApartment_hpAreaRequired)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {hwmApartmentOption === 'hybrid' && (
        <>
          <Grid container item xs={12} direction="row">
            <Grid item xs={6}>
              <Grid item xs={12} container alignItems="flex-start" direction="column" style={{ marginTop: '3%' }}>
                <Grid xs>
                  <Typography className={classes.textheader2}>{getQuantityLabel(schema.hwmApartment__pi_userHybridSwhCapacity)}</Typography>
                </Grid>
                <Grid xs={10} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
                  <Grid xs={5}>
                    <ResetFields
                      id="outlined-basic"
                      variant="outlined"
                      placeholder=""
                      fullWidth
                      required
                      disabled={false}
                      value={getQuantityValue(schema.hwmApartment__pi_userHybridSwhCapacity)}
                      onChange={(e) => updateQuantityValue(schema.hwmApartment__pi_userHybridSwhCapacity, e.target.value)}
                      onClick={() =>
                        updateQuantityValue(
                          schema.hwmApartment__pi_userHybridSwhCapacity,
                          getQuantityDefaultValue(schema.hwmApartment__pi_userHybridSwhCapacity)
                        )
                      }
                    />
                  </Grid>
                  <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                    <Typography variant="h6">{getQuantityUnit(schema.hwmApartment__pi_userHybridSwhCapacity)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8} container alignItems="flex-start">
                <Grid item xs={6}>
                  <Typography className={classes.textheader2}>Type</Typography>
                  <Typography className={classes.textHeader3}>
                    <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                    Central
                  </Typography>
                  <Typography className={classes.textHeader3} style={{ color: 'rgba(158, 158, 158, 1)' }}>
                    <RadioButtonUncheckedIcon disabled className={classes.nonselectedRadiobox} />
                    Individual
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.textheader2}>Technology</Typography>
                  <Typography className={classes.textHeader3}>
                    {hybridSwhVariant === 'etc' ? (
                      <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                    ) : (
                      <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHybridSWhTechnology('etc')} />
                    )}
                    ETC
                    <CustomizedTooltips
                      icon="info"
                      title={<Typography>ETC (Evacuated Tube Collector): Employs glass tubes with absorber coatings. </Typography>}
                      placement="right"
                    />
                  </Typography>
                  <Typography className={classes.textHeader3}>
                    {hybridSwhVariant === 'fpc' ? (
                      <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                    ) : (
                      <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHybridSWhTechnology('fpc')} />
                    )}
                    FPC
                    <CustomizedTooltips
                      icon="info"
                      title={<Typography>FPC (Flat Plate Collector): Utilizes a flat, rectangular panel to absorb sunlight.</Typography>}
                      placement="right"
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12} container alignItems="flex-start" direction="column" style={{ marginTop: '3%' }}>
                <Grid xs>
                  <Typography className={classes.textheader2}>{getQuantityLabel(schema.hwmApartment__pi_userHybridHpCapacity)}</Typography>
                </Grid>
                <Grid xs={10} container alignItems="flex-start" direction="row" style={{ marginTop: '5%', display: 'flex', alignItems: 'center' }}>
                  <Grid xs={5}>
                    <ResetFields
                      id="outlined-basic"
                      variant="outlined"
                      placeholder=""
                      disabled={false}
                      fullWidth
                      required
                      value={getQuantityValue(schema.hwmApartment__pi_userHybridHpCapacity)}
                      onChange={(e) => updateQuantityValue(schema.hwmApartment__pi_userHybridHpCapacity, e.target.value)}
                      onClick={() =>
                        updateQuantityValue(schema.hwmApartment__pi_userHybridHpCapacity, getQuantityDefaultValue(schema.hwmApartment__pi_userHybridHpCapacity))
                      }
                    />
                  </Grid>
                  <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
                    <Typography variant="h6">{getQuantityUnit(schema.hwmApartment__pi_userHybridHpCapacity)}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8} container alignItems="flex-start">
                <Grid item xs={6}>
                  <Typography className={classes.textheader2}>Type</Typography>
                  <Typography className={classes.textHeader3}>
                    <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                    Commercial
                  </Typography>
                  <Typography className={classes.textHeader3} style={{ color: 'rgba(158, 158, 158, 1)' }}>
                    <RadioButtonUncheckedIcon disabled className={classes.nonselectedRadiobox} />
                    Domestic
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.textheader2}>Heating</Typography>
                  <Typography className={classes.textHeader3}>
                    {hybridHeatingSpeed === 'slow' ? (
                      <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                    ) : (
                      <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHybridHpTemp('slow')} />
                    )}
                    Slow Heating
                  </Typography>
                  <Typography className={classes.textHeader3}>
                    {hybridHeatingSpeed === 'fast' ? (
                      <RadioButtonCheckedIcon className={classes.selectedRadiobox} />
                    ) : (
                      <RadioButtonUncheckedIcon className={classes.nonselectedRadiobox} onClick={() => handleHybridHpTemp('fast')} />
                    )}
                    Fast Heating
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} direction="row">
            <Grid item xs={6}>
              <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
                <Grid item xs={6}>
                  <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmApartment_hybridSwhIndividualProductCapacity)}</Typography>
                  <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                    {getQuantityValue(schema.hwmApartment_hybridSwhIndividualProductCapacity)}
                    {getQuantityUnit(schema.hwmApartment_hybridSwhIndividualProductCapacity)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.textHeader3}>{getQuantityLabel(schema.hwmApartment_hybridSwhNumberOfProducts)}</Typography>
                  <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                    {getQuantityValue(schema.hwmApartment_hybridSwhNumberOfProducts)} {getQuantityUnit(schema.hwmApartment_hybridSwhNumberOfProducts)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
                <Grid item xs={6}>
                  <Typography className={classes.textHeader3}> {getQuantityLabel(schema.hwmApartment_hybridSwhAreaRequired)}</Typography>
                  <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                    {getQuantityValue(schema.hwmApartment_hybridSwhAreaRequired)} {getQuantityUnit(schema.hwmApartment_hybridSwhAreaRequired)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
                <Grid item xs={6}>
                  <Typography className={classes.textHeader3}> {getQuantityLabel(schema.hwmApartment_hybridHpIndividualProductCapacity)}</Typography>
                  <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                    {getQuantityValue(schema.hwmApartment_hybridHpIndividualProductCapacity)}
                    {getQuantityUnit(schema.hwmApartment_hybridHpIndividualProductCapacity)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.textHeader3}> {getQuantityLabel(schema.hwmApartment_hybridHpNumberOfProducts)}</Typography>
                  <Typography className={classes.textheader2} style={{ paddingTop: '10px' }}>
                    {getQuantityValue(schema.hwmApartment_hybridHpNumberOfProducts)} {getQuantityUnit(schema.hwmApartment_hybridHpNumberOfProducts)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} container alignItems="flex-start" style={{ paddingTop: '3%' }}>
                {/* <Grid item xs={6}>
                    <Typography className={classes.textHeader3}>Area Required</Typography>
                    <Typography className={classes.textheader2} style={{ paddingTop: "10px" }}>2 sqm</Typography>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default HWMApartmentCustomBasicParameters;
