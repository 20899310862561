import React, { useState, useContext } from 'react';
import { Grid, Typography, makeStyles, InputAdornment, Link, InputLabel, FormControl } from '@material-ui/core';
import cookie from 'react-cookies';

import { UserContext } from '../routing/IndexRouting';
import Loader from '../components/common/progressbar/Loader';
import OutlinedTextField from '../components/common/textfield/OutlinedTextField';
import CustomButton from '../components/common/button/CustomButton';
import CustomIconButton from '../components/common/button/CustomIconButton';
import CustomOutlinedInput from '../components/common/textfield/CustomOutlinedInput';
import { useAppAnalytics } from '../hooks/useAppAnalytics';
import { isValidEmail } from '../v2/utils';
import axios from '../config/Axios';
import api from '../http';
import { updateToken } from '../http/http';
const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(0),
    marginTop: theme.spacing(1),
  },
  response: {
    padding: '3% 3%',
    color: theme.palette.warning.main,
    backgroundColor: '#FFF5E2',
    border: '1px solid rgba(255, 167, 86, 0.5)',
    boxSizing: 'border-box',
    borderRadius: '10px',
  },
}));

export default function Login() {
  const { sendEvent } = useAppAnalytics();
  const classes = useStyles();
  const { handleLoginToken, userDetails, setUserDetails } = useContext(UserContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [loginError, setLoginError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleEmail = (event) => {
    const isValid = isValidEmail(event.target.value);
    setIsEmailValid(isValid);
    setEmail(event.target.value);
    setLoginError(false);
  };

  const handlePassword = (event) => {
    setIsPasswordValid(!!event.target.value);
    setPassword(event.target.value);
    setLoginError(false);
  };

  const makeRequest = async () => {
    setIsDisabled(true);

    try {
      const response = await api.postLogin(email, password);
      handleLoginSuccess(response);
    } catch (error) {
      const message = error.response.data.error;
      setLoginError(message);
    } finally {
      setIsDisabled(false);
    }
  };

  const handleLogin = async () => {
    makeRequest();
    sendEvent({
      eventAction: 'user_logged_in',
    });
  };

  const handleLoginSuccess = (data) => {
    const expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * 30);

    cookie.save('SDPLUS_TOKEN', data.token, { path: '/', expires, maxAge: 2592000 });
    axios.defaults.headers.common = { Authorization: `Bearer ${data.token}` };
    updateToken(data.token);
    setUserDetails({
      ...userDetails,
      _id: data._id,
      role: data.role,
      name: data.name,
      email: data.email,
      organisation: data.organisation,
    });
    handleLoginToken(data.token);
  };

  return (
    <>
      <form className={classes.form} noValidate>
        <OutlinedTextField
          variant="outlined"
          margin="normal"
          required
          value={email}
          onChange={handleEmail}
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />

        {!isEmailValid && <Typography style={{ color: 'red', fontSize: '12px' }}>You have entered an invalid email address!</Typography>}

        <FormControl margin="normal" fullWidth variant="outlined" required>
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>

          <CustomOutlinedInput
            id="outlined-adornment-password"
            value={password}
            onChange={handlePassword}
            name="password"
            type={isShowPassword ? 'text' : 'password'}
            autoComplete="current-password"
            error={!isPasswordValid}
            endAdornment={
              <InputAdornment position="end">
                <CustomIconButton
                  onClick={() => {
                    setIsShowPassword(!isShowPassword);
                  }}
                  edge="end"
                >
                  <Typography variant="h6" color={isShowPassword ? 'textSecondary' : 'primary'}>
                    Show
                  </Typography>
                </CustomIconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>

        {loginError && (
          <Grid container item xs={12}>
            <Typography style={{ color: 'red' }} gutterBottom>
              {loginError}
            </Typography>
          </Grid>
        )}

        <Grid container item xs={12}>
          <Typography variant="body1" gutterBottom className={classes.typo}>
            <Link href="/forgot-password" style={{ color: '#2EE1AC', paddingBottom: '10px', textDecoration: 'none' }}>
              Forgot Password?
            </Link>
          </Typography>
        </Grid>

        {isDisabled && (
          <Grid container direction="column" alignItems="center" item xs={12}>
            <Grid item xs={12}>
              <Loader />
            </Grid>
          </Grid>
        )}

        <CustomButton type="button" onClick={handleLogin} fullWidth variant="contained" className={classes.submit}>
          Login
        </CustomButton>
      </form>
    </>
  );
}
