import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(4),
    minHeight: '100vh',
    marginRight: '15%',
  },
  card: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  logo: {
    width: 160,
    height: 160,
    borderRadius: theme.spacing(1),
    backgroundColor: '#e0e0e0',
  },
  updateButton: {
    marginTop: '5%',
    backgroundColor: '#64dba2',
    color: '#fff',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#52c18e',
    },
  },
  searchInput: {
    width: '100%',
  },
  inviteButton: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    backgroundColor: '#64dba2',
    color: '#fff',
    fontWeight: 'bold',
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#52c18e',
    },
  },
  deleteIcon: {
    color: '#ff5252',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: '600px',
    outline: 'none',
    maxHeight: 600,
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    padding: theme.spacing(2, 2, 2, 3),
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
}));
