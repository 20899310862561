import { NavigatorSource } from '../utils/Defaults';

export const DummyNavigatorData = {
  tabs: ['Products'],
  columns: [
    {
      id: 1,
      source: NavigatorSource.categories,
      collections: [
        {
          name: 'Plumbing Fixtures',
          count: 169,
          subcategories: [
            { id: '134', attributes: { name: 'WCs' } },
            { id: '139', attributes: { name: 'Showers' } },
            { id: '140', attributes: { name: 'Accessories' } },
            { id: '141', attributes: { name: 'Faucets' } },
          ],
        },
        {
          name: 'Paints',
          count: 76,
          subcategories: [
            { id: '138', attributes: { name: 'Interior Paint' } },
            { id: '144', attributes: { name: 'Exterior Paint' } },
          ],
        },
        {
          name: 'Wall Blocks',
          count: 38,
          subcategories: [
            { id: '147', attributes: { name: 'Non Load Bearing' } },
            { id: '151', attributes: { name: 'Load Bearing' } },
          ],
        },
        {
          name: 'Flooring',
          count: 25,
          subcategories: [
            { id: '133', attributes: { name: 'Exterior Flooring' } },
            { id: '150', attributes: { name: 'Interior Flooring' } },
          ],
        },
        {
          name: 'Cement',
          count: 23,
          subcategories: [
            { id: '136', attributes: { name: 'CHD' } },
            { id: '137', attributes: { name: 'PPC' } },
            { id: '143', attributes: { name: 'PCC' } },
            { id: '146', attributes: { name: 'PSC' } },
            { id: '149', attributes: { name: 'PPC' } },
          ],
        },
        {
          name: 'Steel',
          count: 21,
          subcategories: [{ id: '145', attributes: { name: 'TMT Bars' } }],
        },
        {
          name: 'Glazing',
          count: 19,
          subcategories: [
            { id: '135', attributes: { name: 'Single Glazed' } },
            { id: '142', attributes: { name: 'Insulated Glazed' } },
            { id: '148', attributes: { name: 'Double Glazed' } },
          ],
        },
        {
          name: 'Hot Water Management',
          count: 18,
          subcategories: [
            { id: '152', attributes: { name: 'Solar Water Heaters' } },
            { id: '153', attributes: { name: 'Heat Pumps' } },
            { id: '154', attributes: { name: 'Hybrid' } },
          ],
        },
        {
          name: 'SPV',
          count: 15,
          subcategories: [
            { id: '155', attributes: { name: 'Off-Grid' } },
            { id: '156', attributes: { name: 'On-Grid' } },
            { id: '157', attributes: { name: 'Hybrid' } },
          ],
        },
        {
          name: 'Waste Water Treatment',
          count: 12,
          subcategories: [
            { id: '158', attributes: { name: 'Tertiary Treatment' } },
            { id: '159', attributes: { name: 'Natural STP' } },
            { id: '160', attributes: { name: 'Other STP Technologies' } },
          ],
        },
        {
          name: 'Water Management',
          count: 10,
          subcategories: [{ id: '161', attributes: { name: 'Smart Water Meters' } }],
        },
        {
          name: 'Wet Waste Management',
          count: 8,
          subcategories: [
            { id: '162', attributes: { name: 'Fully Automatic Composters' } },
            { id: '163', attributes: { name: 'Semi-Automatic Composters' } },
            { id: '164', attributes: { name: 'Compost Bins' } },
          ],
        },
        {
          name: 'Roof Solutions',
          count: 7,
          subcategories: [{ id: '165', attributes: { name: 'Cool Roof' } }],
        },
        {
          name: 'Waste Management Company',
          count: 5,
          subcategories: [{ id: '166', attributes: { name: 'Waste Management Company' } }],
        },
      ],
    },
    {
      id: 2,
      source: NavigatorSource.supercategories,
      collections: [
        { id: '2', attributes: { Name: 'Envelope' } },
        { id: '3', attributes: { Name: 'Structure' } },
        { id: '4', attributes: { Name: 'Interior' } },
        { id: '5', attributes: { Name: 'Finishes' } },
        { id: '7', attributes: { Name: 'Plumbing' } },
      ],
    },
    {
      id: 3,
      source: NavigatorSource.ecolabels,
      collections: [
        { id: '9', attributes: { name: 'ECBC' } },
        { id: '10', attributes: { name: 'Green Assure' } },
        { id: '11', attributes: { name: 'GreenCo' } },
        { id: '13', attributes: { name: 'Green Label' } },
        { id: '12', attributes: { name: 'GreenPro' } },
        { id: '14', attributes: { name: 'Griha' } },
        { id: '15', attributes: { name: 'IGBC' } },
        { id: '16', attributes: { name: 'LEED' } },
        { id: '17', attributes: { name: 'UIPC-I' } },
      ],
    },
  ],
  drawerData: [
    {
      id: 1,
      name: 'Products',
      data: [
        { id: 1, name: 'Wall Blocks', data: ['Hollow', 'Solid'] },
        { id: 2, name: 'Plumbing Fixtures', data: ['Showers', 'Plumbing', 'Faucets', 'WCs'] },
        { id: 3, name: 'Paints', data: ['Interior Paints', 'Exterior Paints'] },
        { id: 4, name: 'Glazing', data: ['Single Glazed', 'Double Glazed'] },
      ],
    },
    {
      id: 2,
      name: 'Services',
      data: [],
    },
    {
      id: 3,
      name: 'Certifications',
      data: [],
    },
    {
      id: 4,
      name: 'Envelope',
      data: [],
    },
    {
      id: 5,
      name: 'Interior',
      data: [],
    },
    {
      id: 6,
      name: 'Exterior',
      data: [],
    },
    {
      id: 7,
      name: 'Plumbing',
      data: [],
    },
    {
      id: 8,
      name: 'Appliances',
      data: [],
    },
  ],
};
