import { Grid, makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import ProductImageFallback from '../../../assets/Marketplace/Image not available 1.svg';

export const SearchResultList = ({ option }) => {
  const useStyles = makeStyles((theme) => ({
    textstyle: {
      fontFamily: 'Lato',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0.5px',
      color: '#2E2E2E',
    },
  }));
  const classes = useStyles();

  const [productImage, setProductImage] = useState('');
  const [isImageAccessible, setIsImageAccessible] = useState(false);
  useEffect(() => {
    if (productImage && productImage.trim() !== '') {
      const img = new Image();
      img.src = productImage;
      img.onload = () => setIsImageAccessible(true);
      img.onerror = () => setIsImageAccessible(false);
    }
  }, [productImage]);
  useEffect(() => {
    setProductImage(option?.image);
  }, [option]);
  /*these valuse can be made camel case but requires API response change*/
  const { sku_name, categoryname, supercategoryname, subcategoryname } = option || {};

  let primaryLabel = '';
  if (categoryname && supercategoryname) {
    primaryLabel = categoryname;
  } else if (supercategoryname && !categoryname) {
    primaryLabel = supercategoryname;
  } else if (subcategoryname) {
    primaryLabel = subcategoryname;
  } else {
    primaryLabel = sku_name;
  }

  let secondaryLabel = '';
  if (categoryname && supercategoryname) {
    secondaryLabel = `in ${supercategoryname}`;
  } else if (supercategoryname && !categoryname) {
    secondaryLabel = '';
  } else if (subcategoryname) {
    secondaryLabel = `in ${categoryname}`;
  }

  return (
    <Grid container item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span className={classes.textstyle} style={{ display: 'flex', alignItems: 'center' }}>
        {sku_name && <img style={{ height: '20px', paddingRight: '10px' }} src={isImageAccessible ? productImage : ProductImageFallback} alt="..." />}
        {primaryLabel}
      </span>
      <span className={classes.textstyle} style={{ fontWeight: 300, textAlign: 'right' }}>
        {secondaryLabel}
      </span>
    </Grid>
  );
};
