import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    borderRadius: 8,
    backgroundColor: '#fff',
    width: '640px',
    height: '481px',
    margin: 'auto',
    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
  },
  inputContainer: {
    justifyContent: 'top',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius,
    cursor: 'text',
    height: '118px',
    overflowY: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
  },
  hiddenInput: {
    border: 'none',
    outline: 'none',
    background: 'transparent',
    width: '100px',
    minWidth: '50px',
    flexGrow: 1,
    '& .MuiOutlinedInput-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
  chip: {
    marginTop: '2%',
    color: 'rgba(0, 0, 0, 0.8)',
    borderRadius: '8px',
    border: '1px solid #C6F2CD',
    '&.error': {
      border: '1px solid red',
      color: '#ED5E68',
    },
    '& .MuiChip-label': {
      fontSize: '14px',
      padding: '10px',
    },
  },
  errorText: {
    color: '#ff0000',
  },
  actions: {
    marginTop: theme.spacing(3),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
