export const WasteManagementCompanies = ({ isMobile, isselected }, props) => {
  let color = isselected ? '#FFFFFF' : '#EAEAEA';
  return (
    <svg width={80} height={80} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width={80} height={80} fill="#E0E0E0" />
      <g id="Category Updates">
        <path
          d="M-2677 -777C-2677 -778.105 -2676.1 -779 -2675 -779H516C517.104 -779 518 -778.105 518 -777V662C518 663.105 517.104 664 516 664H-2675C-2676.1 664 -2677 663.105 -2677 662V-777Z"
          fill={color}
          fillOpacity={0.666667}
        />
        <path
          d="M-2675 -778H516V-780H-2675V-778ZM517 -777V662H519V-777H517ZM516 663H-2675V665H516V663ZM-2676 662V-777H-2678V662H-2676ZM-2675 663C-2675.55 663 -2676 662.552 -2676 662H-2678C-2678 663.657 -2676.66 665 -2675 665V663ZM517 662C517 662.552 516.552 663 516 663V665C517.657 665 519 663.657 519 662H517ZM516 -778C516.552 -778 517 -777.552 517 -777H519C519 -778.657 517.657 -780 516 -780V-778ZM-2675 -780C-2676.66 -780 -2678 -778.657 -2678 -777H-2676C-2676 -777.552 -2675.55 -778 -2675 -778V-780Z"
          fill="black"
          fillOpacity={0.1}
        />
        <g id="All Categories - Updates" clipPath="url(#clip0_21589_27101)">
          <rect width={1440} height={1283} transform="translate(-1080 -699)" fill="white" />
          <g id="Frame 26086812">
            <g id="Group 26087369">
              <g id="Group 26087367">
                <g id="Frame 26086809">
                  <circle id="Ellipse 542" cx={40} cy={40} r={78.5} fill="white" stroke="#33518E" strokeWidth={3} />
                  <g id="noun-waste-management-6647482 3">
                    <path
                      id="Vector"
                      d="M37.5 34.3312C38.0375 34.6437 38.7313 34.4562 39.0469 33.9187C39.7813 32.6437 41.8282 32.6437 42.5656 33.9187L45.7469 39.4312L44.4344 38.6937C43.8875 38.3812 43.2031 38.5781 42.8938 39.125C42.5875 39.6687 42.7813 40.3625 43.325 40.6656L48.1313 43.3719C48.3032 43.4687 48.4938 43.5156 48.6875 43.5156C48.8875 43.5156 49.0844 43.4625 49.2625 43.3594C49.6125 43.1531 49.8219 42.7781 49.8188 42.3719L49.7563 36.8563C49.75 36.2375 49.2438 35.7376 48.625 35.7376H48.6125C47.9875 35.7438 47.4875 36.2563 47.4938 36.8813L47.5063 37.95L44.525 32.7875C43.75 31.4438 42.3563 30.6406 40.8063 30.6406C39.2563 30.6406 37.8657 31.4438 37.0876 32.7875C36.7719 33.3281 36.9563 34.0187 37.5 34.3312Z"
                      fill="#213162"
                    />
                    <path
                      id="Vector_2"
                      d="M33 50.8884C33.625 50.8884 34.1313 50.3822 34.1313 49.7572C34.1313 49.1322 33.625 48.6259 33 48.6259C32.2656 48.6259 31.6063 48.2447 31.2438 47.6134C30.875 46.9759 30.875 46.2196 31.2438 45.5821L34.425 40.0727L34.4407 41.5758C34.4469 42.1946 34.9532 42.6946 35.5719 42.6946H35.5844C36.2094 42.6883 36.7094 42.1758 36.7032 41.5508L36.6438 36.0321C36.6375 35.6321 36.4188 35.2571 36.0688 35.0602C35.7157 34.8602 35.2844 34.8665 34.9375 35.0727L30.1906 37.8853C29.6531 38.204 29.475 38.8978 29.7938 39.4353C30.1125 39.9696 30.8063 40.1509 31.3438 39.8321L32.2656 39.2884L29.2844 44.4509C28.5094 45.7947 28.5094 47.4009 29.2844 48.7447C30.0562 50.0853 31.4469 50.8884 33 50.8884L33 50.8884Z"
                      fill="#213162"
                    />
                    <path
                      id="Vector_3"
                      d="M49.1722 44.9633C48.6285 45.2758 48.4441 45.9664 48.7597 46.507C49.1285 47.1414 49.1285 47.9008 48.7597 48.5383C48.391 49.1727 47.7347 49.5508 47.0035 49.5508H40.6441L41.9378 48.7883C42.4753 48.4696 42.6534 47.7758 42.3347 47.2383C42.019 46.7071 41.3221 46.5258 40.7847 46.8414L36.0378 49.654C35.6878 49.8602 35.4784 50.2352 35.4815 50.6414C35.4878 51.0477 35.7065 51.4164 36.0565 51.6133L40.8628 54.3196C41.0378 54.4196 41.2284 54.4633 41.4159 54.4633C41.8097 54.4633 42.1972 54.2571 42.4034 53.8883C42.7097 53.3446 42.5159 52.6571 41.9721 52.3477L41.0347 51.8196H47.0034C48.5565 51.8196 49.9471 51.0165 50.7221 49.6727C51.4971 48.329 51.4971 46.7227 50.7221 45.379C50.4034 44.8352 49.7065 44.6446 49.1721 44.9634L49.1722 44.9633Z"
                      fill="#213162"
                    />
                    <path
                      id="Vector_4"
                      d="M49.1813 66.9211C49.1813 69.0836 50.9439 70.843 53.1063 70.843C55.2688 70.843 57.0282 69.0836 57.0282 66.9211C57.0282 64.7586 55.2688 62.9961 53.1063 62.9961C50.9438 62.9992 49.1813 64.7555 49.1813 66.9211ZM54.7657 66.9211C54.7657 67.8367 54.022 68.5774 53.1063 68.5774C52.1907 68.5774 51.4469 67.8336 51.4469 66.9211C51.4469 66.0055 52.1907 65.2617 53.1063 65.2617C54.022 65.2617 54.7657 66.0054 54.7657 66.9211Z"
                      fill="#213162"
                    />
                    <path
                      id="Vector_5"
                      d="M61.7815 16.443H57.6002L56.5158 9.96795C56.0471 7.16169 53.6408 5.12422 50.7939 5.12422L29.2066 5.12109C26.3629 5.12109 23.9566 7.15856 23.4847 9.96483L22.4003 16.4398H18.2191C16.8159 16.4398 15.6785 17.5773 15.6785 18.9804V22.7492C15.6785 24.1523 16.8159 25.2898 18.2191 25.2898H19.3628L24.9128 66.6304C25.3691 70.093 28.3503 72.7054 31.8501 72.7054H47.6815C49.1065 74.0398 51.0065 74.8742 53.1065 74.8742C57.4939 74.8742 61.0625 71.3054 61.0625 66.9182C61.0625 63.5713 58.9781 60.7119 56.0437 59.5402L60.6375 25.2908H61.7843C63.1875 25.2908 64.3249 24.1534 64.3249 22.7502V18.9815C64.3249 17.5815 63.1843 16.444 61.7812 16.444L61.7815 16.443ZM34.4001 7.38695H45.6035V9.97442C45.6035 10.4182 45.241 10.7807 44.7972 10.7807H35.2099C34.7661 10.7807 34.4036 10.4182 34.4036 9.97442L34.4001 7.38695ZM25.7128 10.3432C26.0003 8.63069 27.4691 7.38695 29.2003 7.38695H32.1315V9.97442C32.1315 11.6682 33.5065 13.0432 35.2003 13.0432H44.7876C46.4813 13.0432 47.8595 11.6682 47.8595 9.97442L47.8626 7.38695H50.7939C52.5313 7.38695 53.997 8.63069 54.2813 10.3432L55.3032 16.4432H24.6939L25.7128 10.3432ZM58.8001 66.9219C58.8001 70.0594 56.247 72.6156 53.1064 72.6156C49.9689 72.6156 47.4127 70.0625 47.4127 66.9219C47.4127 63.7844 49.9689 61.2282 53.1064 61.2282C56.2439 61.2313 58.8001 63.7813 58.8001 66.9219ZM53.8251 59.0372C53.5845 59.0153 53.3501 58.9622 53.1033 58.9622C48.7158 58.9622 45.1473 62.531 45.1473 66.9182C45.1473 68.1901 45.4754 69.3745 46.0098 70.4432H31.8538C29.4851 70.4432 27.4663 68.6776 27.1601 66.3307L21.6507 25.2867H58.3573L53.8251 59.0372ZM62.0625 22.7532C62.0625 22.9063 61.9375 23.0282 61.7843 23.0282H18.219C18.0659 23.0282 17.9409 22.9032 17.9409 22.7532V18.9845C17.9409 18.8314 18.0659 18.7095 18.219 18.7095H61.7877C61.9408 18.7095 62.0658 18.8345 62.0658 18.9845L62.0625 22.7532Z"
                      fill="#213162"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_21589_27101">
          <rect width={1440} height={1283} fill="white" transform="translate(-1080 -699)" />
        </clipPath>
      </defs>
    </svg>
  );
};
