import { useState, useEffect } from 'react';
import { DummyNavigatorData } from '../navbar/StaticNavigatorData';
import useGraphqlRequest from '../../catalogue/Common/SendRequest';
import { query } from '../graphql-queries/Navigator.query';

const insertCategoriesData = (backendData, dummyNavigatorData) => {
  if (!backendData) return dummyNavigatorData;

  const categories = backendData.map(({ id, attributes }) => ({
    id,
    name: attributes.name,
    subcategories: attributes.subcategories.data.map(({ id, attributes }) => ({
      id,
      attributes: { name: attributes.name },
    })),
  }));

  return {
    ...dummyNavigatorData,
    columns: dummyNavigatorData.columns.map((column) => (column.id === 1 && column.source === 'Categories' ? { ...column, collections: categories } : column)),
  };
};

const UseNavigatorData = () => {
  const [navigatorData, setNavigatorData] = useState([]);
  const [categoryDetails, sendCategoryDetailsRequest] = useGraphqlRequest(query);

  useEffect(() => {
    if (!categoryDetails?.response) {
      sendCategoryDetailsRequest();
    }
  }, [categoryDetails, sendCategoryDetailsRequest]);

  useEffect(() => {
    const categories = categoryDetails?.response?.data?.data?.categories?.data;
    if (categories) {
      const { columns } = insertCategoriesData(categories, DummyNavigatorData);
      setNavigatorData({ columns });
    }
  }, [categoryDetails]);

  return { navigatorData };
};

export default UseNavigatorData;
