import React, { useEffect, useState } from 'react';
import { Paper, TableContainer, Grid, Table, TableHead, TableRow, TableCell, Typography, TableBody, Box } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import CustomizedTooltips from '../../../common/tooltip/TooltipV2';
// import Collapse from '@material-ui/core/Collapse';
import { extractData } from '../../../../utils/dataConditionsCheck';
import { extractResource } from '../../../../utils/specialforIGBC';
import numberWithCommas from '../../../../utils/numberToComma';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import axios from 'axios';

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.571429px',
    textTransform: 'uppercase',
  },
  body: {
    paddingTop: 0,
    paddingBottom: 0,
    whiteSpace: 'nowrap',

    lineHeight: '22px',
    letterSpacing: '0.571429px',
    // color: '#000000',
    fontWeight: '600',
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    background: 'rgba(0, 0, 0, 0.03)',
    paddingRight: 1,
    paddingLeft: 1,
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: '100%',
    maxWidth: '100%',
    border: '1px solid rgba(0, 0, 0, 0.14)',
    boxSizing: 'border-box',
    borderRadius: '10px',
  },
  container: {
    width: '100%',
    paddingBottom: '3%',
    paddingTop: '3%',
  },
}));

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {
  const history = useHistory();
  const params = useParams();
  const projectID = params && params.id;
  const { row } = props;

  const [open, setOpen] = React.useState(false);
  const [indexforopen, setIndexforOpen] = React.useState('');
  const classes = useRowStyles();
  const handleClick = (id) => {
    history.push(`/projects/${projectID}/interventions/${id}`);
  };
  return (
    <React.Fragment>
      <StyledTableRow className={classes.root} style={{ background: 'rgba(24, 50, 119, 0.07)', height: '8vh' }}>
        <StyledTableCell component="th" scope="row" style={{ background: 'rgba(24, 50, 119, 0.07)', borderBottom: 'none' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center" container>
            <Typography variant="h6">
              <b>{row.name}</b>
            </Typography>
            {row && row.metaData && row.metaData.tooltip === true ? (
              <CustomizedTooltips
                icon="info"
                title={
                  <Typography>
                    <b>{row.metaData.tooltipdata}</b>
                  </Typography>
                }
                placement="right"
              />
            ) : (
              ''
            )}
          </Grid>
        </StyledTableCell>
        <StyledTableCell align="center" style={{ background: 'rgba(24, 50, 119, 0.07)', borderBottom: 'none' }}>
          <Typography variant="h6">
            <b>
              {row.overall && !row.overall.totalpointsachieved && !row.overall.totalpoints
                ? '-'
                : row.overall && row.overall.totalpointsachieved !== undefined && row.overall.totalpoints !== undefined
                ? `${row.overall.totalpointsachieved}/${row.overall.totalpoints}`
                : '-'}
            </b>
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="center" style={{ background: 'rgba(24, 50, 119, 0.07)', borderBottom: 'none' }}>
          <Typography variant="h6">
            <b>
              {row && row.overall && row.overall.capacity
                ? row.overall.capacity.value !== null
                  ? !isNaN(row.overall.capacity.value)
                    ? `${row.overall.capacity.value.toFixed(2)} ${extractData(row.overall.capacity.unit)}`
                    : row.overall.capacity.value
                  : '-'
                : '-'}
            </b>
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="center" style={{ background: 'rgba(24, 50, 119, 0.07)', borderBottom: 'none' }}>
          <Typography variant="h6">
            <b>
              {row && row.overall && row.overall.capex
                ? `${numberWithCommas(extractData(Math.round(row.overall.capex.value)))} ${extractData(row.overall.capex.unit)}`
                : '-'}
            </b>
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="center" style={{ background: 'rgba(24, 50, 119, 0.07)', borderBottom: 'none' }}>
          <Typography variant="h6">
            <b>
              {row && row.overall && row.overall.opex
                ? `${numberWithCommas(extractData(Math.round(row.overall.opex.value)))} ${extractData(row.overall.opex.unit)}`
                : '-'}
            </b>
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="center" style={{ background: 'rgba(24, 50, 119, 0.07)', borderBottom: 'none' }}>
          <Typography variant="h6">
            <b>
              {row && row.overall && row.overall.resourcesaving
                ? `${numberWithCommas(extractResource(Math.round(row.overall.resourcesaving.value)))} ${extractData(row.overall.resourcesaving.unit)}`
                : '-'}
            </b>
          </Typography>
        </StyledTableCell>
      </StyledTableRow>

      {/*hint to be updated once backend linked  */}
      {row && row.metaData && row.metaData.hasOwnProperty('hints') ? (
        <StyledTableRow style={{ border: 'none', height: '2vh', background: 'rgba(24, 50, 119, 0.13)' }}>
          <StyledTableCell style={{ paddingLeft: 15, paddingRight: 15, paddingBottom: 15 }} colSpan={6} rowSpan={1}>
            {row.metaData.hints?.map((hint, index) => {
              return (
                <Grid container alignItems="center" style={{ background: '#E7F1F3', border: '2px solid #49DDB1', padding: 3, marginTop: index > 0 && '1%' }}>
                  <Grid xs={1} style={{ flexBasis: '0', paddingTop: '0.5%' }} alignItems="center">
                    <InfoOutlinedIcon style={{ color: '#49DDB1', width: '30px', height: '22px' }} />
                  </Grid>
                  <Grid xs={11} alignItems="center">
                    <Typography style={{ fontSize: '13px' }}>{hint}</Typography>
                  </Grid>
                </Grid>
              );
            })}
          </StyledTableCell>
        </StyledTableRow>
      ) : (
        ''
      )}
      {row.subparts &&
        row.subparts.map((credit, c) => {
          return (
            <>
              <StyledTableRow
                style={{ height: '8vh', background: 'rgba(24, 50, 119, 0.07)' }}
                key={c}
                onClick={() => {
                  setOpen(!open);
                  setIndexforOpen(c);
                }}
              >
                <StyledTableCell align="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center" container>
                    <Typography
                      variant="h6"
                      style={credit.linkedintervention === true ? { textDecoration: 'underline', cursor: 'pointer' } : { cursor: 'pointer' }}
                      onClick={() => (credit.linkedintervention === true ? handleClick(credit.interventionid) : null)}
                    >
                      {credit.name}
                    </Typography>
                    {credit && credit.metaData && credit.metaData.tooltip === true ? (
                      <CustomizedTooltips icon="info" title={<Typography>{credit.metaData.tooltipdata}</Typography>} placement="right" />
                    ) : (
                      ''
                    )}
                  </Grid>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="h6">
                    {credit.overall && !credit.overall.totalpointsachieved && !credit.overall.totalpoints
                      ? '-'
                      : credit.overall && credit.overall.totalpointsachieved !== undefined && credit.overall.totalpoints !== undefined
                      ? `${credit.overall.totalpointsachieved}/${credit.overall.totalpoints}`
                      : '-'}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="h6">
                    {credit && credit.overall && credit.overall.capacity
                      ? credit.overall.capacity.value !== null
                        ? !isNaN(credit.overall.capacity.value)
                          ? `${Math.round(credit.overall.capacity.value)} ${extractData(credit.overall.capacity.unit)}`
                          : credit.overall.capacity.value
                        : '-'
                      : '-'}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="h6">
                    {credit && credit.overall && credit.overall.capex
                      ? `${numberWithCommas(extractData(Math.round(credit.overall.capex.value)))} ${extractData(credit.overall.capex.unit)}`
                      : '-'}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="h6">
                    {credit && credit.overall && credit.overall.opex
                      ? `${numberWithCommas(extractData(Math.round(credit.overall.opex.value)))} ${extractData(credit.overall.opex.unit)}`
                      : '-'}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="h6">
                    {credit && credit.overall && credit.overall.resourcesaving
                      ? `${extractResource(credit.overall.resourcesaving.value)} ${extractData(credit.overall.resourcesaving.unit)}`
                      : '-'}
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            </>
          );
        })}
    </React.Fragment>
  );
}

export default function IGBCCreditSubpartsTable({ igbcCategoryContext, selectedCategoryData, creditId }) {
  const classes = useStyles();
  const [creditDetails, setCreditDetails] = useState('');

  useEffect(() => {
    if (selectedCategoryData && creditId) {
      selectedCategoryData.categorysections.forEach((element) => {
        const creditData = element && element.credits.find((c) => c && c.id === creditId);
        if (creditData) setCreditDetails(creditData);
      });
    }
  }, [selectedCategoryData, creditId]);

  return (
    <>
      <Grid container className={classes.container} alignItems="center" justifyContent="center">
        <TableContainer component={Paper} className={classes.table}>
          <Table aria-label="collapsible table">
            <colgroup>
              <col style={{ width: '33.34%' }} />
            </colgroup>
            <TableHead style={{ background: 'rgba(24, 50, 119, 0.07)' }}>
              <StyledTableRow>
                <StyledTableCell>
                  <Typography variant="body1">{igbcCategoryContext && igbcCategoryContext}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1">Points</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1">Capacity</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1">Capex Savings</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1">Opex Savings</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1">Resource Savings</Typography>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {creditDetails &&
                creditDetails.parts.map((data, index) => {
                  return (
                    <>
                      <Row key={index} row={data} />
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}
