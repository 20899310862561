import { useContext, useEffect, useMemo, useState } from 'react';
import { SomethingChanged } from '../EditProject';
import { useQuery } from '@tanstack/react-query';
import api, { queryKeys } from '../../../../http';

const useAppProjectPIPMapping = (projectId, extendFormValues = {}) => {
  const { somethingChanged, handlSetSomethingChanged } = useContext(SomethingChanged);

  const {
    data: projectPIPMapping,
    isError,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: [queryKeys.pipMapping, projectId],
    queryFn: () => api.getProjectPIPMapping({ project: projectId }),
    enabled: projectId !== null && projectId !== undefined,
  });

  const projectPIP = useMemo(() => projectPIPMapping?.data?.data?.data, [projectPIPMapping]);
  const unit = useMemo(() => projectPIP?.find((d) => d.PIPScheme.name === 'project__pi_unitPreferenceForArea'), [projectPIP]);
  const city = useMemo(() => projectPIP?.find((ele) => ele.PIPScheme.name === 'project__pi_city'), [projectPIP]);
  const includeClubhouse = useMemo(() => projectPIP?.find((ele) => ele.PIPScheme.name === 'project__pi_includeClubhouse'), [projectPIP]);

  const pipSchemeObject = useMemo(() => {
    if (projectPIP) {
      return projectPIP.reduce((memo, data) => {
        memo[data?.PIPScheme?._id] = data?.value;
        return memo;
      }, {});
    }
    return {};
  }, [projectPIP]);

  const [form, setForm] = useState(
    Object.assign(
      {
        pipvalues: {},
        unitPIPValue: null,
      },
      extendFormValues
    )
  );

  const handleUpdateForm = (key, value) => {
    if (key) {
      return setForm((oldFormValues) => {
        return {
          ...oldFormValues,
          [key]: value,
        };
      });
    }
  };

  const handlePIPValueUpdate = (id, value) => {
    const updatedPIPValues = Object.assign(form?.pipvalues, {
      [id]: value,
    });
    return handleUpdateForm('pipvalues', updatedPIPValues);
  };

  const hasObjectChanged = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length && keys2.length && keys1.length !== keys2.length) {
      return true;
    }
    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (pipSchemeObject && form?.pipvalues) {
      const isChange = hasObjectChanged(form?.pipvalues, pipSchemeObject);
      handlSetSomethingChanged(isChange);
    }
  }, [somethingChanged, form, pipSchemeObject]);

  useEffect(() => {
    if (projectPIP) {
      projectPIP.forEach((d) => {
        handlePIPValueUpdate(d.PIPScheme._id, d.value);
      });
    }
  }, [projectPIP]);

  useEffect(() => {
    if (unit) {
      handleUpdateForm('unitPIPValue', unit?.value);
    }
  }, [unit]);

  return {
    get: {
      projectPIP,
      form,
      unit,
      city,
      includeClubhouse,
      isError,
      isFetching,
      isLoading,
    },
    set: {
      handleUpdateForm,
      handlePIPValueUpdate,
    },
  };
};

export default useAppProjectPIPMapping;
