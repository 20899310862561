const TOUR_INDEX = {
  MyProject: 0,
  BasicProjectDetails: 1,
  LandDetals: 2,
  Goals: 3,
  Insights: 5,
  InterventionSummary: 9,
  FlowDiagram: 11,
  InterventionDetails: 15,
  DataAndCostAssumptions: 21,
};

const TOUR_STATES = {
  complete: 'complete',
  current: 'current',
  notStarted: 'not-started',
  incomplete: 'incomplete',
};

const COMPLETE_TOUR = [
  { type: 'My Projects', status: TOUR_STATES.complete, active: true },
  { type: 'Project Details', status: TOUR_STATES.complete, active: true },
  { type: 'Goals', status: TOUR_STATES.complete, active: true },
  { type: 'Insights', status: TOUR_STATES.complete, active: true },
  { type: 'Intervention', status: TOUR_STATES.complete, active: true },
  { type: 'Intervention Details', status: TOUR_STATES.complete, active: true },
  { type: 'Certification', status: TOUR_STATES.complete, active: false },
  { type: 'Reports', status: TOUR_STATES.complete, active: false },
];

const START_TOUR = [
  { type: 'My Projects', status: TOUR_STATES.incomplete, active: true },
  { type: 'Project Details', status: TOUR_STATES.notStarted, active: true },
  { type: 'Goals', status: TOUR_STATES.notStarted, active: true },
  { type: 'Insights', status: TOUR_STATES.notStarted, active: true },
  { type: 'Intervention', status: TOUR_STATES.notStarted, active: true },
  { type: 'Intervention Details', status: TOUR_STATES.notStarted, active: true },
  { type: 'Certification', status: TOUR_STATES.notStarted, active: false },
  { type: 'Reports', status: TOUR_STATES.notStarted, active: false },
];

export { TOUR_INDEX, TOUR_STATES, COMPLETE_TOUR, START_TOUR };
