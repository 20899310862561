import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ResetFields from '../common/textFiledwithReset';
import CustomizedTooltips from '../../../../common/tooltip/TooltipV2';
import useStyles from './styles';
import { useInterventionDetailsContext } from '../context/InterventionDetailsV2';

const EPNSCustomAdvancesParameters = (props) => {
  const classes = useStyles();
  const {
    schema,
    get: { getQuantityValue, getQuantityDefaultValue, getQuantityLabel, getQuantityUnit },
    set: { updateQuantityValue },
  } = useInterventionDetailsContext();
  return (
    <Grid container direction="column" alignItems="left" item xs={12}>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>{getQuantityLabel(schema.exteriorPaintsNonSunny__pi_numberOfCoats)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.exteriorPaintsNonSunny__pi_numberOfCoats)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.exteriorPaintsNonSunny__pi_numberOfCoats, e.target.value);
                }}
                onClick={() =>
                  updateQuantityValue(schema.exteriorPaintsNonSunny__pi_numberOfCoats, getQuantityDefaultValue(schema.exteriorPaintsNonSunny__pi_numberOfCoats))
                }
                fullWidth
                required
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.exteriorPaintsNonSunny__pi_numberOfCoats)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>{getQuantityLabel(schema.exteriorPaintsNonSunny__pi_coverageArea)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.exteriorPaintsNonSunny__pi_coverageArea)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.exteriorPaintsNonSunny__pi_coverageArea, e.target.value);
                }}
                onClick={() =>
                  updateQuantityValue(schema.exteriorPaintsNonSunny__pi_coverageArea, getQuantityDefaultValue(schema.exteriorPaintsNonSunny__pi_coverageArea))
                }
                fullWidth
                required
                disabled={false}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography variant="h6">{getQuantityUnit(schema.exteriorPaintsNonSunny__pi_coverageArea)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Intervention Cost Assumptions
        <CustomizedTooltips icon="info" title={<Typography>Tooltip text not available on figma</Typography>} placement="right" />
      </Typography>
      <Typography className={classes.textHeader4}>{getQuantityLabel(schema.exteriorPaintsNonSunny__pi_capexMultiplierSource)}</Typography>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {getQuantityValue(schema.exteriorPaintsNonSunny__pi_capexMultiplierSource) === 'VENDOR' ? (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => updateQuantityValue(schema.exteriorPaintsNonSunny__pi_capexMultiplierSource, 'VENDOR')}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  className={classes.nonselectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.exteriorPaintsNonSunny__pi_capexMultiplierSource, 'VENDOR');
                  }}
                />
              )}
              Selected Vendor
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <Typography className={classes.textHeader3}>
                {getQuantityValue(schema.exteriorPaintsNonSunny__pi_vendorCapex)} {getQuantityUnit(schema.exteriorPaintsNonSunny__pi_vendorCapex)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12} style={{ paddingBottom: '10px' }}>
            <Typography className={classes.textHeader3}>
              {getQuantityValue(schema.exteriorPaintsNonSunny__pi_capexMultiplierSource) === 'CUSTOM' ? (
                <CheckCircleIcon
                  className={classes.selectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.exteriorPaintsNonSunny__pi_capexMultiplierSource, 'CUSTOM');
                  }}
                />
              ) : (
                <RadioButtonUncheckedIcon
                  className={classes.nonselectedCheckbox}
                  onClick={() => {
                    updateQuantityValue(schema.exteriorPaintsNonSunny__pi_capexMultiplierSource, 'CUSTOM');
                  }}
                />
              )}
              {getQuantityLabel(schema.exteriorPaintsNonSunny__pi_customCapex)}
            </Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="row" style={{ display: 'flex', alignItems: 'center' }}>
            <Grid xs={7}>
              <ResetFields
                id="outlined-basic"
                variant="outlined"
                value={getQuantityValue(schema.exteriorPaintsNonSunny__pi_customCapex)}
                placeholder=""
                onChange={(e) => {
                  e.preventDefault();
                  updateQuantityValue(schema.exteriorPaintsNonSunny__pi_customCapex, e.target.value);
                }}
                fullWidth
                required
                onClick={() =>
                  updateQuantityValue(schema.exteriorPaintsNonSunny__pi_customCapex, getQuantityDefaultValue(schema.exteriorPaintsNonSunny__pi_customCapex))
                }
                disabled={getQuantityValue(schema.exteriorPaintsNonSunny__pi_capexMultiplierSource) !== 'CUSTOM' ? true : false}
                removeResetIcon={true}
              />
            </Grid>
            <Grid xs={3} className={classes.grid} style={{ paddingLeft: '2%' }}>
              <Typography
                variant="h6"
                className={getQuantityValue(schema.exteriorPaintsNonSunny__pi_capexMultiplierSource) !== 'CUSTOM' ? classes.textDisable : undefined}
              >
                {getQuantityUnit(schema.exteriorPaintsNonSunny__pi_customCapex)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.textHeader1} style={{ paddingTop: '3%' }}>
        Constants
      </Typography>
      <Grid container item xs={12}>
        <Grid item xs={4} direction="column">
          <Grid container item xs={12}>
            <Typography className={classes.textHeader5}>{getQuantityLabel(schema.exteriorPaintsNonSunny_co2EmissionFactor)}</Typography>
          </Grid>
          <Grid xs={12} container alignItems="flex-start" direction="column">
            <Typography className={classes.textHeader3}>
              {getQuantityValue(schema.exteriorPaintsNonSunny_co2EmissionFactor)} {getQuantityUnit(schema.exteriorPaintsNonSunny_co2EmissionFactor)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} style={{ paddingTop: '50px', paddingBottom: '20px' }}></Grid>
    </Grid>
  );
};
export default EPNSCustomAdvancesParameters;
