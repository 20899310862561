import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Fields from '../../../../../common/textfield/Fields';

const ManualPIP = ({ displayName, pipValues, description, _id, appendChanged, index }) => {
  const handleChange = (e) => {
    e.preventDefault();
    appendChanged(_id, e.target.value);
  };

  const descriptionStyle = {
    lineHeight: '20px',
    fontSize: '13px',
    letterSpacing: '0.571429px',
    color: 'rgba(0, 0, 0, 0.38)',
  };

  return (
    <>
      <Grid container alignItems="flex-start" item xs={12} style={{ marginLeft: '5%', marginTop: index === 0 ? '5%' : '5%' }}>
        <Grid xs={12} container alignItems="flex-start" justifyContent="flex-start">
          <Typography variant="h6">{displayName}</Typography>
        </Grid>

        <Grid xs={12} container alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: description !== 'NA' ? '2%' : '0%' }}>
          <Typography variant="h6" style={descriptionStyle}>
            {description !== 'NA' && description}
          </Typography>
        </Grid>
        <Grid xs={4} container alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: '3%' }}>
          <Fields required value={pipValues[_id]} onChange={handleChange} fullWidth />
        </Grid>
      </Grid>
    </>
  );
};

export default ManualPIP;
