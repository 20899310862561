import { useQuery } from '@tanstack/react-query';
import api, { queryKeys } from '../../../../http';

const useAppPIPScheme = (type) => {
  const {
    data: re,
    isError,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey: [queryKeys.pipSchemeV2, type],
    queryFn: () => api.getProjectPIPScheme({ category: type }),
    enabled: type !== null && type !== undefined,
  });
  const data = re?.data?.data?.data;

  return {
    data,
    isError,
    isFetching,
    isLoading,
  };
};
export default useAppPIPScheme;
