import React from 'react';
import { Paper, TableContainer, Grid, Table, TableHead, TableRow, TableCell, Typography, TableBody } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import CustomizedTooltips from '../../../common/tooltip/TooltipV2';
import { extractData } from '../../../../utils/dataConditionsCheck';
import localbuildingreg from '../../../../assets/IGBC/sustainabledesign/localbuildingreg.svg';
import basicfacilityconstructionWorkforce from '../../../../assets/IGBC/sustainabledesign/basicfacilityconstructionWorkforce.svg';
import basichouseHoldAmenities from '../../../../assets/IGBC/sustainabledesign/basichouseHoldAmenities.svg';
import greenEducation from '../../../../assets/IGBC/sustainabledesign/greeneducation.svg';
import greenparkingfacility from '../../../../assets/IGBC/sustainabledesign/greenparkingfacility.svg';
import naturaltopographyandvegetation from '../../../../assets/IGBC/sustainabledesign/naturaltopographyandvegetation.svg';
import passivearchitecture from '../../../../assets/IGBC/sustainabledesign/passivearchitecture.svg';
import soilerosion from '../../../../assets/IGBC/sustainabledesign/soilerosion.svg';
import universaldesign from '../../../../assets/IGBC/sustainabledesign/universaldesign.svg';
import heatisland from '../../../../assets/IGBC/sustainabledesign/heatisland.svg';
//material and resources
import organicWaste from '../../../../assets/IGBC/materialandresources/organicWaste.svg';
import constructionanddemolitionwaste from '../../../../assets/IGBC/materialandresources/constructionanddemolitionwaste.svg';
import alternativeconstructionmaterial from '../../../../assets/IGBC/materialandresources/alternativeconstructionmaterial.svg';
import organicwastetreecofriendlywoodbasedmaterialatment from '../../../../assets/IGBC/materialandresources/ecofriendlywoodbasedmaterial.svg';
import localmaterial from '../../../../assets/IGBC/materialandresources/localmaterial.svg';
import certifiedgreenproducts from '../../../../assets/IGBC/materialandresources/certifiedgreenproducts.svg';
import optimizationstructuraldesign from '../../../../assets/IGBC/materialandresources/optimizationstructuraldesign.svg';
import greenprocurementpolicy from '../../../../assets/IGBC/materialandresources/greenprocurementpolicy.svg';
import separartionofhouseholdwaste from '../../../../assets/IGBC/materialandresources/separartionofhouseholdwaste.svg';

//energy
import integratedenergymonitoringsystem from '../../../../assets/IGBC/energy/integratedenergymonitoringsystem.svg';
import hcfcFreeEquipement from '../../../../assets/IGBC/energy/hcfcFreeEquipement.svg';
import minimumenergyperformance from '../../../../assets/IGBC/energy/minimumenergyperformance.svg';
import enhancedenergyperformance from '../../../../assets/IGBC/energy/enhancedenergyperformance.svg';
import alternatewaterheatingsystem from '../../../../assets/IGBC/energy/alternatewaterheatingsystem.svg';
import commonarealighting from '../../../../assets/IGBC/energy/commonarealighting.svg';
import commonareaequipment from '../../../../assets/IGBC/energy/commonareaequipment.svg';

//water
import landscapedesign from '../../../../assets/IGBC/water/landscapedesign.svg';
import watermetering from '../../../../assets/IGBC/water/watermetering.svg';
import waterquality from '../../../../assets/IGBC/water/waterquality.svg';
import Irrigation from '../../../../assets/IGBC/water/irrigation.svg';
import recycleandreuseofwastewater from '../../../../assets/IGBC/water/recycleandreuseofwastewater.svg';
import enhancedrainwaterharvesting from '../../../../assets/IGBC/water/enhancedrainwaterharvesting.svg';
import waterefficiencyplumbing from '../../../../assets/IGBC/water/waterefficiencyplumbing.svg';
import rainwaterharvesting from '../../../../assets/IGBC/water/rainwaterharvesting.svg';

//resident health and well being  residentialhealthandwellbeing
import enhanceddaylight from '../../../../assets/IGBC/residentialhealthandwellbeing/enhanceddaylight.svg';
import enhancedventilation from '../../../../assets/IGBC/residentialhealthandwellbeing/enhancedventilation.svg';
import crossventilation from '../../../../assets/IGBC/residentialhealthandwellbeing/crossventilation.svg';
import connectivitytoexteriors from '../../../../assets/IGBC/residentialhealthandwellbeing/connectivitytoexteriors.svg';
import lowvocpaints from '../../../../assets/IGBC/residentialhealthandwellbeing/lowvocpaints.svg';
import facilityforphysicallwellbeing from '../../../../assets/IGBC/residentialhealthandwellbeing/facilityforphysicallwellbeing.svg';
import minimumdaylighting from '../../../../assets/IGBC/residentialhealthandwellbeing/minimumdaylighting.svg';
import nosmokingpolicy from '../../../../assets/IGBC/residentialhealthandwellbeing/nosmokingpolicy.svg';
import ventilationdesign from '../../../../assets/IGBC/residentialhealthandwellbeing/ventilationdesign.svg';

// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import numberWithCommas from '../../../../utils/numberToComma';
import rsToCrore from '../../../../utils/rsToCr';
import unit from '../../../../utils/unitConfig';

const icons = [
  { name: 'Heat Island Effect: Roof and Non-Roof', icon: heatisland },
  { name: 'Natural Topography and Vegetation', icon: naturaltopographyandvegetation },
  { name: 'Passive Architecture', icon: passivearchitecture },
  { name: 'Universal Design', icon: universaldesign },
  { name: 'Green Parking Facility', icon: greenparkingfacility },
  { name: 'Basic House-hold Amenities', icon: basichouseHoldAmenities },
  { name: 'Basic Facilities for Construction Workforce', icon: basicfacilityconstructionWorkforce },
  { name: 'Green Education and Awareness', icon: greenEducation },
  { name: 'Local Building Regulations', icon: localbuildingreg },
  { name: 'Soil Erosion Control', icon: soilerosion },
  //energy
  { name: 'Integrated Energy Monitoring System', icon: integratedenergymonitoringsystem },
  { name: 'Enhanced Energy Performance', icon: enhancedenergyperformance },
  { name: 'Energy Efficiency in Common Area Equipment', icon: commonareaequipment },
  { name: 'On-site Renewable Energy - Common area Lighting', icon: commonarealighting },
  { name: 'Alternate Water Heating Systems', icon: alternatewaterheatingsystem },
  { name: 'HCFC Free Equipment', icon: hcfcFreeEquipement },
  { name: 'Minimum Energy Performance', icon: minimumenergyperformance },
  //material and resources
  { name: 'Green Procurement Policy', icon: greenprocurementpolicy },
  { name: 'Optimisation on Structural Design', icon: optimizationstructuraldesign },
  { name: 'Certified Green Products', icon: certifiedgreenproducts },
  { name: 'Local Materials', icon: localmaterial },
  { name: 'Eco Friendly Wood Based Materials', icon: organicwastetreecofriendlywoodbasedmaterialatment },
  { name: 'Alternative Construction Materials', icon: alternativeconstructionmaterial },
  { name: 'Handling of Construction and Demolition Waste', icon: constructionanddemolitionwaste },
  { name: 'Organic Waste management, Post Occupancy', icon: organicWaste },
  { name: 'Separation of House-hold Waste', icon: separartionofhouseholdwaste },
  //water
  { name: 'Landscape Design', icon: landscapedesign },
  { name: 'Water Metering', icon: watermetering },
  { name: 'Water Quality', icon: waterquality },
  { name: 'Management of Irrigation Systems', icon: Irrigation },
  { name: 'Recycle & Reuse of Waste Water', icon: recycleandreuseofwastewater },
  { name: 'Water Efficient Plumbing Fixtures', icon: waterefficiencyplumbing },
  { name: 'Water Efficient Plumbing Fixture', icon: waterefficiencyplumbing },
  { name: 'Enhanced Rainwater Harvesting', icon: enhancedrainwaterharvesting },
  { name: 'Rainwater Harvesting', icon: rainwaterharvesting },
  // resident health and well being
  { name: 'Enhanced Daylighting', icon: enhanceddaylight },
  { name: 'Enhanced Ventilation Design', icon: enhancedventilation },
  { name: 'Cross Ventilation', icon: crossventilation },
  { name: 'Connectivity To Exteriors', icon: connectivitytoexteriors },
  { name: 'Low VOC Materials, Paints & Adhesives', icon: lowvocpaints },
  { name: 'Facility for Physical Wellbeing', icon: facilityforphysicallwellbeing },
  { name: 'Minimum Daylighting', icon: minimumdaylighting },
  { name: 'No Smoking Policy', icon: nosmokingpolicy },
  { name: 'Ventilation Design', icon: ventilationdesign },
];

const StyledTableCell = withStyles((theme) => ({
  head: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.571429px',
    textTransform: 'uppercase',
  },
  body: {
    paddingTop: 0,
    paddingBottom: 0,
    whiteSpace: 'nowrap',

    lineHeight: '22px',
    letterSpacing: '0.571429px',
    color: '#000000',
    fontWeight: '600',
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    background: 'rgba(0, 0, 0, 0.03)',
    paddingRight: 1,
    paddingLeft: 1,
  },
}))(TableRow);

const StyledTableSubRow = withStyles((theme) => ({
  root: {
    paddingRight: 1,
    paddingLeft: 1,
  },
}))(TableRow);

const StyledRowCell = withStyles((theme) => ({
  body: {
    paddingTop: 4,
    paddingBottom: 4,

    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '19px',
    letterSpacing: '0.571429px',
    color: 'rgba(0, 0, 0, 0.82)',
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: '100%',
    maxWidth: '100%',
    border: '1px solid rgba(0, 0, 0, 0.14)',
    boxSizing: 'border-box',
    borderRadius: '10px',
  },
  container: {
    width: '100%',
    paddingBottom: '3%',
    paddingTop: '3%',
  },
}));

export default function IGBCCategoriesTable({ igbcCategoryContext, selectedCategoryData }) {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const projectID = params && params.id;
  const handleClick = (id) => history.push(`/projects/${projectID}/certification/credit/${id}`);
  const geticon = (name) => icons.filter((creditname) => creditname.name === name);

  const getDisplayValueForColumn = (data) => {
    return data ? rsToCrore('Rs', data) : '-';
  };

  return (
    <>
      <Grid container className={classes.container} alignItems="center" justifyContent="center">
        <TableContainer component={Paper} className={classes.table}>
          <Table>
            <colgroup>
              <col style={{ width: '33.34%' }} />
            </colgroup>
            <TableHead style={{ background: 'rgba(24, 50, 119, 0.07)' }}>
              <TableRow>
                <StyledTableCell>
                  <Typography variant="body1">{igbcCategoryContext && igbcCategoryContext}</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1">Points</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1">Capex Savings</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1">Opex Savings</Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant="body1">Resource Savings</Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedCategoryData &&
                selectedCategoryData.categorysections.map((data, index) => {
                  return (
                    <>
                      <StyledTableRow style={{ height: '8vh', background: 'rgba(24, 50, 119, 0.11)' }} key={index}>
                        <StyledTableCell component="th" scope="row">
                          {data.sectionname}
                        </StyledTableCell>
                        <StyledTableCell>
                          {data.overall && !data.overall.totalpointsachieved && !data.overall.totalpoints
                            ? '-'
                            : data.overall && data.overall.totalpointsachieved !== undefined && data.overall.totalpoints !== undefined
                            ? `${data.overall.totalpointsachieved}/${data.overall.totalpoints}`
                            : '-'}
                        </StyledTableCell>
                        <StyledTableCell>
                          {data.overall && data.overall.capex ? `${numberWithCommas(getDisplayValueForColumn(data.overall.capex.value))} ${unit('cr')}` : '-'}
                        </StyledTableCell>
                        <StyledTableCell>
                          {data.overall && data.overall.opex ? `${numberWithCommas(getDisplayValueForColumn(data.overall.opex.value))} ${unit('cr')}` : '-'}
                        </StyledTableCell>
                        <StyledTableCell>
                          {data.overall && data.overall.resourcesaving
                            ? `${numberWithCommas(extractData(Math.round(data.overall.resourcesaving.value)))} ${extractData(data.overall.resourcesaving.unit)}`
                            : '-'}
                        </StyledTableCell>
                      </StyledTableRow>

                      {data.credits &&
                        data.credits.map((credit) => {
                          return (
                            <>
                              <StyledTableSubRow style={{ background: 'rgba(24, 50, 119, 0.07)', height: '8vh' }}>
                                <StyledRowCell component="th" scope="row">
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent="flex-start" alignItems="center" container>
                                    <Grid item xs={1}>
                                      {!credit.creditdetails && credit.overall.indicator === true ? (
                                        <CustomizedTooltips
                                          icon="info"
                                          title={<Typography>Mandatory Requirement Not Met</Typography>}
                                          placement="right"
                                          iconColor="red"
                                        />
                                      ) : (
                                        ''
                                      )}
                                    </Grid>
                                    <Grid item xs={2}>
                                      <img src={geticon(credit.name)[0].icon} height="41px" alt="..." style={{ paddingRight: '10px' }} />
                                    </Grid>
                                    <Grid item xs={9} container alignItems="center">
                                      <div style={{ display: 'flex' }}>
                                        <Typography
                                          onClick={() => (credit.creditdetails === true ? handleClick(credit.id) : null)}
                                          style={{
                                            ...(credit.creditdetails === true
                                              ? { textDecoration: 'underline', cursor: 'pointer', paddingBottom: '1%' }
                                              : { cursor: 'pointer', paddingBottom: '1%' }),
                                            display: 'flex',
                                            maxWidth: 250,
                                          }}
                                        >
                                          {credit.name}
                                        </Typography>
                                        <Typography>
                                          <span style={{ padding: '2px 0 0 4px' }}>
                                            {credit && credit.metaData && credit.metaData.tooltip === true ? (
                                              <CustomizedTooltips
                                                icon="info"
                                                title={<Typography>{credit.metaData.tooltipdata}</Typography>}
                                                placement="right"
                                              />
                                            ) : (
                                              ''
                                            )}
                                          </span>
                                        </Typography>
                                      </div>

                                      {/* <Typography
                                        variant="h6"
                                        style={
                                          credit.creditdetails === true
                                            ? { textDecoration: 'underline', cursor: 'pointer', paddingBottom: '1%' }
                                            : { cursor: 'pointer', paddingBottom: '1%' }
                                        }
                                        onClick={() => (credit.creditdetails === true ? handleClick(credit.id) : null)}
                                      >
                                        {credit.name}
                                      </Typography> */}
                                    </Grid>
                                    {/* <Grid item xs={1}>
                                      {credit && credit.metaData && credit.metaData.tooltip === true ? (
                                        <CustomizedTooltips icon="info" title={<Typography>{credit.metaData.tooltipdata}</Typography>} placement="right" />
                                      ) : (
                                        ''
                                      )}
                                    </Grid> */}
                                  </Grid>
                                </StyledRowCell>
                                <StyledRowCell>
                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center" container>
                                    <Typography variant="h6">
                                      {credit.overall && !credit.overall.totalpointsachieved && !credit.overall.totalpoints
                                        ? '-'
                                        : `${credit.overall.totalpointsachieved}/${credit.overall.totalpoints}`}
                                    </Typography>
                                    {credit && credit.pointsMetaData && credit.pointsMetaData.tooltip === true ? (
                                      <CustomizedTooltips icon="info" title={<Typography>{credit.pointsMetaData.tooltipdata}</Typography>} placement="right" />
                                    ) : (
                                      ''
                                    )}
                                  </Grid>
                                </StyledRowCell>
                                <StyledRowCell>
                                  {credit.overall && credit.overall.capex
                                    ? `${numberWithCommas(getDisplayValueForColumn(credit.overall.capex.value))} ${unit('cr')}`
                                    : '-'}
                                </StyledRowCell>
                                <StyledRowCell>
                                  {credit.overall && credit.overall.opex
                                    ? `${numberWithCommas(getDisplayValueForColumn(credit.overall.opex.value))} ${unit('cr')}`
                                    : '-'}
                                </StyledRowCell>
                                <StyledRowCell>
                                  {credit.overall && credit.overall.resourcesaving
                                    ? `${numberWithCommas(extractData(Math.round(credit.overall.resourcesaving.value)))} ${extractData(
                                        credit.overall.resourcesaving.unit
                                      )}`
                                    : '-'}
                                </StyledRowCell>
                              </StyledTableSubRow>
                            </>
                          );
                        })}
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}
