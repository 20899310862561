import Lottie from 'lottie-react';
import LoadingLogo from '../../assets/lottie/LoadingLogo.json';
import { Backdrop } from '@material-ui/core';

const LoadingAnimation = ({ open = true, ...props }) => {
  return (
    <Backdrop open={open} style={{ zIndex: 10000, backgroundColor: 'rgba(0,0,0,0.4)' }} {...props}>
      <Lottie animationData={LoadingLogo} style={{ width: 200 }} loop={true} />
    </Backdrop>
  );
};

export default LoadingAnimation;
