/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import swal from 'sweetalert';
import axios from '../../../../config/Axios';
import PrimaryButton from '../../../common/button/Button';
import HeadAndBackIcon from '../../../common/header/HeadAndBackIcon';
import SimplePIPS from './components/SimplePIPS';
import { SomethingChanged } from '../EditProject';
import ComplexPIPS from './components/ComplexPIPS';
import useAppProjectDetails from '../hooks/useAppProjectDetails';
import useAppProjectPIPMapping from '../hooks/useAppProjectPIPMapping';
import useAppPIPScheme from '../hooks/useAppPIPScheme';
import ApiError from '../../../common/Error/ApiError';

export const CAPEXPIPsForWWB = {
  OrganicComposter: 'project__pi_ubcOrganicWasteComposterCapex',
  Composting: 'project__pi_ubcNaturalCompostingCapex',
};

export const renewableenergy = {
  spv: 'basecasespvcapacity',
  swh: 'project__pi_baseCaseSwhCapacity',
};

export const Dwelling = {
  perimeter: 'project__pi_perimeter',
  wwr: 'project__pi_wwr',
  floors: 'project__pi_numberOfFloors',
  floorheight: 'project__pi_floorToFloorHeight',
};

// make sure This component will be cleaned up into multiple components for multiple edit projects page - refer to wireframe
// create a context for sqm and sqft and make the components separate

const EditProjectsCommonPIP = (props) => {
  let history = useHistory();
  let { id } = useParams();
  let { type, next, name } = props;
  const { somethingChanged } = useContext(SomethingChanged);

  const { data: projectData } = useAppProjectDetails(id);
  const {
    get: { form, projectPIP, includeClubhouse, isError },
    set: { handlePIPValueUpdate },
  } = useAppProjectPIPMapping(id);

  const { data } = useAppPIPScheme(type);
  const basicPIPs =
    projectData?.subcategory === 'villa'
      ? ['project__pi_areaOfLobbiesAndCorridors', 'project__pi_noOfCarParks', 'project__pi_swimmingPoolSurfaceArea']
      : ['project__pi_unavailableRooftopArea', 'project__pi_areaOfLobbiesAndCorridors', 'project__pi_noOfCarParks', 'project__pi_swimmingPoolSurfaceArea'];
  const commonAreaDetailsPIPIds = ['project__pi_availableClubhouseRooftopArea'];
  const renewableEnergyPIPs = ['project__pi_baseCaseSwhCapacity', 'project__pi_baseCaseCapacity'];
  const organicWasteManagementPIPs = ['project__pi_userBaseCase', 'project__pi_ubcOrganicWasteComposterCapex', 'project__pi_ubcNaturalCompostingCapex'];
  const simplePIPs = data && data?.filter((field) => basicPIPs.includes(field.name)).sort((a, b) => basicPIPs.indexOf(a.name) - basicPIPs.indexOf(b.name));
  const villarefatedPIP = data && data?.filter((field) => commonAreaDetailsPIPIds.includes(field.name));
  const renewableEnergyPIP =
    data &&
    data
      ?.filter((field) => renewableEnergyPIPs.includes(field.name))
      ?.sort((a, b) => {
        if (a?.name.includes('project__pi_baseCaseSwhCapacity')) return -1;
        if (b?.name.includes('project__pi_baseCaseCapacity')) return 1;
        return 0;
      });
  const organicWasteManagementPIP = data && data?.filter((field) => organicWasteManagementPIPs.includes(field.name));

  const handleNext = (next) => {
    if (next && next === 'goals') history.push(`/projects/${id}/goals`);
    else {
      if (somethingChanged) {
        props.toogleShowModal(true);
        props.SETNEXT(next);
      } else {
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (props.saveAfterSomethingChanged === true && next) {
        await handleSave(next && next);
        props.toogleShowModal(false);
        props.setFalseAfterSomethingChanged();
        props.SETNEXT('');
      }
    })();
  }, [props.saveAfterSomethingChanged, next]);

  const handleGoBack = (type) => {
    if (type === 'basic') history.push(`/projects/${id}/insights`);
    else history.push(`/projects/${id}/edit?type=basic`);
  };

  const handleSave = async (next) => {
    axios
      .put(`/ProjectPIPMapping`, {
        projectId: id,
        values: Object.entries(form?.pipvalues).map(([key, value]) => ({
          PIPScheme: key,
          value,
        })),
      })
      .then((response) => {
        props.toogleShowModal(false);
        props.setFalseAfterSomethingChanged();
        props.SETNEXT('');
        swal({ icon: 'success', title: 'Data Saved' });
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      });
  };

  if (isError)
    return (
      <div style={{ display: 'flex', alignItems: 'center', paddingTop: 24, width: '100%' }}>
        <ApiError />
      </div>
    );

  return (
    <>
      <Grid item container justifyContent="left" direction="column" xs={12} style={{ marginTop: '3%', marginLeft: '1%', marginBottom: '3%' }}>
        <HeadAndBackIcon
          heading={`${name && name} ${projectData?.name ? projectData.name : ''}`}
          handleClick={() => handleGoBack(type && type)}
          visitGoals={false}
          headingVariant="h5"
          headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
          showArrow={false}
        />
      </Grid>

      {/*will be cleaned up later*/}
      {type && type === 'common' && simplePIPs && projectPIP?.length !== 0
        ? simplePIPs.map((field, index) => {
            return (
              <SimplePIPS
                index={index}
                unit={form?.unitPIPValue}
                field={field && field}
                projectPIP={projectPIP && projectPIP}
                appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                type={type}
              />
            );
          })
        : null}

      {/* villa related pip */}
      {type && type === 'common' && villarefatedPIP && includeClubhouse?.value && projectPIP?.length !== 0
        ? villarefatedPIP.map((field, index) => {
            return (
              <SimplePIPS
                index={index}
                unit={form?.unitPIPValue}
                field={field && field}
                projectPIP={projectPIP && projectPIP}
                appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                type={type}
              />
            );
          })
        : null}

      {type && type === 'common' && organicWasteManagementPIP && projectPIP?.length !== 0
        ? organicWasteManagementPIP.map((field, index) => {
            if (field?.options?.length !== 0) {
              return (
                <ComplexPIPS
                  index={index}
                  field={field && field}
                  projectPIP={projectPIP && projectPIP}
                  appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                  type={type}
                  PIPs={organicWasteManagementPIP && organicWasteManagementPIP}
                  subcategory={projectData?.subcategory && projectData?.subcategory}
                  pipValues={form?.pipvalues}
                />
              );
            }
          })
        : null}

      {/* renewabale */}
      <Grid item container justifyContent="left" direction="column" xs={12} style={{ paddingLeft: '2%' }}>
        <Typography variant="h5" style={{ fontSize: '20px', fontWeight: 'bold' }}>
          Renewable Energy
        </Typography>
      </Grid>

      {type && type === 'common' && renewableEnergyPIP && projectPIP?.length !== 0
        ? renewableEnergyPIP.map((field, index) => {
            return (
              <SimplePIPS
                index={index}
                unit={form?.unitPIPValue}
                field={field && field}
                projectPIP={projectPIP && projectPIP}
                appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
                type={type}
              />
            );
          })
        : null}

      <Grid container direction="row" alignItems="end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '7%' }}>
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleSave(next && next)} children="Save" />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleNext(next && next)} children="next" />
        </Grid>
      </Grid>
    </>
  );
};
export default EditProjectsCommonPIP;
