/* eslint-disable react-hooks/exhaustive-deps */
// React imports
import React, { useState, useEffect } from 'react';

// Material-UI imports
import { Grid, Typography, Paper, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Third-party imports
import ReCAPTCHA from 'react-google-recaptcha';

// Custom components
import useApiRequest from '../../hooks/useApiRequest';
import { FETCHING, SUCCESS, ERROR } from '../../hooks/useApiRequest/actionTypes';
import Loader from '../../common/progressbar/Loader';
import OutlinedTextField from '../../common/textfield/OutlinedTextField';
import CustomButton from '../../common/button/CustomButton';
import { useAppAnalytics } from '../../../hooks/useAppAnalytics';

// Constants
const ROLES = ['Real Estate Developer', 'Architect', 'MEP Consultant', 'Green Consultant', 'Student', 'Individual'];
const ERROR_MESSAGES = {
  SPECIAL_CHARS: 'special characters are not allowed !!',
  INVALID_CHARS: 'Invalid characters !!',
  NETWORK_ERROR: `Sorry, can't do that right now`,
  DROPIN_EXISTS: 'We already have your details & have sent out an email. Please reach out to us at connect@sdplus.io if you need further assistance.',
  WHITE_PAPER_EXISTS: 'Welcome back! White papers are now ready to download.',
  TIMEOUT: 'Facing timeout issues, please retry later',
};

const VALIDATION_MESSAGES = {
  required: 'This field is required',
  email: 'Please enter a valid email address',
  phone: 'Please enter a valid phone number',
  minLength: (field, length) => `${field} must be at least ${length} characters`,
};

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const PHONE_REGEX = /^(?:\+91|91)?[-\s]?[6789]\d{9}$/;

export default function RequestDemo({ purpose }) {
  const classes = useStyles();
  const { sendEvent } = useAppAnalytics();

  // State management
  const [formData, setFormData] = useState({
    email: '',
    fullName: '',
    organisationName: '',
    role: null,
    inputRole: '',
    phone: '',
  });
  const [errors, setErrors] = useState({
    fullName: '',
    email: '',
    phone: '',
    organisationName: '',
    role: '',
  });

  const validateForm = (data) => {
    let isValid = true;
    const newErrors = {
      fullName: '',
      email: '',
      phone: '',
      organisationName: '',
      role: '',
    };

    // Validate Full Name
    if (!data.fullName.trim()) {
      newErrors.fullName = VALIDATION_MESSAGES.required;
      isValid = false;
    } else if (data.fullName.length < 3) {
      newErrors.fullName = VALIDATION_MESSAGES.minLength('Full Name', 3);
      isValid = false;
    }

    // Validate Email
    if (!data.email.trim()) {
      newErrors.email = VALIDATION_MESSAGES.required;
      isValid = false;
    } else if (!EMAIL_REGEX.test(data.email)) {
      newErrors.email = VALIDATION_MESSAGES.email;
      isValid = false;
    }

    // Validate Phone
    if (!data.phone.trim()) {
      newErrors.phone = VALIDATION_MESSAGES.required;
      isValid = false;
    } else if (!PHONE_REGEX.test(data.phone)) {
      newErrors.phone = VALIDATION_MESSAGES.phone;
      isValid = false;
    }

    // Validate Role
    if (!data.role) {
      newErrors.role = VALIDATION_MESSAGES.required;
      isValid = false;
    }

    if (!data.organisationName) {
      newErrors.organisationName = VALIDATION_MESSAGES.required;
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (field) => (event) => {
    const value = event.target.value;

    setFormData((prev) => {
      const updatedFormData = { ...prev, [field]: value };
      validateForm(updatedFormData);
      return updatedFormData;
    });
  };

  const [googleCaptcha, setGoogleCaptcha] = useState(false);

  // API hooks
  const [{ status, response }, makeRequest] = useApiRequest('/dropins', {
    verb: 'post',
    params: {
      email: formData.email,
      fullname: formData.fullName,
      phone: formData.phone,
      organisationname: formData.organisationName,
      role: formData.role,
      purpose,
    },
  });

  useEffect(() => {
    if (status === SUCCESS && response) {
      sessionStorage.setItem('WHITE_PAPER', 'Success');
      resetForm();
    }
  }, [status, response]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm(formData)) {
      makeRequest();
      sendEvent({ eventAction: 'request_demo_signup' });
    }
  };

  const resetForm = () => {
    setFormData({
      email: '',
      fullName: '',
      organisationName: '',
      role: null,
      inputRole: '',
      phone: '',
    });
  };

  // Render methods
  const renderForm = () => (
    <form className={classes.form} noValidate>
      <OutlinedTextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        value={formData.fullName}
        onChange={handleInputChange('fullName')}
        id="fullName"
        label="Full Name"
        name="fullName"
        autoComplete="fullName"
        autoFocus
        error={!!errors.fullName}
        helperText={errors.fullName}
      />
      <OutlinedTextField
        variant="outlined"
        margin="normal"
        required
        value={formData.email}
        onChange={handleInputChange('email')}
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        error={!!errors.email}
        helperText={errors.email}
      />
      <OutlinedTextField
        variant="outlined"
        margin="normal"
        required
        value={formData.phone}
        onChange={handleInputChange('phone')}
        fullWidth
        id="phoneno"
        label="Phone No"
        name="Phone No"
        autoComplete="phoneno"
        error={!!errors.phone}
        helperText={errors.phone}
      />
      <OutlinedTextField
        variant="outlined"
        margin="normal"
        value={formData.organisationName}
        onChange={handleInputChange('organisationName')}
        fullWidth
        id="organisation"
        label="Organisation"
        name="organisation"
        autoComplete="organisation"
        error={!!errors.organisationName}
        helperText={errors.organisationName}
        required
      />
      <OutlinedTextField
        select
        variant="outlined"
        margin="normal"
        value={formData.role}
        onChange={handleInputChange('role')}
        fullWidth
        id="role"
        label="Role"
        name="role"
        autoComplete="role"
        error={!!errors.role}
        helperText={errors.role}
        required
      >
        {ROLES.map((role) => (
          <MenuItem key={role} value={role}>
            {role}
          </MenuItem>
        ))}
      </OutlinedTextField>

      <Typography variant="h6" style={{ textAlign: 'left', marginTop: '10px' }}>
        *All fields are mandatory
      </Typography>

      <Grid container item xs={12} style={{ marginTop: '10px', textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
        {!googleCaptcha && <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={() => setGoogleCaptcha(true)} />}
      </Grid>

      {status !== FETCHING && googleCaptcha && (
        <CustomButton type="submit" onClick={handleSubmit} fullWidth variant="contained" className={classes.submit}>
          SIGNUP
        </CustomButton>
      )}
      {status === FETCHING && (
        <Grid container direction="column" alignItems="center" item xs={12}>
          <Grid item xs={12} className={classes.submit}>
            <Loader />
          </Grid>
        </Grid>
      )}
    </form>
  );

  const renderResponse = () => {
    if (status === SUCCESS) {
      return (
        <Grid container spacing={1} item xs={12}>
          <Grid item xs={12}>
            <Paper className={classes.response1}>
              <Typography variant="body1">Thank you! We are sending across an introductory email to you.</Typography>
            </Paper>
          </Grid>
        </Grid>
      );
    }

    if (status === ERROR) {
      return (
        <Grid container spacing={1} item xs={12}>
          <Grid item xs={12}>
            <Paper className={classes.response2}>
              <Typography variant="body1">{getErrorMessage(response, purpose)}</Typography>
            </Paper>
          </Grid>
        </Grid>
      );
    }

    return null;
  };

  const getErrorMessage = (response, purpose) => {
    if (response && response.toString().includes('Network Error')) {
      return ERROR_MESSAGES.NETWORK_ERROR;
    }
    if (response && response.toString().includes('404')) {
      return ERROR_MESSAGES.NETWORK_ERROR;
    }
    if (response && response.toString().includes('400') && purpose === 'Dropin') {
      return ERROR_MESSAGES.DROPIN_EXISTS;
    }
    if (response && response.toString().includes('400') && purpose === 'White Paper') {
      return ERROR_MESSAGES.WHITE_PAPER_EXISTS;
    }
    if (response && response.toString().includes('408')) {
      return ERROR_MESSAGES.TIMEOUT;
    }
    return ERROR_MESSAGES.NETWORK_ERROR;
  };

  return (
    <>
      {renderForm()}
      {renderResponse()}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  response1: {
    marginTop: '3%',
    padding: '3% 3%',
    background: '#C6F2CD',
    border: '1px solid #49DDB1',
    boxSizing: 'border-box',
    borderRadius: '6px',
    color: '#666666',
    textAlign: 'center',
  },
  response2: {
    marginTop: '3%',
    padding: '3% 3%',
    color: theme.palette.warning.main,
    backgroundColor: '#FFF5E2',
    border: '1px solid rgba(255, 167, 86, 0.5)',
    boxSizing: 'border-box',
    borderRadius: '6px',
    textAlign: 'center',
  },
}));
