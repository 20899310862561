import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import SearchBar from '../../../common/Searchbar/Search';
import useApiRequest from '../../../hooks/useApiRequest';
import { makeStyles } from '@material-ui/core/styles';
import axios from '../../../../config/Axios';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import swal from 'sweetalert';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import UpdateIcon from '@material-ui/icons/Update';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CustomInput from '../../../common/textfield/CustomInput';
import CustomLink from '../../../common/link/CustomLink';
import PrimaryButton from '../../../common/button/Button';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: '2%',
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  spacing: {
    paddingTop: '2vh',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    width: 800,
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
    borderRadius: '15px',
  },
}));

const Goals = () => {
  const classes = useStyles();
  const [categories, setCategories] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [goals, setGoals] = useState('');
  const [searchGoal, setsearchGoals] = useState('');

  const [goalName, setGoalName] = useState('');
  const [goalCategory, setGoalCategory] = useState('');
  const [selectedGoalID, setSelectedGoalID] = useState('');
  const [goalDescription, setGoalDescription] = useState('');

  const handleCreateGoal = () => {
    let body = { name: goalName, description: goalDescription, category: selectedGoalID };
    axios
      .post(`/goalsschemev2`, body)
      .then(() => {
        swal({ icon: 'success', title: 'Successfully Created goal' });
        makeRequest();
      })
      .catch((err) => console.log(err, 'err'));
  };

  const [{ status, response }, makeRequest] = useApiRequest('/goalsschemev2', {
    verb: 'get',
  });
  if (status === null) makeRequest();

  useEffect(() => {
    if (response) {
      setGoals(response.data.data);
    }
  }, [response]);

  useEffect(() => {
    if (goalCategory && categories) {
      let goalData = categories.data.find((d) => d.name === goalCategory);
      setSelectedGoalID(goalData && goalData._id);
    }
  }, [goalCategory, categories]);

  useEffect(() => {
    axios.get('/categorySchemeV2').then((response) => {
      setCategories(response.data.data);
    });
  }, []);

  const handleTextField = (e) => {
    setSearchValue(e.target.value);
    const newData = goals && goals.data.filter((category) => category.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1);
    setsearchGoals(newData);
  };

  const [on, setOn] = useState(false);
  const [selectedID, setSelectedID] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedDescription, setSelectedDescription] = useState('');

  const [selectedCategoryID, setSelectedCategoryID] = useState('');

  const [updatedName, setUpdatedName] = useState(null);
  const [updatedCategory, setUpdatedCategory] = useState(null);
  const [updatedDescription, setUpdatedDescription] = useState(null);

  const handleClose = () => setOn(false);
  const handleOpen = (data) => {
    setSelectedID(data._id);
    setSelectedName(data.name);
    setSelectedCategory(data.category.name);
    setSelectedCategoryID(data.category._id);
    setSelectedDescription(data.description);
    setOn(true);
  };

  const [updateCategoryID, setUpdateCategoryID] = useState('');

  useEffect(() => {
    if (updatedCategory && categories) {
      let D = categories.data.find((c) => c.name === updatedCategory);
      setUpdateCategoryID(D && D._id);
    }
  }, [updatedCategory, categories]);

  const handleUpdateGoal = () => {
    let body = {
      name: updatedName !== null ? updatedName : selectedName,
      category: updatedCategory !== null ? updateCategoryID : selectedCategoryID,
      description: updatedDescription !== null ? updatedDescription : selectedDescription,
    };
    axios
      .put(`/goalsschemev2/update/${selectedID}`, body)
      .then(() => {
        swal({ icon: 'success', title: 'Successfully Updated goal' });
        makeRequest();
        setOn(false);
      })
      .catch((err) => console.log(err, 'err'));
  };

  return (
    <>
      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        <Grid item xs={6} align="left">
          <Typography variant="h3">Goals Management</Typography>
        </Grid>
        <Grid item xs={3}>
          <SearchBar onChange={handleTextField} value={searchValue} placeholder="Search by Goal name" />
        </Grid>
        <Grid item xs={1} />
      </Grid>

      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '3%' }}>
        <Grid item xs={6} align="left">
          <form className={classes.form} noValidate autoComplete="off">
            <TextField id="standard-basic" label="Goal Name" onChange={(e) => setGoalName(e.target.value)} value={goalName} />
            <TextField
              id="standard-multiline-flexible"
              label="Goal Description"
              multiline
              rowsMax={4}
              value={goalDescription}
              onChange={(e) => setGoalDescription(e.target.value)}
            />
          </form>
        </Grid>
        <Grid item xs={2} align="left">
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">category</InputLabel>

            <Select
              placeholder="category"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={goalCategory}
              onChange={(e) => setGoalCategory(e.target.value)}
            >
              {categories &&
                categories.data.map((data) => {
                  return <MenuItem value={data.name}>{data.name}</MenuItem>;
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3} align="left">
          <Button variant="contained" color="primary" onClick={handleCreateGoal}>
            Add
          </Button>
        </Grid>
        <Grid item xs />
      </Grid>

      <Grid container alignItems="center" item xs={12} style={{ marginBottom: '5%' }}>
        {searchValue && searchGoal
          ? searchGoal.map((goal, index) => {
              return (
                <div className={classes.root}>
                  <Accordion>
                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                      <Grid container spacing={0} alignItems="center">
                        <Grid item xs={2}>
                          <Typography className={classes.heading}>
                            <b>Goal</b>
                          </Typography>
                          <br />
                          <Typography className={classes.secondaryHeading}>{goal.name}</Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography className={classes.heading}>
                            <b>Description</b>
                          </Typography>
                          <br />
                          <Typography className={classes.secondaryHeading}> {goal.description}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography className={classes.heading}>
                            <b>category</b>
                          </Typography>
                          <br />
                          <Typography className={classes.secondaryHeading}> {goal.category ? goal.category.name.toUpperCase() : 'NA'}</Typography>
                        </Grid>
                        <Grid item xs={1} align="center">
                          <Typography className={classes.heading2}>
                            <b>Update</b>
                          </Typography>
                          <br />
                          <UpdateIcon onClick={() => handleOpen(goal)} />
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                  </Accordion>
                </div>
              );
            })
          : goals &&
            goals.data.map((goal, index) => {
              return (
                <div className={classes.root}>
                  <Accordion>
                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
                      <Grid container spacing={0} alignItems="center">
                        <Grid item xs={2}>
                          <Typography className={classes.heading}>
                            <b>Goal</b>
                          </Typography>
                          <br />
                          <Typography className={classes.secondaryHeading}>{goal.name}</Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography className={classes.heading}>
                            <b>Description</b>
                          </Typography>
                          <br />
                          <Typography className={classes.secondaryHeading}> {goal.description}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography className={classes.heading}>
                            <b>category</b>
                          </Typography>
                          <br />
                          <Typography className={classes.secondaryHeading}> {goal.category ? goal.category.name.toUpperCase() : 'NA'}</Typography>
                        </Grid>
                        <Grid item xs={1} align="center">
                          <Typography className={classes.heading2}>
                            <b>Update</b>
                          </Typography>
                          <br />
                          <UpdateIcon onClick={() => handleOpen(goal)} />
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                  </Accordion>
                </div>
              );
            })}
      </Grid>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={on}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={on}>
          <div className={classes.paper}>
            <Typography gutterBottom variant="h5" component="h2">
              Update :{selectedName && selectedName}
            </Typography>
            <br />
            <form className={classes.formModal} noValidate>
              <Grid container alignItems="center" item xs={12} className={classes.container}>
                <Grid item xs={3} style={{ marginTop: '3%' }}>
                  <Grid direction="column">
                    <CustomInput
                      margin="normal"
                      required
                      value={updatedName === null ? selectedName : updatedName}
                      onChange={(e) => setUpdatedName(e.target.value)}
                      placeholder="Name"
                      fullWidth
                      id="standard-multiline-flexible"
                      label="Update name"
                      name="name"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={3} style={{ marginTop: '3%', marginLeft: '10%' }}>
                  <Grid></Grid>
                  <Grid direction="column">
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">Select Category</InputLabel>
                      <Select
                        placeholder="select Category"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={updatedCategory === null ? selectedCategory : updatedCategory}
                        onChange={(e) => setUpdatedCategory(e.target.value)}
                      >
                        {categories &&
                          categories.data.map((data) => {
                            return <MenuItem value={data.name}>{data.name}</MenuItem>;
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid item xs={3} style={{ marginTop: '3%', marginLeft: '10%' }}>
                  <Grid></Grid>
                  <Grid direction="column">
                    <CustomInput
                      margin="normal"
                      required
                      value={updatedDescription === null ? selectedDescription : updatedDescription}
                      onChange={(e) => setUpdatedDescription(e.target.value)}
                      placeholder="description"
                      fullWidth
                      id="standard-multiline-flexible"
                      label="Update Description"
                      name="description"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>
            <Grid container item xs={12} style={{ marginTop: '10%' }}>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <Grid container item xs={12}>
                  <Grid item xs={6}>
                    <CustomLink activeOnlyWhenExact={true} onClick={handleClose} label={<Typography variant="h5">CANCEL </Typography>} />
                  </Grid>
                  <Grid item xs={6}>
                    <PrimaryButton children="UPDATE" onClick={handleUpdateGoal} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
export default Goals;
