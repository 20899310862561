import { api } from './http';

const getProjectPIPScheme = async (params) => {
  const response = await api.get(`/v1/pipschemev2`, { params });
  return response;
};

const getProjectPIPMapping = async (params) => {
  const response = await api.get(`/v1/ProjectPIPMapping/get`, { params });
  return response;
};

export { getProjectPIPScheme, getProjectPIPMapping };
