/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, InputLabel, FormControl, InputAdornment, Link, Paper } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import { validatePayload } from '../v2/utils';
import CustomButton from '../components/common/button/CustomButton';
import CustomIconButton from '../components/common/button/CustomIconButton';
import CustomOutlinedInput from '../components/common/textfield/CustomOutlinedInput';
import InquiryNavBar from '../components/common/header/InquiryNavBar';
import api from '../http';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: 826,
    margin: `10% auto`,
    padding: theme.spacing(2),
  },
  title: {
    fontStyle: 'normal',
    fontWeight: '900',
    fontSize: '24px',
    lineHeight: '33px',
    letterSpacing: '1.42857px',
    color: '#000000',
  },
  desc: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '33px',
    letterSpacing: '1.42857px',
    color: 'rgba(0, 0, 0, 0.82)',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '33px',
    letterSpacing: '1.42857px',
    color: 'rgba(0, 0, 0, 0.82)',
  },
  spacing: {
    marginLeft: '10%',
  },
  spacing2: {
    marginTop: '3%',
  },
}));

function ResetPassword() {
  const classes = useStyles();
  const location = useLocation();

  const [payload, setPayload] = useState(null);
  const [token, setToken] = useState(null);

  const [payloadError, setPayloadError] = useState(false);

  const getPayloadFromUrl = () => {
    try {
      const token = new URLSearchParams(location.search).get('payload');
      const res = validatePayload(token);
      setToken(token);
      setPayload(res);
    } catch (error) {
      setPayloadError(true);
      return;
    }
  };

  useEffect(() => getPayloadFromUrl(), [location]);

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
    showPassword: false,
  });
  const [formError, setFormErrors] = useState({
    name: '',
    password: '',
  });
  const [success, setSuccess] = useState(false);

  const onFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    if (e.target.name === 'password') validatePassword(e.target.value);
    if (e.target.name === 'confirmPassword') validateConfirmPassword(e.target.value);
  };

  const updateFormErrors = (field, error) => setFormErrors({ ...formError, [field]: error });

  const validatePassword = (password) => {
    if (!password) {
      updateFormErrors('password', 'Please enter a valid password');
      return false;
    }

    if (password !== formData.confirmPassword) {
      updateFormErrors('password', 'Passwords do not match');
      return false;
    }

    updateFormErrors('password', '');
    return true;
  };

  const validateConfirmPassword = (confirmPassword) => {
    if (!confirmPassword || confirmPassword !== formData.password) {
      updateFormErrors('password', 'Password does not match');
      return false;
    }

    updateFormErrors('password', '');
    return true;
  };

  const handleClick = async () => {
    let isValid = true;

    isValid = validatePassword(formData.password);
    isValid = validateConfirmPassword(formData.confirmPassword);
    if (!isValid) return;

    try {
      await api.postResetPassword(token, formData.password, payload.email);
      setSuccess(true);
    } catch (error) {
      console.error('Error creating account:', error);
    }
  };

  return (
    <>
      <InquiryNavBar />
      <Paper className={classes.paper} elevation={0}>
        <Grid container direction="column" wrap="nowrap" spacing={3}>
          <Grid container justifyContent="center" alignItems="center" item>
            <Typography className={classes.title}>Reset Password</Typography>
          </Grid>

          <Grid container direction="column" item xs={12} className={classes.spacing}>
            <Typography className={classes.desc}>
              Please enter your new password below to reset password for <span>{payload && payload.email}</span>
            </Typography>
            <Grid item xs={5} style={{ paddingBottom: '3%' }}>
              <Typography className={classes.text}>New Password</Typography>
              <FormControl margin="normal" fullWidth variant="outlined" required>
                <InputLabel htmlFor="outlined-adornment-password">Type Here</InputLabel>

                <CustomOutlinedInput
                  id="outlined-adornment-password"
                  error={formError.password}
                  value={formData.password}
                  onChange={onFormChange}
                  style={{ borderRadius: '40px' }}
                  name="password"
                  type={formData.showPassword ? 'text' : 'password'}
                  autoComplete="current-password"
                  endAdornment={
                    <InputAdornment position="end">
                      <CustomIconButton
                        onClick={() => {
                          setFormData({ ...formData, showPassword: !formData.showPassword });
                        }}
                        edge="end"
                      >
                        <Typography variant="h6" color={formData.showPassword ? 'textSecondary' : 'primary'}>
                          Show
                        </Typography>
                      </CustomIconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
            </Grid>

            <Grid item xs={5}>
              <Typography className={classes.text}>Confirm Password</Typography>
              <FormControl margin="normal" fullWidth variant="outlined" required>
                <InputLabel htmlFor="outlined-adornment-password">Type Here</InputLabel>
                <CustomOutlinedInput
                  id="outlined-adornment-password"
                  error={formError.password}
                  value={formData.confirmPassword}
                  onChange={onFormChange}
                  style={{ borderRadius: '40px' }}
                  name="confirmPassword"
                  type="password"
                  autoComplete="current-password"
                  labelWidth={70}
                />
              </FormControl>
            </Grid>

            <Typography className={classes.desc} style={{ color: 'red' }}>
              {formError.password}
            </Typography>

            <Typography className={classes.desc} style={{ color: '#26E0A3' }}>
              {success ? 'Password reset successfully!' : ''}
              <span>
                <Link href="/">Click Here to Login</Link>
              </span>{' '}
            </Typography>

            {payloadError && (
              <Typography className={classes.desc} style={{ color: 'red' }}>
                Link has expired.{' '}
                <span>
                  <Link href="/forgot-password">Resend link</Link>
                </span>{' '}
              </Typography>
            )}

            <Grid item xs={3} className={classes.spacing2}>
              <CustomButton disabled={success || payloadError} type="submit" variant="contained" onClick={handleClick} style={{ borderRadius: '40px' }}>
                confirm
              </CustomButton>
            </Grid>
            <Typography className={classes.text} style={{ paddingTop: '10%' }}>
              Contact
              <span>
                <Link style={{ color: 'blue' }}> connect@sdplus.io</Link>
              </span>{' '}
              if your issue is not resolved
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default ResetPassword;
