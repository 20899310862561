import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import WaterCard from '../../views/marketing/water/Card';
import CustomLink from '../link/CustomLink';

const useStyles = makeStyles((theme) => ({
  buttongroup: {
    paddingTop: '20px',
  },
  line: {
    paddingTop: '10px',
    paddingBottom: '0%',
  },
  textcolor: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  spacing: {
    paddingBottom: '4%',
  },
}));

function AdminProjectBar(props) {
  const classes = useStyles();
  return (
    <>
      <WaterCard
        body={
          <>
            <Grid container alignItems="center" item xs={12}>
              <Typography variant="body2" className={classes.spacing}>
                Welcome
              </Typography>
              <Typography variant="h6" className={classes.textcolor}>
                Admin Panel
              </Typography>
            </Grid>
            <CustomLink to="/projects" activeOnlyWhenExact={true} label={<Typography variant="h5">Exit</Typography>} />
            <></>
            {/* <img src={sidebarline} alt="..." className={classes.line} style={{ width: '100%' }} /> */}
          </>
        }
        style={{ background: 'transparent', border: 'none', height: '90%' }}
      />
    </>
  );
}

export default AdminProjectBar;
