import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import SpericalWidgetCard from '../common/spericalWidgetCard';
import RightOverview from './RightOverview';

const useStyles = makeStyles((theme) => ({
  overviewgrid1: {
    marginTop: '2%',
  },
}));

function InterventionOverview(props) {
  const classes = useStyles();
  return (
    <>
      <Grid container alignItems="flex-start" direction="row" xs={12} className={classes.overviewgrid1}>
        <Grid xs>
          <Typography variant="h5">
            <span className="intervention-details-overview">{props.title}</span>
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="flex-start" direction="row" xs={12}>
        <Grid item xs={6}>
          <RightOverview color={props.color} />
        </Grid>
        {/* <Grid item xs={1} /> */}
        <Grid item xs={6}>
          <SpericalWidgetCard textcolor={props.textcolor} circlecolor={props.circlecolor} heading="Contribution to Total Savings" />
        </Grid>
      </Grid>
    </>
  );
}

export default InterventionOverview;
