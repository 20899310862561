import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from '../styles';
import PrimaryButton from '../../../../../common/button/Button';

const NoInterventionPage = ({ handleOpen }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container alignItems="center" className={classes.root}>
        <Grid item xs={12} className={classes.paddingCenter}>
          <Typography className={classes.textSmall}>
            In this section you can view and compare multiple intervention parameter sets and their associated impacts. It will help you assess and select the
            best option based on your own success criteria.
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.paddingCenterWithBorder}>
          <ul>
            <li className={classes.textSmall}>
              Configure your intervention by adding products and adjusting capacities, then click <span style={{ fontWeight: 'bold' }}>“Save Current Set”</span>
              to save the intervention parameter set.
            </li>
            <li className={classes.textSmall}>
              Click <span style={{ fontWeight: 'bold' }}>"View All Sets"</span> to evaluate your saved intervention parameter sets and compare them against each
              other.
            </li>
          </ul>
        </Grid>
        <PrimaryButton children="Add Your Current Configuration as a New Set" className={classes.buttonLarge} onClick={() => handleOpen()} />
        <Grid item xs={12} className={classes.paddingCenter}>
          <Typography className={classes.textSmall}>
            Changes to your intervention are automatically saved, so you don’t need to save an intervention set after every adjustment. Save an intervention set
            only if you want to revisit that specific configuration later.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default NoInterventionPage;
