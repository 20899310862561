const putUploadToS3 = async (url, selectedFile) => {
  if (!url) {
    console.error('Upload URL not present');
    return false;
  }
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': selectedFile.type,
      },
      body: selectedFile,
    });

    if (!response.ok) {
      console.error('Error during upload:', response.statusText);
      return false;
    }
    return response;
  } catch (err) {
    console.error('Error during upload:', err);
    return false;
  }
};

export { putUploadToS3 };
