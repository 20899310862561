import rsToCr from '../../../../../utils/rsToCr';
import numberWithCommas from '../../../../../utils/numberToComma';
import { capitalizeFirstLetterOfEachWord, getCapacityUnitDynamically, getIcon, percentChange } from './ReportUtils';
import { MetafireInterventions } from '../../../../../metafire/utils/types';

export const categoryWiseInterventionSummary = (categorisedReportData, category) => {
  const Energy = categorisedReportData.map((interv) => {
    if (interv.interventionKey.includes('hwm')) {
      return {
        intervention: MetafireInterventions[interv.interventionKey],
        icon: getIcon(interv.interventionKey),
        data: [
          {
            name: 'System',
            before: '-',
            after: capitalizeFirstLetterOfEachWord(interv?.option),
            Improvement: interv?.capacityPercentChange ? Math.round(interv?.capacityPercentChange) : '-',
            unit: '',
          },
          {
            name: 'Capacity',
            before: numberWithCommas(Math.round(interv?.capacityPreSdplus)),
            after: typeof interv?.capacity !== 'object' ? numberWithCommas(Math.round(interv?.capacity)) : interv?.capacity,
            Improvement:
              typeof interv?.capacity !== 'object'
                ? interv?.capacityPercentChange
                  ? Math.round(interv?.capacityPercentChange)
                  : '-'
                : { hp: percentChange(interv?.capacity?.hp, interv.capacityPreSdplus), swh: percentChange(interv?.capacity?.swh, interv.capacityPreSdplus) },
            unit: getCapacityUnitDynamically(interv.interventionKey),
          },
          {
            name: 'CAPEX',
            before: rsToCr('Rs', interv.capexPreSdplus),
            after: rsToCr('Rs', interv.capexPostSdplus),
            Improvement: interv?.capexPercentChange ? Math.round(interv?.capexPercentChange) : '-',
            unit: 'Cr',
          },
          {
            name: 'Resource Savings',
            before: numberWithCommas(Math.round(interv.resourceSavingsPreSdPlus)),
            after: numberWithCommas(Math.round(interv.resourceSavings)),
            Improvement: interv?.resourceSavingsPercentChange ? Math.round(interv?.resourceSavingsPercentChange) : '-',
            unit: 'kWh',
          },
        ],
      };
    }
    return {
      intervention: MetafireInterventions[interv.interventionKey],
      icon: getIcon(interv.interventionKey),
      data: [
        {
          name: 'Capacity',
          before: numberWithCommas(Math.round(interv?.capacityPreSdplus)),
          after: numberWithCommas(Math.round(interv?.capacity)),
          Improvement: interv?.capacityPercentChange ? Math.round(interv?.capacityPercentChange) : '-',
          unit: getCapacityUnitDynamically(interv.interventionKey),
        },
        {
          name: 'CAPEX',
          before: rsToCr('Rs', interv.capexPreSdplus),
          after: rsToCr('Rs', interv.capexPostSdplus),
          Improvement: interv?.capexPercentChange ? Math.round(interv?.capexPercentChange) : '-',
          unit: 'Cr',
        },
        {
          name: 'Resource Savings',
          before: numberWithCommas(Math.round(interv.resourceSavingsPreSdPlus)),
          after: numberWithCommas(Math.round(interv.resourceSavings)),
          Improvement: interv?.resourceSavingsPercentChange ? Math.round(interv?.resourceSavingsPercentChange) : '-',
          unit: 'kWh',
        },
      ],
    };
  });
  const Materials = categorisedReportData.map((interv) => {
    if (interv.interventionKey === 'sc') {
      return {
        intervention: MetafireInterventions[interv.interventionKey],
        icon: getIcon(interv.interventionKey),
        data: [
          {
            name: 'Cement Type',
            before: interv.cementTypePreSdplus,
            after: interv.cementType,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Product Name',
            before: interv.productNamePreSdplus,
            after: interv.productName,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Brand',
            before: interv.brandPreSdplus,
            after: interv.brand,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'CAPEX',
            before: rsToCr('Rs', interv.capexPreSdplus),
            after: rsToCr('Rs', interv.capexPostSdplus),
            Improvement: interv?.capexPercentChange ? Math.round(interv?.capexPercentChange) : '-',
            unit: 'Cr',
          },
          {
            name: 'Resource Savings',
            before: numberWithCommas(Math.round(interv.resourceSavingsPreSdPlus)),
            after: numberWithCommas(Math.round(interv.resourceSavings)),
            Improvement: interv?.resourceSavingsPercentChange ? Math.round(interv?.resourceSavingsPercentChange) : '-',
            unit: 'tCO2e',
          },
        ],
      };
    }
    if (interv.interventionKey === 'structuralSteel') {
      return {
        intervention: MetafireInterventions[interv.interventionKey],
        icon: getIcon(interv.interventionKey),
        data: [
          {
            name: 'Steel Type',
            before: interv.typeOfSteelPreSdplus,
            after: interv.typeOfSteel,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Product Name',
            before: interv.productNamePreSdplus,
            after: interv.productName,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Brand',
            before: interv.brandPreSdplus,
            after: interv.brand,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'CAPEX',
            before: rsToCr('Rs', interv.capexPreSdplus),
            after: rsToCr('Rs', interv.capexPostSdplus),
            Improvement: interv?.capexPercentChange ? Math.round(interv?.capexPercentChange) : '-',
            unit: 'Cr',
          },
          {
            name: 'Resource Savings',
            before: numberWithCommas(Math.round(interv.resourceSavingsPreSdPlus)),
            after: numberWithCommas(Math.round(interv.resourceSavings)),
            Improvement: interv?.resourceSavingsPercentChange ? Math.round(interv?.resourceSavingsPercentChange) : '-',
            unit: 'tCO2e',
          },
        ],
      };
    }
    if (
      interv.interventionKey === 'indoorFlooringBedroom' ||
      interv.interventionKey === 'indoorFlooringKitchen' ||
      interv.interventionKey === 'indoorFlooringBathroom' ||
      interv.interventionKey === 'indoorFlooringLnD' ||
      interv.interventionKey === 'indoorFlooringUnB'
    ) {
      return {
        intervention: MetafireInterventions[interv.interventionKey],
        icon: getIcon(interv.interventionKey),
        data: [
          {
            name: 'Flooring Type',
            before: interv.flooringTypePreSdplus,
            after: interv.flooringType,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Product Name',
            before: interv.productNamePreSdplus,
            after: interv.productName,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Brand',
            before: interv.brandPreSdplus,
            after: interv.brand,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'CAPEX',
            before: rsToCr('Rs', interv.capexPreSdplus),
            after: rsToCr('Rs', interv.capexPostSdplus),
            Improvement: interv?.capexPercentChange ? Math.round(interv?.capexPercentChange) : '-',
            unit: 'Cr',
          },
          {
            name: 'Resource Savings',
            before: numberWithCommas(Math.round(interv.resourceSavingsPreSdPlus)),
            after: numberWithCommas(Math.round(interv.resourceSavings)),
            Improvement: interv?.resourceSavingsPercentChange ? Math.round(interv?.resourceSavingsPercentChange) : '-',
            unit: 'tCO2e',
          },
        ],
      };
    }
    if (
      interv.interventionKey === 'exteriorPaintsSunny' ||
      interv.interventionKey === 'exteriorPaintsNonSunny' ||
      interv.interventionKey === 'interiorPaints'
    ) {
      return {
        intervention: MetafireInterventions[interv.interventionKey],
        icon: getIcon(interv.interventionKey),
        data: [
          {
            name: 'Product Name',
            before: interv.productNamePreSdplus,
            after: interv.productName,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Finish',
            before: interv.finishPreSdplus,
            after: interv.finish,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Brand',
            before: interv.brandPreSdplus,
            after: interv.brand,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'CAPEX',
            before: rsToCr('Rs', interv.capexPreSdplus),
            after: rsToCr('Rs', interv.capexPostSdplus),
            Improvement: interv?.capexPercentChange ? Math.round(interv?.capexPercentChange) : '-',
            unit: 'Cr',
          },
          {
            name: 'VOC Level',
            before: interv.vocLevelPreSdplus,
            after: interv.vocLevel,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Resource Savings',
            before: numberWithCommas(Math.round(interv.resourceSavingsPreSdPlus)),
            after: numberWithCommas(Math.round(interv.resourceSavings)),
            Improvement: interv?.resourceSavingsPercentChange ? Math.round(interv?.resourceSavingsPercentChange) : '-',
            unit: 'tCO2e',
          },
        ],
      };
    }
    if (interv.interventionKey === 'wallBlockExterior' || interv.interventionKey === 'wallBlockInterior') {
      return {
        intervention: MetafireInterventions[interv.interventionKey],
        icon: getIcon(interv.interventionKey),
        data: [
          {
            name: 'Type of Wall Block',
            before: interv.typeofWallBlockPreSdplus,
            after: interv.typeofWallBlockPostSdplus,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Fixing / Mortar Agent',
            before: interv.fixingMortarPreSdplus,
            after: interv.fixingMortarPostSD,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Product Name',
            before: interv.productNamePreSdplus,
            after: interv.productName,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Brand',
            before: '-',
            after: interv.brand,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Blocks Quantity',
            before: Math.round(interv.blockQuantityPreSdplus),
            after: Math.round(interv.blockQuantityPostSD),
            Improvement:
              interv?.blockQuantityPreSdplus && interv?.blockQuantityPostSD
                ? Math.round(((interv.blockQuantityPostSD - interv.blockQuantityPreSdplus) / interv.blockQuantityPreSdplus) * 100)
                : '-',
            unit: 'nos',
          },
          {
            name: 'CAPEX',
            before: rsToCr('Rs', interv.capexPreSdplus),
            after: rsToCr('Rs', interv.capexPostSdplus),
            Improvement: interv?.capexPercentChange ? Math.round(interv?.capexPercentChange) : '-',
            unit: 'Cr',
          },
          {
            name: 'Embodied Carbon Savings',
            before: numberWithCommas(Math.round(interv.embodiedCarbonSavingsPreSdPlus)),
            after: numberWithCommas(Math.round(interv.embodiedCarbonSavingsPostSdPlus)),
            Improvement: interv?.embodiedCarbonPercentage ? Math.round(interv?.embodiedCarbonPercentage) : '-',
            unit: 'tCO2e',
          },
        ],
      };
    }
    if (interv.interventionKey === 'glazingDgu' || interv.interventionKey === 'glazingSgu') {
      return {
        intervention: MetafireInterventions[interv.interventionKey],
        icon: getIcon(interv.interventionKey),
        data: [
          {
            name: 'Product Name',
            before: interv.productNamePreSdplus,
            after: interv.productName,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Brand',
            before: '-',
            after: interv.brand,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Glazing Quantity',
            before: interv.capacityPreSdPlus,
            after: interv.capacityPostSdPlus,
            Improvement: '-',
            unit: 'sqm',
          },
          {
            name: 'CAPEX',
            before: rsToCr('Rs', interv.capexPreSdplus),
            after: rsToCr('Rs', interv.capexPostSdplus),
            Improvement: interv?.capexPercentChange ? Math.round(interv?.capexPercentChange) : '-',
            unit: 'Cr',
          },
          {
            name: 'Embodied Carbon Savings',
            before: numberWithCommas(Math.round(interv.resourceSavingsPreSdPlus)),
            after: numberWithCommas(Math.round(interv.resourceSavings)),
            Improvement: interv?.resourceSavingsPercentChange ? Math.round(interv?.resourceSavingsPercentChange) : '-',
            unit: 'tCO2e',
          },
          {
            name: 'Operational Carbon Savings',
            before: numberWithCommas(Math.round(interv.operationalCarbonPreSdPlus)),
            after: numberWithCommas(Math.round(interv.operationalCarbonPostSdPlus)),
            Improvement: interv?.operationalCarbonPercentage ? Math.round(interv?.operationalCarbonPercentage) : '-',
            unit: 'tCO2e',
          },
        ],
      };
    }
    if (interv.interventionKey === 'coolRoof') {
      return {
        intervention: MetafireInterventions[interv.interventionKey],
        icon: getIcon(interv.interventionKey),
        data: [
          {
            name: 'Surface Layer',
            before: '-',
            after: interv.surfaceLayer,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Sub Surface Layer',
            before: '-',
            after: interv.subSurfaceLayer,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Product Name',
            before: interv.productNamePreSdplus,
            after: interv.productName,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Brand',
            before: '-',
            after: interv.brand,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Quantity',
            before: '-',
            after: interv.quantity,
            Improvement: '-',
            unit: 'sqm',
          },
          {
            name: 'CAPEX',
            before: rsToCr('Rs', interv.capexPreSdplus),
            after: rsToCr('Rs', interv.capexPostSdplus),
            Improvement: interv?.capexPercentChange ? Math.round(interv?.capexPercentChange) : '-',
            unit: 'Cr',
          },
          {
            name: 'Embodied Carbon Savings',
            before: numberWithCommas(Math.round(interv.resourceSavingsPreSdPlus)),
            after: numberWithCommas(Math.round(interv.resourceSavings)),
            Improvement: interv?.resourceSavingsPercentChange ? Math.round(interv?.resourceSavingsPercentChange) : '-',
            unit: 'tCO2e',
          },
        ],
      };
    }
  });
  const Waste = categorisedReportData.map((interv) => {
    if (interv.interventionKey === 'wwm') {
      return {
        intervention: MetafireInterventions[interv.interventionKey],
        icon: getIcon(interv.interventionKey),
        data: [
          {
            name: 'Wet Waste Used for',
            before: interv.wwUsedForPreSdplus,
            after: interv.wwUsedFor,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Wet Waste Managed By',
            before: interv.wwManagedByPreSdplus,
            after: interv.wwManagedBy,
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Capacity',
            before: numberWithCommas(Math.round(interv.capacityPreSdplus)),
            after: numberWithCommas(Math.round(interv.capacity)),
            Improvement: interv?.capacityPercentChange ? Math.round(interv?.capacityPercentChange) : '-',
            unit: 'kg',
          },
          {
            name: 'CAPEX',
            before: rsToCr('Rs', interv.capexPreSdplus),
            after: rsToCr('Rs', interv.capexPostSdplus),
            Improvement: interv?.capexPercentChange ? Math.round(interv?.capexPercentChange) : '-',
            unit: 'Cr',
          },
          {
            name: 'Resource Savings',
            before: numberWithCommas(Math.round(interv.resourceSavingsPreSdPlus)),
            after: numberWithCommas(Math.round(interv.resourceSavings)),
            Improvement: interv?.resourceSavingsPercentChange ? Math.round(interv?.resourceSavingsPercentChange) : '-',
            unit: 'KL',
          },
        ],
      };
    }
    if (interv.interventionKey === 'csp') {
      return {
        intervention: MetafireInterventions[interv.interventionKey],
        icon: getIcon(interv.interventionKey),
        data: [
          {
            name: 'Dry Waste Sustainably Managed',
            before: numberWithCommas(Math.round(interv.dryWasteSustainablyManaged)),
            after: numberWithCommas(Math.round(interv.dryWasteSustainablyManagedPreSdplus)),
            Improvement: interv?.dryWasteSustainablyManagedPercentChange ? Math.round(interv?.dryWasteSustainablyManagedPercentChange) : '-',
            unit: 'kg',
          },
          {
            name: 'E Waste Sustainably Managed',
            before: numberWithCommas(Math.round(interv.eWasteSustainablyManaged)),
            after: numberWithCommas(Math.round(interv.eWasteSustainablyManagedPreSdplus)),
            Improvement: interv?.eWasteSustainablyManagedPercentChange ? Math.round(interv?.eWasteSustainablyManagedPercentChange) : '-',
            unit: 'kg',
          },
          {
            name: 'Medical Waste Sustainably Managed',
            before: numberWithCommas(Math.round(interv.medicalWasteSustainablyManaged)),
            after: numberWithCommas(Math.round(interv.medicalWasteSustainablyManaged)),
            Improvement: interv?.medicalWasteSustainablyManagedPercentChange ? Math.round(interv?.medicalWasteSustainablyManagedPercentChange) : '-',
            unit: 'kg',
          },
          {
            name: 'CAPEX',
            before: rsToCr('Rs', interv.capexPreSdplus),
            after: rsToCr('Rs', interv.capexPostSdplus),
            Improvement: interv?.capexPercentChange ? Math.round(interv?.capexPercentChange) : '-',
            unit: 'Cr',
          },
          {
            name: 'Resource Savings',
            before: numberWithCommas(Math.round(interv.resourceSavingsPreSdPlus)),
            after: numberWithCommas(Math.round(interv.resourceSavings)),
            Improvement: interv?.resourceSavingsPercentChange ? Math.round(interv?.resourceSavingsPercentChange) : '-',
            unit: 'KL',
          },
        ],
      };
    }
  });
  const Water = categorisedReportData.map((interv) => {
    if (interv.interventionKey === 'efficientFixturesDualFlush') {
      return {
        intervention: MetafireInterventions[interv.interventionKey],
        icon: getIcon(interv.interventionKey),
        data: [
          {
            name: 'CAPEX',
            before: rsToCr('Rs', interv.capexPreSdplus),
            after: rsToCr('Rs', interv.capexPostSdplus),
            Improvement: interv?.capexPercentChange ? Math.round(interv?.capexPercentChange) : '-',
            unit: 'Cr',
          },
          {
            name: 'Flush Rate - Full',
            before: interv.fullFlowPreSdplus,
            after: interv.fullFlow,
            Improvement: interv?.fullFlowPercentChange ? Math.round(interv?.fullFlowPercentChange) : '-',
            unit: 'LPF',
          },
          {
            name: 'Flush Rate - Half',
            before: interv.halfFlowPreSdplus,
            after: interv.halfFlow,
            Improvement: interv?.halfFlowPercentChange ? Math.round(interv?.halfFlowPercentChange) : '-',
            unit: 'LPF',
          },
          {
            name: 'Resource Savings',
            before: numberWithCommas(Math.round(interv.resourceSavingsPreSdPlus)),
            after: numberWithCommas(Math.round(interv.resourceSavings)),
            Improvement: interv?.resourceSavingsPercentChange ? Math.round(interv?.resourceSavingsPercentChange) : '-',
            unit: 'KL',
          },
        ],
      };
    }
    if (interv.interventionKey === 'twr') {
      return {
        intervention: MetafireInterventions[interv.interventionKey],
        icon: getIcon(interv.interventionKey),
        data: [
          {
            name: 'CAPEX',
            before: rsToCr('Rs', interv.capexPreSdplus),
            after: rsToCr('Rs', interv.capexPostSdplus),
            Improvement: interv?.capexPercentChange ? Math.round(interv?.capexPercentChange) : '-',
            unit: 'Cr',
          },
          {
            name: 'STP Capacity',
            before: Math.round(interv.stpCapacityPreSdplus),
            after: Math.round(interv.stpCapacity),
            Improvement: interv?.stpCapacityPercentChange ? Math.round(interv?.stpCapacityPercentChange) : '-',
            unit: 'KLD',
          },
          {
            name: 'RO Capacity',
            before: Math.round(interv.roCapacityPreSdplus),
            after: Math.round(interv.roCapacity),
            Improvement: interv?.roCapacityPercentChange ? Math.round(interv?.roCapacityPercentChange) : '-',
            unit: 'KLD',
          },
          {
            name: 'Water Discharged',
            before: Math.round(interv.waterDischargedPreSdplus),
            after: Math.round(interv.waterDischarged),
            Improvement: interv?.waterDischargedPercentChange ? Math.round(interv?.waterDischargedPercentChange) : '-',
            unit: 'KLD',
          },
          {
            name: 'Flushing',
            before: interv.flushingPreSdplus ? 'Yes' : 'No',
            after: interv.flushing ? 'Yes' : 'No',
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Landscape',
            before: interv.landScapingPreSdplus ? 'Yes' : 'No',
            after: interv.landScaping ? 'Yes' : 'No',
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Car Wash',
            before: interv.carWashPreSdplus ? 'Yes' : 'No',
            after: interv.carWash ? 'Yes' : 'No',
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Pool',
            before: interv.poolPreSdplus ? 'Yes' : 'No',
            after: interv.pool ? 'Yes' : 'No',
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Common Area Cleaning',
            before: interv.commonAreaCleaningPreSdplus ? 'Yes' : 'No',
            after: interv.commonAreaCleaning ? 'Yes' : 'No',
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Domestic Consumption',
            before: interv.domesticPreSdplus ? 'Yes' : 'No',
            after: interv.domestic ? 'Yes' : 'No',
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Offsite Reuse',
            before: interv.offSiteReusePreSdplus ? 'Yes' : 'No',
            after: interv.offSiteReuse ? 'Yes' : 'No',
            Improvement: '-',
            unit: '',
          },
          {
            name: 'Resource Savings',
            before: numberWithCommas(Math.round(interv.resourceSavingsPreSdPlus)),
            after: numberWithCommas(Math.round(interv.resourceSavings)),
            Improvement: interv?.resourceSavingsPercentChange ? Math.round(interv?.resourceSavingsPercentChange) : '-',
            unit: 'KL',
          },
        ],
      };
    }
    if (interv.interventionKey === 'nstp') {
      return {
        intervention: MetafireInterventions[interv.interventionKey],
        icon: getIcon(interv.interventionKey),
        data: [
          {
            name: 'CAPEX',
            before: rsToCr('Rs', interv.capexPreSdplus),
            after: rsToCr('Rs', interv.capexPostSdplus),
            Improvement: interv?.capexPercentChange ? Math.round(interv?.capexPercentChange) : '-',
            unit: 'Cr',
          },
          {
            name: 'STP Capacity',
            before: Math.round(interv.stpCapacityPreSdplus),
            after: Math.round(interv.stpCapacity),
            Improvement: interv?.stpCapacityPercentChange ? Math.round(interv?.stpCapacityPercentChange) : '-',
            unit: 'KLD',
          },
          {
            name: 'Energy Required',
            before: Math.round(interv.energyRequiredPreSdplus),
            after: Math.round(interv.energyRequired),
            Improvement: interv?.energyRequiredPercentChange ? Math.round(interv?.energyRequiredPercentChange) : '-',
            unit: 'kWh',
          },
          {
            name: 'Resource Savings',
            before: numberWithCommas(Math.round(interv.resourceSavingsPreSdPlus)),
            after: numberWithCommas(Math.round(interv.resourceSavings)),
            Improvement: interv?.resourceSavingsPercentChange ? Math.round(interv?.resourceSavingsPercentChange) : '-',
            unit: 'KL',
          },
        ],
      };
    }
    if (interv.interventionKey === 'swm') {
      return {
        intervention: MetafireInterventions[interv.interventionKey],
        icon: getIcon(interv.interventionKey),
        data: [
          {
            name: 'CAPEX',
            before: rsToCr('Rs', interv.capexPreSdplus),
            after: rsToCr('Rs', interv.capexPostSdplus),
            Improvement: interv?.capexPercentChange ? Math.round(interv?.capexPercentChange) : '-',
            unit: 'Cr',
          },
          {
            name: 'No. Of Sensors',
            before: interv.noOfSensorsPreSdplus,
            after: interv.noOfSensors,
            Improvement: interv?.onOfSensorsPercentChange ? Math.round(interv?.onOfSensorsPercentChange) : '-',
            unit: '',
          },
          {
            name: 'Resource Savings',
            before: numberWithCommas(Math.round(interv.resourceSavingsPreSdPlus)),
            after: numberWithCommas(Math.round(interv.resourceSavings)),
            Improvement: interv?.resourceSavingsPercentChange ? Math.round(interv?.resourceSavingsPercentChange) : '-',
            unit: 'KL',
          },
        ],
      };
    }
    return {
      intervention: MetafireInterventions[interv.interventionKey],
      icon: getIcon(interv.interventionKey),
      data: [
        {
          name: 'CAPEX',
          before: rsToCr('Rs', interv.capexPreSdplus),
          after: rsToCr('Rs', interv.capexPostSdplus),
          Improvement: interv?.capexPercentChange ? Math.round(interv?.capexPercentChange) : '-',
          unit: 'Cr',
        },
        {
          name: 'Fixtures Flow Rate',
          before: interv.flowPreSdplus,
          after: interv.flow,
          Improvement: interv?.flowPercentChange ? Math.round(interv?.flowPercentChange) : '-',
          unit: 'LPM',
        },
        {
          name: 'Resource Savings',
          before: numberWithCommas(Math.round(interv.resourceSavingsPreSdPlus)),
          after: numberWithCommas(Math.round(interv.resourceSavings)),
          Improvement: interv?.resourceSavingsPercentChange ? Math.round(interv?.resourceSavingsPercentChange) : '-',
          unit: 'KL',
        },
      ],
    };
  });
  if (category === 'Energy') return Energy;
  if (category === 'Water') return Water;
  if (category === 'Waste') return Waste;
  if (category === 'Materials') return Materials;
  return [];
};
