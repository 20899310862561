import { Grid, Modal, Typography, makeStyles, withWidth } from '@material-ui/core';
import React, { useState } from 'react';
import ProductCarousal from './ProductCarousal';
import ProductActions from './ProductActions';
import useScreenType from '../../../../../hooks/checkScreenType';
import { MarketplaceColors } from '../../../config';
import { Skeleton } from '@material-ui/lab';
import { useEffect } from 'react';
import LeftArrow from '../../../common/LeftArrow';
import leftarrow from '../../../../../../assets/carousal/leftarrow.svg';
import rightarrow from '../../../../../../assets/carousal/rightarrow.svg';
import RightArrow from '../../../common/RightArrow';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    display: 'flex',
    background: 'transparent',
  };
}
const rightarrowStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'auto',
  cursor: 'pointer',
};

const useStyles = makeStyles((theme) => ({
  imageandproductdetails: {
    paddingTop: theme.spacing(2),
  },
  arrowStyles: {
    position: 'absolute',
    zIndex: 2,
    top: 'calc(50% - 15px)',
    width: 25,
    height: 56,
    cursor: 'pointer',
  },
  paper: {
    position: 'absolute',
    width: '100%',
    height: '80vw',
    outline: 'none',
    background: 'transparent',
    borderRadius: '15px',
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const priceAvilabilityChecker = (price) => {
  if (!price) return false;
  else if (price === '') return false;
  else if (price === 'NA') return false;
  else return true;
};

const next = () => {};
const prev = () => {};

const ProductSummary = (props) => {
  const [seeMore, setSeeMore] = useState(false);
  const { width, productAttributes } = props;
  const { isMobile } = useScreenType(width);
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();
  const [isImageAccessible, setIsImageAccessible] = useState(false);

  useEffect(() => {
    if (productAttributes?.image && productAttributes?.image.trim() !== '') {
      const img = new Image();
      img.src = productAttributes?.image;
      img.onload = () => setIsImageAccessible(true);
      img.onerror = () => setIsImageAccessible(false);
    }
  }, [productAttributes?.image]);

  const [showModal, setShowModal] = useState(false);
  const handleClick = () => {
    setShowModal(true);
  };
  const onClose = () => {
    setShowModal(false);
  };
  const leftarrowStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20px',
    cursor: 'pointer',
    width: '20px',
  };
  const imageStyles = {
    display: 'block',
  };

  return (
    <Grid container className={classes.imageandproductdetails} spacing={2}>
      <Grid xs={12} sm={12} md={5} lg={5} xl={5} item container alignItems="center" justifyContent="center">
        <ProductCarousal handleClick={handleClick} imageUrl={productAttributes && productAttributes?.image} />
      </Grid>
      <Grid container xs={12} sm={12} md={7} lg={7} xl={7} item alignItems="flex-start" justifyContent="flex-start">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          color={!isMobile ? 'textPrimary' : 'textSecondary'}
        >
          {!productAttributes ? (
            <Skeleton variant="rect" style={{ width: 100 }} />
          ) : (
            <Typography variant={!isMobile ? 'h5' : 'h6'}>
              <b>{productAttributes?.sku_name}</b>
            </Typography>
          )}
        </Grid>
        {isMobile && (
          <Grid item container alignItems="center" justifyContent="center">
            <ProductActions />
          </Grid>
        )}
        <Grid item xs={12} container alignItems="flex-start" justifyContent="flex-start">
          {productAttributes && !isMobile ? (
            <Typography color={!isMobile ? 'textSecondary' : 'textPrimary'} variant="h6">
              <b>{!isMobile && priceAvilabilityChecker(productAttributes.price) !== false ? productAttributes && productAttributes.price : ``}</b>
            </Typography>
          ) : (
            <Skeleton variant="rect" style={{ width: 100 }} />
          )}
        </Grid>
        {isMobile ? (
          <Grid item xs={12} container alignItems="center" justifyContent="flex-start" style={{ marginTop: '3%' }}>
            <Typography variant="body1" style={{ lineHeight: '32px', letterSpacing: ' 0.714px' }}>
              {`CSEBs produced by us are high-quality building materials and far exceed the strength requirements stipulated by the Indian Standards. Earth Blocks are far superior in quality & strength than locally made country fired bricks.      Embodied energy and carbon emissions of Earth Blocks is lesser by 10 and 12 times, when compared with country fired bricks`}
              {!seeMore && `...`}
              {seeMore &&
                ` looked up one of the more obscure Latin words,
            consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem
            Ipsum comes from sections 1.10.32 and 1.10.33 of.`}
            </Typography>
            <Typography
              variant="body1"
              style={{ lineHeight: '32px', letterSpacing: ' 0.714px', color: MarketplaceColors.Secondary }}
              onClick={() => setSeeMore((prev) => !prev)}
            >
              {seeMore ? `See Less` : `See More`}
            </Typography>
          </Grid>
        ) : (
          <>
            {productAttributes ? (
              <Grid item xs={12} container alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: '3%' }}>
                <Typography variant="body1" style={{ lineHeight: '32px', letterSpacing: ' 0.714px' }}>
                  {productAttributes && productAttributes.description}
                </Typography>
              </Grid>
            ) : (
              [1, 2, 2, 2, 2].map((d) => (
                <Grid item xs={12} container alignItems="flex-start" justifyContent="flex-start" style={{ marginTop: '1%' }}>
                  <Skeleton variant="rect" style={{ width: '500px' }} />
                </Grid>
              ))
            )}
          </>
        )}
      </Grid>
      <Modal open={showModal} onClose={onClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
        <div onClick={onClose} style={{ direction: 'flex', flexDirection: 'row', ...modalStyle }} className={classes.paper}>
          <LeftArrow zoomed={true} leftarrow={leftarrow} next={next} prev={prev} arrowStyles={leftarrowStyles} imageStyles={imageStyles} />
          <div style={{ width: '80%', borderRadius: '15px', backgroundColor: 'white', margin: '0.5%' }}>
            <ProductCarousal zoomed={true} handleClick={handleClick} imageUrl={productAttributes && productAttributes?.image} />
          </div>
          <RightArrow zoomed={true} rightarrow={rightarrow} next={next} arrowStyles={rightarrowStyles} imageStyles={{ opacity: 1, ...imageStyles }} />
        </div>
      </Modal>
    </Grid>
  );
};

export default withWidth()(ProductSummary);
