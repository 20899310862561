import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, Container, Grid, Typography, Box } from '@material-ui/core';
import PageTitle from '../../insightsPage/insights/components/PageTitle';
import useApiRequest from '../../../hooks/useApiRequest';
import { useParams } from 'react-router-dom';
import { IGBCCategoryContext } from '../../../../projectRouting/Index';
import CustomBreadCrumbs from '../../../common/breadcrumbs/CustomBreadCrumbs';
import IGBCCreditSubpartsTable from '../components/IGBCCreditSubpartsTable';
import PrimaryButton from '../../../common/button/Button';
import EclipseProgress from '../common/EclipseProgress';
import DocumentDashboard from '../../igbc-dashboard/documents-dashboard/DocumentDasboard';
import { extractData } from '../../../../utils/dataConditionsCheck';

import localbuildingreg from '../../../../assets/IGBC/sustainabledesign/localbuildingreg.svg';
import basicfacilityconstructionWorkforce from '../../../../assets/IGBC/sustainabledesign/basicfacilityconstructionWorkforce.svg';
import basichouseHoldAmenities from '../../../../assets/IGBC/sustainabledesign/basichouseHoldAmenities.svg';
import greenEducation from '../../../../assets/IGBC/sustainabledesign/greeneducation.svg';
import greenparkingfacility from '../../../../assets/IGBC/sustainabledesign/greenparkingfacility.svg';
import naturaltopographyandvegetation from '../../../../assets/IGBC/sustainabledesign/naturaltopographyandvegetation.svg';
import passivearchitecture from '../../../../assets/IGBC/sustainabledesign/passivearchitecture.svg';
import soilerosion from '../../../../assets/IGBC/sustainabledesign/soilerosion.svg';
import universaldesign from '../../../../assets/IGBC/sustainabledesign/universaldesign.svg';
import heatisland from '../../../../assets/IGBC/sustainabledesign/heatisland.svg';
//material and resources
import organicWaste from '../../../../assets/IGBC/materialandresources/organicWaste.svg';
import constructionanddemolitionwaste from '../../../../assets/IGBC/materialandresources/constructionanddemolitionwaste.svg';
import alternativeconstructionmaterial from '../../../../assets/IGBC/materialandresources/alternativeconstructionmaterial.svg';
import organicwastetreecofriendlywoodbasedmaterialatment from '../../../../assets/IGBC/materialandresources/ecofriendlywoodbasedmaterial.svg';
import localmaterial from '../../../../assets/IGBC/materialandresources/localmaterial.svg';
import certifiedgreenproducts from '../../../../assets/IGBC/materialandresources/certifiedgreenproducts.svg';
import optimizationstructuraldesign from '../../../../assets/IGBC/materialandresources/optimizationstructuraldesign.svg';
import greenprocurementpolicy from '../../../../assets/IGBC/materialandresources/greenprocurementpolicy.svg';
import separartionofhouseholdwaste from '../../../../assets/IGBC/materialandresources/separartionofhouseholdwaste.svg';

//energy
import integratedenergymonitoringsystem from '../../../../assets/IGBC/energy/integratedenergymonitoringsystem.svg';
import hcfcFreeEquipement from '../../../../assets/IGBC/energy/hcfcFreeEquipement.svg';
import minimumenergyperformance from '../../../../assets/IGBC/energy/minimumenergyperformance.svg';
import enhancedenergyperformance from '../../../../assets/IGBC/energy/enhancedenergyperformance.svg';
import alternatewaterheatingsystem from '../../../../assets/IGBC/energy/alternatewaterheatingsystem.svg';
import commonarealighting from '../../../../assets/IGBC/energy/commonarealighting.svg';
import commonareaequipment from '../../../../assets/IGBC/energy/commonareaequipment.svg';

//water
import landscapedesign from '../../../../assets/IGBC/water/landscapedesign.svg';
import watermetering from '../../../../assets/IGBC/water/watermetering.svg';
import waterquality from '../../../../assets/IGBC/water/waterquality.svg';
import Irrigation from '../../../../assets/IGBC/water/irrigation.svg';
import recycleandreuseofwastewater from '../../../../assets/IGBC/water/recycleandreuseofwastewater.svg';
import enhancedrainwaterharvesting from '../../../../assets/IGBC/water/enhancedrainwaterharvesting.svg';
import waterefficiencyplumbing from '../../../../assets/IGBC/water/waterefficiencyplumbing.svg';
import rainwaterharvesting from '../../../../assets/IGBC/water/rainwaterharvesting.svg';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import { PreviousPageContext } from '../../../../routing/IndexRouting';

const icons = [
  { name: 'Heat Island Effect: Roof and Non-Roof', icon: heatisland },
  { name: 'Natural Topography and Vegetation', icon: naturaltopographyandvegetation },
  { name: 'Passive Architecture', icon: passivearchitecture },
  { name: 'Universal Design', icon: universaldesign },
  { name: 'Green Parking Facility', icon: greenparkingfacility },
  { name: 'Basic House-hold Amenities', icon: basichouseHoldAmenities },
  { name: 'Basic Facilities for Construction Workforce', icon: basicfacilityconstructionWorkforce },
  { name: 'Green Education and Awareness', icon: greenEducation },
  { name: 'Local Building Regulations', icon: localbuildingreg },
  { name: 'Soil Erosion Control', icon: soilerosion },
  //energy
  { name: 'Integrated Energy Monitoring System', icon: integratedenergymonitoringsystem },
  { name: 'Enhanced Energy Performance', icon: enhancedenergyperformance },
  { name: 'Energy Efficiency in Common Area Equipment', icon: commonareaequipment },
  { name: 'On-site Renewable Energy - Common area Lighting', icon: commonarealighting },
  { name: 'Alternate Water Heating Systems', icon: alternatewaterheatingsystem },
  { name: 'HCFC Free Equipment', icon: hcfcFreeEquipement },
  { name: 'Minimum Energy Performance', icon: minimumenergyperformance },
  //material and resources
  { name: 'Green Procurement Policy', icon: greenprocurementpolicy },
  { name: 'Optimisation on Structural Design', icon: optimizationstructuraldesign },
  { name: 'Certified Green Products', icon: certifiedgreenproducts },
  { name: 'Local Materials', icon: localmaterial },
  { name: 'Eco Friendly Wood Based Materials', icon: organicwastetreecofriendlywoodbasedmaterialatment },
  { name: 'Alternative Construction Materials', icon: alternativeconstructionmaterial },
  { name: 'Handling of Construction and Demolition Waste', icon: constructionanddemolitionwaste },
  { name: 'Organic Waste management, Post Occupancy', icon: organicWaste },
  { name: 'Separation of House-hold Waste', icon: separartionofhouseholdwaste },
  //water
  { name: 'Landscape Design', icon: landscapedesign },
  { name: 'Water Metering', icon: watermetering },
  { name: 'Water Quality', icon: waterquality },
  { name: 'Management of Irrigation Systems', icon: Irrigation },
  { name: 'Recycle & Reuse of Waste Water', icon: recycleandreuseofwastewater },
  { name: 'Water Efficient Plumbing Fixtures', icon: waterefficiencyplumbing },
  { name: 'Water Efficient Plumbing Fixture', icon: waterefficiencyplumbing },
  { name: 'Enhanced Rainwater Harvesting', icon: enhancedrainwaterharvesting },
  { name: 'Rainwater Harvesting', icon: rainwaterharvesting },
  // resident health and well being
  { name: 'Enhanced Daylighting', icon: rainwaterharvesting },
  { name: 'Enhanced Ventilation Design', icon: rainwaterharvesting },
  { name: 'Cross Ventilation', icon: rainwaterharvesting },
  { name: 'Connectivity To Exteriors', icon: rainwaterharvesting },
  { name: 'Low VOC Materials, Paints & Adhesives', icon: rainwaterharvesting },
  { name: 'Facility for Physical Wellbeing', icon: rainwaterharvesting },
  { name: 'Minimum Daylighting', icon: rainwaterharvesting },
  { name: 'No Smoking Policy', icon: rainwaterharvesting },
  { name: 'Ventilation Design', icon: rainwaterharvesting },
];
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: 'rgba(24, 50, 119, 0.07)',
  },
  main: {
    paddingTop: theme.spacing(10),
    minHeight: '100vh',
    paddingLeft: '2%',
    paddingRight: '2%',
  },
}));

export default function Credit(props) {
  const classes = useStyles();
  const params = useParams();
  const creditId = params && params.creditId;
  const projectId = params && params.id;
  const { setPreviousPage, setIGBCCreditID } = useContext(PreviousPageContext);

  const [showCreditDocumentation, setShowCreditDocumentation] = useState(false);

  const [creditDetails, setCreditDetails] = useState();

  const [selectedCategoryData, setSelectedCategoryData] = useState('');
  const [igbcCertificationData, setigbcCertificationData] = useState('');
  const [documentUploadPercentage, setDataUploadpercentage] = useState('');

  const { igbcCategoryContext } = useContext(IGBCCategoryContext);
  const [{ status, response }, makeRequest] = useApiRequest(`/igbc-credit/${creditId}`, {
    verb: 'get',
  });

  const [re, sendRequest] = useApiRequest(`/projectv2/${projectId}/igbc-data`, {
    verb: 'get',
  });

  if (status === null && creditId) makeRequest();

  useEffect(() => {
    if (re.status === null && projectId) {
      sendRequest();
    }
  }, [re, projectId]);

  useEffect(() => {
    if (creditId) {
      setIGBCCreditID(creditId);
      setPreviousPage('certificationCredit');
    }
  }, [creditId]);

  useEffect(() => {
    if (re.response && re.response.data && re.response.data.data && re.response.data.data.data) {
      const IGBCdata = re.response.data.data.data.certifications.find((certification) => certification.name === 'IGBC');
      setigbcCertificationData(IGBCdata && IGBCdata);
    }
  }, [re]);

  useEffect(() => {
    if (response && response.data && response.data && response.data.data) setCreditDetails(response.data.data.data);
  }, [response]);

  useEffect(() => {
    if (igbcCategoryContext && igbcCertificationData) {
      const data = igbcCertificationData.categories.find((data) => data.name === igbcCategoryContext);
      if (data) {
        setSelectedCategoryData(data && data);
        setDataUploadpercentage(data && data.overall && data.overall.documentUploadPercentage);
      } else {
        setSelectedCategoryData('');
        setDataUploadpercentage(0);
      }
    }
  }, [igbcCategoryContext, igbcCertificationData]);

  const [creditDetailsData, setCreditDetailsData] = useState('');

  useEffect(() => {
    if (selectedCategoryData && creditId) {
      selectedCategoryData.categorysections.forEach((element) => {
        const creditData = element && element.credits.find((c) => c && c.id === creditId);
        if (creditData) setCreditDetailsData(creditData);
      });
    }
  }, [selectedCategoryData, creditId]);
  const geticon = (name) => icons.filter((creditname) => creditname.name === name);

  return (
    <>
      <div className={classes.container}>
        <Box className={classes.main}>
          <Grid container alignItems="center" item xs={12}>
            <Grid item xs={10} align="left">
              <PageTitle title={igbcCategoryContext && igbcCategoryContext} />
            </Grid>
            {/* <Grid item xs={2} align="right">
              <Typography variant="h6" style={{ color: '#00C58A', fontWeight: 500, cursor: 'pointer' }}>
                {igbcCategoryContext} FAQS
              </Typography>
            </Grid> */}
          </Grid>
          <CustomBreadCrumbs
            links={[{ href: `/projects/${projectId}/certification`, text: 'Building certification' }]}
            stage={
              <Typography variant="h6">
                <b>{creditDetails && creditDetails.creditName}</b>
              </Typography>
            }
          />
          <Grid container alignItems="center" style={{ marginTop: '2%' }}>
            <Grid item xs={0} align="right"></Grid>
            <Grid container item xs={12} align="left">
              <Grid item xs={0}>
                {creditDetails && (
                  <img src={geticon(creditDetails && creditDetails.creditName)[0].icon} height="41px" alt="..." style={{ paddingRight: '10px' }} />
                )}
                {/* */}
              </Grid>
              <Grid item xs={8} align="left">
                <Typography variant="h6" style={{ paddingTop: '2%' }}>
                  <b>{creditDetails && creditDetails.creditName}</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container alignItems="center" style={{ marginTop: '2%' }}>
            <Grid item xs={12} align="left">
              <Typography
                variant="h6"
                style={{ color: '#00C58A', fontWeight: 500, cursor: 'pointer' }}
                onClick={() => setShowCreditDocumentation(!showCreditDocumentation)}
              >
                {!showCreditDocumentation ? `Credit Description` : `Hide Credit Description`}
              </Typography>
            </Grid>
          </Grid>
          {showCreditDocumentation ? (
            <Grid container alignItems="center" style={{ marginTop: '2%' }}>
              <Grid item xs={11} align="left" style={{ border: '2px solid #C6F2CD', borderRadius: '6px', padding: 10, background: 'rgba(209, 246, 235, 0.1)' }}>
                {creditDetailsData &&
                  creditDetailsData.creditdescription.split('(firstlayer)').map((a, index) => {
                    return (
                      <>
                        <Typography variant="h6" style={{ lineHeight: '24px', letterSpacing: '0.714286px' }}>
                          {a.split(':')[0]}:
                        </Typography>
                        <Typography variant="h5" style={{ lineHeight: '24px', letterSpacing: '0.714286px' }}>
                          <Grid container item xs={12}>
                            <Grid item xs={0}>
                              {/* {<FiberManualRecordIcon style={{ fontSize: '10px' }} />} */}
                            </Grid>
                            <Grid item xs={11}>
                              {a
                                .split(':')[1]
                                .split('(secondlayer)')
                                .map((secondlayer, index1) => {
                                  return (
                                    <>
                                      <Typography variant="h6" style={{ lineHeight: '24px', letterSpacing: '0.714286px' }}>
                                        <Grid container item xs={12}>
                                          <Grid item xs={0}>
                                            {index1 === 0 ? '' : <FiberManualRecordIcon style={{ fontSize: '5px' }} />}
                                          </Grid>
                                          <Grid item xs={11}>
                                            {secondlayer.split('(thirdlayer)').map((thirdlayer, index2) => {
                                              return (
                                                <>
                                                  <Typography variant="h6" style={{ lineHeight: '24px', letterSpacing: '0.714286px' }}>
                                                    <Grid container item xs={12}>
                                                      <Grid item xs={0}>
                                                        {index2 === 0 ? '' : <FiberManualRecordOutlinedIcon style={{ fontSize: '10px' }} />}
                                                      </Grid>
                                                      <Grid item xs={11}>
                                                        {thirdlayer.split('(fourthlayer)').map((fourthlayer, index3) => {
                                                          return (
                                                            <>
                                                              <Typography variant="h6" style={{ lineHeight: '24px', letterSpacing: '0.714286px' }}>
                                                                <Grid container item xs={12}>
                                                                  <Grid item xs={0}>
                                                                    {index3 === 0 ? '' : <FiberManualRecordOutlinedIcon style={{ fontSize: '5px' }} />}
                                                                  </Grid>
                                                                  <Grid item xs={11} style={{ paddingLeft: '10px' }}>
                                                                    {fourthlayer}
                                                                  </Grid>
                                                                </Grid>
                                                              </Typography>
                                                            </>
                                                          );
                                                        })}
                                                      </Grid>
                                                    </Grid>
                                                  </Typography>
                                                </>
                                              );
                                            })}
                                          </Grid>
                                        </Grid>
                                      </Typography>
                                    </>
                                  );
                                })}
                            </Grid>
                            {a.split(':')[2] && (
                              <Grid item xs={11}>
                                {a
                                  .split(':')[2]
                                  .split('(secondlayer)')
                                  .map((secondlayer, index1) => {
                                    return (
                                      <>
                                        <Typography variant="h6" style={{ lineHeight: '24px', letterSpacing: '0.714286px' }}>
                                          <Grid container item xs={12}>
                                            <Grid item xs={0}>
                                              {index1 === 0 ? '' : <FiberManualRecordIcon style={{ fontSize: '5px' }} />}
                                            </Grid>
                                            <Grid item xs={11}>
                                              {secondlayer.split('(thirdlayer)').map((thirdlayer, index2) => {
                                                return (
                                                  <>
                                                    <Typography variant="h6" style={{ lineHeight: '24px', letterSpacing: '0.714286px' }}>
                                                      <Grid container item xs={12}>
                                                        <Grid item xs={0}>
                                                          {index2 === 0 ? '' : <FiberManualRecordOutlinedIcon style={{ fontSize: '10px' }} />}
                                                        </Grid>
                                                        <Grid item xs={11}>
                                                          {thirdlayer.split('(fourthlayer)').map((fourthlayer, index3) => {
                                                            return (
                                                              <>
                                                                <Typography variant="h6" style={{ lineHeight: '24px', letterSpacing: '0.714286px' }}>
                                                                  <Grid container item xs={12}>
                                                                    <Grid item xs={0}>
                                                                      {index3 === 0 ? '' : <FiberManualRecordOutlinedIcon style={{ fontSize: '5px' }} />}
                                                                    </Grid>
                                                                    <Grid item xs={11} style={{ paddingLeft: '10px' }}>
                                                                      {fourthlayer}
                                                                    </Grid>
                                                                  </Grid>
                                                                </Typography>
                                                              </>
                                                            );
                                                          })}
                                                        </Grid>
                                                      </Grid>
                                                    </Typography>
                                                  </>
                                                );
                                              })}
                                            </Grid>
                                          </Grid>
                                        </Typography>
                                      </>
                                    );
                                  })}
                              </Grid>
                            )}
                          </Grid>
                        </Typography>
                      </>
                    );
                  })}
              </Grid>
            </Grid>
          ) : (
            ''
          )}
          <Grid container alignItems="flex-end" justifyContent="space-between" item xs={12} style={{ marginTop: '2%' }}>
            <Grid item xs={5} style={{ border: '1px solid #9E9E9E', borderRadius: '6px', padding: 10, background: 'transparent' }} container>
              <Grid xs={12} style={{ paddingLeft: '3%' }}>
                <Typography variant="h6">Total Points</Typography>
              </Grid>
              <Grid xs={12} container style={{ marginTop: '3%', paddingLeft: '3%', paddingBottom: '3%' }} alignItems="center" justifyContent="center">
                <Grid xs={3} item>
                  <EclipseProgress
                    newvalue={creditDetailsData.overall && (creditDetailsData.overall.totalpointsachieved / creditDetailsData.overall.totalpoints) * 100}
                    range={100}
                    staticvalue={100}
                    creditDetailsData={creditDetailsData && creditDetailsData}
                  />
                </Grid>
                <Grid xs={9} item container direction="column" style={{ paddingLeft: '5%' }}>
                  {creditDetailsData &&
                    creditDetailsData.parts &&
                    creditDetailsData.parts.map((phase, index) => {
                      return (
                        <>
                          <Grid xs item container direction="row" alignItems="center" justifyContent="center" style={index > 0 ? { marginTop: '10%' } : null}>
                            <Grid xs={2}>
                              <Typography variant="h6" style={{ fontSize: '12px', color: '#26E0A3' }}>
                                {phase && phase.overall ? `${extractData(phase.overall.totalpointsachieved)}/${extractData(phase.overall.totalpoints)}` : '-'}
                              </Typography>
                            </Grid>
                            <Grid xs={10}>
                              <Typography variant="h6" style={{ fontSize: '12px' }}>
                                <b> {phase.name}</b>
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} align="right">
              <PrimaryButton children="Upload Documents" />
            </Grid>
          </Grid>
          <IGBCCreditSubpartsTable
            igbcCategoryContext={igbcCategoryContext && igbcCategoryContext}
            selectedCategoryData={selectedCategoryData && selectedCategoryData}
            creditId={creditId && creditId}
          />
          <DocumentDashboard
            creditname={creditDetails && creditDetails.creditName}
            creditDetailsForaSingleCredit={creditDetails && creditDetails}
            igbcCategoryContext={igbcCategoryContext && igbcCategoryContext}
            documentUploadPercentage={creditDetailsData && creditDetailsData.overall.documentUploadPercentage}
            source="credit details"
          />
        </Box>
      </div>
    </>
  );
}
