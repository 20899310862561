import React, { useEffect, useState } from 'react';
import { useStyles } from './Styles';
import api from '../../../../../http';
import { Grid, Modal, Backdrop, Fade } from '@material-ui/core';
import { ALLOWED_FILE_TYPES, MAX_FILE_SIZE, RenderUploadOverlay, RenderUploadContent, RenderPreviewImage } from './components';
import { getBaseImageUrl } from '../../../../../utils/getBaseImageUrl';
import { useAppApiRequest } from '../../../../../hooks/useAppApiRequest';

const UploadComponent = ({ organisationId }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [uploadState, setUploadState] = useState('idle');
  const [errorType, setErrorType] = useState(null);
  const [file, setFile] = useState(null);
  const [publicUrl, setPublicUrl] = useState(null);

  const {
    data: responseOrganisation,
    isError,
    isFetching: isFetchingOrganisation,
    refetch: fetchingOrganisation,
  } = useAppApiRequest(() => api.getOrganisationDetailsByOrgId(organisationId), { method: 'GET' }, [organisationId], !!organisationId);
  const organisationLogo = responseOrganisation?.payloadId?.URL;

  useEffect(() => {
    if (organisationLogo) resetState();
  }, [organisationLogo]);

  useEffect(() => {
    if (responseOrganisation) setOpen(false);
  }, [responseOrganisation]);

  const resetState = () => {
    setUploadState('idle');
    setErrorType(null);
    setFile(null);
  };

  const handleFileChange = async (event) => {
    const selectedFile = event?.target?.files[0];
    if (!selectedFile) return;

    // Validate file size
    if (selectedFile.size > MAX_FILE_SIZE) {
      setErrorType('size');
      setUploadState('error');
      return;
    }

    // Validate file type
    if (!ALLOWED_FILE_TYPES.includes(selectedFile.type)) {
      setErrorType('type');
      setUploadState('error');
      return;
    }

    setFile(selectedFile);
    setUploadState('uploading');

    try {
      const signedUrl = await api.getPresignedURL(selectedFile.name);
      if (!signedUrl) throw new Error('Failed to get presigned URL');
      const uploadSuccessful = await api.putUploadToS3(signedUrl, selectedFile);
      if (uploadSuccessful?.status !== 200) throw new Error('Upload failed');
      setUploadState('success');
      const viewImageURL = getBaseImageUrl(signedUrl);
      setPublicUrl(viewImageURL);
    } catch (error) {
      console.error('Error uploading file:', error);
      setErrorType('upload');
      setUploadState('error');
    }
  };

  const handleUpdateURL = async (organisationId, publicUrl) => {
    await api.postOrganisationLogo({ id: organisationId, url: publicUrl });
    await fetchingOrganisation(organisationId);
  };

  return (
    <>
      <Grid item onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} style={{ position: 'relative', width: 160, height: 160 }}>
        <RenderPreviewImage isHovered={isHovered} logo={organisationLogo} isLoadingImage={isFetchingOrganisation} />
        <RenderUploadOverlay isHovered={isHovered} open={open} setOpen={setOpen} />
      </Grid>

      <Modal
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <RenderUploadContent
              organisationId={organisationId}
              handleUpdateURL={() => handleUpdateURL(organisationId, publicUrl)}
              publicUrl={publicUrl}
              uploadState={uploadState}
              handleFileChange={handleFileChange}
              resetState={resetState}
              setOpen={setOpen}
              file={file}
              errorType={errorType}
              isFetchingOrganisation={isFetchingOrganisation}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default UploadComponent;
