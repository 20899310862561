import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import PrimaryButton from '../../../../common/button/Button';
import ProductImageFallback from '../../../../../assets/Marketplace/Image not available 1.svg';

const useStyles = makeStyles((theme) => ({
  productCardText: {
    fontSize: '14px',
    textAlign: 'center',
    lineHeight: '17px',
    letterSpacing: '0.71px',
    color: '#000000',
    fontWeight: 700,
    margin: '2%',
    height: '30px',
  },
}));

const ProductCardComparisonPage = ({ productAddedToIntervention, selectedProduct, source, products, product, index, handleAddProduct }) => {
  const classes = useStyles();

  const [productImage, setProductImage] = useState('');
  const [isImageAccessible, setIsImageAccessible] = useState(false);

  useEffect(() => {
    if (productImage && productImage.trim() !== '') {
      const img = new Image();
      img.src = productImage;
      img.onload = () => setIsImageAccessible(true);
      img.onerror = () => setIsImageAccessible(false);
    }
  }, [productImage]);
  useEffect(() => {
    setProductImage(product?.attributes?.image);
  }, [product]);
  const getSelectButtonState = (productId) => {
    if (source === 'interventionDetails' && !productAddedToIntervention) return selectedProduct === productId;
    if (source === 'interventionDetails' && productAddedToIntervention) return productAddedToIntervention === productId;
    return false;
  };
  const getButtonLabel = (productId) => {
    if (source === 'interventionDetails') {
      return getSelectButtonState(productId) ? 'SELECTED' : 'SELECT PRODUCT';
    }
    if (source === 'marketplace' || source === 'shortlist') return 'ADD PRODUCT';
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      style={{
        borderRight: products?.length - 1 > index ? '1px solid #E5E5E5' : '',
        height: '270px',
        width: '210px',
        marginTop: '2%',
      }}
    >
      <img
        style={{ height: '150px', width: '150px', marginTop: '2%', marginBottom: '2%' }}
        src={isImageAccessible ? productImage : ProductImageFallback}
        alt={'...'}
      />
      <Typography className={classes.productCardText} style={{ color: 'black' }}>
        {product?.attributes?.sku_name}
      </Typography>
      <Typography className={classes.productCardText} style={{ color: 'black' }}>
        {product?.attributes?.brand?.data?.attributes?.name}
      </Typography>
      <PrimaryButton
        onClick={() => handleAddProduct(product?.attributes?.catalog_sku_id)}
        disabled={getSelectButtonState(product?.attributes?.catalog_sku_id)}
        children={getButtonLabel(product?.attributes?.catalog_sku_id)}
        style={{
          width: '90%',
          marginTop: '2%',
          boxShadow: 'none',
        }}
      />
    </Box>
  );
};
export default ProductCardComparisonPage;
