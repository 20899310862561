import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
    backgroundColor: '#fff',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '540px',
    height: '320px',
    margin: theme.spacing(2),
  },
  icon: {
    fontSize: '48px',
    marginBottom: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    fontSize: '20px',
  },
  text: {
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    lineHeight: '20px',
  },
  buttonContainer: {
    marginTop: '3%',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  button: {
    minWidth: '100px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
