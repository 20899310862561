import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import { Typography, Grid, FormControl, InputLabel, CircularProgress, Backdrop, makeStyles } from '@material-ui/core';

import CustomOutlinedInput from '../../components/common/textfield/CustomOutlinedInput';
import CustomButton from '../../components/common/button/CustomButton';

const ERROR_MESSAGES = {
  alreadyRegistered: 'This email ID is already registered',
  notRegistered: 'This email ID is not registered. Please contact your administrator.',
  accountCreated: 'Account created successfully. Please proceed to login.',
};

const SignUpForm = ({ classes, isEmailValid, formData, formError, onChange, disabled, onSubmit }) => {
  const { email, name, password, confirmPassword } = formData;
  const { name: nameError, password: passwordError } = formError;

  return (
    <>
      <Header classes={classes} message="Please enter the details to create your account" />
      <EmailStatus email={email} isEmailValid={isEmailValid} classes={classes} />

      {/* form fields */}
      <Grid item xs={12} style={{ marginTop: '3%' }}>
        <FormControl margin="normal" fullWidth variant="outlined" required>
          <InputLabel htmlFor="name">Enter Name</InputLabel>
          <CustomOutlinedInput
            id="name"
            name="name"
            fullWidth
            className={classes.textField}
            labelWidth={100}
            value={name}
            onChange={onChange}
            error={!!nameError}
          />
        </FormControl>
        <Typography variant="h6" style={{ marginTop: '-1%', color: 'red' }}>
          {nameError}
        </Typography>
      </Grid>

      <Grid item xs={12} style={{ marginTop: '4%' }}>
        <FormControl margin="normal" fullWidth variant="outlined" required>
          <InputLabel htmlFor="password">Create Password</InputLabel>
          <CustomOutlinedInput
            id="password"
            name="password"
            fullWidth
            className={classes.textField}
            autoComplete="current-password"
            labelWidth={130}
            type="password"
            value={password}
            onChange={onChange}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <FormControl margin="normal" fullWidth variant="outlined" required>
          <InputLabel htmlFor="confirm-password">Confirm Password</InputLabel>
          <CustomOutlinedInput
            id="confirm-password"
            name="confirmPassword"
            fullWidth
            className={classes.textField}
            autoComplete="current-password"
            labelWidth={130}
            type="password"
            error={passwordError}
            value={confirmPassword}
            onChange={onChange}
          />
        </FormControl>
        <Typography variant="h6" style={{ marginTop: '-1%', color: 'red' }}>
          {passwordError}
        </Typography>
      </Grid>
      <SubmitButton disabled={disabled} buttonText="CREATE ACCOUNT" onClick={onSubmit} classes={classes} />
    </>
  );
};

const EmailValidationForm = ({ classes, email, onChange, emailError, disabled, loading, onSubmit }) => {
  return (
    <>
      <Header classes={classes} message="Let's get started! Please confirm your email" />

      <Grid item xs={12} container>
        <FormControl margin="normal" fullWidth variant="outlined" required>
          <InputLabel htmlFor="email">Email Address</InputLabel>
          <CustomOutlinedInput
            id="email"
            name="email"
            fullWidth
            className={classes.textField}
            labelWidth={100}
            error={!!emailError}
            onChange={onChange}
            value={email}
          />
        </FormControl>

        <Typography variant="h6" style={{ marginTop: '-1%', color: 'red' }}>
          {emailError}
        </Typography>
      </Grid>

      <MessageSection message="" loading={loading} />
      <SubmitButton disabled={disabled} buttonText="VERIFY EMAIL ADDRESS" onClick={onSubmit} classes={classes} />
    </>
  );
};

const SuccessSection = ({ classes, email, isEmailValid, onSubmit }) => {
  return (
    <>
      <Header classes={classes} message="" />
      <EmailStatus email={email} isEmailValid={isEmailValid} classes={classes} />
      <MessageSection message="Account created successfully. Please proceed to login." />
      <SubmitButton buttonText="BACK TO LOGIN" onClick={onSubmit} classes={classes} />
    </>
  );
};

const InvalidEmailSection = ({ classes, email, emailError, isEmailValid }) => {
  return (
    <>
      <Header classes={classes} message="Please enter the details to create your account" />
      <EmailStatus email={email} isEmailValid={isEmailValid} classes={classes} />
      <MessageSection message={emailError} />
      <SubmitButton disabled={true} buttonText="CREATE ACCOUNT" onClick={() => {}} classes={classes} />
    </>
  );
};

const EmailStatus = ({ email, isEmailValid, classes }) => (
  <Grid container justifyContent="flex-start" alignItems="center">
    <Grid item xs={1} container justifyContent="center" alignItems="center">
      {isEmailValid ? <CheckCircleIcon style={{ color: '#42E198', fontSize: '20px' }} /> : <InfoIcon style={{ color: 'red', fontSize: '20px' }} />}
    </Grid>
    <Grid item xs={10}>
      <Typography className={classes.email} variant="h6" color="textSecondary">
        {email}
      </Typography>
    </Grid>
  </Grid>
);

const Header = ({ classes, message }) => (
  <>
    <Grid item xs={12}>
      <Typography className={classes.title} variant="h5" gutterBottom>
        Welcome to SD+
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography className={classes.subTitle} variant="body1">
        {message}
      </Typography>
    </Grid>
  </>
);

const MessageSection = ({ message }) => {
  return (
    <Grid item xs={12} container justifyContent="center" textAlign="center">
      <Grid item xs={12} style={message !== ERROR_MESSAGES.alreadyRegistered ? { paddingTop: '20%' } : { paddingTop: '20%', textAlign: 'center' }}>
        <Typography variant="h6">{message}</Typography>
      </Grid>

      <Grid item xs={12} style={{ paddingTop: '12%', marginBottom: '12%' }}>
        <div style={{ margin: '2%' }} />
      </Grid>
    </Grid>
  );
};

const SubmitButton = ({ disabled, buttonText, onClick, classes }) => {
  return (
    <Grid item xs={12} style={{ marginTop: '3%' }}>
      <CustomButton
        type="submit"
        onClick={onClick}
        fullWidth
        variant="contained"
        className={disabled ? classes.buttonDisabled : classes.button}
        disabled={disabled}
      >
        {buttonText}
      </CustomButton>
    </Grid>
  );
};

const SignupForm = ({
  isAccountCreated,
  isEmailValid,
  isEmailSubmitted,
  formData,
  formError,
  onFormChange,
  onClickVerifyEmail,
  onClickCreateAccount,
  redirectToHome,
  disabled,
}) => {
  const classes = useStyles();

  if (isAccountCreated) return <SuccessSection classes={classes} email={formData.email} isEmailValid={isEmailValid} onSubmit={redirectToHome} />;

  if (isEmailValid)
    return (
      <SignUpForm
        classes={classes}
        isEmailValid={isEmailValid}
        formData={formData}
        formError={formError}
        onChange={onFormChange}
        disabled={disabled}
        onSubmit={onClickCreateAccount}
      />
    );

  if (isEmailSubmitted) return <InvalidEmailSection classes={classes} email={formData.email} emailError={formError.email} isEmailValid={isEmailValid} />;

  return (
    <EmailValidationForm
      classes={classes}
      email={formData.email}
      onChange={onFormChange}
      emailError={formError.email}
      disabled={disabled}
      onSubmit={onClickVerifyEmail}
    />
  );
};

export const LoadingSpinner = ({ loading = false, ...props }) => (
  <Backdrop open={loading} style={{ zIndex: 9999 }} onClick={() => {}} {...props}>
    <Grid item xs={12} container justifyContent="center" style={{ marginTop: '2rem' }}>
      <CircularProgress color="primary" />
    </Grid>
  </Backdrop>
);

export default SignupForm;

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
    fontSize: '24px',
    marginBottom: theme.spacing(1),
    color: '#333',
  },
  subTitle: {
    fontSize: '16px',
    color: 'black',
    marginBottom: theme.spacing(3),
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
    },
  },
  button: {
    zIndex: 1,
    background: 'linear-gradient(90deg, #28DFCA, #42E198)',
    color: '#FFFFFF',
    fontWeight: 'bold',
    textTransform: 'none',
    borderRadius: '8px',
    padding: theme.spacing(1.5),
    '&:hover': {
      background: 'linear-gradient(90deg, #1FDEDB, #42E198)',
    },
  },
  buttonDisabled: {
    position: 'relative', // Required for pseudo-element positioning
    zIndex: 1,
    background: 'linear-gradient(90deg, #1FDEDB, #42E198)',
    fontWeight: 'bold',
    textTransform: 'none',
    borderRadius: '8px',
    padding: theme.spacing(1.5),
    overflow: 'hidden', // Ensures pseudo-element stays within bounds
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '100%', // Adjust height for how much of the whitish effect you want
      background: 'rgba(255, 255, 255, 0.2)', // Whitish overlay
      borderRadius: '8px 8px 0 0', // Match button's top corners
      pointerEvents: 'none', // Ensures it doesn’t interfere with button interactions
    },
    '&:hover': {
      background: 'linear-gradient(90deg, #42E198, #42E198)',
      '&::before': {
        background: 'rgba(255, 255, 255, 0.3)', // Slightly stronger effect on hover
      },
    },
  },
  email: {
    color: 'black',
  },
}));
