export const UserRoles = {
  superAdmin: 'superAdmin',
  admin: 'admin',
  user: 'user',
  super: 'super',
  normal: 'normal',
  app: 'app',
};

const userRoles = {
  /////////////////////version 1////////////////////
  superadmins: [UserRoles.superAdmin],
  admins: [UserRoles.admin],
  users: [UserRoles.user],
  ///////////////////version 2/////////////////////
  all: [UserRoles.superAdmin, UserRoles.admin, UserRoles.user, UserRoles.normal, UserRoles.super, UserRoles.app],
  version2: [UserRoles.normal, UserRoles.super, UserRoles.admin],
  usersV2: [UserRoles.user],
  supersV2: [UserRoles.super],
  usersandsupers: [UserRoles.normal, UserRoles.super],
  usersandadmins: [UserRoles.normal, UserRoles.admin],
};
export default userRoles;
