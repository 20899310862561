import React, { useEffect, useState } from 'react';
import { Collapse, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import CustomHeader from '../../../certification/common/CustomHeader';
import { Sections } from '../../../certification/edit/ResidentHealthAndWellBeing/DataTypes';
import CustomDrawerEnvelope from './CustomDrawerEnvelope';
import TowersTable from './envelopeTables/TowersTable';
import { useParams } from 'react-router';
import useAppProjectDetails from '../../hooks/useAppProjectDetails';
import useAppPIPScheme from '../../hooks/useAppPIPScheme';
import { getPIP, mergeArrays } from '../../../interventions/intervention/utils';
import useAppProjectPIPMapping from '../../hooks/useAppProjectPIPMapping';
import axios from 'axios';
import DeleteModal from './DeleteModal';
import OpeningsTable from './envelopeTables/OpeningsTable';

const OpeningSchedule = ({ form, handlePIPValueUpdate, setOpeningData, isOpeningAvailable, setIsOpeningAvailable, isTowerAvailable, mount, setMount }) => {
  const history = useHistory();
  let { id } = useParams();
  const [collapseSection, setCollapseSection] = useState(true);
  const [localOpeningData, setLocalOpeningData] = useState([]);
  const [editTable, setEditTable] = useState(false);
  const [editableId, setEditableId] = useState();
  const [editableData, setEditableData] = useState('');
  const [update, setUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [towerToDelete, setTowerToDelete] = useState();
  const [closePopover, setClosePopover] = useState(false);
  const { data: envelopePIPs } = useAppPIPScheme('envelope');
  const requiredPipId = getPIP(envelopePIPs, 'project__pi_openings')?._id;

  setIsOpeningAvailable(form?.pipvalues?.[requiredPipId]?.length > 0 || localOpeningData?.length > 0);
  useEffect(() => {
    setOpeningData(mergeArrays(localOpeningData, form?.pipvalues[requiredPipId] || []));
  }, [isOpeningAvailable, localOpeningData]);

  const handleExpandIcon = () => setCollapseSection((prev) => !prev);
  const handleDelete = () => {
    const towersPipId = getPIP(envelopePIPs, 'project__pi_towerOpenings')?._id;
    const towerPerOpeningValues = form?.pipvalues[towersPipId];
    const existingPipValue = form?.pipvalues[requiredPipId];
    let updatedPipValues = [];
    let updatedTowerPerOpeningValues = [];
    if (existingPipValue.length > 0) {
      updatedPipValues = existingPipValue.filter((item) => item._id !== towerToDelete);
      updatedTowerPerOpeningValues = towerPerOpeningValues.filter((item) => item.openingId !== towerToDelete);
    } else updatedPipValues = localOpeningData.filter((item) => item._id !== towerToDelete);
    setLocalOpeningData(updatedPipValues);
    handlePIPValueUpdate(requiredPipId, updatedPipValues);
    handlePIPValueUpdate(towersPipId, updatedTowerPerOpeningValues);
    setUpdate(!update);
  };
  return (
    <>
      <Grid xs={6}>
        <DeleteModal setClosePopover={setClosePopover} handleDelete={handleDelete} showModal={showModal} setShowModal={setShowModal} />
      </Grid>
      {/* Header */}
      <CustomHeader
        sectionName={'RETV Opening Schedule'}
        collapseSection={collapseSection}
        i={1}
        history={history}
        expand={true}
        handleExpandIcon={handleExpandIcon}
      />
      {!isTowerAvailable && (
        <Typography style={{ fontWeight: '400', color: '#00000061' }}>Add a tower to enable RETV opening schedule specifications</Typography>
      )}
      {/* Drawer */}
      <CustomDrawerEnvelope
        form={form}
        handlePIPValueUpdate={handlePIPValueUpdate}
        setLocalOpeningData={setLocalOpeningData}
        envelopePIPs={envelopePIPs}
        editableId={editableId}
        customAddButton={isTowerAvailable}
        title="ADD OPENING SCHEDULE"
        section={'OPENING'}
        editTable={editTable}
        handleEditTable={(val) => setEditTable(val)}
        editableData={editableData}
        handleEditableData={(d) => setEditableData(d)}
        setUpdate={setUpdate}
        update={update}
        mount={mount}
        setMount={setMount}
      />

      {/* Table */}
      <Collapse in={collapseSection}>
        <OpeningsTable
          closePopover={closePopover}
          localOpeningData={localOpeningData}
          handleEdit={(towerId) => {
            setEditableId(towerId);
            setEditTable(true);
          }}
          setTowerToDelete={setTowerToDelete}
          setShowModal={setShowModal}
          setEditableData={setEditableData}
          editTable={editTable}
          editableId={editableId}
          update={update}
          requiredPipId={requiredPipId}
          setUpdate={setUpdate}
          mount={mount}
          setMount={setMount}
        />
      </Collapse>
    </>
  );
};

export default OpeningSchedule;
