/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState, useRef } from 'react';
import { Grid, Typography, FormControl, Select, MenuItem, Checkbox, ListItemText, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import swal from 'sweetalert';
import axios from '../../../../config/Axios';
import PrimaryButton from '../../../common/button/Button';
import HeadAndBackIcon from '../../../common/header/HeadAndBackIcon';
import SimplePIPS from '../common/components/SimplePIPS';
import { SomethingChanged } from '../EditProject';
import useAppProjectPIPMapping from '../hooks/useAppProjectPIPMapping';
import useAppProjectDetails from '../hooks/useAppProjectDetails';
import ApiError from '../../../common/Error/ApiError';
import useAppPIPScheme from '../hooks/useAppPIPScheme';

// make sure This component will be cleaned up into multiple components for multiple edit projects page - refer to wireframe
// create a context for sqm and sqft and make the components separate

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
    '& > *': {
      borderRadius: '50px',
      height: '7vh',
    },
  },
  icon: {
    marginTop: '3%',
  },
}));

const CustomDropdown = ({ value = [], onChange, options, disabled, placeholder, multiple = false }) => {
  const classes = useStyles();

  const handleChange = (event, option) => {
    const updatedOption = { ...option, selected: !option.selected };
    onChange(updatedOption);
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        style={{
          border: '1px solid #666666',
          width: 160,
          height: 50,
        }}
        classes={{ icon: classes.icon }}
        fullWidth
        disabled={disabled}
        displayEmpty
        multiple={multiple}
        renderValue={(selected) => placeholder}
        value={value}
      >
        {options.map((option, index) => (
          <MenuItem key={index} onClick={(e) => handleChange(e, option)}>
            {multiple && <Checkbox style={{ fontSize: '20px', color: 'rgba(102, 227, 190, 1)' }} checked={option.selected} />}
            <ListItemText primary={option.displayName} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const LandscapeDetailsSection = ({ formattedAdditionalLandscapePIPs, handleDropdownChange, projectPIP }) => {
  return (
    <Grid container xs={12} style={{ marginTop: `2%` }}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ marginLeft: '1%' }} fontWeight="bold">
          <b>Additional Landscape Area Details</b>
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" color="textSecondary" style={{ marginLeft: '1%' }}>
          Choose applicable fields if you have additional details about land area types.
        </Typography>
      </Grid>

      <Grid item xs={6} style={{ marginTop: '2%', marginLeft: '1%' }}>
        <CustomDropdown
          placeholder="Select Options"
          value={formattedAdditionalLandscapePIPs}
          onChange={handleDropdownChange}
          options={formattedAdditionalLandscapePIPs}
          disabled={false}
          multiple={true}
          projectPIP={projectPIP}
        />
      </Grid>
    </Grid>
  );
};

const LandscapePIPSGrid = ({ type, formattedAdditionalLandscapePIPs, projectPIP, handlePIPValueUpdate }) => {
  if (type !== 'land' || !formattedAdditionalLandscapePIPs || !projectPIP?.length) {
    return null;
  }

  return (
    <Grid container direction="row" item xs={12} style={{ marginTop: '2%' }}>
      {formattedAdditionalLandscapePIPs
        .filter((field) => field.selected)
        .map((field, index) => (
          <Grid key={index} item xs={4} style={{ marginBottom: '2%' }}>
            <SimplePIPS
              index={index}
              unit={field.unit}
              field={field}
              projectPIP={projectPIP}
              appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
              type={type}
              xs={6}
            />
          </Grid>
        ))}
    </Grid>
  );
};

const SimplePIPSList = ({ type, simplePIPs, projectPIP, form, handlePIPValueUpdate }) => {
  if (type !== 'land' || !simplePIPs || !projectPIP?.length) {
    return null;
  }

  return (
    <>
      <Grid container direction="row" item xs={12} style={{ margin: '-1%', marginTop: '3%' }}>
        {simplePIPs.map((field, index) => (
          <SimplePIPS
            key={index}
            index={index}
            unit={form?.unitPIPValue}
            field={field}
            projectPIP={projectPIP}
            appendChanged={(fieldId, value) => handlePIPValueUpdate(fieldId, value)}
            type={type}
          />
        ))}
      </Grid>
    </>
  );
};

const basicPIPs = ['project__pi_landscapeArea', 'project__pi_softsurfaceArea'];
const additionalLandscapeOptionsPIP = ['project__pi_additionalLandscapeAreaOptions'];

export const EditProjectLandDetailsPIP = (props) => {
  let history = useHistory();
  let { id } = useParams();
  let { type, next, name } = props;
  const { somethingChanged } = useContext(SomethingChanged);

  const [simplePIPs, setSimplePIPs] = useState(null);
  const [formattedAdditionalLandscapePIPs, setFormattedAdditionalLandscapePIPs] = useState([
    { _id: null, unit: null, name: 'project__pi_concretePavement', displayName: '', description: '', selected: false },
    { _id: null, unit: null, name: 'project__pi_gravelPavement', displayName: '', description: '', selected: false },
    { _id: null, unit: null, name: 'project__pi_openGridConcretePavement', description: '', displayName: '', selected: false },
    { _id: null, unit: null, name: 'project__pi_flatSurface', displayName: '', description: '', selected: false },
    { _id: null, unit: null, name: 'project__pi_vegetationFlatSurface', displayName: '', description: '', selected: false },
    { _id: null, unit: null, name: 'project__pi_vegetationOpenGridGrassPavement', displayName: '', description: '', selected: false },
    { _id: null, unit: null, name: 'project__pi_vegetationSlightlySlopedSurface', displayName: '', description: '', selected: false },
    { _id: null, unit: null, name: 'project__pi_vegetationHillySurface', displayName: '', description: '', selected: false },
    { _id: null, unit: null, name: 'project__pi_vegetationSteepSurface', displayName: '', description: '', selected: false },
    { _id: null, unit: null, name: 'project__pi_grassSlightlySlopedSurface', displayName: '', description: '', selected: false },
    { _id: null, unit: null, name: 'project__pi_grassHillySurface', displayName: '', description: '', selected: false },
    { _id: null, unit: null, name: 'project__pi_grassSteepSurface', displayName: '', description: '', selected: false },
  ]);

  const [additionalLandscapePIPOptionsBasePIP, setAdditionalLandscapePIPOptionsBasePIP] = useState(null);

  const {
    get: { form, projectPIP, isError },
    set: { handlePIPValueUpdate },
  } = useAppProjectPIPMapping(id);

  const { data } = useAppPIPScheme(type);
  const { data: projectData } = useAppProjectDetails(id);

  useEffect(() => {
    if (data) {
      const simple = data?.filter((field) => basicPIPs.includes(field.name))?.sort((a, b) => basicPIPs.indexOf(a.name) - basicPIPs.indexOf(a.name));
      setAdditionalLandscapePIPOptionsBasePIP(data?.find((field) => additionalLandscapeOptionsPIP.includes(field.name)));
      const updatedAdditionalLandscapePIPs = formattedAdditionalLandscapePIPs.map((pip) => {
        const match = data?.find((field) => field.name === pip.name);
        return match
          ? { ...pip, displayName: match.displayName || '', description: match.description || '', _id: match._id || '', unit: match.unit || '' }
          : pip;
      });
      setSimplePIPs(simple);
      setFormattedAdditionalLandscapePIPs(updatedAdditionalLandscapePIPs);
    }
  }, [data]);

  const previousSelectedNamesRef = useRef([]);

  useEffect(() => {
    const selectedNames = formattedAdditionalLandscapePIPs.filter((pip) => pip.selected).map((pip) => pip.name);
    if (additionalLandscapePIPOptionsBasePIP?._id && selectedNames.join(',') !== previousSelectedNamesRef.current.join(',')) {
      handlePIPValueUpdate(additionalLandscapePIPOptionsBasePIP._id, selectedNames.join(','));
      previousSelectedNamesRef.current = selectedNames;
    }
  }, [formattedAdditionalLandscapePIPs, additionalLandscapePIPOptionsBasePIP, handlePIPValueUpdate]);

  const handleNext = (next) => {
    if (next && next === 'goals') history.push(`/projects/${id}/goals`);
    else {
      if (somethingChanged) {
        props.toogleShowModal(true);
        props.SETNEXT(next);
      } else {
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (props.saveAfterSomethingChanged === true && next) {
        await handleSave(next && next);
        props.toogleShowModal(false);
        props.setFalseAfterSomethingChanged();
        props.SETNEXT('');
      }
    })();
  }, [props.saveAfterSomethingChanged, next]);

  useEffect(() => {
    (async () => {
      if (projectPIP && additionalLandscapePIPOptionsBasePIP?._id) {
        const projectData = projectPIP.find((pip) => pip.PIPScheme._id === additionalLandscapePIPOptionsBasePIP._id);
        const selectedLandscapeNames = typeof projectData?.value === 'string' ? projectData.value.split(',') : [];
        setFormattedAdditionalLandscapePIPs((prevState) =>
          prevState.map((item) => (selectedLandscapeNames.includes(item.name) ? { ...item, selected: true } : item))
        );
      }
    })();
  }, [projectPIP, additionalLandscapePIPOptionsBasePIP]);

  const handleGoBack = (type) => {
    if (type === 'basic') history.push(`/projects/${id}/insights`);
    else history.push(`/projects/${id}/edit?type=basic`);
  };

  const handleSave = async (next) => {
    await axios
      .put(`/ProjectPIPMapping`, {
        projectId: id,
        values: Object.entries(form?.pipvalues).map(([key, value]) => ({
          PIPScheme: key,
          value,
        })),
      })
      .then((response) => {
        props.toogleShowModal(false);
        props.setFalseAfterSomethingChanged();
        props.SETNEXT('');
        swal({ icon: 'success', title: 'Data Saved' });
        if (next && next === 'insights') history.push(`/projects/${id}/insights`);
        if (next && next === 'projects') history.push(`/projects`);
        else history.push(`/projects/${id}/edit?type=${next}`);
      });
  };

  if (isError)
    return (
      <div style={{ display: 'flex', alignItems: 'center', paddingTop: 24, width: '100%' }}>
        <ApiError />
      </div>
    );

  const handleDropdownChange = (updatedOption) => {
    setFormattedAdditionalLandscapePIPs((prev) =>
      prev.map((option) => (option.name === updatedOption.name ? { ...option, selected: updatedOption.selected } : option))
    );
  };

  return (
    <>
      <Grid item container justifyContent="left" direction="column" xs={12} style={{ marginTop: '3%', marginLeft: '1%' }}>
        <HeadAndBackIcon
          heading={`${name && name} ${projectData?.name ? projectData?.name : ''}`}
          handleClick={() => handleGoBack(type && type)}
          visitGoals={false}
          headingVariant="h5"
          headingStyle={{ fontSize: '20px', fontWeight: 'bold' }}
          showArrow={false}
        />
      </Grid>

      {SimplePIPSList({ type, simplePIPs, projectPIP, form, handlePIPValueUpdate })}
      {LandscapeDetailsSection({ formattedAdditionalLandscapePIPs, handleDropdownChange, projectPIP })}
      {LandscapePIPSGrid({ type, formattedAdditionalLandscapePIPs, projectPIP, handlePIPValueUpdate })}

      <Grid container direction="row" alignItems="end" justifyContent="flex-end" item xs={12} style={{ marginBottom: '7%' }}>
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleSave(next && next)} children="Save" />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <PrimaryButton onClick={() => handleNext(next && next)} children="next" />
        </Grid>
      </Grid>
    </>
  );
};

export default EditProjectLandDetailsPIP;
