import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { StyledButton } from '../common/StyledButton';
import { IGBCCategories } from '../utils/types';

export default function IGBCCategoriesTogglers({ igbcCategoryContext, categories, handleIGBCCategory, totalCreditCountByCategory }) {
  return (
    <>
      <Grid container alignItems="center" style={{ backgroundColor: 'rgba(217, 217, 217, 0.2)', padding: '1.5% 2% 1.5% 2%' }}>
        {categories &&
          categories.map((data, index) => {
            return (
              <Grid item xs key={index}>
                <StyledButton
                  disabled={data === IGBCCategories.innovation}
                  value={data}
                  selected={igbcCategoryContext === categories[index]}
                  onChange={() => handleIGBCCategory(data)}
                >
                  <Typography variant="h6" style={{ fontSize: '13px', lineHeight: '18px' }}>
                    {data}
                  </Typography>
                  <Typography style={{ fontSize: '13px', paddingLeft: 4 }}>{(totalCreditCountByCategory && totalCreditCountByCategory[data]) || 0}</Typography>
                </StyledButton>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
}
