import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { GreenCheckbox } from '../../common/Checkbox';
// import { Sections } from '../../DataTypes';
// import CustomPopover from '../../common/CustomPopover';
// import { ResidentHealthAndWellBeingContext } from '../..';

export default function IIPData({ myArray, appendChanged }) {
  const classes = useStyles();
  let GrenproPaint =
    myArray &&
    myArray.length !== 0 &&
    myArray.find((d) => d.id === 'sourcing-greenpro-or-any-other-eco-label-certified-paints-coatings-including primers-for-the-interior-walls-and-ceilings') &&
    myArray.find((d) => d.id === 'sourcing-greenpro-or-any-other-eco-label-certified-paints-coatings-including primers-for-the-interior-walls-and-ceilings')
      .value === true
      ? true
      : false;
  let GreenProAdhesive =
    myArray &&
    myArray.length !== 0 &&
    myArray.find((d) => d.id === 'sourcing-greenpro-eco-label-or-any-other-eco-label-certified-adhesives-and-sealants') &&
    myArray.find((d) => d.id === 'sourcing-greenpro-eco-label-or-any-other-eco-label-certified-adhesives-and-sealants').value === true
      ? true
      : false;
  let twoandhalfpercent =
    myArray &&
    myArray.length !== 0 &&
    myArray.find(
      (d) =>
        d.id === '2-5-percent-or-more-of-occupants-have-access-to-facilities-like-gymnasium-aerobics-yoga-meditation-swimming-pool-or-any-indoor-outdoor-games'
    ) &&
    myArray.find(
      (d) =>
        d.id === '2-5-percent-or-more-of-occupants-have-access-to-facilities-like-gymnasium-aerobics-yoga-meditation-swimming-pool-or-any-indoor-outdoor-games'
    ).value === true
      ? true
      : false;
  let fivepercent =
    myArray &&
    myArray.length !== 0 &&
    myArray.find(
      (d) =>
        d.id === '5-percent-or-more-of-occupants-have-access-to-facilities-like-gymnasium-aerobics-yoga-meditation-swimming-pool-or-any-indoor-outdoor-games'
    ) &&
    myArray.find(
      (d) =>
        d.id === '5-percent-or-more-of-occupants-have-access-to-facilities-like-gymnasium-aerobics-yoga-meditation-swimming-pool-or-any-indoor-outdoor-games'
    ).value === true
      ? true
      : false;

  return (
    <>
      <Grid container direction="column" item xs={12}>
        <Typography className={classes.header}>Healthy Materials</Typography>
        <Typography variant="h6" color="textSecondary" style={{ marginTop: '1%' }}>
          Please select if materials with low emissions & VOC are being used
        </Typography>
        <Typography variant="h6" style={{ marginTop: '2%' }}>
          {GrenproPaint ? (
            <CheckBoxIcon
              style={{ color: '#66E3BE', cursor: 'pointer', marginRight: '10px', fontSize: 20 }}
              onClick={() =>
                appendChanged('sourcing-greenpro-or-any-other-eco-label-certified-paints-coatings-including primers-for-the-interior-walls-and-ceilings', false)
              }
            />
          ) : (
            <CheckBoxOutlineBlankIcon
              style={{ color: '#66E3BE', cursor: 'pointer', marginRight: '10px', fontSize: 20 }}
              onClick={() =>
                appendChanged('sourcing-greenpro-or-any-other-eco-label-certified-paints-coatings-including primers-for-the-interior-walls-and-ceilings', true)
              }
            />
          )}
          Sourcing GreenPro or any other Eco-label certified paints, coatings (including primers) for the interior walls and ceilings
        </Typography>
        <Typography variant="h6" style={{ marginTop: '2%', marginBottom: '1%' }}>
          {GreenProAdhesive ? (
            <CheckBoxIcon
              style={{ color: '#66E3BE', cursor: 'pointer', marginRight: '10px', fontSize: 20 }}
              onClick={() => appendChanged('sourcing-greenpro-eco-label-or-any-other-eco-label-certified-adhesives-and-sealants', false)}
            />
          ) : (
            <CheckBoxOutlineBlankIcon
              style={{ color: '#66E3BE', cursor: 'pointer', marginRight: '10px', fontSize: 20 }}
              onClick={() => appendChanged('sourcing-greenpro-eco-label-or-any-other-eco-label-certified-adhesives-and-sealants', true)}
            />
          )}
          Sourcing GreenPro Eco-label or any other Eco-label certified adhesives and sealants
        </Typography>
        <Typography className={classes.header} style={{ paddingTop: '30px' }}>
          Physical Wellbeing
        </Typography>
        <Typography variant="h6" color="textSecondary" style={{ marginTop: '1%' }}>
          Please select if materials with low emissions & VOC are being used
        </Typography>
        <Typography variant="h6" style={{ marginTop: '2%' }}>
          {twoandhalfpercent ? (
            <CheckBoxIcon
              style={{ color: '#66E3BE', cursor: 'pointer', marginRight: '10px', fontSize: 20 }}
              onClick={() =>
                appendChanged(
                  '2-5-percent-or-more-of-occupants-have-access-to-facilities-like-gymnasium-aerobics-yoga-meditation-swimming-pool-or-any-indoor-outdoor-games',
                  false
                )
              }
            />
          ) : (
            <CheckBoxOutlineBlankIcon
              style={{ color: '#66E3BE', cursor: 'pointer', marginRight: '10px', fontSize: 20 }}
              onClick={() =>
                appendChanged(
                  '2-5-percent-or-more-of-occupants-have-access-to-facilities-like-gymnasium-aerobics-yoga-meditation-swimming-pool-or-any-indoor-outdoor-games',
                  true
                )
              }
            />
          )}{' '}
          2.5% or more of occupants have access to facilities like gymnasium, aerobics, yoga, meditation, swimming pool or any indoor / outdoor games
        </Typography>
        <Typography variant="h6" style={{ marginTop: '2%', marginBottom: '2%' }}>
          {fivepercent ? (
            <CheckBoxIcon
              style={{ color: '#66E3BE', cursor: 'pointer', marginRight: '10px', fontSize: 20 }}
              onClick={() =>
                appendChanged(
                  '5-percent-or-more-of-occupants-have-access-to-facilities-like-gymnasium-aerobics-yoga-meditation-swimming-pool-or-any-indoor-outdoor-games',
                  false
                )
              }
            />
          ) : (
            <CheckBoxOutlineBlankIcon
              style={{ color: '#66E3BE', cursor: 'pointer', marginRight: '10px', fontSize: 20 }}
              onClick={() =>
                appendChanged(
                  '5-percent-or-more-of-occupants-have-access-to-facilities-like-gymnasium-aerobics-yoga-meditation-swimming-pool-or-any-indoor-outdoor-games',
                  true
                )
              }
            />
          )}
          5% or more of occupants have access to facilities like gymnasium, aerobics, yoga, meditation, swimming pool or any indoor / outdoor games
        </Typography>
      </Grid>
    </>
  );
}

const useStyles = makeStyles({
  header: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '0.714286px',
    color: 'rgba(0, 0, 0, 0.83)',
  },
  subhead: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '22px',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.38)',
    paddingTop: '10px',
  },
  text: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '22px',
    letterSpacing: '0.5px',
    color: 'rgba(0, 0, 0, 0.83)',
    paddingTop: '16px',
  },
});
